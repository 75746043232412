import { useMemo } from 'react';

import { useGetBotAnalyticsNumbersQuery } from '@api/botsAPI';
import { BotsApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isBotAnalyticsNumbersSuccess: result.isSuccess,
    refetchBotAnalyticsNumbers: result.refetch,
  };

  dataResult.botOrders = BotsApiSelectors.dataSelector(result);

  return dataResult;
};

const useBotAnalyticsNumbers = ({ botId } = {}, options) => {
  const result = useGetBotAnalyticsNumbersQuery({ botId }, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useBotAnalyticsNumbers;
