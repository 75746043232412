import React, { memo } from 'react';

import { ProgressBar } from '@shared/ui';

import { useGetDifferenceBetweenDays } from '@pages/WalletsPage/Staking/hooks';

import styles from './FilledItem.module.scss';

const FilledItem = ({ remainingDays, durationDays }) => {
  const { percentElapsedDays } = useGetDifferenceBetweenDays(
    remainingDays,
    durationDays,
  );

  return (
    <div className={styles.filled_item}>
      <ProgressBar
        height={4}
        percentages={percentElapsedDays}
        customStyles={styles}
        isPill
        percentPrecision={0}
      />
    </div>
  );
};

export default memo(FilledItem);
