import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@components/Button';
import Modal from '@components/modals/Modal';

import { useMarketsInfo } from '@store/api/hooks';
import { useCancelAllOrdersMutation } from '@store/api/ordersAPI';
import { marketIdSelector } from '@store/settings/settingsSelectors';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

const ConfirmCancelOpenOrdersByPairModal = () => {
  const { isOpen, closeModal } = useModal(
    modalsIds.CONFIRM_CANCEL_OPEN_ORDERS_BY_PAIR,
  );

  const [cancelAllOrders, { isLoading }] = useCancelAllOrdersMutation();

  const marketId = useSelector(marketIdSelector);

  const { marketInfo } = useMarketsInfo(
    { marketId },
    {
      skip: !isOpen,
    },
  );

  const handleCancelOpenOrdersByPair = () => {
    cancelAllOrders(marketId).unwrap().finally(closeModal);
  };

  return (
    <Modal
      className="modal-sm"
      onClose={closeModal}
      visible={isOpen}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">
            <FormattedMessage id="ConfirmCancelAllOpenOrders" />
          </div>
        </div>
      }
      footer={
        <div>
          {isLoading ? (
            <Button isLoading fill dangerous rounded />
          ) : (
            <div className="d-flex justify-content-between">
              <Button
                fill
                dangerous
                rounded
                onClick={handleCancelOpenOrdersByPair}
              >
                <FormattedMessage id="YesConfirm" />
              </Button>

              <Button
                fill
                secondary
                rounded
                onClick={closeModal}
                className="ms-10"
              >
                <FormattedMessage id="No" />
              </Button>
            </div>
          )}
        </div>
      }
    >
      <FormattedMessage
        id="ConfirmCancelOpenOrdersByPairMessage"
        values={{
          market: marketInfo?.name,
        }}
      />
    </Modal>
  );
};

export default memo(ConfirmCancelOpenOrdersByPairModal);
