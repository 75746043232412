import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  useAccountBalances,
  useCoinsInfo,
  useCoinsRates,
} from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { format } from '@utils/numbers';

const useTotalBalance = (
  { accountId, coinSymbol, filedName = 'total_balance' } = {},
  options,
) => {
  const { value: currency } = useSelector(currencySelector);

  const totalBalanceCoinSymbol = (coinSymbol || currency)?.toLowerCase();

  const { ratesByCoin: coinRates, isCoinsRatesSuccess } = useCoinsRates(
    {
      fromCoinSymbol: totalBalanceCoinSymbol,
    },
    options,
  );

  const { accountBalancesMap, isSuccessAccountBalances } = useAccountBalances(
    {
      accountId,
    },
    options,
  );

  const { coinInfo, isCoinsInfoSuccess } = useCoinsInfo(
    {
      coinSymbol: totalBalanceCoinSymbol,
    },
    options,
  );

  const totalBalance = useMemo(() => {
    return accountBalancesMap.reduce((sum, balance) => {
      const coinRate = coinRates?.[balance.symbol.toUpperCase()];

      if (coinRate) {
        const balanceCoinRate = Number.parseFloat(coinRate) || 0;
        const totalField = Number.parseFloat(balance[filedName]) || 0;

        return totalField / balanceCoinRate + sum;
      }

      return sum;
    }, 0);
  }, [filedName, accountBalancesMap, coinRates]);

  return {
    formattedTotalBalance: format(totalBalance, {
      precision: coinInfo?.digits,
    }),
    totalBalance,
    totalBalanceCoinSymbol,
    isTotalBalanceSuccess:
      isCoinsRatesSuccess && isCoinsInfoSuccess && isSuccessAccountBalances,
    precision: coinInfo?.digits,
  };
};

export default useTotalBalance;
