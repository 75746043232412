import { createSelector } from '@reduxjs/toolkit';

import { ACCOUNT_SECURITY_SETTINGS } from '@shared/constants/account.constants';
import { percentOfNumber } from '@shared/utils';

import { userSecuritySelector, userSelector } from '@store/api/profileAPI';

import {
  MINIMAL_VISIBLE_PROGRESS_BAR_LENGTH,
  SECURITY_SETTINGS,
} from './account.constants';

const { isGoogleTwoFaExists, isSmsTwoFaExists } = ACCOUNT_SECURITY_SETTINGS;

export const securitySettingsSelector = createSelector(
  userSecuritySelector,
  (userSecurity) => {
    return SECURITY_SETTINGS.map((setting) => {
      return { ...setting, isActive: userSecurity[setting.key] };
    });
  },
);

export const activeSecuritySettingsSelector = createSelector(
  securitySettingsSelector,
  (securitySettings) => {
    return securitySettings.filter((setting) => setting.isActive);
  },
);

export const securityLevelPercentSelector = createSelector(
  activeSecuritySettingsSelector,
  securitySettingsSelector,
  (activeSecuritySettings, securitySettings) => {
    const percent = percentOfNumber(
      activeSecuritySettings.length,
      securitySettings.length,
    );

    return percent < MINIMAL_VISIBLE_PROGRESS_BAR_LENGTH
      ? MINIMAL_VISIBLE_PROGRESS_BAR_LENGTH
      : percent;
  },
);

export const isTwoAuthExistsSelector = createSelector(
  activeSecuritySettingsSelector,
  (activeSecuritySettings) => {
    return activeSecuritySettings.some((setting) =>
      [isGoogleTwoFaExists, isSmsTwoFaExists].includes(setting.key),
    );
  },
);

export const userPhoneSelector = createSelector(userSelector, (user) => {
  return user.phone;
});
