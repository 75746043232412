import { memo } from 'react';

import { PersonalData } from '../PersonalData';

import FormattedPercent from './FormattedPercent';

const PersonalFormattedPercent = ({
  percent,
  precision,
  roundingMode,
  removeZeroEnd,
  ...props
}) => {
  return (
    <PersonalData
      {...props}
      data={
        <FormattedPercent
          percent={percent}
          roundingMode={roundingMode}
          removeZeroEnd={removeZeroEnd}
          precision={precision}
        />
      }
    />
  );
};

export default memo(PersonalFormattedPercent);
