import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kybStatus: {},
  kybActiveStep: undefined,
};

const kybSlice = createSlice({
  name: 'kyb',
  initialState,
  reducers: {
    setKybStep: (state, action) => {
      state.kybActiveStep = action.payload;
    },
    setKybStatus: (state, action) => {
      state.kybStatus = { ...state.kybStatus, ...action.payload };
    },
  },
});

const kybActions = kybSlice.actions;
const kybReducer = kybSlice.reducer;

export { kybActions, kybReducer };

export default kybSlice;
