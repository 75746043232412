import React, { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import styles from './Control.module.scss';

const Control = ({ children, selectProps, ...props }) => {
  const isSearchable = selectProps.isSearchable;
  const isInversion = selectProps.inversion;
  const isBordered = selectProps.bordered;
  const isUnderlined = selectProps.underlined;
  const isInvalid = selectProps.invalid;
  const customStyles = selectProps.customStyles;

  const size = selectProps.size;

  return (
    <components.Control
      {...props}
      className={cx(
        styles.control,
        styles[size],
        {
          [styles.searchable]: isSearchable,
          [styles.inversion]: isInversion,
          [styles.bordered]: isBordered,
          [styles.underlined]: isUnderlined,
          [styles.invalid]: isInvalid,
        },
        customStyles?.bordered && isBordered
          ? customStyles?.bordered
          : undefined,
      )}
    >
      {children}
    </components.Control>
  );
};

export default memo(Control);
