import React, { memo } from 'react';

import classNames from 'classnames';

import { PersonalData } from '@shared/components';

import styles from './TotalValue.module.scss';

const TotalValue = ({ value, symbol, customStyles = {} }) => {
  return (
    <span className={classNames(styles.total, customStyles.total)}>
      <PersonalData data={<>≈ {value}</>} /> {symbol}
    </span>
  );
};

export default memo(TotalValue);
