import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Records from './Records';

const StakedItem = ({ stakedAssets }) => {
  return stakedAssets.length ? (
    stakedAssets.map((item, index) => {
      return (
        <Records
          percent={item.percent}
          index={index}
          item={item}
          key={item.label}
        />
      );
    })
  ) : (
    <center className="fs-14 color-white my-10">
      <FormattedMessage id="NoRecords" />
    </center>
  );
};

export default memo(StakedItem);
