export const tfcOverrides = (TFC, { stx }) => {
  TFC.prototype.renderOpenOrders = function () {
    var openOrderMarkers = this.ephemeralNodes.openOrders;
    var i;
    var openOrderMarker;
    for (i = 0; i < openOrderMarkers.length; i++) {
      openOrderMarker = openOrderMarkers[i];
      openOrderMarker.style.display = '';
      openOrderMarker.style.width = `${
        stx.chart.canvasWidth - stx.chart.yAxis.width - 5
      }px`;
      // openOrderMarker.style.width = '';
      var openOrder = openOrderMarker.openOrder;
      var price = null;
      if (openOrder.limit) price = openOrder.limit;
      else if (openOrder.stop) price = openOrder.stop;
      else if (openOrder.marketIfTouched) price = openOrder.marketIfTouched;
      else price = this.getCurrentPriceForOrder(openOrder.action); // market order
      this.positionAtPrice(price, [openOrderMarker], 'center', null, true);
    }
    for (i = 0; i < openOrderMarkers.length; i++) {
      openOrderMarker = openOrderMarkers[i];
      var overlapOffset = 0;
      for (var j = i + 1; j < openOrderMarkers.length; j++) {
        var potentialOverlap = openOrderMarkers[j];
        if (this.overlap(openOrderMarker, potentialOverlap)) {
          overlapOffset += 30;
        }
      }
      if (this.activeTrade && this.activeTrade != 'market') {
        openOrderMarker.style.width = '425px';
      }
      if (overlapOffset) {
        openOrderMarker.style.width = `${
          stx.chart.canvasWidth - stx.chart.yAxis.width
        }px`;
        // openOrderMarker.style.width =
        //   openOrderMarker.offsetWidth + overlapOffset + 'px';
      }
    }
    // hide any associated with the current modifyingOrder
    for (i = 0; i < openOrderMarkers.length; i++) {
      openOrderMarker = openOrderMarkers[i];
      if (
        this.modifyingOrder &&
        this.modifyingOrder.id == openOrderMarker.openOrder.id
      ) {
        openOrderMarker.style.display = 'none';

        if (openOrderMarker.linked) {
          for (var k = 0; k < openOrderMarker.linked.length; k++)
            openOrderMarker.linked[k].style.display = 'none';
        }
      }
    }
  };
};
