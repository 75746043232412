import NoData from '@components/NoData';

import styles from './TableDefaultBodyNoData.module.scss';

const TableDefaultBodyNoData = ({ table, noDataProps = {} }) => {
  const { component, ...rest } = noDataProps;

  const columnsQuantity = table.getAllColumns().length;

  return (
    <tbody className={styles.table_default_body_no_data}>
      <tr>
        <td colSpan={columnsQuantity}>{component ?? <NoData {...rest} />}</td>
      </tr>
    </tbody>
  );
};

export default TableDefaultBodyNoData;
