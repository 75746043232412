import { BLOCK_TYPES } from '@constants/blockTypes';

export const BLOCK_BREAKPOINTS = {
  [BLOCK_TYPES.chart]: {
    standard: { width: 0 },
    landscape: { width: 0 },
  },
  [BLOCK_TYPES.trading]: {
    standard: { width: 3 },
    landscape: { width: 4 },
  },
  [BLOCK_TYPES.orderBook]: {
    standard: { width: 2 },
    landscape: { width: 5 },
  },
  [BLOCK_TYPES.markets]: {
    standard: { width: 2 },
    landscape: { width: 3 },
  },
  [BLOCK_TYPES.openOrders]: {
    standard: { width: 0 },
    landscape: { width: 6 },
  },
  [BLOCK_TYPES.balances]: {
    standard: { width: 3 },
    landscape: { width: 6 },
  },
  [BLOCK_TYPES.trades]: {
    standard: { width: 2 },
    landscape: { width: 3 },
  },
  [BLOCK_TYPES.depth]: {
    standard: { width: 0 },
    landscape: { width: 0 },
  },
  [BLOCK_TYPES.orderHistory]: {
    standard: { width: 0 },
    landscape: { width: 6 },
  },
};
