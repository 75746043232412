import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { INSTANT_BONUS_STATUS_COLORS } from '@shared/constants';

import StatusDot from '@components/StatusDot';

const InstantBonusStatusItem = ({ status }) => {
  const intl = useIntl();

  const statusItem = INSTANT_BONUS_STATUS_COLORS[status];

  return (
    <StatusDot
      color={statusItem.color}
      label={intl.formatMessage({ id: statusItem.label })}
      labelPosition="left"
    />
  );
};

export default memo(InstantBonusStatusItem);
