import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { DayPickerRangeController, toMomentObject } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';

import { DatePickerModal, FormController } from '@shared/components';
import { MonthElement } from '@shared/components/DatePicker/components';
import {
  DATE_FORMAT_WITH_DASH,
  END_OF_TODAY,
  LAST_THIRTY_DAYS,
  DATEPICKER_ORIENTATION,
  RANGE_BUTTONS_IDS,
  LAST_SIX_DAYS,
  LAST_EIGHTY_NINE_DAYS,
  LAST_TWENTY_NINE_DAYS,
  START_OF_TODAY,
  FROM_ZERO,
  commonPickerProps,
} from '@shared/components/DatePicker/datePicker.constants';
import { TimeRangeButtons } from '@shared/components/DatePicker/RangeDatePicker';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal';

import { modalsIds, NOOP } from '@constants';

import DatePickerField from '../components/DatePickerField/DatePickerField';

const RangeDatePicker = forwardRef(
  (
    {
      isAutoFocusEndDate,
      dateFormat = DATE_FORMAT_WITH_DASH,
      yearsAndMonthsSelectable = true,
      onChange = NOOP,
      value,
      inputProps,
      isCustomRangeButtonDisplayed = true,
      // form controller props
      isInvalid,
      name,
      ...props
    },
    ref,
  ) => {
    const [startDate, setStartDate] = useState(
      value?.startDate
        ? toMomentObject(new Date(value.startDate))
        : LAST_THIRTY_DAYS,
    );
    const [endDate, setEndDate] = useState(
      value?.endDate ? toMomentObject(new Date(value.endDate)) : END_OF_TODAY,
    );
    const [focusedInput, setFocusedInput] = useState(
      isAutoFocusEndDate ? END_DATE : START_DATE,
    );
    const [isOpen, setIsOpen] = useState(false);
    const [isButtonsGroupActive, setIsButtonsGroupActive] = useState(true);

    const monthControllerRef = useRef();

    const { openModal: openCustomRangeModal } = useModal(
      modalsIds.DATE_PICKER_CUSTOM_RANGE,
    );

    const { isMobile } = useBreakpoints();

    // to be able to reset from parent component by pass value === ''
    useEffect(() => {
      if (value === '') {
        setStartDate(LAST_THIRTY_DAYS);
        setEndDate(END_OF_TODAY);
      }
    }, [value]);

    const dateFormatted = useMemo(
      () => `${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`,
      [startDate, endDate, dateFormat],
    );

    const minDate = toMomentObject(new Date(0));
    const maxDate = toMomentObject(new Date());

    const moveCalendarToSelectedDates = (month, year) => {
      // for move to selected year
      setTimeout(() => {
        monthControllerRef.current?.onYearSelect(
          monthControllerRef.current?.month,
          year,
        );
      }, 0);

      // for move to selected month (setTimeout need for select it after year)
      setTimeout(() => {
        monthControllerRef.current?.onMonthSelect(
          monthControllerRef.current?.month,
          month,
        );
      }, 1);
    };

    const handleDatesChange = ({ startDate, endDate }) => {
      setStartDate(startDate);
      setEndDate(endDate ? endDate : startDate);
      setIsButtonsGroupActive(false);

      onChange({ startDate, endDate });
    };

    const handleFocusChange = (focusedInput) => {
      // Force the focusedInput to always be truthy so that dates are always selectable
      setFocusedInput(focusedInput ? focusedInput : START_DATE);
    };

    const handleCalendarOpen = () => {
      setIsOpen(true);
    };

    const handleSelectRange = ({
      startDate,
      endDate,
      isCustomRange = false,
    }) => {
      if (isCustomRange) {
        onChange({ startDate, endDate, isCustomRange });

        return;
      }

      moveCalendarToSelectedDates(endDate.format('MM'), endDate.format('YYYY'));
      setStartDate(startDate);
      setEndDate(endDate);

      onChange({ startDate, endDate });
    };

    const handleClickRange = (item) => {
      setIsButtonsGroupActive(true);
      switch (item.id) {
        case RANGE_BUTTONS_IDS.SEVEN_DAYS:
          handleSelectRange({
            startDate: LAST_SIX_DAYS,
            endDate: END_OF_TODAY,
          });
          break;
        case RANGE_BUTTONS_IDS.THIRTY_DAYS:
          handleSelectRange({
            startDate: LAST_TWENTY_NINE_DAYS,
            endDate: END_OF_TODAY,
          });
          break;
        case RANGE_BUTTONS_IDS.NINETY_DAYS:
          handleSelectRange({
            startDate: LAST_EIGHTY_NINE_DAYS,
            endDate: END_OF_TODAY,
          });
          break;
        case RANGE_BUTTONS_IDS.CUSTOM_RANGE:
          openCustomRangeModal({ handleSelectCustomRange: handleSelectRange });
          break;
        case RANGE_BUTTONS_IDS.TODAY:
          handleSelectRange({
            startDate: START_OF_TODAY,
            endDate: END_OF_TODAY,
          });
          break;
        default:
          return;
      }
    };

    return (
      <div>
        <DatePickerField
          value={dateFormatted}
          ref={ref}
          onOpen={handleCalendarOpen}
          isInvalid={isInvalid}
          name={name}
          {...inputProps}
        />

        <DatePickerModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          footer={
            <TimeRangeButtons
              handleClickRange={handleClickRange}
              isButtonsGroupActive={isButtonsGroupActive}
              isCustomRangeButtonDisplayed={isCustomRangeButtonDisplayed}
            />
          }
        >
          <DayPickerRangeController
            onDatesChange={handleDatesChange}
            onFocusChange={handleFocusChange}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            numberOfMonths={2}
            orientation={
              isMobile
                ? DATEPICKER_ORIENTATION.VERTICAL
                : DATEPICKER_ORIENTATION.HORIZONTAL
            }
            renderMonthElement={(params) => {
              monthControllerRef.current = { ...params };

              return (
                <MonthElement
                  {...params}
                  yearsAndMonthsSelectable={yearsAndMonthsSelectable}
                />
              );
            }}
            isOutsideRange={(date) =>
              date.isBefore(FROM_ZERO, 'day') || date.isAfter(new Date(), 'day')
            }
            minDate={minDate}
            maxDate={maxDate}
            {...commonPickerProps}
            {...props}
          />
        </DatePickerModal>
      </div>
    );
  },
);

export const RangeDatePickerController = (props) => {
  return <FormController component={RangeDatePicker} {...props} />;
};

export default RangeDatePicker;
