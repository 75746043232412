import React, { memo } from 'react';

import MediaQuery from 'react-responsive';

import useTheme from '@hooks/useTheme';

const Breakpoint = ({ children, size, direction }) => {
  const { themeStyles } = useTheme();

  let width = Number.parseFloat(themeStyles[`breakpoint-${size}`]);

  if (direction === 'down') {
    // about 0.2 - https://getbootstrap.com/docs/5.0/layout/breakpoints/#max-width
    return <MediaQuery maxWidth={width - 0.2}> {children}</MediaQuery>;
  }

  return <MediaQuery minWidth={width}>{children}</MediaQuery>;
};

export default memo(Breakpoint);
