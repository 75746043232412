import { useEffect } from 'react';

import { PaginationBlock } from '@shared/components';

const TablePagination = ({ table, meta, ...props }) => {
  const activePageIndex = Math.max(table.getState().pagination.pageIndex, 0);

  useEffect(() => {
    table.resetExpanded();
  }, [activePageIndex, table]);

  const handleSetPageIndex = (index) => {
    table.setPageIndex(index);
  };

  return (
    <PaginationBlock
      meta={meta}
      activePageIndex={activePageIndex}
      onSetPageIndex={handleSetPageIndex}
      onPreviousPage={() => table.previousPage()}
      onNextPage={() => table.nextPage()}
      {...props}
    />
  );
};

export default TablePagination;
