const createDataSelector = (defaultValue) => (result) => {
  return result?.data || defaultValue;
};

const createDataApiSelector = (defaultValue) => (result) => {
  return result?.data?.data || defaultValue;
};

const createMetaSelector = (defaultValue) => (result) => {
  return result?.data?.meta || defaultValue;
};

export const ApiSelectorsUtils = {
  createMetaSelector,
  createDataSelector,
  createDataApiSelector,
};
