import { FormattedMessage } from 'react-intl';

import { Button } from '@shared/ui';

import { ReactComponent as FiltersIconSVG } from '@icons/filters-icon.svg';

import s from './AdvancedFiltersButton.module.scss';

const AdvancedFiltersButton = (props) => {
  return (
    <Button variant="outlined" customStyles={s} {...props}>
      <div className="d-flex align-items-center gap-6">
        <FiltersIconSVG width={16} height={8} />

        <FormattedMessage id="AdvancedFilters" />
      </div>
    </Button>
  );
};

export default AdvancedFiltersButton;
