import React, { memo, useId, useMemo } from 'react';

import Table from '@components/gridTables/Table';

import { useMyStakingTable } from '@pages/WalletsPage/Staking/hooks';

import stakingTableColumns from '../StakingTableColumns';

import { WIDGET_RECORDS_LIMIT } from '../../staking.constants';

const StakingTable = () => {
  const id = useId();

  const { stakingList, isMyStakingSuccess } = useMyStakingTable({
    limit: WIDGET_RECORDS_LIMIT,
  });

  const columns = useMemo(() => stakingTableColumns(), []);

  return (
    <Table
      id={id}
      className="account-tables-swipe my-20"
      columns={columns}
      data={stakingList}
      wideRowsS
      stripedRows={false}
      isLoading={!isMyStakingSuccess}
    />
  );
};

export default memo(StakingTable);
