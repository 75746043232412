import { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { BLOCK_BREAKPOINTS } from 'src/constants/blockBreakpoints';
import { BLOCK_TYPES } from 'src/constants/blockTypes';

import Slider from '@components/Slider';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';
import useBreakpoints from '@hooks/useBreakpoints';

import { format } from '@utils/numbers';

import FieldContainer from '../FieldContainer';

const QUANTITY_MARKS = {
  0: '0%',
  0.25: '25%',
  0.5: '50%',
  0.75: '75%',
  1: '100%',
};

const FormSlider = ({
  layoutItemRef,
  isBuyTab,
  currentPrice,
  quoteAvailableBalance,
  marketAvailableBalance,
  currentMarket,
  onChange,
  absoluteValue = 0,
}) => {
  const { isLaptop, isDesktop } = useBreakpoints();

  const { isMiniX, isLandscapeX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.trading],
  );

  const isSliderWithoutMarks =
    (isDesktop && isLandscapeX) || (isLaptop && isMiniX);
  const isSliderWithoutLine = isLaptop && isLandscapeX;
  const isForceShowLeftSection = isDesktop && isMiniX;

  const [sliderValue, setSliderValue] = useState(0);

  const getAmount = (percent) => {
    let amount = 0;

    if (isBuyTab) {
      // for buy orders calculate based on the available market balance
      // for market type orders we calculate without price, amount will be dependent on available balance only
      const price = Number(currentPrice) || 0;

      if (price !== 0) {
        amount = (quoteAvailableBalance / price) * percent;
      }
    } else {
      // for sell orders calculate based on the available quote balance
      amount = marketAvailableBalance * percent;
    }

    return format(amount, {
      precision: currentMarket?.market_precision_format,
      noCommas: true,
      removeZeroEnd: true,
    });
  };

  useEffect(() => {
    const marksMap = Object.keys(QUANTITY_MARKS);

    const matchedMark = Number(
      marksMap.find((mark) => getAmount(mark) === absoluteValue) || marksMap[0],
    );

    setSliderValue(matchedMark);
  }, [isBuyTab, currentPrice, absoluteValue]);

  const handleChangeAmountPercent = (value) => {
    setSliderValue(value);

    onChange(getAmount(value));
  };

  return (
    <FieldContainer
      label={
        <span>
          <FormattedMessage id="Quantity" />:
        </span>
      }
      forceShowLeftSection={isForceShowLeftSection}
      layoutItemRef={layoutItemRef}
    >
      <Slider
        withoutMarks={isSliderWithoutMarks}
        withoutLine={isSliderWithoutLine}
        value={sliderValue}
        marks={QUANTITY_MARKS}
        onChange={handleChangeAmountPercent}
        max={1}
        min={0}
      />
    </FieldContainer>
  );
};

export default memo(FormSlider);
