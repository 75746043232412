import { memo } from 'react';

import TruncatedText from '@shared/components/TruncatedText/TruncatedText';
import { ProgressBar } from '@shared/ui';

import s from './OrdersFilledPercent.module.scss';

const OrdersFilledPercent = ({ percent, isShowProgress = true }) => {
  if (isShowProgress) {
    return <ProgressBar percentages={percent} customStyles={s} />;
  }

  return (
    <TruncatedText>
      <span className="fw-600">{percent}%</span>
    </TruncatedText>
  );
};

export default memo(OrdersFilledPercent);
