import { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import { Card, UserAvatar } from '@shared/components';
import { Button, InputController, Loader } from '@shared/ui';

import Breakpoint from '@components/Breakpoint';

import { PROFILE_NICKNAME_MAX_LENGTH } from '@pages/AccountPage/account.constants';

import {
  useGetProfileInfoQueryResult,
  useProfileMutations,
} from '@store/api/hooks';
import { userIdSelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal';

import { modalsIds } from '@constants';

import styles from './ProfileInfoCard.module.scss';

const ProfileInfoCard = () => {
  const [isNicknameEditable, setIsNicknameEditable] = useState(false);

  const intl = useIntl();

  const userId = useSelector(userIdSelector);

  const { onChangeNickname, isChangeNicknameLoading } = useProfileMutations();

  const { userNickname, isLoading } = useGetProfileInfoQueryResult();

  const { isMobile, isTabletDown } = useBreakpoints();

  const defaultUserNickname = intl.formatMessage(
    { id: 'AnonymousUser' },
    {
      userId,
    },
  );

  const methods = useForm({
    defaultValues: {
      nickname: userNickname ?? defaultUserNickname,
    },
  });

  const { handleSubmit, setFocus, setValue } = methods;

  const { openModal } = useModal(modalsIds.UPDATE_AVATAR_MODAL);

  useEffect(() => {
    if (isNicknameEditable) {
      setFocus('nickname');
      setValue('nickname', '');
    }
  }, [isNicknameEditable]);

  useEffect(() => {
    setValue('nickname', userNickname || defaultUserNickname);
  }, [defaultUserNickname, userNickname]);

  const handleUpdateAvatarClick = () => {
    openModal();
  };

  const handleEditNickname = () => {
    setIsNicknameEditable(true);
  };

  const handleFormSubmit = ({ nickname }) => {
    onChangeNickname({ nickname });

    setIsNicknameEditable(false);
  };

  const userAvatarWidth = isTabletDown ? 48 : 96;
  const userAvatarHeight = isTabletDown ? 48 : 96;

  const nicknameInstructions = (
    <p className={styles.instructions}>
      <FormattedMessage id="ProfileAvatarInstructions" />
    </p>
  );

  return (
    <Card
      title={<FormattedMessage id="PersonalInfo" />}
      classNames={[styles.personal_info, styles.card]}
    >
      <div className={styles.user_info}>
        {isLoading ? (
          <Loader color="dark" size="xxl" />
        ) : (
          <div className="d-flex align-items-start gap-32">
            <div className="position-relative mb-32">
              <UserAvatar width={userAvatarWidth} height={userAvatarHeight} />

              <div className={styles.circle_button}>
                <Button
                  onClick={handleUpdateAvatarClick}
                  size={isMobile ? 's' : 'm'}
                  isCircle
                  customStyles={styles}
                >
                  +
                </Button>
              </div>
            </div>

            <Breakpoint size="xl" direction="down">
              {nicknameInstructions}
            </Breakpoint>
          </div>
        )}

        <div className={styles.nickname_field}>
          <div className={styles.desktop_nickname_instructions}>
            {nicknameInstructions}
          </div>
          <FormattedMessage id="Nickname" />:
          <div className="d-md-flex justify-content-between gap-20">
            {isLoading ? (
              <Loader color="dark" size="s" />
            ) : (
              <div
                className={`w-100 ${
                  isNicknameEditable ? 'mb-10' : 'mb-16'
                } mb-md-0`}
              >
                <InputController
                  name="nickname"
                  rules={{
                    required: intl.formatMessage({
                      id: 'NicknameIsRequiredField',
                    }),
                  }}
                  maxLength={isNicknameEditable && PROFILE_NICKNAME_MAX_LENGTH}
                  autoComplete="off"
                  disabled={!isNicknameEditable}
                  placeholder={intl.formatMessage({ id: 'ChangeNickname' })}
                  bordered={false}
                  underlined
                  size="m"
                  customStyles={styles}
                  {...methods}
                />
              </div>
            )}

            <Button
              onClick={
                isNicknameEditable
                  ? handleSubmit(handleFormSubmit)
                  : handleEditNickname
              }
              isLoading={isChangeNicknameLoading}
              fill={isMobile}
            >
              <FormattedMessage id={isNicknameEditable ? 'Save' : 'Change'} />
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(ProfileInfoCard);
