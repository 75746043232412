import React, { memo } from 'react';

import classNames from 'classnames';

import Modal from '@components/modals/Modal';

import styles from './AccountModal.module.scss';

const AccountModal = ({ isOpen, children, className, ...rest }) => {
  return (
    <Modal
      className={classNames(styles.modal, className)}
      visible={isOpen}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default memo(AccountModal);
