import { memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';

import { SideBarInfoPoint } from '@shared/components';
import FormActions from '@shared/components/FormActions/FormActions';
import {
  Button,
  CheckboxController,
  InputController,
  SelectController,
} from '@shared/ui';

import SideBar from '@components/SideBar';

import { isIBANValid } from '@pages/WalletsPage';
import { useGetClearJunctionWithdrawFieldsByTransferType } from '@pages/WalletsPage/hooks';
import {
  bankTransferTypeSelector,
  checkoutWithdrawDataSelector,
  withdrawCurrencySymbolSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';
import { walletWithdrawActions } from '@pages/WalletsPage/Withdraw/Withdraw.slice';

import { isAccountThemeSelector } from '@store/settings/settingsSelectors';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import { SERVICE_ROUTES } from '../../../../../routes.constants';

import styles from './SideBarWithdrawBankTransfer.module.scss';

const SideBarWithdrawBankTransfer = () => {
  const intl = useIntl();

  const { amount } = useSelector(checkoutWithdrawDataSelector);
  const selectedCurrency = useSelector(withdrawCurrencySymbolSelector);
  const bankTransferType = useSelector(bankTransferTypeSelector);
  const isAccountTheme = useSelector(isAccountThemeSelector);

  const dispatch = useDispatch();

  const {
    toggleSidebar: toggleBankTransferSidebar,
    isOpen: isSidebarBankTransferOpen,
  } = useSidebar(sidebarIds.WALLETS_WITHDRAW_BANK_TRANSFER);
  const { toggleSidebar: toggleBankTransferConfirmSidebar } = useSidebar(
    sidebarIds.WALLETS_WITHDRAW_CONFIRM_BANK_TRANSFER,
  );

  const defaultFormValues = {
    transferType: null,
    firstName: '',
    lastName: '',
    terms: false,
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
  });

  const { control, setValue, handleSubmit, reset } = methods;

  const watchTransferType = useWatch({ control, name: 'transferType' });
  const watchFirstName = useWatch({ control, name: 'firstName' });
  const watchLastName = useWatch({ control, name: 'lastName' });

  const {
    transferTypes,
    selectedTransferType,
    requiredFields,
    firstName,
    lastName,
  } = useGetClearJunctionWithdrawFieldsByTransferType(
    {
      selectedMethodId: watchTransferType?.value,
    },
    { skip: !isSidebarBankTransferOpen },
  );

  // set first payment method by default
  useEffect(() => {
    if (!!watchTransferType || isEmpty(transferTypes)) return;

    setValue('transferType', transferTypes[0]);
  }, [watchTransferType, transferTypes]);

  // set first and last names
  useEffect(() => {
    if (!watchFirstName && !!firstName) setValue('firstName', firstName);
    if (!watchLastName && !!lastName) setValue('lastName', lastName);
  }, [watchFirstName, firstName, watchLastName, lastName]);

  useEffect(() => {
    if (!isSidebarBankTransferOpen) {
      reset(defaultFormValues);
    }
  }, [isSidebarBankTransferOpen]);

  useEffect(() => {
    if (!selectedTransferType) return;
    if (selectedTransferType.value === bankTransferType?.value) return;

    dispatch(walletWithdrawActions.setBankTransferType(selectedTransferType));
  }, [selectedTransferType, bankTransferType]);

  const handleFormSubmit = (values) => {
    const bankTransferDetails = requiredFields.map((field) => ({
      id: field.name,
      value: values[field.name],
    }));
    dispatch(walletWithdrawActions.setBankTransferDetails(bankTransferDetails));

    const { firstName, lastName } = values;
    dispatch(walletWithdrawActions.setUserData({ firstName, lastName }));

    toggleBankTransferSidebar();
    toggleBankTransferConfirmSidebar();
  };

  const commonFieldProps = {
    bordered: false,
    underlined: true,
    inversion: true,
    size: 'l',
  };

  return (
    <SideBar
      open={isSidebarBankTransferOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={toggleBankTransferSidebar}
      withCloseButton
      title={intl.formatMessage({ id: 'BankTransfer' })}
    >
      <p>
        <FormattedMessage
          id="BankTransferSummaryMessage"
          values={{ amount: `${amount} ${selectedCurrency?.toUpperCase()}` }}
        />
      </p>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <SelectController
          name="transferType"
          rules={{
            required: intl.formatMessage({ id: 'FieldRequired' }),
          }}
          options={transferTypes}
          isSearchable={false}
          {...commonFieldProps}
          {...methods}
        />

        <div className="mt-20">
          <SideBarInfoPoint
            title="Pay attention"
            description="Your First Name and Last Name must be the same as in the KYC"
          />
        </div>

        <div className={styles.fullname_label}>
          {firstName} {lastName}
        </div>

        <div className={styles.form_fields}>
          <div className="container-fluid p-0">
            <div className="row gx-36">
              <div className="col-6">
                <InputController
                  name="firstName"
                  placeholder={intl.formatMessage({ id: 'FirstName' })}
                  label={intl.formatMessage({ id: 'FirstName' })}
                  rules={{
                    required: intl.formatMessage({
                      id: 'FirstNameIsARequiredField',
                    }),
                  }}
                  disabled
                  {...commonFieldProps}
                  {...methods}
                />
              </div>

              <div className="col-6">
                <InputController
                  name="lastName"
                  placeholder={intl.formatMessage({ id: 'LastName' })}
                  label={intl.formatMessage({ id: 'LastName' })}
                  rules={{
                    required: intl.formatMessage({
                      id: 'LastNameIsARequiredField',
                    }),
                  }}
                  disabled
                  {...commonFieldProps}
                  {...methods}
                />
              </div>

              {requiredFields.map((field) => (
                <div
                  className={cx('mt-20', {
                    'col-12': field.isFullFieldWidth,
                    'col-6': !field.isFullFieldWidth,
                  })}
                  key={field.id}
                >
                  <InputController
                    name={field.name}
                    placeholder={intl.formatMessage({ id: field.name })}
                    label={intl.formatMessage({ id: field.name })}
                    rules={{
                      required: intl.formatMessage({ id: 'FieldRequired' }),
                      validate: {
                        isIBANValid:
                          field.name === 'iban'
                            ? isIBANValid(intl)
                            : () => true,
                      },
                    }}
                    autoComplete="off"
                    {...commonFieldProps}
                    {...methods}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="mt-36">
            <CheckboxController
              name="terms"
              label={
                <FormattedMessage
                  id="IReadAndAgreeWithTermsAndConditions"
                  values={{
                    link: (str) => (
                      <Button
                        type="button"
                        variant="text"
                        navigation={{ to: SERVICE_ROUTES.TERM }}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="xxs"
                        inversion={isAccountTheme}
                        customStyles={styles}
                      >
                        {str}
                      </Button>
                    ),
                  }}
                />
              }
              rules={{
                required: intl.formatMessage({ id: 'IAcceptTheTerms' }),
              }}
              customStyles={styles}
              inversion={isAccountTheme}
              {...methods}
            />

            <div className="mt-36">
              <FormActions
                submitText={<FormattedMessage id="Continue" />}
                inversion
                onCancel={toggleBankTransferSidebar}
                customStyles={{ styles }}
              />
            </div>
          </div>
        </div>
      </form>
    </SideBar>
  );
};

export default memo(SideBarWithdrawBankTransfer);
