import { matchRoutes, useLocation } from 'react-router';

const findMatch = ({ routes, pathname, isObjectKeys }) => {
  const routeMapper = (route) => {
    return { path: route };
  };

  const routePaths = isObjectKeys
    ? Object.keys(routes).map(routeMapper)
    : Object.values(routes).map(routeMapper);

  const matchedRoutes = matchRoutes(routePaths, pathname);

  const matchedRoute = matchedRoutes?.[0].route.path;

  return { foundRoute: matchedRoute };
};

const useMatchedRoutes = ({
  routes,
  isObjectKeys = false,
  isNested = false,
}) => {
  const { pathname } = useLocation();

  let matchedRoute = null;

  if (isNested) {
    matchedRoute = Object.values(routes).reduce((route, nestedRoutes) => {
      const { foundRoute } = findMatch({
        isObjectKeys,
        pathname,
        routes: nestedRoutes,
      });

      if (foundRoute) {
        return foundRoute;
      }

      return route;
    }, null);
  } else {
    const { foundRoute } = findMatch({
      isObjectKeys,
      pathname,
      routes,
    });

    matchedRoute = foundRoute;
  }

  return { matchedRoute, isMatchedRoute: Boolean(matchedRoute) };
};

export default useMatchedRoutes;
