import { memo, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import Collapse from 'rc-collapse';

import { Button } from '@shared/ui';
import { collapseMotion } from '@shared/utils';

import { NOOP } from '@constants';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import styles from './CardDetails.module.scss';

const DEFAULT_ACTIVE_KEY = '0';

const CardDetails = ({
  children,
  title = <FormattedMessage id="MoreDetails" />,
  expandedTitle = <FormattedMessage id="LessDetails" />,
  position = 'bottom',
  isExpandedByDefault = false,
  onChange = NOOP,
}) => {
  const [activeKey, setActiveKey] = useState(
    isExpandedByDefault ? [DEFAULT_ACTIVE_KEY] : [],
  );

  const isExpanded = activeKey.includes(DEFAULT_ACTIVE_KEY);

  useEffect(() => {
    onChange(isExpanded);
  }, [isExpanded, onChange]);

  const isBottomPosition = position === 'bottom';

  const collapseItems = useMemo(() => {
    return [
      {
        label: (
          <Button inversion variant="text">
            {isExpanded ? expandedTitle : title}
          </Button>
        ),
        children,
      },
    ];
  }, [isExpanded]);

  return (
    <div
      className={classNames(
        isBottomPosition ? styles.card_details : styles.card_details_top,
      )}
    >
      <Collapse
        onChange={setActiveKey}
        activeKey={activeKey}
        expandIcon={({ isActive }) => (
          <DownIconSVG
            className={classNames(styles.chevron, isActive && styles.expanded)}
            height={7}
            width={14}
          />
        )}
        openMotion={collapseMotion}
        items={collapseItems}
      />
    </div>
  );
};

export default memo(CardDetails);
