import { useIntl } from 'react-intl';

import { ButtonGroup } from '@shared/components';
import {
  RANGE_BUTTONS_IDS,
  timeRangeButtons,
} from '@shared/components/DatePicker/datePicker.constants';

import useBreakpoints from '@hooks/useBreakpoints';

import { NOOP } from '@constants';

import s from './TimeRangeButtons.module.scss';

const TimeRangeButtons = ({
  handleClickRange = NOOP,
  isButtonsGroupActive,
  isCustomRangeButtonDisplayed,
}) => {
  const intl = useIntl();

  const { isMobile } = useBreakpoints();

  const items = timeRangeButtons(intl, isCustomRangeButtonDisplayed);

  const selectedItemId = items.find(
    (item) => item.id === RANGE_BUTTONS_IDS.THIRTY_DAYS,
  );

  return (
    <ButtonGroup
      customStyles={s}
      items={items}
      valueKey="valueLong"
      onClick={handleClickRange}
      selectedItemId={isButtonsGroupActive ? selectedItemId : undefined}
      color="tertiary"
      size="s"
    >
      {(_, item, isSelected) => {
        const value =
          isMobile && !isSelected ? item.valueShort : item.valueLong;

        return (
          <div className="d-flex align-items-center gap-6">
            {item.icon} {value}
          </div>
        );
      }}
    </ButtonGroup>
  );
};

export default TimeRangeButtons;
