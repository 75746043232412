import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

import { getFormData } from '@utils/api';

export const supportAPI = createApi({
  reducerPath: 'supportAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    submitOpportunity: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: 'support/opportunity',
        body: getFormData(body),
      }),
    }),
  }),
});

export const { useSubmitOpportunityMutation } = supportAPI;
