import React, { createElement, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import cx from 'classnames';

import { Loader } from '@shared/ui';

import Tooltip from '@components/Tooltip/Tooltip';

import styles from './Button.module.scss';

const Button = forwardRef(
  (
    {
      children,
      variant = 'contained', // 'outlined' | 'text'
      shape = 'rounded', // 'square'
      color = 'primary', // 'secondary' | 'tertiary' | 'quaternary' | 'green' | 'yellow' | 'dangerous'
      size = 'm', // 'xxs' | 'xs' | 's' | 'l' | 'xl' | 'xxl'
      fill,
      active,
      inversion,
      tooltipConfig,
      isCircle,
      isLoading,
      navigation = { to: null, state: null },
      customStyles = {},
      ...props
    },
    ref,
  ) => {
    const isLink = !!navigation.to;

    let componentProps = {
      className: cx(
        styles.button,
        styles[variant],
        styles[shape],
        styles[color],
        styles[size],
        customStyles.button,
        {
          [styles.circle_button]: isCircle,
          [styles.inversion]: inversion,
          [styles.fill]: fill,
          [styles.active]: active,
          [customStyles.button__active]: active,
        },
      ),
      ref,
      ...props,
    };

    if (isLink) {
      componentProps = {
        to: navigation.to,
        ...componentProps,
      };

      if (navigation.state) {
        componentProps = {
          state: navigation.state,
          ...componentProps,
        };
      }
    }

    const Component = createElement(
      isLink ? Link : 'button',
      componentProps,
      <>
        {isLoading && (
          <Loader
            size="xs"
            color={color === 'secondary' ? 'dark' : 'green'}
            customStyles={styles}
          />
        )}
        {children}
      </>,
    );

    if (tooltipConfig && !props.disabled) {
      return (
        <Tooltip
          {...tooltipConfig}
          className={cx(tooltipConfig.className, { 'w-100': fill })}
        >
          {Component}
        </Tooltip>
      );
    }

    return Component;
  },
);

export default Button;
