import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ItemTitle } from '@shared/components';

import { useChartData, useTotalEarned } from '@pages/AccountPage/hooks';
import { ActionButton } from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/ActionButton';
import { InstantBonusTable } from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/InstantBonus/components';
import { OverviewEarnWidget } from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/OverviewEarnWidget/OverviewEarnWidget';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { WALLETS_ROUTES } from 'src/routes.constants';

import mockData from 'src/mock.json';

const InstantBonus = () => {
  const { chartValue, coin } = mockData?.InstantBonus;
  const { chartData, color, value } = useChartData(chartValue);

  const { currency, totalEarnedInBTC, totalEarnedInSelectedCurrency } =
    useTotalEarned();

  const { symbol, value: selectedUserCurrency } = useSelector(currencySelector);

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: currency,
    toCoinSymbol: selectedUserCurrency,
  });

  return (
    <>
      <ItemTitle
        title={<FormattedMessage id="InstantBonus" />}
        arrowTitle={<FormattedMessage id="Details" />}
        navigation={{ to: WALLETS_ROUTES.INSTANT_BONUS }}
      />

      <OverviewEarnWidget
        percentChange={value}
        title={<FormattedMessage id="TotalEarned" />}
        color={color}
        chartData={chartData}
        value={totalEarnedInBTC}
        valueCurrency={coin}
        totalValue={totalEarnedInSelectedCurrency * ratesByPair}
        totalValueCurrency={selectedUserCurrency}
        totalValueSymbol={symbol}
      />

      <InstantBonusTable />

      <ActionButton navigation={{ to: WALLETS_ROUTES.TRADING_BOTS_START }}>
        <FormattedMessage id="AddNewContract" />
      </ActionButton>
    </>
  );
};

export default memo(InstantBonus);
