import React, { memo } from 'react';

import { components } from 'react-select';

import styles from './CustomGroupHeading.module.scss';

const { GroupHeading } = components;

const CustomGroupHeading = ({ children, ...props }) => {
  return (
    <GroupHeading
      className={
        props.data.isDivider || !children ? undefined : styles.group_heading
      }
      {...props}
    >
      {props.data.isDivider ? <hr className="m-0" /> : children}
    </GroupHeading>
  );
};

export default memo(CustomGroupHeading);
