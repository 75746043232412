import { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { AccountModal } from '@shared/components';
import { GOOGLE_OTP_NUMBER_LENGTH } from '@shared/constants';

import { GoogleTwoFAForm, SMSTwoFAForm } from '@components/form/twoFAforms';
import { AuthLayoutSubtitle } from '@components/layouts/AuthLayout';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';
import { usePostSendSmsMutation } from '@store/api/profileAPI';
import { userEmailSelector } from '@store/settings/settingsSelectors';

import useModal from '@hooks/useModal/useModal';

import { NOOP, modalsIds } from '@constants';

const TwoFAModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.TWO_FA_MODAL,
  );

  const { onSubmit = NOOP } = modal.params;

  const [isLoading, setIsLoading] = useState(false);

  const userEmail = useSelector(userEmailSelector);

  const { isGoogleAuthActive, isSMSAuthActive } = useGetProfileInfoQueryResult(
    undefined,
    {
      skip: !isOpen,
    },
  );

  const [sendSMS] = usePostSendSmsMutation();

  useEffect(() => {
    if (!isGoogleAuthActive && isSMSAuthActive && isOpen) {
      sendSMS();
    }
  }, [isGoogleAuthActive, isSMSAuthActive, isOpen]);

  const handleSubmit = ({ OTPCode }) => {
    setIsLoading(true);

    onSubmit({ OTPCode }).finally(() => {
      setIsLoading(false);

      closeModal();
    });
  };

  const handleAfterOpen = () => {
    const firstOtpInput = document.querySelector('#otp-code-input');

    if (firstOtpInput) {
      firstOtpInput.focus();
    }
  };

  return (
    <AccountModal
      title={<FormattedMessage id="2FA" />}
      isOpen={isOpen}
      onClose={closeModal}
      afterClose={resetModal}
      afterOpen={handleAfterOpen}
    >
      {isGoogleAuthActive ? (
        <>
          <AuthLayoutSubtitle>
            <FormattedMessage
              id="VerificationCodeFromGoogle"
              values={{ number: GOOGLE_OTP_NUMBER_LENGTH }}
            />{' '}
            <FormattedMessage id="Authentication" />
          </AuthLayoutSubtitle>

          <div className="mt-24">
            <GoogleTwoFAForm
              onSubmit={handleSubmit}
              isLoading={isLoading}
              email={userEmail}
            />
          </div>
        </>
      ) : (
        isSMSAuthActive && (
          <>
            <AuthLayoutSubtitle>
              <FormattedMessage id="OTPCode" />
            </AuthLayoutSubtitle>

            <div className="mt-24">
              <SMSTwoFAForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                email={userEmail}
              />
            </div>
          </>
        )
      )}
    </AccountModal>
  );
};

export default memo(TwoFAModal);
