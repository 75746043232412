import React, { memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@components/Button';
import SideBar from '@components/SideBar';

import TradeBlock from '@pages/TerminalPage/components/GridLayout/blocks/Trade';

import { useMarketsInfo } from '@store/api/hooks';
import { marketIdSelector } from '@store/settings/settingsSelectors';

import { ReactComponent as CrossIconSVG } from '@icons/cross-icon.svg';

import styles from './MobileTradingBlock.module.scss';

const MobileTradingBlock = () => {
  const layoutItemRef = useRef();

  const marketId = useSelector(marketIdSelector);
  const { marketInfo } = useMarketsInfo({ marketId });

  const [isBuyTab, setIsBuyTab] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false); // need to reset form on close

  const handleBuyDrawerOpen = () => {
    setIsBuyTab(true);
    setIsShowForm(true);
    setOpenModal(true);
  };

  const handleSellDrawerOpen = () => {
    setIsBuyTab(false);
    setIsShowForm(true);
    setOpenModal(true);
  };

  return (
    <div className={styles.popup_container}>
      <div className="flex-1 d-flex gap-8 p-14">
        <Button lg fill roundedS primary onClick={handleBuyDrawerOpen}>
          <FormattedMessage id="Buy" />

          <span className="text-uppercase ms-4">
            {marketInfo?.market_coin_symbol}
          </span>
        </Button>

        <Button lg fill roundedS dangerous onClick={handleSellDrawerOpen}>
          <FormattedMessage id="Sell" />

          <span className="text-uppercase ms-4">
            {marketInfo?.market_coin_symbol}
          </span>
        </Button>
      </div>

      <SideBar
        customStyles={styles}
        open={openModal}
        placement="bottom"
        width="100%"
        withCloseButton={false}
        handler={false}
        level={null}
        onClose={() => setOpenModal(false)}
        afterVisibleChange={(isOpen) => {
          if (!isOpen) {
            setIsShowForm(false);
          }
        }}
      >
        <div className={styles.block_header} ref={layoutItemRef}>
          <Button
            className={styles.close_button}
            onClick={() => setOpenModal(false)}
          >
            <CrossIconSVG />
          </Button>
        </div>

        {isShowForm && (
          <TradeBlock
            i="mobile-trading-block"
            layoutItemRef={layoutItemRef}
            isBuyTab={isBuyTab}
          />
        )}
      </SideBar>
    </div>
  );
};

export default memo(MobileTradingBlock);
