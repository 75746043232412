import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  AccountModal,
  FORM_ACTION_ORIENTATION,
  FormActions,
} from '@shared/components';

import useModal from '@hooks/useModal/useModal';

import { NOOP, modalsIds } from '@constants';

import styles from './ConfirmModal.module.scss';

const ConfirmModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.CONFIRM_MODAL,
  );

  const { modalMessage, onSubmit = NOOP } = modal.params;

  const handleSubmit = () => {
    closeModal();

    onSubmit();
  };

  return (
    <AccountModal
      title={<FormattedMessage id="Confirmation" />}
      isOpen={isOpen}
      onClose={closeModal}
      afterClose={resetModal}
    >
      <div className={styles.description}>
        <FormattedMessage id={modalMessage} />
      </div>

      <FormActions
        submitText={<FormattedMessage id="YesConfirm" />}
        submitButtonProps={{
          type: 'button',
        }}
        onSubmit={handleSubmit}
        cancelButtonProps={{
          variant: 'text',
        }}
        onCancel={closeModal}
        orientation={FORM_ACTION_ORIENTATION.VERTICAL}
      />
    </AccountModal>
  );
};

export default memo(ConfirmModal);
