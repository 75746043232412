import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';

import { capitalizeFirstLetter } from '@shared/utils';

import { ApiSelectorsUtils } from '../utils';

const defaultData = {};

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const discountLevelSelector = createSelector(
  dataSelector,
  (capitalizeDiscountLevel) => capitalizeDiscountLevel,
  (data, capitalizeDiscountLevel) => {
    const level = data.discount_level ?? 'none';

    return capitalizeDiscountLevel ? capitalizeFirstLetter(level) : level;
  },
);

const tradingFeeSelector = createSelector(dataSelector, (data) => {
  const makerFeeBN = new BigNumber(data.maker_fee);

  return makerFeeBN.multipliedBy(100).toNumber();
});

export const FeesApiSelectors = {
  dataSelector,
  discountLevelSelector,
  tradingFeeSelector,
};
