import { useSelector } from 'react-redux';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';
import { userEmailSelector } from '@store/settings/settingsSelectors';

const useUserAuthBy = (args, options) => {
  const { userPhone } = useGetProfileInfoQueryResult(undefined, options);

  const userEmail = useSelector(userEmailSelector);

  return {
    isAuthByPhone: Boolean(userPhone),
    userAuthByValue: userPhone || userEmail,
  };
};

export default useUserAuthBy;
