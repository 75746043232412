import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '@shared/components';

import Switch from '@components/Switch/Switch';

import styles from './SubscribeToUpdates.module.scss';

const SubscribeToUpdates = () => {
  return (
    <Card
      customStyles={styles}
      title={<FormattedMessage id="SubscribeToUpdates" />}
    >
      <div className={styles.content}>
        <Switch customStyles={styles} />

        <div className={styles.description}>
          <FormattedMessage id="IAgreeToReceiveEmails" />
        </div>
      </div>
    </Card>
  );
};

export default memo(SubscribeToUpdates);
