import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { ACCOUNT_GROUP, MAIN_ACCOUNT_ID } from '@shared/constants';

import { format } from '@utils/numbers';

import { DEFAULT_PRECISION } from '@constants';

import { ApiSelectorsUtils } from '../utils';

const defaultData = {};

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const balancesByAccountIdSelector = createSelector(
  (balances) => balances,
  (balances, accountId) => accountId,
  (balances, accountId) => {
    const balanceAccountId =
      accountId === MAIN_ACCOUNT_ID ? ACCOUNT_GROUP.MAIN : accountId;

    const accountBalances = balances[balanceAccountId];

    return accountBalances;
  },
);

const accountBalancesMapByIsHideZeroTotalBalancesSelector = createSelector(
  (accountBalancesMap) => accountBalancesMap,
  (accountBalancesMap) => {
    return accountBalancesMap.filter(({ total_balance }) => total_balance > 0);
  },
);

const accountBalancesMapOrderByTotalBalanceDirectionSelector = createSelector(
  (accountBalancesMap) => accountBalancesMap,
  (accountBalancesMap, orderByTotalBalanceDirection) =>
    orderByTotalBalanceDirection,
  (accountBalancesMap, orderByTotalBalanceDirection) => {
    return orderBy(
      accountBalancesMap,
      (balance) => Number.parseFloat(balance.total_balance),
      orderByTotalBalanceDirection,
    );
  },
);

const accountBalancesMapFilterByTypeSelector = createSelector(
  (accountBalancesMap) => accountBalancesMap,
  (accountBalancesMap, type) => type,
  (accountBalancesMap, type) => {
    return accountBalancesMap.filter(
      (balance) => balance.coinInfo.type === type,
    );
  },
);

const coinBalanceFromAccountBalancesMapSelector = createSelector(
  (accountBalancesMap) => accountBalancesMap,
  (accountBalancesMap, coinSymbol) => coinSymbol?.toLowerCase(),
  (accountBalancesMap, coinSymbol) => {
    return accountBalancesMap.find(({ symbol }) => symbol === coinSymbol);
  },
);

const coinWithdrawDepositBalanceTableSelector = createSelector(
  ({ data }) => data?.[ACCOUNT_GROUP.MAIN],
  (mainBalances, coins) => coins,
  (mainBalances, coins, coinSymbol) => coinSymbol.toLowerCase(),
  (mainBalances, coins, coinSymbol, withdrawLimits) => withdrawLimits,
  (mainBalances, coins, coinSymbol, withdrawLimits) => {
    const defaultValue = 0;
    const selectedCoinInfo = coins.find((coin) => coin.symbol === coinSymbol);
    const precision = selectedCoinInfo?.digits ?? DEFAULT_PRECISION;
    const userWithdrawLimit = withdrawLimits?.userWithdrawLimit ?? defaultValue;
    const alreadyWithdrawn = withdrawLimits?.alreadyWithdrawn ?? defaultValue;

    const coinBalance = mainBalances?.[coinSymbol];

    const total = format(coinBalance?.total_balance ?? defaultValue, {
      precision,
    });

    const inOrders = format(coinBalance?.in_orders ?? defaultValue, {
      precision,
    });

    const available = `${format(coinBalance?.available ?? defaultValue, {
      precision,
    })}`;

    const withdrawLimitRange = `${format(alreadyWithdrawn, {
      precision,
    })}/${format(userWithdrawLimit, {
      precision,
    })}`;

    const total24HSend = format(alreadyWithdrawn, {
      precision,
    });

    const withdraw24HLimit = format(userWithdrawLimit, {
      precision,
    });

    return {
      total,
      available,
      inOrders,
      withdrawLimitRange,
      total24HSend,
      withdraw24HLimit,
    };
  },
);

export const BalancesAPISelectors = {
  dataSelector,
  balancesByAccountIdSelector,
  accountBalancesMapByIsHideZeroTotalBalancesSelector,
  accountBalancesMapOrderByTotalBalanceDirectionSelector,
  accountBalancesMapFilterByTypeSelector,
  coinBalanceFromAccountBalancesMapSelector,
  coinWithdrawDepositBalanceTableSelector,
};
