import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';

import { ColumnHeaderItem, FormattedCurrency } from '@shared/components';

import { PercentChange } from '../../PercentChange';

import { VISA_PROFIT_TYPE } from '../visaCard.constants';

const { accessor } = createColumnHelper();

const visaCardTableColumns = ({ selectedCurrency, selectedCurrencySymbol }) => [
  accessor('profitType', {
    header: () => <ColumnHeaderItem id="ProfitType" />,
    cell: (info) => {
      const profitType = info.getValue();

      return (
        <span className="fs-14 fw-600">
          <FormattedMessage id={VISA_PROFIT_TYPE[profitType]} />
        </span>
      );
    },

    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
    enableTruncating: true,
  }),
  accessor('profit', {
    header: () => <ColumnHeaderItem id="Profit" />,
    cell: (info) => {
      const profit = info.getValue();

      return (
        <span className="fw-700 fs-14">
          <FormattedCurrency value={profit} currency={selectedCurrency} />{' '}
          {selectedCurrencySymbol}
        </span>
      );
    },
    maxSize: 30,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
    enableTruncating: false,
  }),
  accessor('lastDayChange', {
    header: () => <ColumnHeaderItem id="LastDayChange" />,
    cell: (info) => {
      const growth = info.getValue();

      return <PercentChange value={growth} />;
    },
    maxSize: 50,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
    enableTruncating: false,
  }),
];

export default visaCardTableColumns;
