import { memo } from 'react';

import classNames from 'classnames';

import styles from './InfoItem.module.scss';

const InfoItem = ({
  children,
  title,
  strong,
  titleClassName,
  isInline,
  customStyles = {},
}) => {
  return (
    <div
      className={classNames(
        'info-item',
        isInline && 'd-flex',
        customStyles.info_item,
      )}
    >
      {title && (
        <div
          className={classNames(
            styles.title,
            strong ? styles.strong : null,
            'info-item-title',
            titleClassName,
            customStyles.title,
          )}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

export default memo(InfoItem);
