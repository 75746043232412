import React, { memo } from 'react';

import { components } from 'react-select';

import Breakpoint from '@components/Breakpoint';
import Loader from '@components/Loader';

import { ReactComponent as SubaccountsSVG } from '@icons/subaccounts.svg';

import styles from './SelectedAccountItem.module.scss';

const { SingleValue } = components;

const SelectedAccountItem = ({ isLoading = false, ...rest }) => {
  return (
    <SingleValue {...rest}>
      <div className={styles.single_value}>
        {isLoading ? (
          <Loader xs />
        ) : (
          <SubaccountsSVG className={styles.subaccounts_icon} />
        )}

        <Breakpoint size="xl" direction="up">
          <div className={styles.label}>{rest.data.label}</div>
        </Breakpoint>
      </div>
    </SingleValue>
  );
};

export default memo(SelectedAccountItem);
