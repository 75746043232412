import React, { memo } from 'react';
import { useNavigate } from 'react-router';

import classNames from 'classnames';

import styles from './IdItem.module.scss';

const IdItem = ({ id, navigateUrl = '' }) => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    if (navigateUrl) {
      navigate(navigateUrl);
    }
  };

  return (
    <span
      className={classNames(styles.id, { 'cursor-pointer': navigateUrl })}
      onClick={handleLinkClick}
    >
      {id}
    </span>
  );
};

export default memo(IdItem);
