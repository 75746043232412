import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import cx from 'classnames';

import { Badge, MenuNavigation } from '@shared/components';
import { Button } from '@shared/ui';

import Breakpoint from '@components/Breakpoint';
import SideBarLocalization from '@components/layouts/MainLayout/components/PrimaryHeader/components/SideBarLocalization';
import SideBar from '@components/SideBar';

import {
  isAccountThemeSelector,
  isAuthorizedUserSelector,
} from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import { ReactComponent as BurgerIconSVG } from '@icons/burger-icon.svg';

import {
  AUTH_ROUTES,
  SERVICE_ROUTES,
  SOCIAL_MEDIA_LINKS,
} from 'src/routes.constants';

import s from './SideBarNavigation.module.scss';
import mockData from 'src/mock.json';

const { announcementsTotalUnread, blogPostsAmount } =
  mockData.SideBarNavigation;

const SideBarNavigation = () => {
  const isAccountTheme = useSelector(isAccountThemeSelector);
  const isAuthorizedUser = useSelector(isAuthorizedUserSelector);

  const intl = useIntl();
  const { isMobile, isTablet, isTabletDown } = useBreakpoints();
  const { toggleSidebar, closeSidebar, isOpen } = useSidebar(
    sidebarIds.NAVIGATION,
  );

  const legacyLinks = [
    {
      title: <FormattedMessage id="AML/KYC" />,
      link: SERVICE_ROUTES.KYC,
    },
    {
      title: <FormattedMessage id="GDPR" />,
      link: SERVICE_ROUTES.GDPR,
    },
    {
      title: <FormattedMessage id="TermsAndConditions" />,
      link: SERVICE_ROUTES.TERM,
    },
    {
      title: <FormattedMessage id="PrivacyPolicy" />,
      link: SERVICE_ROUTES.POLICY,
    },
    {
      title: <FormattedMessage id="RiskDisclosure" />,
      link: SERVICE_ROUTES.RISK_DISCLOSURE,
    },
    {
      title: <FormattedMessage id="APILicenseAgreement" />,
      link: SERVICE_ROUTES.LICENSE_AGREEMENT,
    },
  ];

  const socialLinks = [
    {
      title: <FormattedMessage id="Facebook" />,
      link: SOCIAL_MEDIA_LINKS.FACEBOOK,
    },
    {
      title: <FormattedMessage id="Twitter" />,
      link: SOCIAL_MEDIA_LINKS.TWITTER,
    },
    {
      title: <FormattedMessage id="Instagram" />,
      link: SOCIAL_MEDIA_LINKS.INSTAGRAM,
    },
    {
      title: <FormattedMessage id="Medium" />,
      link: SOCIAL_MEDIA_LINKS.MEDIUM,
    },
    {
      title: <FormattedMessage id="Telegram" />,
      link: SOCIAL_MEDIA_LINKS.TELEGRAM,
    },
  ];

  const menuItems = [
    {
      label: intl.formatMessage({ id: 'Announcements' }),
      buttonProps: {
        navigation: { to: SERVICE_ROUTES.ANNOUNCEMENTS },
      },
      collapsible: 'disabled',
      extra: (
        <Badge
          isCircle
          color="dangerous"
          size="xxs"
          variant="contained"
          customStyles={s}
        >
          {announcementsTotalUnread}
        </Badge>
      ),
    },
    {
      label: intl.formatMessage({ id: 'Blog' }),
      buttonProps: {
        target: '_blank',
        navigation: {
          to: 'https://support.paramountdax.com/hc/en-us/categories/360001846399-Media-Announcement',
        },
      },
      collapsible: 'disabled',
      extra: (
        <span className={s.text_opacity}>
          <FormattedMessage id="Post" values={{ itemCount: blogPostsAmount }} />
        </span>
      ),
    },
    {
      label: intl.formatMessage({ id: 'Support' }),
      links: [
        {
          name: intl.formatMessage({ id: 'LiveSupport' }),
          buttonProps: { navigation: { to: SERVICE_ROUTES.LIVE_SUPPORT } },
        },
        {
          name: intl.formatMessage({ id: 'SupportCenter' }),
          buttonProps: { navigation: { to: SERVICE_ROUTES.SUPPORT } },
        },
        {
          name: intl.formatMessage({ id: 'Glossary' }),
          buttonProps: { navigation: { to: SERVICE_ROUTES.GLOSSARY } },
        },
        {
          name: intl.formatMessage({ id: 'Fees' }),
          buttonProps: { navigation: { to: SERVICE_ROUTES.FEES } },
        },
      ],
    },
    {
      label: intl.formatMessage({ id: 'Company' }),
      links: [
        {
          name: intl.formatMessage({ id: 'About' }),
          buttonProps: { navigation: { to: SERVICE_ROUTES.ABOUT_US } },
        },
        {
          name: intl.formatMessage({ id: 'TokenListing' }),
          buttonProps: { navigation: { to: SERVICE_ROUTES.TOKEN_LISTING } },
        },
        {
          name: intl.formatMessage({ id: 'API' }),
          buttonProps: {
            navigation: {
              to:
                process.env.REACT_APP_STAGE === 'production'
                  ? 'https://docs.paramountdax.com/'
                  : 'https://docs-staging.paramountdax.com/',
            },
            target: '_blank',
          },
        },
        {
          name: intl.formatMessage({ id: 'Careers' }),
          buttonProps: { navigation: { to: SERVICE_ROUTES.CAREERS } },
        },
        {
          name: intl.formatMessage({ id: 'ContactUs' }),
          buttonProps: { navigation: { to: SERVICE_ROUTES.CONTACT_US } },
        },
      ],
    },
  ];

  const toggleSideBarNavigation = () => toggleSidebar();
  const closeSideBarNavigation = () => closeSidebar();

  return (
    <>
      <div
        className={cx(s.sidebar_navigation, { [s.active]: isOpen })}
        onClick={toggleSideBarNavigation}
      >
        <BurgerIconSVG className={s.burger_icon} />

        <Breakpoint size="xl" direction="up">
          <span className={s.title}>
            <FormattedMessage id="Menu" />
          </span>
        </Breakpoint>
      </div>

      <SideBar
        className={s.header_navigation_sidebar}
        open={isOpen}
        placement="right"
        handler={false}
        level={null}
        onClose={closeSideBarNavigation}
        withCloseButton={isTablet || isMobile}
        title={(isTablet || isMobile) && <FormattedMessage id="Menu" />}
      >
        <div className={s.sidebar_navigation__content}>
          <div className={s.header_sidebar_main}>
            {(isTablet || isMobile) && (
              <div className={s.header_sidebar_main__menu_for_adaptive}>
                <SideBarLocalization isNested />

                {!isAuthorizedUser && isMobile && (
                  <div className={s.login_sign_container}>
                    <Button
                      customStyles={{ button: 'w-50' }}
                      inversion
                      navigation={{ to: AUTH_ROUTES.LOGIN }}
                      variant="outlined"
                    >
                      <FormattedMessage id="Login" />
                    </Button>

                    <Button
                      customStyles={{ button: 'w-50' }}
                      inversion
                      navigation={{ to: AUTH_ROUTES.REGISTER }}
                      variant="outlined"
                      color="green"
                    >
                      <FormattedMessage id="Signup" />
                    </Button>
                  </div>
                )}
              </div>
            )}

            <MenuNavigation
              items={menuItems}
              onLinkClick={closeSideBarNavigation}
            />
          </div>

          <div className={s.header_sidebar_footer}>
            <div>
              <div className={s.title}>
                <FormattedMessage id="Legacy" />
              </div>

              <div className="d-flex gap-8">
                <div className="flex-1">
                  {legacyLinks.slice(0, 3).map(({ title, link }, index) => (
                    <Button
                      key={`legacy1_${link}_${index}`}
                      variant="text"
                      size={isTabletDown ? 'xs' : 's'}
                      navigation={{ to: link }}
                      inversion={isAccountTheme}
                      customStyles={{ button: [s.link] }}
                      onClick={closeSideBarNavigation}
                    >
                      {title}
                    </Button>
                  ))}
                </div>

                <div className="flex-1">
                  {legacyLinks.slice(3).map(({ title, link }, index) => (
                    <Button
                      key={`legacy2_${link}_${index}`}
                      variant="text"
                      size={isTabletDown ? 'xs' : 's'}
                      navigation={{ to: link }}
                      inversion={isAccountTheme}
                      customStyles={{ button: [s.link] }}
                      onClick={closeSideBarNavigation}
                    >
                      {title}
                    </Button>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div className={s.title}>
                <FormattedMessage id="SocialCommunities" />
              </div>

              <div className={s.social_media}>
                {socialLinks
                  .slice(0, isTabletDown ? 3 : 4)
                  .map(({ title, link }, index) => (
                    <div
                      className="d-flex align-items-center gap-8"
                      key={`social1_${link}_${index}`}
                    >
                      {index !== 0 && <div className={s.dash} />}
                      <Button
                        variant="text"
                        size={isTabletDown ? 'm' : 'l'}
                        navigation={{ to: link }}
                        inversion={isAccountTheme}
                        customStyles={{ button: [s.social_link] }}
                        onClick={closeSideBarNavigation}
                      >
                        {title}
                      </Button>
                    </div>
                  ))}
              </div>

              <div className="mt-16">
                <div className={s.social_media}>
                  {socialLinks
                    .slice(isTabletDown ? 3 : 4)
                    .map(({ title, link }, index) => (
                      <div
                        className="d-flex align-items-center gap-8"
                        key={`social2_${link}_${index}`}
                      >
                        {index !== 0 && <div className={s.dash} />}
                        <Button
                          variant="text"
                          size={isTabletDown ? 'm' : 'l'}
                          navigation={{ to: link }}
                          inversion={isAccountTheme}
                          customStyles={{ button: [s.social_link] }}
                          onClick={closeSideBarNavigation}
                        >
                          {title}
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideBar>
    </>
  );
};

export default SideBarNavigation;
