import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

const useOnClickOutside = (refs, handler, enabled = true) => {
  useEffect(() => {
    const handleEvent = (event) => {
      if (isEmpty(refs)) return;

      const isClickOnSomeRef = refs.some((ref) =>
        ref.current?.contains(event.target),
      );

      if (!isClickOnSomeRef) handler(event);
    };

    if (enabled) {
      document.addEventListener('mousedown', handleEvent);
      document.addEventListener('touchstart', handleEvent);
    }

    return () => {
      document.removeEventListener('mousedown', handleEvent);
      document.removeEventListener('touchstart', handleEvent);
    };
  }, [refs, enabled]);
};

export default useOnClickOutside;
