import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useBonusAccountSettings } from '@store/api/hooks';

import { AvailableContractsSelectors } from '../selectors';

const useSelectedGlobalPeriod = () => {
  const selectedPeriod = useSelector(
    AvailableContractsSelectors.selectedPeriodSelector,
  );

  const { periods } = useBonusAccountSettings();

  const selectedGlobalPeriod = useMemo(
    () => periods.find(({ period }) => period === selectedPeriod),
    [periods, selectedPeriod],
  );

  return {
    selectedGlobalPeriod,
  };
};

export default useSelectedGlobalPeriod;
