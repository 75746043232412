import { memo } from 'react';

import { useCurrencyFormat } from '@shared/hooks';

const FormattedCurrency = ({ currency, value, formatOptions = {} }) => {
  const { formattedCurrency } = useCurrencyFormat(
    currency,
    value,
    formatOptions,
  );

  return formattedCurrency;
};

export default memo(FormattedCurrency);
