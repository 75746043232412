import { memo } from 'react';

import TableDefault from '@components/gridTables/TableDefault';
import TableWithFixedColumns from '@components/gridTables/TableWithFixedColumns';

import useBreakpoints from '@hooks/useBreakpoints';

const Table = ({
  isSwipeable = false,
  swipeableTableProps = {},
  extraHeaderComponent,
  ...props
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <>
      {isSwipeable ? (
        <TableWithFixedColumns
          isMinimizedPagination={isMobile}
          extraHeaderComponent={extraHeaderComponent}
          {...props}
          {...swipeableTableProps}
        />
      ) : (
        <TableDefault isMinimizedPagination={isMobile} {...props} />
      )}
    </>
  );
};

export default memo(Table);
