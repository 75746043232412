import { useMemo, useState } from 'react';

import BigNumber from 'bignumber.js';

import { WALLET_WITHDRAW_STATUSES } from '@shared/constants';

import { useCoinsInfo, useGetWithdrawalsQueryResult } from '@store/api/hooks';

import { ITEMS_PER_PAGE } from '@constants';

const useWithdrawHistoryTable = ({ coinType } = {}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });

  const { withdrawals, meta, isSuccess } = useGetWithdrawalsQueryResult(
    {
      type: coinType,
      page: pageIndex + 1,
      limit: pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { coinsInfo } = useCoinsInfo();

  const data = useMemo(
    () => ({
      withdrawalsHistoryData: withdrawals.map((withdrawal) => {
        const coinSymbol = withdrawal.coin_symbol;
        const amountBN = new BigNumber(withdrawal.amount);
        const feeAmountBN = new BigNumber(
          withdrawal.status === WALLET_WITHDRAW_STATUSES.FAILED
            ? 0
            : withdrawal.fee_amount,
        );
        const coinInfo = coinsInfo?.[coinSymbol];
        const precision = coinInfo?.digits;
        const blockChainExplorer = coinInfo?.blockchain_explorer;

        return {
          asset: coinSymbol,
          details: {
            address: withdrawal.to,
            txid: withdrawal.txid,
            txidLink: blockChainExplorer
              ? `${blockChainExplorer}${withdrawal.txid}`
              : undefined,
          },
          amount: {
            amount: amountBN.toNumber(),
            precision,
          },
          feeAmount: {
            feeAmount: feeAmountBN.toNumber(),
            coinSymbol: withdrawal.coin_symbol,
          },
          total: {
            total: amountBN.plus(feeAmountBN).toNumber(),
            precision,
          },
          date: withdrawal.created_at,
          status: withdrawal.status,
        };
      }),
      withdrawalsHistoryMeta: meta,
      isWithdrawalsHistoryTableSuccess: isSuccess,
      setPagination,
    }),
    [withdrawals, coinsInfo, meta, isSuccess],
  );

  return data;
};

export default useWithdrawHistoryTable;
