import { VisaApiSelectors } from '../selectors/visaAPI.selectors';
import { useAvailableCardQuery } from '../visaAPI';

export const useVisaAvailableCards = (options = {}) => {
  const result = useAvailableCardQuery(undefined, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      availableCards: VisaApiSelectors.availableCardsSelector(result),
      availableCardsMap: VisaApiSelectors.availableCardsMappedSelector(result),
    }),
  });

  return result;
};
