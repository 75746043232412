import { memo, useMemo } from 'react';

import { addDays, addMonths, addYears, format } from 'date-fns';

import { DAY_MONTH_YEAR_FORMAT } from '@shared/constants';

import {
  FLEXIBLE,
  HALF_YEAR,
  MONTH,
  QUARTER,
  WEEK,
  WEEK_IN_DAYS,
  YEAR,
} from '@pages/WalletsPage/Wallets.constants';

const FormattedFinishingDate = ({ period }) => {
  const {
    flexiblePeriod,
    halfYearPeriod,
    monthPeriod,
    quarterPeriod,
    weekPeriod,
    yearPeriod,
  } = useMemo(() => {
    const today = new Date();

    const flexiblePeriod = format(today, DAY_MONTH_YEAR_FORMAT);
    const weekPeriod = format(
      addDays(today, WEEK_IN_DAYS),
      DAY_MONTH_YEAR_FORMAT,
    );
    const monthPeriod = format(addMonths(today, 1), DAY_MONTH_YEAR_FORMAT);
    const quarterPeriod = format(addMonths(today, 3), DAY_MONTH_YEAR_FORMAT);
    const halfYearPeriod = format(addMonths(today, 6), DAY_MONTH_YEAR_FORMAT);
    const yearPeriod = format(addYears(today, 1), DAY_MONTH_YEAR_FORMAT);

    return {
      flexiblePeriod,
      weekPeriod,
      monthPeriod,
      quarterPeriod,
      halfYearPeriod,
      yearPeriod,
    };
  }, []);

  const periods = {
    [FLEXIBLE]: flexiblePeriod,
    [WEEK]: weekPeriod,
    [MONTH]: monthPeriod,
    [QUARTER]: quarterPeriod,
    [HALF_YEAR]: halfYearPeriod,
    [YEAR]: yearPeriod,
  };

  return periods[period];
};

export default memo(FormattedFinishingDate);
