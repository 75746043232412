import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CryptoLoansProductIconSVG } from '@icons/crypto-loans-product.svg';
import { ReactComponent as LaunchpadProductIconSVG } from '@icons/launchpad-product.svg';
import { ReactComponent as PRDXTokenProductIconSVG } from '@icons/prdx-token-product-icon.svg';
import { ReactComponent as ReferralProgramProductIconSVG } from '@icons/referral-program-product-icon.svg';
import { ReactComponent as StakingAssetsProductIconSVG } from '@icons/staking-assets-product-icon.svg';
import { ReactComponent as TradingBotsProductIconSVG } from '@icons/trading-bots-product-icon.svg';
import { ReactComponent as VisaProductIconSVG } from '@icons/visa-product-icon.svg';

import {
  MAIN_ROUTES,
  REFERRAL_ROUTES,
  SERVICE_ROUTES,
  WALLETS_ROUTES,
} from '../../../../routes.constants';

const defaultProductSlides = (isMobile, isLargeTabletDown) => {
  const iconDimensions = () => {
    let size = 100;

    if (isMobile) {
      size = 60;
    }

    if (isLargeTabletDown) {
      size = 80;
    }

    return {
      width: size,
      height: size,
    };
  };

  return [
    {
      id: 1,
      icon: <TradingBotsProductIconSVG {...iconDimensions()} />,
      title: <FormattedMessage id="landing.swiper.product1.title" />,
      description: (
        <FormattedMessage id="landing.swiper.product1.description" />
      ),
      backgroundImageName: 'trading-bots.png',
      link: WALLETS_ROUTES.TRADING_BOTS,
    },
    {
      id: 2,
      icon: <VisaProductIconSVG {...iconDimensions()} />,
      isNew: true,
      title: <FormattedMessage id="landing.swiper.product2.title" />,
      description: (
        <FormattedMessage id="landing.swiper.product2.description" />
      ),
      backgroundImageName: 'visa-card.png',
      link: MAIN_ROUTES.CARD,
    },
    {
      id: 3,
      icon: <LaunchpadProductIconSVG {...iconDimensions()} />,
      title: <FormattedMessage id="landing.swiper.product3.title" />,
      description: (
        <FormattedMessage id="landing.swiper.product3.description" />
      ),
      backgroundImageName: 'launchpad.png',
      link: WALLETS_ROUTES.LAUNCHPAD,
    },
    {
      id: 4,
      icon: <CryptoLoansProductIconSVG {...iconDimensions()} />,
      title: <FormattedMessage id="landing.swiper.product4.title" />,
      description: (
        <FormattedMessage id="landing.swiper.product4.description" />
      ),
      isNew: true,
      backgroundImageName: 'crypto-loans.png',
      link: WALLETS_ROUTES.LOAN_CRYPTO,
    },
    {
      id: 5,
      icon: <ReferralProgramProductIconSVG {...iconDimensions()} />,
      title: <FormattedMessage id="landing.swiper.product5.title" />,
      description: (
        <FormattedMessage id="landing.swiper.product5.description" />
      ),
      backgroundImageName: 'referral-program.png',
      link: REFERRAL_ROUTES.OVERVIEW,
    },
    {
      id: 6,
      title: <FormattedMessage id="landing.swiper.product6.title" />,
      description: (
        <FormattedMessage id="landing.swiper.product6.description" />
      ),
      backgroundImageName: 'instants-bonus.png',
      link: WALLETS_ROUTES.INSTANT_BONUS,
    },
    {
      id: 7,
      icon: <PRDXTokenProductIconSVG {...iconDimensions()} />,
      title: <FormattedMessage id="landing.swiper.product7.title" />,
      description: (
        <FormattedMessage id="landing.swiper.product7.description" />
      ),
      backgroundImageName: 'prdx-token.png',
      link: SERVICE_ROUTES.PRDX_TOKEN,
    },
    {
      id: 8,
      icon: <StakingAssetsProductIconSVG {...iconDimensions()} />,
      title: <FormattedMessage id="landing.swiper.product8.title" />,
      description: (
        <FormattedMessage id="landing.swiper.product8.description" />
      ),
      backgroundImageName: 'staking-assets.png',
      link: WALLETS_ROUTES.STAKING,
    },
  ];
};

export default defaultProductSlides;
