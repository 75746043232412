import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../utils';

const defaultTrades = [];
const defaultMarketTrades = { trades: defaultTrades, marketId: '' };
const defaultMeta = { limit: 10, page: 1, count: 0 };

const dataMarketTradesSelector =
  ApiSelectorsUtils.createDataSelector(defaultMarketTrades);

const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const myTradesSelector = ApiSelectorsUtils.createDataSelector(defaultTrades);

const tradesSelector = createSelector(dataMarketTradesSelector, (data) => {
  return data.trades;
});

const marketTradesIdSelector = createSelector(
  dataMarketTradesSelector,
  (data) => {
    return data.marketId;
  },
);

export const TradesApiSelectors = {
  metaSelector,
  tradesSelector,
  myTradesSelector,
  marketTradesIdSelector,
};
