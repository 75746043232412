import { useMemo } from 'react';
import { generatePath } from 'react-router';

import cx from 'classnames';

import { useCurrencyIcon } from '@shared/hooks';

import Link from '@components/Link/Link';

import { HightLightText } from './HightLightText';

import { MARKETS_ROUTES } from 'src/routes.constants';

import styles from './SearchMenuItem.module.scss';

export const SearchMenuItemCoin = ({ itemData, hightLightText, onClick }) => {
  const currencyIcon = useCurrencyIcon(itemData.symbol, {
    width: 20,
    height: 20,
  });

  const isHightLightText = typeof hightLightText === 'string';

  const path = useMemo(
    () =>
      generatePath(MARKETS_ROUTES.DETAILS, {
        currency: itemData.symbol,
      }),
    [itemData.symbol],
  );

  return (
    <Link
      className={cx(styles.item, 'd-flex gap-10 align-items-center')}
      to={path}
      onClick={() => onClick(itemData)}
    >
      {currencyIcon}

      <span className={styles.item_text}>
        {isHightLightText ? (
          <HightLightText
            text={itemData.title}
            hightLightText={hightLightText}
          />
        ) : (
          itemData.title
        )}
      </span>
    </Link>
  );
};
