import { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import styles from './GroupHeading.module.scss';

const GroupHeading = ({ children, selectProps, ...props }) => {
  const isInversion = selectProps.inversion;
  const isDivider = props.data.isDivider;

  return (
    <components.GroupHeading
      className={cx({
        [styles.group_heading]: !isDivider,
        [styles.inversion]: isInversion,
      })}
      {...props}
    >
      {isDivider ? <hr className="m-0" /> : children}
    </components.GroupHeading>
  );
};

export default memo(GroupHeading);
