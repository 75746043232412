import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';
import classnames from 'classnames';

import {
  ColumnHeaderItem,
  OrdersAmount,
  OrdersId,
  OrdersPair,
  OrdersPrice,
  OrdersSide,
  OrdersTotalAmount,
  OrdersType,
  DateStatusItem,
  OrdersFilledPercent,
  OrdersDate,
  OrdersFee,
} from '@shared/components';

import Button from '@components/Button';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

const columnHelper = createColumnHelper();

const orderHistoryColumns = (
  openOrderHistoryTradeDetailsModal,
  isTableWithSwipe,
) => [
  columnHelper.accessor('side', {
    header: () => <ColumnHeaderItem id="Side" />,
    cell: (info) => {
      const { side, isHideSide } = info.getValue();

      return <OrdersSide size={16} side={side} isHideSide={isHideSide} />;
    },
    enableSorting: false,
    maxSize: 30,
    headerAlign: 'start',
    cellAlign: 'start',
  }),
  columnHelper.accessor('pair', {
    header: () => <ColumnHeaderItem id="Pair" />,
    cell: (info) => {
      const pair = info.getValue();

      return <OrdersPair pair={pair} />;
    },
    enableSorting: false,
    headerAlign: 'start',
    cellAlign: 'start',
    maxSize: 50,
  }),
  columnHelper.accessor('id', {
    header: () => <ColumnHeaderItem id="Id" />,
    cell: (info) => {
      const id = info.getValue();

      return <OrdersId id={id} />;
    },
    enableSorting: false,
    headerAlign: 'start',
    cellAlign: 'start',
    maxSize: 60,
  }),
  columnHelper.accessor('type', {
    header: () => <ColumnHeaderItem id="OrderType" />,
    cell: (info) => {
      const {
        type,
        price,
        activationPrice,
        callbackRate,
        callbackType,
        isHideType,
        isSuborder,
      } = info.getValue();

      return (
        <OrdersType
          type={type}
          price={price}
          activationPrice={activationPrice}
          callbackRate={callbackRate}
          callbackType={callbackType}
          isHideType={isHideType}
          isSuborder={isSuborder}
        />
      );
    },
    enableSorting: true,
    headerAlign: 'start',
    cellAlign: 'start',
    maxSize: 40,
  }),
  columnHelper.accessor('amount_info', {
    header: () => <ColumnHeaderItem id="Amount" />,
    cell: (info) => {
      const { amount, coinSymbol } = info.getValue();

      return <OrdersAmount amount={amount} coinSymbol={coinSymbol} />;
    },
    enableSorting: false,
    maxSize: 50,
  }),
  columnHelper.accessor('price_info', {
    header: () => <ColumnHeaderItem id="Price" />,
    cell: (info) => {
      const { price, coinSymbol } = info.getValue();

      return <OrdersPrice price={price} coinSymbol={coinSymbol} />;
    },
    enableSorting: false,
    maxSize: 80,
  }),
  columnHelper.accessor('filled_percent', {
    header: () => <ColumnHeaderItem id="Filled" />,
    cell: (info) => {
      const filledPercent = info.getValue();

      return (
        <OrdersFilledPercent percent={filledPercent} isShowProgress={false} />
      );
    },
    enableSorting: false,
    maxSize: 80,
  }),
  columnHelper.accessor('total_amount', {
    header: () => <ColumnHeaderItem id="Total" />,
    cell: (info) => {
      const { total, coinSymbol } = info.getValue();

      return <OrdersTotalAmount coinSymbol={coinSymbol} total={total} />;
    },
    enableSorting: false,
    maxSize: 80,
  }),
  columnHelper.accessor('created_at', {
    header: () => (
      <div className="d-flex justify-content-end">
        <ColumnHeaderItem id="Date" /> / <ColumnHeaderItem id="Status" />
      </div>
    ),
    cell: (info) => {
      const { createdAt, status, dotColor } = info.getValue();

      return (
        <div className="d-flex align-items-center justify-content-end">
          <DateStatusItem
            createdAt={createdAt}
            status={status}
            dotColor={dotColor}
          />
        </div>
      );
    },
    enableSorting: false,
    maxSize: 80,
  }),
  columnHelper.accessor('controls', {
    header: () => null,
    cell: (info) => {
      const { isHideControls } = info.getValue();

      if (isHideControls) return null;

      if (isTableWithSwipe) {
        return (
          <div
            className="text-center cursor-pointer text-decoration-underline text-truncate"
            onClick={() =>
              openOrderHistoryTradeDetailsModal({
                data: info.row.original,
              })
            }
          >
            <FormattedMessage id="Details" />
          </div>
        );
      }

      const isExpanded = info.row.getIsExpanded();

      return (
        <Button
          className={classnames('order-history-block__controls-button', {
            'order-history-block__expand-button--opened': isExpanded,
          })}
          onClick={() => info.row.toggleExpanded()}
        >
          <DownIconSVG width={10} height={6} />
        </Button>
      );
    },
    enableSorting: false,
    maxSize: 30,
    headerAlign: 'center',
    cellAlign: 'center',
  }),
];

const tradesColumns = [
  columnHelper.accessor('timestamp', {
    header: () => <ColumnHeaderItem id="Date" />,
    cell: (info) => {
      const date = info.getValue();

      return <OrdersDate date={date} />;
    },
    enableSorting: false,
  }),
  columnHelper.accessor('price_info', {
    header: () => <ColumnHeaderItem id="Price" />,
    cell: (info) => {
      const { price, coinSymbol } = info.getValue();

      return <OrdersPrice price={price} coinSymbol={coinSymbol} />;
    },
    enableSorting: false,
  }),
  columnHelper.accessor('volume_info', {
    header: () => <ColumnHeaderItem id="Filled" />,
    cell: (info) => {
      const { volume, coinSymbol } = info.getValue();

      return <OrdersAmount amount={volume} coinSymbol={coinSymbol} />;
    },
    enableSorting: false,
  }),
  columnHelper.accessor('total_amount', {
    header: () => <ColumnHeaderItem id="Total" />,
    cell: (info) => {
      const { total, coinSymbol } = info.getValue();

      return <OrdersTotalAmount coinSymbol={coinSymbol} total={total} />;
    },
    enableSorting: false,
  }),
  columnHelper.accessor('fee_info', {
    header: () => <ColumnHeaderItem id="Fee" />,
    cell: (info) => {
      const { fee, coinSymbol } = info.getValue();

      return <OrdersFee coinSymbol={coinSymbol} value={fee} />;
    },
    enableSorting: false,
  }),
];

export { tradesColumns };

export default orderHistoryColumns;
