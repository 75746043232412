import React, { memo } from 'react';

import { CurrencyItem, PersonalFormattedCurrency } from '@shared/components';

import { format } from '@utils/numbers';

import styles from './StakeAssetItem.module.scss';

const StakeAssetItem = ({ coinSymbol, amount, precision }) => {
  const value = format(amount, {
    precision,
    returnNumber: true,
  });

  return (
    <div className={styles.container}>
      <CurrencyItem
        coinSymbol={coinSymbol}
        iconAccessor="icon"
        isShowFullName={false}
        iconHeight={32}
        iconWidth={32}
      />

      <div className={styles.amount}>
        <PersonalFormattedCurrency currency={coinSymbol} value={value} />{' '}
        {coinSymbol}
      </div>
    </div>
  );
};

export default memo(StakeAssetItem);
