import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { v4 as uuidv4 } from 'uuid';

import {
  ACTIVE_TAB_WIDTH,
  INACTIVE_TAB_WIDTH,
  SINGLE_TAB_WIDTH,
} from '@constants';

export function getCellWidth(containerWidth, cols, margin, containerPadding) {
  return (
    (containerWidth - margin[0] * (cols - 1) - containerPadding[0] * 2) / cols // formula from the package
  );
}

export function getCellHeight(containerHeight, rows, margin, containerPadding) {
  return (
    (containerHeight - margin[1] * (rows - 1) - containerPadding[1] * 2) / rows // formula from the package
  );
}

// export function isExtendElement(el) {
//   return el.dataset.type !== 'item';
// }

export function clearExtendedLayout(currentLayout) {
  return currentLayout.filter((l) => l.type === 'item');
}

// select collision potential elements with selected box by side and sort by direction
export function getCollisionPotentialElementsBySide(
  layoutElement,
  selectedBox,
  side,
) {
  let filterFn = (el) => true;
  let sortFn = (a, b) => 0;

  switch (side) {
    case 'right': {
      filterFn = (el) =>
        selectedBox.top < el.getBoundingClientRect().bottom &&
        selectedBox.bottom > el.getBoundingClientRect().top &&
        selectedBox.left < el.getBoundingClientRect().left;

      // direction from left to right and top to bottom
      sortFn = (a, b) =>
        a.getBoundingClientRect().left - b.getBoundingClientRect().left ||
        a.getBoundingClientRect().top - b.getBoundingClientRect().top;

      break;
    }

    case 'bottom': {
      filterFn = (el) =>
        selectedBox.left < el.getBoundingClientRect().right &&
        selectedBox.right > el.getBoundingClientRect().left &&
        selectedBox.top < el.getBoundingClientRect().top;

      // direction from top to bottom and left to right
      sortFn = (a, b) =>
        a.getBoundingClientRect().top - b.getBoundingClientRect().top ||
        a.getBoundingClientRect().left - b.getBoundingClientRect().left;

      break;
    }

    default: {
      break;
    }
  }

  return Array.from(layoutElement.children).filter(filterFn).sort(sortFn);
}

// distance between two boxes
function getDistanceBetweenBoxesBySide(firstBox, secondBox, side) {
  let distanceBetweenBlocks = 0;

  switch (side) {
    case 'right': {
      distanceBetweenBlocks = secondBox.left - firstBox.right;

      break;
    }

    case 'bottom': {
      distanceBetweenBlocks = secondBox.top - firstBox.bottom;

      break;
    }

    default: {
      break;
    }
  }

  return distanceBetweenBlocks;
}

// selected box width/height + distance to the closest box by side
export function getMaxBoxSizeBySide(potentialElements, selectedBox, side) {
  let distanceBetweenBlocks = 0;

  if (isEmpty(potentialElements)) {
    return distanceBetweenBlocks;
  }

  const closestBlockElement = potentialElements[0];
  const closestBlockBox = closestBlockElement.getBoundingClientRect();

  switch (side) {
    case 'right': {
      distanceBetweenBlocks =
        getDistanceBetweenBoxesBySide(selectedBox, closestBlockBox, 'right') +
        selectedBox.width;

      break;
    }

    case 'bottom': {
      distanceBetweenBlocks =
        getDistanceBetweenBoxesBySide(selectedBox, closestBlockBox, 'bottom') +
        selectedBox.height;

      break;
    }

    default: {
      break;
    }
  }

  return distanceBetweenBlocks;
}

// Returns true if two boxes overlap
// https://stackoverflow.com/questions/9324339/how-much-do-two-rectangles-overlap
// export function getOverlapPercentBetweenBoxes(firstBox, secondBox) {
//   if (!firstBox || !secondBox) {
//     return 0;
//   }

//   const orange = {
//     triangle: {
//       x: firstBox.left,
//       y: firstBox.top
//     },
//     circle: {
//       x: firstBox.right,
//       y: firstBox.bottom
//     }
//   };

//   const blue = {
//     triangle: {
//       x: secondBox.left,
//       y: secondBox.top
//     },
//     circle: {
//       x: secondBox.right,
//       y: secondBox.bottom
//     }
//   };

//   const intersecting_area =
//     Math.max(
//       0,
//       Math.min(orange.circle.x, blue.circle.x) -
//         Math.max(orange.triangle.x, blue.triangle.x)
//     ) *
//     Math.max(
//       0,
//       Math.min(orange.circle.y, blue.circle.y) -
//         Math.max(orange.triangle.y, blue.triangle.y)
//     );

//   const orange_area = firstBox.width * firstBox.height;
//   // const blue_area = secondBox.width * secondBox.height;

//   return (intersecting_area / orange_area) * 100;

//   // const percent_coverage =
//   //   intersecting_area / (orange_area + blue_area - intersecting_area);

//   // return percent_coverage * 100;
// }

// export function getLayoutItemIndex(currentLayout, i) {
//   return currentLayout.findIndex((l) => l.i === i);
// }

export function getLayoutItem(currentLayout, i) {
  return currentLayout.find((l) => l.i === i);
}

export function createLayoutItem(type, dataGrid) {
  const newLayoutItemI = uuidv4();

  return {
    i: newLayoutItemI,
    type: 'item',
    activeTabIndex: 0,
    dataGrid: {
      i: newLayoutItemI,
      x: dataGrid.x,
      y: dataGrid.y,
      w: dataGrid.w,
      h: dataGrid.h,
      // minW: 3,
      // minH: 3,
      // maxW: 3,
      // maxH: 3,
    },
    tabs: [{ type }],
  };
}

export function removeLayoutItem(currentLayout, i) {
  return currentLayout.filter((l) => l.i !== i);
}

export function updateLayoutItemActiveTabIndex(
  currentLayout,
  i,
  newActiveTabIndex,
) {
  const updatedLayout = cloneDeep(currentLayout);
  const layoutItem = getLayoutItem(updatedLayout, i);

  layoutItem.activeTabIndex = newActiveTabIndex;

  return updatedLayout;
}

function updateActiveTabIndex(
  type,
  { activeTabIndex, tabIndex, tabsLength, newTabIndex },
) {
  let updatedActiveTabIndex = activeTabIndex;

  switch (type) {
    case 'remove': {
      const isActiveLastTab =
        activeTabIndex === tabIndex && tabsLength - 1 === tabIndex;

      const isActiveTabAfterRemovedTab = activeTabIndex > tabIndex;

      if (isActiveLastTab || isActiveTabAfterRemovedTab) {
        updatedActiveTabIndex = activeTabIndex - 1;
      }

      break;
    }

    case 'add': {
      if (activeTabIndex >= newTabIndex) {
        // when user moved tab to the left of the active tab
        updatedActiveTabIndex = activeTabIndex + 1;
      }

      break;
    }

    case 'sort': {
      // when user moved active tab to the new place
      if (activeTabIndex === tabIndex) {
        updatedActiveTabIndex = newTabIndex;
      } else if (activeTabIndex > tabIndex && activeTabIndex <= newTabIndex) {
        // when user moved tab to the right of the active tab
        updatedActiveTabIndex = activeTabIndex - 1;
      } else if (activeTabIndex < tabIndex && activeTabIndex >= newTabIndex) {
        // when user moved tab to the left of the active tab
        updatedActiveTabIndex = activeTabIndex + 1;
      }

      break;
    }

    default: {
      break;
    }
  }

  return updatedActiveTabIndex;
}

export function removeItemTab(currentLayout, i, tabIndex) {
  const updatedLayout = cloneDeep(currentLayout);
  const sourceLayoutItem = getLayoutItem(updatedLayout, i);

  sourceLayoutItem.activeTabIndex = updateActiveTabIndex('remove', {
    activeTabIndex: sourceLayoutItem.activeTabIndex,
    tabIndex,
    tabsLength: sourceLayoutItem.tabs.length,
  });

  sourceLayoutItem.tabs.splice(tabIndex, 1);

  return updatedLayout;
}

export function sortItemTab(currentLayout, i, oldTabIndex, newTabIndex) {
  const updatedLayout = cloneDeep(currentLayout);
  const sourceLayoutItem = getLayoutItem(updatedLayout, i);

  sourceLayoutItem.activeTabIndex = updateActiveTabIndex('sort', {
    activeTabIndex: sourceLayoutItem.activeTabIndex,
    tabIndex: oldTabIndex,
    newTabIndex,
  });

  const [removedTab] = sourceLayoutItem.tabs.splice(oldTabIndex, 1);

  sourceLayoutItem.tabs.splice(newTabIndex, 0, removedTab);

  return updatedLayout;
}

export function moveItemTab(
  currentLayout,
  oldI,
  newI,
  oldTabIndex,
  newTabIndex,
) {
  const updatedLayout = cloneDeep(currentLayout);
  const sourceLayoutItem = getLayoutItem(updatedLayout, oldI);
  const destinationLayoutItem = getLayoutItem(updatedLayout, newI);

  sourceLayoutItem.activeTabIndex = updateActiveTabIndex('remove', {
    activeTabIndex: sourceLayoutItem.activeTabIndex,
    tabIndex: oldTabIndex,
    tabsLength: sourceLayoutItem.tabs.length,
  });

  destinationLayoutItem.activeTabIndex = updateActiveTabIndex('add', {
    activeTabIndex: destinationLayoutItem.activeTabIndex,
    newTabIndex,
  });

  const [removedTab] = sourceLayoutItem.tabs.splice(oldTabIndex, 1);

  destinationLayoutItem.tabs.splice(newTabIndex, 0, removedTab);

  return updatedLayout;
}

export const getTabsWidths = (
  tabs,
  containerWidth,
  minWidth,
  activeTabIndex,
) => {
  const clampedTabWidth = containerWidth / tabs.length;
  const updatedTabWidth = Math.max(minWidth, clampedTabWidth);
  const hasManyTabs = tabs.length > 1;

  let defaultTabWidth = SINGLE_TAB_WIDTH;

  if (hasManyTabs) {
    defaultTabWidth = INACTIVE_TAB_WIDTH;
  }

  return tabs.map((tab, index) => {
    if (hasManyTabs && index === activeTabIndex) {
      defaultTabWidth = ACTIVE_TAB_WIDTH;
    }

    return Math.min(updatedTabWidth, defaultTabWidth);
  });
};

// create empty matrix rows * cols size and put -1 into not empty cell based on the layout
function getFilledLayoutMatrix(currentLayout, rows, cols) {
  const filledLayoutMatrix = Array.from(Array(rows), () => Array(cols).fill(0));

  currentLayout.forEach(({ dataGrid: { x, y, w, h } }) => {
    for (let rowIndex = y; rowIndex < y + h; rowIndex++) {
      filledLayoutMatrix[rowIndex].fill(-1, x, x + w); // fill cells for rowIndex row from x to x + w cols
    }
  });

  return filledLayoutMatrix;
}

// get sub matrix (rows and cols) by sub matrix position and size
function getLayoutSubMatrix(layoutMatrix, pos, size) {
  return layoutMatrix
    .filter((row, rowIndex) => rowIndex >= pos.y && rowIndex < pos.y + size.h) // filter rows
    .map((col) => col.slice(pos.x, pos.x + size.w)); // sliced cols
}

export function extendLayout(currentLayout, rowsAmount, colsAmount) {
  // max and min area size
  const MAX_W = 3;
  const MAX_H = 3;
  const MIN_W = Math.min(1, MAX_W);
  const MIN_H = Math.min(1, MAX_H);

  // the current cell size will decrease until the layout is filled or until the cell reaches the min size
  let currentW = MAX_W;
  let currentH = MAX_H;

  // clear layout from the previous extend
  const extendedLayout = clearExtendedLayout(currentLayout);

  // init matrix calculation
  let filledLayoutMatrix = getFilledLayoutMatrix(
    extendedLayout,
    rowsAmount,
    colsAmount,
  );

  // while cell size is not min and we have available space on the layout
  while (
    (currentW >= MIN_W || currentH >= MIN_H) &&
    filledLayoutMatrix.flat().some((cell) => cell === 0)
  ) {
    const size = { w: currentW, h: currentH };

    // check each cell (rows => cols direction)
    for (let rowIndex = 0; rowIndex < rowsAmount; rowIndex++) {
      for (let colIndex = 0; colIndex < colsAmount; colIndex++) {
        const pos = { x: colIndex, y: rowIndex }; // current cell

        // get sub matrix for the area with current size
        const layoutSubMatrix = getLayoutSubMatrix(
          filledLayoutMatrix,
          pos,
          size,
        );

        // get cells in one line
        const flattenLayoutSubMatrix = layoutSubMatrix.flat();

        // area should be set size and available for filling
        if (
          flattenLayoutSubMatrix.length === size.w * size.h &&
          flattenLayoutSubMatrix.every((cell) => cell === 0)
        ) {
          extendedLayout.push({
            i: uuidv4(),
            type: 'add-area',
            title: '',
            dataGrid: {
              x: colIndex,
              y: rowIndex,
              w: size.w,
              h: size.h,
              static: true,
            },
          });

          filledLayoutMatrix = getFilledLayoutMatrix(
            extendedLayout,
            rowsAmount,
            colsAmount,
          ); // recalculate matrix after changes
        }
      }
    }

    // we should change current size for next iteration to cover all cases, e.g. MAX_W=3, MAX_H=3 => 3x3, 3x2, 3x1, 2x3, 1x3, 2x2, ..., MIN_W x MIN_H
    if (size.w >= size.h && size.h > MIN_H) {
      currentH = currentH - 1;
    } else if (size.h > size.w && size.w > MIN_W) {
      currentW = currentW - 1;
    } else if (size.h === MIN_H && size.w === MIN_W) {
      currentH = currentH - 1;
      currentW = currentW - 1;

      // exit from the while
    } else if (size.h === MIN_H) {
      currentH = currentW;
      currentW = currentW - 1;
    } else if (size.w === MIN_W) {
      currentH = currentH - 1;
      currentW = currentH;
    }
  }

  return extendedLayout;
}

export function roundWidthToCell(width, cellWidth) {
  let widthByCell = 0;

  while (width > widthByCell) {
    widthByCell += cellWidth;
  }

  if (widthByCell === cellWidth) {
    return widthByCell;
  }

  if (widthByCell - width > cellWidth / 2) {
    return widthByCell - cellWidth;
  }

  return widthByCell;
}

export function roundHeightToCell(height, cellHeight) {
  let widthByCell = 0;

  while (height > widthByCell) {
    widthByCell += cellHeight;
  }

  if (widthByCell === cellHeight) {
    return widthByCell;
  }

  if (widthByCell - height > cellHeight / 2) {
    return widthByCell - cellHeight;
  }

  return widthByCell;
}

export const createAddAreaItem = (currentLayout, sourceLayoutItem) => {
  const updatedLayout = cloneDeep(currentLayout);

  updatedLayout.push({
    i: uuidv4(),
    type: 'add-area',
    title: '',
    dataGrid: {
      x: sourceLayoutItem.dataGrid.x,
      y: sourceLayoutItem.dataGrid.y,
      w: sourceLayoutItem.dataGrid.w,
      h: sourceLayoutItem.dataGrid.h,
      static: true,
    },
  });

  return updatedLayout;
};

export const getLayoutToCompare = (data = []) => {
  if (!data) return null;
  const extractedData = data.map(
    ({ activeTabIndex, dataGrid: { x, y, h, w }, tabs }) => ({
      activeTabIndex,
      id: `${x}${y}${h}${w}`,
      tabs,
    }),
  );

  return sortBy(extractedData, ['id']);
};
