import React, { memo } from 'react';

import classnames from 'classnames';

import Footer from './components/Footer';
import Header from './components/Header';

import styles from './AuthLayout.module.scss';

const AuthLayout = ({ children, className }) => {
  return (
    <div className={classnames('page-container', styles.layout, className)}>
      <Header />

      <div className="auth-layout scrollable-layout">
        {children}

        <Footer />
      </div>
    </div>
  );
};

export default memo(AuthLayout);
