import {
  COIN_TYPE,
  CRYPTO_CURRENCIES,
  PAYMENT_METHOD,
} from '@shared/constants';

import { useCoinsInfo, useCoinsRates } from '@store/api/hooks';

import { useGetProfileInfoQueryResult } from '@api/hooks';
import { WalletsApiSelectors } from '@api/selectors';
import {
  useGet24hWithdrawTotalQuery,
  useGetUserWithdrawLimitQuery,
  useGetWithdrawLimitsQuery,
} from '@api/walletsAPI';

import { format } from '@utils/numbers';

import { DEFAULT_PRECISION } from '@constants';

const useUserWithdrawLimit = (
  currency = CRYPTO_CURRENCIES.BTC,
  paymentMethod = PAYMENT_METHOD.ADVCASH,
) => {
  const { userKycLevel } = useGetProfileInfoQueryResult();

  const { coinInfo } = useCoinsInfo({ coinSymbol: currency });

  const { ratesByCoin: coinRateBTC } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.BTC,
  });
  const { ratesByCoin: coinRateUSDT } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.USDT,
  });

  const isFiatCurrency = coinInfo?.type === COIN_TYPE.FIAT;
  const precision = coinInfo?.digits ?? DEFAULT_PRECISION;

  const currencyBTCRate = coinRateBTC?.[currency.toUpperCase()];
  const currencyUSDTRate = coinRateUSDT?.[currency.toUpperCase()];

  const { withdrawLimits } = useGetWithdrawLimitsQuery(undefined, {
    selectFromResult: (result) => ({
      withdrawLimits: WalletsApiSelectors.dataSelector(result),
    }),
  });

  const {
    userWithdrawLimit: {
      withdrawLimitBTC,
      withdrawAdvCashLimitUSDT,
      withdrawClearJunctionLimitUSDT,
      withdrawDefaultLimitUSDT,
    },
  } = useGetUserWithdrawLimitQuery(undefined, {
    selectFromResult: (result) => ({
      userWithdrawLimit: WalletsApiSelectors.userWithdrawLimitSelector(
        result,
        userKycLevel,
        withdrawLimits,
      ),
    }),
  });

  const {
    alreadyWithdrawnWithin24H: { advCashUSDT, bitgoBTC, clearJunctionUSDT },
  } = useGet24hWithdrawTotalQuery(undefined, {
    selectFromResult: (result) => ({
      alreadyWithdrawnWithin24H:
        WalletsApiSelectors.total24hWithdrawSelector(result),
    }),
  });

  // conversion already withdrawn amount within 24H to selected currency
  const alreadyWithdrawnWithin24HBitgo = currencyBTCRate * bitgoBTC;
  const alreadyWithdrawnWithin24HAdvCash = currencyUSDTRate * advCashUSDT;
  const alreadyWithdrawnWithin24HClearJunction =
    currencyUSDTRate * clearJunctionUSDT;

  // conversion withdraw user limits to selected currency
  const withdrawCryptoLimit = currencyBTCRate * withdrawLimitBTC;
  const withdrawFiatAdvCashLimit = currencyUSDTRate * withdrawAdvCashLimitUSDT;
  const withdrawFiatClearJunctionLimit =
    currencyUSDTRate * withdrawClearJunctionLimitUSDT;
  const withdrawFiatDefaultLimit = currencyUSDTRate * withdrawDefaultLimitUSDT;

  let limit = withdrawCryptoLimit - alreadyWithdrawnWithin24HBitgo;
  let alreadyWithdrawn = alreadyWithdrawnWithin24HBitgo;

  if (isFiatCurrency) {
    limit = withdrawFiatDefaultLimit;

    if (paymentMethod === PAYMENT_METHOD.ADVCASH) {
      limit = withdrawFiatAdvCashLimit - alreadyWithdrawnWithin24HAdvCash;
      alreadyWithdrawn = alreadyWithdrawnWithin24HAdvCash;
    }

    if (paymentMethod === PAYMENT_METHOD.BANK_TRANSFER) {
      limit =
        withdrawFiatClearJunctionLimit - alreadyWithdrawnWithin24HClearJunction;
      alreadyWithdrawn = alreadyWithdrawnWithin24HClearJunction;
    }
  }

  limit = Math.max(0, format(limit, { precision, returnNumber: true }));

  return { userWithdrawLimit: limit, alreadyWithdrawn };
};

export default useUserWithdrawLimit;
