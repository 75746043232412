import React, { memo } from 'react';

import TruncatedText from '@shared/components/TruncatedText';

import styles from './TypeItem.module.scss';

const TypeItem = ({ type }) => {
  return (
    <TruncatedText>
      <span className={styles.type}>{type}</span>
    </TruncatedText>
  );
};

export default memo(TypeItem);
