import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FormProvider, useForm } from 'react-hook-form';

import { ButtonGroup, FormActions } from '@shared/components';
import { Loader } from '@shared/ui';

import SideBar from '@components/SideBar';
import {
  SideBarLabel,
  SideBarDescription,
} from '@components/SideBar/components';

import { VisaCardBlock } from '@pages/WalletsPage/VisaCardDetails/components';
import { TopUpMethods } from '@pages/WalletsPage/VisaCardDetails/components/sidebars/SideBarTopUpCard/components';
import { TOP_UP_METHODS } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.constants';
import {
  topUpMethodSelector,
  visaCardSelector,
} from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';
import { visaCardDetailsActions } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.slice';

import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { sidebarIds } from '@constants';

const SideBarTopUpCard = () => {
  const intl = useIntl();

  const card = useSelector(visaCardSelector);
  const topUpMethod = useSelector(topUpMethodSelector);

  const dispatch = useDispatch();

  const { isOpen, closeSidebar } = useSidebar(sidebarIds.TOP_UP_CARD);
  const { openSidebar: openConfirmSidebar } = useSidebar(
    sidebarIds.CONFIRM_TOP_UP,
  );

  const methods = useForm({
    defaultValues: { amount: '', percent: '' },
  });

  const { resetField, handleSubmit } = methods;

  const resetForm = () => {
    resetField('amount');
    resetField('percent');
  };

  const topUpMethods = useMemo(
    () => [
      {
        id: TOP_UP_METHODS.SPOT_ACCOUNT,
        value: <FormattedMessage id="SpotAccount" />,
      },
      { id: TOP_UP_METHODS.OTHERS, value: <FormattedMessage id="Others" /> },
    ],
    [],
  );

  const handleCloseSideBar = () => {
    resetForm();
    closeSidebar();
  };

  const handleSelectTopUpMethod = ({ id: method }) => {
    dispatch(visaCardDetailsActions.setTopUpMethod(method));
  };

  const handleTopUpCard = ({ amount, currency }) => {
    closeSidebar();
    openConfirmSidebar();

    const confirmationDetails = {
      amount: format(amount, { precision: currency.digits }),
      currency: currency.value.toUpperCase(),
    };

    dispatch(
      visaCardDetailsActions.setSpotAccountFormDetails(confirmationDetails),
    );

    resetForm();
  };

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleCloseSideBar}
      withCloseButton
      title={intl.formatMessage({ id: 'TopUpCard' })}
    >
      {!card.type ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit(handleTopUpCard)}
          className="h-100 d-flex flex-column align-items-start justify-content-between"
        >
          <FormProvider {...methods}>
            <div>
              <div className="mt-12">
                <SideBarDescription>
                  <FormattedMessage id="TopUpCardDescription" />
                </SideBarDescription>
              </div>

              <div className="mt-30">
                <SideBarLabel>
                  <FormattedMessage id="FundingCard" />:
                </SideBarLabel>

                <VisaCardBlock />
              </div>

              <div className="mt-30">
                <SideBarLabel>
                  <FormattedMessage id="TopUpMethod" />:
                </SideBarLabel>

                <div className="mt-16">
                  <ButtonGroup
                    color="secondary"
                    inversion
                    variant="outlined"
                    items={topUpMethods}
                    onClick={handleSelectTopUpMethod}
                    selectedItemId={topUpMethod}
                  />
                </div>
              </div>

              <div className="mt-30">
                <TopUpMethods />
              </div>
            </div>

            <div className="w-100 pt-40 pb-80">
              <FormActions
                inversion
                submitText={<FormattedMessage id="Confirm" />}
                submitButtonProps={{
                  disabled: topUpMethod === TOP_UP_METHODS.OTHERS,
                }}
                onCancel={handleCloseSideBar}
              />
            </div>
          </FormProvider>
        </form>
      )}
    </SideBar>
  );
};

export default SideBarTopUpCard;
