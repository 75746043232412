import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import BigNumber from 'bignumber.js';

import { useCoinsInfo, useDefaultAccount } from '@store/api/hooks';
import { useTransactions } from '@store/api/hooks';

import { TransactionHistorySelectors } from '../TransactionHistory/transactionHistory.selectors';

const useTransactionsHistoryTable = () => {
  const queryParams = useSelector(
    TransactionHistorySelectors.queryParamsSelector,
  );

  const { defaultSpotAccount } = useDefaultAccount();

  const { transactions, meta, isTransactionsSuccess } = useTransactions(
    { ...queryParams, accountId: defaultSpotAccount?.id },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { coinsInfo } = useCoinsInfo();

  const data = useMemo(
    () => ({
      transactionsHistoryData: transactions.map((transaction) => {
        const coinSymbol = transaction.coin_symbol;
        const amountBN = new BigNumber(transaction.amount);
        const feeAmountBN = new BigNumber(transaction.fee_amount);
        const coinInfo = coinsInfo?.[coinSymbol];
        const precision = coinInfo?.digits;
        const blockChainExplorer = coinInfo?.blockchain_explorer;

        return {
          asset: coinSymbol,
          type: transaction.tx_type,
          details: {
            address: transaction.address,
            txid: transaction.txid,
            txidLink: blockChainExplorer
              ? `${blockChainExplorer}${transaction.txid}`
              : undefined,
          },
          amount: {
            amount: amountBN.minus(feeAmountBN).toNumber(),
            precision,
          },
          feeAmount: {
            feeAmount: feeAmountBN.toNumber(),
            coinSymbol: transaction.fee_coin,
          },
          total: {
            total: amountBN.toNumber(),
            precision,
          },
          date: transaction.created_at,
          status: transaction.status,
        };
      }),
      transactionsHistoryMeta: meta,
      isTransactionsHistoryTableSuccess: isTransactionsSuccess,
    }),
    [transactions, coinsInfo, meta, isTransactionsSuccess],
  );

  return data;
};

export default useTransactionsHistoryTable;
