import { memo, useMemo } from 'react';

import { FreeMode, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ButtonGroup } from '@shared/components';
import { Button, Loader } from '@shared/ui';

import { useSelectPeriodRange } from '@pages/WalletsPage/Staking/hooks';

import { FormattedPeriods } from '../FormattedPeriods';

import styles from './AvailablePlansPeriodButtons.module.scss';

const ADDITIONAL_SLIDES_SPACE = 82;

const AvailablePlansPeriodButtons = ({
  activePeriod,
  coinSymbol,
  isSwiperActive = false,
  isAllPeriodsSelected,
  isHovered,
}) => {
  const { periods, selectPeriodRange } = useSelectPeriodRange({
    coinSymbol,
    isAllPeriodsSelected,
  });

  const periodsList = useMemo(
    () =>
      periods?.map(({ period }) => ({
        id: `staking_period_id_${period}`,
        period,
      })) || [],
    [periods],
  );

  const selectedPeriodId = useMemo(
    () => periodsList.find(({ period }) => period === activePeriod)?.id,
    [periodsList, activePeriod],
  );

  if (!periodsList.length || !selectedPeriodId) {
    return <Loader />;
  }

  const commonButtonProps = {
    variant: 'outlined',
    color: isHovered ? 'tertiary' : 'primary',
    inversion: isHovered,
    size: 'xs',
    customStyles: styles,
  };

  const buttons = (
    <ButtonGroup
      items={periodsList}
      selectedItemId={selectedPeriodId}
      valueKey="period"
      onClick={({ period }) => selectPeriodRange(period)}
      {...commonButtonProps}
    >
      {(period) => <FormattedPeriods period={period} />}
    </ButtonGroup>
  );

  if (isSwiperActive) {
    return (
      <Swiper
        modules={[Mousewheel, FreeMode]}
        slidesOffsetAfter={ADDITIONAL_SLIDES_SPACE}
        freeMode={{ momentumRatio: 0.5 }}
        mousewheel={{ forceToAxis: true, threshholdDelta: 2 }}
        spaceBetween={10}
        slidesPerView="auto"
      >
        {periodsList.map(({ id, period }) => (
          <SwiperSlide key={id} style={{ width: 40 }}>
            <Button
              active={id === selectedPeriodId}
              onClick={() => selectPeriodRange(period)}
              {...commonButtonProps}
            >
              <FormattedPeriods period={period} />
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }

  return buttons;
};

export default memo(AvailablePlansPeriodButtons);
