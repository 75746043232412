import { useNavigate } from 'react-router';

const useNavigateBack = () => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return navigateBack;
};

export default useNavigateBack;
