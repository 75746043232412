import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../../utils';

const defaultBotsSettings = {
  limits: null,
  grid: { limits: null },
  trend: { limits: null },
};

const dataSelector =
  ApiSelectorsUtils.createDataApiSelector(defaultBotsSettings);

const gridBotsSettingsSelector = createSelector(dataSelector, (data) => {
  return data.grid.limits;
});

const trendBotsSettingsSelector = createSelector(dataSelector, (data) => {
  return data.trend.limits;
});

const limitsBotsSettingsSelector = createSelector(dataSelector, (data) => {
  return data.limits;
});

export const BotsApiSettingsSelectors = {
  dataSelector,
  gridBotsSettingsSelector,
  trendBotsSettingsSelector,
  limitsBotsSettingsSelector,
};
