import { memo } from 'react';

import { Button } from '@shared/ui';

import styles from './PageButton.module.scss';

const PageButton = ({ isActive, index, onClick }) => {
  return (
    <div className={styles.button_container}>
      <Button
        isCircle
        size="s"
        variant={isActive ? 'outlined' : 'text'}
        onClick={onClick}
        customStyles={styles}
      >
        {index}
      </Button>
    </div>
  );
};

export default memo(PageButton);
