import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  SideBarBlock,
  SideBarInfoPoint,
  SideBarList,
} from '@shared/components';
import { Button } from '@shared/ui';

import SideBar from '@components/SideBar';

import { transferDetails } from '@pages/WalletsPage/VisaCardDetails/components/sidebars/SideBarConfirmTransferMoney/transferDetails';
import {
  confirmTransferMoneyDetailsSelector,
  spotAccountFormDetailsSelector,
  transferRecipientSelector,
  visaCardSelector,
} from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import s from './SideBarConfirmTransferMoney.module.scss';

const SideBarConfirmTransferMoney = () => {
  const intl = useIntl();

  const visaCard = useSelector(visaCardSelector);
  const spotAccountFormDetails = useSelector(spotAccountFormDetailsSelector);
  const transferMoneyDetails = useSelector(confirmTransferMoneyDetailsSelector);
  const transferRecipient = useSelector(transferRecipientSelector);

  const { isOpen, closeSidebar } = useSidebar(
    sidebarIds.CONFIRM_TRANSFER_MONEY,
  );

  const { openSidebar: openTransferMoneySidebar } = useSidebar(
    sidebarIds.VISA_TRANSFER_MONEY,
  );

  const transferDetailsMemo = useMemo(
    () =>
      transferDetails({
        intl,
        transferRecipient,
        visaCard,
        transferMoneyDetails,
        spotAccountFormDetails,
      }),
    [
      intl,
      transferRecipient,
      visaCard,
      transferMoneyDetails,
      spotAccountFormDetails,
    ],
  );

  const handleCloseSideBar = () => {
    closeSidebar();
  };

  const handleOpenTransferMoneySidebar = () => {
    closeSidebar();
    openTransferMoneySidebar();
  };

  const handleConfirm = () => {
    console.log('handleConfirm');
  };

  return (
    <SideBar
      withBackButton
      onBack={handleOpenTransferMoneySidebar}
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleCloseSideBar}
      withCloseButton
      title={intl.formatMessage({ id: 'ConfirmTransfer' })}
    >
      <div className={s.sidebar}>
        <div className="w-100">
          <div className="mt-10">
            <SideBarBlock>
              <SideBarList items={transferDetailsMemo} />
            </SideBarBlock>
          </div>

          <div className="mt-10">
            <SideBarBlock>
              <SideBarInfoPoint
                title={intl.formatMessage({
                  id: 'ConfirmTransferMoneyInfoTitle',
                })}
                description={intl.formatMessage({
                  id: 'ConfirmTransferMoneyInfoDescription',
                })}
              />
            </SideBarBlock>
          </div>
        </div>

        <div className={s.controls}>
          <Button
            fill
            type="button"
            color="secondary"
            size="l"
            inversion
            onClick={handleConfirm}
          >
            <FormattedMessage id="YesConfirm" />
          </Button>
          <Button
            fill
            type="button"
            onClick={handleCloseSideBar}
            inversion
            size="l"
            variant="outlined"
          >
            <FormattedMessage id="Cancel" />
          </Button>
        </div>
      </div>
    </SideBar>
  );
};

export default SideBarConfirmTransferMoney;
