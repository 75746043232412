import { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';

import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { InputController } from '@shared/ui';

import { useGetFeesInfoQueryResult } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { ValidationUtils } from '@utils/validations';

import { BotStartValidations } from '../../../BotStart/botStart.validations';

const BotTrendSideDistance = ({
  entryFieldName,
  takeFieldName,
  saveFieldName,
  isDisabled,
}) => {
  const methods = useFormContext();

  const {
    resetField,
    formState: { isSubmitted },
    trigger,
  } = methods;

  const intl = useIntl();

  const { isMobile } = useBreakpoints();

  const { feeInfo } = useGetFeesInfoQueryResult();

  useEffect(() => {
    if (isDisabled) {
      resetField(entryFieldName);
      resetField(takeFieldName);
      resetField(saveFieldName);
      return;
    }

    if (isSubmitted) {
      trigger([entryFieldName, takeFieldName, saveFieldName]);
    }
  }, [isDisabled]);

  const takeSaveLossesValidation = {
    validate: {
      required: ValidationUtils.conditionalRequired(!isDisabled),
      min: BotStartValidations.minTakeSaveLossesDistance(
        intl,
        feeInfo.taker_fee,
        !isDisabled,
      ),

      max: BotStartValidations.maxPercent(intl, !isDisabled),
    },
  };

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    size: 'l',
    inversion: true,
    suffix: '%',
    autoComplete: 'off',
    disabled: isDisabled,
    onlyNumbers: true,
    precision: 3,
  };

  return (
    <>
      <div className={classNames('d-flex', isMobile ? 'gap-16' : 'gap-40')}>
        <InputController
          name={entryFieldName}
          label={`${intl.formatMessage({ id: 'Entry' })}:`}
          placeholder={`${intl.formatMessage({ id: 'Input' })}...`}
          rules={{
            validate: {
              required: ValidationUtils.conditionalRequired(intl, !isDisabled),
              min: BotStartValidations.minEntryDistance(intl, !isDisabled),
              max: BotStartValidations.maxPercent(intl, !isDisabled),
            },
          }}
          {...commonFieldProps}
          {...methods}
        />
        <InputController
          name={takeFieldName}
          label={`${intl.formatMessage({ id: 'TakeProfit' })}:`}
          placeholder={`${intl.formatMessage({ id: 'Input' })}...`}
          rules={takeSaveLossesValidation}
          {...commonFieldProps}
          {...methods}
        />
        <InputController
          name={saveFieldName}
          label={`${intl.formatMessage({ id: 'SaveLosses' })}:`}
          placeholder={`${intl.formatMessage({ id: 'Input' })}...`}
          rules={takeSaveLossesValidation}
          {...commonFieldProps}
          {...methods}
        />
      </div>
    </>
  );
};

export default memo(BotTrendSideDistance);
