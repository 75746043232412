import { useGetAnnouncementsQuery } from '@api/announcementsAPI';
import { AnnouncementsApiSelectors } from '@api/selectors';

const useGetAnnouncementsQueryResult = ({ limit, page }, options = {}) => {
  return useGetAnnouncementsQuery(
    { limit, page },
    {
      ...options,
      selectFromResult: (result) => ({
        ...result,
        announcements: AnnouncementsApiSelectors.announcementsSelector(result),
        meta: AnnouncementsApiSelectors.metaSelector(result),
      }),
    },
  );
};

export default useGetAnnouncementsQueryResult;
