import { FormattedMessage } from 'react-intl';

export const AUTHENTICATION_METHOD = {
  EMAIL: 'email',
  PHONE: 'phone',
};

export const AUTHENTICATION_METHOD_LABEL = {
  EMAIL: <FormattedMessage id="Email" />,
  PHONE: <FormattedMessage id="Phone" />,
};

export const AUTHENTICATION_METHODS_TABS = [
  {
    id: AUTHENTICATION_METHOD.EMAIL,
    value: AUTHENTICATION_METHOD_LABEL.EMAIL,
  },
  {
    id: AUTHENTICATION_METHOD.PHONE,
    value: AUTHENTICATION_METHOD_LABEL.PHONE,
  },
];

export const IDENTIFICATION_TYPE = {
  LOGIN: 'login',
  SMS: 'sms',
  GOOGLE: 'google',
};

export const AUTHENTICATION_ROLE = {
  BUSINESS: 'business',
};

export const NECESSARY_CAPTCHA_KEYS = [
  'captcha_output',
  'gen_time',
  'lot_number',
  'pass_token',
];

export const PRIVATE_TYPE = 'private';

export const CONFIRMATION_PHONE_OPTIONS = {
  SMS: 'sms',
  WHATS_APP: 'whatsApp',
  PHONE_CALL: 'phoneCall',
};

export const SMS_CODE_LENGTH = 7;

export const USER_ROLES = {
  MEMBER: 'member',
  BUSINESS: 'business',
};
