import { createSelector } from '@reduxjs/toolkit';

import { ITEMS_PER_PAGE_NOTIFICATIONS } from '@constants';

import { ApiSelectorsUtils } from '../utils';

const defaultNotification = [];
const defaultData = { notification: defaultNotification };
const defaultMeta = { limit: ITEMS_PER_PAGE_NOTIFICATIONS, page: 1, count: 0 };

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const notificationSelector = createSelector(dataSelector, (data) => {
  return data.notification || defaultNotification;
});

export const NotificationApiSelectors = {
  notificationSelector,
  metaSelector,
};
