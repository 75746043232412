import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { ApiSelectorsUtils } from '@api/utils';

const defaultData = [];

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const coinsInfoMapBySearchValueSelector = createSelector(
  (coinsInfoMap) => coinsInfoMap,
  (coinsInfoMap, searchValue) => searchValue,
  (coinsInfoMap, searchValue) => {
    return coinsInfoMap.filter(({ symbol, name }) => {
      return (
        symbol.toLowerCase().includes(searchValue) ||
        name.toLowerCase().includes(searchValue)
      );
    });
  },
);

const coinsInfoMapOrderByTypeDirectionSelector = createSelector(
  (coinsInfoMap) => coinsInfoMap,
  (coinsInfoMap, orderByTypeDirection) => orderByTypeDirection,
  (coinsInfoMap, orderByTypeDirection) => {
    return orderBy(coinsInfoMap, 'type', orderByTypeDirection);
  },
);

const coinsInfoMapFilterByTypeSelector = createSelector(
  (coinsInfoMap) => coinsInfoMap,
  (coinsInfoMap, type) => type,
  (coinsInfoMap, type) => {
    return coinsInfoMap.filter((coin) => coin.type === type);
  },
);

const coinInfoFromCoinsInfoMapSelector = createSelector(
  (coinsInfoMap) => coinsInfoMap,
  (coinsInfoMap, coinSymbol) => coinSymbol?.toLowerCase(),
  (coinsInfoMap, coinSymbol) => {
    return coinsInfoMap.find(({ symbol }) => symbol === coinSymbol);
  },
);

export const CoinsApiSelectors = {
  dataSelector,
  coinsInfoMapBySearchValueSelector,
  coinsInfoMapOrderByTypeDirectionSelector,
  coinsInfoMapFilterByTypeSelector,
  coinInfoFromCoinsInfoMapSelector,
};
