import { createColumnHelper } from '@tanstack/react-table';

import { ColumnHeaderItem, FormattedCurrency } from '@shared/components';

import { AssetItem } from '@pages/WalletsPage/components';

import { PercentChange } from '../../PercentChange';

const { accessor } = createColumnHelper();

const launchpadAssetsTableColumns = () => [
  accessor('assetName', {
    header: () => <ColumnHeaderItem id="AssetName" />,
    cell: (info) => {
      const assetName = info.getValue();

      return (
        <AssetItem coinSymbol={assetName} iconHeight={28} iconWidth={28} />
      );
    },

    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
    enableTruncating: true,
  }),
  accessor('amount', {
    header: () => <ColumnHeaderItem id="Amount" />,
    cell: (info) => {
      const amount = info.getValue();

      return (
        <span className="fw-700 fs-14">
          <FormattedCurrency value={amount} currency={'USDT'} />
        </span>
      );
    },
    maxSize: 30,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
    enableTruncating: false,
  }),
  accessor('growth', {
    header: () => <ColumnHeaderItem id="Growth" />,
    cell: (info) => {
      const growth = info.getValue();

      return <PercentChange value={growth} />;
    },
    maxSize: 50,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
    enableTruncating: false,
  }),
];

export default launchpadAssetsTableColumns;
