import BigNumber from 'bignumber.js';

import { DEFAULT_PRECISION } from '@constants';

const defaultFormatSettings = {
  // string to prepend
  prefix: '',
  // decimal separator
  decimalSeparator: '.',
  // grouping separator of the integer part
  groupSeparator: ',',
  // primary grouping size of the integer part
  groupSize: 3,
  // secondary grouping size of the integer part
  secondaryGroupSize: 0,
  // grouping separator of the fraction part
  fractionGroupSeparator: ' ',
  // grouping size of the fraction part
  fractionGroupSize: 0,
  // string to append
  suffix: '',
};

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  DECIMAL_PLACES: DEFAULT_PRECISION,
  FORMAT: defaultFormatSettings,
});

export const bigNumberPrefixes = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
  { value: 1e21, symbol: 'Z' },
  { value: 1e24, symbol: 'Y' },
];

export function removeZerosFromEnd(num) {
  let numStr = num.toString();
  let integerPart = numStr.split('.')[0];
  let decimalPart = numStr.split('.')[1];

  if (!decimalPart) return numStr;

  let i = decimalPart.length - 1;

  for (; i >= 0; i--) {
    if (decimalPart[i] !== '0') break;
  }

  if (i < 0) return integerPart;

  return `${integerPart}.${decimalPart.slice(0, i + 1)}`;
}

function shortNumberFormat(num, digits) {
  const numToCheck = Math.abs(num);

  for (let i = bigNumberPrefixes.length - 1; i >= 0; i -= 1) {
    if (numToCheck >= bigNumberPrefixes[i].value) {
      const newNumber = (num / bigNumberPrefixes[i].value).toFixed(digits);

      return `${newNumber}${bigNumberPrefixes[i].symbol}`;
    }
  }

  return '0';
}

export function forceNumeric(nr) {
  return nr
    .replace(',', '.')
    .replace(/[^0-9.]/g, '')
    .replace(/((\d+)?\.(\d+)?)\.(.*)/g, '$1');
}

export const toInteger = (value) => value.trim().replace(/\D/g, '');

export const toFloat = (value, precision) => {
  let formattedValue = value.trim().replace(/[^\d.]/g, '');

  if (precision) {
    const regexString = `(\\.\\d{${precision}})\\d+`;
    formattedValue = formattedValue.replace(new RegExp(regexString), '$1');
  }

  formattedValue = formattedValue.replace(/(\.\d*)\./, '$1'); // Remove any additional dots

  return formattedValue;
};

export const isValidNumber = (
  text,
  precision = DEFAULT_PRECISION,
  isInteger = false,
) => {
  const normalizedText = text.replace(/,/, '.');

  const integerNumberRegexp = /^\d*$/gi;

  if (isInteger) {
    return integerNumberRegexp.test(text);
  }

  const decimalNumberRegexp = new RegExp(`^\\d+\\.\\d{0,${precision}}$`, 'gi');

  return (
    integerNumberRegexp.test(normalizedText) ||
    decimalNumberRegexp.test(normalizedText)
  );
};

export const format = (
  number,
  {
    precision = DEFAULT_PRECISION,
    short = false,
    noCommas = false,
    removeZeroEnd = false,
    roundingMode = BigNumber.ROUND_DOWN,
    formatSettings = {},
    returnNumber = false,
  } = {},
) => {
  const BGNumber = new BigNumber(number || 0);

  let formattedNumber = BGNumber.toFormat(precision, roundingMode, {
    ...defaultFormatSettings,
    ...formatSettings,
  });

  if (short && !returnNumber) {
    formattedNumber = shortNumberFormat(BGNumber.toString(), precision);
  }

  if (noCommas || returnNumber) {
    formattedNumber = BGNumber.toFormat(precision, roundingMode, {
      ...defaultFormatSettings,
      groupSeparator: '',
      ...formatSettings,
    });
  }

  const result = removeZeroEnd
    ? removeZerosFromEnd(formattedNumber)
    : formattedNumber;

  return returnNumber ? Number.parseFloat(result) : result;
};

export const scale = (number, inMin, inMax, outMin, outMax) => {
  return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export const isEven = (number) => number % 2 === 0;

export const setValueByDecimalStep = (
  value,
  isMinus,
  decimal = DEFAULT_PRECISION,
) => {
  const step = Number(Math.pow(0.1, decimal).toFixed(decimal));
  let result = Number(Number(0).toFixed(decimal));

  if (!isNaN(Number(value))) {
    result = Number(Number(value).toFixed(decimal));
  }

  result = isMinus ? Math.max(0, result - step) : result + step;
  result = result.toFixed(decimal);

  return result;
};

export const roundNumber = (number, nearestNumber = 10) => {
  return parseInt(number / nearestNumber, 10) * nearestNumber;
};
