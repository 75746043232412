import { useRef, useState, useEffect, memo } from 'react';
import { createPortal } from 'react-dom';

import { Modal as BootstrapModal } from 'bootstrap';
import classNames from 'classnames';

import { NOOP } from '@constants';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';

import styles from './Modal.module.scss';

const Modal = ({
  modalInstanceRef,
  children,
  autoWidth = false,
  id,
  closable = true,
  hideHeader = false,
  isScrollable = false,
  isCentered = true,
  className,
  title,
  footer,
  onOpen = NOOP,
  afterOpen = NOOP,
  onClose = NOOP,
  afterClose = NOOP,
  customStyles = {},
}) => {
  const modalRef = useRef();

  useEffect(() => {
    const modal = new BootstrapModal(modalRef.current, {
      backdrop: closable ? true : 'static',
      focus: true,
      keyboard: true,
    });

    if (modalInstanceRef) {
      modalInstanceRef.current = modal;
    }

    const handleHideModal = (e) => {
      onClose(e);
    };

    const handleHiddenModal = (e) => {
      afterClose(e);
    };

    const handleShowModal = (e) => {
      onOpen(e);
    };

    const handleShownModal = (e) => {
      afterOpen(e);
    };

    modalRef.current.addEventListener('hide.bs.modal', handleHideModal);
    modalRef.current.addEventListener('hidden.bs.modal', handleHiddenModal);
    modalRef.current.addEventListener('show.bs.modal', handleShowModal);
    modalRef.current.addEventListener('shown.bs.modal', handleShownModal);

    modal.show();

    return () => {
      if (modalRef.current) {
        modalRef.current.removeEventListener('hide.bs.modal', handleHideModal);
        modalRef.current.removeEventListener(
          'hidden.bs.modal',
          handleHiddenModal,
        );
        modalRef.current.removeEventListener('show.bs.modal', handleShowModal);
        modalRef.current.removeEventListener(
          'shown.bs.modal',
          handleShownModal,
        );
      }

      modal.dispose();
    };
  }, [closable]);

  return createPortal(
    <div
      ref={modalRef}
      id={id}
      className={classNames(
        'modal fade rc-dialog-wrap',
        styles.modal,
        className,
        {
          [styles['modal--auto_width']]: autoWidth,
        },
      )}
      tabIndex="-1"
    >
      <div
        className={classNames(
          customStyles.modal_dialog,
          'modal-dialog rc-dialog',
          {
            'modal-dialog-centered': isCentered,
            'modal-dialog-scrollable': isScrollable,
          },
        )}
      >
        <div
          className={classNames(
            customStyles.modal_content,
            'modal-content rc-dialog-content',
          )}
        >
          {!hideHeader && (
            <div
              className={classNames(
                customStyles.modal_header,
                'modal-header rc-dialog-header',
              )}
            >
              <h1 className="modal-title rc-dialog-title">{title}</h1>

              {closable && (
                <div className="modal-close rc-dialog-close" onClick={onClose}>
                  <CrossThinIconSVG width={16} height={16} />
                </div>
              )}
            </div>
          )}

          <div
            className={classNames(
              customStyles.modal_body,
              'modal-body rc-dialog-body',
            )}
          >
            {children}
          </div>

          {footer ? (
            <div
              className={classNames(
                customStyles.modal_footer,
                'modal-footer rc-dialog-footer',
              )}
            >
              {footer}
            </div>
          ) : null}
        </div>
      </div>
    </div>,
    document.getElementById('modal-container'),
  );
};

const ModalContainer = ({ id, visible, afterClose = NOOP, ...rest }) => {
  const modalInstanceRef = useRef();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsVisible(true);
    } else if (modalInstanceRef.current) {
      modalInstanceRef.current.hide();
    }
  }, [visible]);

  const handleAfterClose = (e) => {
    setIsVisible(false);

    afterClose(e);
  };

  return isVisible ? (
    <Modal
      {...rest}
      modalInstanceRef={modalInstanceRef}
      id={id}
      afterClose={handleAfterClose}
    />
  ) : null;
};

export default memo(ModalContainer);
