import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';

import Breakpoint from '@components/Breakpoint';
import {
  SideBarNavigation,
  SideBarProfile,
} from '@components/layouts/MainLayout/components/PrimaryHeader/components';
import Link from '@components/Link';

import {
  isAccountThemeSelector,
  isAuthorizedUserSelector,
} from '@store/settings/settingsSelectors';

import { ReactComponent as LogoSymbolSVG } from '@images/paramountdax-logo-symbol.svg';
import { ReactComponent as LogoTitleSVG } from '@images/paramountdax-logo-title.svg';

import AccountsDropdown from './components/AccountsDropdown/AccountsDropdown';
import HeaderItem from './components/HeaderItem';
import HeaderRunningLine from './components/HeaderRunningLine';
import ProductsMenu from './components/ProductsMenu';
import SearchMenu from './components/SearchMenu';
import SideBarGridLayoutSettings from './components/SideBarGridLayoutSettings';
import SideBarLocalization from './components/SideBarLocalization';
import SideBarNotifications from './components/SideBarNotifications';

import { AUTH_ROUTES, MAIN_ROUTES } from 'src/routes.constants';

const PrimaryHeader = ({ withTerminalFeatures = false }) => {
  const isAuthorizedUser = useSelector(isAuthorizedUserSelector);
  const isAccountTheme = useSelector(isAccountThemeSelector);

  return (
    <div className="primary-header container-fluid">
      <div className="row flex-1">
        <div className="col-auto me-auto d-flex px-0">
          <HeaderItem withDivider>
            <Link to={MAIN_ROUTES.HOME}>
              <LogoSymbolSVG />

              <Breakpoint size="xl" direction="up">
                <LogoTitleSVG className="ms-8" />
              </Breakpoint>
            </Link>
          </HeaderItem>

          <HeaderItem withDivider>
            <ProductsMenu />
          </HeaderItem>

          <HeaderItem withDivider>
            <SearchMenu />
          </HeaderItem>
        </div>

        <Breakpoint size="md" direction="down">
          <div className="col-auto ms-auto d-flex px-0 gap-14">
            {isAuthorizedUser && (
              <AccountsDropdown headerItemClassName="header-item p-0" />
            )}

            {isAuthorizedUser && (
              <HeaderItem className="header-item p-0">
                <SideBarNotifications />
              </HeaderItem>
            )}

            {withTerminalFeatures && (
              <HeaderItem
                className="header-item p-0"
                withDivider={!isAuthorizedUser}
              >
                {isAuthorizedUser && <SideBarGridLayoutSettings />}
              </HeaderItem>
            )}

            {isAuthorizedUser && (
              <HeaderItem className="header-item p-0" withDivider>
                <SideBarProfile />
              </HeaderItem>
            )}

            <HeaderItem className="header-item ps-0">
              <SideBarNavigation />
            </HeaderItem>
          </div>
        </Breakpoint>

        <Breakpoint size="md" direction="up">
          <div className="col d-flex px-0">
            <HeaderRunningLine />
          </div>

          <div className="col-auto ms-auto d-flex px-0">
            {isAuthorizedUser && <AccountsDropdown withDivider />}

            <HeaderItem withDivider>
              <SideBarNavigation />
            </HeaderItem>

            <Breakpoint size="xl" direction="up">
              <HeaderItem withDivider>
                <SideBarLocalization isNested />
              </HeaderItem>
            </Breakpoint>

            {isAuthorizedUser && (
              <HeaderItem withDivider>
                <SideBarNotifications />
              </HeaderItem>
            )}

            {withTerminalFeatures && (
              <HeaderItem withDivider>
                <SideBarGridLayoutSettings />
              </HeaderItem>
            )}

            {isAuthorizedUser ? (
              <HeaderItem>
                <SideBarProfile />
              </HeaderItem>
            ) : (
              <HeaderItem>
                <div className="d-flex">
                  <div className="flex-1">
                    <Link
                      to={AUTH_ROUTES.LOGIN}
                      inversion={isAccountTheme}
                      className="login-link"
                    >
                      <FormattedMessage id="Login" />
                    </Link>
                  </div>

                  <span className="login-splitter" />

                  <div className="flex-1">
                    <Link
                      to={generatePath(AUTH_ROUTES.REGISTER)}
                      className="signup-link"
                      green
                    >
                      <FormattedMessage id="Signup" />
                    </Link>
                  </div>
                </div>
              </HeaderItem>
            )}
          </div>
        </Breakpoint>
      </div>
    </div>
  );
};

export default memo(PrimaryHeader);
