import cx from 'classnames';

import {
  TableDefault,
  TableSwipeable,
} from '@shared/components/Table/components';

import s from './Table.module.scss';

const Table = ({ isSwipeable, customStyles = {}, ...props }) => {
  return (
    <div
      className={cx(s.table, customStyles.table, {
        [s.inversion]: props?.inversion,
        [customStyles.table_inversion]: props?.inversion,
      })}
    >
      {isSwipeable ? (
        <TableSwipeable {...props} />
      ) : (
        <TableDefault {...props} />
      )}
    </div>
  );
};

export default Table;
