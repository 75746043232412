import { useMemo } from 'react';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import { useCoinsRates, useCoinsInfo, useMyContracts } from '@store/api/hooks';

const useInstantBonusTable = (args) => {
  const { contracts, isLoading, isMyContractsSuccess } = useMyContracts(args);

  const { ratesByCoin: coinRateBTC } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.BTC,
  });

  const { coinsInfo } = useCoinsInfo();

  const allContracts = useMemo(
    () =>
      contracts?.map((contract) => {
        const coinInfo = coinsInfo?.[contract?.coin_symbol];

        const amount = contract.amount || 0;
        const bonus = contract.bonus_percents || 0;
        const volumeTraded = contract.volume_traded || 0;
        const volumeToTrade = contract.volume_to_trade || 0;
        const progress = volumeTraded / volumeToTrade;
        const totalBonus = bonus * amount;

        let progressPercent = Math.min(progress * 100, 100);
        let currentBonusPercent = 0;

        if (progressPercent >= 25 && progressPercent < 50) {
          currentBonusPercent = 25;
        }

        if (progressPercent >= 50 && progressPercent < 75) {
          currentBonusPercent = 50;
        }

        if (progressPercent >= 75 && progressPercent < 100) {
          currentBonusPercent = 25;
        }

        if (progressPercent >= 100) {
          currentBonusPercent = 100;
        }

        const earned = totalBonus * (currentBonusPercent / 100);
        const earnedBTC =
          earned / coinRateBTC?.[contract?.coin_symbol?.toUpperCase()];

        return {
          id: contract.id,
          asset: {
            symbol: contract.coin_symbol,
          },
          estimation: contract.bonus_percents * 100,
          progress: {
            progressPercent,
            currentBonusPercent,
          },
          earnedBTC: {
            earnedBTC,
            precision: coinInfo?.digits,
          },
        };
      }),
    [coinRateBTC, coinsInfo, contracts],
  );

  const isLoaded = !isLoading && isMyContractsSuccess;

  return {
    isLoaded,
    contracts: allContracts,
    isInstantBonusSuccess: isMyContractsSuccess,
  };
};

export default useInstantBonusTable;
