import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { AccountModal, WidgetsForm } from '@shared/components';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import { widgetsSelector } from '../walletsWidgets.selectors';
import { walletsWidgetsActions } from '../walletsWidgetsSlice';

const WalletsWidgetsModal = () => {
  const { isOpen, closeModal } = useModal(modalsIds.WALLETS_WIDGETS);

  const widgets = useSelector(widgetsSelector);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(walletsWidgetsActions.updateWidgets(values.widgets));

    closeModal();
  };

  return (
    <AccountModal
      isOpen={isOpen}
      title={<FormattedMessage id="Widgets" />}
      onClose={closeModal}
    >
      <WidgetsForm
        widgets={widgets}
        onSubmit={handleSubmit}
        onCancel={closeModal}
      />
    </AccountModal>
  );
};

export default memo(WalletsWidgetsModal);
