import React, { memo } from 'react';

import classNames from 'classnames';

import { NOOP } from '@constants';

import { PageButton } from '../PageButton';

import styles from './PaginationRange.module.scss';

const PaginationRange = ({
  isMinimizedPagination,
  paginationRange,
  activePageIndex,
  handleSetPageIndex = NOOP,
  onPaginationChange = NOOP,
}) => {
  return (
    <div
      className={classNames('d-flex align-items-center', {
        'gap-14': !isMinimizedPagination,
      })}
    >
      {paginationRange.map((pageNumber) => {
        const pageNumberIndex = pageNumber - 1;

        if (pageNumber?.isDots) {
          return (
            <div
              key={pageNumber.id}
              className={classNames(styles.dots, 'table-pagination-dots')}
            >
              ...
            </div>
          );
        }

        const handlePageChange = () => {
          handleSetPageIndex(pageNumberIndex);
          onPaginationChange(pageNumberIndex);
        };

        return (
          <PageButton
            key={pageNumber}
            isActive={activePageIndex === pageNumberIndex}
            index={pageNumber}
            onClick={handlePageChange}
          />
        );
      })}
    </div>
  );
};

export default memo(PaginationRange);
