import { memo } from 'react';

import cx from 'classnames';

import { ButtonArrow } from '@shared/ui';

import s from './ItemTitle.module.scss';

const ItemTitle = ({
  title,
  arrowTitle,
  isShowArrow = true,
  customStyles = {},
  buttonCustomStyles = {},
  ...props
}) => {
  return (
    <div className={cx(s.item_title, customStyles.item_title)}>
      <div className={cx(s.title, customStyles.title)}>{title}</div>
      {isShowArrow && (
        <ButtonArrow
          color="primary"
          variant="text"
          customStyles={buttonCustomStyles}
          {...props}
        >
          {arrowTitle}
        </ButtonArrow>
      )}
    </div>
  );
};

export default memo(ItemTitle);
