import { createSlice } from '@reduxjs/toolkit';

import { ITEMS_PER_PAGE_LAUNCHPAD } from '@pages/WalletsPage/Launchpad/launchpad.constants';

const initialState = {
  pagination: {
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE_LAUNCHPAD,
  },
};

const watchlistLaunchpad = createSlice({
  name: 'watchlistLaunchpad',
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination.pageIndex = action.payload;
    },
    resetPagination: (state, action) => {
      state.pagination = initialState.pagination;
    },
  },
});

const watchlistLaunchpadActions = watchlistLaunchpad.actions;
const watchlistLaunchpadReducer = watchlistLaunchpad.reducer;

export { watchlistLaunchpadActions, watchlistLaunchpadReducer };

export default watchlistLaunchpad;
