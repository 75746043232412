import { isEmpty } from 'lodash';

import { COIN_TYPE, FIAT_CURRENCIES, PAYMENT_METHOD } from '@shared/constants';

import { useCoinsRates, useCoinsInfo } from '@store/api/hooks';

import { useGetWithdrawFiatFeesQueryResult } from '@api/hooks';

const useWithdrawCurrencyFee = (paymentMethod, currencySymbol) => {
  const fees = useGetWithdrawFiatFeesQueryResult();

  const { ratesByPair: currencyEURRate } = useCoinsRates({
    fromCoinSymbol: FIAT_CURRENCIES.EUR,
    toCoinSymbol: currencySymbol,
  });

  const { coinInfo } = useCoinsInfo({ coinSymbol: currencySymbol });

  const isCryptoCurrency = coinInfo?.type === COIN_TYPE.CRYPTO;

  if (isCryptoCurrency) {
    return Number.parseFloat(coinInfo?.withdraw_fee);
  }

  if (!paymentMethod || !currencySymbol || isEmpty(fees)) return 0;

  const { advCashFeeEUR, clearJunctionFeeEUR, defaultWithdrawFeeEUR } = fees;

  let fee = defaultWithdrawFeeEUR;

  if (paymentMethod === PAYMENT_METHOD.BANK_TRANSFER) {
    fee = clearJunctionFeeEUR;
  }

  if (paymentMethod === PAYMENT_METHOD.ADVCASH) {
    fee = advCashFeeEUR;
  }

  fee = fee * currencyEURRate;

  return fee;
};

export default useWithdrawCurrencyFee;
