import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@components/Button';
import Modal from '@components/modals/Modal';

import { extendLayout } from '@pages/TerminalPage/components/GridLayout/gridLayoutHelper';

import useGridLayout from '@hooks/gridLayout/useGridLayout';
import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

const ClearTemplateModal = () => {
  const { isOpen, closeModal } = useModal(modalsIds.CLEAR_TEMPLATE);

  const { setMappedLayout, rowsAmount, colsAmount } = useGridLayout();

  const handleClearLayoutClick = () => {
    setMappedLayout(extendLayout([], rowsAmount, colsAmount));

    closeModal();
  };

  return (
    <Modal
      className="modal-sm"
      onClose={closeModal}
      visible={isOpen}
      title={<FormattedMessage id="ClearLayout" />}
      footer={
        <div className="d-flex">
          <Button fill dangerous rounded onClick={handleClearLayoutClick}>
            <FormattedMessage id="ClearConfirm" />
          </Button>

          <Button fill secondary rounded onClick={closeModal} className="ms-10">
            <FormattedMessage id="Cancel" />
          </Button>
        </div>
      }
    >
      <FormattedMessage id="ClearLayoutQuestion" />
    </Modal>
  );
};

export default memo(ClearTemplateModal);
