import { memo, useRef, useState } from 'react';

import RCTooltip from 'rc-tooltip';

import useOnClickOutside from '@hooks/useClickOutside';

import { NOOP } from '@constants';

import 'rc-tooltip/assets/bootstrap.css';

import './Tooltip.module.scss';

const Tooltip = ({
  triggerRef,
  children,
  content,
  visible,
  isScrollableTooltip = false,
  manualTrigger = false,
  rawTrigger = false,
  onMouseEnter = NOOP,
  onMouseLeave = NOOP,
  onClick = NOOP,
  onClickOutside = NOOP,
  ...rest
}) => {
  const contentRef = useRef();

  const [isVisible, setIsVisible] = useState(manualTrigger ? visible : false);

  const handleClick = (e) => {
    if (manualTrigger) {
      onClick(e);
    } else {
      setIsVisible(true);
    }
  };

  const handleMouseEnter = (e) => {
    if (manualTrigger) {
      onMouseEnter(e);
    } else {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = (e) => {
    if (manualTrigger) {
      onMouseLeave(e);
    } else {
      setIsVisible(false);
    }
  };

  const getTooltipContainer = () => {
    return isScrollableTooltip
      ? document.getElementsByClassName('scrollable-layout')[0]
      : document.getElementById('page-container');
  };

  useOnClickOutside([contentRef], () => {
    if (manualTrigger) {
      onClickOutside();
    } else {
      setIsVisible(false);
    }
  });

  return (
    <RCTooltip
      {...(isScrollableTooltip
        ? {}
        : { visible: manualTrigger ? visible : isVisible })}
      placement="top"
      destroyTooltipOnHide
      mouseLeaveDelay={isScrollableTooltip ? 0.5 : 0.01}
      overlay={
        <div ref={contentRef} className="text-break">
          {content}
        </div>
      }
      getTooltipContainer={getTooltipContainer}
      {...rest}
    >
      {typeof children === 'function' ? (
        children({
          triggerRef,
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
          onClick: handleClick,
        })
      ) : (
        <div
          ref={triggerRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          {children}
        </div>
      )}
    </RCTooltip>
  );
};

export default memo(Tooltip);
