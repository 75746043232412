import { WalletsApiSelectors } from '@api/selectors';
import { useGetWithdrawFiatFeesQuery } from '@api/walletsAPI';

const useGetWithdrawFiatFeesQueryResult = () => {
  const { fees } = useGetWithdrawFiatFeesQuery(undefined, {
    selectFromResult: (result) => ({
      fees: WalletsApiSelectors.withdrawFiatFeesSelector(result),
    }),
  });

  return fees;
};

export default useGetWithdrawFiatFeesQueryResult;
