import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ItemTitle } from '@shared/components';

import { useChartData } from '@pages/AccountPage/hooks';
import DetailsButton from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/DetailsButton/DetailsButton';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { OverviewEarnWidget } from '../OverviewEarnWidget';

import { VisaCardTable } from './components';

import { WALLETS_ROUTES } from 'src/routes.constants';

import mockData from 'src/mock.json';

const VisaCard = () => {
  const { chartValue, coin, totalCardProfit } = mockData.VisaCard;
  const { chartData, color, value } = useChartData(chartValue);

  const { symbol, value: selectedUserCurrency } = useSelector(currencySelector);

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: coin,
    toCoinSymbol: selectedUserCurrency,
  });

  return (
    <>
      <ItemTitle
        title={<FormattedMessage id="VisaCard" />}
        arrowTitle={<FormattedMessage id="Details" />}
        navigation={{ to: WALLETS_ROUTES.VISA_CARD }}
      />

      <OverviewEarnWidget
        percentChange={value}
        color={color}
        title={<FormattedMessage id="TotalCardProfit" />}
        chartData={chartData}
        value={totalCardProfit}
        valueCurrency={coin}
        totalValue={totalCardProfit * ratesByPair}
        totalValueCurrency={selectedUserCurrency}
        totalValueSymbol={symbol}
      />

      <VisaCardTable />

      <DetailsButton navigation={{ to: WALLETS_ROUTES.VISA_LAST_TRANSACTIONS }}>
        <FormattedMessage id="LastTransactions" />
      </DetailsButton>
    </>
  );
};

export default memo(VisaCard);
