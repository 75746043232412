import { useContext } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import {
  extendLayout,
  getLayoutItem,
  removeItemTab,
  removeLayoutItem,
} from '@pages/TerminalPage/components/GridLayout/gridLayoutHelper';

import useTheme from '@hooks/useTheme';

import { GridLayoutContext } from './GridLayoutProvider';

import defaultTemplates from './defaultTemplates.json';

const useGridLayout = () => {
  const {
    mappedLayout,
    isLockedLayout,
    selectedTemplateId,
    templates,
    setMappedLayout,
    lockGridLayout,
    setIsUpdatingGridLayout,
    updateSelectedTemplateId,
    updateTemplates,
    getGridLayoutSettings,
    isLayoutHasChanged,
    isUpdatingLayout,
  } = useContext(GridLayoutContext);

  const { themeStyles } = useTheme();

  const rowsAmount = Number.parseFloat(themeStyles['layout-rows']);
  const colsAmount = Number.parseFloat(themeStyles['layout-columns']);

  const isDefaultTemplate = (templateId) => {
    const defaultTemplatesIds = Object.values(defaultTemplates).map(
      (template) => template.id,
    );

    return defaultTemplatesIds.includes(templateId);
  };

  const deleteLayoutItem = (currentLayout, layoutItemI) => {
    setMappedLayout(
      extendLayout(
        removeLayoutItem(currentLayout, layoutItemI),
        rowsAmount,
        colsAmount,
      ),
    );
  };

  const closeLayoutItemTab = (currentLayout, i, tabIndex) => {
    const layoutItem = getLayoutItem(currentLayout, i);

    const tabsLength = layoutItem.tabs.length;

    if (tabsLength > 1) {
      setMappedLayout(removeItemTab(currentLayout, i, tabIndex));
    } else {
      deleteLayoutItem(currentLayout, i);
    }
  };

  const addNewLayoutItem = (layoutItem) => {
    const updatedLayout = cloneDeep(mappedLayout);

    updatedLayout.push(layoutItem);

    setMappedLayout(extendLayout(updatedLayout, rowsAmount, colsAmount));
  };

  const selectTemplateById = (id) => {
    setIsUpdatingGridLayout(true);

    const allTemplates = [...Object.values(defaultTemplates), ...templates];

    // needs to reset grid completely
    setMappedLayout([]);

    // needs to set layout after all updates
    setTimeout(() => {
      const layout = allTemplates.find((template) => template.id === id).data;

      setMappedLayout(
        isLockedLayout ? layout : extendLayout(layout, rowsAmount, colsAmount),
      );

      setIsUpdatingGridLayout(false);
    }, 0);

    updateSelectedTemplateId(id);
  };

  const resetLayoutChanges = () => {
    selectTemplateById(selectedTemplateId);
  };

  return {
    mappedLayout,
    isLockedLayout,
    rowsAmount,
    colsAmount,
    selectedTemplateId,
    templates,
    setMappedLayout,
    lockGridLayout,
    updateSelectedTemplateId,
    updateTemplates,
    getGridLayoutSettings,
    isLayoutHasChanged,
    isUpdatingLayout,
    deleteLayoutItem,
    closeLayoutItemTab,
    addNewLayoutItem,
    selectTemplateById,
    resetLayoutChanges,
    isDefaultTemplate,
  };
};

export { defaultTemplates };

export default useGridLayout;
