import React, { memo } from 'react';

import cx from 'classnames';

import styles from './Tag.module.scss';

const Tag = ({
  title,
  outline,
  color = 'default',
  active,
  customStyles = {},
  ...props
}) => {
  const tagClass = `${color}_${outline ? 'outline' : 'solid'}`;

  const isTagClickable = !!props.onClick;

  return (
    <div
      className={cx(
        styles.tag,
        styles[color],
        styles[tagClass],
        customStyles.tag,
        {
          [styles.clickable]: isTagClickable,
          [styles.active]: !!active,
        },
      )}
      {...props}
    >
      {title}
    </div>
  );
};

export default memo(Tag);
