import { generatePath } from 'react-router';

import cx from 'classnames';

import Link from '@components/Link/Link';

import { HightLightText } from './HightLightText';

import { SERVICE_ROUTES } from 'src/routes.constants';

import styles from './SearchMenuItem.module.scss';

export const SearchMenuItemNews = ({ itemData, hightLightText, onClick }) => {
  return (
    <Link
      className={cx(styles.item, 'd-flex gap-10 align-items-center')}
      to={generatePath(SERVICE_ROUTES.BLOG)}
      onClick={() => onClick(itemData)}
    >
      {hightLightText ? (
        <HightLightText text={itemData.title} hightLightText={hightLightText} />
      ) : (
        itemData.title
      )}
    </Link>
  );
};
