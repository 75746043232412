import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import { useForm } from 'react-hook-form';

import { AccountModal } from '@shared/components';
import { Button, RadioInputGroup } from '@shared/ui';

import { CONFIRMATION_PHONE_OPTIONS } from '@pages/AuthPages/authConstants';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import { ReactComponent as MessageIconSVG } from '@icons/message-icon.svg';
import { ReactComponent as PhoneIconSVG } from '@icons/phone-icon.svg';
import { ReactComponent as WhatsAppIconSVG } from '@icons/whats-app-icon.svg';

import styles from './ConfirmPhoneModal.module.scss';

const ConfirmPhoneModal = () => {
  const { isOpen, closeModal } = useModal(modalsIds.CONFIRM_PHONE);

  const { handleSubmit, ...methods } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      confirmationPhoneOptions: CONFIRMATION_PHONE_OPTIONS.SMS,
    },
  });

  const isLoading = false;

  const onSubmit = (data) => {
    console.log('clicked', data);
  };

  // TODO: add when api will be ready
  //   useEffect(() => {
  //     if (isError || isSuccess) onClose();
  //   }, [isSuccess, isError, onClose]);

  const options = useMemo(
    () => [
      {
        value: CONFIRMATION_PHONE_OPTIONS.SMS,
        title: <FormattedMessage id="SMS" />,
        subtitle: <FormattedMessage id="WeWillSendYouCode" />,
        icon: <MessageIconSVG />,
      },
      {
        value: CONFIRMATION_PHONE_OPTIONS.WHATS_APP,
        title: <FormattedMessage id="WhatsApp" />,
        subtitle: <FormattedMessage id="WeWillSendYouCodeOverWiFi" />,
        icon: <WhatsAppIconSVG />,
        disabled: true,
      },
      {
        value: CONFIRMATION_PHONE_OPTIONS.PHONE_CALL,
        title: <FormattedMessage id="PhoneCall" />,
        subtitle: <FormattedMessage id="WeWillCallToYou" />,
        icon: <PhoneIconSVG />,
        disabled: true,
      },
    ],
    [],
  );

  const radioButtonsGroupItems = useMemo(
    () =>
      options.map((option) => ({
        value: option.value,
        label: (
          <div className={styles.item}>
            <div className="d-flex align-items-center me-8">
              <div>{option.icon}</div>
              <div className="ms-16">
                <div className={styles.title}>{option.title}</div>
                <div className={styles.subtitle}>{option.subtitle}</div>
              </div>
            </div>
          </div>
        ),
        disabled: option.disabled,
      })),
    [options],
  );

  return (
    <AccountModal
      title={<FormattedMessage id="OtherOptions" />}
      onClose={closeModal}
      isOpen={isOpen}
      className={classnames('modal-sm modal-md', styles.modal)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <RadioInputGroup
          name="confirmationPhoneOptions"
          items={radioButtonsGroupItems}
          size="l"
          labelPosition="left"
          customStyles={styles}
          {...methods}
        />

        <div className="mt-36">
          <Button
            type="submit"
            isLoading={isLoading}
            fill
            variant="outlined"
            customStyles={styles}
            disabled // Todo: remove, when api will be ready
          >
            <FormattedMessage id="SendCodeAgain" />
          </Button>
        </div>
      </form>
    </AccountModal>
  );
};

export default memo(ConfirmPhoneModal);
