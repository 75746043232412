import { memo } from 'react';

import classNames from 'classnames';

import { CustomSelect } from '@shared/components';

import Button from '@components/Button';

import { NOOP } from '@constants';

import { ReactComponent as DownIconSVG } from '@assets/images/icons/down-icon.svg';

import styles from './InputControls.module.scss';

const InputControls = ({
  name,
  select = false,
  selectId = 'input-controls-select',
  plusminus = false,
  appendLabel,
  appendOptions,
  selectedAppend,
  disabled = false,
  isSelectSearchable = true,
  onMinusPlusClick = NOOP,
  onSelect = NOOP,
}) => {
  if (select) {
    return (
      <div className={classNames(styles.input_control_section__select)}>
        <CustomSelect
          id={selectId}
          bordered
          chevronInversion
          value={selectedAppend}
          isSearchable={isSelectSearchable}
          options={appendOptions}
          onChange={onSelect}
          size="m"
          customStyles={styles}
          styles={{
            control: {
              border: 'none',
              borderRadius: 0,
              borderLeft: '1px solid var(--primary-input-border-color)',
            },
            menu: {
              fontSize: '13px',
              fontWeight: '400',
            },
          }}
        />
      </div>
    );
  }

  const isShowControls = plusminus || appendLabel;

  const handlePlusClick = () => {
    if (!disabled) {
      onMinusPlusClick(name);
    }
  };

  const handleMinusClick = () => {
    if (!disabled) {
      onMinusPlusClick(name, true);
    }
  };

  if (isShowControls) {
    return (
      <div className={styles.input_control_section}>
        {!!appendLabel && (
          <div className={styles.append_label}>{appendLabel}</div>
        )}

        {plusminus && (
          <div>
            <Button
              className={classNames(
                styles.number_control_button,
                styles.number_control_button__top,
              )}
              onClick={handlePlusClick}
              onLongPress={handlePlusClick}
            >
              <DownIconSVG
                width={10}
                height={6}
                className={styles.number_control_button__direct_icon}
              />
            </Button>

            <Button
              className={classNames(
                styles.number_control_button,
                styles.number_control_button__bottom,
              )}
              onClick={handleMinusClick}
              onLongPress={handleMinusClick}
            >
              <DownIconSVG
                width={10}
                height={6}
                className={styles.number_control_button__direct_icon}
              />
            </Button>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default memo(InputControls);
