import { transformTableFiltersValues } from '@shared/components/TableFilters/TableFilters.utils';

export const transformValues = (values = {}) => {
  const transformRestFields = (filters, values) => {
    if (values?.sideParam?.value) {
      filters.sideParam = values.sideParam.value;
    }
    if (values?.takerSideParam?.value) {
      filters.takerSideParam = values.takerSideParam.value;
    }
    if (values?.status?.value) {
      filters.status = values.status.value;
    }
    if (values?.type?.value) {
      filters.type = values.type.value;
    }

    return filters;
  };

  return transformTableFiltersValues(values, transformRestFields);
};
