import {
  TokenGrowth,
  VisaCard,
  LaunchpadAssets,
  InstantBonus,
  Referrals,
  Staking,
} from '@pages/AccountPage/Overview/OverviewEarnWidgets/components';

export const OVERVIEW_EARN_WIDGETS = [
  {
    widgetId: 1,
    component: Referrals,
    title: 'Referrals',
  },
  {
    widgetId: 2,
    component: Staking,
    title: 'Staking',
  },
  {
    widgetId: 3,
    component: InstantBonus,
    title: 'InstantBonus',
  },
  {
    widgetId: 4,
    component: LaunchpadAssets,
    title: 'LaunchpadAssets',
  },
  {
    widgetId: 5,
    component: VisaCard,
    title: 'VisaCard',
  },
  {
    widgetId: 6,
    component: TokenGrowth,
    title: 'TokenGrowth',
  },
];

const INITIAL_SELECTED_WIDGETS_IDS = OVERVIEW_EARN_WIDGETS.map(
  (item) => item.widgetId,
);

export const INITIAL_SELECTED_WIDGETS = OVERVIEW_EARN_WIDGETS.map(
  ({ widgetId, title }) => {
    return {
      widgetId,
      isActive: INITIAL_SELECTED_WIDGETS_IDS.includes(widgetId),
      title,
    };
  },
);
