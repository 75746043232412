import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { useFieldArray, useForm } from 'react-hook-form';

import { FormActions } from '@shared/components';

import { SwitchController } from '@components/Switch/Switch';

import { ReactComponent as ArrowDownSVG } from '@icons/arrow-down-icon.svg';

import styles from './WidgetsForm.module.scss';

const WidgetsForm = ({ widgets, onCancel, onSubmit }) => {
  const { control, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { widgets },
  });

  const { fields, update, swap } = useFieldArray({
    control,
    name: 'widgets',
  });

  const handleUp = (index) => {
    if (index === 0) {
      swap(index, fields.length - 1);
      return;
    }

    swap(index, index - 1);
  };

  const handleDown = (index) => {
    if (index === fields.length - 1) {
      swap(index, 0);
      return;
    }

    swap(index, index + 1);
  };

  const handleSwitch = ({ isActive, ...values }, index) => {
    // wait switch transition 0.2s
    setTimeout(() => {
      update(index, {
        ...values,
        isActive: !isActive,
      });
    }, 200);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classNames(styles.widgets, 'mb-26')}>
        {fields.map((field, index) => (
          <div
            className={classNames(
              styles.widget,
              'd-flex justify-content-between',
            )}
            key={field.id}
          >
            <div
              onClick={() => handleSwitch(field, index)}
              className="d-flex gap-10 align-items-center cursor-pointer"
            >
              <SwitchController
                control={control}
                name={`widgets[${index}].isActive`}
              />
              <FormattedMessage id={field.title} />
            </div>
            <div className="d-flex gap-18">
              <button
                type="button"
                onClick={() => handleUp(index)}
                className={classNames(styles.arrow_icon, styles.up_icon)}
              >
                <ArrowDownSVG width={16} height={10} />
              </button>
              <button
                type="button"
                onClick={() => handleDown(index)}
                className={styles.arrow_icon}
              >
                <ArrowDownSVG width={16} height={10} />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div>
        <FormActions
          submitText={<FormattedMessage id="Apply" />}
          submitButtonProps={{
            color: 'secondary',
            size: 'm',
          }}
          cancelButtonProps={{
            size: 'm',
          }}
          onCancel={onCancel}
          customStyles={styles}
        />
      </div>
    </form>
  );
};

export default memo(WidgetsForm);
