import { useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';

import { useGetClearJunctionWithdrawSettingsQueryResult } from '@store/api/hooks';

const useGetClearJunctionWithdrawFieldsByTransferType = (
  { selectedMethodId },
  options,
) => {
  const { settings } = useGetClearJunctionWithdrawSettingsQueryResult(
    undefined,
    options,
  );
  const { methods, firstName, lastName } = settings;

  const [requiredFields, setRequiredFields] = useState([]);

  const requiredFieldsWithFullWidth = ['accountNumber', 'iban'];

  const transferTypes = useMemo(
    () =>
      methods.map((method) => ({
        value: method.value,
        label: method.label,
      })),
    [methods],
  );

  const selectedTransferType = useMemo(
    () => methods.find((method) => method.value === selectedMethodId),
    [methods, selectedMethodId],
  );

  useEffect(() => {
    if (isEmpty(methods)) return;

    let selectedRequiredFields = methods[0].requiredFields;

    if (selectedTransferType) {
      selectedRequiredFields = selectedTransferType?.requiredFields;
    }

    const mappedRequiredFields = selectedRequiredFields.map((field, index) => ({
      id: `id-${field}-${index}`,
      name: field,
      isFullFieldWidth: requiredFieldsWithFullWidth.includes(field),
    }));

    // sorting fields by field width (full fields is first)
    mappedRequiredFields.sort(
      (a, b) => b.isFullFieldWidth - a.isFullFieldWidth,
    );

    setRequiredFields(mappedRequiredFields);
  }, [methods, selectedTransferType]);

  return {
    transferTypes,
    selectedTransferType,
    requiredFields,
    firstName,
    lastName,
  };
};

export default useGetClearJunctionWithdrawFieldsByTransferType;
