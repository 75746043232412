export const PIE_CHART_RED_COLOR = '#FF6842';

export const PIE_CHART_COLORS = [
  '#FFCC10',
  '#23E5DB',
  '#1B9992',
  '#FE875D',
  PIE_CHART_RED_COLOR,
  '#FE875D',
];

export const INITIAL_SLIDER_VALUE = 0;
export const CURRENCY_PRECISION = 2;
export const VISIBLE_ELEMENTS = 4;
export const PLACEHOLDER_VALUE = 0;
export const WALLETS_STAKING_TABS = {
  AVAILABLE_PLANS: 0,
  MY_STAKING: 1,
};

export const GROUPED_AVAILABLE_PLANS = {
  ALL: 'all',
  ALTS: 'alts',
  FROM_5_TO_10: '5_10',
  MORE_10: '10+',
};
