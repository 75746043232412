import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@shared/components';
import { Select } from '@shared/ui';

import {
  ExternalServices,
  SepaRequisites,
  SettingTitle,
} from '@pages/WalletsPage/VisaCardSettings/components';
import { CARD_REQUISITES_IDS } from '@pages/WalletsPage/VisaCardSettings/VisaCardSettings.constants';

import useBreakpoints from '@hooks/useBreakpoints';

import s from './CardRequisites.module.scss';

const CardRequisites = () => {
  const [selectedRequisiteId, setSelectedRequisiteId] = useState();

  const { isMobile } = useBreakpoints();

  const requisites = useMemo(() => {
    const tabs = [
      {
        id: CARD_REQUISITES_IDS.SEPA,
        label: (
          <div className={s.requisite_tab}>
            <FormattedMessage id="SEPA" />
          </div>
        ),
        content: <SepaRequisites />,
      },
      {
        id: CARD_REQUISITES_IDS.SEPA_INSTANT,
        label: (
          <div className={s.requisite_tab}>
            <FormattedMessage id="SEPAInstant" />
          </div>
        ),
        content: 'SEPAInstant',
      },
      {
        id: CARD_REQUISITES_IDS.SWIFT,
        label: (
          <div className={s.requisite_tab}>
            <FormattedMessage id="Swift" />
          </div>
        ),
        content: 'Swift',
      },
      {
        id: CARD_REQUISITES_IDS.EXTERNAL_SERVICES,
        label: (
          <div className={s.requisite_tab}>
            <FormattedMessage id="ExternalServices" />
          </div>
        ),
        content: <ExternalServices />,
      },
    ];

    if (isMobile) {
      const selectTab = {
        id: 'tab-select',
        label: ({ onTabClick, tabIndex, isSelect }) => {
          const handleSelectItemClick = ({ id }) => {
            setSelectedRequisiteId(id);
            onTabClick({ tabIndex, isSelect });
          };

          return (
            <Select
              options={tabs.slice(-2)}
              isSearchable={false}
              defaultValue={tabs.slice(-2)[0]}
              onChange={handleSelectItemClick}
              size="m"
              styles={{
                control: {
                  minHeight: '20px',
                  height: '20px',
                  fontWeight: '500',
                  fontSize: '13px',
                },
                menuPortal: {
                  width: 'max-content',
                  minWidth: '150px',
                },
              }}
            />
          );
        },
        isSelect: true,
        content: tabs.find((tab) => tab.id === selectedRequisiteId)?.content,
      };

      return [...tabs.slice(0, 2), selectTab];
    }

    return tabs;
  }, [isMobile, selectedRequisiteId]);

  return (
    <div>
      <SettingTitle>
        <FormattedMessage id="CardRequisites" />
      </SettingTitle>
      <div className="mt-20">
        <Tabs>
          <TabList>
            {requisites.map((requisite) => (
              <Tab key={`tab-${requisite.id}`} isSelect={requisite?.isSelect}>
                {requisite.label}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {requisites.map((requisite) => (
              <TabPanel key={`tab-panel-${requisite.id}`}>
                <div className={s.requisite_panel}>{requisite.content}</div>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default CardRequisites;
