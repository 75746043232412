import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { useCurrencyIcon } from '@shared/hooks';
import { Button } from '@shared/ui';

import Link from '@components/Link';

import { useCoinsInfo } from '@api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { EXTERNAL_LINKS, WALLETS_ROUTES } from '../../../../routes.constants';

import styles from './StakingSwiperSlide.module.scss';

const StakingSwiperSlide = ({
  currency,
  percent,
  period,
  inversion,
  showCountries = true,
  customStyles = {},
  buttonProps = {},
}) => {
  const currencyIcon = useCurrencyIcon(currency);

  const { isTabletDown } = useBreakpoints();

  const { coinInfo } = useCoinsInfo({ coinSymbol: currency });

  return (
    <div
      className={cx(styles.card, customStyles.card, {
        [styles.inversion]: inversion,
      })}
    >
      <div className={styles.card_header}>
        <div className={cx(styles.icon, customStyles.icon)}>{currencyIcon}</div>
        <div className={cx(styles.title, customStyles.title)}>
          <h2>{currency.toUpperCase()}</h2>
          <h4>
            {coinInfo?.name}{' '}
            {!isTabletDown && <FormattedMessage id="Staking" />}
          </h4>
        </div>
      </div>

      <div className={cx(styles.content, customStyles.content)}>
        <div className={cx(styles.percent, customStyles.percent)}>
          +{percent}%{showCountries && '*'}
        </div>
        <span className={cx(styles.percent__text, customStyles.percent__text)}>
          <FormattedMessage id="PeriodRewards" values={{ period }} />
        </span>
      </div>

      <div className="mt-20">
        <Button
          navigation={{
            to: WALLETS_ROUTES.STAKING,
            state: {
              formValues: {
                payCurrency: currency,
                period: { period },
              },
            },
          }}
          fill
          size={isTabletDown ? 'm' : 'l'}
          customStyles={{ button: [styles.stake_button] }}
          {...buttonProps}
        >
          <FormattedMessage id="Stake" /> {currency.toUpperCase()}
        </Button>

        {showCountries && (
          <p
            className={cx(
              styles.countries,
              customStyles.countries,
              'text-truncate',
            )}
          >
            *{' '}
            <FormattedMessage
              id="EligibleCountriesOnly"
              values={{
                a: (link) => (
                  <Link
                    target="_blank"
                    green
                    to={EXTERNAL_LINKS.STAKING_COUNTRIES_LIST}
                    rel="noreferrer"
                  >
                    {link}
                  </Link>
                ),
              }}
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(StakingSwiperSlide);
