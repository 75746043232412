import React, { memo } from 'react';

import classNames from 'classnames';

import styles from './LevelsDivider.module.scss';

const LevelsDivider = ({ index, title, customStyles = {}, className }) => {
  return (
    <div
      className={classNames(
        styles.levels_divider,
        customStyles.levels_divider,
        className,
      )}
    >
      <div className={styles.index}>{index}</div>

      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default memo(LevelsDivider);
