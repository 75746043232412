import { useMemo } from 'react';

import {
  useGetMyContractsQuery,
  useLazyGetMyContractsQuery,
} from '@api/bonusAccountAPI';
import { MyContractsApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isMyContractsSuccess: result.isSuccess,
  };

  dataResult.contracts = MyContractsApiSelectors.myContractsSelector(result);

  return dataResult;
};

export const useMyContracts = ({ limit } = {}, options) => {
  const result = useGetMyContractsQuery({ limit }, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export const useLazyMyContracts = (options) => {
  const [trigger, result] = useLazyGetMyContractsQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return [trigger, data];
};
