import { useIntl } from 'react-intl';

import { InputController } from '@shared/ui/Input';

import { ReactComponent as SearchIconSVG } from '@icons/search-icon.svg';

import s from './SearchBar.module.scss';

const SearchBar = ({ onChange, ...props }) => {
  const intl = useIntl();
  return (
    <InputController
      bordered
      placeholder={intl.formatMessage({ id: 'SearchPlaceholder' })}
      rounded
      suffix={<SearchIconSVG className={s.search_icon} />}
      customStyles={{ ...s, ...props?.customStyles }}
      {...props}
    />
  );
};

export default SearchBar;
