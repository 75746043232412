import React, { memo } from 'react';

import { PersonalData } from '@shared/components';
import { ProgressBar } from '@shared/ui';

const PersonalProgressBar = ({
  percentages,
  height = 3,
  color,
  isShowOnZeroPercent = false,
  isShowPercents = true,
  isPill,
  customStyles = {},
}) => {
  return (
    <PersonalData
      data={
        <ProgressBar
          percentages={percentages}
          height={height}
          color={color}
          isShowOnZeroPercent={isShowOnZeroPercent}
          isShowPercents={isShowPercents}
          isPill={isPill}
          customStyles={customStyles}
        />
      }
    />
  );
};

export default memo(PersonalProgressBar);
