import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import cx from 'classnames';

import { HeaderCollapsible } from '@shared/components';

import {
  VisaCardCount,
  VisaCardProgress,
} from '@pages/WalletsPage/VisaCardDetails/components';
import { VisaCardMock } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.mocks';

import { currencySelector } from '@store/settings/settingsSelectors';

import { useGetUserCurrentCardBalanceQueryResult } from '@api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import s from './VisaCardCounts.module.scss';

const VisaCardCounts = ({ currentAmount, amountLimit }) => {
  const { cardBalance } = useGetUserCurrentCardBalanceQueryResult();

  const { isTabletDown } = useBreakpoints();

  const { symbol } = useSelector(currencySelector);

  const cardInfo = [
    {
      title: <FormattedMessage id="Cashback" />,
      amount: VisaCardMock.counts.cashback.amount,
      increase: VisaCardMock.counts.cashback.increase,
    },
    {
      title: <FormattedMessage id="SuperCashback" />,
      amount: VisaCardMock.counts.superCashback.amount,
      increase: VisaCardMock.counts.superCashback.increase,
    },
    {
      title: <FormattedMessage id="TotalReferrals" />,
      amount: VisaCardMock.counts.totalReferrals.amount,
      increase: VisaCardMock.counts.totalReferrals.increase,
    },
    {
      title: <FormattedMessage id="TotalReferralsRewards" />,
      amount: VisaCardMock.counts.totalReferralsRewards.amount,
      increase: VisaCardMock.counts.totalReferralsRewards.increase,
    },
  ];

  if (isTabletDown) {
    return (
      <HeaderCollapsible
        customStyles={s}
        isShowCollapsible
        headerComponent={
          <div
            className={cx(s.visa_card_counts, s.visa_card_counts__collapsible)}
          >
            {cardInfo.slice(0, 2).map((count, index) => (
              <VisaCardCount
                count={count}
                symbol={symbol}
                key={`count-collapsible-header-${index}`}
              />
            ))}
          </div>
        }
        collapsibleComponent={
          <div>
            <div
              className={cx(
                s.visa_card_counts,
                s.visa_card_counts__collapsible,
                s.collapse_item,
              )}
            >
              {cardInfo.slice(2).map((count, index) => (
                <VisaCardCount
                  count={count}
                  symbol={symbol}
                  key={`count-collapsible-${index}`}
                />
              ))}
            </div>
            <div className={s.collapse_item}>
              <VisaCardProgress
                currentAmount={cardBalance}
                amountLimit={amountLimit}
              />
            </div>
          </div>
        }
      />
    );
  }

  return (
    <div>
      <div className={s.visa_card_counts}>
        {cardInfo.map((count, index) => (
          <VisaCardCount count={count} symbol={symbol} key={`count-${index}`} />
        ))}
      </div>
      <div className="mt-50">
        <VisaCardProgress
          currentAmount={currentAmount}
          amountLimit={amountLimit}
        />
      </div>
    </div>
  );
};

export default VisaCardCounts;
