import { isEmpty } from 'lodash';

import { WALLET_FEATURES } from '@shared/constants';

import { useGetProfileInfoQueryResult } from '../../../store/api/hooks';

const useUnblockedWalletFeatures = () => {
  let walletFeatures = Object.values(WALLET_FEATURES);

  const { depositWithdrawBlocking } = useGetProfileInfoQueryResult();

  if (!depositWithdrawBlocking) {
    return [];
  }

  const { all, user } = depositWithdrawBlocking;

  const blockedMainFeatures = all.filter((feature) => feature.value === 'true');

  const blockAllFeaturesByType = (type) => {
    walletFeatures = walletFeatures.filter(
      (feature) => !feature.includes(type),
    );
  };

  if (!isEmpty(blockedMainFeatures)) {
    const isDepositFeatureBlocked = !!blockedMainFeatures.find(
      ({ feature }) => feature === WALLET_FEATURES.DEPOSIT_ALL,
    );
    const isWithdrawFeatureBlocked = !!blockedMainFeatures.find(
      ({ feature }) => feature === WALLET_FEATURES.WITHDRAW_ALL,
    );

    if (isDepositFeatureBlocked) {
      blockAllFeaturesByType('deposit');
    }

    if (isWithdrawFeatureBlocked) {
      blockAllFeaturesByType('withdraw');
    }
  }

  if (!isEmpty(user)) {
    walletFeatures = walletFeatures.filter((feature) => {
      if (!user.hasOwnProperty(feature)) return true;

      return !user[feature];
    });
  }

  return walletFeatures;
};

export default useUnblockedWalletFeatures;
