import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Tooltip from '@components/Tooltip';

import styles from './TrailingStopTooltip.module.scss';

const TrailingStopTooltip = ({
  price,
  activationPrice,
  callbackRate,
  callbackType,
}) => {
  return (
    <div className="position-relative">
      <Tooltip
        className={styles.container}
        content={
          <div className="trailing-stop-tooltip-wrapper">
            <div className="section">
              <div className="title">
                <FormattedMessage id="Price" />:
              </div>

              <div className="value">{price}</div>
            </div>

            <div className="section">
              <div className="title">
                <FormattedMessage id="ActivationPrice" />:
              </div>

              <div className="value">{activationPrice}</div>
            </div>

            <div className="section">
              <div className="title">
                <FormattedMessage id="CallbackRate" />:
              </div>

              <div className="value">{callbackRate}</div>
            </div>

            <div className="section">
              <div className="title">
                <FormattedMessage id="CallbackType" />:
              </div>

              <div className="value">{callbackType}</div>
            </div>
          </div>
        }
      >
        <FormattedMessage id="TrailingStop" />
      </Tooltip>
    </div>
  );
};

export default memo(TrailingStopTooltip);
