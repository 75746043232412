import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';

import reactFastCompare from 'react-fast-compare';

const useNavigationBlocker = ({ isBlocking }) => {
  useBeforeUnload(
    (event) => {
      if (isBlocking) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    { capture: true },
  );

  const blocker = useBlocker((history) => {
    if (
      isBlocking &&
      !reactFastCompare(history.nextLocation, history.currentLocation)
    ) {
      return true;
    }

    return false;
  });

  return blocker;
};

export default useNavigationBlocker;
