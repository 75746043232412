import { createElement, forwardRef, useId, useState } from 'react';

import cx from 'classnames';
import PhoneInput from 'react-phone-number-input/input';

import { FormController } from '@shared/components';

import useRunAfterUpdate from '@hooks/useRunAfterUpdate';

import { toFloat, toInteger } from '@utils/numbers';

import { NOOP } from '@constants';

import s from './Input.module.scss';

const Input = forwardRef(
  (
    {
      maxLength,
      onlyNumbers,
      onlyInteger,
      precision,
      label,
      prefix,
      suffix,
      // styles
      rounded,
      bordered = true,
      underlined,
      size = 'm', // 's', 'l', 'xl', 'xxl'
      inversion,
      customStyles = {},
      isInvalid,
      onChange = NOOP,
      isPhoneInput,
      ...props // only input properties
    },
    ref,
  ) => {
    const id = useId();

    const [count, setCount] = useState(0);

    const runAfterUpdate = useRunAfterUpdate();

    const handleChange = (event) => {
      if (!!maxLength) {
        setCount(event.target.value.length);
      }

      if (onlyNumbers) {
        const currentCursorIndex = event.target.selectionStart;

        onChange(toFloat(event.target.value, precision));

        // to save cursor position after replace
        runAfterUpdate(() => {
          event.target.selectionStart = currentCursorIndex;
          event.target.selectionEnd = currentCursorIndex;
        });
      } else if (onlyInteger) {
        onChange(toInteger(event.target.value));
      } else if (isPhoneInput) {
        onChange(event);
      } else {
        onChange(event.target.value);
      }
    };

    return (
      <div
        className={cx(s.input, customStyles.input, s[size], {
          [s.rounded]: rounded,
          [s.bordered]: bordered,
          [s.underlined]: underlined,
          [s.inversion]: inversion,
        })}
      >
        {label && (
          <label htmlFor={id} className={cx(s.label, customStyles.label)}>
            {label}
          </label>
        )}
        <div className={cx(s.input_container, customStyles.input_container)}>
          {prefix && (
            <div className={cx(s.prefix, customStyles.prefix)}>{prefix}</div>
          )}
          {createElement(isPhoneInput ? PhoneInput : 'input', {
            ref,
            id,
            className: cx(s.input_field, customStyles.input_field, {
              [s.with_prefix]: prefix,
              [s.with_suffix]: suffix,
            }),
            'aria-invalid': isInvalid,
            onChange: handleChange,
            maxLength,
            ...props,
            placeholder: isPhoneInput ? '050 5555 000...' : props.placeholder,
            international: isPhoneInput ? true : undefined,
          })}
          {suffix && (
            <div className={cx(s.suffix, customStyles.suffix)}>{suffix}</div>
          )}
        </div>

        {maxLength && (
          <div
            className={cx(
              s.input_field_counter,
              customStyles.input_field_counter,
            )}
          >
            {count}/{maxLength}
          </div>
        )}
      </div>
    );
  },
);

export default Input;

export const InputController = (props) => {
  return <FormController component={Input} {...props} />;
};
