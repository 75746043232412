import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { ButtonArrow } from '@shared/ui';
import { ARROW_DIRECTION } from '@shared/ui/ButtonArrow/ButtonArrow';

import styles from './AuthLayoutFrame.module.scss';

const AuthLayoutFrame = ({ children, isWideContainer, onBack }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div
          className={cx({
            'col-sm-12 col-md-9 col-lg-6 m-auto': !isWideContainer,
            'col-md-12 col-lg-9 p-0': isWideContainer,
          })}
        >
          {!!onBack && (
            <div className="mb-40">
              <ButtonArrow
                onClick={onBack}
                variant="text"
                color="primary"
                direction={ARROW_DIRECTION.LEFT}
              >
                <FormattedMessage id="Back" />
              </ButtonArrow>
            </div>
          )}

          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(AuthLayoutFrame);
