import React, { memo } from 'react';

import QRCode from 'qrcode.react';

import useTheme from '@hooks/useTheme';

const CustomQRCode = (props) => {
  const { themeStyles } = useTheme();

  return (
    <QRCode
      renderAs="svg"
      bgColor="transparent"
      fgColor={themeStyles.dark}
      {...props}
    />
  );
};

export default memo(CustomQRCode);
