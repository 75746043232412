import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router';

import { BOT_TYPE, LAYOUT_IDS, TERMINAL_URL_QUERIES } from '@shared/constants';

import useBreakpoints from '@hooks/useBreakpoints';

import { NOOP } from '@constants';

import { ReactComponent as OthersIconSVG } from '@icons/others-icon.svg';
import { ReactComponent as ServicesIconSVG } from '@icons/services-icon.svg';
import { ReactComponent as TradeIconSVG } from '@icons/trade-icon.svg';
import { ReactComponent as TradingBotsIconSVG } from '@icons/trading-bots-icon.svg';
import { ReactComponent as VisaCardIconSVG } from '@icons/visa-icon.svg';
import { ReactComponent as WalletIconSVG } from '@icons/wallet-icon.svg';

import ProductsMenuNavigationItem from './components/ProductsMenuNavigationItem';

import {
  DEPOSIT_ROUTES,
  MAIN_ROUTES,
  REFERRAL_ROUTES,
  SERVICE_ROUTES,
  TOKEN_ROUTES,
  TRADE_ROUTES,
  VISA_ROUTES,
  WALLETS_ROUTES,
} from 'src/routes.constants';

const ProductsMenuNavigation = ({ onLinkClick = NOOP }) => {
  const intl = useIntl();
  const breakpoints = useBreakpoints();

  // TODO: mock data, need add logic for new product badge
  const navigationItems = {
    PRDXToken: {
      title: intl.formatMessage({ id: 'PRDXToken' }),
      Icon: OthersIconSVG,
      links: [
        {
          name: intl.formatMessage({ id: 'ParamountDaxToken' }),
          to: SERVICE_ROUTES.PRDX_TOKEN,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'PRDXBurn' }),
          to: TOKEN_ROUTES.PRDX_BURN,
          isNewBadge: false,
        },
      ],
    },
    trade: {
      title: intl.formatMessage({ id: 'Trade' }),
      Icon: TradeIconSVG,
      links: [
        {
          name: intl.formatMessage({ id: 'SimpleConverter' }),
          to: MAIN_ROUTES.SIMPLE_CONVERTER,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'BasicExchange' }),
          to: generatePath(MAIN_ROUTES.TERMINAL),
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'Advanced' }),
          to: `${generatePath(MAIN_ROUTES.TERMINAL)}?${
            TERMINAL_URL_QUERIES.LAYOUT_ID
          }=${LAYOUT_IDS.ADVANCED}`,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'FullSize' }),
          to: TRADE_ROUTES.FULL_SIZE,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'MarginTrading' }),
          to: TRADE_ROUTES.MARGIN_TRADING,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'Features' }),
          to: TRADE_ROUTES.FEATURES,
          isNewBadge: false,
        },
      ],
    },
    visaCard: {
      title: intl.formatMessage({ id: 'VisaCard' }),
      Icon: VisaCardIconSVG,
      isNewBadge: true,
      links: [
        {
          name: intl.formatMessage({ id: 'OrderCard' }),
          to: VISA_ROUTES.ORDER_CARD,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'ParamountDaxCard' }),
          to: VISA_ROUTES.HOME,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'CardReferralProgram' }),
          to: VISA_ROUTES.CARD_REFERRAL_PROGRAM,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'AffiliateMerchants' }),
          to: VISA_ROUTES.AFFILIATE_MERCHANTS,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'MerchantProgram' }),
          to: VISA_ROUTES.MERCHANT_PROGRAM,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'FindYourOwnRarity' }),
          to: VISA_ROUTES.FIND_YOUR_OWN_RARITY,
          isNewBadge: false,
        },
      ],
    },
    buyCrypto: {
      title: intl.formatMessage({ id: 'BuyCrypto' }),
      Icon: WalletIconSVG,
      links: [
        {
          name: intl.formatMessage({ id: 'BuyWithCard' }),
          to: DEPOSIT_ROUTES.CARD,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'BankDeposit' }),
          to: DEPOSIT_ROUTES.BANK,
          isNewBadge: false,
        },
      ],
    },
    tradingBots: {
      title: intl.formatMessage({ id: 'TradingBots' }),
      Icon: TradingBotsIconSVG,
      links: [
        {
          name: intl.formatMessage({ id: 'AboutBots' }),
          to: MAIN_ROUTES.TRADING_BOTS,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'GridTrading' }),
          to: WALLETS_ROUTES.TRADING_BOTS_START,
          state: { botType: BOT_TYPE.grid },
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'Trend' }),
          to: WALLETS_ROUTES.TRADING_BOTS_START,
          state: { botType: BOT_TYPE.trend },
          isNewBadge: false,
        },
      ],
    },
    services: {
      title: intl.formatMessage({ id: 'Services' }),
      Icon: ServicesIconSVG,
      links: [
        {
          name: intl.formatMessage({ id: 'ReferralProgram' }),
          to: REFERRAL_ROUTES.OVERVIEW,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'InstantBonus' }),
          to: WALLETS_ROUTES.INSTANT_BONUS,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'StakingAssets' }),
          to: MAIN_ROUTES.STAKING_ASSETS,
          isNewBadge: false,
        },
        {
          name: intl.formatMessage({ id: 'LoanCrypto' }),
          to: WALLETS_ROUTES.LOAN_CRYPTO,
          isNewBadge: true,
        },
        {
          name: intl.formatMessage({ id: 'Launchpad' }),
          to: WALLETS_ROUTES.LAUNCHPAD,
          isNewBadge: false,
        },
      ],
    },
  };

  const desktopAndLaptop = [
    [navigationItems.trade],
    [navigationItems.visaCard],
    [navigationItems.buyCrypto, navigationItems.tradingBots],
    [navigationItems.services],
    [navigationItems.PRDXToken],
  ];
  const tablet = [
    [
      navigationItems.trade,
      navigationItems.buyCrypto,
      navigationItems.tradingBots,
    ],
    [
      navigationItems.visaCard,
      navigationItems.services,
      navigationItems.PRDXToken,
    ],
  ];
  const mobile = [
    [
      navigationItems.trade,
      navigationItems.visaCard,
      navigationItems.buyCrypto,
      navigationItems.services,
      navigationItems.tradingBots,
      navigationItems.PRDXToken,
    ],
  ];

  const blocks = useMemo(() => {
    if (breakpoints.isDesktop || breakpoints.isLaptop) return desktopAndLaptop;
    if (breakpoints.isTablet) return tablet;
    return mobile;
  }, [breakpoints]);

  return (
    <div className="products-menu-navigation">
      {blocks.map((block, index) => (
        <div index={index} className="products-menu-navigation-item-wrap">
          {block.map((item, index) => (
            <ProductsMenuNavigationItem
              key={item.title}
              item={item}
              index={index}
              onLinkClick={onLinkClick}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default memo(ProductsMenuNavigation);
