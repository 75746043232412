import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Card } from '@shared/components';
import { Button, InputController, ProgressBar } from '@shared/ui';

import {
  SettingDescription,
  SettingTitle,
} from '@pages/WalletsPage/VisaCardSettings/components';

import { currencySelector } from '@store/settings/settingsSelectors';

import useTheme from '@hooks/useTheme';

import { format } from '@utils/numbers';

import { FIAT_PRECISION } from '@constants';

import s from './SpendingLimit.module.scss';

const SpendingLimit = () => {
  const intl = useIntl();

  const { themeStyles } = useTheme();

  const currency = useSelector(currencySelector);

  const NEW_LIMIT_FIELD_NAME = 'new_limit';

  const cardLimit = 1000;
  const spentMoney = 750;

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      [NEW_LIMIT_FIELD_NAME]: '',
    },
  });

  const percent = useMemo(
    () =>
      format((spentMoney * 100) / cardLimit, {
        precision: 0,
      }),
    [cardLimit, spentMoney],
  );

  const moneyLeft = useMemo(
    () =>
      format(cardLimit - spentMoney, {
        precision: FIAT_PRECISION,
      }),
    [cardLimit, spentMoney],
  );

  const spentMoneyFormatted = useMemo(
    () =>
      format(spentMoney, {
        precision: FIAT_PRECISION,
      }),
    [spentMoney],
  );

  const cardLimitFormatted = useMemo(
    () => format(cardLimit, { precision: FIAT_PRECISION }),
    [cardLimit],
  );

  const handleUpdateCardLimit = (values) => {
    console.log('values', values);
  };

  const percentColor = useMemo(() => {
    if (Number(percent) >= 100) return themeStyles?.red;
    if (Number(percent) >= 90) return themeStyles?.orange;
    return themeStyles?.green;
  }, [themeStyles, percent]);

  return (
    <div>
      <SettingTitle>
        <FormattedMessage id="SpendingLimit" />
      </SettingTitle>
      <div className={s.content}>
        <div className={s.card_wrapper}>
          <Card color="secondary" customStyles={s}>
            <div className="py-xl-30 px-xl-30 py-18 px-24">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className={cx({
                    [s.text_danger]: Number(percent) >= 100,
                  })}
                >
                  <span className={s.percent}>{percent}</span>
                  <span className={s.percent__symbol}>%</span>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <label className={s.label__inversion}>
                    <FormattedMessage id="MoneyLeft" />:
                  </label>
                  <div
                    className={cx(s.card_rest_money, {
                      [s.text_danger]: Number(percent) >= 100,
                    })}
                  >
                    {moneyLeft} {currency?.symbol}
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <ProgressBar
                  percentages={Number(percent) >= 100 ? 100 : percent}
                  isShowPercents={false}
                  inversion
                  color={percentColor}
                />
              </div>
              <div className="mt-16 d-flex align-items-center justify-content-between">
                <label className={s.label__inversion}>
                  <FormattedMessage id="LimitUsage" />:
                </label>
                <div className={s.limit_usage}>
                  {spentMoneyFormatted} <FormattedMessage id="of" /> {cardLimit}{' '}
                  {currency?.symbol}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className={s.form_wrapper}>
          <SettingDescription>
            <FormattedMessage id="SpendingLimitDescription" />
          </SettingDescription>
          <form
            className="mt-24"
            onSubmit={handleSubmit(handleUpdateCardLimit)}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className={s.label}>
                <FormattedMessage id="NewLimit" />:
              </div>
              <div>
                <span className={s.current_limit__label}>
                  <FormattedMessage id="Current" />:
                </span>
                <span className={s.current_limit}>
                  {' '}
                  {cardLimitFormatted} {currency?.symbol}
                </span>
              </div>
            </div>
            <InputController
              name={NEW_LIMIT_FIELD_NAME}
              bordered={false}
              underlined
              onlyNumbers
              precision={FIAT_PRECISION}
              rules={{
                required: intl.formatMessage({ id: 'FieldRequired' }),
              }}
              suffix={<span className={s.input_suffix}>{currency?.value}</span>}
              placeholder={`${intl.formatMessage({ id: 'TypeNewLimit' })}...`}
              customStyles={s}
              {...methods}
            />
            <div className="mt-18">
              <Button customStyles={s}>
                <FormattedMessage id="Apply" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SpendingLimit;
