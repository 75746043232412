import { useCallback, useEffect, useRef, useState } from 'react';

import { NOOP } from '@constants';

const useLongPress = (
  onLongPress,
  onClick,
  { shouldPreventDefault = true, delay = 500 } = {},
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const [pressingCount, setPressingCount] = useState(0);
  const timeoutRef = useRef();
  const targetRef = useRef();

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });

        targetRef.current = event.target;
      }

      timeoutRef.current = setTimeout(() => setLongPressTriggered(true), delay);
    },
    [onLongPress, delay, shouldPreventDefault],
  );

  // fire onLongPress during press event
  useEffect(() => {
    if (onLongPress !== NOOP) {
      if (longPressTriggered) {
        onLongPress();
        setTimeout(() => setPressingCount((prevState) => prevState + 1), 100);
      } else {
        setPressingCount(0);
      }
    }
  }, [longPressTriggered, pressingCount]);

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (shouldTriggerClick && !longPressTriggered) {
        onClick(event);
      }

      setLongPressTriggered(false);

      if (shouldPreventDefault && targetRef.current) {
        targetRef.current.removeEventListener('touchend', preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered],
  );

  if (onLongPress === NOOP) {
    return {
      onClick,
    };
  }

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  };
};

const isTouchEvent = (event) => {
  return 'touches' in event;
};

const preventDefault = (event) => {
  if (!isTouchEvent(event)) {
    return;
  }

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export default useLongPress;
