import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '@api/utils';

const defaultData = [];

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const marketInfoFromMarketsInfoMapSelector = createSelector(
  (marketsInfoMap) => marketsInfoMap,
  (marketsInfoMap, marketId) => marketId?.toLowerCase(),
  (marketsInfoMap, marketId) => {
    return marketsInfoMap.find(({ id }) => id === marketId);
  },
);

export const MarketsApiSelectors = {
  dataSelector,
  marketInfoFromMarketsInfoMapSelector,
};
