import React from 'react';

import { useController } from 'react-hook-form';

import { NOOP } from '@constants';

const FieldController = ({
  component: Component,
  control,
  name: $name,
  rules,
  defaultValue,
  transform = {},
  showErrorMessage = true,
  valueAccessor = 'value',
  changeHandlerAccessor = 'onChange',
  onChange: $onChange = NOOP,
  onBlur: $onBlur = NOOP,
  ...restProps
}) => {
  const {
    field: { onChange, onBlur, name, value, ref },
    fieldState: { error },
  } = useController({
    name: $name,
    control,
    rules,
    defaultValue,
  });

  const isError = !!error;

  const handleChange = (value, ...args) => {
    const transformedValue = transform.output ? transform.output(value) : value;

    if (transformedValue === undefined) return;

    onChange(transformedValue);
    $onChange(transformedValue, ...args);
  };

  const handleBlur = (...args) => {
    onBlur();
    $onBlur(args);
  };

  if (!Component) {
    throw new Error(
      'You have to pass one of the props: "render" or "component"',
    );
  }

  return (
    <Component
      {...restProps}
      controllerRef={ref}
      name={name}
      isError={isError}
      errorMessage={showErrorMessage ? error?.message : ''}
      onBlur={handleBlur}
      {...{ [valueAccessor]: transform.input ? transform.input(value) : value }}
      {...{ [changeHandlerAccessor]: handleChange }}
    />
  );
};

export default FieldController;
