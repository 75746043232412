import { useMemo } from 'react';

import { useGetBonusAccountSettingsQuery } from '@api/bonusAccountAPI';
import { BonusAccountSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isBonusSettingsSuccess: result.isSuccess,
  };

  dataResult.coins = BonusAccountSelectors.coinsSelector(result);
  dataResult.limits = BonusAccountSelectors.limitsSelector(result);
  dataResult.markets = BonusAccountSelectors.marketsSelector(result);
  dataResult.periods = BonusAccountSelectors.periodsSelector(result);

  return dataResult;
};

const useBonusAccountSettings = (args, options) => {
  const result = useGetBonusAccountSettingsQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useBonusAccountSettings;
