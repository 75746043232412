import { useEffect, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useForm, useWatch } from 'react-hook-form';

import { marketIdSelector } from '@store/settings/settingsSelectors';

import useDidMountEffect from '@hooks/useDidMountEffect';

import { format, setValueByDecimalStep } from '@utils/numbers';

import { ORDER_FORM_TYPE } from '@constants';

import FormInput from '../../FormInput';
import SubmitFormButton from '../../SubmitFormButton';
import { useBothForms } from '../hooks';

import {
  getSlPriceFieldRules,
  getTpPriceFieldRules,
} from './OcoForm.validations';

const OcoForm = ({ buyTab, currentMarket, currentPrice, layoutItemRef }) => {
  const intl = useIntl();

  const marketId = useSelector(marketIdSelector);

  const {
    control,
    setValue,
    formState: { isSubmitted, errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      tp_price: '',
      sl_price: '',
      amount: '',
    },
  });

  const { isShowBothForms } = useBothForms({ layoutItemRef });

  const tpPriceWatch = useWatch({ control, name: 'tp_price' });
  const slPriceWatch = useWatch({ control, name: 'sl_price' });
  const amountWatch = useWatch({ control, name: 'amount' });

  const marketAmountValidation = {
    required: intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      amount: (v) => {
        if (Number(v) <= 0) {
          return intl.formatMessage({ id: 'FieldRequired' });
        }
      },
    },
  };

  useEffect(() => {
    reset();
  }, [marketId]);

  const handleChangeTpPrice = (value) => {
    setValue('tp_price', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeSlPrice = (value) => {
    setValue('sl_price', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeAmount = (value) => {
    setValue('amount', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });

    amountDependencyUpdates(value);
  };

  const amountDependencyUpdates = (value) => {
    if (slPriceWatch) {
      const updatedTotal = format(slPriceWatch * value, {
        precision: currentMarket?.quote_precision_format,
        noCommas: true,
        removeZeroEnd: true,
      });

      setValue('total', Number(updatedTotal) ? updatedTotal : '', {
        shouldValidate: isSubmitted,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const handleMinusPlusClick = (name, isMinus = false) => {
    switch (name) {
      case 'tp_price': {
        const newValue = setValueByDecimalStep(
          tpPriceWatch,
          isMinus,
          currentMarket?.quote_precision_format,
        );

        handleChangeTpPrice(newValue);
        break;
      }
      case 'sl_price': {
        const newValue = setValueByDecimalStep(
          slPriceWatch,
          isMinus,
          currentMarket?.quote_precision_format,
        );

        handleChangeSlPrice(newValue);
        break;
      }
      case 'amount': {
        const newValue = setValueByDecimalStep(
          amountWatch,
          isMinus,
          currentMarket?.market_precision_format,
        );

        handleChangeAmount(newValue);
        break;
      }
      default:
        break;
    }
  };

  useDidMountEffect(() => {
    reset();
  }, [isShowBothForms]);

  return (
    <>
      <FormInput
        control={control}
        name="tp_price"
        onlyNumbers
        plusminus
        label={
          <span>
            <FormattedMessage id="TpPrice" />:
          </span>
        }
        placeholder={format(0, {
          precision: currentMarket?.quote_precision_format,
        })}
        errors={errors}
        rules={getTpPriceFieldRules(intl, currentPrice, currentMarket, buyTab)}
        appendLabel={currentMarket?.quote_coin_symbol}
        precision={currentMarket?.quote_precision_format}
        layoutItemRef={layoutItemRef}
        onMinusPlusClick={handleMinusPlusClick}
      />

      <FormInput
        control={control}
        name="sl_price"
        onlyNumbers
        plusminus
        label={
          <span>
            <FormattedMessage id="SlPrice" />:
          </span>
        }
        placeholder={format(0, {
          precision: currentMarket?.quote_precision_format,
        })}
        errors={errors}
        rules={getSlPriceFieldRules(intl, currentPrice, currentMarket, buyTab)}
        appendLabel={currentMarket?.quote_coin_symbol}
        precision={currentMarket?.quote_precision_format}
        layoutItemRef={layoutItemRef}
        onMinusPlusClick={handleMinusPlusClick}
      />

      <FormInput
        control={control}
        name="amount"
        onlyNumbers
        plusminus
        label={
          <span>
            <FormattedMessage id="Amount" />:
          </span>
        }
        rules={marketAmountValidation}
        errors={errors}
        appendLabel={currentMarket?.market_coin_symbol}
        precision={currentMarket?.market_precision_format}
        placeholder={format(0, {
          precision: currentMarket?.market_precision_format,
        })}
        layoutItemRef={layoutItemRef}
        onMinusPlusClick={handleMinusPlusClick}
      />

      <SubmitFormButton
        isHideFee
        tradeType={buyTab ? ORDER_FORM_TYPE.STRADDLE : ORDER_FORM_TYPE.STRANGLE}
        buyTab={buyTab}
        currentMarket={currentMarket}
        currentPrice={currentPrice}
        getValues={getValues}
        handleSubmit={handleSubmit}
        layoutItemRef={layoutItemRef}
      />
    </>
  );
};

export default memo(OcoForm);
