import React, { memo } from 'react';

import TruncatedText from '@shared/components/TruncatedText';

import { format } from '@utils/numbers';

import styles from './AmountItem.module.scss';

const AmountItem = ({ amount, precision }) => {
  const formattedAmount = format(amount, {
    precision,
  });

  return (
    <TruncatedText>
      <span className={Number(amount) ? styles.black : styles.grey}>
        {formattedAmount}
      </span>
    </TruncatedText>
  );
};

export default memo(AmountItem);
