import { memo } from 'react';

import { PersonalValueChange } from '../PersonalValueChange';

import FormattedCurrency from './FormattedCurrency';

const PersonalFormattedCurrencyChange = ({ value, currency, ...props }) => {
  return (
    <PersonalValueChange {...props} percentValue={value}>
      {(personalValue, isShowSensitiveData) =>
        isShowSensitiveData ? (
          <FormattedCurrency value={value} currency={currency} />
        ) : (
          personalValue
        )
      }
    </PersonalValueChange>
  );
};

export default memo(PersonalFormattedCurrencyChange);
