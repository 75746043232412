import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ItemTitle } from '@shared/components';

import { useChartData } from '@pages/AccountPage/hooks';
import DetailsButton from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/DetailsButton/DetailsButton';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { OverviewEarnWidget } from '../OverviewEarnWidget';

import { LaunchpadAssetsTable } from './components';

import { WALLETS_ROUTES } from 'src/routes.constants';

import mockData from 'src/mock.json';

const LaunchpadAssets = () => {
  const { chartValue, coin, amount } = mockData.LaunchpadAssets;
  const { chartData, color, value } = useChartData(chartValue);

  const { symbol, value: selectedUserCurrency } = useSelector(currencySelector);

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: coin,
    toCoinSymbol: selectedUserCurrency,
  });

  return (
    <>
      <ItemTitle
        title={<FormattedMessage id="LaunchpadAssets" />}
        arrowTitle={<FormattedMessage id="Details" />}
        navigation={{ to: WALLETS_ROUTES.LAUNCHPAD }}
      />

      <OverviewEarnWidget
        percentChange={value}
        color={color}
        title={<FormattedMessage id="TotalAssetsGrowth" />}
        chartData={chartData}
        value={amount}
        valueCurrency={coin}
        totalValue={amount * ratesByPair}
        totalValueCurrency={selectedUserCurrency}
        totalValueSymbol={symbol}
      />

      <LaunchpadAssetsTable />

      <DetailsButton navigation={{ to: WALLETS_ROUTES.LAUNCHPAD }}>
        <FormattedMessage id="History" />
      </DetailsButton>
    </>
  );
};

export default memo(LaunchpadAssets);
