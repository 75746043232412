import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './OrderOwner.module.scss';

const OrderOwner = () => {
  return (
    <span className={styles.order_owner}>
      <FormattedMessage id="Bot" />
    </span>
  );
};

export default memo(OrderOwner);
