import { useIntl } from 'react-intl';

import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import { useCreateNewOrderMutation } from '@api/ordersAPI';
import { formatObjectKeysFromCamelToSnakeCase } from '@api/utils';

import { getFormData } from '@utils/api';

const useOrderMutations = () => {
  const intl = useIntl();

  const [createNewOrder, { isLoading: isCreateNewOrderLoading }] =
    useCreateNewOrderMutation();

  const onCreateNewOrder = async ({ newOrder, queryParams = {} }) => {
    const dataSnakeCase = formatObjectKeysFromCamelToSnakeCase(newOrder);

    const body = getFormData(dataSnakeCase);

    let queries = queryParams;

    if (isEmpty(queryParams)) {
      queries = { marketId: newOrder.marketId, side: newOrder.side };
    }

    try {
      await createNewOrder({ body, queryParams: queries }).unwrap();

      toast.success(intl.formatMessage({ id: 'Success' }));
    } catch (e) {
      throw Error(e);
    }
  };

  return {
    onCreateNewOrder,
    isCreateNewOrderLoading,
  };
};

export default useOrderMutations;
