import mockData from 'src/mock.json';

const useVisaCard = () => {
  const { cardProfits } = mockData.useVisaCard;

  return {
    cardProfits,
    isSuccess: true,
  };
};

export default useVisaCard;
