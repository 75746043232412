import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { ReactComponent as UploadFileIconSVG } from '@icons/upload-file-icon.svg';

import s from './FileUploaderPlaceholder.module.scss';

const FileUploadPlaceholder = ({
  withImagePreview,
  uploadIcon,
  customStyles = {},
}) => {
  return (
    <div
      className={cx(s.uploader_placeholder, {
        [s.uploader_placeholder__column]: withImagePreview,
      })}
    >
      <div>
        {uploadIcon || (
          <UploadFileIconSVG
            className={cx(s.upload_icon, customStyles.upload_icon)}
          />
        )}
      </div>
      <div className="d-flex flex-column fw-600">
        <div className="text-nowrap">
          <FormattedMessage id="DragAndDropYourFile" />
        </div>
        <div>
          <FormattedMessage id="ClickToUpload" />
        </div>
      </div>
    </div>
  );
};

export default FileUploadPlaceholder;
