import { useGetTradesHistoryQuery } from '@store/api/tradesAPI';

import { TradesApiSelectors } from '@api/selectors';

const useGetTradesHistoryQueryResult = (args, options = {}) => {
  const result = useGetTradesHistoryQuery(args, {
    ...options,
    skip: !args?.accountId || options.skip,
    selectFromResult: (result) => ({
      ...result,
      meta: TradesApiSelectors.metaSelector(result),
      trades: TradesApiSelectors.tradesSelector(result),
    }),
  });

  return result;
};

export default useGetTradesHistoryQueryResult;
