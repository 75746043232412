import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userIdSelector } from '@store/settings/settingsSelectors';

import {
  useNotificationsHistoryQuery,
  useStreamNotificationsQuery,
} from '@api/notificationsAPI';
import { NotificationApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isNotificationsLoading: result.isLoading,
    isNotificationsSuccess: result.isSuccess,
    isNotificationsFetching: result.isFetching,
  };

  dataResult.notifications =
    NotificationApiSelectors.notificationSelector(result);
  dataResult.meta = NotificationApiSelectors.metaSelector(result);

  return dataResult;
};

const useNotifications = (args, options) => {
  const userId = useSelector(userIdSelector);

  useStreamNotificationsQuery(userId, {
    ...options,
    skip: !userId || options.skip,
  });

  const result = useNotificationsHistoryQuery(args, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useNotifications;
