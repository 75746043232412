import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { settingsActions } from '@store/settings/settingsSlice';

import { api } from '@configs';

import { LOCAL_STORAGE_AUTH_TOKEN, UNAUTHORIZED } from '@constants';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: api,
  prepareHeaders: async (headers) => {
    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryAuth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = undefined;

  const isLogout = api.endpoint === 'logout';

  if (!isLogout) {
    result = await baseQuery(args, api, extraOptions);
  }

  if (isLogout || (result.error && result.error.status === UNAUTHORIZED)) {
    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    if (!mutex.isLocked() && token) {
      const release = await mutex.acquire();

      try {
        const logoutResult = await baseQuery(
          { url: '/auth/logout', method: 'DELETE' },
          api,
          extraOptions,
        );

        if (isLogout) {
          result = logoutResult;
        }

        if (!logoutResult.error) {
          api.dispatch(settingsActions.setIsAuthorizedUser(false));
        }
      } finally {
        release();
      }
    }
  }

  return result;
};

export default baseQueryAuth;
