import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ALL_FILTER_KEY } from '@shared/constants';

import {
  isGuestUserSelector,
  marketIdSelector,
} from '@store/settings/settingsSelectors';

import { useMarketsInfo } from '@api/hooks';
import {
  useGetOpenOrdersQuery,
  useLazyGetOpenOrdersQuery,
} from '@api/ordersAPI';
import { OrdersApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    orders: OrdersApiSelectors.openOrdersSelector(result),
    meta: OrdersApiSelectors.metaSelector(result),
    isOpenOrdersSuccess: result.isSuccess,
  };

  return dataResult;
};

export const useOpenOrders = (
  { page, limit, isShowAll = true, accountId, since } = {},
  options = {},
) => {
  const marketId = useSelector(marketIdSelector);
  const isGuest = useSelector(isGuestUserSelector);

  const { marketInfo } = useMarketsInfo({ marketId });

  const result = useGetOpenOrdersQuery(
    {
      pair: isShowAll ? ALL_FILTER_KEY : marketInfo?.id,
      accountId,
      since,
      page,
      limit,
    },
    {
      pollingInterval: 3000,
      ...options,
      skip: isGuest || !accountId || options.skip,
    },
  );

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export const useLazyOpenOrders = (options) => {
  const [trigger, result] = useLazyGetOpenOrdersQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return [trigger, data];
};
