import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

export const accountsAPI = createApi({
  reducerPath: 'accountsAPI',
  baseQuery: client,
  tagTypes: ['Accounts', 'DefaultAccount'],
  endpoints: (builder) => ({
    createAccount: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: 'accounts',
        body,
      }),
      invalidatesTags: ['Accounts'],
    }),
    getAccounts: builder.query({
      query: () => ({
        method: 'GET',
        url: 'accounts',
      }),
      providesTags: ['Accounts'],
    }),
    editAccount: builder.mutation({
      query: (body) => ({
        method: 'PUT',
        url: 'accounts',
        body,
      }),
      invalidatesTags: ['Accounts', 'DefaultAccount'],
    }),
    transfer: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: 'accounts/transfer',
        body,
      }),
      invalidatesTags: ['Accounts'],
    }),
    getDefaultAccount: builder.query({
      query: () => ({
        method: 'GET',
        url: 'accounts/default',
      }),
      providesTags: ['DefaultAccount'],
    }),
  }),
});

export const {
  useCreateAccountMutation,
  useGetAccountsQuery,
  useLazyGetAccountsQuery,
  useEditAccountMutation,
  useTransferMutation,
  useGetDefaultAccountQuery,
  useLazyGetDefaultAccountQuery,
} = accountsAPI;
