import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import classNames from 'classnames';

import { useDefaultAccount } from '@store/api/hooks';

import { CustomOption } from '../CustomSelect/components';

import styles from './AccountOption.module.scss';

const AccountOption = ({ children, ...props }) => {
  const intl = useIntl();

  const { defaultSpotAccount } = useDefaultAccount();

  const isDefault = props.data.value === defaultSpotAccount?.id;

  return (
    children && (
      <CustomOption {...props}>
        <span
          translated-content={`(${intl.formatMessage({ id: 'Default' })})`}
          className={classNames({
            [styles.is_default]: isDefault,
            [styles.inversion]: props.selectProps.inversion,
          })}
        >
          {children}
        </span>
      </CustomOption>
    )
  );
};

export default memo(AccountOption);
