import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

import { get24hAgoInSec } from '@utils';

import { DEFAULT_REQUEST_LIMIT } from '@constants';

export const feesAPI = createApi({
  reducerPath: 'feesAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    getTradeFees: builder.query({
      query: ({
        accountId,
        page = 1,
        limit = DEFAULT_REQUEST_LIMIT,
        fromDate = get24hAgoInSec(),
        toDate = null,
        status = 'fees',
        sideParam,
        pairFrom,
        pairTo,
      }) => {
        return {
          url: '/users/fees',
          params: {
            status,
            account: accountId,
            page,
            fromDate,
            toDate,
            limit,
            sideParam,
            market_coin_symbol: pairFrom,
            quote_coin_symbol: pairTo,
          },
        };
      },
    }),
    exportTradeFeesFile: builder.query({
      query: ({
        accountId,
        status = 'fees',
        page = 1,
        fromDate = get24hAgoInSec(),
        toDate = null,
        format,
        fileType = 'fees',
        sideParam,
        pairFrom,
        pairTo,
      }) => {
        return {
          url: '/users/fees/export',
          params: {
            account: accountId,
            status,
            page,
            fromDate,
            toDate,
            format,
            fileType,
            sideParam,
            market_coin_symbol: pairFrom,
            quote_coin_symbol: pairTo,
          },
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetTradeFeesQuery, useLazyExportTradeFeesFileQuery } =
  feesAPI;
