import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card, InfoItem } from '@shared/components';
import { Button } from '@shared/ui';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { truncatePhoneNumber } from '@utils';

import { ReactComponent as ConfirmedPrimaryIcon } from '@icons/confirmed-primary-icon.svg';

import styles from './ChangePhoneCard.module.scss';

const ChangePhoneCard = () => {
  const { userPhone } = useGetProfileInfoQueryResult();
  const { isMobile } = useBreakpoints();

  return (
    <Card
      customStyles={styles}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <FormattedMessage id="ChangePhone" />
          </div>

          <div className="fs-14 d-flex gap-8 align-items-center">
            <ConfirmedPrimaryIcon width={16} height={16} />

            <FormattedMessage id="Verified" />
          </div>
        </div>
      }
    >
      <div className={styles.change_phone_text}>
        <FormattedMessage id="ThePhoneChangeProcess" />
      </div>

      <div className={styles.change_phone}>
        <InfoItem
          customStyles={styles}
          title={
            <>
              <FormattedMessage id="CurrentPhone" />:
            </>
          }
        >
          <div className={styles.value}>{truncatePhoneNumber(userPhone)}</div>
        </InfoItem>

        <div className={styles.button_container}>
          <Button fill={isMobile} navigation={{ to: '/coming-soon' }}>
            <FormattedMessage id="ChangePhone" />
            ...
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default memo(ChangePhoneCard);
