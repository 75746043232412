import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { components } from 'react-select';

import {
  InfoWidget,
  PersonalData,
  SwiperSlideSelect,
  TotalValue,
  transformToCurrencyOptions,
  Widget,
} from '@shared/components';
import { CRYPTO_CURRENCIES, FIAT_CURRENCIES } from '@shared/constants';
import { Select } from '@shared/ui';

import { useCoinsInfo, useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { format } from '@utils/numbers';

import styles from './EstimatedValueWidget.module.scss';
import mockData from 'src/mock.json';

const { SingleValue } = components;

const SELECT_CURRENCIES = [
  CRYPTO_CURRENCIES.BTC,
  FIAT_CURRENCIES.USD,
  FIAT_CURRENCIES.EUR,
];

const SelectedItem = (props) => {
  return (
    <SingleValue {...props}>
      <div className={classNames(styles.selected_item)}>
        <div className={styles.estimated_value}>
          <PersonalData data={props.data.formattedEstimatedValue} />
        </div>
        <div className={styles.suffix}>
          {props.data.estimatedValueCoinSymbol.toUpperCase()}
        </div>
      </div>
    </SingleValue>
  );
};

const EstimatedValueWidget = ({
  estimatedCurrency,
  onEstimatedCurrencyChange,
  activeIndex,
}) => {
  const estimatedValue =
    mockData.EstimatedValueWidget.estimatedValues[
      estimatedCurrency?.toLowerCase()
    ]?.estimatedValue;
  const estimatedValueCoinSymbol =
    mockData.EstimatedValueWidget.estimatedValues[
      estimatedCurrency?.toLowerCase()
    ]?.estimatedValueCoinSymbol;

  const { value: currency } = useSelector(currencySelector);

  const { ratesByPair: coinRate } = useCoinsRates({
    fromCoinSymbol: estimatedCurrency,
    toCoinSymbol: currency,
  });

  const { coinInfo: estimatedCurrencyInfo } = useCoinsInfo({
    coinSymbol: estimatedCurrency,
  });

  const { coinInfo: currencyInfo } = useCoinsInfo({ coinSymbol: currency });

  const { coinsInfoMap } = useCoinsInfo({ orderByTypeDirection: 'asc' });

  const currencies = useMemo(() => {
    const transformedCurrencies = transformToCurrencyOptions({
      currencies: coinsInfoMap.filter(({ symbol }) =>
        SELECT_CURRENCIES.includes(symbol),
      ),
      inversion: true,
    });

    return transformedCurrencies.map((currency) => ({
      ...currency,
      formattedEstimatedValue: format(estimatedValue, {
        precision: estimatedCurrencyInfo?.digits,
      }),
      estimatedValueCoinSymbol,
    }));
  }, [
    coinsInfoMap,
    estimatedValue,
    estimatedCurrencyInfo,
    estimatedValueCoinSymbol,
  ]);

  const estimatedValueInCurrencyBN = new BigNumber(estimatedValue).multipliedBy(
    new BigNumber(coinRate),
  );

  const handleCurrencyChange = ({ value }) => {
    onEstimatedCurrencyChange(value);
  };

  return (
    <Widget className={styles.estimated_value_widget}>
      <InfoWidget
        customStyles={styles}
        title={<FormattedMessage id="EstimatedValue" />}
        value={
          <SwiperSlideSelect activeIndex={activeIndex}>
            <Select
              options={currencies}
              specificComponents={{
                SingleValue: SelectedItem,
              }}
              inversion
              value={currencies.find(
                ({ value }) => value === estimatedCurrency,
              )}
              onChange={handleCurrencyChange}
            />
          </SwiperSlideSelect>
        }
      />

      <TotalValue
        customStyles={styles}
        value={
          <PersonalData
            data={format(estimatedValueInCurrencyBN, {
              precision: currencyInfo?.digits,
            })}
          />
        }
        symbol={currency.toUpperCase()}
      />
    </Widget>
  );
};

export default memo(EstimatedValueWidget);
