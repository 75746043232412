import {
  useReferralHistoryQuery,
  useTotalRevenueQuery,
} from '@store/api/referralAPI';

import { ReferralApiSelector } from '../selectors/referralAPI.selectors';

export const useGetReferralQueryResult = (args) => {
  const referralParams = args ?? { limit: 10, page: 1 };

  const {
    referralHistory,
    referralEarning,
    referralMeta,
    isFetching: referralIsFetching,
  } = useReferralHistoryQuery(referralParams, {
    selectFromResult: (result) => ({
      ...result,
      referralHistory: ReferralApiSelector.referralHistorySelector(result),
      referralEarning: ReferralApiSelector.referralEarningSelector(result),
      referralMeta: ReferralApiSelector.metaSelector(result),
    }),
  });

  const { totalRevenue, totalRevenueIsSuccess } = useTotalRevenueQuery(null, {
    selectFromResult: (result) => ({
      totalRevenue: ReferralApiSelector.totalRevenueSelector(result),
      totalRevenueIsSuccess: result.isSuccess,
    }),
  });

  return {
    referralHistory,
    referralEarning,
    referralMeta,
    totalRevenue,
    referralIsFetching: referralIsFetching,
    totalRevenueIsSuccess: totalRevenueIsSuccess,
  };
};
