import cx from 'classnames';

import { COLUMN_ALIGN } from '@shared/components/Table/table.constants';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import s from './TableHeaderCell.module.scss';

const TableHeaderCell = ({ children, header, style, inversion }) => {
  const isCanSort = header.column.getCanSort();
  const isSorted = header.column.getIsSorted();
  const onToggleSorting = header.column.getToggleSortingHandler();
  const align = header.column.columnDef.align ?? COLUMN_ALIGN.LEFT;
  const isEnableTruncating = header.column.columnDef.enableTruncating ?? true;

  return (
    <th
      style={{
        maxWidth: header.getSize(),
        minWidth: header.getSize(),
        ...style,
      }}
      className={cx(s.header_cell__wrapper, {
        [s.header_cell__sortable]: isCanSort,
        [s.inversion]: inversion,
      })}
      onClick={onToggleSorting}
    >
      <div className={cx(s.header_cell, s[`header_cell__${align}`])}>
        <span
          className={cx(s.header_cell__text, {
            [s.header_cell__text_truncated]: isEnableTruncating,
          })}
        >
          {children}
        </span>
        {isCanSort && (
          <DownIconSVG
            width={12}
            height={6}
            className={cx(s.icon, {
              [s.icon__expanded]: isSorted,
              [s.inversion]: inversion,
            })}
          />
        )}
      </div>
    </th>
  );
};

export default TableHeaderCell;
