import { useEffect } from 'react';
import { useMatch } from 'react-router';

import Cookies from 'js-cookie';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

import useModal from '@hooks/useModal';

import { COOKIE_SKIP_SECURITY_LEVEL_CHECK, modalsIds } from '@constants';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

const useSecurityLevelModal = () => {
  const isGoogleAuthRoute = useMatch(ACCOUNT_ROUTES.GOOGLE_AUTHENTICATION);
  const isSMSAuthRoute = useMatch(ACCOUNT_ROUTES.SMS_AUTHENTICATION);

  const {
    isTwoAuthExists,
    isSuccess: isSuccessProfileInfo,
    isFetching: isFetchingProfileInfo,
  } = useGetProfileInfoQueryResult();

  const { openModal } = useModal(modalsIds.SECURITY_LEVEL_MODAL);

  useEffect(() => {
    const isSkipSecurityCheck = Cookies.get(COOKIE_SKIP_SECURITY_LEVEL_CHECK);

    if (
      !isGoogleAuthRoute &&
      !isSMSAuthRoute &&
      !isSkipSecurityCheck &&
      isSuccessProfileInfo &&
      !isFetchingProfileInfo &&
      !isTwoAuthExists
    ) {
      openModal();
    }
  }, [
    isGoogleAuthRoute,
    isSMSAuthRoute,
    isSuccessProfileInfo,
    isFetchingProfileInfo,
    isTwoAuthExists,
  ]);
};

export default useSecurityLevelModal;
