import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ALL, INSTANT_BONUS_STATUS } from '@shared/constants';
import { usePagination } from '@shared/hooks';
import { ContractsUtils } from '@shared/utils';

import { useCoinsInfo, useMyContracts } from '@store/api/hooks';

import { ExpiredContractsSelectors } from '../selectors';

const useExpiredContractsTable = () => {
  const filters = useSelector(ExpiredContractsSelectors.filtersSelector);
  const pagination = useSelector(ExpiredContractsSelectors.paginationSelector);

  const { contracts, isMyContractsSuccess } = useMyContracts(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { coinsInfo } = useCoinsInfo();

  const expiredContracts = useMemo(
    () =>
      contracts
        ?.map((contract) => {
          const coinSymbol = contract.coin_symbol;
          const coinInfo = coinsInfo?.[coinSymbol];
          const amount = contract.amount || 0;
          const totalBonusPercent = contract.bonus_percents || 0;
          const totalBonus = amount * totalBonusPercent;
          const volumeTraded = contract.volume_traded || 0;
          const volumeToTrade = contract.volume_to_trade || 0;

          const progress = volumeTraded / volumeToTrade;
          const progressPercent = Math.min(progress * 100, 100);
          const ltv =
            (totalBonus / (Number(contract.amount) + Number(totalBonus))) * 100;

          const { currentBonusPercent } = ContractsUtils.calculateBonus(
            totalBonus,
            progressPercent,
          );

          return {
            id: contract.id,
            botType: contract.bot_id,
            asset: {
              symbol: coinSymbol,
            },
            estimation: contract.bonus_percents * 100,
            deposit: {
              deposit: contract.amount,
              precision: coinInfo?.digits,
            },
            startedDate: contract.created_at,
            ltv,
            progress: {
              progressPercent,
              currentBonusPercent,
            },
            liquidationAmount: {
              coinSymbol,
              liquidationAmount: contract.ProfitLoss,
              precision: coinInfo?.digits,
            },
            expiredDate: contract.expired_at,
            status: contract.status,
          };
        })
        .filter(
          ({ status }) =>
            status === INSTANT_BONUS_STATUS.PAYED ||
            status === INSTANT_BONUS_STATUS.EXPIRED,
        )
        .filter((contract) => {
          const { asset, search, status } = filters;

          const fullName = contract.asset.symbol;

          const selectedStatus = contract.status === status;
          const filteredStatus = selectedStatus || status === ALL;

          const selectedAsset = contract.asset.symbol === asset;
          const filteredAsset = selectedAsset || asset === ALL;

          const filteredFilters = filteredStatus && filteredAsset;

          if (search === '' && filteredFilters) {
            return fullName;
          }

          const isFullNameExist = fullName
            .toLowerCase()
            .includes(search.toLowerCase());

          return isFullNameExist && filteredFilters;
        }),
    [coinsInfo, contracts, filters],
  );

  const { pageData: paginatedContracts } = usePagination(
    pagination,
    expiredContracts,
  );

  return {
    expiredContracts,
    paginatedContracts,
    isExpiredContractsSuccess: isMyContractsSuccess,
  };
};

export default useExpiredContractsTable;
