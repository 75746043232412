import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import { isIntercomBooted } from '@libs/intercom';

import Breakpoint from '@components/Breakpoint';
import Button from '@components/Button';
import Link from '@components/Link';

import {
  isAuthorizedUserSelector,
  isWSConnectedSelector,
} from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal';

import { modalsIds } from '@constants';

import { ReactComponent as ChatIconSVG } from '@icons/chat-icon.svg';
import { ReactComponent as ConnectedIconIconSVG } from '@icons/connected-icon.svg';
import { ReactComponent as DownloadIconSVG } from '@icons/download-icon.svg';
import { ReactComponent as InfoIconIconSVG } from '@icons/info-icon.svg';

import SoundButton from './components/SoundButton';

import { SERVICE_ROUTES } from '../../../../../routes.constants';

import styles from './SecondaryFooter.module.scss';

const SecondaryFooter = ({ withTerminalFeatures = false, className }) => {
  const isAuthorizedUser = useSelector(isAuthorizedUserSelector);
  const isWSConnected = useSelector(isWSConnectedSelector);

  const { openModal } = useModal(modalsIds.DOWNLOAD_MOBILE_APP);

  const { isMobile } = useBreakpoints();

  const handleDownloadMobileAppClick = () => {
    openModal();
  };

  const footerLeftSide = (
    <>
      <div className="d-flex align-items-center">
        <ConnectedIconIconSVG
          className={classnames(
            'me-8',
            styles.secondary_footer__connected_icon,
            {
              [styles['secondary_footer__connected_icon--is_disconnected']]:
                !isWSConnected,
            },
          )}
        />

        <span className={styles.connected}>
          <FormattedMessage id="Connected" />
        </span>
      </div>

      <Link
        to={SERVICE_ROUTES.CONTACT_US}
        className={classnames(
          styles.secondary_footer__button,
          styles.secondary_footer__report_abuse_link,
        )}
      >
        <InfoIconIconSVG />

        <span className="ms-8">
          <FormattedMessage id={isMobile ? 'Report' : 'ReportAbuse'} />
        </span>
      </Link>
    </>
  );

  const footerRightSide = (
    <>
      {withTerminalFeatures && (
        <SoundButton className={styles.secondary_footer__button} />
      )}

      <Button
        s
        className={classnames(
          styles.secondary_footer__button,
          styles.secondary_footer__download_button,
        )}
        onClick={handleDownloadMobileAppClick}
      >
        <DownloadIconSVG />

        <span className="ms-8">
          <FormattedMessage id="Download" />
        </span>
      </Button>

      {isAuthorizedUser && isIntercomBooted && (
        <Button
          id="custom-supports-btn"
          s
          className={classnames(
            styles.secondary_footer__button,
            styles.secondary_footer__support_chat_button,
          )}
        >
          <ChatIconSVG />

          <span className="ms-8">
            <FormattedMessage id="Support" />
          </span>
        </Button>
      )}
    </>
  );

  return (
    <>
      <Breakpoint direction="down" size="md">
        <div className="p-22 d-flex align-items-center justify-content-between">
          {footerLeftSide} {footerRightSide}
        </div>
      </Breakpoint>

      <div
        className={classnames(
          'd-flex gap-md-20',
          styles.secondary_footer,
          className,
        )}
      >
        <div className="flex-1 d-none d-md-flex align-items-center gap-sm-14 gap-md-20">
          {footerLeftSide}
        </div>

        <div className="mb-50 mb-md-0 flex-2 d-flex align-items-center justify-content-center flex-wrap gap-y-sm-6">
          <Link
            className={styles.secondary_footer__link}
            to={SERVICE_ROUTES.POLICY}
          >
            <FormattedMessage id="PrivacyPolicy" />
          </Link>

          <span className={styles.secondary_footer__divider}>|</span>

          <Link
            className={styles.secondary_footer__link}
            to={SERVICE_ROUTES.RISK_DISCLOSURE}
          >
            <FormattedMessage id="Disclaimer" />
          </Link>

          <span className={styles.secondary_footer__divider}>|</span>

          <Link
            className={styles.secondary_footer__link}
            to={SERVICE_ROUTES.TERM}
          >
            <FormattedMessage id="TermsAndConditions" />
          </Link>
        </div>

        <div className="flex-1 d-none d-md-flex align-items-center justify-content-end gap-sm-14 gap-md-20">
          {footerRightSide}
        </div>
      </div>
    </>
  );
};

export default memo(SecondaryFooter);
