import React, { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';

import styles from './ClearIndicator.module.scss';

const ClearIndicator = ({ selectProps, ...props }) => {
  const isInversion = selectProps.inversion;

  return (
    <components.ClearIndicator {...props}>
      <CrossThinIconSVG
        className={cx(styles.cross_icon, { [styles.inversion]: isInversion })}
      />
    </components.ClearIndicator>
  );
};

ClearIndicator.displayName = 'ClearIndicator';

export default memo(ClearIndicator);
