import { createSlice } from '@reduxjs/toolkit';

import { DOCUMENT_TYPE, VERIFICATION_STATUS_TYPE } from '@shared/constants';

const initialState = {
  kycData: {
    status: '',
    reason: '',
    type: '',
  },
  documentsStep: {
    documentType: DOCUMENT_TYPE.ID_CARD,
    firstImage: {
      base64: '',
      name: '',
    },
    secondImage: {
      base64: '',
      name: '',
    },
    selfieBase64: '',
  },
};

const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    setKycData: (state, action) => {
      const { status, reason } = action.payload;

      const detectStatusType = () => {
        if (status.includes(VERIFICATION_STATUS_TYPE.FAIL))
          return VERIFICATION_STATUS_TYPE.FAIL;
        if (status.includes(VERIFICATION_STATUS_TYPE.PENDING))
          return VERIFICATION_STATUS_TYPE.PENDING;
        if (status.includes(VERIFICATION_STATUS_TYPE.SUCCESS))
          return VERIFICATION_STATUS_TYPE.SUCCESS;
      };

      state.kycData = { status, reason, type: detectStatusType() };
    },
    setDocumentsStep: (state, action) => {
      state.documentsStep = { ...state.documentsStep, ...action.payload };
    },
  },
});

const kycActions = kycSlice.actions;
const kycReducer = kycSlice.reducer;

export { kycActions, kycReducer };

export default kycSlice;
