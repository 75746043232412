export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

export const GENDER_LABEL = {
  [GENDER.MALE]: 'Male',
  [GENDER.FEMALE]: 'Female',
};

export const DOCUMENT_TYPE = {
  ID_CARD: 'id_card',
  PASSPORT: 'passport',
  DRIVING_LICENSE: 'driving_license',
};

export const DOCUMENT_TYPE_LABEL = {
  [DOCUMENT_TYPE.ID_CARD]: 'IdCard',
  [DOCUMENT_TYPE.PASSPORT]: 'Passport',
  [DOCUMENT_TYPE.DRIVING_LICENSE]: 'DriversLicense',
};

export const VERIFICATION_STATUS_TYPE = {
  FAIL: 'fail',
  PENDING: 'pending',
  SUCCESS: 'success',
};

export const ACCOUNT_SECURITY_SETTINGS = {
  isGoogleTwoFaExists: 'google_2fa_exists',
  isSmsTwoFaExists: 'sms_2fa_exists',
  isTradePasswordExists: 'trade_password_exists',
  isDetectIpChange: 'detect_ip_change',
  isLoginPasswordExists: 'login_password_exists',
  isAntiPhishingExists: 'anti_phishing_exists',
};
