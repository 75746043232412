import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { BLOCK_BREAKPOINTS } from 'src/constants/blockBreakpoints';
import { BLOCK_TYPES } from 'src/constants/blockTypes';

import { ORDER_SIDES } from '@shared/constants';

import Button from '@components/Button';
import NoData from '@components/NoData';

import { useDefaultAccount } from '@store/api/hooks';
import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import { useOrderMutations } from '@api/hooks';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';
import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal';

import { ORDER_FORM_TYPE, UI_ORDER_TYPES, modalsIds } from '@constants';

import { ReactComponent as BuyIconSVG } from '@assets/images/icons/arrow-side-green.svg';
import { ReactComponent as SellIconSVG } from '@assets/images/icons/arrow-side-red.svg';

import FeeSection from './FeeSection';

import styles from './SubmitFormButton.module.scss';

const SubmitFormButton = ({
  tradeType,
  buyTab,
  currentMarket,
  currentPrice,
  fee,
  isHideFee,
  getValues,
  handleSubmit,
  layoutItemRef,
}) => {
  const { onCreateNewOrder, isCreateNewOrderLoading } = useOrderMutations();
  const isGuest = useSelector(isGuestUserSelector);

  const { defaultSpotAccount } = useDefaultAccount();

  const { isTabletDown, isLaptop, isDesktop } = useBreakpoints();

  const { isStandardX, isLandscapeX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.trading],
  );

  const { openModal: openConfirmTradeModal } = useModal(
    modalsIds.CONFIRM_TRADE_MODAL,
  );

  const isLargeButton =
    isTabletDown || (isLaptop && isStandardX) || (isDesktop && !isLandscapeX);

  const handleSubmitOrder = async () => {
    const newOrder = {
      amount: getValues('amount'),
      stop: 'none',
      ui: UI_ORDER_TYPES.BASIC,
      account: defaultSpotAccount?.id,
    };

    const queryParams = {
      marketId: currentMarket.id,
      side: buyTab ? ORDER_SIDES.BUY : ORDER_SIDES.SELL,
    };

    switch (tradeType) {
      case ORDER_FORM_TYPE.MARKET: {
        newOrder.type = 'market';
        newOrder.price = currentPrice;

        break;
      }

      case ORDER_FORM_TYPE.LIMIT_OTO_BASIC:
      case ORDER_FORM_TYPE.LIMIT: {
        newOrder.type = 'limit';
        newOrder.price = getValues('price');

        break;
      }

      case ORDER_FORM_TYPE.STOP_OTO_BASIC:
      case ORDER_FORM_TYPE.STOP: {
        newOrder.type = 'market';
        newOrder.stop_price = getValues('price');
        newOrder.stop = buyTab ? 'entry' : 'loss';

        break;
      }

      case ORDER_FORM_TYPE.TRAILING_STOP: {
        const callbackType = getValues('callbackType');

        newOrder.type = 'trailing_stop';
        newOrder.ts_activation_price = getValues('activationPrice');
        newOrder.ts_price =
          callbackType === 'percentage'
            ? `${getValues('callbackRate') / 100}`
            : getValues('callbackRate');
        newOrder.ts_price_type = callbackType;

        break;
      }

      case ORDER_FORM_TYPE.STRADDLE: {
        const sl_price = getValues('sl_price');
        const tp_price = getValues('tp_price');

        queryParams.side = ORDER_SIDES.SELL;
        newOrder.type = 'straddle';
        newOrder.price = tp_price;
        newOrder.sl_price = sl_price;
        newOrder.tp_price = tp_price;

        break;
      }

      case ORDER_FORM_TYPE.STRANGLE: {
        const sl_price = getValues('sl_price');
        const tp_price = getValues('tp_price');

        queryParams.side = ORDER_SIDES.SELL;
        newOrder.type = 'strangle';
        newOrder.price = sl_price;
        newOrder.sl_price = sl_price;
        newOrder.tp_price = tp_price;

        break;
      }

      case ORDER_FORM_TYPE.LIMIT_OTO_ADVANCED: {
        newOrder.price = getValues('price');
        newOrder.type = 'oto';
        newOrder.oto_type = 'limit';
        newOrder.stop = 'none';

        const sl_price = getValues('sl_price');
        const tp_price = getValues('tp_price');

        if (sl_price) {
          newOrder.sl_price = sl_price;
        }
        if (tp_price) {
          newOrder.tp_price = tp_price;
        }

        break;
      }

      case ORDER_FORM_TYPE.STOP_OTO_ADVANCED: {
        newOrder.type = 'oto';
        newOrder.stop_price = getValues('price');
        newOrder.oto_type = 'market';
        newOrder.stop = buyTab ? 'entry' : 'loss';

        const sl_price = getValues('sl_price');
        const tp_price = getValues('tp_price');

        if (sl_price) {
          newOrder.sl_price = sl_price;
        }
        if (tp_price) {
          newOrder.tp_price = tp_price;
        }

        break;
      }

      default:
        break;
    }

    openConfirmTradeModal({
      order: newOrder,
      tradeType: tradeType,
      currentMarket: currentMarket,
      currentPrice: currentPrice,
      queryParams,
      onSubmit: async () => {
        await onCreateNewOrder({ newOrder, queryParams });
      },
    });
  };

  return (
    <div className={styles.submit_form_button}>
      {!isHideFee && <FeeSection fee={fee} currentMarket={currentMarket} />}

      {isGuest ? (
        <Button
          fill
          lg={isLargeButton}
          className={styles.submit_form_button__guest}
        >
          <NoData
            showIsGuest
            className={styles.submit_form_button__guest__no_data}
          />
        </Button>
      ) : (
        <Button
          fill
          lg={isLargeButton}
          rounded
          primary={buyTab}
          dangerous={!buyTab}
          isLoading={isCreateNewOrderLoading}
          className={styles.submit_form_button__submit}
          onClick={handleSubmit(handleSubmitOrder)}
        >
          <>
            {buyTab ? (
              <BuyIconSVG
                className={styles.submit_form_button__submit__buy_icon}
              />
            ) : (
              <SellIconSVG
                className={styles.submit_form_button__submit__sell_icon}
              />
            )}

            <span className="ms-8">
              <FormattedMessage id={buyTab ? 'Buy' : 'Sell'} />

              <span className="text-uppercase ms-4">
                {currentMarket?.market_coin_symbol}
              </span>
            </span>
          </>
        </Button>
      )}
    </div>
  );
};

export default memo(SubmitFormButton);
