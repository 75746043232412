import { useMemo, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { getCountries } from 'react-phone-number-input';
import { components } from 'react-select';

import { FormController } from '@shared/components';
import { CountryOption } from '@shared/components/CountriesSelect/components';
import { Select } from '@shared/ui';
import { Menu } from '@shared/ui/Select/components';

import s from './CountriesSelect.module.scss';

const { SingleValue } = components;

export const getCountriesOptions = () => {
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], {
    type: 'region',
  });

  return getCountries().map((countryCode) => ({
    value: countryCode,
    label: regionNamesInEnglish.of(countryCode),
  }));
};

const CountriesSelect = forwardRef((props, ref) => {
  const intl = useIntl();

  const regionNamesInEnglish = useMemo(
    () =>
      new Intl.DisplayNames(['en'], {
        type: 'region',
      }),
    [],
  );

  const countriesOptions = useMemo(() => {
    return getCountriesOptions();
  }, [regionNamesInEnglish]);

  const formatOptionLabel = ({ value }) => (
    <CountryOption countryCode={value} customStyles={props?.customStyles}>
      {regionNamesInEnglish.of(value)}
    </CountryOption>
  );

  const SpecificSingleValue = (rest) => {
    const { value, label } = rest.data;

    return (
      <SingleValue {...rest}>
        <CountryOption countryCode={value} customStyles={props?.customStyles}>
          {label}
        </CountryOption>
      </SingleValue>
    );
  };

  const SpecificMenu = ({ children, ...props }) => {
    return (
      <Menu customStyles={s} {...props}>
        {children}
      </Menu>
    );
  };

  return (
    <Select
      ref={ref}
      formatOptionLabel={formatOptionLabel}
      options={countriesOptions}
      placeholder={`${intl.formatMessage({ id: 'SelectCountry' })}...`}
      virtualized
      specificComponents={{
        SingleValue: SpecificSingleValue,
        Menu: SpecificMenu,
      }}
      optionHeight={36}
      isClearable={false}
      {...props}
    />
  );
});

export default CountriesSelect;

export const CountriesSelectController = (props) => {
  return <FormController component={CountriesSelect} {...props} />;
};
