import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TooltipHelpIcon from '@components/TooltipHelpIcon';

import styles from './HeaderTitleInfo.module.scss';

const HeaderTitleInfo = ({ titleId, info }) => {
  const intl = useIntl();

  return (
    <div className={styles.title}>
      <div>
        <FormattedMessage id={titleId} />
      </div>

      {info && (
        <div className={styles.info_icon_container}>
          <TooltipHelpIcon
            className={styles.info_icon}
            content={intl.formatMessage({ id: info })}
          />
        </div>
      )}
    </div>
  );
};

export default memo(HeaderTitleInfo);
