import { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useForm, useWatch } from 'react-hook-form';

import CheckBox from '@components/CheckBox';
import TooltipHelpIcon from '@components/TooltipHelpIcon';

import { marketIdSelector } from '@store/settings/settingsSelectors';

import useDidMountEffect from '@hooks/useDidMountEffect';

import { format, setValueByDecimalStep } from '@utils/numbers';
import { getRiskReward } from '@utils/orders';

import { ORDER_FORM_TYPE } from '@constants';

import FieldContainer from '../../FieldContainer';
import FormInput from '../../FormInput';
import FormSlider from '../../FormSlider';
import SubmitFormButton from '../../SubmitFormButton';
import { useBothForms } from '../hooks';

import RiskReward from './components/RiskReward';
import {
  getPriceFieldRules,
  getAmountFieldRules,
  getTotalFieldRules,
  getTpPriceFieldRules,
  getSlPriceFieldRules,
} from './LimitStopOtoForm.validations';

const LimitStopOtoForm = ({
  buyTab,
  currentMarket,
  currentPrice,
  quoteAvailableBalance,
  marketAvailableBalance,
  layoutItemRef,
  basicOrderFormType,
  advancedOrderFormType,
}) => {
  const intl = useIntl();

  const marketId = useSelector(marketIdSelector);

  const [isTakeProfit, setIsTakeProfit] = useState();
  const [isStopLoss, setIsStopLoss] = useState();
  const [tradeType, setTradeType] = useState(basicOrderFormType);
  const [riskReward, setRiskReward] = useState('');

  const {
    control,
    setValue,
    formState: { isSubmitted, errors },
    getValues,
    handleSubmit,
    register,
    unregister,
    reset,
  } = useForm({
    defaultValues: {
      amount: '',
      price: '',
      total: '',
      oto_type:
        basicOrderFormType === ORDER_FORM_TYPE.LIMIT_OTO_BASIC
          ? 'limit'
          : 'market',
    },
  });

  const { isShowBothForms } = useBothForms({ layoutItemRef });

  const amountWatch = useWatch({ control, name: 'amount' });
  const priceWatch = useWatch({ control, name: 'price' });
  const totalWatch = useWatch({ control, name: 'total' });
  const tpPriceWatch = useWatch({ control, name: 'tp_price' });
  const slPriceWatch = useWatch({ control, name: 'sl_price' });

  useEffect(() => {
    reset();
  }, [marketId]);

  useEffect(() => {
    if (isTakeProfit) {
      register('tp_price');
      return;
    }

    unregister('tp_price');
  }, [isTakeProfit]);

  useEffect(() => {
    if (isStopLoss) {
      register('sl_price');
      return;
    }

    unregister('sl_price');
  }, [isStopLoss]);

  useEffect(() => {
    if (isTakeProfit || isStopLoss) {
      setTradeType(advancedOrderFormType);
      return;
    }

    setTradeType(basicOrderFormType);
  }, [isTakeProfit, isStopLoss]);

  useEffect(() => {
    const tp_price = Number(tpPriceWatch);
    const sl_price = Number(slPriceWatch);
    const price = Number(priceWatch);

    if (isNaN(tp_price) || isNaN(sl_price) || isNaN(price)) {
      setRiskReward('');
      return;
    }

    setRiskReward(
      tp_price > sl_price
        ? getRiskReward(buyTab, price, sl_price, tp_price)
        : getRiskReward(buyTab, price, tp_price, sl_price),
    );
  }, [tpPriceWatch, slPriceWatch, priceWatch, buyTab, basicOrderFormType]);

  const handleChangePrice = (value) => {
    setValue('price', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeAmount = (value) => {
    setValue('amount', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });

    amountDependencyUpdates(value);
  };

  const handleChangeTotal = (value) => {
    setValue('total', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeTpPrice = (value) => {
    setValue('tp_price', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeSlPrice = (value) => {
    setValue('sl_price', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeTpPriceType = (selectedCallbackTypeId) => {
    setValue('tp_price', selectedCallbackTypeId, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeSlPriceType = (selectedCallbackTypeId) => {
    setValue('sl_price', selectedCallbackTypeId, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const priceDependencyUpdates = (value) => {
    if (amountWatch) {
      const updatedTotal = format(value * amountWatch, {
        precision: currentMarket.quote_precision_format,
        noCommas: true,
        removeZeroEnd: true,
      });

      setValue('total', Number(updatedTotal) ? updatedTotal : '', {
        shouldValidate: isSubmitted,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const amountDependencyUpdates = (value) => {
    if (priceWatch) {
      const updatedTotal = format(priceWatch * value, {
        precision: currentMarket?.quote_precision_format,
        noCommas: true,
        removeZeroEnd: true,
      });

      setValue('total', Number(updatedTotal) ? updatedTotal : '', {
        shouldValidate: isSubmitted,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const totalDependencyUpdates = (value) => {
    if (priceWatch) {
      const updatedAmount = format(value / priceWatch, {
        precision: currentMarket?.market_precision_format,
        noCommas: true,
        removeZeroEnd: true,
      });

      setValue('amount', Number(updatedAmount) ? updatedAmount : '', {
        shouldValidate: isSubmitted,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const handleMinusPlusClick = (name, isMinus = false) => {
    switch (name) {
      case 'price': {
        const newValue = setValueByDecimalStep(
          priceWatch,
          isMinus,
          currentMarket?.quote_precision_format,
        );

        handleChangePrice(newValue);
        priceDependencyUpdates(newValue);
        break;
      }

      case 'tp_price': {
        const newValue = setValueByDecimalStep(
          tpPriceWatch,
          isMinus,
          currentMarket?.quote_precision_format,
        );

        handleChangeTpPrice(newValue);
        break;
      }

      case 'sl_price': {
        const newValue = setValueByDecimalStep(
          slPriceWatch,
          isMinus,
          currentMarket?.quote_precision_format,
        );

        handleChangeSlPrice(newValue);
        break;
      }

      case 'amount': {
        const newValue = setValueByDecimalStep(
          amountWatch,
          isMinus,
          currentMarket?.market_precision_format,
        );

        handleChangeAmount(newValue);
        break;
      }

      case 'total': {
        const newValue = setValueByDecimalStep(
          totalWatch,
          isMinus,
          currentMarket?.quote_precision_format,
        );

        handleChangeTotal(newValue);
        totalDependencyUpdates(newValue);
        break;
      }

      default:
        break;
    }
  };

  useDidMountEffect(() => {
    reset();
  }, [isShowBothForms]);

  return (
    <>
      <div>
        <FormInput
          control={control}
          name="price"
          onlyNumbers
          plusminus
          label={
            <span>
              <FormattedMessage id="Price" />:
            </span>
          }
          errors={errors}
          rules={getPriceFieldRules(
            intl,
            currentPrice,
            currentMarket,
            basicOrderFormType,
            buyTab,
          )}
          appendLabel={currentMarket?.quote_coin_symbol}
          precision={currentMarket?.quote_precision_format}
          placeholder={format(0, {
            precision: currentMarket?.quote_precision_format,
          })}
          layoutItemRef={layoutItemRef}
          dependencyUpdates={priceDependencyUpdates}
          onMinusPlusClick={handleMinusPlusClick}
        />

        <FieldContainer layoutItemRef={layoutItemRef}>
          <div className="d-flex gap-16 my-8">
            <div className="d-flex gap-8">
              <CheckBox
                checked={isTakeProfit}
                label={
                  <span className="fs-13">
                    <FormattedMessage id="TakeProfit" />
                  </span>
                }
                onChange={(value) => setIsTakeProfit(value)}
              />

              <TooltipHelpIcon
                content={intl.formatMessage({
                  id: 'TakeProfitHelpContent',
                })}
              />
            </div>

            <div className="d-flex gap-8">
              <CheckBox
                checked={isStopLoss}
                label={
                  <span className="fs-13">
                    <FormattedMessage id="StopLoss" />
                  </span>
                }
                onChange={(value) => setIsStopLoss(value)}
              />

              <TooltipHelpIcon
                content={intl.formatMessage({
                  id: 'StopLossHelpContent',
                })}
              />
            </div>
          </div>
        </FieldContainer>

        <div className="d-flex flex-column gap-20">
          {isTakeProfit && (
            <FormInput
              control={control}
              name="tp_price"
              onlyNumbers
              plusminus
              label={
                <span>
                  <FormattedMessage id="TpPrice" />:
                </span>
              }
              errors={errors}
              rules={getTpPriceFieldRules(
                intl,
                currentMarket,
                buyTab,
                isTakeProfit,
                priceWatch,
              )}
              appendLabel={currentMarket?.quote_coin_symbol}
              precision={currentMarket?.quote_precision_format}
              placeholder={format(0, {
                precision: currentMarket?.quote_precision_format,
              })}
              layoutItemRef={layoutItemRef}
              dependencyUpdates={handleChangeTpPriceType}
              onMinusPlusClick={handleMinusPlusClick}
            />
          )}

          {isStopLoss && (
            <div>
              <FormInput
                control={control}
                name="sl_price"
                onlyNumbers
                plusminus
                label={
                  <span>
                    <FormattedMessage id="SlPrice" />:
                  </span>
                }
                errors={errors}
                rules={getSlPriceFieldRules(
                  intl,
                  currentMarket,
                  buyTab,
                  isStopLoss,
                  priceWatch,
                )}
                appendLabel={currentMarket?.quote_coin_symbol}
                precision={currentMarket?.quote_precision_format}
                placeholder={format(0, {
                  precision: currentMarket?.quote_precision_format,
                })}
                layoutItemRef={layoutItemRef}
                dependencyUpdates={handleChangeSlPriceType}
                onMinusPlusClick={handleMinusPlusClick}
              />

              {!!riskReward && <RiskReward riskReward={riskReward} />}
            </div>
          )}

          <FormInput
            control={control}
            name="amount"
            onlyNumbers
            plusminus
            label={
              <span>
                <FormattedMessage id="Amount" />:
              </span>
            }
            rules={getAmountFieldRules(intl)}
            errors={errors}
            appendLabel={currentMarket?.market_coin_symbol}
            precision={currentMarket?.market_precision_format}
            placeholder={format(0, {
              precision: currentMarket?.market_precision_format,
            })}
            layoutItemRef={layoutItemRef}
            dependencyUpdates={amountDependencyUpdates}
            onMinusPlusClick={handleMinusPlusClick}
          />
        </div>
      </div>

      <FormSlider
        layoutItemRef={layoutItemRef}
        isBuyTab={buyTab}
        currentPrice={priceWatch}
        quoteAvailableBalance={quoteAvailableBalance}
        marketAvailableBalance={marketAvailableBalance}
        currentMarket={currentMarket}
        absoluteValue={amountWatch}
        onChange={handleChangeAmount}
      />

      <FormInput
        control={control}
        name="total"
        onlyNumbers={true}
        plusminus
        label={
          <span>
            <FormattedMessage id="Total" />:
          </span>
        }
        errors={errors}
        rules={getTotalFieldRules(intl)}
        appendLabel={currentMarket?.quote_coin_symbol}
        precision={currentMarket?.quote_precision_format}
        placeholder={format(0, {
          precision: currentMarket?.quote_precision_format,
        })}
        layoutItemRef={layoutItemRef}
        dependencyUpdates={totalDependencyUpdates}
        onMinusPlusClick={handleMinusPlusClick}
      />

      <SubmitFormButton
        isHideFee
        tradeType={tradeType}
        buyTab={buyTab}
        currentMarket={currentMarket}
        currentPrice={currentPrice}
        getValues={getValues}
        handleSubmit={handleSubmit}
        layoutItemRef={layoutItemRef}
      />
    </>
  );
};

export default memo(LimitStopOtoForm);
