import { createSlice } from '@reduxjs/toolkit';

import { ITEMS_PER_PAGE } from '@constants';

import { MY_CONTRACTS_DEFAULT_VALUES } from '../instantBonus.constants';

const initialState = {
  filters: MY_CONTRACTS_DEFAULT_VALUES,
  pagination: {
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  },
};

const myContractsSlice = createSlice({
  name: 'myContracts',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    resetPagination: (state, action) => {
      state.pagination = initialState.pagination;
    },
  },
});

const myContractsActions = myContractsSlice.actions;
const myContractsReducer = myContractsSlice.reducer;

export { myContractsActions, myContractsReducer };

export default myContractsSlice;
