import { createColumnHelper } from '@tanstack/react-table';

import { ColumnHeaderItem } from '@shared/components';
import {
  DAY_MONTH_YEAR_TIME_FORMAT,
  WALLET_WITHDRAW_STATUS_COLOR,
} from '@shared/constants';

import {
  AmountItem,
  AssetItem,
  DateItem,
  TotalItem,
  DetailsItem,
  StatusItem,
  FeeAmountItem,
} from '@pages/WalletsPage/components';

const { accessor } = createColumnHelper();

const withdrawHistoryTableColumns = ({ isSwipeableTable }) => [
  accessor('asset', {
    header: () => <ColumnHeaderItem id="Asset" />,
    cell: (info) => {
      const coinSymbol = info.getValue();

      return (
        <AssetItem
          coinSymbol={coinSymbol}
          isShowSymbol={!isSwipeableTable}
          isShowFullName={!isSwipeableTable}
        />
      );
    },
    maxSize: 85,
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
  }),
  ...(isSwipeableTable
    ? [
        accessor('asset', {
          header: () => <ColumnHeaderItem id="Asset" />,
          cell: (info) => {
            const coinSymbol = info.getValue();

            return (
              <AssetItem
                coinSymbol={coinSymbol}
                isShowFullName
                isShowCurrencyIcon={false}
              />
            );
          },
          maxSize: 85,
          cellAlign: 'start',
          headerAlign: 'start',
          enableSorting: false,
        }),
      ]
    : []),
  accessor('details', {
    header: () => <ColumnHeaderItem id="Details" />,
    cell: (info) => {
      const { address, txid, txidLink } = info.getValue();

      return <DetailsItem address={address} txid={txid} txidLink={txidLink} />;
    },
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
  }),
  accessor('amount', {
    header: () => <ColumnHeaderItem id="Amount" />,
    cell: (info) => {
      const { amount, precision } = info.getValue();

      return <AmountItem amount={amount} precision={precision} />;
    },
    maxSize: 60,
    enableSorting: false,
  }),
  accessor('feeAmount', {
    header: () => <ColumnHeaderItem id="Fee" />,
    cell: (info) => {
      const { feeAmount, coinSymbol } = info.getValue();

      return <FeeAmountItem feeAmount={feeAmount} coinSymbol={coinSymbol} />;
    },
    maxSize: 40,
    enableSorting: false,
  }),
  accessor('total', {
    header: () => <ColumnHeaderItem id="Total" />,
    cell: (info) => {
      const { total, precision } = info.getValue();

      return <TotalItem total={total} precision={precision} />;
    },
    maxSize: 50,
    enableSorting: false,
  }),
  accessor('date', {
    header: () => <ColumnHeaderItem id="Date" />,
    cell: (info) => {
      const date = info.getValue();

      return <DateItem date={date} formatType={DAY_MONTH_YEAR_TIME_FORMAT} />;
    },
    maxSize: 60,
    enableSorting: false,
  }),
  accessor('status', {
    header: () => <ColumnHeaderItem id="Status" />,
    cell: (info) => {
      const status = info.getValue();

      return (
        <StatusItem
          status={status}
          statusColors={WALLET_WITHDRAW_STATUS_COLOR}
        />
      );
    },
    maxSize: 30,
    enableSorting: false,
  }),
];

export default withdrawHistoryTableColumns;
