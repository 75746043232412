import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ALL, INSTANT_BONUS_STATUS } from '@shared/constants';
import { usePagination } from '@shared/hooks';
import { ContractsUtils } from '@shared/utils';

import { WalletsUtils } from '@pages/WalletsPage/walletsPage.utils';

import { useCoinsInfo, useMyContracts } from '@store/api/hooks';

import { MyContractsSelectors } from '../selectors';

const useSelectResult = ({ contracts, isMyContractsSuccess }) => {
  const filters = useSelector(MyContractsSelectors.filtersSelector);
  const pagination = useSelector(MyContractsSelectors.paginationSelector);

  const { coinsInfo } = useCoinsInfo();

  const myContracts = useMemo(
    () =>
      contracts
        ?.map((contract) => {
          const coinInfo = coinsInfo?.[contract?.coin_symbol];

          const amount = contract.amount || 0;
          const totalBonusPercent = contract.bonus_percents || 0;
          const totalBonus = amount * totalBonusPercent;
          const volumeTraded = contract.volume_traded || 0;
          const volumeToTrade = contract.volume_to_trade || 0;

          const progress = volumeTraded / volumeToTrade;
          let progressPercent = Math.min(progress * 100, 100);
          const ltv =
            (totalBonus / (Number(contract.amount) + Number(totalBonus))) * 100;

          const { currentBonus, currentBonusPercent } =
            ContractsUtils.calculateBonus(totalBonus, progressPercent);

          return {
            id: contract.id,
            botType: contract.bot_id,
            asset: {
              symbol: contract.coin_symbol,
            },
            estimation: contract.bonus_percents * 100,
            deposit: {
              deposit: contract.amount,
              precision: coinInfo?.digits,
            },
            startedDate: contract.created_at,
            ltv,
            grossBonus: {
              currentBonus,
              precision: coinInfo?.digits,
            },
            progress: {
              progressPercent,
              currentBonusPercent,
            },
            expiredDate: contract.expired_at,
            status: contract.status,
          };
        })
        .filter(
          ({ status }) =>
            status === INSTANT_BONUS_STATUS.INACTIVE ||
            status === INSTANT_BONUS_STATUS.ACTIVE ||
            status === INSTANT_BONUS_STATUS.PENDING_EXPIRATION,
        )
        .filter((contract) => {
          const { asset, search, status } = filters;

          const fullName = contract.asset.symbol;

          const selectedStatus = contract.status === status;
          const filteredStatus = selectedStatus || status === ALL;

          const selectedAsset = contract.asset.symbol === asset;
          const filteredAsset = selectedAsset || asset === ALL;

          const filteredFilters = filteredStatus && filteredAsset;

          const id = contract.id;
          const botId = contract.botType;

          if (search === '' && filteredFilters) {
            return fullName;
          }

          const isItemWasFound = WalletsUtils.isSomeContractExists(
            [fullName, id, botId],
            filters,
          );

          return isItemWasFound && filteredFilters;
        }),
    [coinsInfo, contracts, filters],
  );

  const { pageData: paginatedContracts } = usePagination(
    pagination,
    myContracts,
  );

  return {
    myContracts,
    paginatedContracts,
    isMyContractsSuccess,
  };
};

const useMyContractsTable = (args, options) => {
  const data = useMyContracts(args, {
    ...options,
    refetchOnMountOrArgChange: true,
  });

  const result = useSelectResult(data);

  return result;
};

export default useMyContractsTable;
