import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { parseToLocalization } from '@shared/utils';

import StatusDot from '@components/StatusDot';

import styles from './StatusItem.module.scss';

const StatusItem = ({ status, statusColors, isShortView = false }) => {
  const intl = useIntl();

  if (isShortView) {
    return (
      <StatusDot
        tooltipStatus={intl.formatMessage({ id: parseToLocalization(status) })}
        color={statusColors[status]}
      />
    );
  }

  return (
    <StatusDot
      label={
        <span className={styles.color}>
          {intl.formatMessage({ id: parseToLocalization(status) })}
        </span>
      }
      color={statusColors[status]}
      labelPosition="left"
    />
  );
};
export default memo(StatusItem);
