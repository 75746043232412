import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { components } from 'react-select';

import styles from './CustomNoOptionsMessage.module.scss';

const { NoOptionsMessage } = components;

const CustomNoOptionsMessage = ({ children, selectProps, ...props }) => {
  const isInversion = selectProps.inversion;

  return (
    <NoOptionsMessage
      className={classNames(styles.message, {
        [styles.inversion]: isInversion,
      })}
      {...props}
    >
      <FormattedMessage id="NotFound" />
    </NoOptionsMessage>
  );
};

export default memo(CustomNoOptionsMessage);
