import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@components/Button';
import Modal from '@components/modals/Modal';

import { useCancelAllOrdersMutation } from '@store/api/ordersAPI';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

const ConfirmCancelAllOpenOrdersModal = () => {
  const { isOpen, closeModal } = useModal(
    modalsIds.CONFIRM_CANCEL_ALL_OPEN_ORDERS,
  );

  const [cancelAllOrders, { isLoading }] = useCancelAllOrdersMutation();

  const handleCancelAllOpenOrders = () => {
    cancelAllOrders().unwrap().finally(closeModal);
  };

  return (
    <Modal
      className="modal-sm"
      onClose={closeModal}
      visible={isOpen}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">
            <FormattedMessage id="ConfirmCancelAllOpenOrders" />
          </div>
        </div>
      }
      footer={
        <div>
          {isLoading ? (
            <Button isLoading fill dangerous rounded />
          ) : (
            <div className="d-flex justify-content-between">
              <Button
                fill
                dangerous
                rounded
                onClick={handleCancelAllOpenOrders}
              >
                <FormattedMessage id="YesConfirm" />
              </Button>

              <Button
                fill
                secondary
                rounded
                onClick={closeModal}
                className="ms-10"
              >
                <FormattedMessage id="No" />
              </Button>
            </div>
          )}
        </div>
      }
    >
      <FormattedMessage id="ConfirmCancelAllOpenOrdersMessage" />
    </Modal>
  );
};

export default memo(ConfirmCancelAllOpenOrdersModal);
