import React, { memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormProvider, useForm } from 'react-hook-form';

import { FormActions } from '@shared/components';
import { InputController } from '@shared/ui';

import SideBar from '@components/SideBar';

import { isNewAddress, isNotInternalAddress } from '@pages/WalletsPage';
import { withdrawCurrencySymbolSelector } from '@pages/WalletsPage/Withdraw/Withdraw.selectors';

import { userEmailSelector } from '@store/settings/settingsSelectors';

import { useWalletsMutations } from '@api/hooks';
import { WalletsApiSelectors } from '@api/selectors';
import { useGetWithdrawAddressesQuery } from '@api/walletsAPI';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import styles from './SideBarAddWithdrawAddress.module.scss';

const SideBarAddWithdrawAddress = () => {
  const { sidebar, isOpen, toggleSidebar } = useSidebar(
    sidebarIds.WALLETS_WITHDRAW_ADD_ADDRESS,
  );

  const { onCreateWithdrawAddress, isLoadingCreateWithdrawAddress } =
    useWalletsMutations();

  const intl = useIntl();

  const { isTabletDown } = useBreakpoints();

  const defaultFormValues = { addressLabel: '', coinAddress: '' };

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: defaultFormValues,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    reset,
  } = methods;

  // reset form after closing
  useEffect(() => {
    if (!isOpen) reset(defaultFormValues);
  }, [isOpen]);

  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);
  const userEmail = useSelector(userEmailSelector);

  const { addresses } = useGetWithdrawAddressesQuery(undefined, {
    skip: !isOpen,
    selectFromResult: (result) => ({
      addresses: WalletsApiSelectors.withdrawAddressesSelector(result),
    }),
  });

  const selectedCoinSymbol = sidebar?.params?.coin?.toUpperCase();
  const typedUserAddress = sidebar?.params?.address;

  useEffect(() => {
    if (!!typedUserAddress) {
      setValue('coinAddress', typedUserAddress);
    }
  }, [sidebar?.params]);

  const commonFieldProps = {
    bordered: false,
    underlined: true,
    inversion: true,
    size: 'l',
  };

  const handleFormSubmit = async (values) => {
    const newAddressData = {
      name: values.addressLabel,
      address: values.coinAddress,
      coin: selectedCurrencySymbol,
    };

    await onCreateWithdrawAddress(newAddressData);

    toggleSidebar();
  };

  const handleCloseSidebar = () => toggleSidebar();

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleCloseSidebar}
      withCloseButton
      title={intl.formatMessage(
        { id: 'SidebarAddWithdrawAddressTitle' },
        { coin: selectedCoinSymbol },
      )}
    >
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <div>
            <p className={styles.description}>
              <FormattedMessage id="MemorizeYourAddress" />
            </p>
            <div className={styles.fields_group}>
              <div className={styles.field}>
                <InputController
                  name="addressLabel"
                  label={`${intl.formatMessage({ id: 'AddressLabel' })}:`}
                  placeholder={`${intl.formatMessage({
                    id: 'InputAddressLabel',
                  })}...`}
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                  }}
                  {...commonFieldProps}
                  {...methods}
                />
              </div>

              <div className={styles.field}>
                <InputController
                  name="coinAddress"
                  label={`${intl.formatMessage(
                    { id: 'CoinAddress' },
                    { coin: selectedCoinSymbol },
                  )}:`}
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                    validate: {
                      isNewAddress: isNewAddress(addresses, intl),
                      isNotInternalAddress: isNotInternalAddress(
                        userEmail,
                        selectedCurrencySymbol,
                        intl,
                      ),
                    },
                  }}
                  {...commonFieldProps}
                  {...methods}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="mt-36">
              <FormActions
                submitText={<FormattedMessage id="Save" />}
                cancelText={<FormattedMessage id="Cancel" />}
                submitButtonProps={{
                  size: isTabletDown ? 'm' : 'l',
                  fill: true,
                  inversion: true,
                  isLoading: isLoadingCreateWithdrawAddress,
                  disabled: !isValid,
                }}
                cancelButtonProps={{
                  size: isTabletDown ? 'm' : 'l',
                  fill: true,
                  inversion: true,
                }}
                onCancel={handleCloseSidebar}
                customStyles={styles}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </SideBar>
  );
};

export default memo(SideBarAddWithdrawAddress);
