import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { ColumnHeaderItem } from '@shared/components';

import {
  AssetItem,
  BonusItem,
  MinDepositItem,
  StakeItem,
} from '@pages/WalletsPage/components';

import { AvailablePlansPeriodButtons } from '../AvailablePlansPeriodButtons';

const { accessor } = createColumnHelper();

const availablePlansTableColumns = ({ isTabletDown }) => {
  const smallTableColumns = ['asset', 'estimation', 'minDeposit', 'action'];

  const allColumns = [
    accessor('asset', {
      header: () => <ColumnHeaderItem id="Asset" />,
      cell: (info) => {
        const { coinSymbol } = info.getValue();

        return (
          <AssetItem coinSymbol={coinSymbol} iconHeight={32} iconWidth={32} />
        );
      },
      maxSize: 130,
      cellAlign: 'start',
      headerAlign: 'start',
      enableSorting: false,
      enableTruncating: true,
    }),
    accessor('period', {
      header: () => <ColumnHeaderItem id="Period" />,
      cell: (info) => {
        const { active, coinSymbol } = info.getValue();

        return (
          <AvailablePlansPeriodButtons
            activePeriod={active}
            coinSymbol={coinSymbol}
            isAllPeriodsSelected={false}
          />
        );
      },
      cellAlign: 'start',
      headerAlign: 'start',
      enableSorting: false,
      enableTruncating: false,
    }),
    accessor('estimation', {
      header: () => <ColumnHeaderItem id="Estimation" />,
      cell: (info) => <BonusItem info={info} />,
      maxSize: 30,
      cellAlign: 'start',
      headerAlign: 'start',
      enableSorting: false,
    }),
    accessor('minDeposit', {
      header: () => <ColumnHeaderItem id="MinDeposit" />,
      cell: (info) => {
        const { minDeposit, coinSymbol } = info.getValue();

        return (
          <MinDepositItem minDeposit={minDeposit} coinSymbol={coinSymbol} />
        );
      },
      cellAlign: 'end',
      headerAlign: 'end',
      enableSorting: false,
    }),
    accessor('action', {
      header: () => <ColumnHeaderItem id="Action" />,
      cell: (info) => {
        const { coinSymbol } = info.getValue();

        return <StakeItem coinSymbol={coinSymbol} />;
      },
      cellAlign: 'end',
      headerAlign: 'end',
      enableSorting: false,
      enableTruncating: false,
    }),
  ];

  if (isTabletDown) {
    return allColumns.filter((col) =>
      smallTableColumns.includes(col.accessorKey),
    );
  }

  return allColumns;
};

export default availablePlansTableColumns;
