import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import cx from 'classnames';

import { Button } from '@shared/ui';

import { isShowSensitiveInfoSelector } from '@store/settings/settingsSelectors';
import { settingsActions } from '@store/settings/settingsSlice';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as EyeClosed } from '@icons/eye-closed.svg';
import { ReactComponent as EyeOpen } from '@icons/eye-open.svg';

import styles from './TogglePersonalDataButton.module.scss';

const TogglePersonalDataButton = ({ inversion, ...props }) => {
  const dispatch = useDispatch();

  const { isMobile } = useBreakpoints();

  const isShowSensitiveInfo = useSelector(isShowSensitiveInfoSelector);

  const togglePersonalData = () => {
    dispatch(settingsActions.toggleShowSensitiveInfo());
  };

  return (
    <Button
      variant="text"
      onClick={togglePersonalData}
      inversion={inversion}
      {...props}
    >
      <div
        className={cx(styles.content, {
          [styles.inversion]: inversion,
        })}
      >
        <div className={styles.icon}>
          {isShowSensitiveInfo ? <EyeClosed /> : <EyeOpen />}
        </div>
        {!isMobile && (
          <span className={styles.title}>
            <FormattedMessage
              id={isShowSensitiveInfo ? 'HideData' : 'ShowData'}
            />
          </span>
        )}
      </div>
    </Button>
  );
};

export default memo(TogglePersonalDataButton);
