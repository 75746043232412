import { forwardRef, memo } from 'react';

import classnames from 'classnames';

import { NOOP } from '@constants';

import { ReactComponent as CheckIconSVG } from '@assets/images/icons/check-icon.svg';

import styles from './CheckBox.module.scss';

const CheckBox = forwardRef(
  (
    {
      checked = false,
      label,
      onChange = NOOP,
      className,
      containerClassName,
      checkedIcon,
      uncheckedIcon,
      noBorder = false,
      noBackground = false,
      inversion,
    },
    ref,
  ) => {
    const toggle = (e) => {
      onChange(!checked, e);
    };

    return (
      <label className={classnames(styles.checkbox, className, {})}>
        <span>
          <div
            className={classnames(
              styles.container,
              {
                [styles.container__no_border]: noBorder,
                [styles.inversion]: inversion,
                [styles.checked]: checked,
              },
              containerClassName,
            )}
          >
            {checked && (
              <div
                className={classnames(styles.icon_container, {
                  [styles.icon_container__no_background]: noBackground,
                })}
              >
                {checkedIcon ? (
                  checkedIcon
                ) : (
                  <CheckIconSVG className={styles.icon} />
                )}
              </div>
            )}

            {!checked && uncheckedIcon && (
              <div
                className={classnames(
                  styles.icon_container,
                  styles.icon_container__no_background,
                )}
              >
                {uncheckedIcon}
              </div>
            )}

            <input
              ref={ref}
              type="checkbox"
              checked={checked}
              onChange={toggle}
              hidden
            />
          </div>
        </span>

        {!!label && <span className={styles.label}>{label}</span>}
      </label>
    );
  },
);

export default memo(CheckBox);
