import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../utils';

const defaultCoins = [];
const defaultLimits = [];
const defaultPeriods = [];
const defaultMyContracts = [];
const defaultStakingEarnings = [];

const defaultData = {
  coins: defaultCoins,
  limits: defaultLimits,
  periods: defaultPeriods,
};

const settingsDataApiSelector =
  ApiSelectorsUtils.createDataApiSelector(defaultMyContracts);
const earningsDataApiSelector =
  ApiSelectorsUtils.createDataApiSelector(defaultData);

const coinsSelector = createSelector(earningsDataApiSelector, (data) => {
  return data?.coins || defaultCoins;
});

const limitsSelector = createSelector(earningsDataApiSelector, (data) => {
  return data?.limits || defaultLimits;
});

const periodsSelector = createSelector(earningsDataApiSelector, (data) => {
  return data?.periods || defaultPeriods;
});

const myStakingSelector = createSelector(settingsDataApiSelector, (data) => {
  return data || defaultMyContracts;
});

const stakingEarningsSelector = createSelector(
  settingsDataApiSelector,
  (data) => {
    return data || defaultStakingEarnings;
  },
);

export const MyStakingSelectors = {
  coinsSelector,
  limitsSelector,
  periodsSelector,
  myStakingSelector,
  stakingEarningsSelector,
};
