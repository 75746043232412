import React, { memo, useId } from 'react';

import classNames from 'classnames';

import Button from '@components/Button/Button';
import Dropdown from '@components/Dropdown';

import { ReactComponent as ThreeHorizontalDotsIconSVG } from '@icons/three-horizontal-dots.svg';

import styles from './CardMenuActions.module.scss';

const CardMenuActions = ({ actions, customStyles = {} }) => {
  const id = useId();

  if (!actions.length) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.card_menu_actions,
        customStyles.card_menu_actions,
      )}
    >
      <Dropdown
        id={`controls-dropdown-${id}`}
        topOffset={1}
        menuItems={actions}
      >
        {({ triggerRef, handleMenuTrigger }) => (
          <Button
            ref={triggerRef}
            className={styles.button}
            onClick={handleMenuTrigger}
          >
            <ThreeHorizontalDotsIconSVG className="three-horizontal-dots-icon" />
          </Button>
        )}
      </Dropdown>
    </div>
  );
};

export default memo(CardMenuActions);
