import { useGetAnnouncementTopicsQuery } from '@api/announcementsAPI';
import { AnnouncementsApiSelectors } from '@api/selectors';

const useGetAnnouncementTopicsQueryResult = (args, options) => {
  return useGetAnnouncementTopicsQuery(args, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      topics: AnnouncementsApiSelectors.announcementTopicsSelector(result),
    }),
  });
};

export default useGetAnnouncementTopicsQueryResult;
