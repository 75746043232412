import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

import { getFormData } from '@utils/api';

export const visaAPI = createApi({
  reducerPath: 'visaAPI',
  baseQuery: client,
  tagTypes: ['CardAccountBalance'],
  endpoints: (builder) => ({
    availableCard: builder.query({
      query: () => 'card/list-available-cards',
      providesTags: ['CardAccountBalance'],
    }),
    getUserCurrentCardBalance: builder.query({
      query: () => 'card/user-current-balance',
      providesTags: ['CardAccountBalance'],
    }),
    transferFromAccountToCard: builder.mutation({
      query: ({ coin, amount }) => {
        return {
          url: 'card/deposit',
          method: 'POST',
          body: getFormData({ coin, amount }),
        };
      },
      invalidatesTags: ['CardAccountBalance'],
    }),
    withdrawFromCardToAccount: builder.mutation({
      query: ({ coin, amount }) => {
        return {
          url: 'card/withdraw',
          method: 'POST',
          body: getFormData({ coin, amount }),
        };
      },
      invalidatesTags: ['CardAccountBalance'],
    }),
    joinToCardWaitList: builder.mutation({
      query: (body) => {
        return {
          url: 'card/join-waitlist',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CardAccountBalance'],
    }),
    addConsumerAccount: builder.mutation({
      query: (body) => {
        return {
          url: 'card/add-consumer',
          method: 'POST',
          body: getFormData(body),
        };
      },
    }),
    activateCard: builder.mutation({
      query: (body) => {
        return {
          url: 'card/activate-card',
          method: 'POST',
          body: getFormData(body),
        };
      },
    }),
    requestNewCard: builder.mutation({
      query: (body) => {
        return {
          url: 'card/request-new-card',
          method: 'POST',
          body: getFormData(body),
        };
      },
    }),
    issuePhysicalCard: builder.mutation({
      query: (body) => {
        return {
          url: 'card/issue-physical-card',
          method: 'POST',
          body: getFormData(body),
        };
      },
    }),
    getCardDetails: builder.query({
      query: () => 'card/get-card-details',
    }),
    getCardAccountDetails: builder.query({
      query: () => 'card/get-card-account-details',
    }),
  }),
});

export const {
  useAvailableCardQuery,
  useGetUserCurrentCardBalanceQuery,
  useTransferFromAccountToCardMutation,
  useWithdrawFromCardToAccountMutation,
  useJoinToCardWaitListMutation,
  useAddConsumerAccountMutation,
  useActivateCardMutation,
  useRequestNewCardMutation,
  useIssuePhysicalCardMutation,
  useGetCardDetailsQuery,
  useGetCardAccountDetailsQuery,
} = visaAPI;
