import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { createFilter } from 'react-select';

import { COIN_TYPE } from '@shared/constants';
import { SelectController } from '@shared/ui/Select/Select';

/**
 * to get currencies array you should use transformToCurrencyOptions function
 * */

const CurrenciesSelect = ({ currencies = [], grouped, ...props }) => {
  const intl = useIntl();

  const getCoinsByType = (type) => {
    return currencies.filter((coin) => coin.type === type);
  };

  const fiatCoins = useMemo(() => {
    if (!grouped) return [];

    return getCoinsByType(COIN_TYPE.FIAT);
  }, [grouped, currencies]);

  const cryptoCoins = useMemo(() => {
    if (!grouped) return [];

    return getCoinsByType(COIN_TYPE.CRYPTO);
  }, [grouped, currencies]);

  const groupedByTypeCoins = [
    { label: intl.formatMessage({ id: 'Fiat' }), options: fiatCoins },
    { label: intl.formatMessage({ id: 'Crypto' }), options: cryptoCoins },
  ];

  const currenciesOptions = grouped ? groupedByTypeCoins : currencies;

  const filterConfig = {
    ignoreCase: true,
    trim: true,
    stringify: (opt) =>
      `${opt.value} ${opt.data?.fullName ? opt.data.fullName : ''}`,
  };

  return (
    <SelectController
      options={currenciesOptions}
      placeholder={`${intl.formatMessage({ id: 'SelectCoin' })}...`}
      filterOption={createFilter(filterConfig)}
      {...props}
    />
  );
};

export default CurrenciesSelect;
