import React, { memo, useMemo, useRef } from 'react';

import cx from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { CustomSwiper } from '@shared/components';

import { LandingPageContainer } from '@pages/landings/components';

import useBreakpoints from '@hooks/useBreakpoints';
import useWindowResize from '@hooks/useWindowResize';

import styles from './LandingPageSwiper.module.scss';

import 'swiper/css/virtual';
import 'swiper/css';

/**
 * slides = [
 *   { id: 'some-id', ... }
 *   ...
 * ]
 * */

const LandingPageSwiper = ({
  slides = [],
  SlideComponent,
  slideStyles,
  customStyles = {},
  externalContainerWidth,
  inversion,
  slidesOffsetBefore,
  slidesOffsetAfter,
  withExpandableSlides,
  ...props
}) => {
  const containerRef = useRef();

  const { isMobile } = useBreakpoints();

  const { size: containerSize, isResizing } = useWindowResize(containerRef);

  const getSlidesOffset = () => {
    if (!containerSize?.width) return 0;

    let containerWidth = externalContainerWidth
      ? externalContainerWidth
      : containerSize?.width;

    return (window.innerWidth - containerWidth) / 2;
  };

  const slidesOffsetBeforeMemo = useMemo(
    () =>
      slidesOffsetBefore !== undefined ? slidesOffsetBefore : getSlidesOffset(),
    [isMobile, containerSize?.width, externalContainerWidth, isResizing],
  );

  const slidesOffsetAfterMemo = useMemo(
    () =>
      slidesOffsetAfter !== undefined ? slidesOffsetAfter : getSlidesOffset(),
    [containerSize?.width, externalContainerWidth, isResizing],
  );

  return (
    <>
      <LandingPageContainer>
        <div ref={containerRef} />
      </LandingPageContainer>
      <div
        className={cx(
          styles.landing_page_swiper,
          customStyles.landing_page_swiper,
        )}
      >
        <CustomSwiper
          slidesOffsetBefore={slidesOffsetBeforeMemo}
          slidesOffsetAfter={slidesOffsetAfterMemo}
          scrollBarInLandingPageContainer={props?.isShowScrollBar}
          isFreeMode={false}
          scrollbar={{ snapOnRelease: false }}
          customStyles={{ ...styles, ...customStyles }}
          {...props}
        >
          {slides.map((slide, index) => {
            return (
              <SwiperSlide
                key={slide.id}
                style={slideStyles}
                className={cx(customStyles.landing_page_swiper_slide, {
                  [styles.expandable_slide]: withExpandableSlides,
                })}
                virtualIndex={props?.virtual ? index : undefined}
              >
                <SlideComponent
                  inversion={inversion}
                  index={index}
                  {...slide}
                  // TODO: think how pass this prop
                  // onChangeIsShowCursorIcon={changeIsShowCursorIcon}
                />
              </SwiperSlide>
            );
          })}
        </CustomSwiper>
      </div>
    </>
  );
};

export default memo(LandingPageSwiper);
