import { forwardRef } from 'react';

import cx from 'classnames';

import { Button } from '@shared/ui';

import s from './ButtonIconCircle.module.scss';

const ButtonIconCircle = forwardRef(({ icon, ...props }, ref) => {
  return (
    <Button ref={ref} isCircle customStyles={s} {...props}>
      <div
        className={cx(s.button_icon_circle, s[props.color], s[props.variant])}
      >
        {icon}
      </div>
    </Button>
  );
});

export default ButtonIconCircle;
