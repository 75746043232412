import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import useSidebar from '@hooks/useSidebar/useSidebar';

import { LOCAL_STORAGE_SEARCH_MENU_RECENTLY, sidebarIds } from '@constants';

import { useSearchMenuList } from '../../hooks/useSearchMenuList';
import { LIMIT_COUNT_SEARCH_ITEMS } from '../../SearchMenu.constant';
import { SearchInput } from '../SearchInput';
import { SearchMenuItem } from '../SearchMenuItem';

import styles from './SearchMenuContent.module.scss';
import mockData from 'src/mock.json';

const { inTrendList } = mockData.SearchMenu;

const SearchMenuContent = () => {
  const [searchParam, setSearchParam] = useState('');
  const filteredSearchList = useSearchMenuList(searchParam);

  const [recentlyList, setRecentlyList] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_MENU_RECENTLY)) ?? [],
  );

  const addRecently = (item) => {
    const existFilteredList = recentlyList.filter(
      ({ title }) => title !== item.title,
    );
    const newList = [item, ...existFilteredList].slice(
      0,
      LIMIT_COUNT_SEARCH_ITEMS,
    );

    setRecentlyList(newList);
    localStorage.setItem(
      LOCAL_STORAGE_SEARCH_MENU_RECENTLY,
      JSON.stringify(newList),
    );
  };

  const { closeSidebar } = useSidebar(sidebarIds.SEARCH);

  const handleItemClick = (item) => {
    addRecently(item);
    closeSidebar();
  };

  return (
    <div className={styles.content}>
      <SearchInput onSearchValueChange={setSearchParam} />

      <div
        className={cx(
          'd-flex justify-content-sm-start justify-content-xl-between',
          'mt-sm-30 mt-xl-32 mt-3xl-36',
          'ps-sm-0 ps-xl-24 pe-sm-20 pe-3xl-0',
        )}
      >
        <div
          className={cx(
            'd-flex flex-column gap-sm-10 gap-3xl-14 mt-sm-0 mt-3xl-0',
            styles.list,
          )}
        >
          {!filteredSearchList.length && (
            <div className={styles.text_info}>
              <FormattedMessage id="NothingFound" />
            </div>
          )}

          {!!filteredSearchList.length &&
            filteredSearchList.map((item) => (
              <SearchMenuItem
                itemData={item}
                hightLightText={searchParam}
                onClick={handleItemClick}
              />
            ))}
        </div>

        <div
          className={cx(
            'd-sm-none d-md-flex flex-column',
            'gap-sm-10 gap-3xl-14 mt-sm-0  mt-xl-4 mt-3xl-0',
            styles.list,
          )}
        >
          <h3 className={styles.header}>
            <FormattedMessage id="Recently" />
          </h3>
          {!recentlyList.length && (
            <div className={styles.text_info}>
              <FormattedMessage id="NoRecentSearches" />
            </div>
          )}

          {!!recentlyList.length &&
            recentlyList.map((item) => (
              <SearchMenuItem itemData={item} onClick={closeSidebar} />
            ))}
        </div>

        <div
          className={cx(
            'd-sm-none d-xl-flex flex-column gap-14 mt-sm-4 mt-3xl-0',
            styles.list,
          )}
        >
          <h3 className={styles.header}>
            <FormattedMessage id="InTrend" />
          </h3>
          {inTrendList.map((item) => (
            <SearchMenuItem itemData={item} onClick={handleItemClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchMenuContent;
