import { memo } from 'react';

import classNames from 'classnames';

import styles from './BotInfoItem.module.scss';

const BotInfoItem = ({ title, children, customStyles = {} }) => {
  return (
    <div
      className={classNames(
        'd-flex justify-content-between',
        customStyles.bot_info_item,
      )}
    >
      <div className={classNames(styles.title, customStyles.title)}>
        {title}:
      </div>
      <div className={classNames(styles.children, customStyles.children)}>
        {children}
      </div>
    </div>
  );
};

export default memo(BotInfoItem);
