import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './CustomCreateLabel.module.scss';

const CustomCreateLabel = ({ inputValue }) => {
  return (
    <div className={styles.create_label}>
      <div className={styles.plus_circle}>+</div>
      <FormattedMessage id="AddNew" />
      {` ${inputValue}`}
    </div>
  );
};

export default memo(CustomCreateLabel);
