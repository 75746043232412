import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

import { DEFAULT_REQUEST_STAKING_LIMIT } from '@constants';

export const stakingAPI = createApi({
  reducerPath: 'stakingAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    stakingSettings: builder.query({
      query: () => ({
        url: '/staking/settings',
        method: 'GET',
      }),
    }),
    createStaking: builder.mutation({
      query: ({ period, amount, coin_symbol, isGrowthReward }) => ({
        url: '/staking/create',
        body: {
          period,
          amount,
          coin_symbol,
          isGrowthReward,
        },
        method: 'POST',
      }),
      invalidatesTags: ['Stake'],
    }),
    stakingList: builder.query({
      query: ({ limit = DEFAULT_REQUEST_STAKING_LIMIT }) => ({
        method: 'GET',
        url: '/staking/list',
        params: {
          limit,
        },
      }),
      providesTags: ['Stake'],
    }),
    stakingEarnings: builder.query({
      query: ({ stakingId }) => ({
        url: `/staking/earnings/${stakingId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useStakingSettingsQuery,
  useCreateStakingMutation,
  useStakingListQuery,
  useLazyStakingListQuery,
  useStakingEarningsQuery,
} = stakingAPI;
