import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useForm } from 'react-hook-form';

import { Button, PasswordInputController } from '@shared/ui';
import { validatePassword } from '@shared/validations';

import { useProfileMutations } from '@store/api/hooks';

import styles from './PasswordGetCodeForm.module.scss';

const PasswordGetCodeForm = ({ onSubmit }) => {
  const intl = useIntl();

  const methods = useForm();

  const { handleSubmit } = methods;

  const { onSendSMS, isSendSMSLoading } = useProfileMutations();

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    size: 'l',
  };

  const handleFormSubmit = async () => {
    await onSendSMS();

    onSubmit();
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
      {/*TODO: implement on API will be ready in scope of
      <PasswordInputController
        name="login_password"
        label={`${intl.formatMessage({
          id: 'EnterPassword',
        })}:`}
        placeholder={intl.formatMessage({
          id: 'EnterPassword',
        })}
        rules={{
          required: intl.formatMessage({ id: 'PasswordIsRequired' }),
          validate: {
            format: validatePassword(intl),
          },
        }}
        {...commonFieldProps}
        {...methods}
      />
      */}
      <Button fill variant="outlined" isLoading={isSendSMSLoading}>
        <FormattedMessage id="GetCode" />
      </Button>
    </form>
  );
};

export default memo(PasswordGetCodeForm);
