import { useIntl } from 'react-intl';

import { toast } from 'react-toastify';

import { formatObjectKeysFromCamelToSnakeCase } from '@api/utils';
import {
  useAdvCashDepositCoinRequestMutation,
  useClearJunctionDepositRequestMutation,
  useCreateCoinAddressMutation,
  useCreateWithdrawAddressMutation,
  useMakeWithdrawalCoinMutation,
} from '@api/walletsAPI';

const useWalletsMutations = () => {
  const intl = useIntl();

  const [createCoinAddress, { isLoading: isLoadingCreateCoinAddress }] =
    useCreateCoinAddressMutation();

  const [
    advCashDepositCoinRequest,
    { isLoading: isLoadingAdvCashDepositCoinRequest },
  ] = useAdvCashDepositCoinRequestMutation();

  const [
    clearJunctionDepositRequest,
    { isLoading: isLoadingClearJunctionDepositRequest },
  ] = useClearJunctionDepositRequestMutation();

  const [createWithdrawAddress, { isLoading: isLoadingCreateWithdrawAddress }] =
    useCreateWithdrawAddressMutation();

  const [makeWithdrawalCoin, { isLoading: isLoadingMakeWithdrawalCoin }] =
    useMakeWithdrawalCoinMutation();

  const onCreateCoinAddress = async (coinSymbol) => {
    await createCoinAddress({ coinSymbol }).unwrap();

    toast.success(intl.formatMessage({ id: 'DepositAddressCreatedSuccess' }));
  };

  const onAdvCashDepositCoinRequest = async (coinSymbol, body) => {
    const advCashDeposit = await advCashDepositCoinRequest({
      coinSymbol,
      body,
    }).unwrap();

    return advCashDeposit;
  };

  const onClearJunctionDepositRequest = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    const clearJunctionDeposit = await clearJunctionDepositRequest({
      body,
    }).unwrap();

    return {
      allowedCurrencies: Object.keys(clearJunctionDeposit.data.allowedSymbols),
      clientRefCode: clearJunctionDeposit.data.clientRefCode,
      bankDetails: clearJunctionDeposit.data.requisites,
    };
  };

  const onCreateWithdrawAddress = async (data) => {
    const address = await createWithdrawAddress(data).unwrap();

    toast.success(intl.formatMessage({ id: 'WithdrawAddressCreatedSuccess' }));

    return address;
  };

  const onMakeWithdrawalCoin = async (data) => {
    const coin = data.coinSymbol;
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    const withdrawal = await makeWithdrawalCoin({
      coin,
      body,
    }).unwrap();

    toast.success(
      intl.formatMessage({ id: 'CoinWithdrawalMadeSuccess' }, { coin }),
    );

    return withdrawal;
  };

  return {
    onCreateCoinAddress,
    isLoadingCreateCoinAddress,
    onAdvCashDepositCoinRequest,
    isLoadingAdvCashDepositCoinRequest,
    onClearJunctionDepositRequest,
    isLoadingClearJunctionDepositRequest,
    onCreateWithdrawAddress,
    isLoadingCreateWithdrawAddress,
    onMakeWithdrawalCoin,
    isLoadingMakeWithdrawalCoin,
  };
};

export default useWalletsMutations;
