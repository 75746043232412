import { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';

const defaultDeps = [];

const useElementResize = (
  elementRef,
  { deps = defaultDeps, isBorderBoxSize = false } = {},
) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const debouncedSetSize = useRef(
    debounce((width, height) => setSize({ width, height }), 100),
  ).current;

  const observerRef = useRef(
    new ResizeObserver((entries) => {
      let { width, height } = entries[0].contentRect;

      if (isBorderBoxSize) {
        width = entries[0].borderBoxSize[0].inlineSize;
        height = entries[0].borderBoxSize[0].blockSize;
      }

      debouncedSetSize(width, height);
    }),
  ).current;

  useEffect(() => {
    return () => debouncedSetSize.cancel();
  }, [debouncedSetSize]);

  useEffect(() => {
    if (elementRef.current) {
      observerRef.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observerRef.unobserve(elementRef.current);
      } else {
        observerRef.disconnect();
      }
    };
  }, [elementRef.current, ...deps]);

  return size;
};

export default useElementResize;
