import React, { memo, useRef, useState } from 'react';

import cx from 'classnames';

import { Button } from '@shared/ui';

import useClickOutside from '@hooks/useClickOutside';

import { NOOP } from '@constants';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import styles from './ChartSelect.module.scss';

const ChartSelect = ({
  items,
  handleSelectItem = NOOP,
  isActiveItem = NOOP,
  title,
  hideChevron = false,
  dropdownHeight = 410,
  children,
}) => {
  const chartiqSelectRef = useRef();

  const [isOpened, setIsOpened] = useState(false);

  useClickOutside([chartiqSelectRef], () => setIsOpened(false));

  const generateItem = (item, index) => {
    switch (item.type) {
      case 'item':
        return (
          <li
            key={'chart-select-item-' + item.label + index}
            className={cx(styles.select_container__list__item, {
              [styles['select_container__list__item--active']]:
                isActiveItem(item),
            })}
            onClick={() => handleSelectItem(item)}
          >
            <cq-item stxtap={item.cmd}>{item.label}</cq-item>
          </li>
        );

      case 'separator':
        return (
          <div
            key={'chart-select-separator-' + item.label + index}
            className={styles.select_container__list__separator}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div ref={chartiqSelectRef} className={styles.chart_select}>
      <Button
        variant="text"
        customStyles={{
          button: cx(styles.select_button, {
            [styles.select_button__opened]: isOpened,
          }),
        }}
        onClick={() => setIsOpened((prevState) => !prevState)}
      >
        {title && <div className={styles.select_button__title}>{title}</div>}
        {!hideChevron && (
          <DownIconSVG
            className={styles.select_button__chevron}
            width={10}
            height={6}
          />
        )}
      </Button>

      <div
        className={cx(styles.select_container, {
          [styles['select_container--hidden']]: !isOpened,
        })}
        style={{ maxHeight: dropdownHeight }}
      >
        {children ? (
          React.cloneElement(children, {
            isOpen: isOpened,
            close: () => setIsOpened(false),
          })
        ) : (
          <ul className={styles.select_container__list}>
            {items.map((item, index) => generateItem(item, index))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default memo(ChartSelect);
