import React from 'react';
import { Navigate, createBrowserRouter, useRouteError } from 'react-router-dom';

import { ACCOUNT_SECURITY_SETTINGS } from '@shared/constants/account.constants';

import { RouteContainer, SecuritySettingRoute } from './routeHelpers';

import {
  AUTH_ROUTES,
  MAIN_ROUTES,
  ACCOUNT_ROUTES,
  ORDERS_ROUTES,
  REFERRAL_ROUTES,
  WALLETS_ROUTES,
  SIMPLE_CONVERTER_ROUTES,
  DEPOSIT_ROUTES,
  MARKETS_ROUTES,
  SERVICE_ROUTES,
  VISA_ROUTES,
} from 'src/routes.constants';

const BubbleError = () => {
  const error = useRouteError();

  throw error;
};

const router = createBrowserRouter([
  {
    path: AUTH_ROUTES.LOGIN,
    async lazy() {
      let { LoginPage } = await import('@pages/AuthPages/LoginPage');

      return { element: <RouteContainer notAuth element={LoginPage} /> };
    },
    errorElement: <BubbleError />,
  },
  {
    path: AUTH_ROUTES.REGISTER,
    async lazy() {
      let { RegisterPage } = await import('@pages/AuthPages/RegisterPage');

      return { element: <RouteContainer notAuth element={RegisterPage} /> };
    },
    errorElement: <BubbleError />,
  },
  {
    path: AUTH_ROUTES.FORGOT_PASSWORD,
    async lazy() {
      let { ForgotPasswordPage } = await import(
        '@pages/AuthPages/ForgotPasswordPage'
      );

      return {
        element: <RouteContainer notAuth element={ForgotPasswordPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.RESET_PASSWORD,
    async lazy() {
      let { ResetPasswordPage } = await import(
        '@pages/AuthPages/ResetPasswordPage'
      );

      return {
        element: <RouteContainer notAuth element={ResetPasswordPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: AUTH_ROUTES.RESET_2FA,
    async lazy() {
      let { ResetTwoFAPage } = await import('@pages/AuthPages/ResetTwoFAPage');

      return {
        element: <RouteContainer notAuth element={ResetTwoFAPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: AUTH_ROUTES.EMAIL_CONFIRMATION,
    async lazy() {
      let { EmailConfirmationPage } = await import(
        '@pages/AuthPages/EmailConfirmationPage'
      );

      return {
        element: <RouteContainer element={EmailConfirmationPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: AUTH_ROUTES.CONFIRM_PHONE,
    async lazy() {
      let { PhoneConfirmation } = await import(
        '@pages/AuthPages/PhoneConfirmation'
      );

      return {
        element: <RouteContainer element={PhoneConfirmation} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.EMAIL_CONFIRMED,
    async lazy() {
      let { EmailConfirmedPage } = await import(
        '@pages/AuthPages/EmailConfirmedPage'
      );

      return {
        element: <RouteContainer element={EmailConfirmedPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: AUTH_ROUTES.IP_CONFIRMED,
    async lazy() {
      let { ActivatedNewIpPage } = await import(
        '@pages/AuthPages/ActivatedNewIpPage'
      );

      return {
        element: <RouteContainer element={ActivatedNewIpPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.APPROVE_ACTION,
    async lazy() {
      let { ApproveActionPage } = await import(
        '@pages/AuthPages/ApproveActionPage'
      );

      return {
        element: <RouteContainer element={ApproveActionPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.TERMINAL,
    async lazy() {
      let { TerminalPage } = await import('@pages/TerminalPage');

      return {
        element: <RouteContainer element={TerminalPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.ACCOUNT,
    async lazy() {
      let { AccountPage } = await import('@pages/AccountPage');

      return {
        element: <RouteContainer auth element={AccountPage} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        element: <Navigate to={ACCOUNT_ROUTES.OVERVIEW} />,
      },
      {
        path: ACCOUNT_ROUTES.OVERVIEW,
        async lazy() {
          let { Overview } = await import('@pages/AccountPage/Overview');

          return {
            element: <Overview />,
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.PROFILE,
        async lazy() {
          let { Profile } = await import('@pages/AccountPage/Profile');

          return {
            element: <Profile />,
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.REFERRAL_PROGRAM,
        element: <Navigate to={REFERRAL_ROUTES.OVERVIEW} />,
      },
      {
        path: ACCOUNT_ROUTES.SECURITY,
        async lazy() {
          let { Security } = await import('@pages/AccountPage/Security');

          return {
            element: <Security />,
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.GOOGLE_AUTHENTICATION,
        async lazy() {
          let { EnableGoogleAuth } = await import(
            '@pages/AccountPage/Security/GoogleAuth/EnableGoogleAuth'
          );
          let { DisableGoogleAuth } = await import(
            '@pages/AccountPage/Security/GoogleAuth/DisableGoogleAuth'
          );

          return {
            element: (
              <SecuritySettingRoute
                securitySettingKey={
                  ACCOUNT_SECURITY_SETTINGS.isGoogleTwoFaExists
                }
                enableSettingElement={EnableGoogleAuth}
                disableSettingElement={DisableGoogleAuth}
              />
            ),
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.SMS_AUTHENTICATION,
        async lazy() {
          let { EnableSMSAuth } = await import(
            '@pages/AccountPage/Security/SMSAuth/EnableSMSAuth'
          );
          let { DisableSMSAuth } = await import(
            '@pages/AccountPage/Security/SMSAuth/DisableSMSAuth'
          );

          return {
            element: (
              <SecuritySettingRoute
                securitySettingKey={ACCOUNT_SECURITY_SETTINGS.isSmsTwoFaExists}
                enableSettingElement={EnableSMSAuth}
                disableSettingElement={DisableSMSAuth}
              />
            ),
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.TRADE_PASSWORD,
        async lazy() {
          let { EnableTradePassword } = await import(
            '@pages/AccountPage/Security/TradePassword/EnableTradePassword'
          );
          let { DisableTradePassword } = await import(
            '@pages/AccountPage/Security/TradePassword/DisableTradePassword'
          );

          return {
            element: (
              <SecuritySettingRoute
                securitySettingKey={
                  ACCOUNT_SECURITY_SETTINGS.isTradePasswordExists
                }
                enableSettingElement={EnableTradePassword}
                disableSettingElement={DisableTradePassword}
              />
            ),
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.ANTI_PHISHING_CODE,
        async lazy() {
          let { EnableAntiPhishingCode } = await import(
            '@pages/AccountPage/Security/AntiPhishingCode/EnableAntiPhishingCode'
          );
          let { DisableAntiPhishingCode } = await import(
            '@pages/AccountPage/Security/AntiPhishingCode/DisableAntiPhishingCode'
          );

          return {
            element: (
              <SecuritySettingRoute
                securitySettingKey={
                  ACCOUNT_SECURITY_SETTINGS.isAntiPhishingExists
                }
                enableSettingElement={EnableAntiPhishingCode}
                disableSettingElement={DisableAntiPhishingCode}
              />
            ),
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.VERIFICATION,
        async lazy() {
          let { Verification } = await import(
            '@pages/AccountPage/Verification'
          );

          return {
            element: <Verification />,
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.API_MANAGEMENT,
        async lazy() {
          let { ApiManagement } = await import(
            '@pages/AccountPage/ApiManagement'
          );

          return {
            element: <ApiManagement />,
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.API_KEY_SETTINGS,
        async lazy() {
          let { ApiKeySettings } = await import(
            '@pages/AccountPage/ApiManagement/ApiKeySettings'
          );

          return {
            element: <ApiKeySettings />,
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.LAST_LOGINS,
        async lazy() {
          let { LastLogins } = await import('@pages/AccountPage/LastLogins');

          return {
            element: <LastLogins />,
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.CHANGE_PASSWORD,
        async lazy() {
          let { ChangePassword } = await import(
            '@pages/AccountPage/Profile/ChangePassword'
          );

          return {
            element: <ChangePassword />,
          };
        },
      },
      {
        path: ACCOUNT_ROUTES.NOTIFICATIONS,
        async lazy() {
          let { Notifications } = await import(
            '@pages/AccountPage/Notifications'
          );

          return {
            element: <Notifications />,
          };
        },
      },
    ],
  },
  {
    path: ACCOUNT_ROUTES.KYC,
    async lazy() {
      let { KYC } = await import('@pages/AccountPage/Verification/KYC');

      return {
        element: <RouteContainer auth element={KYC} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: ACCOUNT_ROUTES.CONFIRM_NEW_API_KEY,
    async lazy() {
      let { ApiKeyConfirmNew } = await import(
        '@pages/AccountPage/ApiManagement/ApiKeyConfirmNew'
      );

      return {
        element: <RouteContainer auth element={ApiKeyConfirmNew} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: ACCOUNT_ROUTES.PRIVATE_API_KEY,
    async lazy() {
      let { ApiKeyShowOnce } = await import(
        '@pages/AccountPage/ApiManagement/ApiKeyShowOnce'
      );

      return {
        element: <RouteContainer auth element={ApiKeyShowOnce} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.WALLETS,
    async lazy() {
      let { WalletsPage } = await import('@pages/WalletsPage');

      return {
        element: <RouteContainer auth element={WalletsPage} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        element: <Navigate to={WALLETS_ROUTES.OVERVIEW} />,
      },
      {
        path: WALLETS_ROUTES.OVERVIEW,
        async lazy() {
          let { WalletsOverview } = await import(
            '@pages/WalletsPage/WalletsOverview'
          );

          return {
            element: <WalletsOverview />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.MAIN_ACCOUNT,
        async lazy() {
          let { MainAccount } = await import('@pages/WalletsPage/MainAccount');

          return {
            element: <MainAccount />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.SPOT_ACCOUNTS,
        async lazy() {
          let { SpotAccounts } = await import(
            '@pages/WalletsPage/SpotAccounts'
          );

          return {
            element: <SpotAccounts />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.SPOT_ACCOUNTS_DETAILS,
        async lazy() {
          let { SingleSpotAccount } = await import(
            '@pages/WalletsPage/SingleSpotAccount'
          );

          return {
            element: <SingleSpotAccount />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.VISA_CARD,
        async lazy() {
          let { VisaCardDetails } = await import(
            '@pages/WalletsPage/VisaCardDetails'
          );

          return {
            element: <VisaCardDetails />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.VISA_CARD_SETTINGS,
        async lazy() {
          let { VisaCardSettings } = await import(
            '@pages/WalletsPage/VisaCardSettings'
          );

          return {
            element: <VisaCardSettings />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.INSTANT_BONUS,
        async lazy() {
          let { InstantBonus } = await import(
            '@pages/WalletsPage/InstantBonus'
          );

          return {
            element: <InstantBonus />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.TRADING_BOTS,
        async lazy() {
          let { TradingBots } = await import('@pages/WalletsPage/TradingBots');

          return {
            element: <TradingBots />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.TRADING_BOTS_DETAILS,
        async lazy() {
          let { BotDetails } = await import('@pages/WalletsPage/BotDetails');

          return {
            element: <BotDetails />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.TRADING_BOTS_START,
        async lazy() {
          let { BotStart } = await import('@pages/WalletsPage/BotStart');

          return {
            element: <BotStart />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.LAUNCHPAD,
        async lazy() {
          let { Launchpad } = await import('@pages/WalletsPage/Launchpad');

          return {
            element: <Launchpad />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.STAKING,
        async lazy() {
          let { Staking } = await import('@pages/WalletsPage/Staking');

          return {
            element: <Staking />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.STAKING_ASSETS_HISTORY,
        async lazy() {
          let { StakingHistory } = await import(
            '@pages/WalletsPage/StakingHistory'
          );

          return {
            element: <StakingHistory />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.TRANSACTION_HISTORY,
        async lazy() {
          let { TransactionHistory } = await import(
            '@pages/WalletsPage/TransactionHistory'
          );

          return {
            element: <TransactionHistory />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.WALLETS,
    async lazy() {
      let { WalletsPage } = await import('@pages/WalletsPage');

      return {
        element: <RouteContainer auth requireKYC element={WalletsPage} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        path: WALLETS_ROUTES.DEPOSIT,
        async lazy() {
          let { Deposit } = await import('@pages/WalletsPage/Deposit');

          return {
            element: <Deposit />,
          };
        },
      },
      {
        path: WALLETS_ROUTES.WITHDRAW,
        async lazy() {
          let { Withdraw } = await import('@pages/WalletsPage/Withdraw');

          return {
            element: <Withdraw />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.ORDERS,
    async lazy() {
      let { OrderPage } = await import('@pages/OrderPages');

      return {
        element: <RouteContainer auth element={OrderPage} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        element: <Navigate to={ORDERS_ROUTES.OPEN_ORDERS} />,
      },
      {
        path: ORDERS_ROUTES.OPEN_ORDERS,
        async lazy() {
          let { OpenOrders } = await import('@pages/OrderPages/OpenOrders');

          return {
            element: <OpenOrders />,
          };
        },
      },
      {
        path: ORDERS_ROUTES.ORDER_HISTORY,
        async lazy() {
          let { OrderHistory } = await import('@pages/OrderPages/OrderHistory');

          return {
            element: <OrderHistory />,
          };
        },
      },
      {
        path: ORDERS_ROUTES.TRADE_FEE,
        async lazy() {
          let { TradeFees } = await import('@pages/OrderPages/TradeFees');

          return {
            element: <TradeFees />,
          };
        },
      },
      {
        path: ORDERS_ROUTES.TRADE_HISTORY,
        async lazy() {
          let { TradeHistory } = await import('@pages/OrderPages/TradeHistory');

          return {
            element: <TradeHistory />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.REFERRAL,
    async lazy() {
      let { ReferralProgram } = await import(
        '@pages/AccountPage/ReferralProgram'
      );

      return {
        element: <RouteContainer auth element={ReferralProgram} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        element: <Navigate to={REFERRAL_ROUTES.OVERVIEW} />,
      },
      {
        path: REFERRAL_ROUTES.OVERVIEW,
        async lazy() {
          let { ReferralOverview } = await import(
            '@pages/AccountPage/ReferralProgram/ReferralOverview'
          );

          return {
            element: <ReferralOverview />,
          };
        },
      },
      {
        path: REFERRAL_ROUTES.EARNING_HISTORY,
        async lazy() {
          let { ReferralEarningsHistory } = await import(
            '@pages/AccountPage/ReferralProgram/ReferralEarningsHistory'
          );

          return {
            element: <ReferralEarningsHistory />,
          };
        },
      },
      {
        path: REFERRAL_ROUTES.REFERRAL_HISTORY,
        async lazy() {
          let { ReferralHistory } = await import(
            '@pages/AccountPage/ReferralProgram/ReferralHistory'
          );

          return {
            element: <ReferralHistory />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.DEPOSIT,
    async lazy() {
      let { DepositPage } = await import('@pages/DepositPage');

      return {
        element: <RouteContainer auth element={DepositPage} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        element: <Navigate to={DEPOSIT_ROUTES.CARD} />,
      },
      {
        path: DEPOSIT_ROUTES.CARD,
        async lazy() {
          let { DepositCard } = await import('@pages/DepositPage/DepositCard');

          return {
            element: <DepositCard />,
          };
        },
      },
      {
        path: DEPOSIT_ROUTES.BANK,
        async lazy() {
          let { DepositBank } = await import('@pages/DepositPage/DepositBank');

          return {
            element: <DepositBank />,
          };
        },
      },
      {
        path: DEPOSIT_ROUTES.ADVCASH,
        async lazy() {
          let { DepositAdvcash } = await import(
            '@pages/DepositPage/DepositAdvcash'
          );

          return {
            element: <DepositAdvcash />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.SIMPLE_CONVERTER,
    async lazy() {
      let { SimpleConverter } = await import('@pages/SimpleConverter');

      return {
        element: <RouteContainer auth element={SimpleConverter} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        element: <Navigate to={SIMPLE_CONVERTER_ROUTES.MARKET} />,
      },
      {
        path: SIMPLE_CONVERTER_ROUTES.MARKET,
        async lazy() {
          let { MarketConverter } = await import(
            '@pages/SimpleConverter/MarketConverter'
          );

          return {
            element: <MarketConverter />,
          };
        },
      },
      {
        path: SIMPLE_CONVERTER_ROUTES.LIMIT,
        async lazy() {
          let { LimitConverter } = await import(
            '@pages/SimpleConverter/LimitConverter'
          );

          return {
            element: <LimitConverter />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.MARKETS,
    async lazy() {
      let { MarketsPage } = await import('@pages/landings/MarketsPage');

      return {
        element: <RouteContainer element={MarketsPage} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        element: <Navigate to={MARKETS_ROUTES.ALL} />,
      },
      {
        path: MARKETS_ROUTES.ALL,
        async lazy() {
          let { Markets } = await import('@pages/landings/MarketsPage/Markets');

          return {
            element: <Markets />,
          };
        },
      },
      {
        path: MARKETS_ROUTES.DETAILS,
        async lazy() {
          let { MarketDetailsPage } = await import(
            '@pages/landings/MarketDetailsPage'
          );

          return {
            element: <MarketDetailsPage />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.SERVICE,
    async lazy() {
      let { ServicePage } = await import('@pages/ServicePage');

      return {
        element: <RouteContainer element={ServicePage} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        path: SERVICE_ROUTES.GLOSSARY,
        async lazy() {
          let { GlossaryPage } = await import(
            '@pages/LegacyPages/GlossaryPage'
          );

          return {
            element: <GlossaryPage />,
          };
        },
      },
      {
        path: SERVICE_ROUTES.TERM,
        async lazy() {
          let { TermPage } = await import('@pages/LegacyPages/TermPage');

          return {
            element: <TermPage />,
          };
        },
      },
      {
        path: SERVICE_ROUTES.INSTANT_BONUS_TERMS,
        async lazy() {
          let { InstantBonusPage } = await import(
            '@pages/LegacyPages/InstantBonusPage'
          );

          return {
            element: <InstantBonusPage />,
          };
        },
      },
      {
        path: SERVICE_ROUTES.ANNOUNCEMENTS,
        async lazy() {
          let { AnnouncementsPage } = await import(
            '@pages/LegacyPages/AnnouncementsPage'
          );

          return {
            element: <AnnouncementsPage />,
          };
        },
      },
      {
        path: SERVICE_ROUTES.ANNOUNCEMENT,
        async lazy() {
          let { AnnouncementPage } = await import(
            '@pages/LegacyPages/AnnouncementPage'
          );

          return {
            element: <AnnouncementPage />,
          };
        },
      },
      {
        path: SERVICE_ROUTES.ABOUT_US,
        async lazy() {
          let { AboutUsPage } = await import('@pages/LegacyPages/AboutUsPage');

          return {
            element: <AboutUsPage />,
          };
        },
      },
      {
        path: SERVICE_ROUTES.TOKEN_LISTING,
        async lazy() {
          let { TokenListingPage } = await import(
            '@pages/LegacyPages/TokenListingPage'
          );

          return {
            element: <TokenListingPage />,
          };
        },
      },
      {
        path: SERVICE_ROUTES.CAREERS,
        async lazy() {
          let { CareersPage } = await import('@pages/LegacyPages/CareersPage');

          return {
            element: <CareersPage />,
          };
        },
      },
      {
        path: SERVICE_ROUTES.CONTACT_US,
        async lazy() {
          let { ContactsPage } = await import(
            '@pages/LegacyPages/ContactsPage'
          );

          return {
            element: <ContactsPage />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.CARD,
    async lazy() {
      let { VisaPage } = await import('@pages/VisaPage');

      return {
        element: <RouteContainer element={VisaPage} />,
      };
    },
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        async lazy() {
          let { CardPage } = await import('@pages/landings/CardPage');

          return {
            element: <CardPage />,
          };
        },
      },
      {
        path: VISA_ROUTES.CARD_REFERRAL_PROGRAM,
        async lazy() {
          let { CardReferralProgramPage } = await import(
            '@pages/landings/CardReferralProgramPage'
          );

          return {
            element: <CardReferralProgramPage />,
          };
        },
      },
      {
        path: VISA_ROUTES.HOME,
        async lazy() {
          let { VisaHomePage } = await import('@pages/landings/VisaHomePage');

          return {
            element: <VisaHomePage />,
          };
        },
      },
      {
        path: VISA_ROUTES.ORDER_CARD,
        async lazy() {
          let { OrderCard } = await import('@pages/VisaPage/OrderCard');

          return {
            element: <OrderCard />,
          };
        },
      },
    ],
  },
  {
    path: MAIN_ROUTES.HOME,
    async lazy() {
      let { HomePage } = await import('@pages/landings/HomePage');

      return {
        element: <RouteContainer element={HomePage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.TRADING_BOTS,
    async lazy() {
      let { TradingBotsPage } = await import('@pages/landings/TradingBotsPage');

      return {
        element: <RouteContainer element={TradingBotsPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.STAKING_ASSETS,
    async lazy() {
      let { StakingAssetsPage } = await import(
        '@pages/landings/StakingAssetsPage'
      );

      return {
        element: <RouteContainer element={StakingAssetsPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.ROOT,
    async lazy() {
      let { RootRoute } = await import('./routeHelpers');

      return {
        element: <RootRoute />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: MAIN_ROUTES.COMING_SOON,
    async lazy() {
      let { default: ComingSoonPage } = await import('@pages/ComingSoonPage');

      return {
        element: <RouteContainer element={ComingSoonPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
  {
    path: '*',
    async lazy() {
      let { default: NotFoundPage } = await import('@pages/NotFoundPage');

      return {
        element: <RouteContainer element={NotFoundPage} />,
      };
    },
    errorElement: <BubbleError />,
  },
]);

export default router;
