import React, { memo, useState } from 'react';

import { HeaderCollapsible } from '@shared/components';

import { useIsShowAccountHeader } from '@pages/AccountPage/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { UserHeader } from './UserHeader';
import { UserInfoHeaderCollapsible } from './UserInfoHeaderCollapsible';

import styles from './UserInfoHeader.module.scss';

const UserInfoHeader = () => {
  const isShowAccountHeader = useIsShowAccountHeader();

  const [isActive, setIsActive] = useState(false);

  const { isTabletDown } = useBreakpoints();

  if (!isShowAccountHeader) return null;

  return (
    <HeaderCollapsible
      customStyles={styles}
      isActive={isActive && isTabletDown}
      isShowCollapsible={isTabletDown}
      onChange={setIsActive}
      headerComponent={<UserHeader />}
      collapsibleComponent={<UserInfoHeaderCollapsible />}
    />
  );
};

export default memo(UserInfoHeader);
