import { useState } from 'react';

const initState = {
  opened: false,
  params: {},
};

const useModalManager = () => {
  const [modals, setModals] = useState({});

  const register = (modalId) => {
    setModals((prevState) => ({
      ...prevState,
      [modalId]: initState,
    }));
  };

  const open = (modalId, params) => {
    setModals((prevState) => ({
      ...prevState,
      [modalId]: {
        ...prevState[modalId],
        opened: true,
        params: params ?? prevState[modalId].params,
      },
    }));
  };

  const close = (modalId) => {
    setModals((prevState) => ({
      ...prevState,
      [modalId]: {
        ...prevState[modalId],
        opened: false,
      },
    }));
  };

  const reset = (modalId) => {
    setModals((prevState) => ({
      ...prevState,
      [modalId]: initState,
    }));
  };

  const changeParams = (modalId, params) => {
    setModals((prevState) => ({
      ...prevState,
      [modalId]: {
        ...prevState[modalId],
        params: params ?? prevState[modalId].params,
      },
    }));
  };

  return { modals, open, close, reset, changeParams, register };
};
export default useModalManager;
