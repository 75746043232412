import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { MAIN_ACCOUNT_ID } from '@shared/constants';

import { useSpotAccounts } from '@store/api/hooks';

const useTransferableAccounts = (args, options) => {
  const { spotAccountsInfoMap } = useSpotAccounts(undefined, options);
  const intl = useIntl();

  const defaultTransferableAccounts = [
    {
      value: MAIN_ACCOUNT_ID,
      label: intl.formatMessage({ id: 'MainAccount' }),
    },
  ];

  const transferableAccounts = useMemo(() => {
    return spotAccountsInfoMap.reduce((accounts, account) => {
      if (account.transfer_allowed) {
        return [...accounts, { value: account.id, label: account.title }];
      }

      return accounts;
    }, defaultTransferableAccounts);
  }, [defaultTransferableAccounts, spotAccountsInfoMap]);

  return transferableAccounts;
};

export default useTransferableAccounts;
