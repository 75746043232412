import React, { memo } from 'react';

import TruncatedText from '@shared/components/TruncatedText';

import styles from './OrdersDate.module.scss';

const OrdersDate = ({ date }) => {
  return (
    <TruncatedText>
      <span className={styles.orders_date}>{date}</span>
    </TruncatedText>
  );
};

export default memo(OrdersDate);
