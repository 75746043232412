import { useEffect, useState } from 'react';

import { debounce } from 'lodash';

export default function useWindowResize(elementRef) {
  const [size, setSize] = useState();
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    const stopResize = debounce(() => {
      setIsResizing(false);
    }, 200);

    const calculateSize = () => {
      if (elementRef.current) {
        setSize(elementRef.current.getBoundingClientRect());
      }
    };

    const onResize = () => {
      setIsResizing(true);

      calculateSize();
      stopResize();
    };

    calculateSize();

    window.addEventListener('resize', onResize);

    return () => {
      stopResize.cancel();

      window.removeEventListener('resize', onResize);
    };
  }, [elementRef.current]);

  return { size, isResizing };
}
