export const IS_DEVELOPMENT =
  !process.env.REACT_APP_IS_DEVELOPMENT ||
  process.env.REACT_APP_IS_DEVELOPMENT === 'true'; // process.env.NODE_ENV === 'development';

export const domain =
  process.env.REACT_APP_DOMAIN_API || window.location.hostname;

export const api = `https://${domain}/api`;

export const wssWebsocket = `wss://${domain}/socket`;
