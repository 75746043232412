import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ItemTitle } from '@shared/components';
import { CRYPTO_CURRENCIES } from '@shared/constants';

import { useChartData } from '@pages/AccountPage/hooks';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { OverviewEarnWidget } from '../OverviewEarnWidget';

import { TokenGrowthTable } from './components';

import { TOKEN_ROUTES } from 'src/routes.constants';

import mockData from 'src/mock.json';

const TokenGrowth = () => {
  const token = CRYPTO_CURRENCIES.PRDX.toUpperCase();
  const { chartValue, totalCardProfit } = mockData.TokenGrowth;
  const { chartData, color, value } = useChartData(chartValue);

  const { symbol, value: selectedUserCurrency } = useSelector(currencySelector);

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.BTC,
    toCoinSymbol: selectedUserCurrency,
  });

  return (
    <>
      <ItemTitle
        title={
          <>
            {token} <FormattedMessage id="TokenGrowth" />
          </>
        }
        arrowTitle={<FormattedMessage id="Details" />}
        navigation={{ to: TOKEN_ROUTES.TOKEN_GROWTH }}
      />

      <OverviewEarnWidget
        percentChange={value}
        color={color}
        title={<FormattedMessage id="EstimatedTokenValue" values={{ token }} />}
        chartData={chartData}
        value={totalCardProfit}
        valueCurrency={token}
        totalValue={totalCardProfit * ratesByPair}
        totalValueCurrency={selectedUserCurrency}
        totalValueSymbol={symbol}
      />

      <TokenGrowthTable />
    </>
  );
};

export default memo(TokenGrowth);
