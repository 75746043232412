export const getFormData = (data) => {
  const form = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          form.append(key, v);
        });
      } else {
        value =
          typeof value === 'object' && !(value instanceof File)
            ? JSON.stringify(value)
            : value;

        form.append(key, value);
      }
    }
  });

  return form;
};
