import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import styles from './PaginationContainer.module.scss';

const PaginationContainer = ({
  isMinimizedPagination,
  pagesShownUpperLimit,
  meta = {},
  children,
  customStyles = {},
  isShowPageInfo = true,
}) => {
  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-center pt-30',
        styles.pagination_container,
        customStyles.pagination_container,
        { 'justify-content-between': isMinimizedPagination },
      )}
    >
      <div
        className={styles.left_section}
        style={{
          position: isMinimizedPagination ? 'initial' : 'absolute',
          display: isShowPageInfo ? 'block' : 'none',
        }}
      >
        {pagesShownUpperLimit} <FormattedMessage id="From" /> {meta.count || 0}{' '}
        {!isMinimizedPagination && (
          <span className="table-pagination-records">
            <FormattedMessage id="Records" />
          </span>
        )}
      </div>

      <div className="d-flex items-center gap-6">{children}</div>
    </div>
  );
};

export default memo(PaginationContainer);
