import { memo, useMemo, useState } from 'react';

import classNames from 'classnames';
import Collapse from 'rc-collapse';

import { collapseMotion } from '@shared/utils';

import { NOOP } from '@constants';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import styles from './HeaderCollapsible.module.scss';

const DEFAULT_ACTIVE_KEY = '1';

const HeaderCollapsible = ({
  headerComponent,
  collapsibleComponent,
  customStyles = {},
  isActive = false,
  isShowCollapsible,
  onChange = NOOP,
}) => {
  const [activeKey, setActiveKey] = useState([]);
  const isExpanded = activeKey.includes(DEFAULT_ACTIVE_KEY);

  const collapseItems = useMemo(
    () => [{ label: headerComponent, children: collapsibleComponent }],
    [headerComponent, collapsibleComponent],
  );

  return (
    <div className={classNames(styles.header, customStyles.header)}>
      <Collapse
        onChange={(value) => {
          setActiveKey(value);
          onChange(Boolean(value.length));
        }}
        activeKey={isShowCollapsible || isActive ? activeKey : []}
        isActive={isActive}
        onClick={(e) => {
          e.stopPropagation();
        }}
        expandIcon={({ isActive }) =>
          isShowCollapsible && (
            <DownIconSVG
              className={classNames(
                styles.chevron,
                isActive && styles.expanded,
                customStyles.chevron,
              )}
              height={10}
              width={18}
            />
          )
        }
        openMotion={collapseMotion}
        items={collapseItems}
      />

      {isShowCollapsible && (
        <hr className={classNames('d-xxl-none', isExpanded && 'mt-2')} />
      )}
    </div>
  );
};

export default memo(HeaderCollapsible);
