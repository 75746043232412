import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shared/ui';

import styles from './CreateLabel.module.scss';

const CreateLabel = (props) => {
  return (
    <Button type="button" variant="text" {...props} customStyles={styles}>
      <div className={styles.create_label}>
        <div className={styles.plus_circle}>+</div>
        <FormattedMessage id="AddNew" />
      </div>
    </Button>
  );
};

export default memo(CreateLabel);
