import { MARKET_STATISTIC_KEYS } from '@shared/constants';

import { useMarketsStatistics } from '@store/api/hooks';

const useLastMarketPrice = (marketId, options) => {
  const { marketStatistics } = useMarketsStatistics({ marketId }, options);

  const lastMarketPrice = marketStatistics?.[MARKET_STATISTIC_KEYS.DAY_CHANGE];

  return Number(lastMarketPrice) || 0;
};

export default useLastMarketPrice;
