import { memo, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useForm, useWatch } from 'react-hook-form';

import {
  CurrenciesSelect,
  TableFilters,
  transformToCurrencyOptions,
} from '@shared/components';
import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';
import { CRYPTO_CURRENCIES } from '@shared/constants';
import { SelectController, SwitchController } from '@shared/ui';

import { useCoinsInfo } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { PRECISION_ITEMS } from '@constants';

import s from './SpotAccountsTableControls.module.scss';

// Don't change order of these keys, because it will affect on destructured values in useEffect (const [1,2] = getValues())
export const SPOT_ACCOUNT_FILTER_NAMES = {
  PRECISION: 'precision',
  ESTIMATION_CURRENCY: 'estimation_currency',
  HIDE_ZERO: 'hide_zero',
};

const SpotAccountsTableControls = ({ filters, handleFiltersChange }) => {
  const defaultValues = {
    [TABLE_FILTERS_NAMES.SEARCHBAR]: '',
    [SPOT_ACCOUNT_FILTER_NAMES.PRECISION]: PRECISION_ITEMS.find(
      (item) => item.value === filters[SPOT_ACCOUNT_FILTER_NAMES.PRECISION],
    ),
    [SPOT_ACCOUNT_FILTER_NAMES.HIDE_ZERO]:
      filters[SPOT_ACCOUNT_FILTER_NAMES.HIDE_ZERO],
  };
  const methods = useForm({ defaultValues });

  const { isTabletDown, isDesktop } = useBreakpoints();

  const { coinsInfoMap, coinsInfo } = useCoinsInfo({
    orderByTypeDirection: 'asc',
  });

  const currencies = useMemo(
    () =>
      transformToCurrencyOptions({
        currencies: coinsInfoMap,
        isShowFullName: false,
        isShowCurrencyIcon: isDesktop,
      }),
    [coinsInfoMap, isDesktop],
  );

  const transformValues = (values) => {
    const transformedValues = {
      [TABLE_FILTERS_NAMES.SEARCHBAR]: values[TABLE_FILTERS_NAMES.SEARCHBAR],
      [SPOT_ACCOUNT_FILTER_NAMES.HIDE_ZERO]:
        values[SPOT_ACCOUNT_FILTER_NAMES.HIDE_ZERO],
    };

    if (values[SPOT_ACCOUNT_FILTER_NAMES.PRECISION]?.value) {
      transformedValues[SPOT_ACCOUNT_FILTER_NAMES.PRECISION] =
        values[SPOT_ACCOUNT_FILTER_NAMES.PRECISION]?.value;
    }

    if (values[SPOT_ACCOUNT_FILTER_NAMES.ESTIMATION_CURRENCY]?.value) {
      transformedValues[SPOT_ACCOUNT_FILTER_NAMES.ESTIMATION_CURRENCY] =
        values[SPOT_ACCOUNT_FILTER_NAMES.ESTIMATION_CURRENCY]?.value;
    }

    return transformedValues;
  };

  const estimationCurrencyWatch = useWatch({
    control: methods.control,
    name: SPOT_ACCOUNT_FILTER_NAMES.ESTIMATION_CURRENCY,
  });

  const precisionWatch = useWatch({
    control: methods.control,
    name: SPOT_ACCOUNT_FILTER_NAMES.ESTIMATION_CURRENCY,
  });

  const restFieldsWatch = useWatch({
    control: methods.control,
    name: Object.values(SPOT_ACCOUNT_FILTER_NAMES),
  });

  useEffect(() => {
    const selectedCurrency = estimationCurrencyWatch?.value;
    const precision = precisionWatch?.value;

    if (!selectedCurrency) return;
    const digits = coinsInfo[selectedCurrency]?.digits;

    if (precision === digits) return;

    methods.setValue(
      SPOT_ACCOUNT_FILTER_NAMES.PRECISION,
      PRECISION_ITEMS.find((item) => item.value === digits),
    );
  }, [coinsInfo, estimationCurrencyWatch, precisionWatch]);

  const handleFilterChange = () => {
    const values = methods.getValues();

    handleFiltersChange(transformValues(values));
  };

  useEffect(() => {
    const currenciesSelectValue =
      methods.getValues()[SPOT_ACCOUNT_FILTER_NAMES.ESTIMATION_CURRENCY];

    if (!currenciesSelectValue) {
      methods.setValue(
        SPOT_ACCOUNT_FILTER_NAMES.ESTIMATION_CURRENCY,
        currencies.find((currency) => currency.value === CRYPTO_CURRENCIES.BTC),
      );
    }
  }, [currencies]);

  useEffect(() => {
    const values = methods.getValues();

    handleFiltersChange(transformValues(values));
  }, [restFieldsWatch]);

  return (
    <TableFilters
      searchbar
      onFiltersChange={handleFilterChange}
      showAdvancedFilterButton={isTabletDown}
      customStyles={{ searchbar: s }}
      {...methods}
    >
      {({ control }) => (
        <div className="w-100 d-flex align-items-md-center align-items-start justify-content-between flex-md-row flex-column gap-20">
          <div className="d-flex align-items-center flex-md-row flex-column gap-20">
            <div className="d-flex align-items-center gap-10">
              <label>
                <FormattedMessage id="Quantity" />:
              </label>
              <SelectController
                name={SPOT_ACCOUNT_FILTER_NAMES.PRECISION}
                control={control}
                options={PRECISION_ITEMS}
                bordered
                size="m"
                styles={{ control: { minWidth: '120px' } }}
                {...methods}
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <label>
                <FormattedMessage id="Estimation" />:
              </label>
              <CurrenciesSelect
                currencies={currencies}
                name={SPOT_ACCOUNT_FILTER_NAMES.ESTIMATION_CURRENCY}
                control={control}
                bordered
                size="m"
                styles={{ control: { minWidth: '120px' } }}
                {...methods}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-10">
            <label className="text-nowrap">
              <FormattedMessage id="HideZeroBalances" />:
            </label>
            <SwitchController
              name={SPOT_ACCOUNT_FILTER_NAMES.HIDE_ZERO}
              control={control}
              {...methods}
            />
          </div>
        </div>
      )}
    </TableFilters>
  );
};

export default memo(SpotAccountsTableControls);
