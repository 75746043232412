import { useState, forwardRef, memo, useRef, useLayoutEffect } from 'react';

import classnames from 'classnames';

import { FieldController } from '@shared/components';

import useRunAfterUpdate from '@hooks/useRunAfterUpdate';

import { forceNumeric, isValidNumber, format } from '@utils/numbers';

import { DEFAULT_PRECISION, NOOP } from '@constants';

import styles from './Input.module.scss';

const Input = forwardRef(
  (
    {
      id,
      value = '',
      error,
      textCenter,
      precision = DEFAULT_PRECISION,
      removeZeroEnd = true,
      onlyNumbers = false,
      onlyInteger = false,
      label = null,
      labelClassName,
      prefix = null,
      suffix = null,
      fill = false,
      rounded = true,
      bordered = true,
      transparent = false,
      size = 'm', // 's', 'l'
      autoFocus = false,
      autoComplete = 'on',
      onChange = NOOP,
      onBlur = NOOP,
      autoSize = false,
      className,
      containerClassName,
      inputContainerClassName,
      inversion,
      customStyles = {},
      controllerRef,
      isError,
      errorMessage,
      ...rest
    },
    ref,
  ) => {
    const inputRef = useRef();
    const hiddenLabelRef = useRef();
    const [inputWidth, setInputWidth] = useState(0);

    const runAfterUpdate = useRunAfterUpdate();

    useLayoutEffect(() => {
      if (autoFocus) {
        const currentRef = ref || controllerRef || inputRef;

        currentRef.current.focus();
      }
    }, [autoFocus]);

    useLayoutEffect(() => {
      if (autoSize) {
        setInputWidth(
          Math.ceil(hiddenLabelRef.current.getBoundingClientRect().width + 7),
        );
      }
    }, [value]);

    const handleOnChange = (e) => {
      if (onlyNumbers || onlyInteger) {
        const numberText = forceNumeric(e.target.value);

        if (isValidNumber(numberText, precision + 1, onlyInteger)) {
          const input = e.target;
          const currentCursor = input.selectionStart;
          const [integerPart, decimalPart] = numberText.split('.');
          const generatedNumber =
            integerPart +
            (decimalPart === undefined
              ? ''
              : '.' + decimalPart.substring(0, precision));

          onChange(generatedNumber, e);

          runAfterUpdate(() => {
            input.selectionStart = currentCursor;
            input.selectionEnd = currentCursor;
          });
        }

        return;
      }

      onChange(e.target.value, e);
    };

    const handleOnBlur = (e) => {
      let formattedText = e.target.value.trim();

      if ((onlyNumbers || onlyInteger) && formattedText !== '') {
        formattedText = format(formattedText, {
          precision,
          noCommas: true,
          removeZeroEnd: removeZeroEnd,
        });
      }

      onChange(formattedText, e);

      onBlur(e);
    };

    return (
      <div
        className={classnames(
          styles.input,
          containerClassName,
          customStyles.input,
          {
            [styles.fill]: fill,
            [styles.rounded]: rounded,
            [styles.bordered]: bordered,
            [styles.transparent]: transparent,
            [styles.inversion]: inversion,
            [styles['input--disabled']]: rest.disabled,
            [customStyles['input--disabled']]: rest.disabled,
          },
        )}
      >
        {typeof label === 'string' ? (
          <label
            className={classnames(labelClassName, customStyles.input_label)}
            htmlFor={id}
          >
            {label}
          </label>
        ) : (
          label
        )}

        <div
          className={classnames(
            styles.input_container,
            customStyles.input_container,
            inputContainerClassName,
          )}
        >
          {autoSize && (
            <label className="hidden-label" ref={hiddenLabelRef}>
              {value}
            </label>
          )}

          {prefix}

          <input
            ref={ref || controllerRef || inputRef}
            type="text"
            id={id}
            className={classnames(
              className,
              customStyles.input_field,
              styles[size],
              {
                [styles.auto_size]: autoSize,
                'text-center': textCenter,
              },
            )}
            style={{ width: inputWidth && autoSize ? inputWidth : '' }}
            value={value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            autoComplete={autoComplete}
            {...rest}
          />

          {suffix}
        </div>

        {errorMessage && <div className="color-red mt-2">{errorMessage}</div>}
      </div>
    );
  },
);

export const InputController = memo((props) => {
  return <FieldController {...props} component={Input} />;
});

export default memo(Input);
