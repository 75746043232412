import cx from 'classnames';

import s from './TabPanel.module.scss';

const TabPanel = ({
  selectedTabIndex,
  tabIndex,
  customStyles = {},
  children,
  inversion,
}) => {
  if (selectedTabIndex !== tabIndex) return null;

  return (
    <div
      className={cx(s.tab_panel, customStyles.tab_panel, {
        [s.inversion]: inversion,
      })}
    >
      {children}
    </div>
  );
};

export default TabPanel;
