import React, { forwardRef, memo } from 'react';

const HiddenAdvCashForm = forwardRef(({ advCashDepositResponse }, ref) => {
  return (
    <form
      ref={ref}
      id="depositForm"
      action="https://wallet.advcash.com/sci/"
      method="post"
      target="_blank"
    >
      <input
        type="hidden"
        name="ac_order_id"
        value={advCashDepositResponse?.ac_order_id}
      />
      <input
        type="hidden"
        name="ac_sign"
        value={advCashDepositResponse?.ac_sign}
      />
      <input
        type="hidden"
        name="ac_account_email"
        value={advCashDepositResponse?.ac_account_email}
      />
      <input
        type="hidden"
        name="ac_sci_name"
        value={advCashDepositResponse?.ac_sci_name}
      />
      <input
        type="hidden"
        name="ac_amount"
        value={advCashDepositResponse?.ac_amount}
      />
      <input
        type="hidden"
        name="ac_currency"
        value={advCashDepositResponse?.ac_currency}
      />
      <input
        type="hidden"
        name="ac_custom_fields"
        value={advCashDepositResponse?.ac_custom_fields}
      />
    </form>
  );
});

export default memo(HiddenAdvCashForm);
