import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import {
  PersonalPercentChange,
  Widget,
  InfoItem,
  PercentChangeChart,
} from '@shared/components';

import useChartData from '@pages/AccountPage/hooks/useChartData';

import styles from './DayChangePnlWidget.module.scss';
import mockData from 'src/mock.json';

const DayChangePnlWidget = () => {
  const dayPnlChange = mockData.WalletsTotalBalanceChange.percent24H;
  const isZeroPnl = dayPnlChange === 0;

  const { chartData, color } = useChartData(dayPnlChange);

  return (
    <Widget className={styles.day_change_pnl_widget}>
      <InfoItem
        customStyles={styles}
        strong
        title={<FormattedMessage id="24hChange" />}
      >
        <div
          className={classNames(
            'd-flex flex-column',
            isZeroPnl ? 'gap-20' : 'gap-12',
          )}
        >
          <PersonalPercentChange
            color={color}
            containerClassName={styles.percent}
            percentValue={dayPnlChange}
          />

          <PercentChangeChart
            data={chartData}
            color={color}
            className={styles.chart}
          />
        </div>
      </InfoItem>
    </Widget>
  );
};

export default memo(DayChangePnlWidget);
