import { useMemo, useState } from 'react';

import BigNumber from 'bignumber.js';

import {
  useCoinsInfo,
  useGetDepositHistoryQueryResult,
} from '@store/api/hooks';

import { ITEMS_PER_PAGE } from '@constants';

const useDepositHistoryTable = ({ coinType } = {}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });

  const { depositHistory, meta, isSuccess } = useGetDepositHistoryQueryResult(
    {
      type: coinType,
      page: pageIndex + 1,
      limit: pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { coinsInfo } = useCoinsInfo();

  const data = useMemo(
    () => ({
      depositHistoryData: depositHistory.map((deposit) => {
        const coinSymbol = deposit.coin_symbol;
        const amountBN = new BigNumber(deposit.amount);
        const feeAmountBN = new BigNumber(deposit.fee_amount);
        const coinInfo = coinsInfo?.[coinSymbol];
        const precision = coinInfo?.digits;
        const blockChainExplorer = coinInfo?.blockchain_explorer;

        return {
          asset: { coinSymbol, system: deposit.external_system },
          details: {
            address: deposit.address,
            txid: deposit.txid,
            txidLink: blockChainExplorer
              ? `${blockChainExplorer}${deposit.txid}`
              : undefined,
          },
          amount: {
            amount: amountBN.toNumber(),
            precision,
          },
          feeAmount: {
            feeAmount: feeAmountBN.toNumber(),
            coinSymbol: deposit.fee_coin,
          },
          total: {
            total: amountBN.plus(feeAmountBN).toNumber(),
            precision,
          },
          date: deposit.created_at,
          status: deposit.status,
        };
      }),
      depositHistoryMeta: meta,
      isDepositHistoryTableSuccess: isSuccess,
      setPagination,
    }),
    [depositHistory, coinsInfo, meta, isSuccess],
  );

  return data;
};

export default useDepositHistoryTable;
