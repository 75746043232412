import { CARD_TYPE } from '@shared/constants';

export const VisaCardMock = {
  card: {
    type: CARD_TYPE.VIBRANT_BUTTERFLY,
    number: '4569 1234 5469 3456',
    expiredDate: '10/5/23',
    currentAmount: 20000,
    amountLimit: 100000,
    balance: '123456.78946',
    pinCode: '8712',
    cvv2Code: '985',
    spendingLimit: 1000,
  },
  counts: {
    cashback: {
      amount: 0,
      increase: 0,
    },
    superCashback: {
      amount: 0,
      increase: 0,
    },
    totalReferrals: {
      amount: 0,
      increase: 0,
    },
    totalReferralsRewards: {
      amount: 0,
      increase: 0,
    },
  },
};
