import React, { memo, useId, useMemo } from 'react';

import Table from '@components/gridTables/Table';

import { useLaunchpadAssets } from '@pages/AccountPage/Overview/OverviewEarnWidgets/hooks';

import launchpadAssetsTableColumns from '../LaunchpadAssetsTableColumns';

const LaunchpadAssetsTable = () => {
  const id = useId();

  const { assets, isSuccess } = useLaunchpadAssets();

  const columns = useMemo(() => launchpadAssetsTableColumns(), []);

  return (
    <Table
      id={id}
      className="account-tables-swipe my-20"
      columns={columns}
      data={assets}
      wideRowsS
      stripedRows={false}
      isLoading={!isSuccess}
    />
  );
};

export default memo(LaunchpadAssetsTable);
