import React, { useMemo, useState } from 'react';

import reactFastCompare from 'react-fast-compare';

import {
  clearExtendedLayout,
  getLayoutToCompare,
} from '@pages/TerminalPage/components/GridLayout/gridLayoutHelper';

import useBreakpoints from '@hooks/useBreakpoints';

import { LOCAL_STORAGE_LAYOUT_SETTINGS } from '@constants';

import defaultTemplates from './defaultTemplates.json';

const GridLayoutContext = React.createContext();

const { Provider } = GridLayoutContext;

const GridLayoutProvider = ({ children }) => {
  const [mappedLayout, setMappedLayout] = useState([]);
  const [isLockedLayout, setIsLockLayout] = useState(true);
  const [isUpdatingLayout, setIsUpdatingLayout] = useState(false);

  const { isMobile, isTablet } = useBreakpoints();

  const getGridLayoutSettings = () =>
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_LAYOUT_SETTINGS));

  const [selectedTemplateId, setSelectedTemplateId] = useState(() => {
    const gridLayoutSettings = getGridLayoutSettings();

    if (isMobile) {
      return defaultTemplates.mobile.id;
    }

    if (isTablet) {
      return defaultTemplates.tablet.id;
    }

    return gridLayoutSettings?.selectedTemplateId ?? defaultTemplates.basic.id;
  });

  const [templates, setTemplates] = useState(() => {
    const gridLayoutSettings = getGridLayoutSettings();

    return gridLayoutSettings?.templates ?? [];
  });

  const isLayoutHasChanged = useMemo(() => {
    if (isLockedLayout || isUpdatingLayout) return false;

    const allTemplates = [...Object.values(defaultTemplates), ...templates];

    const baseTemplate = allTemplates.find(
      (template) => template.id === selectedTemplateId,
    );
    const updatedLayout = clearExtendedLayout(mappedLayout);

    const baseGrid = getLayoutToCompare(baseTemplate?.data);
    const updatedGrid = getLayoutToCompare(updatedLayout);

    return !reactFastCompare(baseGrid, updatedGrid);
  }, [mappedLayout, selectedTemplateId, templates, isLockedLayout]);

  const updateSelectedTemplateId = (id) => {
    const gridLayoutSettings = getGridLayoutSettings();

    localStorage.setItem(
      LOCAL_STORAGE_LAYOUT_SETTINGS,
      JSON.stringify({
        ...gridLayoutSettings,
        selectedTemplateId: id,
      }),
    );

    setSelectedTemplateId(id);
  };

  const lockGridLayout = (status = !isLockedLayout) => {
    setIsLockLayout(status);
  };

  const setIsUpdatingGridLayout = (status = !isUpdatingLayout) => {
    setIsUpdatingLayout(status);
  };

  const updateTemplates = (templates) => {
    const gridLayoutSettings = getGridLayoutSettings();

    localStorage.setItem(
      LOCAL_STORAGE_LAYOUT_SETTINGS,
      JSON.stringify({
        ...gridLayoutSettings,
        templates,
      }),
    );

    setTemplates(templates);
  };

  return (
    <Provider
      value={{
        mappedLayout,
        templates,
        selectedTemplateId,
        isLockedLayout,
        isLayoutHasChanged,
        isUpdatingLayout,
        setMappedLayout,
        lockGridLayout,
        setIsUpdatingGridLayout,
        updateSelectedTemplateId,
        updateTemplates,
        getGridLayoutSettings,
      }}
    >
      {children}
    </Provider>
  );
};

export { GridLayoutContext };

export default GridLayoutProvider;
