const initOneTrustScript = () => {
  const otScript = document.createElement('script');

  otScript.setAttribute('charset', 'UTF-8');
  otScript.setAttribute(
    'src',
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  );
  otScript.setAttribute(
    'data-domain-script',
    `f0cf9ae0-2b6c-4612-ac45-7970560732be${
      process.env.REACT_APP_STAGE === 'production' ? '' : '-test'
    }`,
  );

  document.head.appendChild(otScript);
};

export default initOneTrustScript;
