import React, { memo } from 'react';

import { ProgressBar } from '@shared/ui';

import { format } from '@utils/numbers';

import styles from './ProgressItem.module.scss';

const ProgressItem = ({ progressPercent }) => {
  const formattedPercent = format(progressPercent, {
    precision: 0,
  });

  return (
    <ProgressBar
      isPill
      percentages={formattedPercent}
      height={4}
      customStyles={styles}
    />
  );
};

export default memo(ProgressItem);
