import { memo, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import cx from 'classnames';

import { ButtonArrow } from '@shared/ui';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './ProductSwiperSlide.module.scss';

const ProductSwiperSlide = ({
  icon,
  title,
  description,
  link,
  backgroundImageName,
  isNew,
  customStyles = {},
}) => {
  const navigate = useNavigate();

  const [imageSrc, setImageSrc] = useState();

  const { isMobile } = useBreakpoints();

  useEffect(() => {
    const fetchImageSrc = async () => {
      const response = await import(`@images/products/${backgroundImageName}`);

      setImageSrc(response.default);
    };

    fetchImageSrc().then();
  }, [backgroundImageName]);

  const isSlideClickable = useMemo(() => isMobile, [isMobile]);

  const handleSlideClick = () => {
    if (!isSlideClickable || !link) return;

    navigate(link);
  };

  return (
    <div
      className={cx(
        styles.product_swiper_slide,
        customStyles.product_swiper_slide,
        {
          [styles.product_swiper_slide__clickable]: isSlideClickable,
        },
      )}
      style={{
        backgroundImage: `url("${imageSrc}")`,
      }}
      onClick={handleSlideClick}
    >
      <div className={cx(styles.wrapper, customStyles.wrapper)}>
        <div>{icon}</div>

        <div className={cx(styles.content, customStyles.content)}>
          <div className={cx(styles.title, customStyles.title)}>
            {title}
            {isNew && (
              <sup className={styles.new}>
                <FormattedMessage id="New" />
              </sup>
            )}
          </div>
          <div className={cx(styles.description, customStyles.description)}>
            {description}
          </div>

          {!isSlideClickable && link && (
            <ButtonArrow
              navigation={{ to: link }}
              color="primary"
              variant="outlined"
              inversion
              size="l"
              customStyles={{ button: [styles.button_link] }}
            >
              <FormattedMessage id="ReadMore" />
            </ButtonArrow>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ProductSwiperSlide);
