import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, generatePath } from 'react-router-dom';

import { useMatchedRoutes } from '@shared/hooks';

import { KYC_LEVELS } from '@pages/AccountPage/constants';

import {
  isUserHasActiveSettingSelector,
  useGetProfileInfoQuery,
} from '@store/api/profileAPI';
import { isAuthorizedUserSelector } from '@store/settings/settingsSelectors';
import { settingsActions } from '@store/settings/settingsSlice';

import { useLazyGetProfileInfoQueryResult } from '@api/hooks';

import Modals from '@hooks/useModal/Modals';
import ModalsProvider from '@hooks/useModal/ModalsProvider';
import SidebarsProvider from '@hooks/useSidebar/SidebarsProvider';
import useTheme from '@hooks/useTheme';

import {
  ACCOUNT_ROUTES,
  AUTH_ROUTES,
  MAIN_ROUTES,
  ACCOUNTS_THEME_ROUTES,
} from 'src/routes.constants';

const Themify = ({ children }) => {
  const { terminalTheme, accountTheme } = useTheme();

  const dispatch = useDispatch();

  const { isMatchedRoute: isAccountTheme } = useMatchedRoutes({
    routes: ACCOUNTS_THEME_ROUTES,
    isNested: true,
  });

  useLayoutEffect(() => {
    dispatch(settingsActions.setIsAccountTheme(isAccountTheme));
  }, [isAccountTheme]);

  useLayoutEffect(() => {
    document.body.classList.forEach((classItem) => {
      if (classItem.includes('theme-')) {
        document.body.classList.remove(classItem);
      }
    });

    document.body.classList.add(
      `theme-${isAccountTheme ? accountTheme : terminalTheme}`,
    );
  }, [accountTheme, terminalTheme, isAccountTheme]);

  return children;
};

const Verifications = ({
  children,
  auth = false,
  notAuth = false,
  requireKYC = false,
}) => {
  const [
    getProfileInfo,
    {
      userKycLevel,
      isSuccess: isProfileInfoLoaded,
      isUninitialized: isProfileInfoUninitialized,
    },
  ] = useLazyGetProfileInfoQueryResult();

  const isAuthorizedUser = useSelector(isAuthorizedUserSelector);

  if (auth && !isAuthorizedUser) {
    return <Navigate to={AUTH_ROUTES.LOGIN} replace />;
  }

  if (notAuth && isAuthorizedUser) {
    return <Navigate to={generatePath(MAIN_ROUTES.TERMINAL)} replace />;
  }

  if (requireKYC && isAuthorizedUser) {
    if (isProfileInfoUninitialized) {
      getProfileInfo();
    }

    if (!isProfileInfoLoaded) return null;

    if (userKycLevel === KYC_LEVELS.ZERO) {
      return (
        <Navigate
          to={ACCOUNT_ROUTES.KYC}
          state={{ isShowWarn: true }}
          replace
        />
      );
    }
  }

  return children;
};

export const SecuritySettingRoute = ({
  securitySettingKey,
  disableSettingElement: DisableSettingElement = null,
  enableSettingElement: EnableSettingElement = null,
}) => {
  const { isSuccess: isProfileInfoLoaded, isUserHasActiveSetting } =
    useGetProfileInfoQuery(undefined, {
      selectFromResult: (result) => ({
        ...result,
        isUserHasActiveSetting: isUserHasActiveSettingSelector(
          result,
          securitySettingKey,
        ),
      }),
    });

  if (!isProfileInfoLoaded) return null;

  return isUserHasActiveSetting ? (
    <DisableSettingElement />
  ) : (
    <EnableSettingElement />
  );
};

export const RootRoute = () => {
  const isAuthorizedUser = useSelector(isAuthorizedUserSelector);

  return (
    <Navigate
      to={
        isAuthorizedUser ? generatePath(MAIN_ROUTES.TERMINAL) : MAIN_ROUTES.HOME
      }
    />
  );
};

export const RouteContainer = ({
  notAuth = false,
  auth = false,
  requireKYC = false,
  element: Element = null,
}) => {
  return (
    <Verifications auth={auth} notAuth={notAuth} requireKYC={requireKYC}>
      <Themify>
        <ModalsProvider>
          <SidebarsProvider>
            <Element />

            <Modals />
          </SidebarsProvider>
        </ModalsProvider>
      </Themify>
    </Verifications>
  );
};
