import { useSelector } from 'react-redux';

import { useBotsPnl, useArchivedBots } from '@store/api/hooks';

import { ArchivedBotsSelectors } from '../ArchivedBotsTab/archivedBots.selectors';

import useBotsTableData from './useBotsTableData';

const useArchivedBotsTableData = () => {
  const { archivedBots, isSuccessArchivedBots } = useArchivedBots(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { isBotsPnlSuccess } = useBotsPnl();
  const filters = useSelector(ArchivedBotsSelectors.filtersSelector);
  const pagination = useSelector(ArchivedBotsSelectors.paginationSelector);

  const { paginatedBots, filteredBotsLength } = useBotsTableData({
    filters,
    pagination,
    bots: archivedBots,
  });

  return {
    archivedBots: paginatedBots,
    archivedBotsCount: filteredBotsLength,
    allArchivedBotsLength: archivedBots.length,
    isArchivedBotsSuccess: isSuccessArchivedBots && isBotsPnlSuccess,
  };
};

export default useArchivedBotsTableData;
