import React, { memo } from 'react';

import CoinPercentDynamics from '@components/CoinPercentDynamics';

const HighlightItem = ({ item }) => {
  return (
    <div className="markets-item">
      <span className="pair-name">{item.marketData.name}</span>

      <div className="flex-4 d-flex align-items-center">
        <CoinPercentDynamics percent={item?.ch} />
      </div>
    </div>
  );
};

export default memo(HighlightItem);
