import { createSlice } from '@reduxjs/toolkit';

import { PAYMENT_METHOD } from '@shared/constants';

const initialState = {
  paymentMethod: PAYMENT_METHOD.BANK_TRANSFER,
  bankTransferType: null,
  selectedCoinType: '',
  withdrawCurrencySymbol: '',
  coins: [],
  userData: {
    firstName: '',
    lastName: '',
  },
  checkoutWithdrawData: {
    email: '',
    amount: '',
    fee: '',
    profit: '',
  },
  bankTransferDetails: [],
};

const withdrawSlice = createSlice({
  name: 'walletWithdraw',
  initialState,
  reducers: {
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setSelectedCoinType: (state, action) => {
      state.selectedCoinType = action.payload;
    },
    setCheckoutWithdrawData: (state, action) => {
      state.checkoutWithdrawData = {
        ...state.checkoutWithdrawData,
        ...action.payload,
      };
    },
    setBankTransferType: (state, action) => {
      state.bankTransferType = action.payload;
    },
    setWithdrawCurrencySymbol: (state, action) => {
      const selectedCoinType = state.coins.find(
        (coin) => coin.symbol === action.payload,
      )?.type;

      state.selectedCoinType = selectedCoinType;

      state.withdrawCurrencySymbol = action.payload;
    },
    setBankTransferDetails: (state, action) => {
      state.bankTransferDetails = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setWithdrawCoins: (state, action) => {
      state.coins = action.payload;
    },
  },
});

const walletWithdrawActions = withdrawSlice.actions;
const walletWithdrawReducer = withdrawSlice.reducer;

export { walletWithdrawActions, walletWithdrawReducer };

export default withdrawSlice;
