import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

import { WalletsUtils } from '@pages/WalletsPage/walletsPage.utils';

import {
  useAccountBalances,
  useCoinsInfo,
  useDefaultAccount,
} from '@store/api/hooks';
import { useBonusAccountSettings } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { AvailableContractsSelectors } from '../selectors';

import useSelectedGlobalPeriod from './useSelectedGlobalPeriod';

const useAvailableContractsTable = ({ isHideZeroBalances = false }) => {
  const selectedPeriods = useSelector(
    AvailableContractsSelectors.selectedPeriodsSelector,
  );
  const searchValue = useSelector(AvailableContractsSelectors.searchSelector);

  const { defaultSpotAccount } = useDefaultAccount();

  const { isTabletDown } = useBreakpoints();

  const { coinsInfo } = useCoinsInfo();

  const { accountBalancesMap } = useAccountBalances({
    accountId: defaultSpotAccount?.id,
  });

  const { selectedGlobalPeriod } = useSelectedGlobalPeriod();

  const { coins, limits, periods, isBonusSettingsSuccess } =
    useBonusAccountSettings();

  const availableCoinsForContracts = useMemo(
    () =>
      Object.entries(coins)
        .filter(([coin, isActive]) => isActive)
        .map((coin) => coin[0]),
    [coins],
  );

  const data = useMemo(() => {
    return {
      availableContractsTableData: accountBalancesMap
        .map((balance) => {
          const coinSymbol = balance.symbol;
          const coinInfo = coinsInfo?.[coinSymbol];
          const period =
            periods?.find(
              ({ period }) => period === selectedPeriods[coinSymbol]?.period,
            ) || periods[0];

          const selectedPeriod = isTabletDown ? selectedGlobalPeriod : period;
          const precision = coinInfo?.digits;

          return {
            asset: {
              symbol: coinSymbol,
              fullName: coinInfo?.name,
            },
            period: {
              active: selectedPeriod?.period,
              periods,
              coinSymbol,
            },
            bonus: selectedPeriod?.percent * 100,
            minDeposit: {
              minDeposit: limits[coinSymbol]?.min,
              coinSymbol,
            },
            availableBalance: {
              availableBalance: balance.available,
              precision,
            },
            totalAmountUntilExpiration: {
              totalAmountUntilExpiration:
                Number(balance.available) +
                Number(balance.available) * selectedPeriod?.percent,
              coinSymbol,
            },
            action: {
              coinSymbol,
              selectedPeriod,
            },
          };
        })
        .filter(({ asset, availableBalance }) => {
          const hidingZeroBalances = isHideZeroBalances
            ? availableBalance?.availableBalance > 0
            : true;

          return (
            availableCoinsForContracts.includes(asset.symbol) &&
            hidingZeroBalances
          );
        })
        .filter((contract) => {
          const coinSymbol = contract.asset.symbol;
          const fullName = contract.asset.fullName;

          if (searchValue === '') {
            return coinSymbol;
          }

          const isItemWasFound = WalletsUtils.isSomeContractExists(
            [coinSymbol, fullName],
            { [TABLE_FILTERS_NAMES.SEARCHBAR]: searchValue },
          );

          return isItemWasFound;
        }),
      isAvailableContractsTableDataSuccess: isBonusSettingsSuccess,
    };
  }, [
    accountBalancesMap,
    coinsInfo,
    isTabletDown,
    selectedGlobalPeriod,
    selectedPeriods,
    periods,
    limits,
    isHideZeroBalances,
    availableCoinsForContracts,
    isBonusSettingsSuccess,
    searchValue,
  ]);

  return data;
};

export default useAvailableContractsTable;
