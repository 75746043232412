import { TFC } from 'chartiq/plugins/tfc/tfc-loader';

import 'chartiq/plugins/tfc/tfc.js';

import { format } from '@utils/numbers';

import { DEFAULT_PRECISION } from '@constants';

import tfcAccount from './tfcAccount';
import { tfcOverrides } from './tfcOverrides';

export const getChartConfig = ({ resources }) => {
  const config = {
    quoteFeeds: [
      {
        quoteFeed: resources.quoteFeed,
        behavior: { refreshInterval: 1, bufferSize: 200 },
      },
    ],
    enabledAddOns: {
      dataLoader: true,
      extendedHours: true,
      fullScreen: false,
      inactivityTimer: true,
      rangeSlider: true,
      shortcuts: true,
      tableView: false,
      tooltip: false,
    },
    plugins: {
      tfc: {
        moduleName: 'TFC',
        allowUniqueAccountConstruction: true,
        account: tfcAccount(),
      },
    },
    menuPeriodicityFavorite: [
      {
        type: 'item',
        label: '15m',
        cmd: "Layout.setPeriodicity(1,15,'minute')",
        value: { period: 1, interval: 15, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '1H',
        cmd: "Layout.setPeriodicity(1,60,'minute')",
        value: { period: 1, interval: 60, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '4H',
        cmd: "Layout.setPeriodicity(1,240,'minute')",
        value: { period: 1, interval: 240, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '1 D',
        cmd: "Layout.setPeriodicity(1,1,'day')",
        value: { period: 1, interval: 1, timeUnit: 'day' },
      },
      {
        type: 'item',
        label: '1 W',
        cmd: "Layout.setPeriodicity(1,1,'week')",
        value: { period: 1, interval: 1, timeUnit: 'week' },
      },
    ],
    menuPeriodicity: [
      {
        type: 'item',
        label: '1min',
        cmd: "Layout.setPeriodicity(1,1,'minute')",
        active: true,
        value: { period: 1, interval: 1, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '5min',
        cmd: "Layout.setPeriodicity(1,5,'minute')",
        value: { period: 1, interval: 5, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '10min',
        cmd: "Layout.setPeriodicity(1,10,'minute')",
        value: { period: 1, interval: 10, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '15min',
        cmd: "Layout.setPeriodicity(1,15,'minute')",
        value: { period: 1, interval: 15, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '30min',
        cmd: "Layout.setPeriodicity(1,30,'minute')",
        value: { period: 1, interval: 30, timeUnit: 'minute' },
      },
      { type: 'separator' },
      {
        type: 'item',
        label: '1H',
        cmd: "Layout.setPeriodicity(1,60,'minute')",
        value: { period: 1, interval: 60, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '2H',
        cmd: "Layout.setPeriodicity(1,120,'minute')",
        value: { period: 1, interval: 120, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '4H',
        cmd: "Layout.setPeriodicity(1,240,'minute')",
        value: { period: 1, interval: 240, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '6H',
        cmd: "Layout.setPeriodicity(1,360,'minute')",
        value: { period: 1, interval: 360, timeUnit: 'minute' },
      },
      { type: 'separator' },
      {
        type: 'item',
        label: '1D',
        cmd: "Layout.setPeriodicity(1,1,'day')",
        value: { period: 1, interval: 1, timeUnit: 'day' },
      },
      {
        type: 'item',
        label: '1W',
        cmd: "Layout.setPeriodicity(1,1,'week')",
        value: { period: 1, interval: 1, timeUnit: 'week' },
      },
      {
        type: 'item',
        label: '1M',
        cmd: "Layout.setPeriodicity(1,1,'month')",
        value: { period: 1, interval: 1, timeUnit: 'month' },
      },
    ],
  };

  return config;
};

export const applyMarketFormatting = (stx, marketInfo) => {
  stx.chart.yAxis.decimalPlaces =
    marketInfo?.quote_precision_format ?? DEFAULT_PRECISION;
  stx.chart.yAxis.priceFormatter = function (stx, panel, price, decimalPlaces) {
    return format(price, { precision: marketInfo?.quote_precision_format });
  };
};

export const applyCustomConfiguration = (stx) => {
  stx.chart.yAxis.yaxisLabelStyle = 'rect';
  stx.chart.yAxis.displayGridLines = false;
  stx.chart.xAxis.displayGridLines = false;
  stx.controls.chartControls.style.display = 'none';
  stx.controls.chartControls = null;
  stx.controls.mSticky = null;

  stx.setPeriodicity({ interval: 4, timeUnit: 'hour' });

  tfcOverrides(TFC, { stx });
};
