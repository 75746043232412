import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetStreamTradesQueryResult } from '@store/api/hooks';
import { marketIdSelector } from '@store/settings/settingsSelectors';

const useLastTradeInfo = () => {
  const marketId = useSelector(marketIdSelector);

  const { trades } = useGetStreamTradesQueryResult(marketId);

  const data = useMemo(() => {
    let className = '';
    let value = 0;

    if (trades.length) {
      const lastTrade = trades[0];
      const isSamePriceValue =
        trades.length > 1 &&
        lastTrade.price === trades[1].price &&
        lastTrade.taker_side === trades[1].taker_side;

      className = isSamePriceValue ? '' : lastTrade.taker_side;
      value = lastTrade.price;
    }

    return {
      className,
      value,
    };
  }, [trades]);

  return data;
};

export default useLastTradeInfo;
