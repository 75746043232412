import React, { memo } from 'react';

import { format } from '@utils/numbers';

import styles from './GrossBonusItem.module.scss';

const GrossBonusItem = ({ currentBonus, precision }) => {
  const value = format(currentBonus, {
    precision,
  });

  return (
    <span className={value > 0 ? styles.black : styles.grey}>{value}</span>
  );
};

export default memo(GrossBonusItem);
