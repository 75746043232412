import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { endOfDay, getTime, lastDayOfMonth, startOfDay } from 'date-fns';
import { toMomentObject } from 'react-dates';
import { useForm } from 'react-hook-form';

import { AccountModal } from '@shared/components';
import { DATE_FORMAT_WITH_SLASH } from '@shared/components/DatePicker/datePicker.constants';
import {
  getMonthsMapped,
  getYearsMapped,
} from '@shared/components/DatePicker/datePicker.utils';
import { dateShouldBeLessOrHigher } from '@shared/components/DatePicker/datePicker.validation';
import { Button, SelectController } from '@shared/ui';

import useModal from '@hooks/useModal';

import { modalsIds } from '@constants';

import s from './CustomRangeModal.module.scss';

const RANGE_FIELDS = {
  FROM_YEAR: 'from_year',
  FROM_MONTH: 'from_month',
  TO_YEAR: 'to_year',
  TO_MONTH: 'to_month',
};

const CustomRangeModal = () => {
  const { modal, isOpen, closeModal } = useModal(
    modalsIds.DATE_PICKER_CUSTOM_RANGE,
  );
  const { openModal: openSuccessModal } = useModal(
    modalsIds.SUCCESS_SELECT_CUSTOM_RANGE,
  );

  const { handleSelectCustomRange } = modal.params;

  const intl = useIntl();

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      [RANGE_FIELDS.FROM_YEAR]: '',
      [RANGE_FIELDS.FROM_MONTH]: '',
      [RANGE_FIELDS.TO_YEAR]: '',
      [RANGE_FIELDS.TO_MONTH]: '',
    },
  });

  const yearOptions = useMemo(() => getYearsMapped(), []);

  const monthOptions = useMemo(() => getMonthsMapped({ intl }), [intl]);

  const setDefaultValues = useCallback(() => {
    methods.setValue(RANGE_FIELDS.FROM_MONTH, monthOptions[0]);
    methods.setValue(RANGE_FIELDS.FROM_YEAR, yearOptions.slice(-1)[0]);
    methods.setValue(RANGE_FIELDS.TO_YEAR, yearOptions[0]);
    methods.setValue(RANGE_FIELDS.TO_MONTH, monthOptions.slice(-1)[0]);
  }, [yearOptions, monthOptions]);

  useEffect(() => {
    setDefaultValues();
  }, [setDefaultValues]);

  const handleRequestRange = (values) => {
    const { from_year, from_month, to_year, to_month } = values;

    const startDate = startOfDay(
      new Date(from_year.value, from_month.order, 1),
    );
    const endDate = endOfDay(
      lastDayOfMonth(new Date(to_year.value, to_month.order)),
    );

    const startDateTime = getTime(startDate);
    const endDateTime = getTime(endDate);

    const startDateFormatted = toMomentObject(startDate).format(
      DATE_FORMAT_WITH_SLASH,
    );
    const endDateFormatted = toMomentObject(endDate).format(
      DATE_FORMAT_WITH_SLASH,
    );

    handleSelectCustomRange({
      startDate: startDateTime,
      endDate: endDateTime,
      isCustomRange: true,
    });

    closeModal();
    openSuccessModal({
      fromDate: startDateFormatted,
      toDate: endDateFormatted,
    });
  };

  return (
    <AccountModal
      title={<FormattedMessage id="CustomRange" />}
      onClose={closeModal}
      isOpen={isOpen}
    >
      <div>
        <div className="fw-500">
          <FormattedMessage id="CustomRangeDescription" />
        </div>
        <form className={s.form} onSubmit={handleSubmit(handleRequestRange)}>
          <div className="d-flex justify-content-between align-items-md-start align-items-center flex-md-row flex-column gap-4">
            <div>
              <div className="d-flex align-items-start gap-4">
                <SelectController
                  options={yearOptions}
                  isSearchable={false}
                  size="m"
                  underlined
                  name={RANGE_FIELDS.FROM_YEAR}
                  styles={{ control: { minWidth: '60px' } }}
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                    validate: {
                      date: dateShouldBeLessOrHigher(
                        intl.formatMessage({ id: 'DateShouldBeLess' }),
                        methods.getValues,
                      ),
                    },
                  }}
                  isErrorDisplayed={false}
                  {...methods}
                />
                <SelectController
                  options={monthOptions}
                  isSearchable={false}
                  underlined
                  size="m"
                  name={RANGE_FIELDS.FROM_MONTH}
                  styles={{ control: { minWidth: '100px' } }}
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                    validate: {
                      date: dateShouldBeLessOrHigher(
                        intl.formatMessage({ id: 'DateShouldBeLess' }),
                        methods.getValues,
                      ),
                    },
                  }}
                  isErrorDisplayed={false}
                  {...methods}
                />
              </div>

              {methods.formState.errors[RANGE_FIELDS.FROM_YEAR] && (
                <div className="color-red mt-2">
                  {methods.formState.errors[RANGE_FIELDS.FROM_YEAR].message}
                </div>
              )}
            </div>
            <div>
              <div className="d-flex align-items-strat gap-4">
                <SelectController
                  options={yearOptions}
                  isSearchable={false}
                  size="m"
                  underlined
                  name={RANGE_FIELDS.TO_YEAR}
                  styles={{ control: { minWidth: '60px' } }}
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                    validate: {
                      date: dateShouldBeLessOrHigher(
                        intl.formatMessage({ id: 'DateShouldBeHigher' }),
                        methods.getValues,
                      ),
                    },
                  }}
                  isErrorDisplayed={false}
                  {...methods}
                />
                <SelectController
                  options={monthOptions}
                  isSearchable={false}
                  underlined
                  size="m"
                  name={RANGE_FIELDS.TO_MONTH}
                  styles={{ control: { minWidth: '100px' } }}
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                    validate: {
                      date: dateShouldBeLessOrHigher(
                        intl.formatMessage({ id: 'DateShouldBeHigher' }),
                        methods.getValues,
                      ),
                    },
                  }}
                  isErrorDisplayed={false}
                  {...methods}
                />
              </div>
              {methods.formState.errors[RANGE_FIELDS.TO_YEAR] && (
                <div className="color-red mt-2">
                  {methods.formState.errors[RANGE_FIELDS.TO_YEAR].message}
                </div>
              )}
            </div>
          </div>
          <div className="mt-40">
            <Button customStyles={s} size="l" color="tertiary">
              <FormattedMessage id="RequestHistory" />
            </Button>
          </div>
        </form>
      </div>
    </AccountModal>
  );
};

export default CustomRangeModal;
