import { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { PAGINATION_ALIGN } from '@shared/components/Table/table.constants';

import useBreakpoints from '@hooks/useBreakpoints';
import useWindowResize from '@hooks/useWindowResize';

import { NOOP } from '@constants';

import s from './PaginationBlock.module.scss';

/**
 * meta = { limit: 10, count: 56 }
 * */
const PaginationBlock = ({
  meta,
  activePageIndex,
  onSetPageIndex,
  inversion,
  isPageCounterDisplayed = true,
  paginationAlign = PAGINATION_ALIGN.CENTER,
  isLoading,
  onPreviousPage = NOOP,
  onNextPage = NOOP,
}) => {
  const pagesCounterRef = useRef();
  const pageCount = Math.ceil(meta.count / meta.limit);

  const { isMobile } = useBreakpoints();

  const { size: pagesCounterSize } = useWindowResize(pagesCounterRef);

  const isPreviousArrowsDisplayed = useMemo(
    () => activePageIndex - 2 >= 0 && pageCount > 3,
    [activePageIndex, pageCount],
  );

  const isStartEllipsisDisplayed = useMemo(
    () => activePageIndex - 2 >= 0 && pageCount > 3,
    [activePageIndex, pageCount],
  );

  const isPrevPreviousPageDisplayed = useMemo(
    () => activePageIndex + 1 === pageCount && pageCount > 2,
    [activePageIndex, pageCount],
  );

  const isPreviousPageDisplayed = useMemo(
    () => activePageIndex - 1 >= 0,
    [activePageIndex],
  );

  const isNextPageDisplayed = useMemo(
    () => activePageIndex + 2 < pageCount,
    [activePageIndex, pageCount],
  );

  const isLastPageDisplayed = useMemo(
    () => activePageIndex + 1 !== pageCount && pageCount > 0,
    [activePageIndex, pageCount],
  );

  const isAfterNextPageDisplayed = useMemo(
    () =>
      activePageIndex === 0 &&
      activePageIndex + 3 !== pageCount &&
      pageCount > 2,
    [activePageIndex, pageCount],
  );

  const isNextEllipsesDisplayed = useMemo(
    () => activePageIndex + 3 < pageCount && pageCount > 4,
    [activePageIndex, pageCount],
  );

  const isNextArrowsDisplayed = useMemo(
    () => activePageIndex + 2 < pageCount && pageCount > 4,
    [activePageIndex, pageCount],
  );

  const itemsOnPage = useMemo(() => {
    const itemsQuantity = (activePageIndex + 1) * meta.limit;
    return meta.count < itemsQuantity ? meta.count : itemsQuantity;
  }, [activePageIndex, meta.limit, meta.count]);

  const handleClickOnStartEllipsis = () => {
    const isLastAndMoreThenThirdPage =
      activePageIndex + 1 === pageCount && pageCount > 3;
    const index = isLastAndMoreThenThirdPage
      ? activePageIndex - 3
      : activePageIndex - 2;

    onSetPageIndex(index);
  };

  const handleClickOnNextEllipsis = () => {
    const isFirstPage = activePageIndex === 0;
    const index = isFirstPage ? activePageIndex + 3 : activePageIndex + 2;

    onSetPageIndex(index);
  };

  const handleSetPageIndex = (index) => {
    onSetPageIndex(index);
  };

  const handleGoToPreviousPage = () => {
    if (onPreviousPage !== NOOP) {
      onPreviousPage();
    } else {
      onSetPageIndex(activePageIndex - 1);
    }
  };

  const handleGoToNextPage = () => {
    if (onNextPage !== NOOP) {
      onNextPage();
    } else {
      onSetPageIndex(activePageIndex + 1);
    }
  };

  const controlsStyle = useMemo(() => {
    let marginLeft = 'auto';
    if (paginationAlign === PAGINATION_ALIGN.CENTER) {
      marginLeft = isPageCounterDisplayed
        ? `calc(50% - ${pagesCounterSize?.width}px)`
        : '50%';
    }
    if (paginationAlign === PAGINATION_ALIGN.LEFT) marginLeft = '0';

    return { marginLeft };
  }, [paginationAlign, isPageCounterDisplayed, pagesCounterSize?.width]);

  return (
    <div className={s.pagination}>
      <div
        ref={pagesCounterRef}
        className={cx(s.pages_counter, { [s.inversion]: inversion })}
      >
        {isPageCounterDisplayed && (
          <>
            {itemsOnPage} <FormattedMessage id="From" /> {meta.count}{' '}
            {!isMobile && <FormattedMessage id="Records" />}
          </>
        )}
      </div>
      <div
        className={cx(s.controls, s[paginationAlign], {
          [s.inversion]: inversion,
        })}
        style={controlsStyle}
      >
        {isPreviousArrowsDisplayed && (
          <>
            <button
              disabled={isLoading}
              className={s.button}
              onClick={() => handleSetPageIndex(0)}
            >
              {'<<'}
            </button>
            <button
              disabled={isLoading}
              className={s.button}
              onClick={handleGoToPreviousPage}
            >
              {'<'}
            </button>
          </>
        )}

        {isStartEllipsisDisplayed && (
          <button
            disabled={isLoading}
            className={s.button}
            onClick={handleClickOnStartEllipsis}
          >
            {'...'}
          </button>
        )}

        {isPrevPreviousPageDisplayed && (
          <button
            disabled={isLoading}
            className={s.button}
            onClick={() => handleSetPageIndex(activePageIndex - 2)}
          >
            {activePageIndex - 1}
          </button>
        )}

        {isPreviousPageDisplayed && (
          <button
            disabled={isLoading}
            className={s.button}
            onClick={() => handleSetPageIndex(activePageIndex - 1)}
          >
            {activePageIndex}
          </button>
        )}

        <button disabled={isLoading} className={cx(s.active_page, s.button)}>
          {activePageIndex + 1}
        </button>

        {isNextPageDisplayed && (
          <button
            disabled={isLoading}
            className={s.button}
            onClick={() => handleSetPageIndex(activePageIndex + 1)}
          >
            {activePageIndex + 2}
          </button>
        )}

        {isAfterNextPageDisplayed && (
          <button
            disabled={isLoading}
            className={s.button}
            onClick={() => handleSetPageIndex(activePageIndex + 2)}
          >
            {activePageIndex + 3}
          </button>
        )}

        {isNextEllipsesDisplayed && (
          <button
            disabled={isLoading}
            className={s.button}
            onClick={handleClickOnNextEllipsis}
          >
            {'...'}
          </button>
        )}

        {isLastPageDisplayed && (
          <button
            disabled={isLoading}
            className={s.button}
            onClick={() => handleSetPageIndex(pageCount - 1)}
          >
            {pageCount}
          </button>
        )}

        {isNextArrowsDisplayed && (
          <>
            <button
              disabled={isLoading}
              className={s.button}
              onClick={handleGoToNextPage}
            >
              {'>'}
            </button>
            <button
              disabled={isLoading}
              className={s.button}
              onClick={() => handleSetPageIndex(pageCount - 1)}
            >
              {'>>'}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PaginationBlock;
