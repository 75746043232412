import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';
import dateFormat from 'date-fns/format';

import { DefaultAvatar } from '@shared/components';
import { DAY_MONTH_YEAR_TIME_FORMAT } from '@shared/constants';

import { HISTORY_COLUMN_NAMES } from './referralTable.constant';

const columnHelper = createColumnHelper();

const referralHistoryColumns = ({
  hideColumnNames,
  isSmallAvatars = false,
}) => {
  const avatarSize = isSmallAvatars ? 28 : 36;

  const allColumns = [
    columnHelper.accessor(HISTORY_COLUMN_NAMES.DATE_OF_INVITE_LEVEL_A, {
      header: () => <FormattedMessage id="DateOfInviteLevelA" />,
      cell: (info) => (
        <span className="fs-12">
          {dateFormat(new Date(info.getValue()), DAY_MONTH_YEAR_TIME_FORMAT)}
        </span>
      ),

      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
    columnHelper.accessor(HISTORY_COLUMN_NAMES.LEVEL_A_INVITED, {
      header: () => (
        <FormattedMessage id="ReferralLevelInvited" values={{ level: 'A' }} />
      ),
      cell: (info) => {
        const { avatarId, value } = info.getValue();

        return (
          <span className="fw-600 fs-14 d-flex gap-14 align-items-center">
            <DefaultAvatar
              avatarId={avatarId}
              height={avatarSize}
              width={avatarSize}
            />{' '}
            {value}
          </span>
        );
      },
      enableSorting: false,
      headerAlign: 'start',
      cellAlign: 'start',
    }),
    columnHelper.accessor(HISTORY_COLUMN_NAMES.LEVEL_B_INVITED, {
      header: () => (
        <FormattedMessage id="ReferralLevelInvited" values={{ level: 'B' }} />
      ),
      cell: (info) => <span className="fs-14">{info.getValue() ?? '-'}</span>,
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
    columnHelper.accessor(HISTORY_COLUMN_NAMES.LEVEL_C_INVITED, {
      header: () => (
        <FormattedMessage id="ReferralLevelInvited" values={{ level: 'C' }} />
      ),
      cell: (info) => <span className="fs-14">{info.getValue() ?? '-'}</span>,
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
    columnHelper.accessor(HISTORY_COLUMN_NAMES.TOTAL_INVITED, {
      header: () => <FormattedMessage id="Total" />,
      cell: (info) => (
        <span className="fs-14 fw-600">{info.getValue() ?? '-'}</span>
      ),
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
  ];

  return allColumns
    .filter((col) => !hideColumnNames.includes(col.accessorKey))
    .map((column, index) => {
      if (index === 0) {
        return {
          ...column,
          headerAlign: 'start',
          cellAlign: 'start',
        };
      }
      return column;
    });
};

export default referralHistoryColumns;
