import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccountModal } from '@shared/components';
import { Button } from '@shared/ui';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import { ReactComponent as InfoIconSVG } from '@icons/info-icon.svg';

import styles from './WithdrawAdvcashFailedModal.module.scss';

const WithdrawAdvcashFailedModal = () => {
  const { isOpen, closeModal } = useModal(
    modalsIds.WALLETS_WITHDRAW_ADVCASH_FAILED,
  );

  return (
    <AccountModal
      isOpen={isOpen}
      title={<FormattedMessage id="WithdrawalFailed" />}
      footer={
        <Button
          type="button"
          variant="outlined"
          size="l"
          fill
          onClick={closeModal}
        >
          <FormattedMessage id="Close" />
        </Button>
      }
      onClose={closeModal}
    >
      <div className={styles.info_block}>
        <InfoIconSVG className={styles.info_block__icon} />

        <p className={styles.info_block__title}>
          <FormattedMessage id="WithdrawalFailedModalDescription" />
        </p>
      </div>
    </AccountModal>
  );
};

export default memo(WithdrawAdvcashFailedModal);
