import { FormattedMessage } from 'react-intl';

import { Button } from '@shared/ui';

import {
  SettingDescription,
  SettingTitle,
} from '@pages/WalletsPage/VisaCardSettings/components';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal';

import { modalsIds } from '@constants';

import s from './CloseCard.module.scss';

const CloseCard = () => {
  const { isMobile } = useBreakpoints();
  const { openModal } = useModal(modalsIds.CLOSE_CARD);

  const handleOpenModal = () => {
    openModal();
  };
  return (
    <div>
      <SettingTitle>
        <FormattedMessage id="ClosingTheCard" />
      </SettingTitle>
      <div className={s.content}>
        <SettingDescription>
          <FormattedMessage id="ClosingTheCardDescription" />
        </SettingDescription>
        <Button
          fill={isMobile}
          customStyles={s}
          color="dangerous"
          onClick={handleOpenModal}
        >
          <FormattedMessage id="Apply" />
        </Button>
      </div>
    </div>
  );
};

export default CloseCard;
