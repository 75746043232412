import React, { memo } from 'react';

import { components } from 'react-select';

import styles from './CustomOption.module.scss';

const { Option } = components;

const CustomOption = ({ children, selectProps, ...props }) => {
  return (
    children && (
      <Option className={styles.option} {...props}>
        {children}
      </Option>
    )
  );
};

export default memo(CustomOption);
