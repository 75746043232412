import { useMemo } from 'react';

import { useMarketsInfo } from '@store/api/hooks';

import { format } from '@utils/numbers';

const useMarketFormat = ({
  marketId,
  value,
  type, // 'market' || 'quote',
  formatOptions = {},
}) => {
  const { marketInfo } = useMarketsInfo({ marketId });

  const precision = marketInfo?.[`${type}_precision_format`];

  const formattedValue = useMemo(
    () =>
      format(value, {
        precision,
        ...formatOptions,
      }),
    [value, precision, formatOptions],
  );

  return { formattedValue, precision };
};

export default useMarketFormat;
