import { memo, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { CIQ } from '@libs/chartiq/Chart';

import getQuoteFeed from '@components/CustomChart/chartConfig/quoteFeed';
import CustomChart from '@components/CustomChart/CustomChart';

import { BotChartTemplate } from '@pages/WalletsPage/components';

import { useMarketsInfo, useMarketsStatistics } from '@store/api/hooks';
import { localeSelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';

import { format } from '@utils/numbers';

import {
  getChartConfig,
  applyCustomConfiguration,
  applyMarketFormatting,
} from './chartConfig/config';

import styles from './BotChart.module.scss';

const defaultDrawingLineProperties = {
  col: '#23e5db',
  d0: '20220507110000000',
  pnl: 'chart',
  tzo0: -180,
  lw: 1,
  ptrn: 'solid',
  al: true,
  v0: 0,
};

const defaultDrawingTextProperties = {
  col: '#23e5db',
  d0: '20220507110000000',
  pnl: 'chart',
  tzo0: -180,
  fnt: { sz: '12px', fl: 'Helvetica, sans-serif' },
  fl: 'Helvetica, sans-serif',
  sz: '12px',
  text: '',
  ptrn: 'solid',
  v0: 0,
};

const BotChart = ({
  currentBotMarketId,
  openOrders,
  currentMarketName,
  isFullSizeChart,
  onFullSizeClick,
  isGridBotSetting,
  isTrendBotSellSideEnabled,
  buyTrendBotEntryDistancePrice,
  buyTrendBotTakeProfitDistancePrice,
  buyTrendBotSaveLossesDistancePrice,
  sellTrendBotEntryDistancePrice,
  sellTrendBotTakeProfitDistancePrice,
  sellTrendBotSaveLossesDistancePrice,
  customStyles = {},
}) => {
  const { value: locale } = useSelector(localeSelector);
  const { isDesktop } = useBreakpoints();

  const [tfc, setTFC] = useState();
  const [uiContext, setUiContext] = useState();
  const intl = useIntl();

  const { marketInfo, isMarketsInfoSuccess } = useMarketsInfo({
    marketId: currentBotMarketId,
  });

  const marketIdSymbol = currentBotMarketId?.toUpperCase();

  const { marketsStatistics } = useMarketsStatistics();

  useEffect(() => {
    if (tfc) {
      tfc.account.openOrders = {
        [marketIdSymbol]: openOrders,
      };

      tfc.updateData();
    }
  }, [tfc, openOrders]);

  useEffect(() => {
    if (uiContext && marketInfo) {
      uiContext.changeSymbol({
        symbol: marketIdSymbol,
        name: marketInfo.name,
        exchDisp: 'PARAMOUNTDAX',
      });

      applyMarketFormatting(uiContext.stx, marketInfo);
    }
  }, [marketInfo]);

  useEffect(() => {
    if (!uiContext) return;

    if (!isEmpty(uiContext.stx.drawingObjects)) {
      uiContext.stx.clearDrawings();
    }

    if (!isGridBotSetting) {
      drawTrendOrders(uiContext.stx);
    }
  }, [
    buyTrendBotEntryDistancePrice,
    buyTrendBotTakeProfitDistancePrice,
    buyTrendBotSaveLossesDistancePrice,
    sellTrendBotEntryDistancePrice,
    sellTrendBotTakeProfitDistancePrice,
    sellTrendBotSaveLossesDistancePrice,
    isGridBotSetting,
    isTrendBotSellSideEnabled,
  ]);

  const config = useMemo(() => {
    return getChartConfig({
      resources: {
        quoteFeed: getQuoteFeed(marketInfo),
      },
    });
  }, [marketInfo]);

  // Callback function where you can access both the chartEngine and the UIContext.
  const chartInitialized = ({ chartEngine: stx, uiContext }) => {
    // Assign stx and CIQ to window for development convenience

    // Initialize TFC account manually
    const { channelWrite } = CIQ.UI.BaseComponent.prototype;
    channelWrite('channel.tfc', true, stx);

    applyCustomConfiguration(stx);
    applyMarketFormatting(stx, marketInfo);

    stx.loadChart(
      {
        symbol: marketIdSymbol,
        name: currentMarketName,
        exchDisp: 'PARAMOUNTDAX',
      },
      {},
      () => {
        const formatOpenOrders = (stxx) => {
          const openOrderMarkers = stxx.tfc.ephemeralNodes.openOrders;

          openOrderMarkers.forEach((order) => {
            const priceNode = order.querySelector('.tfc-price');

            priceNode.innerHTML = format(order.openOrder.quantity, {
              precision: marketInfo.quote_precision_format,
            });
          });
        };

        stx.append('draw', function () {
          formatOpenOrders(stx);
        });

        setTFC(stx.tfc);
      },
    );

    setUiContext(uiContext);
  };

  const drawTrendOrders = (stx) => {
    const draw = createChartDrawing(stx);

    if (
      buyTrendBotEntryDistancePrice !== null &&
      buyTrendBotSaveLossesDistancePrice !== null &&
      buyTrendBotTakeProfitDistancePrice !== null
    ) {
      draw('horizontal', {
        v0: Number(buyTrendBotEntryDistancePrice),
        col: '#fff',
      });
      draw('annotation', {
        text: intl.formatMessage({ id: 'BuyEntryDistancePrice' }),
        v0: Number(buyTrendBotEntryDistancePrice) + 100,
        col: '#fff',
      });

      draw('horizontal', {
        v0: Number(buyTrendBotSaveLossesDistancePrice),
        col: '#f23345',
      });
      draw('annotation', {
        text: intl.formatMessage({ id: 'BuySaveLossesDistancePrice' }),
        v0: Number(buyTrendBotSaveLossesDistancePrice),
        col: '#f23345',
      });

      draw('horizontal', {
        v0: Number(buyTrendBotTakeProfitDistancePrice),
      });
      draw('annotation', {
        text: intl.formatMessage({ id: 'BuyTakeProfitDistancePrice' }),
        v0: Number(buyTrendBotTakeProfitDistancePrice),
      });
    }

    if (
      isTrendBotSellSideEnabled &&
      sellTrendBotEntryDistancePrice !== null &&
      sellTrendBotSaveLossesDistancePrice !== null &&
      sellTrendBotTakeProfitDistancePrice !== null
    ) {
      draw('horizontal', {
        v0: Number(sellTrendBotEntryDistancePrice),
        col: '#fff',
      });
      draw('annotation', {
        text: intl.formatMessage({ id: 'SellEntryDistancePrice' }),
        v0: Number(sellTrendBotEntryDistancePrice),
        col: '#fff',
      });

      draw('horizontal', {
        v0: Number(sellTrendBotSaveLossesDistancePrice),
        col: '#f23345',
      });
      draw('annotation', {
        text: intl.formatMessage({ id: 'SellSaveLossesDistancePrice' }),
        v0: Number(sellTrendBotSaveLossesDistancePrice),
        col: '#f23345',
      });

      draw('horizontal', {
        v0: Number(sellTrendBotTakeProfitDistancePrice),
      });
      draw('annotation', {
        text: intl.formatMessage({ id: 'SellTakeProfitDistancePrice' }),
        v0: Number(sellTrendBotTakeProfitDistancePrice),
      });
    }
  };

  const createChartDrawing = (stx) => (name, properties) => {
    const defaultDrawingProperties =
      name === 'horizontal'
        ? defaultDrawingLineProperties
        : defaultDrawingTextProperties;

    stx.addDrawing(
      stx.createDrawing(name, {
        ...defaultDrawingProperties,
        ...properties,
      }),
    );
  };

  return (
    <div
      className={classNames(styles.chart, customStyles.chart, {
        [styles['chart--full_size']]: isFullSizeChart,
        [customStyles['chart--full_size']]: isFullSizeChart,
      })}
    >
      {currentBotMarketId && currentMarketName && isMarketsInfoSuccess && (
        <CustomChart
          chartId="_bot-chart"
          isDarkTheme
          locale={locale}
          config={config}
          percentChange={marketsStatistics?.c}
          customStyles={{ ...styles, ...customStyles }}
          chartInitialized={chartInitialized}
        >
          <BotChartTemplate
            isFullSize={isFullSizeChart}
            onFullSizeClick={onFullSizeClick}
            hideMenuPeriodicityItems={isDesktop ? [] : ['1 W']}
          />
        </CustomChart>
      )}
    </div>
  );
};

export default memo(BotChart);
