import React, { memo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { Button } from '@shared/ui';

import styles from './TextCutter.module.scss';

const TextCutter = ({ children, charactersNumber, customStyles = {} }) => {
  const [isTextDisplayed, setIsTextDisplayed] = useState(false);
  const [textTruncated, setTextTruncated] = useState();

  const toggleTextDisplay = () => setIsTextDisplayed((prevState) => !prevState);

  const extractTextFromChildren = (children) => {
    return React.Children.toArray(children).reduce((text, child) => {
      if (typeof child === 'string') {
        return text + child;
      } else if (typeof child === 'object' && child?.props?.children) {
        return text + extractTextFromChildren(child.props.children);
      }
      return text;
    }, '');
  };

  useEffect(() => {
    const extractedText = extractTextFromChildren(children);

    const textMoreThanMaxCharacterNumber =
      extractedText.length > charactersNumber;

    if (textMoreThanMaxCharacterNumber) {
      setTextTruncated(`${extractedText.slice(0, charactersNumber)}...`);
    }
  }, [children, charactersNumber]);

  return (
    <div>
      <span
        className={cx(customStyles.text_block, {
          'me-10': !isTextDisplayed,
        })}
      >
        {textTruncated && !isTextDisplayed ? textTruncated : children}
      </span>

      {textTruncated && (
        <Button
          type="button"
          variant="text"
          customStyles={styles}
          onClick={toggleTextDisplay}
        >
          {isTextDisplayed ? (
            <FormattedMessage id="ShowLess" />
          ) : (
            <FormattedMessage id="ReadMore" />
          )}
        </Button>
      )}
    </div>
  );
};

export default memo(TextCutter);
