import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '@components/Link';

const PromoSectionItem = ({ item }) => {
  return (
    <div className="promo-section-item">
      <img src={item.banner} alt={item.title} />

      <span className="title">
        <FormattedMessage id={item.title} />
      </span>

      <div className="d-flex justify-content-between">
        {item.links.map((link, index) => (
          <Link to={link.link} isNewTab={link.isNewTab} key={index}>
            {link.content}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default memo(PromoSectionItem);
