import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';

import { ReactComponent as SlideIconSVG } from '@images/icons/slide-icon.svg';

import styles from './SwiperCursorContainer.module.scss';

const SwiperCursorContainer = ({ children, customStyles = {} }) => {
  const [isActiveCursor, setIsActiveCursor] = useState(false);
  const [cursorStyle, setCursorStyle] = useState({
    display: 'none',
  });

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const updateCursorStyle = (newState) => {
    setCursorStyle((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  const changeIsShowCursorIcon = (isShow) => {
    updateCursorStyle({
      display: isShow ? 'block' : 'none',
    });
  };

  const handleMouseMove = (e) => {
    const x = e.pageX;
    const y = e.pageY;

    document.body.style.overflowX = 'hidden';

    updateCursorStyle({
      transform: `translate3d(${x}px, ${y}px, 1px) scale(1)`,
    });
  };

  const handleMouseEnter = () => {
    document.body.style.overflowX = 'hidden';

    updateCursorStyle({
      display: 'block',
    });
  };

  const handleMouseLeave = () => {
    updateCursorStyle({
      display: 'none',
    });
  };

  const handleTouchStart = () => {
    setIsActiveCursor(true);
  };

  const handleTouchEnd = () => {
    setIsActiveCursor(false);
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      {children(
        {
          onTouchStart: handleTouchStart,
          onTouchEnd: handleTouchEnd,
          onSliderMove: (swiper, e) => {
            handleMouseMove(e);
          },
        },
        changeIsShowCursorIcon,
      )}

      {createPortal(
        <div
          className={classNames(styles.cursor, {
            [styles.active]: isActiveCursor,
          })}
        >
          <div style={cursorStyle} className={styles.cursor_element}>
            <div
              className={classNames(styles.cursor_bg, customStyles.cursor_bg, {
                [styles.active]: isActiveCursor,
              })}
            >
              <SlideIconSVG />
            </div>
          </div>
        </div>,
        document.body,
      )}
    </div>
  );
};

export default memo(SwiperCursorContainer);
