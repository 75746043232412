import { createSelector } from '@reduxjs/toolkit';
import { subDays, subMonths, subWeeks } from 'date-fns';

import { CURRENCY_LIST } from '@constants';

import {
  ALL_CRYPTO_MARKETS_TIME_FRAMES_KEY,
  ASSETS_ITEMS_KEY,
} from '../AllMarkets/allMarkets.constants';

const featureSelector = (state) => state.allCryptoMarkets;

const filtersSelector = createSelector(featureSelector, (data) => data.filters);

const paginationSelector = createSelector(
  featureSelector,
  (data) => data.pagination,
);

const selectedCurrencySelector = createSelector(
  filtersSelector,
  (filters) => filters.selectedCurrency,
);

const selectedAssetsKeySelector = createSelector(
  filtersSelector,
  (filters) => filters.selectedAssetsKey,
);

const selectedPairsKeySelector = createSelector(
  filtersSelector,
  (filters) => filters.selectedPairsKey,
);

const selectedTimeFrameSelector = createSelector(
  filtersSelector,
  (filters) => filters.selectedTimeFrame,
);

const isShowTradePairsTableSelector = createSelector(
  selectedAssetsKeySelector,
  selectedPairsKeySelector,
  (selectedAssetsKey, selectedPairsKey, selectedFiatPairsKey) =>
    selectedAssetsKey !== ASSETS_ITEMS_KEY.ALL_CRYPTOS || selectedPairsKey,
);

const searchValueSelector = createSelector(filtersSelector, (filters) => {
  return filters.searchValue;
});

const selectedTimeFrameDateSelector = createSelector(
  selectedTimeFrameSelector,
  (selectedTimeFrame) => {
    const today = new Date();

    if (selectedTimeFrame === ALL_CRYPTO_MARKETS_TIME_FRAMES_KEY.ONE_DAY) {
      return subDays(today, 1).getTime();
    }

    if (selectedTimeFrame === ALL_CRYPTO_MARKETS_TIME_FRAMES_KEY.ONE_WEEK) {
      return subWeeks(today, 1).getTime();
    }

    if (selectedTimeFrame === ALL_CRYPTO_MARKETS_TIME_FRAMES_KEY.ONE_MONTH) {
      return subMonths(today, 1).getTime();
    }
  },
);

const selectedCurrencySymbolSelector = createSelector(
  selectedCurrencySelector,
  (selectedCurrency) =>
    CURRENCY_LIST.find(
      (currency) => selectedCurrency.toUpperCase() === currency.value,
    ).symbol,
);

export const AllCryptoMarketsSelectors = {
  filtersSelector,
  paginationSelector,
  selectedCurrencySelector,
  selectedCurrencySymbolSelector,
  selectedTimeFrameSelector,
  selectedTimeFrameDateSelector,
  searchValueSelector,
  selectedAssetsKeySelector,
  selectedPairsKeySelector,
  isShowTradePairsTableSelector,
};
