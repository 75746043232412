import React, { Fragment, memo, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import {
  Menu,
  Item,
  Submenu,
  Separator,
  useContextMenu,
} from 'react-contexify';

import useTheme from '@hooks/useTheme';

import { ReactComponent as RightChevronIconSvg } from '@icons/right-chevron-icon.svg';

import 'react-contexify/ReactContexify.css';

const getCornerPosition = (triggerRef, cornerRef, leftOffset) => {
  const triggerLeftPosition =
    triggerRef.current?.getBoundingClientRect().left +
    leftOffset +
    triggerRef.current?.clientWidth / 2;
  const cornerPosition =
    cornerRef.current?.getBoundingClientRect().left +
    cornerRef.current?.clientWidth;

  const left = triggerLeftPosition - cornerPosition;

  return {
    left: isNaN(left) || left < 0 ? 0 : left,
  };
};

const Dropdown = ({
  children,
  className,
  id,
  rounded = false,
  menuItems = [],
  menuItemClassName,
  leftOffset = 0,
  topOffset = 0,
  hasLimitedWidth = false,
  onItemClick = () => {},
  onMenuTrigger,
  idAccessor = 'id',
  titleAccessor = 'title',
  isWithCorner,
  animation = 'scale',
}) => {
  const triggerRef = useRef();
  const submenuRef = useRef();
  const cornerRef = useRef();
  const { themeStyles } = useTheme();
  const submenuMaxHeight = Number.parseInt(
    themeStyles['dropdown-submenu-max-height'],
  );
  const animationDelay = 300;
  const { show } = useContextMenu({ id });

  const [submenuTopPosition, setSubmenuTopPosition] = useState(null);
  const [cornerPosition, setCornerPosition] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuTrigger = (e) => {
    e.stopPropagation();

    const { left, bottom } = triggerRef.current.getBoundingClientRect();

    show({
      event: e,
      position: {
        x: left + leftOffset,
        y: bottom + topOffset,
      },
    });
  };

  const setSubmenuPosition = () => {
    if (!submenuRef.current) return;

    const result = {};
    const { innerHeight, innerWidth } = window;
    const { clientHeight } = submenuRef.current;
    const { top, right } = submenuRef.current.getBoundingClientRect();

    const submenuHeight =
      clientHeight > submenuMaxHeight ? submenuMaxHeight : clientHeight;
    const bottomPosition = top + submenuHeight;

    if (right - innerWidth > 0) {
      result.right = 'auto';
      result.left = '100%';
    }

    if (bottomPosition - innerHeight > 0) {
      result.top = 'auto';
      result.bottom = 0;
    }

    setSubmenuTopPosition(result);
  };

  const handleVisibilityChange = (isVisible) => {
    setIsOpen(isVisible);

    setTimeout(() => setSubmenuPosition(), 0);

    setTimeout(
      () =>
        setCornerPosition(
          isVisible
            ? getCornerPosition(triggerRef, cornerRef, leftOffset)
            : null,
        ),
      isVisible ? 0 : animationDelay,
    );
  };

  return (
    <>
      {children({
        triggerRef,
        handleMenuTrigger: onMenuTrigger || handleMenuTrigger,
        isOpen,
      })}

      {createPortal(
        <Menu
          className={classNames(
            'v2-dropdown-menu v2-dropdown-menu__dark',
            className,
            {
              'v2-dropdown-menu--rounded': rounded,
              'has-submenu': menuItems.some(
                (item) => item.isSubmenu && item.submenuItems.length > 0,
              ),
              'v2-dropdown-menu__with_corner': isWithCorner,
            },
          )}
          id={id}
          animation={animation}
          onVisibilityChange={handleVisibilityChange}
          style={
            hasLimitedWidth
              ? {
                  minWidth: triggerRef.current?.clientWidth,
                }
              : {}
          }
        >
          {/* Fragment created to avoid pass triggerEvent and propsFromTrigger props in div
             'https://github.com/fkhadra/react-contexify/issues/187
          */}
          <Fragment>
            {isWithCorner && (
              <span
                className="corner-item"
                ref={cornerRef}
                style={cornerPosition}
              />
            )}

            {menuItems.map((item, index) => (
              <Fragment key={index}>
                {item.withDivider ? <Separator /> : null}

                {item.isSubmenu ? (
                  <Submenu
                    style={submenuTopPosition}
                    label={item[titleAccessor]}
                    hidden={item.isSubmenu && item.submenuItems.length === 0}
                    arrow={
                      <div className="d-flex align-items-center">
                        {item.extraComponent ? (
                          <div className="me-8">{item.extraComponent}</div>
                        ) : null}
                        <RightChevronIconSvg
                          width={9}
                          height={14}
                          className="v2-dropdown-menu__arrow"
                        />
                      </div>
                    }
                  >
                    {/* Fragment created to avoid pass triggerEvent and propsFromTrigger props in div
                         'https://github.com/fkhadra/react-contexify/issues/187
                      */}
                    <Fragment>
                      <div ref={submenuRef}>
                        {item.submenuItems.map(
                          ({ title, ...subRest }, index) => (
                            <Fragment key={index}>
                              <Item {...subRest}>{title}</Item>
                            </Fragment>
                          ),
                        )}
                      </div>
                    </Fragment>
                  </Submenu>
                ) : (
                  <Item
                    className={classNames(menuItemClassName, item.className)}
                    id={item[idAccessor]}
                    disabled={item.disabled}
                    onClick={
                      item.onClick ? item.onClick : () => onItemClick(item)
                    }
                  >
                    {typeof item[titleAccessor] === 'string' ? (
                      <span
                        className={classNames(item.className, {
                          'text-truncate': hasLimitedWidth,
                        })}
                      >
                        {item[titleAccessor]}
                      </span>
                    ) : (
                      item[titleAccessor]
                    )}
                  </Item>
                )}
              </Fragment>
            ))}
          </Fragment>
        </Menu>,
        document.querySelector('#dropdown-container'),
      )}
    </>
  );
};

export default memo(Dropdown);
