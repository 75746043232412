import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DownloadFromStoreButton } from '@shared/components';
import { APP_STORE_MOBILE_APP_LINK } from '@shared/constants';

import { ReactComponent as AppStoreSvg } from '@images/icons/apple-icon.svg';

const DownloadFromAppStoreButton = ({ ...props }) => {
  const getIconSize = () => {
    if (props.size === 'l') {
      return { width: 18, height: 22 };
    }

    return { width: 15, height: 18 };
  };

  const { width, height } = getIconSize();

  return (
    <DownloadFromStoreButton
      navigation={{ to: APP_STORE_MOBILE_APP_LINK }}
      icon={<AppStoreSvg width={width} height={height} />}
      text={<FormattedMessage id="DownloadOnThe" />}
      subText={<FormattedMessage id="AppStore" />}
      variant="contained"
      color="primary"
      {...props}
    />
  );
};

export default memo(DownloadFromAppStoreButton);
