import { useMemo } from 'react';

import { useCoinsInfo, useMarketsInfo } from '@store/api/hooks';

import { getMarketPairFullName } from '@utils/market';

import {
  LIMIT_COUNT_SEARCH_ITEMS,
  SEARCH_ITEM_TYPE,
} from '../SearchMenu.constant';

const LIMIT_COUNT_COIN_WITHOUT_SEARCH = 3;
const LIMIT_COUNT_MARKETS_WITHOUT_SEARCH = 2;

export const useSearchMenuList = (searchFilter) => {
  const { coinsInfoMap } = useCoinsInfo();
  const { marketsInfoMap } = useMarketsInfo();

  const coinsList = useMemo(
    () =>
      coinsInfoMap.map(({ name, symbol }) => ({
        title: [name, symbol.toUpperCase()],
        type: SEARCH_ITEM_TYPE.COIN,
        symbol,
      })),
    [coinsInfoMap.length],
  );

  const marketList = useMemo(
    () =>
      marketsInfoMap.map((market) => {
        const marketFullName = getMarketPairFullName(
          coinsInfoMap,
          {
            marketCoinSymbol: market.market_coin_symbol,
            quoteCoinSymbol: market.quote_coin_symbol,
          },
          true,
        );

        return {
          title: [market.name, marketFullName],
          type: SEARCH_ITEM_TYPE.MARKET,
          id: market.id,
        };
      }),
    [coinsInfoMap.length, marketsInfoMap.length],
  );

  const filteredList = useMemo(() => {
    const list = searchFilter
      ? [...coinsList, ...marketList]
      : [
          ...coinsList.slice(0, LIMIT_COUNT_COIN_WITHOUT_SEARCH),
          ...marketList.slice(0, LIMIT_COUNT_MARKETS_WITHOUT_SEARCH),
        ];

    return list
      .reduce((acc, item) => {
        if (!searchFilter) {
          acc.push({ ...item, title: item.title[0] });

          return acc;
        }

        const title = item.title.find((title) =>
          title.toLowerCase().includes(searchFilter),
        );

        if (title) {
          acc.push({ ...item, title });
        }

        return acc;
      }, [])
      .slice(0, LIMIT_COUNT_SEARCH_ITEMS);
  }, [coinsList.length, marketList.length, searchFilter]);

  return filteredList;
};
