import { useNavigate } from 'react-router-dom';

import { useNavigateBack } from '@shared/hooks';

import {
  useDeleteApiKeyMutation,
  useDeleteIpsFromApiKeyWhitelistMutation,
  useAddIpsToApiKeyWhitelistMutation,
  usePostGenerateApiKeyMutation,
} from '@api/managementApiKeyAPI';
import { formatObjectKeysFromCamelToSnakeCase } from '@api/utils';

import { ACCOUNT_ROUTES } from '../../../../routes.constants';

const useManagementApiKeyMutations = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  const [generateApiKey] = usePostGenerateApiKeyMutation();
  const [addAllowedIpsToKey] = useAddIpsToApiKeyWhitelistMutation();
  const [deleteApiKey] = useDeleteApiKeyMutation();
  const [deleteAllowedIpsInApiKey] = useDeleteIpsFromApiKeyWhitelistMutation();

  const onGenerateApiKey = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    const result = await generateApiKey(body).unwrap();

    navigate(ACCOUNT_ROUTES.PRIVATE_API_KEY, { state: result });
  };

  const onAddAllowedIpsToKey = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await addAllowedIpsToKey(body).unwrap();

    navigateBack();
  };

  const onDeleteApiKey = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await deleteApiKey(body).unwrap();

    navigateBack();
  };

  const onDeleteAllowedIpsInApiKey = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await deleteAllowedIpsInApiKey(body).unwrap();

    navigateBack();
  };

  return {
    onGenerateApiKey,
    onAddAllowedIpsToKey,
    onDeleteApiKey,
    onDeleteAllowedIpsInApiKey,
  };
};

export default useManagementApiKeyMutations;
