import { useMemo } from 'react';

import { useGetClosedOrdersQuery } from '@api/ordersAPI';
import { ClosedOrdersApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isClosedOrdersSuccess: result.isSuccess,
  };

  dataResult.closedOrders =
    ClosedOrdersApiSelectors.closedOrdersSelector(result);
  dataResult.closedOrdersMeta = ClosedOrdersApiSelectors.metaSelector(result);

  return dataResult;
};

const useClosedOrders = (
  { pair, accountId, since, page, limit } = {},
  options,
) => {
  const result = useGetClosedOrdersQuery(
    { pair, accountId, since, page, limit },
    {
      ...options,
      skip: !accountId || options.skip,
    },
  );

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useClosedOrders;
