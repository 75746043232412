import { memo } from 'react';

import { flexRender } from '@tanstack/react-table';
import classnames from 'classnames';

import { isEven } from '@utils/numbers';

import { NOOP } from '@constants';

const TableDataCell = ({
  stripedRows,
  cell,
  width,
  index,
  className,
  style,
  wideRows = false,
  wideRowsS = false,
  wideRowsL = false,
  wideRowsXL = false,
  onRowClick = NOOP,
  onRowMouseDown = NOOP,
  onRowTouchStart = NOOP,
  onMouseEnterRow = NOOP,
  onMouseLeaveRow = NOOP,
  ...props
}) => {
  const stripedRowsState = cell.row.original.row_data?.stripedRowsState;
  const isEnableTruncating = cell.column.columnDef.enableTruncating ?? true;
  const cellAlignClass = cell.column.columnDef.cellAlign ?? 'end';
  const rowClassName = cell.row.original.row_data?.className;
  const isClickableCell =
    (cell.column.columnDef.isClickable ?? true) && onRowClick !== NOOP;
  const cellUnderlined = cell.column.columnDef.cellUnderlined;

  return (
    <td
      style={{
        maxWidth: isEnableTruncating ? 0 : undefined,
        ...style,
      }}
      className={classnames(
        `table-default__cell--${cellAlignClass}-align`,
        {
          'table-default__cell--contrast-cell':
            (stripedRowsState ? stripedRowsState.isStriped : isEven(index)) &&
            stripedRows,
          'table-default__cell--is_link': cellUnderlined,
          'cursor-pointer': isClickableCell,
          'wide-rows': wideRows,
          'wide-rows-s': wideRowsS,
          'wide-rows-l': wideRowsL,
          'wide-rows-xl': wideRowsXL,
        },
        rowClassName,
        className,
        cell.column.id,
      )}
      onClick={() => {
        if (isClickableCell) {
          onRowClick(cell.row);
        }
      }}
      onMouseDown={onRowMouseDown}
      onTouchStart={onRowTouchStart}
      onMouseEnter={() => onMouseEnterRow(cell.row)}
      onMouseLeave={() => onMouseLeaveRow(cell.row)}
      {...props}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

export default memo(TableDataCell);
