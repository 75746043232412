import { memo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

import { Button } from '@shared/ui';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar/useSidebar';

import { sidebarIds } from '@constants';

const AddNewAccountButton = () => {
  const { state } = useLocation();

  const { isMobile } = useBreakpoints();
  const { openSidebar } = useSidebar(sidebarIds.SPOT_ACCOUNT);

  const isCreateAccount = state?.isCreateAccount;

  useEffect(() => {
    if (isCreateAccount) {
      openSidebar();
    }
  }, [isCreateAccount]);

  const handleClick = () => {
    openSidebar({ accountId: null });
  };

  return (
    <Button onClick={handleClick} isCircle={isMobile} variant="outlined">
      + {!isMobile && <FormattedMessage id="AddNewAccount" />}
    </Button>
  );
};

export default memo(AddNewAccountButton);
