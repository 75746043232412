import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { FORBIDDEN_FILE_TYPES } from '@shared/constants';

export function validatePassword(intl) {
  return (password) => {
    const pattern = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~§±])(?=.{8,})/,
    );

    const isValidPassword = pattern.test(password);

    return (
      isValidPassword || intl.formatMessage({ id: 'InvalidPasswordFormat' })
    );
  };
}

export function validateNewPassword(getValues, intl) {
  return () => {
    const { current, newPassword } = getValues();

    if (current === newPassword) {
      return intl.formatMessage({ id: 'NewPasswordCantBeEqualOldPassword' });
    }
    return true;
  };
}

export function validateConfirmPassword(password, intl) {
  return (confirmPassword) => {
    if (password === confirmPassword) {
      return true;
    }

    return intl.formatMessage({ id: 'PasswordsDoNotMatch' });
  };
}

export function validateEmail(intl) {
  return (email) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isEmailValid = pattern.test(email);

    return isEmailValid || intl.formatMessage({ id: 'InvalidEmailFormat' });
  };
}

export const validatePhoneNumber = (intl) => (phone) =>
  isValidPhoneNumber(phone) || intl.formatMessage({ id: 'InvalidPhoneFormat' });

export function validatePostCode(intl, countryCode) {
  return (postCode) => {
    if (postcodeValidatorExistsForCountry(countryCode)) {
      return (
        postcodeValidator(postCode, countryCode) ||
        intl.formatMessage({ id: 'InvalidZIPCodeFormat' })
      );
    }

    return true;
  };
}

export const validateFileSize =
  ({ intl, maxSizeBites = 5000000 }) =>
  (file) => {
    if (!file) return true;

    return (
      file.size <= maxSizeBites ||
      intl.formatMessage(
        { id: 'FileSizeIsMoreThan' },
        { fileName: file.name, sizeMb: maxSizeBites / 10e5 },
      )
    );
  };

const getFileExtension = (fileName) => {
  const splitFileName = fileName.split('.').pop();

  return `.${splitFileName}`.toLowerCase();
};
export const validateFileType =
  ({ intl, acceptableFileTypes = [] }) =>
  (file) => {
    if (!file) return true;

    const fileName = file.name;
    const fileExtension = getFileExtension(fileName);

    let isInvalid;

    if (!!acceptableFileTypes.length) {
      isInvalid = !acceptableFileTypes.find(
        (acceptableType) => acceptableType === fileExtension,
      );
    } else {
      isInvalid = !!FORBIDDEN_FILE_TYPES.find(
        (fileType) => fileType === fileExtension,
      );
    }

    return (
      !isInvalid ||
      intl.formatMessage({ id: 'IsNotAllowedFileType' }, { fileName })
    );
  };
