import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { sum } from 'lodash';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import { useMyStakingTable } from '@pages/WalletsPage/Staking/hooks';

import { useCoinsRates, useCoinsInfo } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { StakingUtils } from '../staking.utils';

const useCalculateStakedBalance = () => {
  const { value: currency } = useSelector(currencySelector);

  const { stakingList } = useMyStakingTable();

  const { coinsInfo } = useCoinsInfo();

  const { ratesByCoin: coinRateBTC, isCoinsRatesSuccess } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.BTC,
  });

  const amounts = useMemo(() => {
    return stakingList
      .filter(
        (item) => item.asset.coinSymbol !== CRYPTO_CURRENCIES.BTC.toLowerCase(),
      )
      .map((item) => {
        const amount = Number(item.asset.amount);
        const coinSymbol = item.asset.coinSymbol;

        const coinToBtcCourse = Number(
          coinRateBTC?.[item.asset.coinSymbol.toUpperCase()],
        );

        const valueInBTC = amount / coinToBtcCourse;

        return [coinSymbol, valueInBTC];
      });
  }, [coinRateBTC, stakingList]);

  const duplicatedValues = StakingUtils.findDuplicatesAndSum(amounts);

  const amountInBTC = useMemo(
    () => sum(duplicatedValues.map((item) => item[1])),
    [amounts],
  );

  const btcCoins = stakingList?.filter(
    (item) => item.asset.coinSymbol === CRYPTO_CURRENCIES.BTC.toLowerCase(),
  );

  const valuesInBTC = sum(btcCoins.map((item) => Number(item.asset.amount)));

  const resultItems = useMemo(() => {
    if (!isCoinsRatesSuccess) {
      return [];
    }

    return [...duplicatedValues, [CRYPTO_CURRENCIES.BTC, valuesInBTC]].map(
      (item) => {
        const coinSymbol = item[0];
        const coinInfo = coinsInfo?.[coinSymbol];

        return { coinSymbol, coinFullname: coinInfo?.name, value: item[1] };
      },
    );
  }, [coinsInfo, isCoinsRatesSuccess, valuesInBTC, amounts]);

  const totalAmountInBTC = amountInBTC + valuesInBTC;

  const totalAmountInSelectedCurrency =
    totalAmountInBTC * coinRateBTC?.[currency];

  return {
    currency,
    totalAmountInBTC,
    totalAmountInSelectedCurrency,
    resultItem: resultItems,
    isLoaded: isCoinsRatesSuccess,
  };
};

export default useCalculateStakedBalance;
