export const getMarketPairFullName = (
  coinsInfoMap,
  { marketCoinSymbol, quoteCoinSymbol },
  isReturnString = false,
) => {
  const findCurrencyName = (currencySymbol) => {
    return coinsInfoMap.find((coin) => coin.symbol === currencySymbol)?.name;
  };

  const fullMarketName = findCurrencyName(marketCoinSymbol);

  const fullQuoteName = findCurrencyName(quoteCoinSymbol);

  if (isReturnString) {
    return `${fullMarketName}/${fullQuoteName}`;
  }

  return {
    fullMarketName,
    fullQuoteName,
  };
};
