import { useSelector } from 'react-redux';

import { useBotsPnl, useArchivedBots } from '@store/api/hooks';

import { LiquidatedBotsSelectors } from '../LiquidatedBotsTab/liquidatedBots.selectors';

import useBotsTableData from './useBotsTableData';

const useLiquidatedBotsTableData = () => {
  const { liquidatedBots, isSuccessArchivedBots } = useArchivedBots(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { isBotsPnlSuccess } = useBotsPnl();

  const filters = useSelector(LiquidatedBotsSelectors.filtersSelector);
  const pagination = useSelector(LiquidatedBotsSelectors.paginationSelector);

  const { paginatedBots, filteredBotsLength } = useBotsTableData({
    filters,
    pagination,
    bots: liquidatedBots,
  });

  return {
    liquidatedBots: paginatedBots,
    liquidatedBotsCount: filteredBotsLength,
    allLiquidatedBotsLength: liquidatedBots.length,
    isLiquidatedSuccess: isSuccessArchivedBots && isBotsPnlSuccess,
  };
};

export default useLiquidatedBotsTableData;
