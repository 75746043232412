import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../utils';

const defaultCoins = [];
const defaultLimits = [];
const defaultMarkets = [];
const defaultPeriods = [];

const defaultData = {
  coins: defaultCoins,
  limits: defaultLimits,
  markets: defaultMarkets,
  periods: defaultPeriods,
  meta: {
    limit: 5,
    page: 1,
  },
};
const defaultMeta = {};

const dataSelector = ApiSelectorsUtils.createDataApiSelector(defaultData);

const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const coinsSelector = createSelector(dataSelector, (data) => {
  return data?.coins || defaultCoins;
});

const limitsSelector = createSelector(dataSelector, (data) => {
  return data?.limits || defaultLimits;
});

const marketsSelector = createSelector(dataSelector, (data) => {
  return data?.markets || defaultMarkets;
});

const periodsSelector = createSelector(dataSelector, (data) => {
  return data?.periods || defaultPeriods;
});

export const BonusAccountSelectors = {
  coinsSelector,
  limitsSelector,
  marketsSelector,
  periodsSelector,
  metaSelector,
};
