import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '@store/api/utils';

const defaultData = {};
const defaultMeta = {};
const defaultOpenOrders = [];
const defaultOrderHistory = [];
const defaultOrdersTrades = [];
const defaultOrderHistoryDetails = {
  orders: [],
  trades: [],
  meta: {},
};

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);
const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const openOrdersSelector = createSelector(
  dataSelector,
  (orderHistory) => orderHistory.orders ?? defaultOpenOrders,
);

const orderHistorySelector = createSelector(
  dataSelector,
  (orderHistory) => orderHistory.orders ?? defaultOrderHistory,
);

const orderTradesSelector = createSelector(
  dataSelector,
  (orderHistory) => orderHistory.trades ?? defaultOrdersTrades,
);

const tradesByOrderIdsSelector = createSelector(
  ({ data }) => data ?? defaultOrderHistoryDetails,
  (OrderHistoryDetails, marketInfo) => marketInfo,
  (OrderHistoryDetails, marketInfo) => {
    const { trades } = OrderHistoryDetails;

    return trades.reduce((acc, trade) => {
      const { order_id } = trade;

      if (acc.hasOwnProperty(order_id)) {
        acc[order_id].push(trade);
      } else {
        acc[order_id] = [trade];
      }

      return acc;
    }, {});
  },
);

export const OrdersApiSelectors = {
  dataSelector,
  metaSelector,
  openOrdersSelector,
  orderHistorySelector,
  orderTradesSelector,
  tradesByOrderIdsSelector,
};
