import { HeaderCollapsible } from '@shared/components';

import {
  CardBalance,
  CardNumber,
  CardType,
} from '@pages/WalletsPage/VisaCardSettings/components/CardInfoBlock/components';

import useBreakpoints from '@hooks/useBreakpoints';

import s from './CardInfoBlock.module.scss';

const CardInfoBlock = () => {
  const { isTabletDown } = useBreakpoints();

  if (isTabletDown) {
    return (
      <HeaderCollapsible
        customStyles={s}
        isShowCollapsible
        headerComponent={
          <div className="pe-30">
            <CardType />
          </div>
        }
        collapsibleComponent={
          <div>
            <div className={s.collapse_item}>
              <CardNumber />
            </div>
            <div className={s.collapse_item}>
              <CardBalance />
            </div>
          </div>
        }
      />
    );
  }

  return (
    <div className="row">
      <div className="col-5">
        <CardType />
      </div>
      <div className="col-4">
        <CardNumber />
      </div>
      <div className="col-3">
        <CardBalance />
      </div>
    </div>
  );
};

export default CardInfoBlock;
