import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { PLAY_MARKET_MOBILE_APP_LINK } from '@shared/constants';

import Button from '@components/Button';

import getStarted from '@images/get-started-promo.jpg';
import { ReactComponent as MobileAppAndroidDownloadSVG } from '@images/mobile-app-android-download.svg';
import { ReactComponent as MobileAppIOSDownloadSVG } from '@images/mobile-app-ios-download.svg';
import mobileAppPromoSrc from '@images/mobile-app-promo.jpg';

import { ReactComponent as ArrowRightSVG } from '@icons/arrow-right-side-icon.svg';

import PromoSectionItem from './components/PromoSectionItem';

const PromoSection = () => {
  const PromoSectionItems = [
    {
      banner: getStarted,
      title: 'RelaxWhileYourAssetsMakeUpto30%PassiveIncome',
      links: [
        {
          content: (
            <Button className="get-started">
              <FormattedMessage id="GetStarted" />

              <ArrowRightSVG className="ms-12" />
            </Button>
          ),
          link: 'https://apps.apple.com/ua/app/paramountdax/id1585379080',
          isNewTab: true,
        },
      ],
    },
    {
      banner: mobileAppPromoSrc,
      title: 'ExchangeInYourPocket',
      links: [
        {
          content: (
            <MobileAppIOSDownloadSVG className="mobile-app-ios-download" />
          ),
          link: 'https://apps.apple.com/ua/app/paramountdax/id1585379080',
          isNewTab: true,
        },
        {
          content: (
            <MobileAppAndroidDownloadSVG className="mobile-app-android-download" />
          ),
          link: PLAY_MARKET_MOBILE_APP_LINK,
          isNewTab: true,
        },
      ],
    },
  ];

  return (
    <div className="promo-section">
      {PromoSectionItems.map((item, index) => (
        <PromoSectionItem item={item} key={index} />
      ))}
    </div>
  );
};

export default memo(PromoSection);
