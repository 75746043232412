import { useMemo } from 'react';

import { useStreamCoinRatesQuery } from '@api/coinsAPI';
import { CoinsApiSelectors } from '@api/selectors';

const selectFromResult = (result, { fromCoinSymbol, toCoinSymbol } = {}) => {
  const dataResult = {
    isCoinsRatesSuccess: result.data && result.isSuccess,
  };

  const coinsRates = CoinsApiSelectors.dataSelector(result);
  const ratesByCoinFrom = coinsRates?.[fromCoinSymbol?.toUpperCase()];

  dataResult.ratesByCoin = ratesByCoinFrom;
  dataResult.ratesByPair =
    Number.parseFloat(ratesByCoinFrom?.[toCoinSymbol?.toUpperCase()]) || 0;
  dataResult.coinsRates = coinsRates;

  return dataResult;
};

const useCoinsRates = ({ fromCoinSymbol, toCoinSymbol } = {}, options) => {
  const result = useStreamCoinRatesQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result, { fromCoinSymbol, toCoinSymbol });
  }, [result, fromCoinSymbol, toCoinSymbol]);

  return data;
};

export default useCoinsRates;
