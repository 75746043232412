import useTheme from '@hooks/useTheme';

const useValueColor = (value) => {
  const { themeStyles } = useTheme();

  const isPositiveValue = value > 0;
  const isZeroValue = value === 0;

  const getColor = () => {
    if (isZeroValue) {
      return themeStyles['dark-grey'];
    }
    return isPositiveValue ? themeStyles.green : themeStyles.red;
  };

  return { color: getColor(), isPositiveValue, isZeroValue };
};

export default useValueColor;
