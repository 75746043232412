export const BLOCK_TYPES = {
  chart: 'chart',
  trading: 'trading',
  orderBook: 'orderBook',
  markets: 'markets',
  openOrders: 'openOrders',
  balances: 'balances',
  trades: 'trades',
  depth: 'depth',
  orderHistory: 'orderHistory',
};
