import React, { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import styles from './CustomPlaceholder.module.scss';

const { Placeholder } = components;

const CustomPlaceholder = ({ children, selectProps, ...props }) => {
  const isInversion = selectProps.inversion;

  return (
    <Placeholder
      className={cx(styles.placeholder, {
        [styles.inversion]: isInversion,
      })}
      {...props}
    >
      {children}
    </Placeholder>
  );
};

export default memo(CustomPlaceholder);
