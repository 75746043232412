import { useEffect, useMemo, useState } from 'react';

import { useBots, useArchivedBots } from '@store/api/hooks';

const useAllBots = (args, options) => {
  const [allBots, setAllBots] = useState([]);

  const { botsMap, isBotsSuccess, isBotsFetching, refetchBots } = useBots(
    undefined,
    options,
  );
  const {
    archivedBots,
    liquidatedBots,
    isSuccessArchivedBots,
    isFetchingArchivedBots,
    refetchArchivedBots,
  } = useArchivedBots(undefined, options);

  useEffect(() => {
    if (isBotsFetching || isFetchingArchivedBots) {
      return;
    }

    setAllBots([...botsMap, ...archivedBots, ...liquidatedBots]);
  }, [
    archivedBots,
    botsMap,
    isFetchingArchivedBots,
    isBotsFetching,
    liquidatedBots,
  ]);

  const data = useMemo(() => {
    return {
      allCurrentBots: [...botsMap, ...archivedBots, ...liquidatedBots],
      allBots,
      isLoading: !isBotsFetching || !isFetchingArchivedBots,
      isLoaded: isBotsSuccess && isSuccessArchivedBots,
      refetchBots,
      refetchArchivedBots,
    };
  }, [
    allBots,
    botsMap,
    archivedBots,
    liquidatedBots,
    isBotsFetching,
    isFetchingArchivedBots,
    isBotsSuccess,
    isSuccessArchivedBots,
  ]);

  return data;
};

export default useAllBots;
