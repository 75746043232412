import React, { useLayoutEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer } from 'react-toastify';

import { loadIntercom } from '@libs/intercom';
import initOneTrustScript from '@libs/oneTrust';

import { isAuthorizedUserSelector } from '@store/settings/settingsSelectors';
import { settingsActions } from '@store/settings/settingsSlice';

import wsClient from '@api/ws/client';

import { getUrlParams } from '@utils';

import { LOCAL_STORAGE_AUTH_TOKEN, LOCAL_STORAGE_MID } from '@constants';

import Routes from './Routes';

// availability to pass data via params at start-up. Useful in the mobile webview case
const urlParams = getUrlParams(window.location);
const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
const currentMID = localStorage.getItem(LOCAL_STORAGE_MID);

if (urlParams.mid && !currentMID) {
  localStorage.setItem(LOCAL_STORAGE_MID, urlParams.mid);
}

if (urlParams.token && !token) {
  localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, urlParams.token);

  window.location.reload();
}

const App = () => {
  const isAuthorizedUser = useSelector(isAuthorizedUserSelector);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    initOneTrustScript();
    loadIntercom();

    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    if (token) {
      wsClient.client.setToken(token);
    }

    wsClient.client.on('connect', async (context) => {
      console.log(context);

      dispatch(settingsActions.setIsWSConnected(true));
    });

    wsClient.client.on('disconnect', (context) => {
      console.log(context);

      dispatch(settingsActions.setIsWSConnected(false));
    });

    wsClient.connect();
  }, [isAuthorizedUser]);

  return (
    <>
      <Routes />
      <ToastContainer icon={false} theme="colored" />
    </>
  );
};

export default memo(App);
