import { memo } from 'react';

import cx from 'classnames';

import { Button } from '@shared/ui';

import styles from './DownloadFromStoreButton.module.scss';

const DownloadFromStoreButton = ({
  icon,
  text,
  subText,
  customStyles = {},
  ...props
}) => {
  return (
    <Button
      target="_blank"
      color="tertiary"
      variant="outlined"
      type="button"
      {...props}
    >
      <div
        className={cx(
          styles.content,
          customStyles.content,
          styles[props.size],
          {
            [styles.inversion]: props?.inversion,
          },
        )}
      >
        <div className={cx(styles.icon, customStyles.icon)}>{icon}</div>

        {(text || subText) && (
          <div className={cx(styles.text_block, customStyles.text_block)}>
            <div className={cx(styles.text, customStyles.text)}>{text}</div>
            <div className={cx(styles.sub_text, customStyles.sub_text)}>
              {subText}
            </div>
          </div>
        )}
      </div>
    </Button>
  );
};

export default memo(DownloadFromStoreButton);
