import { RangeDatePickerController } from '@shared/components';

import s from './DatePicker.module.scss';

const DatePicker = ({ ...props }) => {
  const inputProps = {
    bordered: true,
    rounded: true,
    customStyles: s,
  };
  return <RangeDatePickerController inputProps={inputProps} {...props} />;
};

export default DatePicker;
