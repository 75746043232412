import React, { memo, useId } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import { PieChart } from '@shared/components';

import { useCalculateStakedItem } from '@pages/WalletsPage/Staking/hooks';
import { PIE_CHART_COLORS } from '@pages/WalletsPage/Staking/staking.constants';

import { isShowSensitiveInfoSelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as NoCoinsPieChartSVG } from '@icons/no-coins-pie-chart.svg';

const StakedDiagram = ({ resultItem }) => {
  const chartId = useId();

  const intl = useIntl();

  const { isMobile } = useBreakpoints();

  const isShowSensitiveInfo = useSelector(isShowSensitiveInfoSelector);

  const { stakedAssets } = useCalculateStakedItem(resultItem);

  const NO_ASSETS = [
    { label: intl.formatMessage({ id: 'NoAssets' }), percent: 100 },
  ];

  const isAssetsExist = !isEmpty(stakedAssets);

  const chartData = isAssetsExist ? stakedAssets : NO_ASSETS;

  const iconSize = isMobile ? 85 : 92;

  return (
    <>
      {isAssetsExist && isShowSensitiveInfo ? (
        <div className="cursor-pointer mb-6">
          <PieChart
            chartId={chartId}
            data={chartData}
            colors={PIE_CHART_COLORS}
            height={iconSize}
            width={iconSize}
          />
        </div>
      ) : (
        <NoCoinsPieChartSVG
          width={iconSize}
          height={iconSize}
          className="mb-6"
        />
      )}

      {!isAssetsExist && (
        <span className="text-white text-end text-md-start text-xl-end">
          <FormattedMessage id="NoDataToBuildAGraph" />
        </span>
      )}
    </>
  );
};

export default memo(StakedDiagram);
