import { CIQ } from '@libs/chartiq/Chart';

import { format } from '@utils/numbers';

import { api } from '@configs';

import { DEFAULT_PRECISION } from '@constants';

const getIntervalFromPeriodicity = (unit, period) => {
  switch (unit) {
    case 'minute':
      return period * 60;
    case 'day':
      return period * 24 * 60 * 60;
    case 'week':
      return period * 7 * 24 * 60 * 60;
    case 'month':
      return period * 31 * 24 * 60 * 60;
    default:
      return 60;
  }
};

const getURL = (symbol, from, to, params) => {
  let url = `${api}/candles/${symbol}?`;

  if (from) {
    url += `&from=${Math.floor(from.valueOf() / 1000)}`;
  }

  if (to) {
    url += `&to=${Math.floor(to.valueOf() / 1000)}`;
  }

  if (params.interval && params.period)
    url += `&interval=${getIntervalFromPeriodicity(
      params.interval,
      params.period,
    )}`;

  if (params.scope) {
    url += `&scope=${params.scope}`;
  }

  url += '&limit=5000';

  return url;
};

// utility function to format data for chart input; given simulator was designed to work with library, very little formatting is needed
const formatChartData = (response, symbol, market) => {
  const data = JSON.parse(response);
  const newQuotes = [];
  const prec = market?.market_precision_format ?? DEFAULT_PRECISION;

  for (let i = 0; i < data.t.length; i++) {
    newQuotes[i] = {};
    newQuotes[i].DT = new Date(data.t[i] * 1000);
    newQuotes[i].Open = 1 * data.o[i];
    newQuotes[i].High = 1 * data.h[i];
    newQuotes[i].Low = 1 * data.l[i];
    newQuotes[i].Close = 1 * data.c[i];
    newQuotes[i].Volume = Number(format(1 * data.v[i], prec, null, false));
  }

  return newQuotes;
};

const getQuoteFeed = (market) => {
  const quoteFeed = {};

  quoteFeed.maxTicks = 50000;
  // called by chart to fetch initial data
  quoteFeed.fetchInitialData = (symbol, from, to, params, cb) => {
    const symbolDisplay = params.symbolObject?.symbolDisplay;
    params.stx.chart.symbolDisplay = symbolDisplay ? symbolDisplay : symbol;

    const queryUrl = getURL(symbol.toLowerCase(), from, to, params);

    CIQ.postAjax(queryUrl, null, (status, response) => {
      if (status === 200) {
        const newQuotes = formatChartData(response, symbol, market);
        cb({
          quotes: newQuotes,
          moreAvailable: true,
          attribution: { source: 'exchange', exchange: 'ParamountDax' },
        });
      } else {
        cb({ error: response ?? status });
      }
    });
  };

  // called by chart to fetch update data
  quoteFeed.fetchUpdateData = (symbol, startDate, params, cb) => {
    const symbolDisplay = params.symbolObject?.symbolDisplay;
    params.stx.chart.symbolDisplay = symbolDisplay ? symbolDisplay : symbol;

    const queryUrl = getURL(symbol.toLowerCase(), startDate, null, params);

    CIQ.postAjax(queryUrl, null, (status, response) => {
      // process the HTTP response from the datafeed

      if (status === 200) {
        // if successful response from datafeed
        const newQuotes = formatChartData(response, symbol, market);

        cb({
          quotes: newQuotes,
          attribution: { source: 'exchange', exchange: 'ParamountDax' },
        }); // return the fetched data
      } else {
        // else error response from datafeed
        cb({ error: response ?? status }); // specify error in callback
      }
    });
  };

  // called by chart to fetch pagination data
  quoteFeed.fetchPaginationData = (
    symbol,
    suggestedStartDate,
    endDate,
    params,
    cb,
  ) => {
    const symbolDisplay = params.symbolObject?.symbolDisplay;
    params.stx.chart.symbolDisplay = symbolDisplay ? symbolDisplay : symbol;

    const queryUrl = getURL(symbol.toLowerCase(), suggestedStartDate, endDate, {
      ...params,
      scope: 'pagination',
    });

    CIQ.postAjax(queryUrl, null, (status, response) => {
      // process the HTTP response from the datafeed
      if (status === 200) {
        // if successful response from datafeed
        const newQuotes = formatChartData(response, symbol, market);

        cb({
          quotes: newQuotes,
          moreAvailable: newQuotes.length > 0,
          // moreAvailable: suggestedStartDate.getTime() > 0,
          upToDate: endDate.getTime() > Date.now(),
          attribution: { source: 'exchange', exchange: 'ParamountDax' },
        }); // return fetched data (and set moreAvailable)
      } else {
        // else error response from datafeed
        cb({ error: response ?? status }); // specify error in callback
      }
    });
  };

  quoteFeed.announceError = function (params, dataCallback) {
    if (params.startDate) {
      // Perhaps some sort of "disconnected" message on screen
    } else if (params.endDate) {
      // Perhaps something indicating the end of the chart
    } else {
      // CIQ.alert("Error fetching quote:" + dataCallback.error); // Probably a not found error?
    }
  };

  return quoteFeed;
};

export default getQuoteFeed;
