import React, { memo } from 'react';

import TruncatedText from '@shared/components/TruncatedText';

import { format } from '@utils/numbers';

import styles from './FeeAmountItem.module.scss';

const FeeAmountItem = ({ feeAmount, coinSymbol }) => {
  return (
    <TruncatedText>
      <span className={Number(feeAmount > 0) ? styles.black : styles.grey}>
        {format(feeAmount, { removeZeroEnd: true })}
      </span>

      <span className={styles.coin_symbol}> {coinSymbol}</span>
    </TruncatedText>
  );
};

export default memo(FeeAmountItem);
