export const excludeReactHookFormProps = (props) => {
  const reactHookFormProps = [
    'handleSubmit',
    'clearErrors',
    'formState',
    'getFieldState',
    'getValues',
    'register',
    'reset',
    'resetField',
    'setError',
    'setFocus',
    'setValue',
    'trigger',
    'unregister',
    'watch',
  ];

  return Object.fromEntries(
    Object.entries(props).filter(
      ([key, _]) => !reactHookFormProps.includes(key),
    ),
  );
};
