import { memo } from 'react';

import { Button } from '@shared/ui';

const ErrorPage = ({ clearError }) => (
  <div className="page-container d-flex align-items-center justify-content-center bg-color-white">
    <h1 className="fw-700 mb-24 color-black">Something went wrong</h1>

    <Button variant="outlined" onClick={clearError}>
      Reload
    </Button>
  </div>
);

export default memo(ErrorPage);
