import { useSelector } from 'react-redux';

import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import {
  favouritePairsNamesSelector,
  useGetFavouritesPairsQuery,
} from '../profileAPI';

const useGetFavouritesPairsQueryResult = (args, options = {}) => {
  const isGuest = useSelector(isGuestUserSelector);

  const { favouritePairs, isSuccess } = useGetFavouritesPairsQuery(undefined, {
    ...options,
    skip: isGuest || options.skip,
    selectFromResult: (result) => ({
      ...result,
      favouritePairs: favouritePairsNamesSelector(result),
    }),
  });

  return {
    favouritePairs,
    isFavouritesPairsSuccess: isSuccess,
  };
};

export default useGetFavouritesPairsQueryResult;
