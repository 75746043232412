import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import classnames from 'classnames';
import reactFastCompare from 'react-fast-compare';

import client from '@api/client';
import subscriptions from '@api/ws/subscriptions';

import {
  isCoinExistsInPair,
  groupMarketDepthData,
  normalizeMarketDepthData,
} from '@utils';
import { format, scale } from '@utils/numbers';

import { ORDER_BOOK_TABLE_SIDES, PERCENT_PRECISION } from '@constants';

export const marketsAPI = createApi({
  reducerPath: 'marketsAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    getMarketsInfo: builder.query({
      query: () => 'markets',
      transformResponse: (response) =>
        response
          .filter((marketInfo) => marketInfo.status === 'active')
          .sort((a, b) => new Intl.Collator().compare(a.id, b.id)),
    }),
    streamMarketStatistics: builder.query({
      queryFn: () => ({ data: undefined }),
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded;

          subscriptions.subscribeMarketStatistics(({ data, reason }) => {
            updateCachedData((draftState) => {
              if (reason === 'error') {
                return null;
              }

              if (reason === 'unsubscribe') {
                return draftState;
              }

              if (!draftState || !reactFastCompare(draftState[data.m], data)) {
                return { ...draftState, [data.m]: data };
              }
            });
          });
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        await cacheEntryRemoved;

        subscriptions.unsubscribeMarketStatistics();
      },
    }),
    streamMarketDepth: builder.query({
      queryFn: () => ({ data: undefined }),
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(
        marketId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded;

          subscriptions.subscribeMarketDepth(
            marketId,
            ({ data, reason }) => {
              updateCachedData((draftState) => {
                if (reason === 'error') {
                  return null;
                }

                if (reason === 'unsubscribe') {
                  return draftState;
                }

                if (
                  !draftState ||
                  !reactFastCompare(draftState.bids, data) ||
                  !reactFastCompare(draftState.asks, data)
                ) {
                  return { ...data, marketId };
                }
              });
            },
            { reconnect: true },
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        await cacheEntryRemoved;

        // subscriptions.unsubscribeMarketDepth();
      },
    }),
  }),
});

// SELECTORS
const defaultMarketsInfo = [];
const defaultMarketsStatistic = {};
const defaultMarketDepth = [];
const defaultMiddleMarketPrice = 0;
const defaultMinVolume = 0;
const defaultMaxVolume = 0;
const defaultMarketDepthId = '';
const defaultTableOrderBook = [];

const calcAverageOrdersData = (
  data,
  index,
  side,
  pricePrecision,
  marketInfo,
) => {
  if (!data.length) return;

  let avgPrice = 0;
  let totalAmount = 0;
  let priceSum = 0;
  let priceSumByAmount = 0;

  if (side === 'sell') {
    for (let i = index; i < data.length; i++) {
      priceSum += parseFloat(data[i]?.[0]);
      totalAmount += parseFloat(data[i]?.[1]);
      priceSumByAmount += parseFloat(data[i]?.[0] * data[i]?.[1]);
    }
    avgPrice = priceSum / (data.length - index);
  } else if (side === 'buy') {
    for (let i = 0; i <= index; i++) {
      priceSum += parseFloat(data[i]?.[0]);
      totalAmount += parseFloat(data[i]?.[1]);
      priceSumByAmount += parseFloat(data[i]?.[0] * data[i]?.[1]);
    }
    avgPrice = priceSum / (index + 1);
  }

  const avgAndTotalData = {
    avgPrice: format(avgPrice, {
      precision: pricePrecision,
    }),
    totalAmount: format(totalAmount, {
      precision: marketInfo?.market_precision_format,
    }),
    priceSumByAmount: format(priceSumByAmount, {
      precision: 2,
      short: true,
    }),
  };

  return avgAndTotalData;
};

const marketsInfoSelector = (result) => result.data ?? defaultMarketsInfo;

const marketsStatisticSelector = (result) =>
  result.data ?? defaultMarketsStatistic;

export const streamMarketsStatisticByMarketIdSelector = createSelector(
  marketsStatisticSelector,
  (result, marketId) => marketId,
  (result, marketId) => result[marketId] ?? defaultMarketsStatistic,
);

export const marketStatisticsByMarketIdSelector = createSelector(
  marketsStatisticSelector,
  (result, marketId) => marketId,
  (marketStatistics, marketId) =>
    marketStatistics?.[marketId] ?? defaultMarketsStatistic,
);

export const marketDepthIdSelector = (result) =>
  result.data?.marketId ?? defaultMarketDepthId;

export const middleMarketPriceSelector = createSelector(
  (result) => result.data,
  (marketDepthInfo) => {
    if (!marketDepthInfo) {
      return defaultMiddleMarketPrice;
    }

    const bestBidsPrice = marketDepthInfo.bids.length
      ? Number(marketDepthInfo.bids[marketDepthInfo.bids.length - 1][0])
      : 0;
    const bestAsksPrice = marketDepthInfo.asks.length
      ? Number(marketDepthInfo.asks[marketDepthInfo.asks.length - 1][0])
      : 0;

    return (bestBidsPrice + bestAsksPrice) / 2;
  },
);

export const marketDepthMaxVolume = createSelector(
  (result) => result.data,
  (marketDepthInfo, pricePrecision) => pricePrecision,
  (marketDepthInfo, pricePrecision, selectedSide) => selectedSide,
  (marketDepthInfo, pricePrecision, selectedSide) => {
    if (!marketDepthInfo) {
      return defaultMinVolume;
    }

    const data = [];

    // group duplicates by price
    switch (selectedSide) {
      case ORDER_BOOK_TABLE_SIDES.BUY:
        data.push(
          ...groupMarketDepthData(marketDepthInfo.bids, pricePrecision),
        );
        break;
      case ORDER_BOOK_TABLE_SIDES.SELL:
        data.push(
          ...groupMarketDepthData(marketDepthInfo.asks, pricePrecision),
        );
        break;
      default:
        data.push(
          ...groupMarketDepthData(marketDepthInfo.bids, pricePrecision),
          ...groupMarketDepthData(marketDepthInfo.asks, pricePrecision),
        );
    }

    return Math.max(...data.map((item) => Number.parseFloat(item[1])));
  },
);

export const marketDepthMinVolume = createSelector(
  (result) => result.data,
  (marketDepthInfo, pricePrecision) => pricePrecision,
  (marketDepthInfo, pricePrecision, selectedSide) => selectedSide,
  (marketDepthInfo, pricePrecision, selectedSide) => {
    if (!marketDepthInfo) {
      return defaultMinVolume;
    }

    const data = [];

    // group duplicates by price
    switch (selectedSide) {
      case ORDER_BOOK_TABLE_SIDES.BUY:
        data.push(
          ...groupMarketDepthData(marketDepthInfo.bids, pricePrecision),
        );
        break;
      case ORDER_BOOK_TABLE_SIDES.SELL:
        data.push(
          ...groupMarketDepthData(marketDepthInfo.asks, pricePrecision),
        );
        break;
      default:
        data.push(
          ...groupMarketDepthData(marketDepthInfo.bids, pricePrecision),
          ...groupMarketDepthData(marketDepthInfo.asks, pricePrecision),
        );
    }

    return Math.min(...data.map((item) => Number.parseFloat(item[1])));
  },
);

export const marketDepthSelector = createSelector(
  (result) => result.data,
  (marketDepthInfo) => {
    if (!marketDepthInfo) {
      return defaultMarketDepth;
    }

    // group duplicates by price
    const groupedBidsData = groupMarketDepthData(marketDepthInfo.bids);
    const groupedAsksData = groupMarketDepthData(marketDepthInfo.asks);

    let normalizedBidsData = normalizeMarketDepthData(
      groupedBidsData,
      'bids',
      true,
    );
    let normalizedAsksData = normalizeMarketDepthData(
      groupedAsksData,
      'asks',
      false,
    );

    if (normalizedBidsData.length < normalizedAsksData.length) {
      normalizedAsksData = normalizedAsksData.slice(
        0,
        normalizedBidsData.length,
      );
    }

    if (normalizedBidsData.length > normalizedAsksData.length) {
      normalizedBidsData = normalizedBidsData.slice(-normalizedAsksData.length);
    }

    return [...normalizedBidsData, ...normalizedAsksData];
  },
);

export const marketsForTableOrderBookSelector = createSelector(
  marketsInfoSelector,
  (marketDepthInfo, showOrdersFromBook) => showOrdersFromBook,
  (marketDepthInfo, showOrdersFromBook, side) => side,
  (marketInfo, showOrdersFromBook, side, maxVolume) => maxVolume,
  (marketInfo, showOrdersFromBook, side, maxVolume, minVolume) => minVolume,
  (marketInfo, showOrdersFromBook, side, maxVolume, minVolume, hoveredId) =>
    hoveredId,
  (
    marketInfo,
    showOrdersFromBook,
    side,
    maxVolume,
    minVolume,
    hoveredId,
    progressBarRowWidth,
  ) => progressBarRowWidth,
  (
    marketDepthInfo,
    showOrdersFromBook,
    side,
    maxVolume,
    minVolume,
    hoveredId,
    progressBarRowWidth,
    tooltipOnLeft,
  ) => tooltipOnLeft,
  (
    marketDepthInfo,
    showOrdersFromBook,
    side,
    maxVolume,
    minVolume,
    hoveredId,
    progressBarRowWidth,
    tooltipOnLeft,
    pricePrecision,
  ) => pricePrecision,
  (
    marketDepthInfo,
    showOrdersFromBook,
    side,
    maxVolume,
    minVolume,
    hoveredId,
    progressBarRowWidth,
    tooltipOnLeft,
    pricePrecision,
    showAverageAndTotal,
  ) => showAverageAndTotal,
  (
    marketDepthInfo,
    showOrdersFromBook,
    side,
    maxVolume,
    minVolume,
    hoveredId,
    progressBarRowWidth,
    tooltipOnLeft,
    pricePrecision,
    showAverageAndTotal,
    marketInfo,
  ) => marketInfo,
  (
    marketDepthInfo,
    showOrdersFromBook,
    side,
    maxVolume,
    minVolume,
    hoveredId,
    progressBarRowWidth,
    tooltipOnLeft,
    pricePrecision,
    showAverageAndTotal,
    marketInfo,
  ) => {
    if (
      (side === 'sell' && !marketDepthInfo?.asks?.length) ||
      (side === 'buy' && !marketDepthInfo?.bids?.length)
    ) {
      return defaultTableOrderBook;
    }

    let orders = marketDepthInfo.asks;

    if (side === 'buy') {
      orders = marketDepthInfo.bids;
    }

    // make correct order
    orders = [...orders].sort((a, b) => b[0] - a[0]);

    // group duplicates by price
    const groupedOrders = groupMarketDepthData(orders, pricePrecision);

    const averageData =
      hoveredId !== -1
        ? calcAverageOrdersData(
            groupedOrders,
            hoveredId,
            side,
            pricePrecision,
            marketInfo,
          )
        : undefined;

    return groupedOrders.map((order, i) => {
      const MAX_AMOUNT_VALUE_FOR_TRANSPARENT = 1;
      const MAX_OPACITY_VALUE_FOR_AMOUNT = 1;
      const MIN_OPACITY_VALUE_FOR_AMOUNT = 0.3;
      const price = order[0];
      const amount = order[1];
      const total = price * amount;
      const progressBarPercentage = (amount / maxVolume) * progressBarRowWidth;
      let amountOpacity = MIN_OPACITY_VALUE_FOR_AMOUNT;

      if (amount < MAX_AMOUNT_VALUE_FOR_TRANSPARENT) {
        amountOpacity = scale(
          amount,
          minVolume,
          MAX_AMOUNT_VALUE_FOR_TRANSPARENT,
          MIN_OPACITY_VALUE_FOR_AMOUNT,
          MAX_OPACITY_VALUE_FOR_AMOUNT,
        );
      }

      const isLastHovered = showAverageAndTotal && hoveredId === i;
      const isPrevHovered =
        showAverageAndTotal &&
        hoveredId !== -1 &&
        (side === 'buy' ? hoveredId > i : hoveredId < i);

      return {
        row_data: {
          isClickable: showOrdersFromBook,
          tooltipData: isLastHovered ? { ...averageData, side } : undefined,
          className: classnames('order-book-table__body-row', {
            'order-book-table__body-row__prev-item': isPrevHovered,
            'order-book-table__body-row__sell': side === 'sell',
            'order-book-table__body-row__sell--hover':
              isLastHovered && side === 'sell',
            'order-book-table__body-row__buy': side === 'buy',
            'order-book-table__body-row__buy--hover':
              isLastHovered && side === 'buy',
          }),
        },
        price: {
          side,
          value: format(price, { precision: pricePrecision }),
          tooltipData: tooltipOnLeft && isLastHovered ? averageData : undefined,
          numberValue: Number(price),
        },
        amount: {
          side,
          value: format(amount, {
            precision: marketInfo?.market_precision_format,
          }),
          numberValue: Number(amount),
          opacity: amountOpacity,
        },
        total: {
          side,
          numberValue: format(total, {
            precision: marketInfo?.quote_precision_format,
            returnNumber: true,
          }),
          shortNumberValue: format(total, {
            precision: 2,
            short: true,
          }),
          value: format(total, {
            precision: marketInfo?.quote_precision_format,
          }),
          progressBarWidth: progressBarPercentage,
        },
      };
    });
  },
);

export const marketsForTableSelector = createSelector(
  marketsInfoSelector,
  (marketsInfo, marketsStatistic) => marketsStatistic,
  (marketsInfo, marketsStatistic, favouritePairs) => favouritePairs,
  (marketsInfo, marketsStatistic, favouritePairs, showFavouritePairs) =>
    showFavouritePairs,
  (
    marketsInfo,
    marketsStatistic,
    favouritePairs,
    showFavouritePairs,
    marketCoinSymbol,
  ) => marketCoinSymbol,
  (
    marketsInfo,
    marketsStatistic,
    favouritePairs,
    showFavouritePairs,
    marketCoinSymbol,
    searchValue,
  ) => searchValue,
  (
    marketsInfo,
    marketsStatistic,
    favouritePairs,
    showFavouritePairs,
    marketCoinSymbol,
    searchValue,
  ) =>
    marketsInfo
      .filter((marketInfo) => {
        if (showFavouritePairs) {
          const isFavouriteMarket = favouritePairs.includes(marketInfo.id);

          if (marketCoinSymbol) {
            return (
              isFavouriteMarket &&
              isCoinExistsInPair(marketInfo.id, marketCoinSymbol)
            );
          }

          return isFavouriteMarket;
        }

        if (marketCoinSymbol) {
          return isCoinExistsInPair(marketInfo.id, marketCoinSymbol);
        } else if (searchValue) {
          return marketInfo.id
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        }

        return true;
      })
      .map((marketInfo) => {
        let changePercent = Number(marketsStatistic?.[marketInfo.id]?.ch);
        const isDown = changePercent < 0;
        const isNeutral = isNaN(changePercent) || changePercent === 0;

        changePercent = `${isDown ? '' : '+'}${format(changePercent, {
          precision: PERCENT_PRECISION,
        })}%`;

        let className = isDown ? 'color-red' : 'color-green';

        if (isNeutral) {
          className = '';
          changePercent = '-';
        }

        const isFavourite = favouritePairs.includes(marketInfo.id);

        return {
          favourite_info: { isFavourite, marketId: marketInfo.id },
          symbol: marketInfo.name,
          price: {
            value: format(marketsStatistic?.[marketInfo.id]?.c, {
              precision: marketInfo.quote_precision_format,
            }),
            className,
          },
          '24h': { value: changePercent, className },
        };
      }),
);

export const {
  useGetMarketsInfoQuery,
  useLazyGetMarketsInfoQuery,
  useStreamMarketStatisticsQuery,
  useStreamMarketDepthQuery,
} = marketsAPI;
