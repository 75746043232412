import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';

import { ItemTitle, TableFilters } from '@shared/components';
import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';
import { SelectController } from '@shared/ui/Select/Select';

import { VisaCardTransactionsTable } from '@pages/WalletsPage/VisaCardDetails/components';

import useBreakpoints from '@hooks/useBreakpoints';

import s from './VisaCardTransactions.module.scss';

const VisaCardTransactions = () => {
  const defaultValues = {
    [TABLE_FILTERS_NAMES.SEARCHBAR]: '',
    [TABLE_FILTERS_NAMES.DATE_PICKER]: '',
  };

  const methods = useForm({ defaultValues });

  const typeOptions = useMemo(
    () => [
      { label: 'All', value: 'all' },
      { label: 'Transfer', value: 'transfer' },
      { label: 'Payment', value: 'payment' },
      { label: 'Services', value: 'services' },
    ],
    [],
  );

  const { isTabletDown } = useBreakpoints();

  // TODO: change when data of transactions will be ready
  const data = [];

  const handleFiltersChange = (data) => {
    console.log('handleFiltersChange');
  };

  const handleControlsClick = (data) => {
    console.log('handleControlsClick', data);
  };

  return (
    <div>
      <ItemTitle
        title={<FormattedMessage id="Transactions" />}
        isShowArrow={false}
      />
      <div className={s.filters}>
        <form>
          {!isEmpty(data) && (
            <TableFilters
              searchbar
              datepicker
              exportPdf
              exportXsl
              onFiltersChange={handleFiltersChange}
              onControlsClick={handleControlsClick}
              showAdvancedFilterButton={isTabletDown}
              isCustomRangeButtonDisplayed={false}
              {...methods}
            >
              {({ control, onFiltersChange, getValues }) => (
                <div className="d-flex align-items-center gap-12">
                  <label htmlFor="select_name">
                    <FormattedMessage id="Type" /> :
                  </label>
                  <div style={{ minWidth: '100px' }}>
                    <SelectController
                      control={control}
                      bordered
                      defaultValue={typeOptions[0]}
                      name="type"
                      onChange={() => onFiltersChange(getValues())}
                      options={typeOptions}
                      size="m"
                    />
                  </div>
                </div>
              )}
            </TableFilters>
          )}
        </form>
      </div>
      <div className="mt-30">
        <VisaCardTransactionsTable />
      </div>
    </div>
  );
};

export default VisaCardTransactions;
