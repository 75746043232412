import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import cx from 'classnames';
import { toast } from 'react-toastify';

import { MobileSecurityIndex } from '@shared/components';
import { ACCOUNT_SECURITY_SETTINGS } from '@shared/constants/account.constants';
import { Button } from '@shared/ui';

import Loader from '@components/Loader';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';
import {
  usePostEnableDetectIpMutation,
  usePutDisableDetectIpMutation,
} from '@store/api/profileAPI';

import useBreakpoints from '@hooks/useBreakpoints';

import { SecurityCard, UserInfoHeader } from '../components';
import { useIsShowAccountHeader } from '../hooks';

import { MySecurityLevelWidget } from './MySecurityLevelWidget';

import styles from './Security.module.scss';

const Security = () => {
  const isShowSecurity = useIsShowAccountHeader();
  const intl = useIntl();
  const { securitySettings } = useGetProfileInfoQueryResult();
  const navigate = useNavigate();
  const { isLaptopUp, isTabletDown, isTablet, isMobile } = useBreakpoints();

  const [enableDetectIP] = usePostEnableDetectIpMutation();
  const [disableDetectIP] = usePutDisableDetectIpMutation();

  const handleClick = async (key, isActive) => {
    if (key === ACCOUNT_SECURITY_SETTINGS.isDetectIpChange) {
      const mutation = isActive ? disableDetectIP : enableDetectIP;

      await mutation().unwrap();

      toast.success(
        intl.formatMessage({
          id: isActive
            ? 'DetectIpAddressChangeDisabled'
            : 'DetectIpAddressChangeEnabled',
        }),
      );
    }
  };

  const handleMobileClick = (key, isActive, linkTo) => {
    if (!isTabletDown) return;

    handleClick(key, isActive);

    if (linkTo) {
      navigate(linkTo);
    }
  };

  return (
    <div className={styles.security}>
      <UserInfoHeader />

      {isShowSecurity ? (
        <div className="row gy-30">
          <div
            className={cx(
              'col-12 col-md-12 col-xl-10 order-2 order-xl-1',
              styles.cards,
            )}
          >
            {securitySettings.map(
              (
                {
                  key,
                  isActive,
                  title,
                  shortTitle,
                  linkTo,
                  disableButtonText,
                  enableButtonText,
                  description,
                },
                index,
              ) => (
                <div key={key}>
                  <SecurityCard
                    isActive={isActive}
                    index={index + 1}
                    customStyles={styles}
                    title={isLaptopUp && <FormattedMessage id={title} />}
                    indexChildren={
                      <>
                        {isTablet && (
                          <span className={styles.mobile_title}>
                            <FormattedMessage id={title} />
                          </span>
                        )}

                        {isMobile && (
                          <MobileSecurityIndex
                            linkTo={linkTo}
                            isActive={isActive}
                            onClick={() =>
                              handleMobileClick(key, isActive, linkTo)
                            }
                            shortTitle={shortTitle}
                            title={title}
                          />
                        )}
                      </>
                    }
                    description={
                      !isMobile && (
                        <div className={styles.card_description}>
                          <div>
                            <FormattedMessage id={description} />
                          </div>

                          {isTabletDown && (
                            <Button
                              type="button"
                              variant="outlined"
                              navigation={{ to: linkTo }}
                            >
                              {isActive ? disableButtonText : enableButtonText}
                            </Button>
                          )}
                        </div>
                      )
                    }
                    linkTo={linkTo}
                    onClick={() => handleClick(key, isActive)}
                    buttonText={
                      isLaptopUp && (
                        <FormattedMessage
                          id={isActive ? disableButtonText : enableButtonText}
                        />
                      )
                    }
                  />
                </div>
              ),
            )}
          </div>

          <div
            className={cx(
              'col-12 col-md-12 col-xl-2 order-1 order-xl-2',
              styles.widget,
            )}
          >
            <MySecurityLevelWidget
              title={<FormattedMessage id="MySecurityLevel" />}
            />
          </div>
        </div>
      ) : (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default memo(Security);
