import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import dateFormat from 'date-fns/format';

import { YEAR_MONTH_DAY_TIME_FORMAT } from '@shared/constants';

import styles from './LastLoginsTableColumns.module.scss';

const { accessor } = createColumnHelper();

const LastLoginsTableColumns = () => [
  accessor('idAddress', {
    header: () => <FormattedMessage id="IPAddress" />,
    cell: (info) => {
      return <span className="fs-14 fw-600">{info.getValue()}</span>;
    },
    enableSorting: false,
    headerAlign: 'start',
    cellAlign: 'start',
  }),
  accessor('device', {
    header: () => <FormattedMessage id="Device" />,
    cell: (info) => {
      const device = info.getValue();
      return (
        <span className="fs-14 fw-500">
          {device?.Name}, {device?.OS && device.OS}{' '}
          {device?.OSVersion && <>({device?.OSVersion})</>}
        </span>
      );
    },
    enableSorting: false,
    headerAlign: 'start',
    cellAlign: 'start',
  }),
  accessor('country', {
    header: () => <FormattedMessage id="Country" />,
    cell: (info) => <span className="fs-14 fw-600">{info.getValue()}</span>,
    enableSorting: false,
    headerAlign: 'start',
    cellAlign: 'start',
  }),
  accessor('date', {
    header: () => <FormattedMessage id="DateAndTime" />,
    cell: (info) => (
      <span className={classNames(styles.thin_text, 'fs-14')}>
        {dateFormat(new Date(info.getValue()), YEAR_MONTH_DAY_TIME_FORMAT)}
      </span>
    ),
    enableSorting: false,
    headerAlign: 'end',
    cellAlign: 'end',
  }),
];

export default LastLoginsTableColumns;
