import cx from 'classnames';

import { Loader } from '@shared/ui';

import s from './TableIsLoading.module.scss';

const TableIsLoading = ({ inversion }) => {
  return (
    <div
      className={cx(s.table_is_loading, {
        [s.inversion]: inversion,
      })}
    >
      <Loader />
    </div>
  );
};

export default TableIsLoading;
