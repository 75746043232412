import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Card, FORM_ACTION_ORIENTATION } from '@shared/components';
import FormActions from '@shared/components/FormActions/FormActions';
import { PasswordInputController } from '@shared/ui';
import { validatePassword } from '@shared/validations';

import { useProfileMutations } from '@api/hooks';

import styles from './DisableTradePassword.module.scss';

const DisableTradePassword = () => {
  const methods = useForm({ defaultValues: { tradePassword: '' } });
  const { handleSubmit } = methods;

  const { onDisableTradePassword, isDisableTradePasswordLoading } =
    useProfileMutations();

  const intl = useIntl();

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    size: 'l',
  };

  const handleFormSubmit = ({ tradePassword }) => {
    onDisableTradePassword({ tradePassword });
  };

  return (
    <div className={cx(styles.security_card, 'security-card')}>
      <Card
        customStyles={styles}
        title={<FormattedMessage id="DisableTradePassword" />}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <PasswordInputController
            name="tradePassword"
            label={`${intl.formatMessage({
              id: 'EnterTradePasswordToDisable',
            })}:`}
            placeholder={intl.formatMessage({
              id: 'EnterPassword',
            })}
            rules={{
              required: intl.formatMessage({ id: 'PasswordIsRequired' }),
              validate: {
                format: validatePassword(intl),
              },
            }}
            {...commonFieldProps}
            {...methods}
          />

          <div className="mt-22">
            <FormActions
              submitText={<FormattedMessage id="Disable" />}
              submitButtonProps={{
                color: 'dangerous',
                isLoading: isDisableTradePasswordLoading,
                disabled: isDisableTradePasswordLoading,
              }}
              cancelButtonProps={{
                variant: 'text',
              }}
              orientation={FORM_ACTION_ORIENTATION.VERTICAL}
            />
          </div>
        </form>
      </Card>
    </div>
  );
};

export default memo(DisableTradePassword);
