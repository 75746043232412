import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useForm, useWatch } from 'react-hook-form';

import SideBar from '@components/SideBar';

import { useCoinsInfo } from '@store/api/hooks';

import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { sidebarIds } from '@constants';

import { ConfirmUnstakeForm, UnstakeForm } from './components';

import styles from './UnstakeItemSidebar.module.scss';

const UnstakeItemSidebar = ({ toggleStakingSideBar }) => {
  const [isConfirmUnstaking, setIsConfirmUnstaking] = useState(false);

  const { sidebar, isOpen } = useSidebar(sidebarIds.WALLETS_UNSTAKE_ITEM);

  const amount = sidebar?.params?.amount;
  const currency = sidebar?.params?.coinSymbol;

  const { coinInfo } = useCoinsInfo(
    { coinSymbol: currency },
    { skip: !isOpen },
  );

  const precision = coinInfo?.digits;

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      amount,
      currency,
    },
  });

  const { control, handleSubmit, setValue, reset } = methods;

  const watchAmount = useWatch({ control, name: 'amount' });

  useEffect(() => {
    if (isOpen) {
      setValue('amount', amount);
      setValue('currency', currency);
    } else {
      reset();
      setIsConfirmUnstaking(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const setMaxValueHandler = () => {
    const formattedAmount = format(amount, { precision, removeZeroEnd: true });

    setValue('amount', formattedAmount);
  };

  const onBackHandler = () => {
    setIsConfirmUnstaking(false);
  };

  const navigateToConfirmUnstaking = () => {
    setIsConfirmUnstaking(true);
  };

  const cancelUnstakingItem = () => {
    toggleStakingSideBar();
    setIsConfirmUnstaking(false);
  };

  const onSubmit = async (values) => {
    console.log(currency);
    console.log(values);

    // toggleStakingSideBar();

    // setIsConfirmUnstaking(false);

    // toast.success(
    //   intl.formatMessage({
    //     id: 'Success',
    //   }),
    // );
  };

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={toggleStakingSideBar}
      withCloseButton
      title={
        isConfirmUnstaking ? (
          <span className="text-capitalize">
            <FormattedMessage id="ConfirmUnstaking" />
          </span>
        ) : (
          <FormattedMessage id="Unstake" />
        )
      }
      withBackButton={isConfirmUnstaking}
      onBack={onBackHandler}
      customStyles={styles}
    >
      {isConfirmUnstaking ? (
        <ConfirmUnstakeForm
          amount={amount}
          cancelUnstakingItem={cancelUnstakingItem}
          handleSubmit={handleSubmit}
          methods={methods}
          onSubmit={onSubmit}
          watchAmount={watchAmount}
          currency={currency}
        />
      ) : (
        <UnstakeForm
          amount={amount}
          cancelUnstakingItem={cancelUnstakingItem}
          currency={currency}
          handleSubmit={handleSubmit}
          methods={methods}
          navigateToConfirmUnstaking={navigateToConfirmUnstaking}
          setMaxValueHandler={setMaxValueHandler}
        />
      )}
    </SideBar>
  );
};

export default memo(UnstakeItemSidebar);
