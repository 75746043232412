import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import FavouriteMarketButton from '@pages/TerminalPage/components/GridLayout/blocks/Markets/components/TabContent/components/FavouriteMarketButton/FavouriteMarketButton';

import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import { useToggleFavouritePairMutation } from '@api/profileAPI';

const FavouriteMarketButtonCell = ({ isFavourite, marketId }) => {
  const isGuest = useSelector(isGuestUserSelector);

  const [toggleFavouritePair] = useToggleFavouritePairMutation();

  const handleStarClick = (e) => {
    e.stopPropagation();

    if (!isGuest) toggleFavouritePair({ pair: marketId });
  };

  return (
    <FavouriteMarketButton
      isGuest={isGuest}
      isActive={isFavourite}
      width={24}
      height={20}
      onClick={handleStarClick}
    />
  );
};

export default memo(FavouriteMarketButtonCell);
