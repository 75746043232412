import React, { memo } from 'react';

import Marquee from 'react-fast-marquee';

import Loader from '@components/Loader';

import { useMarketsInfo } from '@store/api/hooks';

import RunningLineItem from './RunningLineItem';

const RunningLine = (props) => {
  const { marketsInfoMap, isMarketsInfoSuccess } = useMarketsInfo();

  if (!isMarketsInfoSuccess) {
    return (
      <div className="d-flex flex-1 justify-content-center">
        <Loader xs />
      </div>
    );
  }

  return (
    <div className="running-line">
      <Marquee speed={30} gradient={false} {...props}>
        {marketsInfoMap.map((market) => (
          <RunningLineItem key={market.id} item={market} />
        ))}
      </Marquee>
    </div>
  );
};

export default memo(RunningLine);
