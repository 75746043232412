import { forwardRef, useId } from 'react';

import cx from 'classnames';

import { FormController } from '@shared/components';

import s from './Switch.module.scss';

const Switch = forwardRef(
  ({ customStyles = {}, inversion, size = 'm', isInvalid, ...props }, ref) => {
    const id = useId();

    return (
      <>
        <input
          id={id}
          ref={ref}
          type="checkbox"
          className={s.switch_checkbox}
          checked={props?.checked || props?.value}
          {...props}
        />
        <label
          htmlFor={id}
          className={cx(
            s.switch_label,
            s[size],
            {
              [s.inversion]: inversion,
              [s.disabled]: props.disabled,
              [s.invalid]: isInvalid,
            },
            customStyles.switch_label,
          )}
        >
          <span className={cx(s.switch_button, customStyles.switch_button)} />
        </label>
      </>
    );
  },
);

export default Switch;

export const SwitchController = (props) => {
  return <FormController component={Switch} {...props} />;
};
