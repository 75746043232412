import { forwardRef, useRef, useState } from 'react';

import debounce from 'lodash/debounce';

import { Input } from '@shared/ui';

import { ReactComponent as SearchIconSVG } from '@icons/search-icon.svg';

import s from './SearchBar.module.scss';

const SearchBar = forwardRef(({ onSearch }, ref) => {
  const [searchKeyword, setSearchKeyword] = useState('');

  const debouncedSearch = useRef(
    debounce((value) => {
      onSearch(value);
    }, 300),
  ).current;

  const handleSearch = (value) => {
    setSearchKeyword(value);
    debouncedSearch(value);
  };

  return (
    <Input
      ref={ref}
      value={searchKeyword}
      onChange={handleSearch}
      prefix={<SearchIconSVG />}
      rounded
      inversion
      customStyles={s}
    />
  );
});

export default SearchBar;
