import { memo } from 'react';

import { ProgressBar } from '@shared/ui';

import { useSecurityLevelColor } from '@pages/AccountPage/hooks';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

const SecurityLevelProgressBar = ({ height = 4, inversion }) => {
  const { securityLevelPercent } = useGetProfileInfoQueryResult();

  const { color } = useSecurityLevelColor();

  return (
    <ProgressBar
      isPill
      percentages={securityLevelPercent}
      color={color}
      height={height}
      isShowPercents={false}
      isShowOnZeroPercent={true}
      inversion={inversion}
    />
  );
};

export default memo(SecurityLevelProgressBar);
