import { format } from '@utils/numbers';

export const getActivationPriceFieldRules = (
  intl,
  currentPrice,
  currentMarket,
  buyTab,
) => {
  return {
    validate: {
      currentPrice: (v) => {
        // we can place trailing stop without activation price
        if (!v) {
          return true;
        }

        const currentPriceValue = Number(currentPrice) || 0;

        if (buyTab && Number(v) >= currentPriceValue) {
          return intl.formatMessage(
            { id: 'PriceLowerThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        if (!buyTab && Number(v) <= currentPriceValue) {
          return intl.formatMessage(
            { id: 'PriceHigherThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        return true;
      },
    },
  };
};

export const getCallbackRateFieldRules = (
  intl,
  currentPrice,
  currentMarket,
  callbackTypeWatch,
) => {
  const percentageTypeRules = {
    required: intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      max: (v) => {
        if (5 < Number(v)) {
          return intl.formatMessage(
            {
              id: 'MaxCallbackRatePercentage',
            },
            {
              percent: 5,
            },
          );
        }

        return true;
      },
    },
  };

  const absoluteTypeRules = {
    required: intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      max: (v) => {
        const currentPriceValue = Number(currentPrice) || 0;
        const percentFromCurrentPrice =
          Number(
            format(currentPriceValue * 0.05, {
              precision: currentMarket.market_precision_format,
              noCommas: true,
              removeZeroEnd: true,
            }),
          ) || 0;

        if (percentFromCurrentPrice < Number(v)) {
          return intl.formatMessage(
            {
              id: 'MaxCallbackRateAbsolute',
            },
            {
              price: format(percentFromCurrentPrice, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }
        return true;
      },
    },
  };

  return callbackTypeWatch === 'percentage'
    ? percentageTypeRules
    : absoluteTypeRules;
};

export const getAmountFieldRules = (intl) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
  };
};
