import React, { memo } from 'react';

import { format } from '@utils/numbers';

import styles from './DepositItem.module.scss';

const DepositItem = ({ deposit, precision }) => {
  const value = format(deposit, {
    precision,
  });

  const numberValue = format(deposit, {
    precision,
    returnNumber: true,
  });

  return (
    <span className={numberValue ? styles.black : styles.grey}>{value}</span>
  );
};

export default memo(DepositItem);
