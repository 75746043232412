import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';
import { ALL } from '@shared/constants';

import { EXPIRED_CONTRACT_FILTER_NAMES } from '@pages/WalletsPage/InstantBonus/components/ExpiredContractsFilters/ExpiredContractsFilters';
import { MY_CONTRACT_FILTER_NAMES } from '@pages/WalletsPage/InstantBonus/components/MyContractsFilters/MyContractsFilters';

export const MY_CONTRACTS_DEFAULT_VALUES = {
  [TABLE_FILTERS_NAMES.SEARCHBAR]: '',
  [MY_CONTRACT_FILTER_NAMES.ASSET]: ALL,
  [MY_CONTRACT_FILTER_NAMES.STATUS]: ALL,
};

export const EXPIRED_CONTRACTS_DEFAULT_VALUES = {
  [TABLE_FILTERS_NAMES.SEARCHBAR]: '',
  [EXPIRED_CONTRACT_FILTER_NAMES.ASSET]: ALL,
  [EXPIRED_CONTRACT_FILTER_NAMES.STATUS]: ALL,
};

export const LTV_PERCENT_PRECISION = 2;
