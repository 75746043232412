import React from 'react';

import BugsnagPluginReact from '@bugsnag/plugin-react';

import { getUserEmail, getUserId } from '@utils';

export const bigsnagConfig = {
  apiKey: '2784cc9b63b340127469d99585c79398',
  plugins: [new BugsnagPluginReact(React)],
  notifyReleaseStages: ['production'],
  releaseStage: process.env.REACT_APP_STAGE,
  onError(event) {
    event.setUser(getUserId(), getUserEmail());
  },
};
