import React, { memo } from 'react';

import classnames from 'classnames';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

const SortIcon = ({ sorting }) => {
  return (
    <div className="d-flex flex-column">
      <DownIconSVG
        className={classnames('up-icon', {
          hidden: sorting,
        })}
        width={6}
        height={7}
      />
      <DownIconSVG
        className={classnames('down-icon', {
          active: sorting?.desc,
        })}
        width={6}
        height={7}
      />
    </div>
  );
};

export default memo(SortIcon);
