import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import dateFormat from 'date-fns/format';

import { CARD_TYPE_MAP, MONTH_YEAR_FORMAT } from '@shared/constants';
import { Button } from '@shared/ui';
import { copyToClipboard } from '@shared/utils';

import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import { format } from '@utils/numbers';

import { CURRENCY_LIST, FIAT_PRECISION } from '@constants';

import { ReactComponent as PRDXLogoIconSVG } from '@images/paramountdax-logo-symbol.svg';
import { ReactComponent as VisaIconSVG } from '@images/visa.svg';

import { ReactComponent as PlusThinIconSVG } from '@icons/plus-thin-icon.svg';
import { ReactComponent as UnlockCardIconSVG } from '@icons/unlock-icon-card.svg';

import s from './VisaCard.module.scss';

const VisaCard = ({ lockedCard }) => {
  const intl = useIntl();

  const [isLockedCard, setIsLockedCard] = useState(lockedCard);
  const [isShowFullCardNumber, setIsShowFullCardNumber] = useState(false);

  const card = useSelector(visaCardSelector);

  const cardData = useMemo(() => {
    if (!card.type) return null;

    return {
      type: card.type,
      type_title: CARD_TYPE_MAP[card.type].intl,
      animalIcon: CARD_TYPE_MAP[card.type].animalIcon,
      balance: card.balance,
      currency: CURRENCY_LIST[2].symbol,
      number: card.number,
      expiredDate: new Date(card.expiredDate).getTime(),
    };
  }, [card]);

  const isCardExist = !!cardData;

  // TODO: uncomment and tweak when backend will be done
  // const isLockedCard = useMemo(() => {
  //   if (!isCardExist) return false;
  //
  //   const today = new Date().getTime();
  //   return today > cardData.expiredDate;
  // }, [isCardExist, cardData]);

  const truncatedCardNumber = useMemo(() => {
    if (!isCardExist) return null;

    return isShowFullCardNumber
      ? cardData.number
      : `**** ${cardData.number.slice(-4)}`;
  }, [cardData?.number, isCardExist, isShowFullCardNumber]);

  useEffect(() => {
    setIsLockedCard(lockedCard);
  }, [lockedCard]);

  const handleUnlockCard = () => {
    setIsLockedCard((prevState) => !prevState);
  };

  const handleShowFullCardNumber = async () => {
    setIsShowFullCardNumber((prevState) => !prevState);
    if (isShowFullCardNumber) return;

    await copyToClipboard({ intl, text: card.number });
  };

  return (
    <div className={s.visa_card}>
      {isLockedCard && (
        <div className={s.visa_card__locked}>
          <Button inversion onClick={handleUnlockCard} customStyles={s}>
            <UnlockCardIconSVG />
            <FormattedMessage id="UnlockCard" />
          </Button>
        </div>
      )}
      {!isCardExist && (
        <div className={s.visa_card__plus_icon}>
          <PlusThinIconSVG />
        </div>
      )}
      {isCardExist && (
        <div className={s.visa_card__animal_bg}>
          <cardData.animalIcon />
        </div>
      )}
      <div className={s.visa_card__data}>
        <div className={s.visa_card__header}>
          {isCardExist && (
            <>
              <PRDXLogoIconSVG className={s.prdx_logo} />
              <span className={s.header_text}>
                <FormattedMessage id="Virtual" />
              </span>
            </>
          )}
        </div>
        <div className={s.visa_card__content}>
          {isCardExist && (
            <div>
              <div className={s.balance}>
                <span>
                  {format(cardData.balance, { precision: FIAT_PRECISION })}
                </span>
                <span className={s.balance__currency}>{cardData.currency}</span>
              </div>
              <div className={s.card_info}>
                <FormattedMessage id={cardData.type_title} />
                <div
                  className={s.card_info__number}
                  onClick={handleShowFullCardNumber}
                >
                  <span>{truncatedCardNumber}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={s.visa_card__footer}>
          {isCardExist && (
            <div className="d-xl-block d-flex align-items-center gap-4">
              <div className={s.expired_text}>
                <FormattedMessage id="Expired" />:
              </div>
              <div className={s.expired_date}>
                {dateFormat(new Date(cardData.expiredDate), MONTH_YEAR_FORMAT)}
              </div>
            </div>
          )}
          <VisaIconSVG className={s.visa_icon} />
        </div>
      </div>
    </div>
  );
};

export default VisaCard;
