import { useDispatch } from 'react-redux';

import { availablePlansActions } from '../slices';

import useAvailablePlansTable from './useAvailablePlansTable';

const useSelectPeriodRange = ({ coinSymbol, isAllPeriodsSelected }) => {
  const dispatch = useDispatch();

  const { periods } = useAvailablePlansTable({ isAllPeriodsSelected });

  const selectPeriodRange = (selectedPeriod) => {
    const dispatchSelectedPeriod = (coinSymbol) => {
      dispatch(
        availablePlansActions.setSelectedPeriods({
          coinSymbol,
          period: selectedPeriod,
        }),
      );
    };

    isAllPeriodsSelected
      ? dispatch(availablePlansActions.setSelectedPeriod(selectedPeriod))
      : dispatchSelectedPeriod(coinSymbol);
  };

  return {
    selectPeriodRange,
    periods,
  };
};

export default useSelectPeriodRange;
