import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccountModal, CustomQRCode } from '@shared/components';
import { Button } from '@shared/ui';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

const QRCodeModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.QR_CODE_MODAL,
  );

  const { value, title } = modal.params;

  return (
    <AccountModal
      onClose={closeModal}
      isOpen={isOpen}
      afterClose={resetModal}
      title={title}
      footer={
        <div className="d-flex justify-content-center">
          <Button variant="text" onClick={closeModal}>
            <FormattedMessage id="Back" />
          </Button>
        </div>
      }
    >
      <div className="text-center">
        <CustomQRCode size="80%" value={value} />
      </div>
    </AccountModal>
  );
};

export default memo(QRCodeModal);
