import { format } from '@utils/numbers';

export const getPriceFieldRules = (
  intl,
  currentPrice,
  currentMarket,
  buyTab,
) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      currentPrice: (v) => {
        const currentPriceValue = Number(currentPrice) || 0;

        if (buyTab && Number(v) <= currentPriceValue) {
          return intl.formatMessage(
            { id: 'PriceHigherThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        if (!buyTab && Number(v) >= currentPriceValue) {
          return intl.formatMessage(
            { id: 'PriceLowerThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        return true;
      },
    },
  };
};

export const getAmountFieldRules = (intl) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
  };
};

export const getTotalFieldRules = (intl) => {
  return {
    validate: {
      min: (v) => {
        if (Number(v) <= 0) {
          return intl.formatMessage({ id: 'TotalLessThanZero' });
        }

        return true;
      },
    },
  };
};
