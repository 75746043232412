import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { orderBy } from 'lodash';

import { FIAT_CURRENCIES } from '@shared/constants';

import { useAccountBalances, useCoinsRates } from '@store/api/hooks';
import { isGuestUserSelector } from '@store/settings/settingsSelectors';

const useOrderedBalancesByRate = (
  {
    accountId,
    isHideZeroTotalBalances,
    searchValue,
    filterByType,
    orderRateDirection = 'desc',
    coinRateSymbol = FIAT_CURRENCIES.USD,
  } = {},
  options,
) => {
  const { ratesByCoin: coinRate, isCoinsRatesSuccess } = useCoinsRates({
    fromCoinSymbol: coinRateSymbol,
  });
  const isGuest = useSelector(isGuestUserSelector);

  const { accountBalancesMap, isSuccessAccountBalances } = useAccountBalances(
    { accountId, isHideZeroTotalBalances, searchValue, filterByType },
    options,
  );

  const isSuccessBalances = isGuest ? true : isSuccessAccountBalances;

  const orderedBalancesMap = useMemo(() => {
    const mappedBalances = accountBalancesMap.map((balance) => {
      const balanceCoinRate = coinRate?.[balance.symbol.toUpperCase()];

      return {
        ...balance,
        inCurrency:
          Number.parseFloat(balance.total_balance / balanceCoinRate) || 0,
      };
    });

    return orderBy(mappedBalances, 'inCurrency', orderRateDirection);
  }, [accountBalancesMap, coinRate, orderRateDirection]);

  return {
    orderedBalancesMap,
    isSuccessOrderedBalancesByRate: isSuccessBalances && isCoinsRatesSuccess,
  };
};

export default useOrderedBalancesByRate;
