import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  AvailablePlansFilters,
  AvailablePlansGrid,
  AvailablePlansTable,
  CreateStakeItemSidebar,
} from '@pages/WalletsPage/Staking/components';
import { availablePlansActions } from '@pages/WalletsPage/Staking/slices';

const AvailablePlansTab = () => {
  const dispatch = useDispatch();

  const [isGrid, setIsGrid] = useState(true);

  const handleSearch = (value) => {
    dispatch(availablePlansActions.setSearch(value));
  };

  return (
    <>
      <AvailablePlansFilters
        isGrid={isGrid}
        setIsGrid={setIsGrid}
        onSearchChange={handleSearch}
      />

      {isGrid ? <AvailablePlansGrid /> : <AvailablePlansTable />}

      <CreateStakeItemSidebar isGrid={isGrid} />
    </>
  );
};

export default memo(AvailablePlansTab);
