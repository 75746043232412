import React, { cloneElement } from 'react';

import { useController, useForm } from 'react-hook-form';

import { excludeReactHookFormProps } from '@shared/components/FormController/FormController.utils';

import { NOOP } from '@constants';

const FormController = ({
  component: Component,
  name,
  control,
  rules,
  defaultValue,
  isErrorDisplayed = true, // it's need when you combine several fields and want show common error
  onBlur = NOOP,
  ...props
}) => {
  const {
    field: { onBlur: reactHookOnBlur, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const componentProps = excludeReactHookFormProps(props);

  const handleBlur = (event) => {
    reactHookOnBlur();
    onBlur(event);
  };

  return (
    <div>
      <Component
        isInvalid={!!error}
        onBlur={handleBlur}
        {...componentProps}
        {...field}
      />
      {isErrorDisplayed && error && (
        <div className="color-red mt-2">{error.message}</div>
      )}
    </div>
  );
};

export default FormController;

// For stories in StoryBook to test Controllers
export const FormProvider = ({ defaultValues, children }) => {
  const { handleSubmit, ...methods } = useForm({ defaultValues });

  const onSubmit = (data) => console.log(data);

  const onSetErrors = () => {
    Object.keys(defaultValues).forEach((fieldName) => {
      methods.setError(fieldName, { message: 'Field is wrong' });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {cloneElement(children, { ...methods })}
      <div className="d-flex">
        <button className="mt-10" type="submit">
          Submit
        </button>

        {!!defaultValues && (
          <button onClick={onSetErrors} className="mt-10" type="button">
            Set errors
          </button>
        )}
      </div>
    </form>
  );
};
