import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';

import { Card, FORM_ACTION_ORIENTATION } from '@shared/components';
import FormActions from '@shared/components/FormActions/FormActions';
import { SMS_OTP_NUMBER_LENGTH } from '@shared/constants';
import { OTPInputController } from '@shared/ui';

import { useProfileMutations } from '@api/hooks';

import { PasswordGetCodeForm } from '../PasswordGetCodeForm';

import styles from './DisableSMSAuth.module.scss';

const DisableSMSAuth = () => {
  const intl = useIntl();

  const methods = useForm({ defaultValues: { code: '' } });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { onUnbindPhoneNumber, isUnbindPhoneNumberLoading } =
    useProfileMutations();

  const [isPasswordCodeSend, setIsPasswordCodeSend] = useState(false);

  const handleFormSubmit = ({ code }) => {
    onUnbindPhoneNumber({ code });
  };

  const handleGetCode = () => {
    setIsPasswordCodeSend(true);
  };

  return (
    <div className={cx('security-card', styles.security_card)}>
      <Card
        title={<FormattedMessage id="DisableSMSAuthentication" />}
        customStyles={styles}
      >
        <div className="security-card-description">
          <FormattedMessage id="DisableSMSAuthenticationDescription" />
        </div>

        <div className={styles.form}>
          <PasswordGetCodeForm onSubmit={handleGetCode} />

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <OTPInputController
                name="code"
                label={`${intl.formatMessage({ id: 'EnterCodeFromSMS' })}:`}
                numInputs={SMS_OTP_NUMBER_LENGTH}
                size="xl"
                rounded
                rules={{
                  required: intl.formatMessage({ id: 'FieldRequired' }),
                  minLength: {
                    value: SMS_OTP_NUMBER_LENGTH,
                    message: intl.formatMessage({ id: 'FieldRequired' }),
                  },
                }}
                {...methods}
              />

              <div className="mt-30">
                <FormActions
                  submitText={<FormattedMessage id="Disable" />}
                  submitButtonProps={{
                    color: 'dangerous',
                    isLoading: isUnbindPhoneNumberLoading,
                    disabled:
                      isUnbindPhoneNumberLoading ||
                      !isPasswordCodeSend ||
                      !isValid,
                  }}
                  cancelButtonProps={{
                    variant: 'text',
                  }}
                  orientation={FORM_ACTION_ORIENTATION.VERTICAL}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </Card>
    </div>
  );
};

export default memo(DisableSMSAuth);
