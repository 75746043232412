import { TRANSFER_RECIPIENTS } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.constants';

export const transferDetails = ({
  intl,
  transferRecipient,
  visaCard,
  transferMoneyDetails,
  spotAccountFormDetails,
}) => {
  let details = [
    {
      id: 'fromCardNumber',
      label: `${intl.formatMessage({ id: 'FromCardNumber' })}:`,
      value: visaCard.number,
      order: 1,
    },
    {
      id: 'date',
      label: `${intl.formatMessage({ id: 'Date' })}:`,
      value: `${intl.formatDate(new Date())}`,
      order: 5,
    },
  ];

  if (transferRecipient === TRANSFER_RECIPIENTS.USERS) {
    details = [
      ...details,
      {
        id: 'toUserEmail',
        label: `${intl.formatMessage({ id: 'ToUserEmail' })}:`,
        value: transferMoneyDetails.userEmail,
        order: 2,
      },
      {
        id: 'amount',
        label: `${intl.formatMessage({ id: 'Amount' })}:`,
        value: `${transferMoneyDetails.currency} ${transferMoneyDetails.amount}`,
        order: 3,
      },
    ];

    if (!!transferMoneyDetails.message) {
      details = [
        ...details,
        {
          id: 'message',
          label: `${intl.formatMessage({ id: 'Message' })}:`,
          value: transferMoneyDetails.message,
          order: 4,
        },
      ];
    }
  }

  if (transferRecipient === TRANSFER_RECIPIENTS.SPOT_ACCOUNT) {
    details = [
      ...details,
      {
        id: 'userAccount',
        label: `${intl.formatMessage({ id: 'UserAccount' })}:`,
        value: spotAccountFormDetails.userAccount,
        order: 2,
      },
      {
        id: 'amount',
        label: `${intl.formatMessage({ id: 'Amount' })}:`,
        value: `${spotAccountFormDetails.amount} ${spotAccountFormDetails.currency}`,
        order: 3,
      },
      {
        id: 'commission',
        label: `${intl.formatMessage({ id: 'Commission' })}:`,
        value: spotAccountFormDetails.commission.formattedValue,
        order: 4,
      },
    ];
  }

  return details.sort((a, b) => a.order - b.order);
};
