import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useForm } from 'react-hook-form';

import { Button, InputController } from '@shared/ui';

import {
  SettingDescription,
  SettingTitle,
} from '@pages/WalletsPage/VisaCardSettings/components';

import useBreakpoints from '@hooks/useBreakpoints';

import s from './CardName.module.scss';

const CardName = () => {
  const intl = useIntl();
  const { isMobile } = useBreakpoints();

  const { handleSubmit, ...methods } = useForm({
    defaultValues: { accountName: '' },
  });

  const onSubmit = (values) => {
    // TODO: add real endpoint
    console.log(values);
  };

  const labelText = `${intl.formatMessage({
    id: 'Card',
  })} / ${intl.formatMessage({ id: 'AccountName' })}`;

  return (
    <div>
      <SettingTitle>{labelText}</SettingTitle>
      <div className="mt-xl-22 mt-16">
        <SettingDescription>
          <FormattedMessage id="SettingCardAccountNameDescription" />
        </SettingDescription>
      </div>
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <div className="w-100">
          <InputController
            name="accountName"
            label={labelText}
            placeholder={`${intl.formatMessage({
              id: 'SettingCardAccountNamePlaceholder',
            })}...`}
            maxLength={20}
            size="s"
            bordered={false}
            underlined
            customStyles={s}
            rules={{
              required: intl.formatMessage({ id: 'FieldRequired' }),
            }}
            {...methods}
          />
        </div>
        <Button fill={isMobile} customStyles={s} size="m">
          <FormattedMessage id="Apply" />
        </Button>
      </form>
    </div>
  );
};

export default CardName;
