import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';

import { COIN_TYPE } from '@shared/constants';

import Table from '@components/gridTables/Table';

import { useUserWithdrawLimit } from '@pages/WalletsPage/hooks';
import {
  selectedCoinTypeSelector,
  withdrawCurrencySymbolSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';

import { useStreamBalancesQuery } from '@store/api/balancesAPI';
import { useCoinsInfo } from '@store/api/hooks';
import { BalancesAPISelectors } from '@store/api/selectors';
import { userIdSelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './CoinBalanceTable.module.scss';

const CoinBalanceTable = () => {
  const userId = useSelector(userIdSelector);
  const coinSymbol = useSelector(withdrawCurrencySymbolSelector);
  const selectedCoinType = useSelector(selectedCoinTypeSelector);

  const { coinsInfoMap } = useCoinsInfo();

  const userWithdrawLimits = useUserWithdrawLimit(coinSymbol);

  const { data } = useStreamBalancesQuery(userId, {
    selectFromResult: (result) => ({
      data: BalancesAPISelectors.coinWithdrawDepositBalanceTableSelector(
        result,
        coinsInfoMap,
        coinSymbol,
        userWithdrawLimits,
      ),
    }),
  });

  const { isMobile } = useBreakpoints();

  const columnHelper = createColumnHelper();

  const isFiatCoin = selectedCoinType === COIN_TYPE.FIAT;

  const columns = useMemo(() => {
    const commonColumns = [
      columnHelper.accessor('total', {
        header: () => <FormattedMessage id="TotalBalance" />,
        cell: (info) => <span className={styles.cell}>{info.getValue()}</span>,
        enableSorting: false,
        headerAlign: 'start',
        cellAlign: 'start',
      }),
    ];

    const fiatColumns = [
      columnHelper.accessor('total24HSend', {
        header: () => <FormattedMessage id="Total24HSend" />,
        cell: (info) => <span className={styles.cell}>{info.getValue()}</span>,
        enableSorting: false,
        headerAlign: 'start',
        cellAlign: 'start',
      }),
      columnHelper.accessor('withdraw24HLimit', {
        header: () => <FormattedMessage id="Withdraw24HLimit" />,
        cell: (info) => <span className={styles.cell}>{info.getValue()}</span>,
        enableSorting: false,
        headerAlign: 'start',
        cellAlign: 'start',
      }),
    ];

    const cryptoColumns = [
      columnHelper.accessor('inOrders', {
        header: () => <FormattedMessage id="InOrders" />,
        cell: (info) => <span className={styles.cell}>{info.getValue()}</span>,
        enableSorting: false,
        headerAlign: 'start',
        cellAlign: 'start',
      }),
      columnHelper.accessor('available', {
        header: () => <FormattedMessage id="Available" />,
        cell: (info) => <span className={styles.cell}>{info.getValue()}</span>,
        enableSorting: false,
        headerAlign: 'start',
        cellAlign: 'start',
      }),
      columnHelper.accessor('withdrawLimitRange', {
        header: () => <FormattedMessage id="24HWithdrawLimit" />,
        cell: (info) => <span className={styles.cell}>{info.getValue()}</span>,
        enableSorting: false,
        headerAlign: 'start',
        cellAlign: 'start',
      }),
    ];

    return isFiatCoin
      ? [...commonColumns, ...fiatColumns]
      : [...commonColumns, ...cryptoColumns];
  }, [columnHelper, isFiatCoin]);

  const tables = useMemo(() => {
    if (!isMobile) return [{ columns }];

    const chunk = 2;
    const tables = [];

    for (let i = 0; i < columns.length; i += chunk) {
      tables.push({ columns: columns.slice(i, i + chunk) });
    }

    return tables;
  }, [isMobile, coinSymbol, isFiatCoin]);

  return tables.map(({ columns }, index) => (
    <div className={cx({ 'mt-16': index > 0 })} key={`table-${index}`}>
      <Table wideRows data={[data]} columns={columns} />
    </div>
  ));
};

export default memo(CoinBalanceTable);
