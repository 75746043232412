import AvailableEstimationWidget from './AvailableEstimationWidget/AvailableEstimationWidget';
import { BotsProfitWidget } from './BotsProfitWidget';
import { DayChangePnlWidget } from './DayChangePnlWidget';
import { EstimatedValueWidget } from './EstimatedValueWidget';
import { PortfolioCoinsWidget } from './PortfolioCoinsWidget';
import { SpotMarginComparisonWidget } from './SpotMarginComparisonWidget';
import { TokenValueWidget } from './TokenValueWidget';
import { VisaCardWidget } from './VisaCardWidget';

export const WIDGETS = [
  {
    widgetId: 1,
    title: 'EstimationValue',
    component: EstimatedValueWidget,
  },
  {
    widgetId: 2,
    title: '24hEstimationValueChange',
    component: DayChangePnlWidget,
  },
  { widgetId: 3, title: 'PRDXTokenValue', component: TokenValueWidget },
  { widgetId: 4, title: 'AssetPortfolio', component: PortfolioCoinsWidget },
  {
    widgetId: 5,
    title: 'SpotMarginComparison',
    component: SpotMarginComparisonWidget,
  },
  {
    widgetId: 6,
    title: 'VisaCard',
    component: VisaCardWidget,
  },
  { widgetId: 7, title: 'BotsProfit', component: BotsProfitWidget },
  {
    widgetId: 8,
    title: 'AvailableEstimation',
    component: AvailableEstimationWidget,
  },
];

const INITIAL_SELECTED_WIDGETS_IDS = [1, 2, 3, 4, 5, 6, 7, 8];

export const INITIAL_SELECTED_WIDGETS = WIDGETS.map(({ widgetId, title }) => {
  return {
    widgetId,
    isActive: INITIAL_SELECTED_WIDGETS_IDS.includes(widgetId),
    title,
  };
});
