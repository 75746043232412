import classNames from 'classnames';

import TruncatedText from '@shared/components/TruncatedText';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';

import s from './FileUploaderTextPreview.module.scss';

const FileUploaderTextPreview = ({ onRemoveFile, file }) => {
  console.log('FileUploaderTextPreview');
  const handleClick = (event) => {
    event.stopPropagation();

    onRemoveFile();
  };
  return (
    <div className={classNames(s.remove_file)}>
      <TruncatedText>{file.name}</TruncatedText>

      <div className="cursor-pointer" onClick={handleClick}>
        <CrossThinIconSVG width={18} height={18} />
      </div>
    </div>
  );
};

export default FileUploaderTextPreview;
