import { getCountries } from 'react-phone-number-input';

export const ALL_COUNTRIES_CODES = getCountries();

export const RECOMMENDED_COUNTRIES_CODES = ['US', 'GB', 'IT', 'DE', 'FR', 'CH'];

export const OTHER_COUNTRIES_CODES = ALL_COUNTRIES_CODES.filter(
  (country) => !RECOMMENDED_COUNTRIES_CODES.includes(country),
);

export const ORDERED_COUNTRIES_CODES = [
  ...RECOMMENDED_COUNTRIES_CODES,
  ...OTHER_COUNTRIES_CODES,
];
