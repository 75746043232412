import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Table } from '@shared/components';
import {
  HEADER_HEIGHT,
  ROW_HEIGHT,
  VARIANTS_ROWS,
} from '@shared/components/Table/table.constants';

import { visaCardTransactionsColumns } from '@pages/WalletsPage/VisaCardDetails/components/VisaCardTransactions/components/VisaCardTransactionsTable/VisaCardTransactionsColumns';

import { currencySelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';

import s from './VisaCardTransactionsTable.module.scss';

const VisaCardTransactionsTable = () => {
  const currency = useSelector(currencySelector);

  const { isLaptopDown, isMobile } = useBreakpoints();

  const columns = useMemo(
    () =>
      visaCardTransactionsColumns({
        mainCurrencySymbol: currency.symbol,
        isLaptopDown,
        isMobile,
      }),
    [currency, isLaptopDown, isMobile],
  );

  const data = useMemo(
    () => [
      {
        date: '13:50',
        description: 'email@mail.com',
        type: 'Transfer',
        amount: '-45.79',
        cashback: '0.05',
        balance: '123,453.97',
      },
    ],
    [],
  );

  return (
    <Table
      columns={columns}
      data={[]}
      // data={data}
      headerHeight={HEADER_HEIGHT.L}
      rowHeight={ROW_HEIGHT.L}
      variantRows={VARIANTS_ROWS.UNDERLINED_LIGHT}
    />
  );
};

export default VisaCardTransactionsTable;
