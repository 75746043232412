import React, { memo } from 'react';

import { FormattedCurrency } from '@shared/components';

import styles from './MinDepositItem.module.scss';

const MinDepositItem = ({ minDeposit, coinSymbol }) => {
  return (
    <span className={Number(minDeposit) ? styles.black : styles.grey}>
      <FormattedCurrency currency={coinSymbol} value={minDeposit} />
    </span>
  );
};

export default memo(MinDepositItem);
