import { useMemo } from 'react';

import cx from 'classnames';

import { CoinsIconSet } from '@shared/components';

import { getMarketPairFullName } from '@utils/market';

import s from './MarketItem.module.scss';

const MarketItem = ({ markets, marketCoinSymbol, quoteCoinSymbol }) => {
  const { fullMarketName, fullQuoteName } = useMemo(
    () =>
      getMarketPairFullName(markets, {
        marketCoinSymbol,
        quoteCoinSymbol,
      }),
    [markets, marketCoinSymbol, quoteCoinSymbol],
  );

  return (
    <div className={s.content}>
      <CoinsIconSet
        coins={[marketCoinSymbol, quoteCoinSymbol]}
        displayQuantity={2}
        iconStyle="iconOutline"
        iconOffset={10}
        iconSize={24}
        customStyles={s}
      />

      <div className="d-flex align-items-center gap-18">
        <div className={s.pair_symbols}>
          {marketCoinSymbol.toUpperCase()} / {quoteCoinSymbol.toUpperCase()}
        </div>

        {fullMarketName && fullQuoteName && (
          <div className={cx('text-truncate', s.pair_full_name)}>
            {fullMarketName} / {fullQuoteName}
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketItem;
