import { useMemo } from 'react';

import { useGetBotsQuery, useLazyGetBotsQuery } from '@api/botsAPI';
import { BotsApiSelectors } from '@api/selectors';

const selectFromResult = (result, { orderByIdDirection } = {}) => {
  const dataResult = {
    isBotsSuccess: result.isSuccess,
    isBotsFetching: result.isFetching,
    refetchBots: result.refetch,
  };

  let botsMap = BotsApiSelectors.dataSelector(result);

  if (orderByIdDirection) {
    botsMap = BotsApiSelectors.botsMapOrderByIdDirectionSelector(
      botsMap,
      orderByIdDirection,
    );
  }

  dataResult.botsMap = botsMap;

  return dataResult;
};

export const useBots = ({ orderByIdDirection = 'desc' } = {}, options) => {
  const result = useGetBotsQuery(undefined, {
    ...options,
    refetchOnMountOrArgChange: true,
  });

  const data = useMemo(() => {
    return selectFromResult(result, { orderByIdDirection });
  }, [result, orderByIdDirection]);

  return data;
};

export const useLazyBots = (options) => {
  const [trigger, result] = useLazyGetBotsQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result, result.originalArgs);
  }, [result]);

  return [trigger, data];
};
