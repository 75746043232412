import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../../utils';

const defaultOrders = [];
const defaultData = {
  orders: defaultOrders,
  meta: {
    limit: 5,
    page: 1,
  },
};
const defaultMeta = {};

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const closedOrdersSelector = createSelector(dataSelector, (data) => {
  return data?.orders || defaultOrders;
});

export const ClosedOrdersApiSelectors = {
  closedOrdersSelector,
  metaSelector,
};
