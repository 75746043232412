import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  InfoWidget,
  PersonalFormattedCurrency,
  TotalValue,
  Widget,
} from '@shared/components';
import { CRYPTO_CURRENCIES, FIAT_CURRENCIES } from '@shared/constants';
import { useCurrencyIcon } from '@shared/hooks';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import styles from './TokenValueWidget.module.scss';
import mockData from 'src/mock.json';

const TokenValueWidget = () => {
  const coinBalancePRDX = mockData.PRDXTokenValueWidget;

  const { label, value: currency } = useSelector(currencySelector);

  const { ratesByPair: PRDXtoUSDRate } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.PRDX,
    toCoinSymbol: currency,
  });

  const PRDXTotalBalanceUSD =
    Number.parseFloat(coinBalancePRDX?.total_balance * PRDXtoUSDRate) || 0;

  const icon = useCurrencyIcon(CRYPTO_CURRENCIES.PRDX, {
    width: 24,
    height: 24,
  });

  return (
    <Widget className={styles.token_value_widget}>
      <InfoWidget
        customStyles={styles}
        title={<FormattedMessage id="PRDXTokenValue" />}
        value={
          <PersonalFormattedCurrency
            value={coinBalancePRDX?.total_balance}
            currency={FIAT_CURRENCIES.USD}
          />
        }
        suffix={<span className="icon-suffix">{icon}</span>}
      />

      <TotalValue
        customStyles={styles}
        value={
          <PersonalFormattedCurrency
            value={PRDXTotalBalanceUSD}
            currency={CRYPTO_CURRENCIES.PRDX}
          />
        }
        symbol={label}
      />
    </Widget>
  );
};

export default memo(TokenValueWidget);
