import { useMemo } from 'react';

import { useCoinsInfo } from '@store/api/hooks';
import { useGetStakingList } from '@store/api/hooks';

const useSelectResult = ({ myStaking, isMyStakingSuccess }) => {
  const { coinsInfo } = useCoinsInfo();

  const stakingList = useMemo(
    () =>
      myStaking.map((item) => {
        const coinInfo = coinsInfo?.[item.coin_symbol];

        const id = item.id;
        const amount = item.amount;
        const coinSymbol = item.coin_symbol;
        const precision = coinInfo?.digits;
        const percent = item.percents * 100;

        const started = item.created_at;
        const expired = item.expired_at;

        return {
          id,
          asset: {
            coinSymbol,
            fullName: coinInfo?.name,
            amount,
            precision,
          },
          estimation: {
            percent,
            coinSymbol,
            amount,
            period: item.period,
          },
          totalEarnings: item.total_earnings,
          remainingDays: {
            remainingDays: started,
            durationDays: expired,
          },
          dateInfo: {
            start: started,
            expired,
          },
          action: {
            id,
            amount,
            coinSymbol,
          },
        };
      }),
    [coinsInfo, myStaking],
  );

  return { stakingList, isMyStakingSuccess };
};

const useMyStakingTable = (args, options) => {
  const data = useGetStakingList(args, {
    ...options,
    refetchOnMountOrArgChange: true,
  });

  const result = useSelectResult(data);

  return result;
};

export default useMyStakingTable;
