import React, { memo } from 'react';

import CoinPercentDynamics from '@components/CoinPercentDynamics';

import { format } from '@utils/numbers';

const TopGainersItem = ({ item }) => {
  return (
    <div className="markets-item">
      <img
        width={24}
        height={24}
        src={`/images/coins/${item.marketData.market_coin_symbol}.png`}
        alt={item.marketData.market_coin_symbol}
      />

      <span className="pair-name ms-8">
        {item.marketData.market_coin_symbol}
      </span>

      <div className="flex-4 d-flex align-items-center">
        <span className="pair-value ms-8">
          {format(item.c, {
            precision: item.marketData.quote_precision_format,
          })}
        </span>

        <div className="flex-2 d-flex">
          <CoinPercentDynamics percent={item?.ch} />
        </div>
      </div>
    </div>
  );
};

export default memo(TopGainersItem);
