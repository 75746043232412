import { memo, useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import reactFastCompare from 'react-fast-compare';

import Breakpoint from '@components/Breakpoint';
import { ChartSelect } from '@components/CustomChart/components';

import styles from './ChartMenuPeriodicity.module.scss';

const ChartMenuPeriodicity = ({
  stx,
  config,
  chartHeight,
  customStyles = {},
  minimizeOnBreakpoint = 'md',
  hideMenuPeriodicityItems = [],
}) => {
  const [periodicity, setPeriodicity] = useState(stx?.getPeriodicity());

  const isActivePeriodicity = (period) => {
    if (!stx) return false;

    return reactFastCompare(period.value, periodicity);
  };

  const handleSelectPeriod = () => {
    setPeriodicity(stx?.getPeriodicity());
  };

  useEffect(() => {
    if (stx) {
      setPeriodicity(stx.getPeriodicity());
    }
  }, [stx]);

  const activePeriodLabel = config?.menuPeriodicity?.find((period) =>
    reactFastCompare(period.value, periodicity),
  )?.label;

  const menuPeriodicityFavoriteItems = useMemo(() => {
    const periods = config?.menuPeriodicityFavorite || [];

    return periods.filter(
      (period) => !hideMenuPeriodicityItems.includes(period.label),
    );
  }, [config?.menuPeriodicityFavorite, hideMenuPeriodicityItems]);

  return (
    <>
      <Breakpoint size={minimizeOnBreakpoint} direction="up">
        <ul
          className={classnames(
            styles.menu_periodicity,
            customStyles.menu_periodicity,
          )}
        >
          {menuPeriodicityFavoriteItems.map((period, index) => (
            <li
              key={period.label + index}
              className={classnames(styles.item, customStyles.item, {
                [styles['item--active']]: isActivePeriodicity(period),
              })}
              onClick={handleSelectPeriod}
            >
              <cq-item stxtap={period.cmd}>{period.label}</cq-item>
            </li>
          ))}
        </ul>
      </Breakpoint>

      <Breakpoint size={minimizeOnBreakpoint} direction="down">
        <span
          className={classnames(
            styles.item,
            styles['item--active'],
            customStyles.item,
          )}
        >
          <cq-item class="ps-10 pe-0">{activePeriodLabel}</cq-item>
        </span>
      </Breakpoint>

      <ChartSelect
        dropdownHeight={chartHeight}
        items={config?.menuPeriodicity}
        handleSelectItem={handleSelectPeriod}
        isActiveItem={isActivePeriodicity}
      />
    </>
  );
};

export default memo(ChartMenuPeriodicity);
