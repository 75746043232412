import React from 'react';
import { FormattedMessage } from 'react-intl';

import { daysToWeeks } from 'date-fns';

import {
  FLEXIBLE,
  HALF_YEAR,
  HALF_YEAR_IN_DAYS,
  MONTH,
  MONTH_IN_DAYS,
  ONE_YEAR,
  QUARTER,
  QUARTER_IN_DAYS,
  WEEK,
  WEEKS_OF_MONTH,
  WEEK_IN_DAYS,
  YEAR,
} from '@pages/WalletsPage/Wallets.constants';

const periods = {
  [FLEXIBLE]: <FormattedMessage id="Flex" />,
  [WEEK]: (
    <>
      {daysToWeeks(WEEK_IN_DAYS)}
      <FormattedMessage id="Week" />
    </>
  ),
  [MONTH]: (
    <>
      {daysToWeeks(MONTH_IN_DAYS) / WEEKS_OF_MONTH}
      <FormattedMessage id="Month" />
    </>
  ),
  [QUARTER]: (
    <>
      {daysToWeeks(QUARTER_IN_DAYS) / WEEKS_OF_MONTH}
      <FormattedMessage id="Month" />
    </>
  ),
  [HALF_YEAR]: (
    <>
      {Math.floor(daysToWeeks(HALF_YEAR_IN_DAYS) / WEEKS_OF_MONTH)}
      <FormattedMessage id="Month" />
    </>
  ),
  [YEAR]: (
    <>
      {ONE_YEAR}
      <FormattedMessage id="Year" />
    </>
  ),
};

const FormattedPeriods = ({ period }) => periods[period];

export default FormattedPeriods;
