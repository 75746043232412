import { createSlice } from '@reduxjs/toolkit';

import { FIAT_CURRENCIES } from '@shared/constants';

import {
  ALL_CRYPTO_MARKETS_TIME_FRAMES,
  ASSETS_ITEMS_KEY,
} from '../AllMarkets/allMarkets.constants';

const initialState = {
  filters: {
    selectedCurrency: FIAT_CURRENCIES.EUR.toUpperCase(),
    selectedTimeFrame: ALL_CRYPTO_MARKETS_TIME_FRAMES[0].value,
    searchValue: '',
    selectedAssetsKey: ASSETS_ITEMS_KEY.ALL_CRYPTOS,
    selectedPairsKey: null,
  },
};

const allCryptoMarketsSlice = createSlice({
  name: 'allCryptoMarkets',
  initialState,
  reducers: {
    setSelectedCurrency: (state, action) => {
      state.filters.selectedCurrency = action.payload;
    },
    setSelectedTimeFrame: (state, action) => {
      state.filters.selectedTimeFrame = action.payload;
    },
    setSearchValue: (state, action) => {
      state.filters.searchValue = action.payload;
    },
    setSelectedAssetsKey: (state, action) => {
      state.filters.selectedPairsKey = null;

      state.filters.selectedAssetsKey = action.payload;
    },
    setSelectedPairsKey: (state, action) => {
      state.filters.selectedAssetsKey = null;

      state.filters.selectedPairsKey = action.payload;
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
    },
  },
});

const allCryptoMarketsActions = allCryptoMarketsSlice.actions;
const allCryptoMarketsReducer = allCryptoMarketsSlice.reducer;

export { allCryptoMarketsReducer, allCryptoMarketsActions };

export default allCryptoMarketsSlice;
