import React, { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import styles from './DropdownIndicator.module.scss';

const DropdownIndicator = ({ selectProps, ...props }) => {
  const inversion = selectProps.inversion;
  const withDropdownIndicator = selectProps.withDropdownIndicator;

  if (withDropdownIndicator) {
    return (
      <components.DropdownIndicator {...props}>
        <DownIconSVG
          className={cx('custom-indicator', styles.down_icon, {
            [styles.inversion]: inversion,
          })}
        />
      </components.DropdownIndicator>
    );
  }

  return null;
};

export default memo(DropdownIndicator);
