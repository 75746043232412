import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../utils';

const defaultData = [];
const defaultMeta = {
  page: 1,
  limit: 5,
};

const dataSelector = ApiSelectorsUtils.createDataApiSelector(defaultData);

const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const myContractsSelector = createSelector(dataSelector, (data) => {
  return data || defaultData;
});

export const MyContractsApiSelectors = {
  myContractsSelector,
  metaSelector,
};
