import { useMemo } from 'react';

import { useCoinsInfo } from '@store/api/hooks';

import { format } from '@utils/numbers';

const useCurrencyFormat = (currency, value, formatOptions = {}, options) => {
  const { coinInfo } = useCoinsInfo({ coinSymbol: currency }, options);

  const formattedCurrency = useMemo(
    () =>
      format(value, {
        precision: coinInfo?.digits,
        ...formatOptions,
      }),
    [value, coinInfo, formatOptions],
  );

  return { formattedCurrency, precision: coinInfo?.digits };
};

export default useCurrencyFormat;
