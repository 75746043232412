export function getMinProfitRestrictionByUserLine(userLine) {
  switch (userLine) {
    case 'none':
      return 0.8;
    case 'silver':
      return 0.75;
    case 'gold':
      return 0.65;
    case 'platinum':
      return 0.6;
    case 'black':
      return 0.57;

    default:
      return 1;
  }
}

export const TradingFeesUtils = {
  getMinProfitRestrictionByUserLine,
};
