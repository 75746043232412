import { toast } from 'react-toastify';

export const isAllLoaded = (...args) => {
  return args.every(Boolean);
};

/**
 * Find percent from totalValue for partialValue
 * @param {number} partialValue - 10
 * @param {number} totalValue - 100
 * @return {number} 10
 */
export function percentOfNumber(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}

export const isElementHasScrolls = (element) => {
  let horizontal = false;
  let vertical = false;

  if (element) {
    horizontal = element.scrollWidth > element.clientWidth;
    vertical = element.scrollHeight > element.clientHeight;
  }

  return { vertical, horizontal };
};

export function subtractPercent(totalValue, percent) {
  return (totalValue / 100) * percent;
}

export function addPercent(value, percent) {
  return value + value * (percent / 100);
}

export function isTextTruncated(element) {
  if (!element) {
    return false;
  }

  return element.offsetWidth < element.scrollWidth;
}

export const filterObjectByKeysArray = (obj, keysArray) =>
  Object.keys(obj)
    .filter((key) => keysArray.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

export const isDarkColor = (colorHex) => {
  const hex = colorHex.includes('#') ? colorHex.replace('#', '') : colorHex;
  const red = parseInt(hex.substring(0, 2), 16) / 255;
  const green = parseInt(hex.substring(2, 4), 16) / 255;
  const blue = parseInt(hex.substring(4, 6), 16) / 255;

  // Convert RGB to HSL
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  const lightness = (max + min) / 2;

  return lightness < 0.5;
};

export const copyToClipboard = async ({ intl, text }) => {
  try {
    await navigator.clipboard.writeText(text);

    toast.success(
      intl.formatMessage({
        id: 'SuccessfullyCopiedToClipboard',
      }),
    );
  } catch (error) {
    if (!navigator.clipboard) {
      const textArea = document.createElement('textarea');

      textArea.value = text;
      textArea.setAttribute('readonly', '');
      textArea.style.position = 'absolute';
      textArea.style.left = '-9999px';

      document.body.appendChild(textArea);

      textArea.select();

      try {
        document.execCommand('copy');

        toast.success(
          intl.formatMessage({
            id: 'SuccessfullyCopiedToClipboard',
          }),
        );
      } catch (err) {
        toast.success(
          intl.formatMessage({ id: 'SomethingWentWrongError' }, { error: err }),
        );
      }

      document.body.removeChild(textArea);
    } else {
      toast.success(
        intl.formatMessage({ id: 'SomethingWentWrongError' }, { error }),
      );
    }
  }
};
