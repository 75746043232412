import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useForm, FormProvider } from 'react-hook-form';

import {
  FormActions,
  SideBarBlock,
  SideBarInfoPoint,
  SideBarList,
} from '@shared/components';
import { Button, CheckboxController } from '@shared/ui';

import SideBar from '@components/SideBar';

import {
  checkoutWithdrawDataSelector,
  withdrawCurrencySymbolSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';

import { useCoinsInfo } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { sidebarIds } from '@constants';

import { SERVICE_ROUTES } from '../../../../../routes.constants';

import styles from './SideBarConfirmAdvcashWithdraw.module.scss';

const SideBarConfirmAdvcashWithdraw = ({ onSubmit }) => {
  const intl = useIntl();

  const { isTabletDown } = useBreakpoints();

  const checkoutData = useSelector(checkoutWithdrawDataSelector);
  const selectedCurrency = useSelector(withdrawCurrencySymbolSelector);

  const { email, amount, fee, profit } = checkoutData;

  const { toggleSidebar, isOpen } = useSidebar(
    sidebarIds.WALLETS_WITHDRAW_CONFIRM_ADVCASH,
  );

  const { coinInfo: coinInfoByCurrency } = useCoinsInfo(
    {
      coinSymbol: selectedCurrency,
    },
    { skip: !isOpen },
  );

  const precision = coinInfoByCurrency?.digits;

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { terms: false },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const advcashWithdrawCheckoutItems = [
    {
      id: 'paymentMethod',
      label: `${intl.formatMessage({ id: 'PaymentMethod' })}:`,
      value: intl.formatMessage({ id: 'Advcash' }),
    },
    {
      id: 'emailAddress',
      label: `${intl.formatMessage({ id: 'EmailAddress' })}:`,
      value: email,
    },
    {
      id: 'amount',
      label: `${intl.formatMessage({ id: 'Amount' })}:`,
      value: `${format(amount, {
        precision,
      })} ${selectedCurrency.toUpperCase()}`,
    },
    {
      id: 'commission',
      label: `${intl.formatMessage({ id: 'Commission' })}:`,
      value: `${format(fee, { precision })} ${selectedCurrency.toUpperCase()}`,
    },
    {
      id: 'youWillGet',
      label: `${intl.formatMessage({ id: 'YouWillGet' })}:`,
      value: `${format(profit, {
        precision,
      })} ${selectedCurrency.toUpperCase()}`,
    },
  ];

  const handleConfirmAdvcashWithdraw = () => {
    toggleSidebar();

    const transferData = {
      amount,
      email,
    };

    onSubmit(transferData);
  };

  const handleCloseSidebar = () => toggleSidebar();

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleCloseSidebar}
      withCloseButton
      title={intl.formatMessage({ id: 'ConfirmWithdraw' })}
    >
      <div className={styles.content}>
        <div>
          <SideBarBlock>
            <SideBarList items={advcashWithdrawCheckoutItems} />
          </SideBarBlock>
          <SideBarBlock>
            <SideBarInfoPoint
              title={intl.formatMessage({
                id: 'withdrawAdvcashInfoPoint.title',
              })}
              description={intl.formatMessage({
                id: 'withdrawAdvcashInfoPoint.description',
              })}
            />
          </SideBarBlock>
        </div>
        <div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleConfirmAdvcashWithdraw)}>
              <CheckboxController
                name="terms"
                label={
                  <FormattedMessage
                    id="IAgreeTheTerms"
                    values={{
                      a: (str) => (
                        <Button
                          type="button"
                          variant="text"
                          navigation={{ to: SERVICE_ROUTES.TERM }}
                          target="_blank"
                          rel="noopener noreferrer"
                          size="xxs"
                          inversion
                          customStyles={styles}
                        >
                          {str}
                        </Button>
                      ),
                    }}
                  />
                }
                rules={{
                  required: intl.formatMessage({ id: 'IAcceptTheTerms' }),
                }}
                inversion
                customStyles={styles}
                {...methods}
              />

              <div className="mt-36">
                <FormActions
                  submitText={<FormattedMessage id="Confirm" />}
                  cancelText={<FormattedMessage id="Cancel" />}
                  submitButtonProps={{
                    size: isTabletDown ? 'm' : 'l',
                    fill: true,
                    inversion: true,
                    disabled: !isValid,
                  }}
                  cancelButtonProps={{
                    size: isTabletDown ? 'm' : 'l',
                    fill: true,
                    inversion: true,
                  }}
                  onCancel={handleCloseSidebar}
                  customStyles={styles}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </SideBar>
  );
};

export default memo(SideBarConfirmAdvcashWithdraw);
