import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { TrailingStopTooltip } from '@shared/components';
import TruncatedText from '@shared/components/TruncatedText/TruncatedText';
import { parseToLocalization } from '@shared/utils';

import { ORDER_TYPE, SUBORDER_TYPE } from '@constants';

import { ReactComponent as StopLossSVG } from '@icons/stop-loss-icon.svg';
import { ReactComponent as TakeProfitSVG } from '@icons/take-profit-icon.svg';

const OrdersType = ({
  type,
  price,
  activationPrice,
  callbackRate,
  callbackType,
  isHideType,
  isSuborder,
}) => {
  if (isHideType) return null;

  if (isSuborder) {
    return (
      <TruncatedText>
        <FormattedMessage id={parseToLocalization(type)} />{' '}
        {type === SUBORDER_TYPE.OTO.STOP_LOSS && <StopLossSVG />}
        {type === SUBORDER_TYPE.OTO.TAKE_PROFIT && <TakeProfitSVG />}
      </TruncatedText>
    );
  }

  if (type === ORDER_TYPE.TRAILING_STOP) {
    return (
      <TrailingStopTooltip
        price={price}
        activationPrice={activationPrice}
        callbackRate={callbackRate}
        callbackType={callbackType}
      />
    );
  }

  return (
    <TruncatedText>
      <FormattedMessage id={parseToLocalization(type)} />
    </TruncatedText>
  );
};

export default memo(OrdersType);
