import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { sum } from 'lodash';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import { useMyStakingTable } from '@pages/WalletsPage/Staking/hooks';
import { StakingUtils } from '@pages/WalletsPage/Staking/staking.utils';

import { useCoinsRates, useCoinsInfo } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

const useCalculateTotalEarnings = () => {
  const { value: currency } = useSelector(currencySelector);

  const { stakingList } = useMyStakingTable();

  const { coinsInfo } = useCoinsInfo();

  const { ratesByCoin: coinRateBTC, isCoinsRatesSuccess } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.BTC,
  });

  const earnings = useMemo(() => {
    return stakingList
      .filter(
        (item) => item.asset.coinSymbol !== CRYPTO_CURRENCIES.BTC.toLowerCase(),
      )
      .map((item) => {
        const totalEarnings = Number(item.totalEarnings);
        const coinSymbol = item.asset.coinSymbol;

        const coinToBtcCourse = Number(
          coinRateBTC?.[item.asset.coinSymbol.toUpperCase()],
        );

        const valueInBTC = totalEarnings / coinToBtcCourse;

        return [coinSymbol, valueInBTC];
      });
  }, [coinRateBTC, stakingList]);

  const duplicatedValues = useMemo(
    () => StakingUtils.findDuplicatesAndSum(earnings),
    [earnings],
  );

  const earningsInBTC = useMemo(
    () => sum(duplicatedValues.map((item) => item[1])),
    [earnings],
  );

  const btcCoins = useMemo(
    () =>
      stakingList?.filter(
        (item) => item.asset.coinSymbol === CRYPTO_CURRENCIES.BTC.toLowerCase(),
      ),
    [stakingList],
  );

  const valuesInBTC = useMemo(
    () => sum(btcCoins.map((item) => Number(item.totalEarnings))),
    [btcCoins],
  );

  const resultItems = useMemo(() => {
    if (!isCoinsRatesSuccess) {
      return [];
    }

    return [...duplicatedValues, [CRYPTO_CURRENCIES.BTC, valuesInBTC]].map(
      (item) => {
        const coinSymbol = item[0];
        const coinInfo = coinsInfo?.[coinSymbol];

        return { coinSymbol, coinFullname: coinInfo?.name, value: item[1] };
      },
    );
  }, [coinsInfo, isCoinsRatesSuccess, valuesInBTC, earnings]);

  const totalEarningsInBTC = earningsInBTC + valuesInBTC;

  const totalEarningsInSelectedCurrency =
    totalEarningsInBTC * coinRateBTC?.[currency];

  return {
    currency,
    totalEarningsInBTC,
    totalEarningsInSelectedCurrency,
    resultItem: resultItems,
    isLoaded: isCoinsRatesSuccess,
  };
};

export default useCalculateTotalEarnings;
