import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';
import dateFormat from 'date-fns/format';

import { DefaultAvatar, FormattedCurrency } from '@shared/components';
import {
  CRYPTO_CURRENCIES,
  DAY_MONTH_YEAR_TIME_FORMAT,
} from '@shared/constants';

import { EARNING_HISTORY_COLUMN_NAMES } from './referralTable.constant';

const columnHelper = createColumnHelper();

const earningHistoryColumns = ({
  hideColumnNames,
  currency,
  isSmallAvatars = false,
}) => {
  const avatarSize = isSmallAvatars ? 28 : 36;

  const allColumns = [
    columnHelper.accessor(EARNING_HISTORY_COLUMN_NAMES.USER, {
      header: () => <FormattedMessage id="User" />,
      cell: (info) => {
        const { avatarId, value } = info.getValue();

        return (
          <span className="fs-14 fw-600 d-flex gap-14 align-items-center">
            <DefaultAvatar
              avatarId={avatarId}
              height={avatarSize}
              width={avatarSize}
            />{' '}
            {value}
          </span>
        );
      },
      enableSorting: false,
      headerAlign: 'start',
      cellAlign: 'start',
    }),
    columnHelper.accessor(EARNING_HISTORY_COLUMN_NAMES.LEVEL_A_REVENUE, {
      header: () => (
        <span className="text-wrap">
          <FormattedMessage
            id="ReferralLevelRevenuePrdx"
            values={{ level: 'A' }}
          />
        </span>
      ),
      cell: (info) => (
        <span className="fs-14">
          {info.getValue() ? (
            <FormattedCurrency value={info.getValue()} currency={currency} />
          ) : (
            '-'
          )}
        </span>
      ),
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
    columnHelper.accessor(EARNING_HISTORY_COLUMN_NAMES.LEVEL_B_REVENUE, {
      header: () => (
        <span className="text-wrap">
          <FormattedMessage
            id="ReferralLevelRevenuePrdx"
            values={{ level: 'B' }}
          />
        </span>
      ),
      cell: (info) => (
        <span className="fs-14">
          {info.getValue() ? (
            <FormattedCurrency value={info.getValue()} currency={currency} />
          ) : (
            '-'
          )}
        </span>
      ),
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
    columnHelper.accessor(EARNING_HISTORY_COLUMN_NAMES.LEVEL_C_REVENUE, {
      header: () => (
        <span className="text-wrap">
          <FormattedMessage
            id="ReferralLevelRevenuePrdx"
            values={{ level: 'C' }}
          />
        </span>
      ),
      cell: (info) => (
        <span className="fs-14">
          {info.getValue() ? (
            <FormattedCurrency value={info.getValue()} currency={currency} />
          ) : (
            '-'
          )}
        </span>
      ),
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
    columnHelper.accessor(EARNING_HISTORY_COLUMN_NAMES.TOTAL_REVENUE, {
      header: () => <FormattedMessage id="Total" />,
      cell: (info) => {
        const { value, coin } = info.getValue();

        if (!value) {
          return <span className="fs-12">-</span>;
        }

        return (
          <>
            <span className="fs-14 fw-700">
              <FormattedCurrency value={value} currency={currency} />
            </span>{' '}
            <span className="fs-12 text-uppercase">{coin}</span>
          </>
        );
      },
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
    columnHelper.accessor(EARNING_HISTORY_COLUMN_NAMES.DATA_AND_TIME, {
      header: () => <FormattedMessage id="DateAndTime" />,
      cell: (info) => (
        <span className="fs-12">
          {dateFormat(new Date(info.getValue()), DAY_MONTH_YEAR_TIME_FORMAT)}
        </span>
      ),
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
    columnHelper.accessor(EARNING_HISTORY_COLUMN_NAMES.EARNING_IN_COIN, {
      header: () => (
        <FormattedMessage
          id="EarnedInCoin"
          values={{ coin: CRYPTO_CURRENCIES.PRDX.toUpperCase() }}
        />
      ),
      cell: (info) => (
        <span className="fs-14 fw-600">
          {info.getValue() ? `+ ${info.getValue()}` : '-'}
        </span>
      ),
      enableSorting: false,
      headerAlign: 'end',
      cellAlign: 'end',
    }),
  ];

  return allColumns
    .filter((col) => !hideColumnNames.includes(col.accessorKey))
    .map((column, index) => {
      if (index === 0) {
        return {
          ...column,
          headerAlign: 'start',
          cellAlign: 'start',
        };
      }
      return column;
    });
};

export default earningHistoryColumns;
