import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  announcement: null,
  previousAnnouncementId: null,
  nextAnnouncementId: null,
  isSuccess: false,
  topic: '',
};

const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    setAnnouncement: (state, action) => {
      const {
        announcement,
        previousAnnouncement,
        nextAnnouncement,
        isSuccess,
      } = action.payload;

      state.announcement = announcement;
      state.previousAnnouncementId = previousAnnouncement.id;
      state.nextAnnouncementId = nextAnnouncement.id;
      state.isSuccess = isSuccess;
    },
    setTopic: (state, action) => {
      state.topic = action.payload;
    },
  },
});

const announcementActions = announcementSlice.actions;
const announcementReducer = announcementSlice.reducer;

export { announcementActions, announcementReducer };

export default announcementSlice;
