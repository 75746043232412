import { createSelector } from '@reduxjs/toolkit';

const featureSelector = (state) => state.tradingBots.myBots;

const filtersSelector = createSelector(featureSelector, (myBots) => {
  return myBots.filters;
});

const paginationSelector = createSelector(featureSelector, (myBots) => {
  return myBots.pagination;
});

export const MyBotsSelectors = {
  filtersSelector,
  paginationSelector,
};
