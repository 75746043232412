import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGroupedAvailablePlans } from '@pages/WalletsPage/Staking/hooks';
import { WalletsUtils } from '@pages/WalletsPage/walletsPage.utils';

import {
  useAccountBalances,
  useCoinsInfo,
  useDefaultAccount,
} from '@store/api/hooks';
import { useGetStakingSettings } from '@store/api/hooks';

import { AvailablePlansSelectors } from '../selectors';

import useSelectedGlobalPeriod from './useSelectedGlobalPeriod';

const useAvailablePlansTable = ({ isAllPeriodsSelected } = {}, options) => {
  const searchValue = useSelector(AvailablePlansSelectors.searchSelector);
  const selectedPeriods = useSelector(
    AvailablePlansSelectors.selectedPeriodsSelector,
  );

  const { defaultSpotAccount } = useDefaultAccount(undefined, options);

  const { coinsInfo } = useCoinsInfo(undefined, options);

  const { accountBalancesMap } = useAccountBalances(
    {
      accountId: defaultSpotAccount?.id,
    },
    options,
  );

  const { selectedGlobalPeriod } = useSelectedGlobalPeriod();

  const { coins, limits, periods, isStakingSettingsSuccess } =
    useGetStakingSettings();

  const availablePlansCoinSymbols = useMemo(
    () =>
      Object.entries(coins)
        .filter(([coin, isActive]) => isActive)
        .map((coin) => coin[0]),
    [coins],
  );

  const availablePlans = useMemo(
    () =>
      accountBalancesMap
        .map((balance) => {
          const coinSymbol = balance.symbol;
          const coinInfo = coinsInfo?.[coinSymbol];
          const period =
            periods?.find(
              ({ period }) => period === selectedPeriods[coinSymbol]?.period,
            ) || periods[0];
          const selectedPeriod = isAllPeriodsSelected
            ? selectedGlobalPeriod
            : period;

          return {
            asset: {
              coinSymbol,
              fullName: coinInfo?.name,
            },
            period: { active: selectedPeriod?.period, periods, coinSymbol },
            estimation: selectedPeriod?.percent[coinSymbol] * 100,
            minDeposit: {
              minDeposit: limits[coinSymbol]?.min,
              coinSymbol,
            },
            action: {
              coinSymbol,
            },
          };
        })
        .filter(({ asset }) =>
          availablePlansCoinSymbols.includes(asset.coinSymbol),
        )
        .filter(({ asset }) => {
          const coinSymbol = asset.coinSymbol;
          const fullName = asset.fullName;

          if (searchValue === '') {
            return fullName;
          }

          const isItemWasFound = WalletsUtils.isSomeContractExists(
            [coinSymbol, fullName],
            searchValue,
          );

          return isItemWasFound;
        }),
    [
      accountBalancesMap,
      coinsInfo,
      periods,
      isAllPeriodsSelected,
      selectedGlobalPeriod,
      limits,
      selectedPeriods,
      availablePlansCoinSymbols,
      searchValue,
    ],
  );

  const groupedAvailablePlans = useGroupedAvailablePlans(availablePlans);

  return {
    availablePlans,
    groupedAvailablePlans,
    limits,
    periods,
    isAvailablePlansSuccess: isStakingSettingsSuccess,
    isAllPeriodsSelected,
  };
};

export default useAvailablePlansTable;
