import { memo } from 'react';

import { BLOCK_BREAKPOINTS } from 'src/constants/blockBreakpoints';
import { BLOCK_TYPES } from 'src/constants/blockTypes';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';
import useBreakpoints from '@hooks/useBreakpoints';

import styles from './FieldContainer.module.scss';

const FieldContainer = ({
  label,
  forceShowLeftSection = false,
  children,
  layoutItemRef,
}) => {
  const { isTablet, isLaptop } = useBreakpoints();

  const { isMiniX, isLandscapeX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.trading],
  );

  const isShowLeftSection =
    forceShowLeftSection ||
    (!isTablet && !(isLaptop && isLandscapeX) && !isMiniX);

  return (
    <div className={styles.field_container}>
      {isShowLeftSection && <div className={styles.left_section}>{label}</div>}

      <div className={styles.right_section}>{children}</div>
    </div>
  );
};

export default memo(FieldContainer);
