import React, { memo } from 'react';

import classNames from 'classnames';

import { format } from '@utils/numbers';

import { PERCENT_PRECISION } from '@constants';

import { ReactComponent as UpDirectionIconSVG } from '@icons/up-direction-icon.svg';

import styles from './CoinValuePercentDynamics.module.scss';

const CoinValuePercentDynamics = ({
  value,
  percent,
  isShowValue = true,
  isShowPercentArrow = false,
  customStyles = {},
}) => {
  const isDown = Number(percent) < 0;

  const isNeutral = isNaN(Number(percent)) || Number(percent) === 0;

  return (
    <div
      className={classNames(
        styles.coin_dynamics,
        {
          [styles.down]: isDown,
          [styles.neutral]: isNeutral,
        },
        customStyles.coin_dynamics,
      )}
    >
      {isShowValue && (
        <div className={classNames(styles.pair_price, customStyles.pair_price)}>
          {!isNeutral && (
            <div
              className={classNames(styles.arrow_icon, customStyles.arrow_icon)}
            >
              <UpDirectionIconSVG className={styles.up_direction_icon} />
            </div>
          )}
          <div>{value}</div>
        </div>
      )}
      <div
        className={classNames(styles.pair_percent, customStyles.pair_percent)}
      >
        {!isNeutral && isShowPercentArrow && (
          <span
            className={classNames(styles.arrow_icon, customStyles.arrow_icon)}
          >
            <UpDirectionIconSVG className={styles.up_direction_icon} />
          </span>
        )}
        {isNeutral ? '' : isDown ? ' ' : ' +'}
        {format(percent, {
          precision: PERCENT_PRECISION,
        })}
        %
      </div>
    </div>
  );
};

export default memo(CoinValuePercentDynamics);
