import { memo } from 'react';
import { useSelector } from 'react-redux';

import TruncatedText from '@shared/components/TruncatedText/TruncatedText';

import { useCoinsRates, useCoinsInfo } from '@store/api/hooks';
import { isShowSensitiveInfoSelector } from '@store/settings/settingsSelectors';

import { format } from '@utils/numbers';

import { PERSONAL_DATA_PLUG } from '@constants';

import styles from './EstimationValueItem.module.scss';

const EstimationValueItem = ({
  total,
  coinSymbol,
  forcedPrecision,
  selectedCoin,
}) => {
  const isShowSensitiveInfo = useSelector(isShowSensitiveInfoSelector);

  const { ratesByPair: coinRate } = useCoinsRates({
    fromCoinSymbol: selectedCoin,
    toCoinSymbol: coinSymbol,
  });

  const { coinInfo } = useCoinsInfo({ coinSymbol: selectedCoin });

  const estimationValue = coinRate > 0 ? total / coinRate : 0;

  const formattedEstimationValue = isShowSensitiveInfo
    ? format(estimationValue, {
        precision: forcedPrecision ?? coinInfo?.digits,
      })
    : PERSONAL_DATA_PLUG;

  return (
    <TruncatedText>
      <span className={styles.bold_text}>{formattedEstimationValue}</span>
    </TruncatedText>
  );
};

export default memo(EstimationValueItem);
