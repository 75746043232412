import cx from 'classnames';

import s from './TableBodyRow.module.scss';

const TableBodyRow = ({
  children,
  rowHeight,
  variantRows,
  isClickableRow,
  isSwipeableTable = false,
  inversion,
}) => (
  <tr
    className={cx(s.body_row, s[rowHeight], s[variantRows], 'table__body-row', {
      [s.is_clickable]: isClickableRow && !isSwipeableTable,
      'cursor-pointer': isClickableRow && isSwipeableTable,
      [s.inversion]: inversion,
    })}
  >
    {children}
  </tr>
);

export default TableBodyRow;
