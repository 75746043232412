import React from 'react';

import cx from 'classnames';

import { formatNumberToK } from '@shared/utils';

import s from './VisaCardCount.module.scss';

const VisaCardCount = ({ count, symbol }) => {
  return (
    <div className={s.visa_card_count}>
      <span className={s.visa_card_count__title}>{count.title}</span>
      <div className={s.visa_card_count__data}>
        <div className={s.visa_card_count__amount_container}>
          <span className={s.visa_card_count__amount}>
            {formatNumberToK(count.amount)}
          </span>
          <span className={s.visa_card_count__currency}>{symbol}</span>
        </div>
        <span
          className={cx(s.visa_card_count__increase, {
            [s.increase__positive]: count.increase > 0,
          })}
        >
          {count.increase > 0 && '+'}
          {count.increase}
        </span>
      </div>
    </div>
  );
};

export default VisaCardCount;
