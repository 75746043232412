import { Children, cloneElement } from 'react';

const TabPanels = ({ selectedTabIndex, children }) => {
  return Children.map(children, (child, tabIndex) => {
    return child
      ? cloneElement(child, {
          selectedTabIndex,
          tabIndex,
        })
      : null;
  });
};

export default TabPanels;
