import { VisaApiSelectors } from '@api/selectors/visaAPI.selectors';
import { useGetCardDetailsQuery } from '@api/visaAPI';

const useGetCardDetailsQueryResult = (args, options = {}) => {
  return useGetCardDetailsQuery(args, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      cardDetails: VisaApiSelectors.cardDetails(result),
    }),
  });
};

export default useGetCardDetailsQueryResult;
