import { useEffect, useState } from 'react';

import cx from 'classnames';

import { Button } from '@shared/ui';

import s from './ButtonGroup.module.scss';

/**
 * Example of items:
 *
 * const items = [ { id: 1, value: 'value 1' },... ];
 * */

const ButtonGroup = ({
  items,
  idKey = 'id',
  valueKey = 'value',
  selectedItemId,
  onClick = (item) => {},
  customStyles = {},
  children,
  isScrollable,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState(
    selectedItemId
      ? items.find((item) => item[idKey] === selectedItemId)
      : undefined,
  );

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    onClick(item);
  };

  // to be able to reset from parent component by pass selectedItemId as undefined
  useEffect(() => {
    if (
      selectedItemId === undefined &&
      selectedItem?.[idKey] !== selectedItemId
    ) {
      setSelectedItem(undefined);
    }
  }, [idKey, selectedItemId]);

  return (
    <div
      className={cx(s.button_group, customStyles.button_group, {
        [s.scrollable_buttons]: isScrollable,
      })}
    >
      {items.map((item) => {
        const key = idKey ? item[idKey] : item;
        const value = valueKey ? item[valueKey] : item;

        const isSelected = key === selectedItem?.id;
        const isDisabled = item?.isDisabled;

        return (
          <Button
            onClick={() => handleSelectItem(item)}
            active={isSelected}
            key={key}
            customStyles={customStyles}
            type="button"
            disabled={isDisabled}
            {...props}
          >
            {children ? children(value, item, isSelected) : value}
          </Button>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
