import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { LandingPageContainer } from '@pages/landings/components';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as AppleIconSVG } from '@images/icons/apple-icon.svg';

import { ReactComponent as ExchangeIconSVG } from '@icons/exchange-2-icon.svg';
import { ReactComponent as GoogleIconSVG } from '@icons/google-auth-icon.svg';
import { ReactComponent as PayIconSVG } from '@icons/pay-icon.svg';
import { ReactComponent as WalletIconSVG } from '@icons/wallet-2-icon.svg';

import styles from './DiscoverVisaCardBenefits.module.scss';

const DiscoverVisaCardBenefitsItem = memo(({ title, description, icons }) => {
  const { isLargeTabletDown } = useBreakpoints();

  return (
    <div className={styles.item}>
      <div className={styles.title}>{title}</div>

      <div className={styles.content}>
        <div className={styles.description}>{description}</div>

        <div className={styles.icons}>
          {icons.map((icon, index) => {
            const isLastIcon = index === icons.length - 1;
            const isSingleIcon = icons.length === 1;
            const isShowLine =
              (!isLastIcon || isSingleIcon) && !isLargeTabletDown;

            return (
              <>
                <div key={index} className={styles.icon}>
                  {icon}
                </div>
                {isShowLine && <div className={styles.line} />}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
});

const DiscoverVisaCardBenefits = () => {
  const { isTabletDown } = useBreakpoints();

  const appleIconWidth = isTabletDown ? 20 : 30;
  const appleIconHeight = isTabletDown ? 24 : 36;

  const googleIconSize = isTabletDown ? 24 : 36;

  const securityWalletSize = isTabletDown ? 24 : 36;

  const payIconSize = isTabletDown ? 24 : 36;
  const tradingIconSize = isTabletDown ? 24 : 36;

  return (
    <section className={styles.section}>
      <LandingPageContainer>
        <div className="row gy-50">
          <div className={classNames('col-12 col-md-6', styles.left_block)}>
            <DiscoverVisaCardBenefitsItem
              title={<FormattedMessage id="Licensed" />}
              description={<FormattedMessage id="LicensedDescription" />}
              icons={[
                <AppleIconSVG
                  width={appleIconWidth}
                  height={appleIconHeight}
                />,
                <GoogleIconSVG
                  className={styles.google_icon}
                  width={googleIconSize}
                  height={googleIconSize}
                />,
                <PayIconSVG width={payIconSize} height={payIconSize} />,
              ]}
            />
            <DiscoverVisaCardBenefitsItem
              title={<FormattedMessage id="SecurityWallet" />}
              description={<FormattedMessage id="SecurityWalletDescription" />}
              icons={[
                <WalletIconSVG
                  width={securityWalletSize}
                  height={securityWalletSize}
                />,
              ]}
            />
          </div>
          <div className="col-6 col-md-3 d-flex">
            <DiscoverVisaCardBenefitsItem
              title={<FormattedMessage id="EasyTrading" />}
              description={<FormattedMessage id="EasyTradingDescription" />}
              icons={[
                <ExchangeIconSVG
                  width={tradingIconSize}
                  height={tradingIconSize}
                />,
              ]}
            />
          </div>
        </div>
      </LandingPageContainer>
    </section>
  );
};

export default memo(DiscoverVisaCardBenefits);
