import React, { memo } from 'react';

import { components } from 'react-select';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';

import styles from './CustomClearIndicator.module.scss';

const { ClearIndicator } = components;

const CustomClearIndicator = (props) => (
  <ClearIndicator {...props}>
    <CrossThinIconSVG className={styles.cross_icon} />
  </ClearIndicator>
);

CustomClearIndicator.displayName = 'ClearIndicator';

export default memo(CustomClearIndicator);
