import cx from 'classnames';

import s from './Loader.module.scss';

const Loader = ({
  color = 'green',
  size = 'm', // xs | s | xxl
  customStyles = {},
  ...rest
}) => {
  return (
    <div
      className={cx(s.loader, s[color], s[size], customStyles.loader)}
      {...rest}
    />
  );
};

export default Loader;
