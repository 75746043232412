import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import { getAsset } from '@utils';

import { CHART_STYLES_LIST, NOOP } from '@constants';

import { ReactComponent as InfoIconSVG } from '@icons/info-icon.svg';

import styles from './ChartStyles.module.scss';

const ChartStyles = ({
  config,
  isActiveStyle = NOOP,
  handleSelectChartType,
}) => {
  const generateListItem = (item) => {
    if (item.type === 'radio') {
      return (
        <li
          key={'chart-styles-item-' + item.value}
          className={classnames(styles.item__list__item, {
            [styles['item__list__item--active']]: isActiveStyle(item),
          })}
          onClick={() => handleSelectChartType(item)}
        >
          <cq-item stxsetget={item.cmd} data-value={item.value}>
            <span>{getAsset(CHART_STYLES_LIST, item.value)}</span>
            {item.label}
          </cq-item>
        </li>
      );
    }
  };

  return (
    <div className={styles.chart_styles}>
      {config && (
        <>
          <div className={styles.item}>
            <p className={styles.item__title}>
              <FormattedMessage id="Standard" />
            </p>
            <ul className={styles.item__list}>
              {config.menuChartStyle.map((style) => generateListItem(style))}
            </ul>
          </div>
          <div className={styles.item}>
            <p className={styles.item__title}>
              <FormattedMessage id="Advanced" />
            </p>
            <ul className={styles.item__list}>
              {config.menuChartAggregates.map((aggregate) =>
                generateListItem(aggregate),
              )}
            </ul>
            <div className={styles.item__info}>
              <InfoIconSVG />
              <p>
                <FormattedMessage id="ChartStylePopupInfo" />
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(ChartStyles);
