import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useFormContext, useWatch } from 'react-hook-form';

import { GOOGLE_OTP_NUMBER_LENGTH } from '@shared/constants';
import { OTPInputController, PasswordInputController } from '@shared/ui';
import { validatePassword } from '@shared/validations';

import styles from './OtpForm.module.scss';

const OtpForm = () => {
  const intl = useIntl();

  const methods = useFormContext();

  const { trigger, control } = methods;

  const watchCode = useWatch({ control, name: 'code' });

  useEffect(() => {
    if (watchCode?.length === GOOGLE_OTP_NUMBER_LENGTH) {
      trigger('loginPassword');
    }
  }, [watchCode]);

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    size: 'l',
  };

  return (
    <div className={styles.form}>
      <PasswordInputController
        name="loginPassword"
        label={`${intl.formatMessage({
          id: 'EnterPassword',
        })}:`}
        placeholder={intl.formatMessage({
          id: 'EnterPassword',
        })}
        rules={{
          required: intl.formatMessage({ id: 'PasswordIsRequired' }),
          validate: { format: validatePassword(intl) },
        }}
        {...commonFieldProps}
        {...methods}
      />

      <OTPInputController
        name="code"
        label={`${intl.formatMessage({ id: 'EnterCode' })}:`}
        numInputs={GOOGLE_OTP_NUMBER_LENGTH}
        size="xl"
        rounded
        rules={{
          required: intl.formatMessage({ id: 'FieldRequired' }),
          minLength: {
            value: GOOGLE_OTP_NUMBER_LENGTH,
            message: intl.formatMessage({ id: 'FieldRequired' }),
          },
        }}
        customStyles={styles}
        {...methods}
      />
    </div>
  );
};

export default OtpForm;
