import { FormattedMessage } from 'react-intl';

import ReferralTable from '@pages/AccountPage/ReferralProgram/components/ReferralTable/ReferralTable';
import {
  EARNING_HISTORY_COLUMN_NAMES,
  HISTORY_COLUMN_NAMES,
} from '@pages/AccountPage/ReferralProgram/components/ReferralTable/referralTable.constant';

import mockData from 'src/mock.json';

export const referralTablesTabs = [
  {
    id: 'last_earnings',
    title: (
      <span className="fs-16">
        <FormattedMessage id="LastEarnings" />
      </span>
    ),
    content: (
      <ReferralTable
        variant="earningHistory"
        queryParams={mockData.Referrals.queryParams}
        stripedRows={false}
        wideRowsS
        isSmallAvatars
        hideColumnNames={[
          EARNING_HISTORY_COLUMN_NAMES.DATA_AND_TIME,
          EARNING_HISTORY_COLUMN_NAMES.LEVEL_A_REVENUE,
          EARNING_HISTORY_COLUMN_NAMES.LEVEL_B_REVENUE,
          EARNING_HISTORY_COLUMN_NAMES.LEVEL_C_REVENUE,
          EARNING_HISTORY_COLUMN_NAMES.TOTAL_REVENUE,
        ]}
      />
    ),
  },
  {
    id: 'last_referred',
    title: (
      <span className="fs-16">
        <FormattedMessage id="LastReferred" />
      </span>
    ),
    content: (
      <ReferralTable
        variant="referralHistory"
        queryParams={mockData.Referrals.queryParams}
        stripedRows={false}
        wideRowsS
        isSmallAvatars
        hideColumnNames={[
          HISTORY_COLUMN_NAMES.DATE_OF_INVITE_LEVEL_A,
          HISTORY_COLUMN_NAMES.LEVEL_B_INVITED,
          HISTORY_COLUMN_NAMES.LEVEL_C_INVITED,
        ]}
      />
    ),
  },
];
