import styles from './SearchMenuItem.module.scss';

export const HightLightText = ({ text, hightLightText }) => {
  const indexHighlightedString = text.toLowerCase().indexOf(hightLightText);

  const startTextValue = text.slice(0, indexHighlightedString);
  const hightLightTextValue = text.slice(
    indexHighlightedString,
    indexHighlightedString + hightLightText.length,
  );
  const endTextValue = text.slice(
    indexHighlightedString + hightLightText.length,
  );

  return (
    <span className={styles.hight_light_text}>
      {startTextValue}
      <b>{hightLightTextValue}</b>
      {endTextValue}
    </span>
  );
};
