import { memo } from 'react';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import { TableFilters } from '@shared/components';
import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

import { ReactComponent as GridIconSVG } from '@icons/grid-icon.svg';
import { ReactComponent as ListIconSVG } from '@icons/list-icon.svg';

import styles from './AvailablePlansFilters.module.scss';

const AvailablePlansFilters = ({ isGrid, setIsGrid, onSearchChange }) => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      [TABLE_FILTERS_NAMES.SEARCHBAR]: '',
    },
  });

  const handleFilterChange = (values) => {
    onSearchChange(values);
  };

  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <TableFilters
        onControlsClick={() => {}}
        onFiltersChange={handleFilterChange}
        searchbar
        customStyles={{ searchbar: styles }}
        {...methods}
      />

      <div className="d-flex align-items-center gap-28 gap-xl-16">
        <GridIconSVG
          width={16}
          height={16}
          onClick={() => setIsGrid(true)}
          className={classNames(styles.icon, isGrid && styles.active)}
        />

        <ListIconSVG
          width={16}
          height={16}
          onClick={() => setIsGrid(false)}
          className={classNames(styles.icon, !isGrid && styles.active)}
        />
      </div>
    </div>
  );
};

export default memo(AvailablePlansFilters);
