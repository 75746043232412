import React, { memo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { Transition } from 'react-transition-group';

import { useScroll } from '@shared/hooks';

import Breakpoint from '@components/Breakpoint';

import useBreakpoints from '@hooks/useBreakpoints';
import useOnClickOutside from '@hooks/useClickOutside';
import useSidebar from '@hooks/useSidebar/useSidebar';

import { sidebarIds } from '@constants';

import { ReactComponent as CrossThinIconSVG } from '@images/icons/cross-thin-icon.svg';
import { ReactComponent as MenuIconSVG } from '@images/icons/menu-icon.svg';

import ProductMenuFooter from './components/ProductMenuFooter';
import { ProductsMenuAllMarkets } from './components/ProductsMenuAllMarkets';
import ProductsMenuNavigation from './components/ProductsMenuNavigation';
import PromoSection from './components/PromoSection';

import mockData from 'src/mock.json';

const { withNewBadge } = mockData.ProductsMenu;

const ProductsMenu = () => {
  const containerRef = useRef();
  const productsMenuRef = useRef();

  const { isOpen, openSidebar, closeSidebar, closeAllSidebars } = useSidebar(
    sidebarIds.PRODUCTS,
  );

  const { isLaptop, isDesktop } = useBreakpoints();

  const { enableScroll, disableScroll } = useScroll();

  useOnClickOutside([containerRef, productsMenuRef], closeAllSidebars, isOpen);

  const toggleMenuOpen = (e) => {
    if (isOpen) {
      enableScroll();
      closeSidebar();
    } else {
      disableScroll();
      closeAllSidebars();
      openSidebar();
    }
  };

  useHotkeys(
    'esc',
    () => {
      if (isOpen) {
        toggleMenuOpen();
      }
    },
    [isOpen],
  );

  const handleCloseSideBar = () => {
    closeSidebar();
  };

  return (
    <div className={classnames('products-menu', { 'is-menu-open': isOpen })}>
      <div
        className="products-menu-button"
        onClick={toggleMenuOpen}
        ref={productsMenuRef}
      >
        <div
          className={classnames('products-menu-button', {
            'is-new-badge': withNewBadge,
          })}
        >
          <MenuIconSVG className="menu-icon" />
        </div>

        <Breakpoint size="xl" direction="up">
          <span className="title">
            <FormattedMessage id="Products" />
          </span>
        </Breakpoint>
      </div>

      {createPortal(
        <Transition in={isOpen} timeout={200} unmountOnExit>
          {(state) => (
            <div
              className="products-menu-view"
              style={{
                transition: 'opacity 0.2s ease',
                opacity: state === 'entered' ? 1 : 0,
              }}
              ref={containerRef}
            >
              <div
                className="products-menu-view__space"
                onClick={toggleMenuOpen}
              />

              <div className="flex-1 d-flex flex-column products-menu-container">
                <Breakpoint size="xl" direction="down">
                  <div className="products-menu-view__title_container">
                    <span className="products-menu-view__title">
                      <FormattedMessage id="Products" />
                    </span>

                    <ProductsMenuAllMarkets
                      onCloseSideBar={handleCloseSideBar}
                    />
                  </div>
                </Breakpoint>

                <ProductsMenuNavigation onLinkClick={handleCloseSideBar} />

                {(isDesktop || isLaptop) && (
                  <ProductMenuFooter onCloseSideBar={handleCloseSideBar} />
                )}
              </div>

              <CrossThinIconSVG
                className="products-menu-view__close-icon"
                onClick={toggleMenuOpen}
              />

              {isDesktop && <PromoSection />}
            </div>
          )}
        </Transition>,
        document.getElementById('modal-container'),
      )}
    </div>
  );
};

export default memo(ProductsMenu);
