import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import cx from 'classnames';

import { Button } from '@shared/ui';

import { ACCOUNT_ROUTES } from '../../../../../routes.constants';

import styles from './KycLevelInfo.module.scss';

const KycLevelInfo = ({
  isShowBottomLine,
  kycLevel,
  withdrawLimit,
  isCurrentKycLevel,
  isShowStartButton,
  isShowLevelAdditionalInfo,
}) => {
  const currency = 'BTC';

  const intl = useIntl();

  const levelAdditionalInfo = useMemo(() => {
    if (!isShowLevelAdditionalInfo) return null;

    return intl.formatMessage(
      { id: `kycAdditionalInfo${kycLevel}` },
      { withdrawLimit: `${withdrawLimit} ${currency}` },
    );
  }, [isShowLevelAdditionalInfo, kycLevel, withdrawLimit]);

  return (
    <div className={cx(styles.kyc_level_info, isShowBottomLine && 'border-0')}>
      <div className={styles.kyc_level_info__main}>
        <div className="d-flex flex-column">
          <div className="fw-400">
            <FormattedMessage id="KYCLevelNumber" values={{ kycLevel }} />{' '}
            <FormattedMessage id="Level" />
          </div>
          <div className={styles.withdraw_limit}>
            {withdrawLimit} {currency}
          </div>
        </div>

        {isCurrentKycLevel && <FormattedMessage id="CurrentLevel" />}

        {isShowStartButton && (
          <Button
            type="button"
            navigation={{ to: ACCOUNT_ROUTES.KYC }}
            variant="outlined"
          >
            <FormattedMessage id="Start" />
          </Button>
        )}
      </div>
      {isShowLevelAdditionalInfo && (
        <div className={styles.kyc_level_info__additional}>
          {levelAdditionalInfo}
        </div>
      )}
    </div>
  );
};
export default memo(KycLevelInfo);
