import { useMemo } from 'react';

import { GROUPED_AVAILABLE_PLANS } from '../staking.constants';

const getInitialGroupedValue = () =>
  Object.values(GROUPED_AVAILABLE_PLANS).reduce((acc, value) => {
    acc[value] = [];

    return acc;
  }, {});

const useGroupedAvailablePlans = (availablePlans) => {
  const groupedPlans = useMemo(() => {
    return availablePlans.reduce((acc, current) => {
      acc[GROUPED_AVAILABLE_PLANS.ALL].push(current);

      if (current.estimation >= 5 && current.estimation <= 10) {
        acc[GROUPED_AVAILABLE_PLANS.FROM_5_TO_10].push(current);
      }

      if (current.estimation > 10) {
        acc[GROUPED_AVAILABLE_PLANS.MORE_10].push(current);
      }

      return acc;
    }, getInitialGroupedValue());
  }, [availablePlans]);

  return groupedPlans;
};

export default useGroupedAvailablePlans;
