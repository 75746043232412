import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Notification } from '@shared/components';

import Link from '@components/Link';
import Loader from '@components/Loader';
import NoData from '@components/NoData';
import SideBar from '@components/SideBar';

import useNotificationsList from '@pages/AccountPage/Notifications/hooks/useNotiticationsList';

import { useNotificationMutations } from '@store/api/hooks';
import { useNotificationsTotalUnreadQuery } from '@store/api/notificationsAPI';
import { isAccountThemeSelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import { ReactComponent as BellIconSVG } from '@icons/bell-icon.svg';
import { ReactComponent as CrossIconSVG } from '@icons/cross-icon.svg';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

import styles from './SideBarNotifications.module.scss';

const SideBarNotifications = () => {
  const isAccountTheme = useSelector(isAccountThemeSelector);

  const { onDeleteNotifications } = useNotificationMutations();

  const { isMobile, isTablet } = useBreakpoints();
  const { isOpen, toggleSidebar, closeSidebar } = useSidebar(
    sidebarIds.NOTIFICATIONS,
  );

  const { data: notificationsTotalUnread } = useNotificationsTotalUnreadQuery(); // without skip to show total count in the header

  const {
    notifications,
    todayNotifications,
    earlierNotifications,
    isSuccess: isLoaded,
  } = useNotificationsList(); // without skip to detect new notification via WS and call total unread counter update

  const toggleSideBarNotifications = (e) => toggleSidebar();
  const closeSidebarNotifications = () => closeSidebar();

  return (
    <>
      <div
        className={classnames(styles.sidebar_notifications, {
          [styles['sidebar_notifications--active']]: isOpen,
        })}
        onClick={toggleSideBarNotifications}
      >
        <BellIconSVG className={styles.bell_icon} />

        {!!notificationsTotalUnread && (
          <div className={styles.count_badge}>{notificationsTotalUnread}</div>
        )}
      </div>

      <SideBar
        className={styles.sidebar}
        open={isOpen}
        placement="right"
        handler={false}
        level={null}
        onClose={closeSidebarNotifications}
        withCloseButton={isTablet || isMobile}
        title={
          (isTablet || isMobile) && <FormattedMessage id="Notifications" />
        }
      >
        <div className={styles.content}>
          {isLoaded ? (
            <div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className={styles.title}>
                    <FormattedMessage id="Today" />
                  </div>

                  {!isEmpty(notifications) && (
                    <div
                      className={styles.read_all}
                      onClick={onDeleteNotifications}
                    >
                      <FormattedMessage id="ClearAll" />

                      <CrossIconSVG width={12} height={12} />
                    </div>
                  )}
                </div>

                {isEmpty(todayNotifications) ? (
                  <NoData className="fs-12" inversion={isAccountTheme} />
                ) : (
                  todayNotifications.map((notification) => (
                    <Notification
                      customStyles={styles}
                      key={notification.id}
                      item={notification}
                      isShowDeleteButton
                      isDateAtBottom
                    />
                  ))
                )}
              </div>

              <div className={styles.earlier_section}>
                <div className={styles.title}>
                  <FormattedMessage id="Earlier" />
                </div>

                {isEmpty(earlierNotifications) ? (
                  <NoData className="fs-12" inversion={isAccountTheme} />
                ) : (
                  earlierNotifications.map((notification) => (
                    <Notification
                      customStyles={styles}
                      key={notification.id}
                      item={notification}
                      dateFormatString="MMMM dd"
                      isShowDeleteButton
                      isDateAtBottom
                    />
                  ))
                )}
              </div>
            </div>
          ) : (
            <div className="flex-1 d-flex align-items-center justify-content-center">
              <Loader s />
            </div>
          )}

          <div className={styles.footer}>
            <Link
              to={ACCOUNT_ROUTES.NOTIFICATIONS}
              onClick={closeSidebarNotifications}
              className={styles.view_all}
              bordered
              inversion={isAccountTheme}
            >
              <FormattedMessage id="ViewAllNotifications" />
            </Link>
          </div>
        </div>
      </SideBar>
    </>
  );
};

export default memo(SideBarNotifications);
