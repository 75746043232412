import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shared/ui';

import { DailyWithdrawalLimitWidget } from '../DailyWithdrawalLimitWidget';
import { ProfileKYCLevelWidget } from '../ProfileKYCLevelWidget';
import { TradingFeesWidget } from '../TradingFeesWidget';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

const UserInfoHeaderCollapsible = () => {
  return (
    <div className="row">
      <div className="col-12 d-md-none mb-16">
        <Button
          fill
          navigation={{ to: ACCOUNT_ROUTES.PROFILE }}
          variant="outlined"
        >
          <FormattedMessage id="Profile" />
        </Button>
      </div>

      <hr className="d-md-none" />

      <div className="col-6 col-md-4 col-xl-2">
        <TradingFeesWidget />
      </div>

      <div className="col-6 col-md-4 col-xl-2">
        <ProfileKYCLevelWidget />
      </div>

      <hr className="d-md-none" />

      <div className="col-6 col-md-4 col-lg-auto col-xxl-3">
        <DailyWithdrawalLimitWidget />
      </div>

      <div className="d-md-none col-6 col-md-4 d-flex align-items-center">
        <Button navigation={{ to: ACCOUNT_ROUTES.KYC }} variant="outlined">
          <FormattedMessage id="Upgrade" />
        </Button>
      </div>
    </div>
  );
};

export default memo(UserInfoHeaderCollapsible);
