import { createColumnHelper } from '@tanstack/react-table';

import { ColumnHeaderItem, FormattedPercent } from '@shared/components';

import { AssetItem, EstimationItem } from '@pages/WalletsPage/components';

import { FilledItem } from './FilledItem';

const { accessor } = createColumnHelper();

const stakingTableColumns = () => [
  accessor('asset', {
    header: () => <ColumnHeaderItem id="Asset" />,
    cell: (info) => {
      const { coinSymbol } = info.getValue();

      return (
        <AssetItem coinSymbol={coinSymbol} iconHeight={28} iconWidth={28} />
      );
    },
    maxSize: 80,
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
    enableTruncating: true,
  }),
  accessor('estimation', {
    header: () => <ColumnHeaderItem id="Bonus" />,
    cell: (info) => {
      const { percent } = info.getValue();

      const formattedPercent = (
        <FormattedPercent percent={percent} removeZeroEnd />
      );

      return <EstimationItem estimation={formattedPercent} />;
    },
    maxSize: 50,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
    enableTruncating: false,
  }),
  accessor('remainingDays', {
    header: () => <ColumnHeaderItem id="Filled" />,
    cell: (info) => {
      const { remainingDays, durationDays } = info.getValue();

      return (
        <FilledItem remainingDays={remainingDays} durationDays={durationDays} />
      );
    },
    maxSize: 220,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
    enableTruncating: false,
  }),
];

export default stakingTableColumns;
