import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../utils';

const defaultData = [];

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const launchpadsSelector = createSelector(dataSelector, (data) => {
  return data.Launchpads || defaultData;
});

export const LaunchpadProjectsSelectors = {
  launchpadsSelector,
};
