import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { sum } from 'lodash';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import useInstantBonusTable from './useInstantBonusTable';

const useTotalEarned = () => {
  const { value: currency } = useSelector(currencySelector);

  const { contracts } = useInstantBonusTable();

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.BTC,
    toCoinSymbol: currency,
  });

  const totalEarnedInBTC = useMemo(
    () => sum(contracts.map(({ earnedBTC: { earnedBTC } }) => earnedBTC)),
    [contracts],
  );

  const totalEarnedInSelectedCurrency = totalEarnedInBTC * ratesByPair;

  return { currency, totalEarnedInBTC, totalEarnedInSelectedCurrency };
};

export default useTotalEarned;
