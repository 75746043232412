import React, { memo } from 'react';

import { format } from '@utils/numbers';

import { PERCENT_PRECISION } from '@constants';

import styles from './BonusItem.module.scss';

const BonusItem = ({ info }) => {
  const percent = format(info.getValue(), {
    precision: PERCENT_PRECISION,
    removeZeroEnd: true,
  });

  return <span className={styles.green}>+{percent}%</span>;
};

export default memo(BonusItem);
