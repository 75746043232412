import { useMemo } from 'react';

import { MyStakingSelectors } from '@api/selectors';
import { useLazyStakingListQuery, useStakingListQuery } from '@api/stakingAPI';

const selectFromResult = (result) => {
  const dataResult = {
    isMyStakingSuccess: result.isSuccess,
  };

  dataResult.myStaking = MyStakingSelectors.myStakingSelector(result);

  return dataResult;
};

export const useGetStakingList = ({ limit } = {}, options) => {
  const result = useStakingListQuery({ limit }, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export const useLazyGetStakingList = (options) => {
  const [trigger, result] = useLazyStakingListQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return [trigger, data];
};
