import React, { memo } from 'react';

import {
  AvailablePlansCard,
  StakingNoRecords,
} from '@pages/WalletsPage/Staking/components';
import { useAvailablePlansTable } from '@pages/WalletsPage/Staking/hooks';

const AvailablePlansGrid = () => {
  const { availablePlans } = useAvailablePlansTable();

  return (
    <div className="row g-24 g-md-30 g-xl-24">
      {availablePlans.map((availablePlan) => (
        <AvailablePlansCard
          key={availablePlan.asset.coinSymbol}
          availablePlan={availablePlan}
        />
      ))}

      {!availablePlans.length && <StakingNoRecords />}
    </div>
  );
};

export default memo(AvailablePlansGrid);
