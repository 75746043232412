import { useMemo } from 'react';

import { TransactionsApiSelectors } from '@api/selectors';
import { useGetTransactionsQuery } from '@api/transactionsAPI';

const selectFromResult = (result) => {
  const dataResult = {
    isTransactionsSuccess: result.isSuccess,
  };

  dataResult.transactions =
    TransactionsApiSelectors.transactionsSelector(result);
  dataResult.meta = TransactionsApiSelectors.metaSelector(result);

  return dataResult;
};

const useTransactions = (
  { accountId, page, type, limit, fromDate, toDate } = {},
  options = {},
) => {
  const result = useGetTransactionsQuery(
    { accountId, page, type, limit, fromDate, toDate },
    {
      ...options,
      skip: !accountId || options.skip,
    },
  );

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useTransactions;
