import { createApi } from '@reduxjs/toolkit/query/react';
import jwtDecode from 'jwt-decode';

import client from '@api/client';

import { getIsTokenPartial } from '@utils';
import { getFormData } from '@utils/api';

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    registerGeetest: builder.query({
      query: () => 'auth/geetest4/register',
    }),
    login: builder.mutation({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body: getFormData(body),
      }),
      transformResponse: (response) => {
        const decodedToken = jwtDecode(response.token);
        const isTokenPartial = getIsTokenPartial(decodedToken);

        return {
          ...response,
          ...decodedToken,
          isTokenPartial,
        };
      },
    }),
    loginPhone: builder.mutation({
      query: (body) => ({
        url: 'auth/login-phone',
        method: 'POST',
        body: getFormData(body),
      }),
      transformResponse: (response) => {
        const decodedToken = jwtDecode(response.token);
        const isTokenPartial = getIsTokenPartial(decodedToken);

        return {
          ...response,
          ...decodedToken,
          isTokenPartial,
        };
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'DELETE',
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: 'auth/register',
        method: 'POST',
        body: getFormData(body),
      }),
      transformResponse: (response) => {
        const decodedToken = jwtDecode(response.token);
        const isTokenPartial = getIsTokenPartial(decodedToken);

        return {
          ...response,
          ...decodedToken,
          isTokenPartial,
        };
      },
    }),
    registerPhone: builder.mutation({
      query: (body) => ({
        url: 'auth/register-phone',
        method: 'POST',
        body: getFormData(body),
      }),
      transformResponse: (response) => {
        const decodedToken = jwtDecode(response.token);
        const isTokenPartial = getIsTokenPartial(decodedToken);

        return {
          ...response,
          ...decodedToken,
          isTokenPartial,
        };
      },
    }),
    confirmEmail: builder.mutation({
      query: ({ token }) => ({
        url: `auth/email/confirm/${token}`,
        method: 'POST',
      }),
    }),
    phoneCode: builder.mutation({
      query: (body) => ({
        url: 'auth/register-phone/sms',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    confirmPhoneCode: builder.mutation({
      query: (body) => ({
        url: 'auth/register-phone/sms',
        method: 'PUT',
        body: getFormData(body),
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: 'auth/reset-password',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    sendReset2FACode: builder.mutation({
      query: (body) => ({
        url: 'auth/reset-2fa/recovery/send',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    verifyReset2FACode: builder.mutation({
      query: (body) => ({
        url: 'auth/reset-2fa/recovery/verify',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    approveAction: builder.mutation({
      query: ({ action_id, key }) => ({
        url: `actions/${action_id}/approve/${key}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useRegisterGeetestQuery,
  useLoginMutation,
  useLoginPhoneMutation,
  usePhoneCodeMutation,
  useRegisterMutation,
  useRegisterPhoneMutation,
  useConfirmPhoneCodeMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSendReset2FACodeMutation,
  useVerifyReset2FACodeMutation,
  useApproveActionMutation,
  useConfirmEmailMutation,
} = authAPI;
