import { Fragment } from 'react';

import cx from 'classnames';

import Loader from '@components/Loader';

import { NOOP } from '@constants';

import TableDataCell from './components/TableDataCell';
import TableDefaultBodyNoData from './components/TableDefaultBodyNoData';

const TableDefaultBody = ({
  id,
  table,
  data,
  subRow,
  virtualRows,
  isVirtualized = false,
  wideRows = false,
  wideRowsS = false,
  wideRowsL = false,
  wideRowsXL = false,
  stripedRows = true,
  underlinedRows,
  isLoading = false,
  tableHeight,
  paddingTop,
  paddingBottom,
  noDataProps = {},
  rowTooltipComponent: RowTooltipComponent = null,
  onRowClick = NOOP,
  onRowMouseDown = NOOP,
  onRowTouchStart = NOOP,
  onMouseEnterRow = NOOP,
  onMouseLeaveRow = NOOP,
  onTouchMove = NOOP,
  inversion,
}) => {
  const rows = table.getRowModel().rows;
  const bodyRow = isVirtualized ? virtualRows : rows;

  if (isLoading) {
    return (
      <TableDefaultBodyNoData
        table={table}
        noDataProps={{
          label: <Loader />,
          className: 'bg-transparent',
          style: { height: tableHeight },
        }}
      />
    );
  }

  if (!data.length) {
    return (
      <TableDefaultBodyNoData
        table={table}
        noDataProps={{
          ...noDataProps,
          style: { height: tableHeight, ...noDataProps.style },
        }}
      />
    );
  }

  return (
    <tbody className="fs-13">
      {isVirtualized && paddingTop > 0 && (
        <tr>
          <td style={{ height: paddingTop }} />
        </tr>
      )}

      {bodyRow.map((rowItem, index) => {
        const row = isVirtualized ? rows[rowItem.index] : rowItem;

        const rowComponent = (
          <tr
            className={cx('table-default__row', {
              'table-default__row--underlined': underlinedRows,
              'table-default__row--inversion': inversion,
            })}
          >
            {row.getVisibleCells().map((cell) => (
              <TableDataCell
                key={`${id}-${cell.id}`}
                index={index}
                cell={cell}
                stripedRows={stripedRows}
                style={{ width: cell.column.getSize() }}
                wideRows={wideRows}
                wideRowsS={wideRowsS}
                wideRowsL={wideRowsL}
                wideRowsXL={wideRowsXL}
                onRowClick={onRowClick}
                onRowMouseDown={onRowMouseDown}
                onRowTouchStart={onRowTouchStart}
                onMouseEnterRow={onMouseEnterRow}
                onMouseLeaveRow={onMouseLeaveRow}
                onTouchMove={onTouchMove}
              />
            ))}
          </tr>
        );

        return (
          <Fragment key={`${id}-${row.id}`}>
            {RowTooltipComponent ? (
              <RowTooltipComponent row={row}>
                {rowComponent}
              </RowTooltipComponent>
            ) : (
              rowComponent
            )}

            {row.getIsExpanded() ? subRow(row) : null}
          </Fragment>
        );
      })}

      {isVirtualized && paddingBottom > 0 && (
        <tr>
          <td style={{ height: paddingBottom }} />
        </tr>
      )}
    </tbody>
  );
};

export default TableDefaultBody;
