import { createSelector } from '@reduxjs/toolkit';

const featureSelector = (state) => state.tradingBots.archivedBots;

const filtersSelector = createSelector(featureSelector, (archivedBots) => {
  return archivedBots.filters;
});

const paginationSelector = createSelector(featureSelector, (archivedBots) => {
  return archivedBots.pagination;
});

export const ArchivedBotsSelectors = {
  filtersSelector,
  paginationSelector,
};
