import { useMemo } from 'react';

import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';
import { usePagination } from '@shared/hooks';

import { useBotsPnl, useMarketsInfo } from '@store/api/hooks';

import { TradingBotsUtils } from '../tradingBots.utils';

const selectFromResult = ({
  findBotPnl,
  marketsInfo,
  isMarketsInfoSuccess,
  filters,
  bots,
}) => {
  const filteredTableData = isMarketsInfoSuccess
    ? bots
        .map((bot) => {
          const settings = bot.versions[0]?.settings;
          const pnl = findBotPnl(bot);

          const marketInfo = marketsInfo?.[settings?.currencyPair];

          return {
            ...bot,
            pair: {
              pairFrom: marketInfo?.market_coin_symbol,
              pairTo: marketInfo?.quote_coin_symbol,
            },
            pnl,
            action: {
              botId: bot.id,
              botStatus: bot.status,
              botContractId: bot.contract_id,
            },
            row_data: {
              isClickable: true,
            },
          };
        })
        .filter(({ pair, id }) => {
          const {
            [TABLE_FILTERS_NAMES.SEARCHBAR]: search,
            pairFrom,
            pairTo,
          } = filters;

          const isIdExist = id.toString().indexOf(search) !== -1;
          const isPair = TradingBotsUtils.isEqualPair(
            { pairFrom, pairTo },
            { pairFrom: pair.pairFrom, pairTo: pair.pairTo },
          );

          return isIdExist && isPair;
        })
    : [];

  const dataResult = {
    filteredTableData,
    filteredBotsLength: filteredTableData.length,
  };

  return dataResult;
};

const useBotsTableData = ({ filters, pagination, bots }, options) => {
  const { findBotPnl } = useBotsPnl(undefined, options);
  const { marketsInfo, isMarketsInfoSuccess } = useMarketsInfo(
    undefined,
    options,
  );

  let data = useMemo(() => {
    return selectFromResult({
      findBotPnl,
      marketsInfo,
      isMarketsInfoSuccess,
      filters,
      bots,
    });
  }, [findBotPnl, marketsInfo, isMarketsInfoSuccess, filters, bots]);

  const { pageData: paginatedBots } = usePagination(
    pagination,
    data.filteredTableData,
  );

  data = useMemo(() => {
    return {
      ...data,
      paginatedBots,
    };
  }, [data, paginatedBots]);

  return data;
};

export default useBotsTableData;
