import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

import styles from './CardTitle.module.scss';

const CardTitle = ({ title = 'AccountVerification' }) => {
  const { userKycLevel } = useGetProfileInfoQueryResult();

  const isZeroLevel = userKycLevel === 0;

  return (
    <div
      className={classNames(
        'd-flex justify-content-between',
        styles.title_container,
      )}
    >
      <span className={styles.title}>
        <FormattedMessage id={title} />
      </span>

      <div
        className={classNames(styles.kyc_level, {
          'color-red': isZeroLevel,
        })}
      >
        {userKycLevel} <FormattedMessage id="Level" />
      </div>
    </div>
  );
};

export default memo(CardTitle);
