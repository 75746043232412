import { forwardRef, useEffect, useMemo, useState } from 'react';

import { DayPickerSingleDateController } from 'react-dates';

import {
  DatePickerModal,
  FormController,
  MonthElement,
} from '@shared/components';
import {
  commonPickerProps,
  DATE_FORMAT_WITH_DOT,
  END_OF_TODAY,
} from '@shared/components/DatePicker/datePicker.constants';

import { NOOP } from '@constants';

import DatePickerField from '../components/DatePickerField/DatePickerField';

const SingleDatePicker = forwardRef(
  (
    {
      isAutoFocusEndDate,
      onChange = NOOP,
      value,
      dateFormat = DATE_FORMAT_WITH_DOT,
      yearsAndMonthsSelectable,
      inputProps,
      // form controller props
      isInvalid,
      name,
      ...props
    },
    ref,
  ) => {
    const [date, setDate] = useState(value?.date ?? END_OF_TODAY);
    const [focused, setFocused] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // to be able to reset from parent component by pass value === ''
    useEffect(() => {
      if (value === '') {
        setDate(END_OF_TODAY);
      }
    }, [value]);

    const dateFormatted = useMemo(
      () => `${date?.format(dateFormat)}`,
      [date, dateFormat],
    );

    const handleCalendarOpen = () => {
      setIsOpen(true);
    };

    const handleDateChange = (date) => {
      setDate(date);

      onChange(date);
      setIsOpen(false);
    };

    const handleFocusChange = ({ focused }) => {
      setFocused(focused);
    };

    return (
      <div>
        <DatePickerField
          value={dateFormatted}
          ref={ref}
          onOpen={handleCalendarOpen}
          isInvalid={isInvalid}
          name={name}
          {...inputProps}
        />

        <DatePickerModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <DayPickerSingleDateController
            onDateChange={handleDateChange}
            onFocusChange={handleFocusChange}
            focused={focused}
            date={date}
            renderMonthElement={(params) => (
              <MonthElement
                {...params}
                yearsAndMonthsSelectable={yearsAndMonthsSelectable}
              />
            )}
            {...commonPickerProps}
            {...props}
          />
        </DatePickerModal>
      </div>
    );
  },
);

export const SingleDatePickerController = (props) => {
  return <FormController component={SingleDatePicker} {...props} />;
};

export default SingleDatePicker;
