import React, { memo, useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { ORDER_SIDES } from '@shared/constants';

import Button from '@components/Button';
import ButtonGroup from '@components/ButtonGroup';
import { TabList, TabPanel, TabPanels, Tab, Tabs } from '@components/Tabs';
import TabSelector from '@components/TabSelector';
import TooltipHelpIcon from '@components/TooltipHelpIcon';

import {
  useAccountBalances,
  useDefaultAccount,
  useMarketsInfo,
} from '@store/api/hooks';
import {
  selectedOrderBookItemSelector,
  orderBookControlsSelector,
} from '@store/gridBlocks/gridBlocksSelectors';
import { marketIdSelector } from '@store/settings/settingsSelectors';

import {
  marketStatisticsByMarketIdSelector,
  useStreamMarketStatisticsQuery,
} from '@api/marketsAPI';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';
import useBreakpoints from '@hooks/useBreakpoints';

import { NOOP, ORDER_FORM_TYPE } from '@constants';
import { BLOCK_BREAKPOINTS } from '@constants/blockBreakpoints';
import { BLOCK_TYPES } from '@constants/blockTypes';

import LimitForm from './components/forms/LimitForm';
import LimitStopOtoForm from './components/forms/LimitStopOtoForm';
import MarketForm from './components/forms/MarketForm';
import OcoForm from './components/forms/OcoForm';
import StopForm from './components/forms/StopForm';
import TabContent from './components/TabContent';

import styles from './Trade.module.scss';

const TABS = {
  MARKET_TAB: 'market',
  LIMIT_TAB: 'limit',
  STOP_TAB: 'stop',
  OCO_TAB: 'oco',
  LIMIT_OTO_TAB: 'limit_oto',
  STOP_OTO_TAB: 'stop_oto',
};

export const TABS_INDEX = {
  MARKET_TAB: 0,
  LIMIT_TAB: 1,
  STOP_TAB: 2,
  OCO_TAB: 2,
  LIMIT_OTO_TAB: 2,
  STOP_OTO_TAB: 2,
};

const TradeBlock = ({
  i,
  layoutItemRef,
  isBuyTab: forceIsBuyTab,
  isVerticalOrientation = NOOP,
}) => {
  const [isBuyTab, setIsBuyTab] = useState(forceIsBuyTab ?? true);
  const [selectedTradeType, setSelectedTradeType] = useState(TABS.STOP_TAB);
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const intl = useIntl();
  const { defaultSpotAccount } = useDefaultAccount();

  const { isTabletDown, isLaptopDown, isDesktop } = useBreakpoints();
  const { isMiniX, isStandardX, isLandscapeX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.trading],
  );

  const marketId = useSelector(marketIdSelector);

  const { marketInfo } = useMarketsInfo({ marketId });

  const { coinBalance: quoteCoinBalance } = useAccountBalances({
    coinSymbol: marketInfo?.quote_coin_symbol,
    accountId: defaultSpotAccount?.id,
  });
  const { coinBalance: marketCoinBalance } = useAccountBalances({
    coinSymbol: marketInfo?.market_coin_symbol,
    accountId: defaultSpotAccount?.id,
  });

  const { marketStatistic } = useStreamMarketStatisticsQuery(undefined, {
    selectFromResult: (result) => ({
      marketStatistic: marketStatisticsByMarketIdSelector(result, marketId),
    }),
  });

  const isShowSelect = isTabletDown || (isLaptopDown && isStandardX) || isMiniX;
  const isLargeButtonsGroup =
    isTabletDown || (isLaptopDown && isStandardX) || isDesktop;
  const isShowButtonsGroup = isTabletDown || !isLandscapeX;

  const { selectedOrderBookItem } = useSelector(selectedOrderBookItemSelector);
  const { showOrdersFromBook } = useSelector(
    orderBookControlsSelector(selectedOrderBookItem?.layoutItemId),
  );

  useEffect(() => {
    if (showOrdersFromBook && selectedOrderBookItem) {
      setTabActiveIndex(TABS_INDEX.LIMIT_TAB);
      setIsBuyTab(selectedOrderBookItem.side === ORDER_SIDES.BUY);
    }
  }, [selectedOrderBookItem]);

  const quoteAvailableBalance =
    Number.parseFloat(quoteCoinBalance?.available) || 0;
  const marketAvailableBalance =
    Number.parseFloat(marketCoinBalance?.available) || 0;

  const selectItems = [
    {
      value: TABS.STOP_TAB,
      label: intl.formatMessage({ id: 'Stop' }),
    },
    {
      value: TABS.OCO_TAB,
      label: intl.formatMessage({ id: 'OCO' }),
    },
    {
      value: TABS.LIMIT_OTO_TAB,
      label: intl.formatMessage({ id: 'LimitOTO' }),
    },
    {
      value: TABS.STOP_OTO_TAB,
      label: intl.formatMessage({ id: 'StopOto' }),
    },
  ];

  const tabs = [
    {
      label: intl.formatMessage({ id: 'Market' }),
      name: TABS.MARKET_TAB,
      content: (isBuyMode) => getContent(TABS.MARKET_TAB, isBuyMode),
      show: true,
    },
    {
      label: intl.formatMessage({ id: 'Limit' }),
      name: TABS.LIMIT_TAB,
      content: (isBuyMode) => getContent(TABS.LIMIT_TAB, isBuyMode),
      show: true,
    },
    {
      label: ({ onTabClick, tabIndex, isSelect }) => {
        const handleSelectItemClick = (selectedId) => {
          setSelectedTradeType(selectedId);
          onTabClick({ tabIndex, isSelect });
        };

        return (
          <TabSelector
            className="w-100"
            options={selectItems}
            isSearchable={false}
            value={selectedTradeType}
            onChange={handleSelectItemClick}
          />
        );
      },
      name: 'trade_select',
      isSelect: true,
      content: (isBuyMode) => getSelectContent(isBuyMode),
      show: isShowSelect,
    },
    {
      label: intl.formatMessage({ id: 'Stop' }),
      name: TABS.STOP_TAB,
      content: (isBuyMode) => getContent(TABS.STOP_TAB, isBuyMode),
      show: !isShowSelect,
    },
    {
      label: intl.formatMessage({ id: 'OCO' }),
      name: TABS.OCO_TAB,
      content: (isBuyMode) => getContent(TABS.OCO_TAB, isBuyMode),
      show: !isShowSelect,
    },
    {
      label: intl.formatMessage({ id: 'LimitOTO' }),
      name: TABS.LIMIT_OTO_TAB,
      content: (isBuyMode) => getContent(TABS.LIMIT_OTO_TAB, isBuyMode),
      show: !isShowSelect,
    },
    {
      label: intl.formatMessage({ id: 'StopOto' }),
      name: TABS.STOP_OTO_TAB,
      content: (isBuyMode) => getContent(TABS.STOP_OTO_TAB, isBuyMode),
      show: !isShowSelect,
    },
  ];

  const getSelectContent = (isBuyMode) => {
    switch (selectedTradeType) {
      case TABS.OCO_TAB:
        return getContent(TABS.OCO_TAB, isBuyMode);
      case TABS.LIMIT_OTO_TAB:
        return getContent(TABS.LIMIT_OTO_TAB, isBuyMode);
      case TABS.STOP_OTO_TAB:
        return getContent(TABS.STOP_OTO_TAB, isBuyMode);
      default:
        return getContent(TABS.STOP_TAB, isBuyMode);
    }
  };

  const getContent = (tabName, isBuyMode) => {
    switch (tabName) {
      case TABS.MARKET_TAB: {
        return (
          <MarketForm
            buyTab={isBuyMode}
            currentMarket={marketInfo}
            currentPrice={marketStatistic.c}
            quoteAvailableBalance={quoteAvailableBalance}
            marketAvailableBalance={marketAvailableBalance}
            layoutItemRef={layoutItemRef}
          />
        );
      }

      case TABS.LIMIT_TAB: {
        return (
          <LimitForm
            buyTab={isBuyMode}
            currentMarket={marketInfo}
            currentPrice={marketStatistic.c}
            quoteAvailableBalance={quoteAvailableBalance}
            marketAvailableBalance={marketAvailableBalance}
            layoutItemRef={layoutItemRef}
          />
        );
      }

      case TABS.STOP_TAB: {
        return (
          <StopForm
            layoutItemId={i}
            buyTab={isBuyMode}
            currentMarket={marketInfo}
            currentPrice={marketStatistic.c}
            quoteAvailableBalance={quoteAvailableBalance}
            marketAvailableBalance={marketAvailableBalance}
            layoutItemRef={layoutItemRef}
          />
        );
      }

      case TABS.OCO_TAB: {
        return (
          <OcoForm
            buyTab={isBuyMode}
            currentMarket={marketInfo}
            currentPrice={marketStatistic.c}
            layoutItemRef={layoutItemRef}
          />
        );
      }

      case TABS.LIMIT_OTO_TAB: {
        return (
          <LimitStopOtoForm
            buyTab={isBuyMode}
            currentMarket={marketInfo}
            currentPrice={marketStatistic.c}
            layoutItemRef={layoutItemRef}
            quoteAvailableBalance={quoteAvailableBalance}
            marketAvailableBalance={marketAvailableBalance}
            layoutItemId={i}
            basicOrderFormType={ORDER_FORM_TYPE.LIMIT_OTO_BASIC}
            advancedOrderFormType={ORDER_FORM_TYPE.LIMIT_OTO_ADVANCED}
          />
        );
      }

      case TABS.STOP_OTO_TAB: {
        return (
          <LimitStopOtoForm
            buyTab={isBuyMode}
            currentMarket={marketInfo}
            currentPrice={marketStatistic.c}
            layoutItemRef={layoutItemRef}
            quoteAvailableBalance={quoteAvailableBalance}
            marketAvailableBalance={marketAvailableBalance}
            layoutItemId={i}
            basicOrderFormType={ORDER_FORM_TYPE.STOP_OTO_BASIC}
            advancedOrderFormType={ORDER_FORM_TYPE.STOP_OTO_ADVANCED}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  const handleTabIndexChanged = ({ tabIndex }) => {
    setTabActiveIndex(tabIndex);
  };

  useEffect(() => {
    const selectIndex = tabs.findIndex((item) => item.isSelect);

    if (isShowSelect) {
      if (tabActiveIndex > selectIndex) {
        const tabsLength = tabs.length - selectItems.length;

        const selectedItem = selectItems.find(
          (item, index) => index === tabActiveIndex - tabsLength,
        );

        setTabActiveIndex(selectIndex);
        setSelectedTradeType(selectedItem.value);
      }
    } else {
      if (tabActiveIndex === selectIndex) {
        const selectItemIndex = selectItems.findIndex(
          (item) => item.value === selectedTradeType,
        );

        setTabActiveIndex(selectIndex + selectItemIndex + 1);
      }
    }
  }, [isShowSelect]);

  return (
    <div className={styles.trade_block}>
      {isShowButtonsGroup && (
        <ButtonGroup>
          <Button
            fill
            primary
            className={classNames(styles.group_button, styles.buy, {
              [styles.group_button__not_active]: !isBuyTab,
              [styles.lg]: isLargeButtonsGroup,
            })}
            onClick={() => setIsBuyTab(true)}
          >
            <FormattedMessage id="Buy" />
          </Button>
          <Button
            fill
            dangerous
            className={classNames(styles.group_button, styles.sell, {
              [styles.group_button__not_active]: isBuyTab,
              [styles.lg]: isLargeButtonsGroup,
            })}
            onClick={() => setIsBuyTab(false)}
          >
            <FormattedMessage id="Sell" />
          </Button>
        </ButtonGroup>
      )}

      <Tabs
        id="trade-block-tabs-id"
        tabActiveIndex={tabActiveIndex}
        onTabIndexChanged={handleTabIndexChanged}
      >
        <TabList
          suffix={
            <TooltipHelpIcon
              content={
                <span>
                  <b className="fs-14">
                    <FormattedMessage id="MarketOrder" />
                  </b>
                  <div>
                    <FormattedMessage id="MarketOrderDescription" />
                  </div>
                  <br />
                  <b className="fs-14">
                    <FormattedMessage id="LimitOrder" />
                  </b>
                  <div>
                    <FormattedMessage id="LimitOrderDescription" />
                  </div>
                  <br />
                  <b className="fs-14">
                    <FormattedMessage id="StopOrder" />
                  </b>
                  <div>
                    <FormattedMessage id="StopOrderDescription" />
                  </div>
                </span>
              }
            />
          }
        >
          {tabs.map(
            (tab) =>
              tab.show && (
                <Tab
                  key={tab.name}
                  className={tab.isSelect ? styles.select_tab : undefined}
                  isSelect={tab.isSelect}
                >
                  {tab.label}
                </Tab>
              ),
          )}
        </TabList>
        <TabPanels>
          {tabs.map(
            (tab) =>
              tab.show && (
                <TabPanel key={tab.name}>
                  <TabContent
                    quoteAvailableBalance={quoteAvailableBalance}
                    marketAvailableBalance={marketAvailableBalance}
                    marketInfo={marketInfo}
                    layoutItemRef={layoutItemRef}
                    isBuyTab={isBuyTab}
                    content={tab.content}
                  />
                </TabPanel>
              ),
          )}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default memo(TradeBlock);
