import { memo } from 'react';

import { format } from '@utils/numbers';

import { PERCENT_PRECISION } from '@constants';

const FormattedPercent = ({
  percent,
  roundingMode,
  precision = PERCENT_PRECISION,
  removeZeroEnd = false,
}) =>
  format(percent, {
    precision,
    roundingMode,
    removeZeroEnd,
  });

export default memo(FormattedPercent);
