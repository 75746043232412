import { createSlice } from '@reduxjs/toolkit';

import { PAYMENT_METHOD } from '@shared/constants';

const initialState = {
  paymentMethod: PAYMENT_METHOD.BANK_TRANSFER,
  depositCurrencySymbol: null,
  selectedCoinType: null,
  coins: [],
};

const depositSlice = createSlice({
  name: 'walletDeposit',
  initialState,
  reducers: {
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setDepositCurrencySymbol: (state, action) => {
      const selectedCoinType = state.coins.find(
        (coin) => coin.symbol === action.payload,
      )?.type;

      state.selectedCoinType = selectedCoinType;

      state.depositCurrencySymbol = action.payload;
    },
    setSelectedCoinType: (state, action) => {
      state.selectedCoinType = action.payload;
    },
    setDepositCoins: (state, action) => {
      state.coins = action.payload;
    },
  },
});

const walletDepositActions = depositSlice.actions;
const walletDepositReducer = depositSlice.reducer;

export { walletDepositReducer, walletDepositActions };

export default depositSlice;
