import React, { memo } from 'react';

import classNames from 'classnames';

import TruncatedText from '@shared/components/TruncatedText/TruncatedText';

import styles from './OrdersFee.module.scss';

const OrdersFee = ({ value, coinSymbol, numberValueClassName }) => {
  return (
    <TruncatedText>
      <span
        className={classNames(
          {
            [styles.grey]: Number(value) === 0,
          },
          numberValueClassName,
        )}
      >
        {value}
      </span>

      <span className={styles.thin}> {coinSymbol}</span>
    </TruncatedText>
  );
};

export default memo(OrdersFee);
