import { createApi } from '@reduxjs/toolkit/query/react';

import { ITEMS_PER_PAGE_ANNOUNCEMENTS } from '@pages/LegacyPages/AnnouncementsPage/announcements.constants';

import client from '@api/client';

export const announcementsAPI = createApi({
  reducerPath: 'announcementsAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    getAnnouncements: builder.query({
      query: ({ limit = ITEMS_PER_PAGE_ANNOUNCEMENTS, ...params }) => ({
        method: 'GET',
        url: 'announcements',
        params: {
          limit,
          ...params,
        },
      }),
    }),
    getAnnouncementByTopicAndById: builder.query({
      query: ({ id, topic }) => ({
        method: 'GET',
        url: 'announcements/list/by-topics',
        params: { id, topic },
      }),
    }),
    getAnnouncementTopics: builder.query({
      query: () => ({
        method: 'GET',
        url: 'announcements/topics',
      }),
    }),
    getAnnouncementsByTopic: builder.query({
      query: (params) => ({
        method: 'GET',
        url: 'announcements/by-topics',
        params,
      }),
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useGetAnnouncementTopicsQuery,
  useGetAnnouncementsByTopicQuery,
  useGetAnnouncementByTopicAndByIdQuery,
} = announcementsAPI;
