import React, { memo, useEffect, useState } from 'react';

import cx from 'classnames';

import { useScrollDirection } from '@shared/hooks';

import styles from './ExpandedOnScrollBlock.module.scss';

/**
 * position = 'top' | 'bottom'
 * openedOnScrollDirection = 'to_top' | 'to_bottom'
 * */

export const EXPANDED_BLOCK_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const SCROLL_DIRECTIONS = {
  TO_TOP: 'to_top',
  TO_BOTTOM: 'to_bottom',
};

const ExpandedOnScrollBlock = ({
  children,
  position = EXPANDED_BLOCK_POSITION.BOTTOM,
  openedOnScrollDirection = SCROLL_DIRECTIONS.TO_BOTTOM,
  customStyles = {},
  isOpened = false,
}) => {
  const [isOpenedBlock, setIsOpenedBlock] = useState(false);

  const { isScrollingUp, isScrollingDown } = useScrollDirection();

  useEffect(() => {
    setIsOpenedBlock(
      (openedOnScrollDirection === SCROLL_DIRECTIONS.TO_BOTTOM &&
        isScrollingDown) ||
        (openedOnScrollDirection === SCROLL_DIRECTIONS.TO_TOP && isScrollingUp),
    );
  }, [openedOnScrollDirection, isScrollingUp, isScrollingDown]);

  // set state by default
  useEffect(() => {
    setIsOpenedBlock(isOpened);
  }, [isOpened]);

  return (
    <div
      className={cx(
        styles.expanded_block,
        customStyles.expanded_block,
        styles[position],
        {
          [styles.opened]: isOpenedBlock,
        },
      )}
    >
      {children}
    </div>
  );
};

export default memo(ExpandedOnScrollBlock);
