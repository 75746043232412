import React, { memo } from 'react';

import classNames from 'classnames';

import Tooltip from '@components/Tooltip';

import { ReactComponent as InfoIconIconSVG } from '@images/icons/info-icon.svg';

import styles from './TooltipHelpIcon.module.scss';

const TooltipHelpIcon = ({
  className,
  iconSize = { width: 12, height: 12 },
  ...props
}) => {
  return (
    <Tooltip {...props}>
      <InfoIconIconSVG
        className={classNames(styles.info_icon, className)}
        {...iconSize}
      />
    </Tooltip>
  );
};

export default memo(TooltipHelpIcon);
