import React, { memo, useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import { COIN_TYPE } from '@shared/constants';
import { CUSTOM_SELECT_DIVIDER } from '@shared/constants/customSelect.constants';

import Loader from '@components/Loader';
import SearchBar from '@components/SearchBar';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@components/Tabs';
import TabSelector from '@components/TabSelector';

import { useCoinsInfo } from '@store/api/hooks';
import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import {
  marketsForTableSelector,
  useGetMarketsInfoQuery,
  useStreamMarketStatisticsQuery,
} from '@api/marketsAPI';
import {
  favouritePairsNamesSelector,
  useGetFavouritesPairsQuery,
} from '@api/profileAPI';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';
import useBreakpoints from '@hooks/useBreakpoints';

import { isCoinExistsInPair } from '@utils';

import { NO_SELECTED_INDEX, NOOP } from '@constants';
import { BLOCK_BREAKPOINTS } from '@constants/blockBreakpoints';
import { BLOCK_TYPES } from '@constants/blockTypes';

import { ReactComponent as StarIconSVG } from '@icons/star-icon.svg';

import TabContent from './components/TabContent';

import styles from './Markets.module.scss';

const MarketsBlock = ({
  i,
  layoutItemRef,
  withoutHeader,
  setIsOpen = NOOP,
}) => {
  const intl = useIntl();
  const isGuest = useSelector(isGuestUserSelector);

  const [showFavouritePairs, setShowFavouritePairs] = useState(false);
  const [selectedCoinSymbol, setSelectedCoinSymbol] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [tabActiveIndex, setTabActiveIndex] = useState(NO_SELECTED_INDEX);
  const [searchMode, setSearchMode] = useState(false);

  const { isLaptopDown } = useBreakpoints();

  const { isMiniX, isStandardX, isLandscapeX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.markets],
  );

  const { favouritePairs } = useGetFavouritesPairsQuery(undefined, {
    skip: isGuest,
    selectFromResult: (result) => ({
      ...result,
      favouritePairs: favouritePairsNamesSelector(result),
    }),
  });

  const { data: marketsStatistic } = useStreamMarketStatisticsQuery();

  const { marketsInfo, isSuccess: isMarketsInfoSuccess } =
    useGetMarketsInfoQuery(undefined, {
      selectFromResult: (result) => ({
        ...result,
        marketsInfo: marketsForTableSelector(
          result,
          marketsStatistic,
          favouritePairs,
          showFavouritePairs,
          selectedCoinSymbol,
          searchValue,
        ),
      }),
    });

  const { coinsInfoMap, isCoinsInfoSuccess } = useCoinsInfo({
    orderByTypeDirection: 'asc',
  });

  const visibleCoinsQty = useMemo(() => {
    if (isLaptopDown && isStandardX) {
      return 2;
    }

    if (isStandardX || isLandscapeX) {
      return 3;
    }

    return 0;
  }, [isLaptopDown, isStandardX, isLandscapeX]);

  const { visibleCoins, hiddenCoins } = useMemo(() => {
    const visibleCoins = coinsInfoMap.slice(0, visibleCoinsQty);
    const hiddenCoins = coinsInfoMap.slice(visibleCoinsQty);

    return {
      visibleCoins,
      hiddenCoins,
    };
  }, [coinsInfoMap, visibleCoinsQty]);

  useEffect(() => {
    // This useEffect works only when any tab selected
    if (tabActiveIndex === NO_SELECTED_INDEX) return;

    const coinIndex = visibleCoins.findIndex(
      (coin) => coin.symbol === selectedCoinSymbol,
    );

    const isCoinInSelector = coinIndex === NO_SELECTED_INDEX;

    setTabActiveIndex(
      isCoinInSelector ? visibleCoins.length + 1 : coinIndex + 1,
    );
  }, [visibleCoinsQty]);

  const handleTabChanged = ({ tabIndex, isSelect }) => {
    const isTabFavourite = tabIndex === 0;
    const isClickOnActiveTab = tabActiveIndex === tabIndex;
    const isClickOnSelectedCoinInSelector =
      tabActiveIndex === visibleCoins.length + 1;

    if (isClickOnActiveTab) {
      if (!isClickOnSelectedCoinInSelector) {
        setTabActiveIndex(NO_SELECTED_INDEX);
        setSelectedCoinSymbol(undefined);
      }
    } else {
      setTabActiveIndex(tabIndex);
      if (!isSelect) {
        setSelectedCoinSymbol(
          isTabFavourite ? undefined : visibleCoins[tabIndex - 1].symbol,
        );
      }
    }

    if (isTabFavourite) {
      setShowFavouritePairs(tabActiveIndex !== tabIndex);
    } else if (showFavouritePairs) {
      setShowFavouritePairs(false);
    }
  };

  const selectAllCoins = () => {
    setTabActiveIndex(NO_SELECTED_INDEX);
    setSelectedCoinSymbol(undefined);
    setShowFavouritePairs(false);
  };

  const handleSearch = (value) => {
    setSearchValue(value);

    if (value) {
      selectAllCoins();
    }
  };

  const selectItems = useMemo(() => {
    const cryptoCurrencies = hiddenCoins.filter(
      (currency) => currency.type === COIN_TYPE.CRYPTO,
    );

    const fiatCurrencies = hiddenCoins.filter(
      (currency) => currency.type === COIN_TYPE.FIAT,
    );

    const mapToOptions = (options) => {
      return options.map((coin) => ({
        ...coin,
        value: coin.symbol,
        label: coin.symbol.toUpperCase(),
      }));
    };

    return [
      {
        label: null,
        options: [
          {
            value: 'all_coins',
            label: intl.formatMessage({ id: 'AllCoins' }),
          },
        ],
      },
      CUSTOM_SELECT_DIVIDER,
      {
        label: null,
        options: mapToOptions(cryptoCurrencies),
      },
      CUSTOM_SELECT_DIVIDER,
      {
        label: null,
        options: mapToOptions(fiatCurrencies),
      },
    ];
  }, [hiddenCoins, intl]);

  const tabs = useMemo(() => {
    return [
      {
        label: <StarIconSVG />,
        name: 'markets_favourite',
        data: marketsInfo,
      },
      ...visibleCoins.map((coin) => ({
        label: <span className="text-uppercase">{coin.symbol}</span>,
        name: coin.symbol,
        data: marketsInfo.filter((market) =>
          isCoinExistsInPair(market.symbol, coin.symbol),
        ),
      })),
      {
        label: ({ onTabClick, tabIndex, isSelect }) => {
          const handleSelectItemClick = (selectedId) => {
            if (selectedId === 'all_coins') {
              selectAllCoins();
              return;
            }

            setSelectedCoinSymbol(selectedId);
            onTabClick({ tabIndex, isSelect });
          };

          return (
            <TabSelector
              grouped
              id={`markets-select-${i}`}
              className="w-100"
              options={selectItems}
              value={selectedCoinSymbol ?? null}
              onChange={handleSelectItemClick}
              placeholder={intl.formatMessage({ id: 'More' })}
            />
          );
        },
        name: 'markets_select',
        isSelect: true,
        data: marketsInfo,
      },
    ];
  }, [
    marketsInfo,
    selectItems,
    selectedCoinSymbol,
    visibleCoins,
    isCoinsInfoSuccess,
  ]);

  if (!isCoinsInfoSuccess) {
    return (
      <div className="layout-item__loader">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={classnames(styles.markets_block, {
        [styles['markets_block--without_header']]: withoutHeader,
      })}
    >
      {isMarketsInfoSuccess ? (
        <>
          <Tabs
            tabActiveIndex={tabActiveIndex}
            onTabIndexChanged={handleTabChanged}
            id="markets-block-tabs-id"
          >
            <TabList
              containerClassName="mx-12"
              className={classnames('tabs__tab-list--with-searchbar gap-10', {
                hidden: searchMode,
              })}
              sm={isMiniX && isLaptopDown}
              suffix={
                <div
                  style={{ zIndex: searchMode ? 3 : 1 }}
                  className="tabs__searchbar"
                >
                  <SearchBar
                    shortView={isMiniX}
                    searchMode={searchMode}
                    setSearchMode={setSearchMode}
                    onSearch={handleSearch}
                    isAutoFocus
                    isUseOnClickOutside
                  />
                </div>
              }
            >
              {tabs.map((tab) => (
                <Tab
                  key={`markets-tab-${tab.name}`}
                  className={tab.isSelect ? styles.select_tab : undefined}
                  isSelect={tab.isSelect}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            {tabActiveIndex === NO_SELECTED_INDEX ? (
              <TabContent
                layoutItemRef={layoutItemRef}
                withoutHeader={withoutHeader}
                data={marketsInfo}
                setIsOpen={setIsOpen}
              />
            ) : (
              <TabPanels>
                {tabs.map((tab) => (
                  <TabPanel key={`markets-tab-panel-${tab.name}`}>
                    <TabContent
                      layoutItemRef={layoutItemRef}
                      withoutHeader={withoutHeader}
                      data={tab.data}
                      setIsOpen={setIsOpen}
                    />
                  </TabPanel>
                ))}
              </TabPanels>
            )}
          </Tabs>
        </>
      ) : (
        <div className="layout-item__loader">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default memo(MarketsBlock);
