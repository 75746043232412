import React, { forwardRef, memo, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import classnames from 'classnames';

import styles from './Link.module.scss';

const Link = forwardRef(
  (
    {
      children,
      to = '',
      className = '',
      activeClassName = '',
      isNewTab = false,
      green = false,
      bordered = false,
      disabled = false,
      inversion = false,
      customStyles = {},
      ...rest
    },
    ref,
  ) => {
    const linkRef = useRef();

    useEffect(() => {
      if (isNewTab) {
        const currentRef = ref || linkRef;

        currentRef.current.target = '_blank';
      }
    }, [isNewTab]);

    if (to.charAt(0) === '/') {
      return (
        <NavLink
          ref={ref || linkRef}
          className={({ isActive }) => {
            return classnames('v2-link', styles.link, className, {
              // [styles['link--active']]: isActive,
              [activeClassName]: isActive,
              [styles['link--green']]: green,
              [styles['link--bordered']]: bordered,
              [styles['link--disabled']]: disabled,
              [styles['link--inversion']]: inversion,
            });
          }}
          to={to}
          {...rest}
        >
          {children}
        </NavLink>
      );
    }

    return (
      <a
        ref={ref || linkRef}
        href={to}
        className={classnames(
          'v2-link',
          styles.link,
          className,
          {
            [styles['link--green']]: green,
            [styles['link--bordered']]: bordered,
            [styles['link--disabled']]: disabled,
            [styles['link--inversion']]: inversion,
          },
          customStyles.link,
        )}
        {...rest}
      >
        {children}
      </a>
    );
  },
);

export default memo(Link);
