export const BOTS_STATUS = {
  ACTIVE: 'active',
  STOPPED: 'stopped',
  ARCHIVED: 'archived',
  STOPPED_BY_SYSTEM_TRIGGER: 'stopped_by_system_trigger',
  LIQUIDATED: 'liquidated',
};

export const BOT_TYPE = {
  trend: 'trend',
  grid: 'grid',
};

export const TYPE_BONUS_CONTRACT = {
  [BOT_TYPE.trend]: 'Trend',
  [BOT_TYPE.grid]: 'TradingGrid',
};
