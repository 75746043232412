import { createSlice } from '@reduxjs/toolkit';

const gridBlocksSlice = createSlice({
  name: 'gridBlocks',
  initialState: {
    openOrdersControls: {
      blockIdsWithShowAll: {},
    },
    orderHistoryControls: {
      blockIdsWithShowAll: {},
      blockIdsWithExpand: {},
    },
    balancesControls: {
      blockIdsWithShowSmallAmount: {},
      blockIdsWithShowEstimatedValue: {},
    },
    orderBookControls: {
      blockIdsWithPricePrecisionValue: {},
      blockIdsWithShowAverageAndTotal: {},
      blockIdsWithShowOrdersFromBook: {},
    },
    selectedOrderBookItem: undefined,
  },
  reducers: {
    toggleShowAllOpenOrders: (state, action) => {
      const { layoutItemId, showAll } = action.payload;
      const currentState = state.openOrdersControls.blockIdsWithShowAll;

      if (showAll) {
        if (!currentState.hasOwnProperty(layoutItemId)) {
          currentState[layoutItemId] = true;
        }
      } else {
        delete currentState[layoutItemId];
      }
    },
    toggleExpandOpenOrders: (state, action) => {
      const { layoutItemId, showAll } = action.payload;
      const currentState = state.openOrdersControls.blockIdsWithExpand;

      if (showAll) {
        if (!currentState.hasOwnProperty[layoutItemId]) {
          currentState[layoutItemId] = true;
        }
      } else {
        delete currentState[layoutItemId];
      }
    },
    toggleShowAllOrderHistory: (state, action) => {
      const { layoutItemId, showAll } = action.payload;
      const currentState = state.orderHistoryControls.blockIdsWithShowAll;

      if (showAll) {
        if (!currentState.hasOwnProperty(layoutItemId)) {
          currentState[layoutItemId] = true;
        }
      } else {
        delete currentState[layoutItemId];
      }
    },
    toggleExpandOrderHistory: (state, action) => {
      const { layoutItemId, showAll } = action.payload;
      const currentState = state.orderHistoryControls.blockIdsWithExpand;

      if (showAll) {
        if (!currentState.hasOwnProperty[layoutItemId]) {
          currentState[layoutItemId] = true;
        }
      } else {
        delete currentState[layoutItemId];
      }
    },
    toggleShowSmallAmountBalances: (state, action) => {
      const { layoutItemId, showSmallAmount } = action.payload;
      const currentState = state.balancesControls.blockIdsWithShowSmallAmount;

      if (showSmallAmount) {
        if (!currentState.hasOwnProperty(layoutItemId)) {
          currentState[layoutItemId] = true;
        }
      } else {
        delete currentState[layoutItemId];
      }
    },
    toggleShowEstimatedValueBalances: (state, action) => {
      const { layoutItemId, showEstimatedValue } = action.payload;
      const currentState =
        state.balancesControls.blockIdsWithShowEstimatedValue;

      if (showEstimatedValue) {
        if (!currentState.hasOwnProperty[layoutItemId]) {
          currentState[layoutItemId] = true;
        }
      } else {
        delete currentState[layoutItemId];
      }
    },
    toggleShowAverageAndTotalOrderBook: (state, action) => {
      const { layoutItemId, showAverageAndTotal } = action.payload;
      const currentState =
        state.orderBookControls.blockIdsWithShowAverageAndTotal;

      if (showAverageAndTotal) {
        if (!currentState.hasOwnProperty(layoutItemId)) {
          currentState[layoutItemId] = true;
        }
      } else {
        delete currentState[layoutItemId];
      }
    },
    toggleShowOrdersFromBookOrderBook: (state, action) => {
      const { layoutItemId, showOrdersFromBook } = action.payload;
      const currentState =
        state.orderBookControls.blockIdsWithShowOrdersFromBook;

      if (showOrdersFromBook) {
        if (!currentState.hasOwnProperty(layoutItemId)) {
          currentState[layoutItemId] = true;
        }
      } else {
        delete currentState[layoutItemId];
      }
    },
    setPricePrecisionOrderBook: (state, action) => {
      const { layoutItemId, setPricePrecision } = action.payload;
      const currentState =
        state.orderBookControls.blockIdsWithPricePrecisionValue;

      currentState[layoutItemId] = setPricePrecision;
    },
    setSelectedOrderBookItem: (state, action) => {
      state.selectedOrderBookItem = action.payload;
    },
  },
});

const gridBlocksActions = gridBlocksSlice.actions;
const gridBlocksReducer = gridBlocksSlice.reducer;

export { gridBlocksActions, gridBlocksReducer };
export default gridBlocksSlice;
