import { fromUnixTime } from 'date-fns';

function removeNegativeTimeZoneUnixTime(unixTime) {
  if (unixTime < 0) {
    const dt = fromUnixTime(unixTime);

    const timeZoneOffsetSec = Math.abs(dt.getTimezoneOffset()) * 60;

    return unixTime + timeZoneOffsetSec;
  }

  return unixTime;
}

export const DateUtils = {
  removeNegativeTimeZoneUnixTime,
};
