import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { BOTS_STATUS } from '@shared/constants';

import { ApiSelectorsUtils } from '../../utils';

const defaultBots = [];
const defaultBotsPnl = {
  pnlGroupedByBots: [],
  pnlGroupedByVersions: [],
};

const dataSelector = ApiSelectorsUtils.createDataApiSelector(defaultBots);
const botsPnlDataSelector =
  ApiSelectorsUtils.createDataApiSelector(defaultBotsPnl);

const botsMapOrderByIdDirectionSelector = createSelector(
  (botsMap) => botsMap,
  (botsMap, orderByIdDirection) => orderByIdDirection,
  (botsMap, orderByIdDirection) => {
    return orderBy(botsMap, 'id', orderByIdDirection);
  },
);

const archivedBotsSelector = createSelector(dataSelector, (bots) => {
  const filterArchivedBots = bots.filter(
    (bot) =>
      bot.status === BOTS_STATUS.ARCHIVED ||
      bot.status === BOTS_STATUS.STOPPED_BY_SYSTEM_TRIGGER,
  );

  return orderBy(filterArchivedBots, 'id', 'desc');
});

const liquidatedBotsSelector = createSelector(dataSelector, (bots) => {
  const filterLiquidatedBots = bots.filter(
    (bot) => bot.status === BOTS_STATUS.LIQUIDATED,
  );

  return orderBy(filterLiquidatedBots, 'id', 'desc');
});

const pnlByBotsSelector = createSelector(botsPnlDataSelector, (data) => {
  return data.pnlGroupedByBots;
});

const pnlByVersionsSelector = createSelector(botsPnlDataSelector, (data) => {
  return data.pnlGroupedByVersions;
});

export const BotsApiSelectors = {
  dataSelector,
  botsMapOrderByIdDirectionSelector,
  archivedBotsSelector,
  liquidatedBotsSelector,
  botsPnlSelector: botsPnlDataSelector,
  pnlByBotsSelector,
  pnlByVersionsSelector,
};
