import React, { memo } from 'react';

import classNames from 'classnames';

import Breakpoint from '@components/Breakpoint';
import Link from '@components/Link';

import { ReactComponent as LogoSymbolSVG } from '@images/paramountdax-logo-symbol.svg';
import { ReactComponent as LogoTitleSVG } from '@images/paramountdax-logo-title.svg';

import HeaderItem from '../../../MainLayout/components/PrimaryHeader/components/HeaderItem';
import SideBarLocalization from '../../../MainLayout/components/PrimaryHeader/components/SideBarLocalization';

import { MAIN_ROUTES } from 'src/routes.constants';

import styles from './Header.module.scss';

const Header = () => {
  return (
    <header className={classNames('primary-header', styles.header)}>
      <div className="w-100 d-flex justify-content-between">
        <HeaderItem>
          <Link to={MAIN_ROUTES.HOME}>
            <LogoSymbolSVG />

            <Breakpoint size="sm" direction="up">
              <LogoTitleSVG className="ms-8" />
            </Breakpoint>
          </Link>
        </HeaderItem>

        <HeaderItem>
          <SideBarLocalization withCurrency={false} />
        </HeaderItem>
      </div>
    </header>
  );
};

export default memo(Header);
