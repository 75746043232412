import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

const StakingNoRecords = () => {
  return (
    <span className="text-center fw-600">
      <FormattedMessage id="NoRecords" />
    </span>
  );
};

export default memo(StakingNoRecords);
