import { forwardRef } from 'react';

import classnames from 'classnames';

import TableHead from './components/TableHead';

const TableDefaultHeader = forwardRef(
  (
    {
      id,
      table,
      collapseHeader = false,
      sortingState,
      inversion,
      underlinedRows,
      headerSize = 's', // m, l
    },
    ref,
  ) => {
    return (
      <thead
        ref={ref}
        className={classnames(
          `table-default__thead table-default__thead--size-${headerSize}`,
          {
            'table-default__thead--collapsed': collapseHeader,
            'table-default__thead--inversion': inversion,
            'table-default__thead--underlined': underlinedRows,
          },
        )}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={`${id}-${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <TableHead
                key={`${id}-${header.id}`}
                style={{ width: header.getSize() }}
                header={header}
                sortingState={sortingState}
              />
            ))}
          </tr>
        ))}
      </thead>
    );
  },
);

export default TableDefaultHeader;
