import { useMemo } from 'react';

import { MyStakingSelectors } from '@api/selectors';
import { useStakingSettingsQuery } from '@api/stakingAPI';

const selectFromResult = (result) => {
  const dataResult = {
    isStakingSettingsSuccess: result.isSuccess,
  };

  dataResult.coins = MyStakingSelectors.coinsSelector(result);
  dataResult.limits = MyStakingSelectors.limitsSelector(result);
  dataResult.periods = MyStakingSelectors.periodsSelector(result);

  return dataResult;
};

const useGetStakingSettings = (args, options = {}) => {
  const result = useStakingSettingsQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useGetStakingSettings;
