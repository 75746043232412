import { memo } from 'react';
import { useIntl } from 'react-intl';

import TruncatedText from '@shared/components/TruncatedText/TruncatedText';
import { parseToLocalization } from '@shared/utils';

import StatusDot from '@components/StatusDot/StatusDot';

import styles from './DateStatusItem.module.scss';

const DateStatusItem = ({ createdAt, status, dotColor, isHideStatus }) => {
  const intl = useIntl();

  const tooltipStatus =
    status &&
    intl.formatMessage({
      id: parseToLocalization(status),
    });

  return (
    <>
      <TruncatedText>
        <span className={styles.date_status_item}>{createdAt}</span>
      </TruncatedText>

      {!isHideStatus && (
        <div className="ms-6">
          <StatusDot tooltipStatus={tooltipStatus} color={dotColor} />
        </div>
      )}
    </>
  );
};

export default memo(DateStatusItem);
