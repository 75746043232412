import React, { memo, useState } from 'react';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import { AccountsSwiper } from './AccountsSwiper';
import { LastTransactions } from './LastTransactions';
import { Widgets } from './Widgets';

import styles from './WalletsOverview.module.scss';

const WalletsOverview = () => {
  const [estimatedCurrency, setEstimatedCurrency] = useState(
    CRYPTO_CURRENCIES.BTC,
  );

  return (
    <div className={styles.container}>
      <Widgets
        estimatedCurrency={estimatedCurrency}
        onEstimatedCurrencyChange={setEstimatedCurrency}
      />

      <AccountsSwiper estimatedCurrency={estimatedCurrency} />

      <div className="row">
        <LastTransactions />
      </div>
    </div>
  );
};

export default memo(WalletsOverview);
