import { memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { compact, isEmpty } from 'lodash';
import { FileDrop } from 'react-file-drop';

import { acceptedExt, validateFile } from '@shared/utils/files';

import Loader from '@components/Loader';

import { NOOP } from '@constants';

import { FieldController } from '../FieldController';

import FileUploaderLoadedFiles from './FileUploaderLoadedFiles/FileUploaderLoadedFiles';
import { FileUploaderPlaceHolder } from './FileUploaderPlaceHolder';

import styles from './FileUploader.module.scss';

const FileUploader = ({
  acceptTypes,
  isLoading,
  onRemoveFile = NOOP,
  onError = NOOP,
  onChange,
  maxSizeMb,
  loadedFiles = [],
  customStyles = {},
}) => {
  const fileInputRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const onFileInputChange = (event) => {
    const { files } = event.target;

    if (!files || !files?.length) {
      return;
    }

    const errors = Array.from(files).reduce((errors, file) => {
      const error = validateFile(file, acceptTypes, maxSizeMb);

      return [...errors, error];
    }, []);

    if (compact(errors).length) {
      onError(errors);
      return;
    }

    onChange(files);
  };

  const onTargetClick = () => {
    if (!loadedFiles.length) {
      fileInputRef.current.click();
    }
  };

  const handleDragEnter = () => {
    setIsHovered(true);
  };

  const handleDragLeave = () => {
    setIsHovered(false);
  };

  const handleOnDrop = (files) => {
    onFileInputChange({ target: { files } });

    setIsHovered(false);
  };

  const onInputClick = (event) => {
    event.target.value = '';
  };

  return (
    <div
      classNames={classNames(
        'd-flex justify-content-center align-items-center',
      )}
    >
      <input
        style={{ display: 'none' }}
        onChange={onFileInputChange}
        onClick={onInputClick}
        accept={acceptedExt(acceptTypes)}
        ref={fileInputRef}
        type="file"
        className="hidden"
      />

      <FileDrop
        onTargetClick={onTargetClick}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleOnDrop}
      >
        <div
          className={classNames(
            'd-flex align-items-center justify-content-center gap-26',
            styles.file_uploader,
            customStyles.file_uploader,
            isHovered && styles.file_uploader_hovered,
          )}
        >
          {isLoading && (
            <div className="d-flex align-items-center gap-30 align-self-start">
              <Loader />
              <div>
                <FormattedMessage id="Loading" />
                ...
              </div>
            </div>
          )}

          {!isLoading && (
            <>
              {isEmpty(loadedFiles) ? (
                <FileUploaderPlaceHolder customStyles={customStyles} />
              ) : (
                loadedFiles.map((file, index) => (
                  <FileUploaderLoadedFiles
                    file={file}
                    key={index}
                    onRemoveFile={onRemoveFile}
                  />
                ))
              )}
            </>
          )}
        </div>
      </FileDrop>
    </div>
  );
};

export const FileUploaderController = memo((props) => {
  return <FieldController {...props} component={FileUploader} />;
});

export default memo(FileUploader);
