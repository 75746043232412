import { FormattedMessage, useIntl } from 'react-intl';

import { useForm } from 'react-hook-form';

import { FormActions } from '@shared/components';
import { InputController } from '@shared/ui';

import SideBar from '@components/SideBar';
import { SideBarDescription } from '@components/SideBar/components';

import { useVisaMutations } from '@api/hooks';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

const SideBarActivateCard = () => {
  const intl = useIntl();

  const { isOpen, closeSidebar } = useSidebar(sidebarIds.ACTIVATE_CARD);

  const { onActivateCard, isActivateCardLoading } = useVisaMutations();

  const methods = useForm({
    defaultValues: { pan: '', cvv: '' },
  });

  const { handleSubmit, reset } = methods;

  const commonInputProps = {
    size: 'l',
    inversion: true,
    bordered: false,
    underlined: true,
  };

  const handleCloseSidebar = () => {
    reset();
    closeSidebar();
  };

  const handleActivateCard = async (values) => {
    try {
      await onActivateCard(values);
      handleCloseSidebar();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleCloseSidebar}
      withCloseButton
      title={intl.formatMessage({ id: 'ActivateCard' })}
    >
      <form
        onSubmit={handleSubmit(handleActivateCard)}
        className="h-100 d-flex flex-column align-items-start justify-content-between"
      >
        <div>
          <div className="mt-12">
            <SideBarDescription>
              <FormattedMessage id="ActivateCardDescription" />
            </SideBarDescription>
          </div>
          <div className="mt-20">
            <InputController
              name="pan"
              label="PAN"
              placeholder={intl.formatMessage({ id: 'TypePANCode' })}
              rules={{
                required: intl.formatMessage({ id: 'FieldRequired' }),
              }}
              {...methods}
              {...commonInputProps}
            />
          </div>
          <div className="mt-10">
            <InputController
              name="cvv"
              label="CVV"
              placeholder={intl.formatMessage({ id: 'TypeCVVCode' })}
              rules={{
                required: intl.formatMessage({ id: 'FieldRequired' }),
              }}
              {...methods}
              {...commonInputProps}
            />
          </div>
        </div>

        <div className="w-100 py-40">
          <FormActions
            inversion
            submitText={<FormattedMessage id="Activate" />}
            onCancel={handleCloseSidebar}
            submitButtonProps={{
              isLoading: isActivateCardLoading,
              disabled: isActivateCardLoading,
            }}
          />
        </div>
      </form>
    </SideBar>
  );
};

export default SideBarActivateCard;
