import React, { memo, useMemo } from 'react';

import { InfoWidget, PersonalFormattedCurrency } from '@shared/components';
import { CRYPTO_CURRENCIES } from '@shared/constants';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import useUserWithdrawLimit from '../../../../WalletsPage/hooks/useUserWithdrawLimit';
import { HeaderTitleInfo } from '../HeaderTitleInfo';

import { ACCOUNT_ROUTES } from '../../../../../routes.constants';

import styles from './DailyWithdrawalLimitWidget.module.scss';

const DailyWithdrawalLimitWidget = () => {
  const currency = CRYPTO_CURRENCIES.BTC;

  const { isMobile } = useBreakpoints();

  const { userWithdrawLimit } = useUserWithdrawLimit(currency);

  const { isUserHasMaxLevel } = useGetProfileInfoQueryResult();

  const dailyWithdrawLimit = useMemo(
    () => (!!userWithdrawLimit ? userWithdrawLimit : 0),
    [userWithdrawLimit],
  );

  const formatOptions = {
    removeZeroEnd: true,
  };

  const isLinkToVisible = !isMobile && !isUserHasMaxLevel;

  return (
    <InfoWidget
      customStyles={styles}
      title={
        <HeaderTitleInfo
          titleId="DailyWithdrawalLimit"
          info="DailyWithdrawalLimitInfo"
        />
      }
      linkId="UpgradeLevel"
      linkTo={isLinkToVisible && ACCOUNT_ROUTES.KYC}
      value={
        <PersonalFormattedCurrency
          value={dailyWithdrawLimit}
          currency={currency}
          formatOptions={formatOptions}
        />
      }
      contentClassName="gap-10"
      suffix={<span className="value-suffix">{currency.toUpperCase()}</span>}
    />
  );
};

export default memo(DailyWithdrawalLimitWidget);
