import React, { memo } from 'react';

import { CurrencyItem } from '@shared/components';

import styles from './AssetItem.module.scss';

const AssetItem = ({
  coinSymbol,
  isShowFullName = false,
  isShowCurrencyIcon = true,
  iconHeight,
  iconWidth,
  isShowSymbol = true,
}) => {
  return (
    <CurrencyItem
      coinSymbol={coinSymbol}
      className={styles.asset}
      iconAccessor="icon"
      isShowCurrencyIcon={isShowCurrencyIcon}
      isShowSymbol={isShowSymbol}
      isShowFullName={isShowFullName}
      iconHeight={iconHeight}
      iconWidth={iconWidth}
    />
  );
};

export default memo(AssetItem);
