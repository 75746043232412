import React, { memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { InformationBlock } from '@shared/components';
import { COIN_TYPE, PAYMENT_METHOD } from '@shared/constants';

import { SideBarAddWithdrawAddress } from '@pages/WalletsPage/Withdraw/sidebars';
import {
  selectedCoinTypeSelector,
  withdrawCurrencySymbolSelector,
  withdrawPaymentMethodSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';
import { walletWithdrawActions } from '@pages/WalletsPage/Withdraw/Withdraw.slice';

import { useCoinsInfo } from '@store/api/hooks';

import { WithdrawCoinBlock } from './WithdrawCoinBlock';
import { WithdrawHistory } from './WithdrawHistory';

const defaultMinWithdraw = 0;

const Withdraw = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const activePaymentMethod = useSelector(withdrawPaymentMethodSelector);
  const selectedCoinType = useSelector(selectedCoinTypeSelector);
  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);

  const isFiatCoin = selectedCoinType === COIN_TYPE.FIAT;

  const { coinsInfoMap } = useCoinsInfo({
    orderByTypeDirection: 'asc',
  });

  const selectedCoinInfo = useMemo(
    () => coinsInfoMap.find((coin) => coin.symbol === selectedCurrencySymbol),
    [coinsInfoMap, selectedCurrencySymbol],
  );

  useEffect(() => {
    dispatch(walletWithdrawActions.setWithdrawCoins(coinsInfoMap));
  }, [coinsInfoMap]);

  const minimalWithdrawAmount = `${
    selectedCoinInfo?.min_withdraw || defaultMinWithdraw
  } ${selectedCurrencySymbol?.toUpperCase()}`;

  const attentionBlockItems = useMemo(() => {
    const minimalWithdrawInfo = intl.formatMessage(
      { id: 'withdrawAttention.message1' },
      {
        amount: minimalWithdrawAmount,
        b: (str) => <strong>{str}</strong>,
      },
    );

    const cryptoCoinInfo = intl.formatMessage({
      id: 'withdrawAttention.message2',
    });

    const sepaBankInfo = intl.formatMessage({
      id: 'withdrawAttention.message3',
    });

    const advcashInfo = intl.formatMessage({
      id: 'withdrawAttention.message4',
    });

    let blocks = [minimalWithdrawInfo];

    if (isFiatCoin) {
      if (activePaymentMethod === PAYMENT_METHOD.BANK_TRANSFER) {
        blocks = [...blocks, sepaBankInfo];
      } else {
        blocks = [...blocks, advcashInfo];
      }
    } else {
      blocks = [...blocks, cryptoCoinInfo];
    }

    return blocks;
  }, [activePaymentMethod, minimalWithdrawAmount, isFiatCoin]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-8 col-12">
          <WithdrawCoinBlock coins={coinsInfoMap} />
        </div>
        <div className="col-xl-4 col-12 mt-xl-0 mt-44">
          {!!selectedCurrencySymbol && (
            <InformationBlock
              title={intl.formatMessage({ id: 'Attention' })}
              items={attentionBlockItems}
            />
          )}
        </div>
      </div>
      <div className="row mt-44">
        <div className="col-12">
          <WithdrawHistory />
        </div>
      </div>

      <SideBarAddWithdrawAddress />
    </div>
  );
};

export default memo(Withdraw);
