export const findDuplicatesAndSum = (inptArr) => {
  const duplicateIndex = {};
  const outputArr = [];

  inptArr?.forEach((item) => {
    let collisionIndex = duplicateIndex[item[0]];

    if (collisionIndex > -1) {
      outputArr[collisionIndex][1] += item[1];
    } else {
      outputArr.push(item);
      duplicateIndex[item[0]] = outputArr.length - 1;
    }
  });

  return outputArr;
};

export const StakingUtils = {
  findDuplicatesAndSum,
};
