import { memo, useMemo } from 'react';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import Table from '@components/gridTables/Table';

import { useGetReferralQueryResult } from '@store/api/hooks';

import earningHistoryColumns from './earningHistoryColumns';
import referralHistoryColumns from './referralHistoryColumns';

const ReferralTable = ({
  variant,
  hideColumnNames = [],
  queryParams,
  isSmallAvatars,
  ...rest
}) => {
  const { referralEarning, referralHistory, referralMeta, referralIsFetching } =
    useGetReferralQueryResult(queryParams);

  const columns = useMemo(
    () =>
      variant === 'referralHistory'
        ? referralHistoryColumns({ hideColumnNames, isSmallAvatars })
        : earningHistoryColumns({
            hideColumnNames,
            currency: CRYPTO_CURRENCIES.PRDX,
            isSmallAvatars,
          }),
    [variant, hideColumnNames, isSmallAvatars],
  );

  const data = useMemo(
    () => (variant === 'referralHistory' ? referralHistory : referralEarning),
    [variant, referralHistory, referralEarning],
  );

  return (
    <Table
      id="referral-table"
      className="account-tables-swipe"
      columns={columns}
      data={data}
      isLoading={referralIsFetching}
      swipeableTableProps={{
        stickyColumns: 1,
      }}
      meta={referralMeta}
      {...rest}
    />
  );
};

export default memo(ReferralTable);
