import { WalletsApiSelectors } from '@api/selectors';
import { useGetClearJunctionWithdrawSettingsQuery } from '@api/walletsAPI';

const useGetClearJunctionWithdrawSettingsQueryResult = (args, options) => {
  return useGetClearJunctionWithdrawSettingsQuery(args, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      settings:
        WalletsApiSelectors.getClearJunctionWithdrawSettingsSelector(result),
    }),
  });
};

export default useGetClearJunctionWithdrawSettingsQueryResult;
