import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button } from '@shared/ui';

import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import { currencySelector } from '@store/settings/settingsSelectors';

import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { FIAT_PRECISION, sidebarIds } from '@constants';

import s from './CardBalance.module.scss';

const CardBalance = () => {
  const card = useSelector(visaCardSelector);
  const currency = useSelector(currencySelector);

  const { openSidebar } = useSidebar(sidebarIds.TOP_UP_CARD);

  const cardBalance = useMemo(() => {
    if (!card.balance) return '';
    return format(card.balance, { precision: FIAT_PRECISION });
  }, [card.balance]);

  if (!card.number) return null;

  const handleOpenTopUpSidebar = () => {
    openSidebar();
  };

  return (
    <div className={s.card_balance}>
      <div>
        <div className={s.label}>
          <FormattedMessage id="CardNumber" />
        </div>
        <div className={s.balance}>
          <div>{cardBalance}</div>
          <div className={s.balance__currency}>{currency?.symbol}</div>
        </div>
      </div>
      <div>
        <Button onClick={handleOpenTopUpSidebar}>
          <FormattedMessage id="TopUp" />
        </Button>
      </div>
    </div>
  );
};

export default CardBalance;
