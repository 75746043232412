import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Card, InfoItem } from '@shared/components';
import { Button } from '@shared/ui';

import { userEmailSelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';

import { truncateEmail } from '@utils';

import { ReactComponent as ConfirmedPrimaryIcon } from '@icons/confirmed-primary-icon.svg';

import styles from './ChangeEmailCard.module.scss';

const ChangeEmailCard = () => {
  const userEmail = useSelector(userEmailSelector);

  const { isMobile } = useBreakpoints();

  return (
    <Card
      customStyles={styles}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <FormattedMessage id="ChangeEmail" />
          </div>

          <div className="fs-14 d-flex gap-8 align-items-center">
            <ConfirmedPrimaryIcon width={16} height={16} />

            <FormattedMessage id="Verified" />
          </div>
        </div>
      }
    >
      <div className={styles.change_email_text}>
        <FormattedMessage id="TheChangeProcess" />
      </div>

      <div className={styles.change_email}>
        <InfoItem
          customStyles={styles}
          title={
            <>
              <FormattedMessage id="CurrentEmail" />:
            </>
          }
        >
          <div className={styles.value}>{truncateEmail(userEmail)}</div>
        </InfoItem>

        <div className={styles.button_container}>
          <Button fill={isMobile} navigation={{ to: '/coming-soon' }}>
            <FormattedMessage id="ChangeEmail" />
            ...
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default memo(ChangeEmailCard);
