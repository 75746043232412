import { createSlice } from '@reduxjs/toolkit';

import { ITEMS_PER_PAGE } from '@constants';

import { EXPIRED_CONTRACTS_DEFAULT_VALUES } from '../instantBonus.constants';

const initialState = {
  filters: EXPIRED_CONTRACTS_DEFAULT_VALUES,
  pagination: {
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  },
};

const expiredContractsSlice = createSlice({
  name: 'expiredContracts',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    resetPagination: (state, action) => {
      state.pagination = initialState.pagination;
    },
  },
});

const expiredContractsActions = expiredContractsSlice.actions;
const expiredContractsReducer = expiredContractsSlice.reducer;

export { expiredContractsActions, expiredContractsReducer };

export default expiredContractsSlice;
