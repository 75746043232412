export const COLUMN_ALIGN = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const VARIANTS_ROWS = {
  STRIPED: 'striped',
  UNDERLINED: 'underlined',
  UNDERLINED_LIGHT: 'underlined-light',
};

export const HEADER_HEIGHT = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
};

export const ROW_HEIGHT = {
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
};

export const SWIPER_PAGINATION_SIZE = {
  S: 's',
  M: 'm',
};

export const SWIPER_PAGINATION_VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
};

export const PAGINATION_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};
