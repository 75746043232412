import { forwardRef, memo, useMemo } from 'react';

import { createFilter } from 'react-select';

import { FormController } from '@shared/components';
import { visaCardOptions } from '@shared/components/VisaCardSelect/visaCardOptions';
import { Select } from '@shared/ui';

const ICON_SIZE = {
  m: { width: 25, height: 16 },
  l: { width: 32, height: 20 },
};

const VisaCardSelect = forwardRef(
  ({ customStyles = {}, iconSize, ...props }, ref) => {
    const { width, height } = iconSize ?? ICON_SIZE[props.size];

    const mappedOptions = useMemo(() => {
      return visaCardOptions({
        customStyles,
        iconWidth: width,
        iconHeight: height,
      });
    }, [customStyles, height, width]);

    const filterConfig = {
      ignoreCase: true,
      trim: true,
    };

    return (
      <Select
        ref={ref}
        options={mappedOptions}
        filterOption={createFilter(filterConfig)}
        {...props}
      />
    );
  },
);

export const VisaCardSelectController = memo((props) => {
  return <FormController component={VisaCardSelect} {...props} />;
});

export default memo(VisaCardSelect);
