import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { ApiSelectorsUtils } from '../utils';

const defaultAnnouncement = [];
const defaultData = {};
const defaultMeta = {};

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const announcementSelector = createSelector(dataSelector, (data) => ({
  announcement: data.current || defaultData,
  previousAnnouncement: data.previous || 0,
  nextAnnouncement: data.next || 0,
}));

const announcementsSelector = createSelector(dataSelector, (data) => {
  return data.announcement || defaultAnnouncement;
});

const announcementsByTopicSelector = createSelector(dataSelector, (data) =>
  !isEmpty(data) ? data.data : defaultAnnouncement,
);

const announcementsByTopicMetaSelector = createSelector(
  metaSelector,
  (meta) => {
    if (isEmpty(meta)) return meta;

    return {
      count: meta.total_count,
      limit: meta.page_size,
      page: meta.current_page,
    };
  },
);

const announcementTopicsSelector = createSelector(dataSelector, (data) => {
  return data.topics || defaultAnnouncement;
});

export const AnnouncementsApiSelectors = {
  announcementSelector,
  announcementsSelector,
  announcementsByTopicSelector,
  announcementTopicsSelector,
  announcementsByTopicMetaSelector,
  metaSelector,
};
