export const withdrawPaymentMethodSelector = (state) =>
  state.walletsWithdraw.paymentMethod;
export const selectedCoinTypeSelector = (state) =>
  state.walletsWithdraw.selectedCoinType;
export const checkoutWithdrawDataSelector = (state) =>
  state.walletsWithdraw.checkoutWithdrawData;
export const bankTransferTypeSelector = (state) =>
  state.walletsWithdraw.bankTransferType;
export const bankTransferDetailsSelector = (state) =>
  state.walletsWithdraw.bankTransferDetails;
export const withdrawCurrencySymbolSelector = (state) =>
  state.walletsWithdraw.withdrawCurrencySymbol;
export const userDataSelector = (state) => state.walletsWithdraw.userData;
export const withdrawCoinsSelector = (state) => state.walletsWithdraw.coins;
