import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import {
  TransactionHistoryFilters,
  TransactionHistoryTable,
} from '@pages/WalletsPage/TransactionHistory/components';

import { transactionHistoryActions } from './transactionHistory.slice';

import { TRANSACTION_HISTORY_DEFAULT_VALUES } from './transactionHistory.constants';

const TransactionHistory = () => {
  const dispatch = useDispatch();

  const handleFiltersChange = (values) => {
    dispatch(transactionHistoryActions.resetPagination());
    dispatch(transactionHistoryActions.setFilters(values));
  };

  return (
    <>
      <TransactionHistoryFilters
        defaultValues={TRANSACTION_HISTORY_DEFAULT_VALUES}
        onFiltersChange={handleFiltersChange}
      />
      <TransactionHistoryTable />
    </>
  );
};

export default memo(TransactionHistory);
