import React, { memo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { localeSelector } from '@store/settings/settingsSelectors';

import { intlMessages } from '@utils/translations';

const LocalizationProvider = ({ children }) => {
  const locale = useSelector(localeSelector);

  return (
    <IntlProvider locale={locale.value} messages={intlMessages[locale.value]}>
      {children}
    </IntlProvider>
  );
};

export default memo(LocalizationProvider);
