import IBAN from 'iban';

import {
  CRYPTO_CURRENCIES,
  INTERNAL_COIN_ADDRESSES,
  INTERNAL_EMAILS,
} from '@shared/constants';

export const isMinWithdrawAmountForCoin = (coin, intl) => {
  return (amount) => {
    if (Number(amount) >= Number(coin.min_withdraw)) {
      return true;
    }

    return intl.formatMessage(
      { id: 'WithdrawAmountValidation' },
      { amount: coin.min_withdraw },
    );
  };
};

export const isMaxWithdrawAmountForUser = (
  userWithdrawLimit,
  currency,
  intl,
) => {
  return (amount) => {
    if (Number(amount) <= userWithdrawLimit) {
      return true;
    }

    return intl.formatMessage(
      { id: 'WithdrawMaxAmountValidation' },
      { amount: `${userWithdrawLimit} ${currency.toUpperCase()}` },
    );
  };
};

export const isAmountAvailable = (availableAmount, currency, intl) => {
  return (amount) => {
    if (Number(amount) <= Number(availableAmount)) {
      return true;
    }

    return intl.formatMessage(
      { id: 'AvailableAmountValidation' },
      { amount: `${availableAmount} ${currency.toUpperCase()}` },
    );
  };
};

export const isNewAddress = (allAddresses, intl) => {
  return (newAddress) => {
    const isAddressFound = !!allAddresses.find(
      ({ address }) => address === newAddress,
    );

    if (!isAddressFound) {
      return true;
    }

    return intl.formatMessage({ id: 'AddressAlreadyExists' });
  };
};

export const isNotInternalAddress = (email, coin, intl) => {
  return (address) => {
    const internalEmails = [INTERNAL_EMAILS.SUPPORT, INTERNAL_EMAILS.LEGAL];

    // for internal emails we don't check coins addresses
    if (internalEmails.includes(email)) return true;

    if (
      (coin === CRYPTO_CURRENCIES.XRP &&
        INTERNAL_COIN_ADDRESSES.XRP === address?.value) ||
      (coin === CRYPTO_CURRENCIES.EOS &&
        INTERNAL_COIN_ADDRESSES.EOS === address?.value)
    ) {
      return intl.formatMessage({
        id: 'TransactionWithInternalAddressValidation',
      });
    }

    return true;
  };
};

export const isIBANValid = (intl) => {
  return (iban) => {
    if (IBAN.isValid(iban)) {
      return true;
    }

    return intl.formatMessage({ id: 'IBANIsNotValid' });
  };
};
