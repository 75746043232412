import React, { memo, useEffect, useState } from 'react';

import classnames from 'classnames';

import Link from '@components/Link';

import { LOCAL_STORAGE_RUNNING_LINE } from '@constants';

import { ReactComponent as PauseIconSVG } from '@icons/pause-icon.svg';
import { ReactComponent as PlusIconSVG } from '@icons/plus-icon.svg';

import HeaderItem from '../HeaderItem';

import RunningLine from './RunningLine';

import { MAIN_ROUTES } from 'src/routes.constants';

const HeaderRunningLine = () => {
  const [isRunningLinePlay, setIsRunningLinePlay] = useState(false);

  const toggleRunningLinePlay = (e) => {
    setIsRunningLinePlay((prevState) => !prevState);
  };

  useEffect(() => {
    const runningLinePlayState =
      localStorage.getItem(LOCAL_STORAGE_RUNNING_LINE) || 'true';

    if (runningLinePlayState === 'true') {
      setIsRunningLinePlay(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_RUNNING_LINE, isRunningLinePlay);
  }, [isRunningLinePlay]);

  return (
    <>
      <HeaderItem withDivider>
        <PauseIconSVG
          onClick={toggleRunningLinePlay}
          className={classnames('pause-icon', { active: !isRunningLinePlay })}
        />
      </HeaderItem>

      <HeaderItem withDivider className="flex-1 px-0">
        <RunningLine
          play={isRunningLinePlay}
          pauseOnHover={!isRunningLinePlay}
          pauseOnClick={!isRunningLinePlay}
        />
      </HeaderItem>
    </>
  );
};

export default memo(HeaderRunningLine);
