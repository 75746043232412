import React from 'react';

import { endOfDay, startOfDay, subDays } from 'date-fns';
import { toMomentObject } from 'react-dates';

import { Button } from '@shared/ui';

import { ReactComponent as PrevCalendarIconSVG } from '@icons/navigation-calendar-icon.svg';

import s from './DatePicker.module.scss';

export const FROM_ZERO = 0;
export const SEVEN_DAYS = 7;
export const THIRTY_DAYS = 30;
export const NINETY_DAYS = 90;
export const SINGLE_DAY = 0;
export const NUMBER_OF_MONTH = 2;
export const YEAR_2K = 2000;

const TODAY_DATE = new Date();

export const START_OF_TODAY = toMomentObject(startOfDay(TODAY_DATE));
export const END_OF_TODAY = toMomentObject(endOfDay(TODAY_DATE));
export const LAST_SIX_DAYS = toMomentObject(
  subDays(TODAY_DATE, SEVEN_DAYS - 1),
);
export const LAST_THIRTY_DAYS = toMomentObject(
  subDays(TODAY_DATE, THIRTY_DAYS),
);
export const LAST_TWENTY_NINE_DAYS = toMomentObject(
  subDays(TODAY_DATE, THIRTY_DAYS - 1),
);
export const LAST_EIGHTY_NINE_DAYS = toMomentObject(
  subDays(TODAY_DATE, NINETY_DAYS - 1),
);

export const DATE_FORMAT_WITH_SLASH = 'DD/MM/YYYY';
export const DATE_FORMAT_WITH_DOT = 'DD.MM.YYYY';
export const DATE_FORMAT_WITH_DASH = 'YYYY-MM-DD';

export const DATEPICKER_ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

const getButtonName = (intl, id, days) => intl.formatMessage({ id }, { days });

export const RANGE_BUTTONS_IDS = {
  SEVEN_DAYS: 'last-seven-days',
  THIRTY_DAYS: 'last-thirty-days',
  NINETY_DAYS: 'last-ninety-days',
  CUSTOM_RANGE: 'custom-range',
  TODAY: 'today',
};

export const timeRangeButtons = (intl, isCustomRangeButtonDisplayed) => {
  const buttons = [
    {
      id: RANGE_BUTTONS_IDS.SEVEN_DAYS,
      valueShort: getButtonName(intl, 'DynamicDays', SEVEN_DAYS),
      valueLong: getButtonName(intl, 'LastDays', SEVEN_DAYS),
      icon: null,
    },
    {
      id: RANGE_BUTTONS_IDS.THIRTY_DAYS,
      valueShort: getButtonName(intl, 'DynamicDays', THIRTY_DAYS),
      valueLong: getButtonName(intl, 'LastDays', THIRTY_DAYS),
      icon: null,
    },
    {
      id: RANGE_BUTTONS_IDS.NINETY_DAYS,
      valueShort: getButtonName(intl, 'DynamicDays', NINETY_DAYS),
      valueLong: getButtonName(intl, 'LastDays', NINETY_DAYS),
      icon: null,
    },
  ];

  if (isCustomRangeButtonDisplayed) {
    buttons.push({
      id: RANGE_BUTTONS_IDS.CUSTOM_RANGE,
      valueShort: getButtonName(intl, 'Other', null),
      valueLong: getButtonName(intl, 'CustomRange', null),
      icon: null,
    });
  }

  return [
    ...buttons,
    {
      id: RANGE_BUTTONS_IDS.TODAY,
      valueShort: getButtonName(intl, 'Today', null),
      valueLong: getButtonName(intl, 'Today', null),
      icon: <div className={s.dot} />,
    },
  ];
};

export const commonPickerProps = {
  hideKeyboardShortcutsPanel: true,
  noBorder: true,
  weekDayFormat: 'ddd',
  navPrev: (
    <Button variant="text" size="s">
      <PrevCalendarIconSVG />
    </Button>
  ),
  navNext: (
    <Button variant="text" size="s">
      <PrevCalendarIconSVG style={{ transform: 'rotate(180deg)' }} />
    </Button>
  ),
  daySize: 36,
  renderDayContents: (day) => {
    return <div className="single-day">{day.format('D')}</div>;
  },
  isDayHighlighted: (day) => day.isSame(new Date(), 'd'),
};
