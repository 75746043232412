import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';
import subscriptions from '@api/ws/subscriptions';

export const notificationsAPI = createApi({
  reducerPath: 'notificationsAPI',
  baseQuery: client,
  tagTypes: ['NotificationsTotalUnread', 'NotificationsHistory'],
  endpoints: (builder) => ({
    changeNotificationsStatus: builder.mutation({
      query: ({ ids, status }) => {
        const form = new FormData();

        ids.forEach((id) => form.append('id', id));

        form.append('status', status);

        return {
          url: 'notifications/status',
          method: 'POST',
          body: form,
        };
      },
      invalidatesTags: [
        'NotificationsInfo',
        'NotificationsTotalUnread',
        'NotificationsHistory',
      ],
    }),
    notificationsTotalUnread: builder.query({
      query: () => 'notifications/unread/total',
      providesTags: ['NotificationsTotalUnread'],
    }),
    notificationsHistory: builder.query({
      query: (params) => ({
        url: 'notifications',
        params,
      }),
      providesTags: ['NotificationsHistory'],
    }),
    streamNotifications: builder.query({
      queryFn: () => ({ data: undefined }),
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(
        userId,
        { dispatch, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded;

          subscriptions.subscribeNotifications(
            userId,
            ({ draftState, reason }) => {
              if (reason === 'error') {
                return null;
              }

              if (reason === 'unsubscribe') {
                return draftState;
              }

              if (reason === 'publish') {
                dispatch(
                  notificationsAPI.util.invalidateTags([
                    'NotificationsTotalUnread',
                  ]),
                );

                dispatch(
                  notificationsAPI.util.invalidateTags([
                    'NotificationsHistory',
                  ]),
                );
              }
            },
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        await cacheEntryRemoved;

        subscriptions.unsubscribeNotifications();
      },
    }),
    deleteNotification: builder.mutation({
      query: ({ notificationId }) => {
        return {
          url: `notifications/${notificationId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['NotificationsHistory', 'NotificationsTotalUnread'],
    }),
    deleteNotifications: builder.mutation({
      query: ({ from, to }) => {
        return {
          url: `notifications/range`,
          params: {
            from,
            to,
          },
          method: 'DELETE',
        };
      },
      invalidatesTags: ['NotificationsHistory', 'NotificationsTotalUnread'],
    }),
  }),
});

export const {
  useStreamNotificationsQuery,
  useNotificationsHistoryQuery,
  useNotificationsTotalUnreadQuery,
  useChangeNotificationsStatusMutation,
  useDeleteNotificationMutation,
  useDeleteNotificationsMutation,
} = notificationsAPI;
