import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import cx from 'classnames';

import { CARD_TYPE_MAP } from '@shared/constants';
import { Button } from '@shared/ui';

import StatusDot from '@components/StatusDot';

import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as LockIconSVG } from '@icons/lock-icon.svg';

import s from './CardType.module.scss';

const CardType = () => {
  const intl = useIntl();

  const card = useSelector(visaCardSelector);
  const { isMobile } = useBreakpoints();

  const cardData = useMemo(() => {
    if (!card.type) return null;

    return {
      type: card.type,
      title: CARD_TYPE_MAP[card.type].intl,
      icon: CARD_TYPE_MAP[card.type].icon,
      expiredDate: new Date(card.expiredDate).getTime(),
      status: intl.formatMessage({ id: 'ActiveVirtual' }),
    };
  }, [intl, card]);

  const handleLockCard = useCallback((e) => {
    console.log('handleLockCard');

    e.stopPropagation();
  }, []);

  if (!cardData) return null;

  return (
    <div
      className={cx(s.card_type, {
        [s.card_type__column]: isMobile,
      })}
    >
      <div className={s.card_type__info}>
        <div className={s.icon}>
          <cardData.icon />
        </div>
        <div>
          <div className={s.card_title}>
            <FormattedMessage id={cardData.title} />
          </div>
          <div className={s.description}>
            <StatusDot color="green" />
            <div className={s.status}>{cardData.status}</div>
            <div className={s.expired}>
              <FormattedMessage id="Expired" />:{' '}
              {intl.formatDate(cardData.expiredDate, {
                month: '2-digit',
                year: '2-digit',
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={cx('mt-md-0 mt-20', { 'w-100': isMobile })}>
        <Button
          type="button"
          onClick={handleLockCard}
          customStyles={s}
          variant="outlined"
          fill={isMobile}
        >
          <LockIconSVG className={s.lock_icon} />
          <FormattedMessage id="LockCard" />
        </Button>
      </div>
    </div>
  );
};

export default CardType;
