export const visaCardSelector = (state) => state.visaCardDetails.visaCard;
export const availableCardsSelector = (state) =>
  state.visaCardDetails.availableCards;
export const topUpMethodSelector = (state) => state.visaCardDetails.topUpMethod;
export const transferRecipientSelector = (state) =>
  state.visaCardDetails.transferRecipient;
export const spotAccountFormDetailsSelector = (state) =>
  state.visaCardDetails.spotAccountFormDetails;
export const confirmTransferMoneyDetailsSelector = (state) =>
  state.visaCardDetails.confirmTransferMoneyDetails;
