import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { components } from 'react-select';

import styles from './NoOptionsMessage.module.scss';

const NoOptionsMessage = ({ children, selectProps, ...props }) => {
  const isInversion = selectProps.inversion;

  return (
    <components.NoOptionsMessage
      className={classNames(styles.message, {
        [styles.inversion]: isInversion,
      })}
      {...props}
    >
      <FormattedMessage id="NotFound" />
    </components.NoOptionsMessage>
  );
};

export default memo(NoOptionsMessage);
