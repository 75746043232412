import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Fade } from 'react-awesome-reveal';

import Link from '@components/Link';

import { isAccountThemeSelector } from '@store/settings/settingsSelectors';

import { NOOP } from '@constants';

import { ReactComponent as RightChevronIconSVG } from '@icons/right-chevron-icon.svg';

import styles from './ProductsMenuNavigationItem.module.scss';

const ProductsMenuNavigationItem = ({
  item: { title = '', Icon = null, links = [], isNewBadge = false },
  index = 0,
  onLinkClick = NOOP,
}) => {
  const isAccountTheme = useSelector(isAccountThemeSelector);

  return (
    <div className={styles.products_menu_navigation_item}>
      <Fade direction="up" duration={500} delay={index * 100} triggerOnce>
        <div className="d-flex align-items-center mb-16">
          <Icon />

          <span className={styles.title}>{title}</span>

          {isNewBadge && (
            <div className={styles.is_new_badge}>
              <FormattedMessage id="New" />
            </div>
          )}
        </div>

        {links.map((link) => (
          <Link
            key={link.name}
            className={styles.link}
            inversion={isAccountTheme}
            onClick={onLinkClick}
            {...link}
          >
            <div className="d-flex align-items-center">
              <span className="fs-18">{link.name}</span>

              {link.isNewBadge && (
                <div className={styles.is_new_badge}>
                  <FormattedMessage id="New" />
                </div>
              )}
            </div>

            <RightChevronIconSVG />
          </Link>
        ))}
      </Fade>
    </div>
  );
};

export default memo(ProductsMenuNavigationItem);
