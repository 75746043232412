import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccountModal } from '@shared/components';
import { Loader } from '@shared/ui';

import s from './DatePickerModal.module.scss';

const DatePickerModal = ({ children, isOpen, setIsOpen, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleCalendarClose = () => {
    setIsOpen(false);
  };

  return (
    <AccountModal
      onClose={handleCalendarClose}
      isOpen={isOpen}
      afterOpen={() => setIsLoading(false)}
      afterClose={() => setIsLoading(true)}
      customStyles={s}
      autoWidth
      title={<FormattedMessage id="SelectDates" />}
      {...props}
    >
      {/*
          fix incorrect call sequences between bootstrap backdrop animation setTimeout (callback after animation ends)
          and setTimeout in componentDidMount of the CalendarMonth.jsx file to remove .hidden class from the .DatePicker
        */}
      {isLoading ? (
        <div className={s.day_picker__loader_container}>
          <Loader size="xxl" customStyles={s} />
        </div>
      ) : (
        <div className={s.day_picker}>{children}</div>
      )}
    </AccountModal>
  );
};

export default DatePickerModal;
