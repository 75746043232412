import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useStreamBalancesQuery } from '@store/api/balancesAPI';
import { useCoinsInfo, useCoinsRates, useSpotAccounts } from '@store/api/hooks';
import { accountBalancesSelectFromResult } from '@store/api/hooks/balances/useAccountBalances';
import {
  currencySelector,
  userIdSelector,
} from '@store/settings/settingsSelectors';

import { format } from '@utils/numbers';

const useSpotTotalBalances = ({
  coinSymbol,
  filedName = 'total_balance', // 'available' | 'in_orders'
  formatOptions = {},
} = {}) => {
  const { value: currency } = useSelector(currencySelector);
  const { spotAccountsInfoMap, isAccountsSuccess } = useSpotAccounts();
  const userId = useSelector(userIdSelector);

  const balancesResult = useStreamBalancesQuery(userId);

  const totalBalanceCoinSymbol = (coinSymbol || currency)?.toLowerCase();

  const { ratesByCoin: coinRates, isCoinsRatesSuccess } = useCoinsRates({
    fromCoinSymbol: totalBalanceCoinSymbol,
  });

  const { coinInfo, coinsInfo, isCoinsInfoSuccess } = useCoinsInfo({
    coinSymbol: totalBalanceCoinSymbol,
  });

  const totalBalance = useMemo(() => {
    return spotAccountsInfoMap.reduce((sum, account) => {
      const { accountBalancesMap } = accountBalancesSelectFromResult(
        balancesResult,
        {
          accountId: account.id,
          coinsInfo,
          isHideZeroTotalBalances: true,
          isCoinsInfoSuccess,
        },
      );

      const accountSum = accountBalancesMap.reduce((accSum, balance) => {
        const coinRate = coinRates?.[balance.symbol.toUpperCase()];

        if (coinRate) {
          const balanceCoinRate = Number.parseFloat(coinRate) || 0;
          const totalField = Number.parseFloat(balance[filedName]) || 0;

          return totalField / balanceCoinRate + accSum;
        }

        return accSum;
      }, 0);

      return sum + accountSum;
    }, 0);
  }, [
    balancesResult,
    coinRates,
    coinsInfo,
    filedName,
    isCoinsInfoSuccess,
    spotAccountsInfoMap,
  ]);

  return {
    formattedTotalBalance: format(totalBalance, {
      precision: coinInfo?.digits,
      ...formatOptions,
    }),
    totalBalance,
    totalBalanceCoinSymbol,
    isTotalBalanceSuccess:
      isCoinsRatesSuccess && isCoinsInfoSuccess && isAccountsSuccess,
    precision: coinInfo?.digits,
  };
};

export default useSpotTotalBalances;
