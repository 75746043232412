import { memo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import cx from 'classnames';
import dateFormat from 'date-fns/format';

import { PersonalData, UserAvatar } from '@shared/components';
import {
  YEAR_MONTH_DAY_FORMAT,
  YEAR_MONTH_DAY_TIME_FORMAT,
} from '@shared/constants';
import { useUserAuthBy } from '@shared/hooks';
import { Button } from '@shared/ui';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';
import { userIdSelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';

import { DailyWithdrawalLimitWidget } from '../DailyWithdrawalLimitWidget';
import { ProfileKYCLevelWidget } from '../ProfileKYCLevelWidget';
import { TradingFeesWidget } from '../TradingFeesWidget';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

import styles from './UserHeader.module.scss';

const UserHeader = () => {
  const userId = useSelector(userIdSelector);

  const { isAuthByPhone } = useUserAuthBy();

  const intl = useIntl();
  const { isMobile, isTabletDown } = useBreakpoints();

  const { userNickname, userLastLogin } = useGetProfileInfoQueryResult();

  const lastLoginFormat = isTabletDown
    ? YEAR_MONTH_DAY_FORMAT
    : YEAR_MONTH_DAY_TIME_FORMAT;

  const userAvatarSize = isMobile ? 48 : 64;

  const defaultUserNickname = intl.formatMessage(
    { id: 'AnonymousUser' },
    {
      userId,
    },
  );

  return (
    <div className={cx('row', styles.user_info_header)}>
      <div className={cx('col-12 col-md-11 col-xl-5', styles.user_info)}>
        <div className={styles.user_block}>
          <div className={styles.avatar}>
            <UserAvatar width={userAvatarSize} height={userAvatarSize} />
          </div>
          <div className={styles.user_info_details}>
            <PersonalData
              type={isAuthByPhone ? 'phone' : 'email'}
              data={userNickname || defaultUserNickname}
            >
              {(value) => (
                <div title={value} className={styles.auth_by_value}>
                  {value}
                </div>
              )}
            </PersonalData>

            <div className={styles.login_details}>
              <div className={styles.id}>
                {intl.formatMessage({ id: 'Id' })}: {userId}
              </div>
              {userLastLogin ? (
                <div className={styles.last_login}>
                  {intl.formatMessage({ id: 'LastLogin' })}:{' '}
                  {dateFormat(new Date(userLastLogin), lastLoginFormat)}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="d-none d-md-block">
          <Button
            navigation={{ to: ACCOUNT_ROUTES.PROFILE }}
            variant="outlined"
          >
            <FormattedMessage id="Profile" />
          </Button>
        </div>
      </div>

      <div className="col d-none d-xl-block col-xl-2">
        <TradingFeesWidget />
      </div>

      <div className="col d-none d-xl-block col-xl-2">
        <ProfileKYCLevelWidget />
      </div>

      <div className="col d-none d-xl-block col-xxl-3">
        <DailyWithdrawalLimitWidget />
      </div>
    </div>
  );
};

export default memo(UserHeader);
