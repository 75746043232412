import { BLOCK_BREAKPOINTS } from 'src/constants/blockBreakpoints';
import { BLOCK_TYPES } from 'src/constants/blockTypes';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';
import useBreakpoints from '@hooks/useBreakpoints';

const useBothForms = ({ layoutItemRef }) => {
  const { isTabletDown } = useBreakpoints();

  const { isLandscapeX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.trading],
  );

  const isShowBothForms = !isTabletDown && isLandscapeX;

  return { isShowBothForms };
};

export default useBothForms;
