import { useGetAnnouncementsByTopicQuery } from '@api/announcementsAPI';
import { AnnouncementsApiSelectors } from '@api/selectors';

const useGetAnnouncementsByTopicQueryResult = (params, options = {}) => {
  return useGetAnnouncementsByTopicQuery(params, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      announcements:
        AnnouncementsApiSelectors.announcementsByTopicSelector(result),
      meta: AnnouncementsApiSelectors.announcementsByTopicMetaSelector(result),
    }),
  });
};

export default useGetAnnouncementsByTopicQueryResult;
