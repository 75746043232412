import { createSelector } from '@reduxjs/toolkit';

import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

const featureSelector = (state) => state.instantBonus.availableContracts;

const selectedPeriodsSelector = createSelector(
  featureSelector,
  (availableContracts) => {
    return availableContracts.selectedPeriods;
  },
);

const selectedPeriodSelector = createSelector(
  featureSelector,
  (availableContracts) => {
    return availableContracts.selectedPeriod;
  },
);

const searchSelector = createSelector(featureSelector, (availableContracts) => {
  return availableContracts[TABLE_FILTERS_NAMES.SEARCHBAR];
});

export const AvailableContractsSelectors = {
  selectedPeriodsSelector,
  selectedPeriodSelector,
  searchSelector,
};
