import { useMemo } from 'react';

import { useGetBotAnalyticsByVersionQuery } from '@api/botsAPI';
import { BotsApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isBotAnalyticsByVersionSuccess: result.isSuccess,
  };

  dataResult.botHistoryOrders = BotsApiSelectors.dataSelector(result);

  return dataResult;
};

const useBotAnalyticsByVersion = ({ botId, versionId } = {}, options) => {
  const result = useGetBotAnalyticsByVersionQuery(
    { botId, versionId },
    options,
  );

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useBotAnalyticsByVersion;
