import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { Accordion } from '@shared/components';

import s from './FAQAccordion.module.scss';

/**
 * This component import Accordion, so it accept all props for this component
 * Example of items:
 *
 * const items = [
 *   { label: 'title 1', children: 'text' },
 *   { label: 'title 2', children: 'text' },
 * ];
 * */

const FAQAccordion = ({
  title,
  description,
  items = [],
  customStyles = {},
  ...props
}) => {
  return (
    <div className={cx(s.faq_accordion, customStyles.faq_accordion)}>
      <div className={cx(s.faq_part, customStyles.left_part)}>
        {(title || description) && (
          <>
            <div className={cx(s.faq_title, customStyles.faq_title)}>
              {title || <FormattedMessage id="FAQ" />}
            </div>
            <div
              className={cx(s.faq_description, customStyles.faq_description)}
            >
              {description}
            </div>
          </>
        )}
      </div>
      <div className={cx(s.faq_part, customStyles.right_part)}>
        <Accordion customStyles={s} items={items} {...props} />
      </div>
    </div>
  );
};

export default FAQAccordion;
