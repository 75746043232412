import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import { marketIdSelector } from '@store/settings/settingsSelectors';

import styles from './MarketAnalytics.module.scss';

const MarketAnalytics = ({ isHidden }) => {
  const marketId = useSelector(marketIdSelector);

  useEffect(() => {
    const addTradingChart = (itemId, time) => {
      let tag = document.createElement('script');

      tag.type = 'text/javascript';
      tag.async = true;
      tag.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';

      tag.innerHTML = `
        {
          "interval": "${time}",
          "width": "450",
          "isTransparent": true,
          "height": "450",
          "symbol": "BINANCE:${marketId.toUpperCase()}",
          "showIntervalTabs": true,
          "locale": "en",
          "colorTheme": "dark"
        }
      `;

      document.getElementById(itemId).appendChild(tag);
    };

    addTradingChart('tradingContainer1', '1m');
    addTradingChart('tradingContainer2', '5m');
    addTradingChart('tradingContainer3', '15m');
  }, [marketId]);

  return (
    <div
      className={classnames('container-fluid', styles.market_analytics, {
        [styles['market_analytics--hide']]: isHidden,
      })}
    >
      <div className="row justify-content-center">
        <div
          className={classnames(
            'col-auto',
            styles.tradingview_widget_container,
          )}
          id="tradingContainer1"
        />
        <div
          className={classnames(
            'col-auto',
            styles.tradingview_widget_container,
          )}
          id="tradingContainer2"
        />
        <div
          className={classnames(
            'col-auto',
            styles.tradingview_widget_container,
          )}
          id="tradingContainer3"
        />
      </div>
    </div>
  );
};

export default memo(MarketAnalytics);
