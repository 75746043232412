import { Fragment, useState } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  TableIsLoading,
  TableNoData,
  TablePagination,
} from '@shared/components/Table/components';
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeaderCell,
  TableHeaderRow,
} from '@shared/components/Table/components/ui';
import {
  HEADER_HEIGHT,
  ROW_HEIGHT,
  VARIANTS_ROWS,
} from '@shared/components/Table/table.constants';
import { getColumnStyle } from '@shared/components/Table/table.utils';

import { ITEMS_PER_PAGE, NOOP } from '@constants';

import s from './TableDefault.module.scss';

const TableDefault = ({
  data,
  columns,
  onRowClick = NOOP,
  // styles
  headerHeight = HEADER_HEIGHT.XS, // 'xs' | 's' | 'm' | 'l'
  rowHeight = ROW_HEIGHT.S, // 's' | 'm' | 'l' | 'xl'
  variantRows = VARIANTS_ROWS.STRIPED, // 'striped' | 'underlined' | 'underlined-light',
  inversion,
  isColumnHeadersDisplayed = true,
  // for expanding
  renderSubComponent,
  // for pagination
  onPaginationChange,
  isPaginationDisplayed,
  isPageCounterDisplayed,
  paginationAlign,
  meta = { count: 0, pageIndex: 0, limit: ITEMS_PER_PAGE },
  isLoading,
  // for NoData component
  noDataLabel,
}) => {
  const [expanded, setExpanded] = useState({});

  const isClickableRow = onRowClick !== NOOP;

  const pageCount = Math.ceil(meta.count / meta.limit);

  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      pagination: {
        pageIndex: meta.pageIndex,
        pageSize: meta.limit,
      },
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange,
    manualPagination: true,
  });

  return (
    <div>
      <div className={s.table_wrapper}>
        {isLoading && <TableIsLoading inversion={inversion} />}
        <table>
          <TableHead>
            {isColumnHeadersDisplayed &&
              table.getHeaderGroups().map((headerGroup) => (
                <TableHeaderRow
                  key={headerGroup.id}
                  headerHeight={headerHeight}
                >
                  {headerGroup.headers.map((header, headerIndex) => (
                    <TableHeaderCell
                      key={header.id}
                      header={header}
                      inversion={inversion}
                      style={getColumnStyle(
                        headerIndex,
                        headerGroup.headers.length,
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHeaderCell>
                  ))}
                </TableHeaderRow>
              ))}
          </TableHead>

          {data.length > 0 && (
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableBodyRow
                    inversion={inversion}
                    rowHeight={rowHeight}
                    variantRows={variantRows}
                    isClickableRow={isClickableRow}
                  >
                    {row.getVisibleCells().map((cell, cellIndex) => (
                      <TableBodyCell
                        key={cell.id}
                        cell={cell}
                        style={getColumnStyle(
                          cellIndex,
                          row.getVisibleCells().length,
                        )}
                        onRowClick={
                          isClickableRow ? () => onRowClick(row) : undefined
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableBodyCell>
                    ))}
                  </TableBodyRow>
                  {row.getIsExpanded() &&
                    renderSubComponent({
                      row,
                      headerHeight,
                      rowHeight,
                      variantRows,
                    })}
                </Fragment>
              ))}
            </TableBody>
          )}

          {!data.length && (
            <TableNoData
              table={table}
              inversion={inversion}
              noDataLabel={noDataLabel}
            />
          )}
        </table>
      </div>
      {isPaginationDisplayed && (
        <div className="mt-24">
          <TablePagination
            table={table}
            meta={meta}
            isLoading={isLoading}
            paginationAlign={paginationAlign}
            isPageCounterDisplayed={isPageCounterDisplayed}
            inversion={inversion}
          />
        </div>
      )}
    </div>
  );
};

export default TableDefault;
