import { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

import { Select } from '@shared/ui';

import Button from '@components/Button';
import Input from '@components/Input';
import Modal from '@components/modals/Modal';
import RadioButton from '@components/RadioButton';

import { clearExtendedLayout } from '@pages/TerminalPage/components/GridLayout/gridLayoutHelper';

import useGridLayout from '@hooks/gridLayout/useGridLayout';
import useModal from '@hooks/useModal/useModal';

import { modalsIds, NOOP, TEMPLATE_SAVE_TYPES } from '@constants';

const SaveTemplateModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.SAVE_TEMPLATE,
  );

  const { onClose = NOOP, onSubmit = NOOP } = modal.params;

  const {
    mappedLayout,
    updateTemplates,
    templates,
    selectedTemplateId,
    updateSelectedTemplateId,
  } = useGridLayout();

  const [templateName, setTemplateName] = useState('');
  const [selectedSaveMethod, setSelectedSaveMethod] = useState(
    TEMPLATE_SAVE_TYPES.NEW,
  );
  const [selectedLayoutId, setSelectedLayoutId] = useState(selectedTemplateId);

  const placeholderLayoutName = `Layout-${templates.length + 1}`;

  useEffect(() => {
    if (isOpen) {
      setSelectedSaveMethod(
        templates.length
          ? TEMPLATE_SAVE_TYPES.EXISTING
          : TEMPLATE_SAVE_TYPES.NEW,
      );
    }
    if (templates.length) {
      setSelectedLayoutId(
        templates.find((item) => item.id === selectedTemplateId)
          ? selectedTemplateId
          : templates[0].id,
      );
    }
  }, [isOpen, templates.length, selectedTemplateId]);

  const updateTemplate = () => {
    const updatedTemplates = cloneDeep(templates);
    const updatedTemplate = updatedTemplates.find(
      (template) => template.id === selectedLayoutId,
    );

    updatedTemplate.data = clearExtendedLayout(mappedLayout);

    updateTemplates(updatedTemplates);
    updateSelectedTemplateId(selectedLayoutId);
  };

  const addTemplate = (newTemplate) => {
    updateTemplates([...templates, newTemplate]);
    updateSelectedTemplateId(newTemplate.id);
  };

  const handleClose = () => {
    closeModal();

    onClose();
  };

  const handleAfterClose = () => {
    setTemplateName('');
    setSelectedSaveMethod(TEMPLATE_SAVE_TYPES.NEW);
    setSelectedLayoutId(selectedTemplateId);
    resetModal();
  };

  const handleSuccess = () => {
    closeModal();

    onSubmit();
  };

  const handleSaveClick = () => {
    switch (selectedSaveMethod) {
      case TEMPLATE_SAVE_TYPES.EXISTING: {
        updateTemplate();

        handleSuccess();

        break;
      }

      case TEMPLATE_SAVE_TYPES.NEW: {
        const newTemplate = {
          id: uuidv4(),
          label: templateName || placeholderLayoutName,
          data: clearExtendedLayout(mappedLayout),
        };

        addTemplate(newTemplate);

        handleSuccess(newTemplate.id);

        break;
      }

      default:
        break;
    }
  };

  const handleSelectLayout = ({ id }) => setSelectedLayoutId(id);

  return (
    <Modal
      className="modal-sm"
      visible={isOpen}
      onClose={handleClose}
      afterClose={handleAfterClose}
      title={<FormattedMessage id="SaveLayout" />}
      footer={
        <div className="d-flex gap-16">
          <Button fill primary rounded onClick={handleSaveClick}>
            {selectedSaveMethod === TEMPLATE_SAVE_TYPES.EXISTING ? (
              <FormattedMessage id="Update" />
            ) : (
              <FormattedMessage id="Save" />
            )}
          </Button>

          <Button fill secondary rounded onClick={handleClose}>
            <FormattedMessage id="Cancel" />
          </Button>
        </div>
      }
    >
      <>
        {!!templates.length && (
          <>
            <RadioButton
              className="mb-8"
              onChange={(e) => setSelectedSaveMethod(e.target.value)}
              checked={selectedSaveMethod === TEMPLATE_SAVE_TYPES.EXISTING}
              name={TEMPLATE_SAVE_TYPES.EXISTING}
              value={TEMPLATE_SAVE_TYPES.EXISTING}
              label={<FormattedMessage id="UpdateTemplate" />}
            />

            <Select
              options={templates}
              value={templates.find(({ id }) => id === selectedLayoutId)}
              bordered
              onChange={handleSelectLayout}
              size="m"
              isDisabled={selectedSaveMethod !== TEMPLATE_SAVE_TYPES.EXISTING}
            />
          </>
        )}

        <div className={classnames({ 'mt-18': templates.length })}>
          <>
            <RadioButton
              className="mb-8"
              onChange={(e) => setSelectedSaveMethod(e.target.value)}
              checked={selectedSaveMethod === TEMPLATE_SAVE_TYPES.NEW}
              name={TEMPLATE_SAVE_TYPES.NEW}
              value={TEMPLATE_SAVE_TYPES.NEW}
              label={<FormattedMessage id="CreateNewTemplate" />}
            />

            <Input
              className="fs-14 fw-500"
              disabled={selectedSaveMethod !== TEMPLATE_SAVE_TYPES.NEW}
              autoFocus
              value={templateName}
              onChange={setTemplateName}
              placeholder={placeholderLayoutName}
            />
          </>
        </div>
      </>
    </Modal>
  );
};

export default memo(SaveTemplateModal);
