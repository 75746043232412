import { memo } from 'react';
import { useIntl } from 'react-intl';

import cx from 'classnames';
import { BLOCK_BREAKPOINTS } from 'src/constants/blockBreakpoints';
import { BLOCK_TYPES } from 'src/constants/blockTypes';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';
import useBreakpoints from '@hooks/useBreakpoints';

import { format } from '@utils/numbers';

import { useBothForms } from '../forms/hooks';

import styles from './TabContent.module.scss';

const TabContent = ({
  quoteAvailableBalance,
  marketAvailableBalance,
  marketInfo,
  layoutItemRef,
  isBuyTab,
  content,
}) => {
  const intl = useIntl();

  const { isTablet, isLaptop } = useBreakpoints();

  const { isMiniX, isLandscapeX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.trading],
  );

  const { isShowBothForms } = useBothForms({ layoutItemRef });

  const leftFormIsBuySide = isBuyTab || isShowBothForms;
  const isShowShortLabel = isTablet || (isLaptop && (isMiniX || isLandscapeX));

  const AvailableBalanceContent = (buyMode) => {
    const availableBalance = buyMode
      ? quoteAvailableBalance
      : marketAvailableBalance;

    const precision = buyMode
      ? marketInfo?.quote_precision_format
      : marketInfo?.market_precision_format;

    const coinSymbol = buyMode
      ? marketInfo?.quote_coin_symbol
      : marketInfo?.market_coin_symbol;

    const AvailableBalanceLabel = intl.formatMessage({
      id: isShowShortLabel ? 'Available' : 'AvailableBalance',
    });

    return (
      <div className={cx(styles.section)}>
        <div className={styles.label}>{AvailableBalanceLabel}:</div>

        <div>
          {format(availableBalance, {
            precision,
          })}{' '}
          <span className="text-uppercase">{coinSymbol}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 d-flex flex-column gap-8 mt-10">
      <div className="d-flex gap-20">
        <div className="flex-1">
          {AvailableBalanceContent(leftFormIsBuySide)}

          <div className="d-flex flex-column gap-20">
            {content(leftFormIsBuySide)}
          </div>
        </div>

        {isShowBothForms && (
          <div className="flex-1">
            {AvailableBalanceContent(false)}

            <div className="d-flex flex-column gap-20">{content(false)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TabContent);
