const conditionalRequired = (intl, isValidate, errorMessage) => (value) => {
  if (isValidate && !value)
    return errorMessage || intl.formatMessage({ id: 'FieldRequired' });

  return true;
};

export const ValidationUtils = {
  conditionalRequired,
};
