import { capitalizeFirstLetter } from '@shared/utils';

import { getSuborders } from '@utils/orders';

import { SUBORDER_TYPE } from '@constants';

import { STOP_SORT_TEXT } from '../ConfirmTradeModal.constant';

const useOtoSuborderValue = (order) => {
  const subOrders = getSuborders(order ?? {});

  return subOrders.reduce((acc, order) => {
    const side = capitalizeFirstLetter(order.side);
    const isStop = order.type === SUBORDER_TYPE.OTO.STOP_LOSS;
    const price = order.price;

    acc.push(`${side} ${price} ${isStop ? STOP_SORT_TEXT : ''}`);
    return acc;
  }, []);
};

export default useOtoSuborderValue;
