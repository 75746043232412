import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { PersonalData } from '@shared/components';
import TruncatedText from '@shared/components/TruncatedText';

import Link from '@components/Link';

import { isShowSensitiveInfoSelector } from '@store/settings/settingsSelectors';

import styles from './DetailsItem.module.scss';

const DetailsItem = ({
  address,
  txid,
  txidLink,
  isShowPersonalData = true,
}) => {
  const intl = useIntl();

  const isShowSensitiveInfo = useSelector(isShowSensitiveInfoSelector);
  const isShowPersonalDetails = !isShowSensitiveInfo && !isShowPersonalData;

  return (
    <TruncatedText>
      <span className={styles.text}>
        <span className={styles.black}>
          {intl.formatMessage({ id: 'Address' })}:{' '}
          <span className={styles.grey}>
            {isShowPersonalDetails ? <PersonalData data={address} /> : address}
          </span>
        </span>

        {txid ? (
          <>
            <br />

            <span className={styles.black}>
              {intl.formatMessage({ id: 'Txid' })}:{' '}
              {txidLink ? (
                <Link isNewTab to={txidLink} className={styles.grey}>
                  {txid}
                </Link>
              ) : (
                <span className={styles.grey}>
                  {isShowPersonalDetails ? <PersonalData data={txid} /> : txid}
                </span>
              )}
            </span>
          </>
        ) : null}
      </span>
    </TruncatedText>
  );
};

export default memo(DetailsItem);
