import { memo, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useForm, useWatch } from 'react-hook-form';

import {
  CurrenciesSelect,
  TableFilters,
  transformToCurrencyOptions,
} from '@shared/components';
import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';
import {
  ALL_FILTER_KEY,
  ALL_OPTIONS,
  INSTANT_BONUS_STATUS,
} from '@shared/constants';
import { SelectController } from '@shared/ui';

import { useCoinsInfo } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './ExpiredContractsFilters.module.scss';

export const EXPIRED_CONTRACT_FILTER_NAMES = {
  ASSET: 'asset',
  STATUS: 'status',
};

const ExpiredContractsFilters = ({ filters, onFiltersChange }) => {
  const intl = useIntl();

  const { isTabletDown, isDesktop } = useBreakpoints();

  const statuses = [
    {
      value: ALL_FILTER_KEY,
      label: intl.formatMessage({ id: 'All' }),
    },
    {
      value: INSTANT_BONUS_STATUS.EXPIRED,
      label: intl.formatMessage({ id: 'Expired' }),
    },
    {
      value: INSTANT_BONUS_STATUS.PAYED,
      label: intl.formatMessage({ id: 'Payed' }),
    },
  ];

  const { coinsInfoMap } = useCoinsInfo();

  const currencies = useMemo(
    () =>
      transformToCurrencyOptions({
        currencies: [ALL_OPTIONS, ...coinsInfoMap],
        isShowFullName: false,
        isShowCurrencyIcon: isDesktop,
      }),
    [coinsInfoMap, isDesktop],
  );

  const defaultValues = {
    [TABLE_FILTERS_NAMES.SEARCHBAR]: filters[TABLE_FILTERS_NAMES.SEARCHBAR],
    [EXPIRED_CONTRACT_FILTER_NAMES.STATUS]: statuses.find(
      (status) =>
        status.value === filters[EXPIRED_CONTRACT_FILTER_NAMES.STATUS],
    ),
    [EXPIRED_CONTRACT_FILTER_NAMES.ASSET]: currencies.find(
      (currency) =>
        currency.value === filters[EXPIRED_CONTRACT_FILTER_NAMES.ASSET],
    ),
  };

  const methods = useForm({ defaultValues });

  const restFieldsWatch = useWatch({
    control: methods.control,
    name: Object.values(EXPIRED_CONTRACT_FILTER_NAMES),
  });

  const transformValues = (values) => {
    const transformedValues = {
      [TABLE_FILTERS_NAMES.SEARCHBAR]: values[TABLE_FILTERS_NAMES.SEARCHBAR],
    };

    if (values[EXPIRED_CONTRACT_FILTER_NAMES.ASSET]?.value) {
      transformedValues[EXPIRED_CONTRACT_FILTER_NAMES.ASSET] =
        values[EXPIRED_CONTRACT_FILTER_NAMES.ASSET]?.value;
    }

    if (values[EXPIRED_CONTRACT_FILTER_NAMES.STATUS]?.value) {
      transformedValues[EXPIRED_CONTRACT_FILTER_NAMES.STATUS] =
        values[EXPIRED_CONTRACT_FILTER_NAMES.STATUS]?.value;
    }

    return transformedValues;
  };

  useEffect(() => {
    onFiltersChange(transformValues(methods.getValues()));
  }, [restFieldsWatch]);

  const handleFiltersChange = (values) => {
    onFiltersChange(transformValues(values));
  };

  return (
    <div className="mb-20">
      <TableFilters
        searchbar
        clearAllButton
        onFiltersChange={handleFiltersChange}
        autoComplete="off"
        placeholder={`${intl.formatMessage({ id: 'SearchAsset' })}...`}
        customStyles={{ searchbar: styles }}
        {...methods}
      >
        {({ control }) => (
          <div className="d-flex flex-wrap align-items-center justify-content-lg-between gap-20">
            <div className="d-flex align-items-center mx-0 md-me-24 xxl-me-40">
              <span className="me-10 xl-me-16">
                <FormattedMessage id="Asset" />:
              </span>

              <CurrenciesSelect
                currencies={currencies}
                name={EXPIRED_CONTRACT_FILTER_NAMES.ASSET}
                control={control}
                bordered
                size="m"
                styles={{
                  control: { minWidth: isTabletDown ? '90px' : '120px' },
                }}
                {...methods}
              />
            </div>

            <div className="d-flex align-items-center me-0 md-me-24 xxl-me-40">
              <span className="me-10 xl-me-16">
                <FormattedMessage id="Status" />:
              </span>

              <SelectController
                name={EXPIRED_CONTRACT_FILTER_NAMES.STATUS}
                control={control}
                options={statuses}
                bordered
                size="m"
                styles={{
                  control: { minWidth: isTabletDown ? '90px' : '120px' },
                }}
                placeholder={`${intl.formatMessage({ id: 'All' })}...`}
                {...methods}
              />
            </div>
          </div>
        )}
      </TableFilters>
    </div>
  );
};

export default memo(ExpiredContractsFilters);
