import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';
import classnames from 'classnames';

import FavouriteMarketButton from './FavouriteMarketButton';

const columnHelper = createColumnHelper();

const columns = (isMiniX, isStandardX, isGuest, toggleFavouritePair) => {
  const miniTableColumns = ['favourite_info', 'symbol'];
  const standardTableColumns = ['favourite_info', 'symbol', 'price'];

  const allColumns = [
    columnHelper.accessor('favourite_info', {
      header: () => null,
      cell: (info) => {
        const { marketId, isFavourite } = info.getValue();

        const handleStarClick = (e) => {
          e.stopPropagation();

          if (!isGuest) toggleFavouritePair({ pair: marketId });
        };

        return (
          <FavouriteMarketButton
            isGuest={isGuest}
            isActive={isFavourite}
            onClick={handleStarClick}
          />
        );
      },
      enableSorting: false,
      maxSize: 40,
    }),
    columnHelper.accessor('symbol', {
      header: () => <FormattedMessage id="Symbol" />,
      cell: (info) => <span className="fw-600">{info.getValue()}</span>,
      enableSorting: false,
      headerAlign: 'start',
      cellAlign: 'start',
    }),
    columnHelper.accessor('price', {
      header: () => <FormattedMessage id="Price" />,
      cell: (info) => {
        const { className, value } = info.renderValue();

        return <span className={classnames('fw-500', className)}>{value}</span>;
      },
      enableSorting: false,
    }),
    columnHelper.accessor('24h', {
      header: () => <FormattedMessage id="24h" />,
      cell: (info) => {
        const { className, value } = info.renderValue();

        return <span className={classnames('fw-500', className)}>{value}</span>;
      },
      enableSorting: false,
    }),
  ];

  if (isMiniX) {
    return allColumns.filter((col) =>
      miniTableColumns.includes(col.accessorKey),
    );
  }

  if (isStandardX) {
    return allColumns.filter((col) =>
      standardTableColumns.includes(col.accessorKey),
    );
  }

  return allColumns;
};

export default columns;
