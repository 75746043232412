import { createSelector } from '@reduxjs/toolkit';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import { ApiSelectorsUtils } from '@api/utils';

const defaultTotalRevenue = '';
const defaultReferrals = [];
const defaultMeta = { limit: 10, page: 1, count: 0 };

const dataSelector = ApiSelectorsUtils.createDataApiSelector(defaultReferrals);
const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const referralHistorySelector = createSelector(dataSelector, (data) => {
  return data.map((item) => {
    return {
      dataOfInviteLevelA: item.register_date,
      levelAInvited: {
        avatarId: item.avatar_id,
        value: item.email,
      },
      levelBInvited: item.l_2_count === 0 ? null : item.l_2_count,
      levelCInvited: item.l_3_count === 0 ? null : item.l_3_count,
      totalInvited:
        item.l_2_count + item.l_3_count === 0
          ? null
          : item.l_2_count + item.l_3_count,
    };
  });
});

const referralEarningSelector = createSelector(dataSelector, (data) => {
  return data.map((item) => {
    return {
      user: {
        avatarId: item.avatar_id,
        value: item.email,
      },
      levelARevenue:
        item.l_1_earnings === '0' ? null : parseFloat(item.l_1_earnings),
      levelBRevenue:
        item.l_2_earnings === '0' ? null : parseFloat(item.l_2_earnings),
      levelCRevenue:
        item.l_3_earnings === '0' ? null : parseFloat(item.l_3_earnings),
      totalRevenue: {
        value:
          parseFloat(item.l_1_earnings) +
          parseFloat(item.l_2_earnings) +
          parseFloat(item.l_3_earnings),
        coin: CRYPTO_CURRENCIES.PRDX,
      },
      dataAndTime: item.register_date,
      earningInCoin:
        parseFloat(item.l_1_earnings) +
        parseFloat(item.l_2_earnings) +
        parseFloat(item.l_3_earnings),
    };
  });
});

const totalRevenueSelector =
  ApiSelectorsUtils.createDataSelector(defaultTotalRevenue);

export const ReferralApiSelector = {
  referralHistorySelector,
  referralEarningSelector,
  totalRevenueSelector,
  metaSelector,
};
