import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import TruncatedText from '@shared/components/TruncatedText/TruncatedText';
import { ORDER_SIDES } from '@shared/constants';

import { ReactComponent as ArrowGreenSVG } from '@icons/arrow-side-green.svg';
import { ReactComponent as ArrowRedSVG } from '@icons/arrow-side-red.svg';

const OrdersSide = ({
  side,
  isHideSide,
  isSideNameVisible = false,
  size = 18,
  isReversed = false,
}) => {
  if (isHideSide) {
    return null;
  }

  const ArrowComponent =
    side === ORDER_SIDES.SELL ? ArrowRedSVG : ArrowGreenSVG;

  const SideLabel = isSideNameVisible ? (
    <span>
      <FormattedMessage id={side === ORDER_SIDES.SELL ? 'Sell' : 'Buy'} />
    </span>
  ) : null;

  return (
    <TruncatedText>
      {isReversed ? (
        <>
          {SideLabel} <ArrowComponent width={size} height={size} />
        </>
      ) : (
        <>
          <ArrowComponent width={size} height={size} /> {SideLabel}
        </>
      )}
    </TruncatedText>
  );
};

export default memo(OrdersSide);
