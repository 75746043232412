import { FormattedMessage } from 'react-intl';

import { CRYPTO_CURRENCIES } from '@shared/constants';

export const MARKET_TYPES = {
  MAINSTREAM: 'Mainstream',
  NEW_COIN: 'NewCoin',
  TOP_GAINERS: 'TOPGainers',
  TOP_LOSERS: 'TOPLosers',
};

export const ALL_MARKETS_TYPES = [
  MARKET_TYPES.MAINSTREAM,
  MARKET_TYPES.NEW_COIN,
  MARKET_TYPES.TOP_GAINERS,
  MARKET_TYPES.TOP_LOSERS,
];

export const ASSETS_ITEMS_KEY = {
  ALL_CRYPTOS: 'all_cryptos',
  SPOT_MARKETS: 'spot_markets',
  MARGIN_MARKETS: 'margin_markets',
  TRADABLE: 'tradable',
};

export const ASSETS_ITEMS = [
  {
    title: <FormattedMessage id="AllCryptos" />,
    value: ASSETS_ITEMS_KEY.ALL_CRYPTOS,
  },
  {
    title: <FormattedMessage id="SpotMarkets" />,
    value: ASSETS_ITEMS_KEY.SPOT_MARKETS,
  },
  {
    title: <FormattedMessage id="MarginMarkets" />,
    value: ASSETS_ITEMS_KEY.MARGIN_MARKETS,
  },
  {
    title: <FormattedMessage id="Tradable" />,
    value: ASSETS_ITEMS_KEY.TRADABLE,
  },
];

export const COIN_ITEMS = [
  CRYPTO_CURRENCIES.BTC,
  CRYPTO_CURRENCIES.ETH,
  CRYPTO_CURRENCIES.USDT,
  CRYPTO_CURRENCIES.PRDX,
];

export const ALL_CRYPTO_MARKETS_TIME_FRAMES_KEY = {
  ONE_DAY: '1D',
  ONE_WEEK: '1W',
  ONE_MONTH: '1M',
};

export const ALL_CRYPTO_MARKETS_TIME_FRAMES = [
  {
    label: (
      <>
        1 <FormattedMessage id="OneDay" />
      </>
    ),
    value: ALL_CRYPTO_MARKETS_TIME_FRAMES_KEY.ONE_DAY,
  },
  {
    label: (
      <>
        1 <FormattedMessage id="OneWeek" />
      </>
    ),
    value: ALL_CRYPTO_MARKETS_TIME_FRAMES_KEY.ONE_WEEK,
  },
  {
    label: (
      <>
        1 <FormattedMessage id="OneMonth" />
      </>
    ),
    value: ALL_CRYPTO_MARKETS_TIME_FRAMES_KEY.ONE_MONTH,
  },
];
