import { memo, useEffect, useState } from 'react';

import classnames from 'classnames';

import {
  ChartMenuPeriodicity,
  ChartSelect,
  ChartStyles,
} from '@components/CustomChart/components';
import CustomChartHeaderControlsStyles from '@components/CustomChart/components/HeaderControls/HeaderControls.module.scss';

import { getAsset } from '@utils';

import { CHART_STYLES_LIST } from '@constants';

import { ReactComponent as CrosshairIconSVG } from '@images/chartiq/crosshair-icon.svg';

import { ReactComponent as InfoOutlineIconSVG } from '@icons/info-outline-icon.svg';

import styles from './HeaderControls.module.scss';

const HeaderControls = ({
  chartHeight,
  stx,
  config,
  hideMenuPeriodicityItems,
}) => {
  const [chartType, setChartType] = useState();

  useEffect(() => {
    // Runs only at the beginning
    if (!stx?.layout.chartType || !!chartType) return;

    // if aggregationType === 'ohlc' is standard chart type else is advanced
    const currentChartStyle =
      stx?.layout.aggregationType === 'ohlc'
        ? stx?.layout.chartType
        : stx?.layout.aggregationType;

    setChartType(currentChartStyle);
  }, [stx?.layout.chartType]);

  const isActiveChartStyle = (style) => chartType === style.value;

  const handleSelectChartType = (chartType) => {
    setChartType(chartType.value);
  };

  return (
    <div className={CustomChartHeaderControlsStyles.header_controls}>
      <div
        className={classnames(
          CustomChartHeaderControlsStyles.item,
          styles.item,
        )}
      >
        <ChartMenuPeriodicity
          stx={stx}
          minimizeOnBreakpoint="xl"
          config={config}
          chartHeight={chartHeight}
          customStyles={styles}
          hideMenuPeriodicityItems={hideMenuPeriodicityItems}
        />
      </div>

      <div
        className={classnames(
          CustomChartHeaderControlsStyles.item,
          styles.item,
        )}
      >
        <ChartSelect
          dropdownHeight={chartHeight}
          title={getAsset(CHART_STYLES_LIST, chartType)}
        >
          <ChartStyles
            isActiveStyle={isActiveChartStyle}
            handleSelectChartType={handleSelectChartType}
            config={config}
          />
        </ChartSelect>
      </div>

      <div
        className={classnames(
          CustomChartHeaderControlsStyles.item,
          styles.item,
        )}
      >
        <div className={CustomChartHeaderControlsStyles.item__control_icon}>
          <cq-toggle
            class="ciq-CH"
            cq-member="crosshair"
            cq-toggles="show,null"
          >
            <CrosshairIconSVG />
            <cq-tooltip class="top">Crosshair (Alt + \)</cq-tooltip>
          </cq-toggle>
        </div>

        <div className={CustomChartHeaderControlsStyles.item__control_icon}>
          <cq-toggle
            class="ciq-HU"
            cq-member="headsUp"
            cq-toggles="static,null"
          >
            <InfoOutlineIconSVG />
            <cq-tooltip class="top">Info</cq-tooltip>
          </cq-toggle>
        </div>
      </div>
    </div>
  );
};

export default memo(HeaderControls);
