import React, { memo } from 'react';

import cx from 'classnames';

import styles from './LandingPageContainer.module.scss';

const LandingPageContainer = ({
  half, // 'left', 'right'
  children,
  customStyles = {},
}) => {
  return (
    <div
      className={cx(
        styles.landing_page_container,
        customStyles.landing_page_container,
        {
          [styles.half__left]: half === 'left',
          [styles.half__right]: half === 'right',
        },
      )}
    >
      {children}
    </div>
  );
};

export default memo(LandingPageContainer);
