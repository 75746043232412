import React, { memo } from 'react';

import classNames from 'classnames';
import { isFunction } from 'lodash';
import { FreeMode, Mousewheel, Scrollbar } from 'swiper';
import { Swiper } from 'swiper/react';

import { LandingPageContainer } from '@pages/landings/components';

import { NOOP } from '@constants';

import { SwiperCursorContainer } from '../SwiperCursorContainer';

import styles from './CustomSwiper.module.scss';

import 'swiper/css/scrollbar';
import 'swiper/css';

const CustomSwiper = ({
  isShowScrollBar,
  scrollBarInLandingPageContainer,
  isWithSwiperCursor,
  isFreeMode = true,
  modules = [],
  children,
  customStyles = {},
  scrollbar = {},
  ...props
}) => {
  const swiper = (swiperProps = {}, changeIsShowCursorIcon = NOOP) => {
    return (
      <Swiper
        {...swiperProps}
        modules={[
          Mousewheel,
          ...(isFreeMode ? [FreeMode] : []),
          ...(isShowScrollBar ? [Scrollbar] : []),
          ...modules,
        ]}
        scrollbar={{
          el: '.swiper-scrollbar',
          draggable: true,
          horizontalClass: styles.scroll_bar,
          dragClass: styles.drag_element,
          ...scrollbar,
        }}
        freeMode={{ momentumRatio: 0.5 }}
        touchAngle={70}
        mousewheel={{ forceToAxis: true, thresholdDelta: 2 }}
        {...props}
      >
        {isWithSwiperCursor && isFunction(children)
          ? children(changeIsShowCursorIcon)
          : children}
      </Swiper>
    );
  };

  return (
    <div
      className={classNames(styles.custom_swiper, customStyles.custom_swiper)}
    >
      {isShowScrollBar &&
        (scrollBarInLandingPageContainer ? (
          <LandingPageContainer>
            <div className={'swiper-scrollbar'} />
          </LandingPageContainer>
        ) : (
          <div className={'swiper-scrollbar'} />
        ))}

      <div className={classNames(styles.swiper, customStyles.swiper)}>
        {isWithSwiperCursor ? (
          <SwiperCursorContainer customStyles={customStyles}>
            {swiper}
          </SwiperCursorContainer>
        ) : (
          swiper()
        )}
      </div>
    </div>
  );
};

export default memo(CustomSwiper);
