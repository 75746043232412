import { memo, useId } from 'react';

import cx from 'classnames';

import { Button } from '@shared/ui';

import Dropdown from '@components/Dropdown';

import { ReactComponent as ThreeHorizontalDotsIconSVG } from '@icons/three-horizontal-dots.svg';

import s from './MenuActionsButton.module.scss';

/**
 * Example of menuItems:
 * [
 *   {
 *     name: 'item_1',
 *     title: <div>Item 1</div>,
 *     onClick: () => { console.log('1') },
 *   },
 *   ...
 * ]
 * */

const MenuActionsButton = ({
  menuItems,
  customStyles = {},
  variant = 'outlined',
  color = 'primary',
  ...props
}) => {
  const id = useId();

  if (!menuItems.length) {
    return null;
  }

  return (
    <Dropdown
      id={`controls-dropdown-${id}`}
      topOffset={1}
      menuItems={menuItems}
      isWithCorner
      rounded
    >
      {({ triggerRef, handleMenuTrigger }) => (
        <div
          className={cx(
            s.menu_actions,
            s[variant],
            s[color],
            customStyles.menu_actions,
          )}
        >
          <Button
            ref={triggerRef}
            onClick={handleMenuTrigger}
            isCircle
            customStyles={{ ...s, ...customStyles }}
            variant={variant}
            color={color}
            {...props}
          >
            <ThreeHorizontalDotsIconSVG />
          </Button>
        </div>
      )}
    </Dropdown>
  );
};

export default memo(MenuActionsButton);
