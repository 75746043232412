import React, { useLayoutEffect } from 'react';

import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import classNames from 'classnames';

import styles from './PieChart.module.scss';

const defaultTooltipContainerBounds = {
  top: 50,
  right: 100,
  bottom: 50,
  left: 100,
};

function PieChart({
  chartId,
  data,
  colors,
  width,
  height,
  className,
  tooltipContainerBounds = defaultTooltipContainerBounds,
}) {
  useLayoutEffect(() => {
    const root = am5.Root.new(chartId, {
      tooltipContainerBounds,
    });

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(95),
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'percent',
        categoryField: 'label',
        showTooltipOn: 'click',
      }),
    );

    const seriesColors = colors.map((color) => am5.color(color));

    series.get('colors').set('colors', seriesColors);

    series.slices.template.set('toggleKey', 'none');

    series.ticks.template.set('visible', false);

    series.data.setAll(data);

    root._logo.dispose();

    return () => {
      root.dispose();
    };
  }, [chartId, colors, data]);

  return (
    <div
      className={classNames(styles.chart, className)}
      style={{ width, height }}
      id={chartId}
    />
  );
}

export default PieChart;
