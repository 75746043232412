import React from 'react';

import { CustomSelect } from '@shared/components';

import styles from './TabSelector.module.scss';

const TabSelector = (props) => {
  return (
    <div className={styles.container}>
      <CustomSelect
        chevronInversion
        size="m"
        className={styles.select}
        styles={{
          singleValue: {
            fontSize: '13px',
            fontWeight: 500,
          },
          control: { height: '100%', minHeight: 0, lineHeight: '27px' },
          menuPortal: {
            width: 'max-content',
            minWidth: '100%',
          },
          menu: {
            marginTop: '2px',
            width: 'unset',
            fontSize: '13px',
            fontWeight: '400',
          },
          container: { height: '28px' },
          noOptionsMessage: { padding: '0 10px 6px' },
        }}
        {...props}
      />
    </div>
  );
};

TabSelector.role = 'Select';

export default TabSelector;
