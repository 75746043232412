import cx from 'classnames';

import s from './Tab.module.scss';

const Tab = ({
  id,
  children,
  customStyles = {},
  selectedTabIndex,
  onTabClick,
  tabIndex,
  isSelect,
  inversion,
}) => {
  const isActiveTab = selectedTabIndex === tabIndex;

  return (
    <li
      className={cx(s.tab, customStyles.tab, {
        [s.active]: isActiveTab,
        [s.inversion]: inversion,
        [customStyles.tab_active]: !!customStyles.tab_active && isActiveTab,
      })}
      onClick={() => {
        if (!isSelect) {
          onTabClick({ tabIndex, isSelect: false });
        }
      }}
    >
      {typeof children === 'function'
        ? children({
            id,
            selectedTabIndex,
            onTabClick,
            tabIndex,
            isSelect,
          })
        : children}
    </li>
  );
};

export default Tab;
