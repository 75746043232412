import { useIntl } from 'react-intl';

import { createFilter } from 'react-select';

import { FormController } from '@shared/components';
import { Select } from '@shared/ui';

const MarketPairSelect = ({ markets = [], ...props }) => {
  const intl = useIntl();

  const filterConfig = {
    ignoreCase: true,
    trim: true,
    stringify: (opt) => `${opt.value} ${opt.data.fullName}`,
  };

  return (
    <Select
      options={markets}
      placeholder={`${intl.formatMessage({ id: 'SelectPair' })}...`}
      filterOption={createFilter(filterConfig)}
      {...props}
    />
  );
};

export default MarketPairSelect;

export const MarketPairSelectController = (props) => {
  return <FormController component={MarketPairSelect} {...props} />;
};
