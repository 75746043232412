import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import classNames from 'classnames';
import { components } from 'react-select';

import { useDefaultAccount } from '@store/api/hooks';

import styles from './SelectedAccountItem.module.scss';

const { SingleValue } = components;

const SelectedAccountItem = (props) => {
  const intl = useIntl();

  const { defaultSpotAccount } = useDefaultAccount();

  const isDefault = props.data.value === defaultSpotAccount?.id;

  return (
    <SingleValue {...props}>
      <div
        translated-content={`(${intl.formatMessage({ id: 'Default' })})`}
        className={classNames({
          [styles.is_default]: isDefault,
          [styles.inversion]: props.selectProps.inversion,
        })}
      >
        {props.data.label}
      </div>
    </SingleValue>
  );
};

export default memo(SelectedAccountItem);
