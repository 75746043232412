import React, { memo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { Transition } from 'react-transition-group';

import Breakpoint from '@components/Breakpoint';
import Button from '@components/Button/Button';

import useOnClickOutside from '@hooks/useClickOutside';
import useSidebar from '@hooks/useSidebar/useSidebar';

import { sidebarIds } from '@constants';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';
import { ReactComponent as SearchIconSVG } from '@icons/search-icon.svg';

import { SearchMenuContent } from './components/SearchMenuContent';

import styles from './SearchMenu.module.scss';

const SearchMenu = () => {
  const containerRef = useRef();
  const searchButtonRefRef = useRef();

  const { isOpen, openSidebar, closeSidebar, closeAllSidebars } = useSidebar(
    sidebarIds.SEARCH,
  );

  useHotkeys('esc', () => closeSidebar(), {
    enabled: isOpen,
    enableOnTags: ['INPUT'],
  });

  useOnClickOutside(
    [containerRef, searchButtonRefRef],
    closeAllSidebars,
    isOpen,
  );

  const handleSearchButtonClick = () => {
    if (isOpen) {
      closeSidebar();
      return;
    }

    closeAllSidebars();
    openSidebar();
  };

  return (
    <div>
      <Button
        className={classnames(styles.search_button, {
          [styles.menu_open]: isOpen,
        })}
        onClick={handleSearchButtonClick}
        ref={searchButtonRefRef}
      >
        <SearchIconSVG />
      </Button>

      {createPortal(
        <Transition in={isOpen} timeout={200} unmountOnExit>
          {(state) => (
            <div
              className={styles.container}
              style={{
                transition: 'opacity 0.2s ease',
                opacity: state === 'entered' ? 1 : 0,
              }}
              ref={containerRef}
            >
              <Breakpoint size="xl" direction="down">
                <div className={styles.space} onClick={closeSidebar} />
              </Breakpoint>

              <div className={styles.wrapper_content}>
                <Breakpoint size="xl" direction="down">
                  <h2 className={styles.title}>
                    <FormattedMessage id="Search" />
                  </h2>

                  <CrossThinIconSVG
                    className={styles.close_icon}
                    onClick={closeSidebar}
                  />
                </Breakpoint>

                <SearchMenuContent />
              </div>
            </div>
          )}
        </Transition>,
        document.getElementById('modal-container'),
      )}
    </div>
  );
};

export default memo(SearchMenu);
