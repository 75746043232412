import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedPair: {},
  isFullSizeChart: false,
  isSettingsAccepted: false,
  selectedInvestCurrency: null,
  gridInterval: 0,
};

const botStartSlice = createSlice({
  name: 'botStart',
  initialState,
  reducers: {
    setSelectedPair: (state, action) => {
      state.selectedPair = action.payload;
    },
    setIsSettingsAccepted: (state, action) => {
      state.isSettingsAccepted = action.payload;
    },
    setIsFullSizeChart: (state, action) => {
      state.isFullSizeChart = action.payload;
    },
    setGridInterval: (state, action) => {
      state.gridInterval = action.payload;
    },
    setSelectedInvestCurrency: (state, action) => {
      state.selectedInvestCurrency = action.payload;
    },
    setInitialState: () => initialState,
  },
});

const botStartActions = botStartSlice.actions;
const botStartReducer = botStartSlice.reducer;

export { botStartReducer, botStartActions };

export default botStartSlice;
