import { useMemo } from 'react';

import { keyBy } from 'lodash';

import { useGetAccountsQuery, useLazyGetAccountsQuery } from '@api/accountsAPI';
import { AccountsApiSelectors } from '@api/selectors';

const selectFromResult = (result, { accountId } = {}) => {
  const dataResult = {
    isAccountsSuccess: result.isSuccess,
  };

  const spotAccountsInfoMap = AccountsApiSelectors.accountsSelector(result);

  dataResult.spotAccountInfo = accountId
    ? AccountsApiSelectors.spotAccountInfoFromSpotAccountsInfoMapSelector(
        spotAccountsInfoMap,
        accountId,
      )
    : undefined;

  dataResult.spotAccountsInfo = spotAccountsInfoMap.length
    ? keyBy(spotAccountsInfoMap, 'id')
    : undefined;

  dataResult.spotAccountsInfoMap = spotAccountsInfoMap;

  dataResult.spotAccountsLength = spotAccountsInfoMap.length;

  return dataResult;
};

export const useSpotAccounts = ({ accountId } = {}, options) => {
  const result = useGetAccountsQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result, { accountId });
  }, [result, accountId]);

  return data;
};

export const useLazySpotAccounts = (options) => {
  const [trigger, result] = useLazyGetAccountsQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result, result.originalArgs);
  }, [result]);

  return [trigger, data];
};
