import React, { Children, cloneElement } from 'react';

import classnames from 'classnames';

const TabList = ({
  id,
  sm = false,
  lg = false,
  children,
  selectedTabIndex,
  containerClassName,
  className,
  bottomSeparator = true,
  suffix,
  onTabClick,
}) => {
  return (
    <div
      className={classnames('tabs__tab-list-container', containerClassName, {
        'bottom-separator': bottomSeparator,
      })}
    >
      <ul
        className={classnames('tabs__tab-list', className, {
          sm,
          md: !sm && !lg, // by default
          lg,
        })}
      >
        {Children.map(children, (child, tabIndex) =>
          child
            ? cloneElement(child, {
                id,
                selectedTabIndex,
                onTabClick,
                tabIndex,
              })
            : null,
        )}
      </ul>

      {suffix}
    </div>
  );
};

export default TabList;
