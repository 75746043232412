import { useIntl } from 'react-intl';

import { toast } from 'react-toastify';

import { formatObjectKeysFromCamelToSnakeCase } from '@api/utils';
import {
  useActivateCardMutation,
  useAddConsumerAccountMutation,
  useIssuePhysicalCardMutation,
  useJoinToCardWaitListMutation,
  useRequestNewCardMutation,
  useTransferFromAccountToCardMutation,
  useWithdrawFromCardToAccountMutation,
} from '@api/visaAPI';

const useVisaMutations = () => {
  const intl = useIntl();

  const [
    transferFromAccountToCard,
    { isLoading: isTransferFromAccountToCardMutationLoading },
  ] = useTransferFromAccountToCardMutation();
  const [
    withdrawFromCardToAccount,
    { isLoading: isWithdrawFromCardToAccountLoading },
  ] = useWithdrawFromCardToAccountMutation();
  const [joinToCardWaitList, { isLoading: isJoinToCardWaitListLoading }] =
    useJoinToCardWaitListMutation();
  const [
    addConsumerAccount,
    { isLoading: isAddConsumerInSolarisAccountLoading },
  ] = useAddConsumerAccountMutation();
  const [activateCard, { isLoading: isActivateCardLoading }] =
    useActivateCardMutation();
  const [requestNewCard, { isLoading: isRequestNewCardLoading }] =
    useRequestNewCardMutation();
  const [issuePhysicalCard, { isLoading: isIssuePhysicalCardLoading }] =
    useIssuePhysicalCardMutation();

  const onJoinToCardWaitList = async (body) => {
    try {
      await joinToCardWaitList(body).unwrap();

      toast.success(intl.formatMessage({ id: 'JoinToCardWaitListSuccess' }));
    } catch (e) {
      throw Error(e);
    }
  };

  const onTransferFromAccountToCard = async (body) => {
    try {
      await transferFromAccountToCard(body).unwrap();

      toast.success(
        intl.formatMessage({ id: 'TransferFromSubAccountToCardSuccessful' }),
      );
    } catch (e) {
      throw Error(e);
    }
  };

  const onWithdrawFromCardToAccount = async (body) => {
    try {
      await withdrawFromCardToAccount(body).unwrap();

      toast.success(
        intl.formatMessage({ id: 'TransferFromCardToSubAccountSuccessful' }),
      );
    } catch (e) {
      throw Error(e);
    }
  };

  const onAddConsumerInSolarisAccount = async (body) => {
    try {
      const values = formatObjectKeysFromCamelToSnakeCase(body);

      await addConsumerAccount(values).unwrap();

      toast.success(
        intl.formatMessage({ id: 'ConsumerAccountAddedSuccessfully' }),
      );
    } catch (e) {
      throw Error(e);
    }
  };

  const onActivateCard = async (body) => {
    try {
      await activateCard(body).unwrap();

      toast.success(intl.formatMessage({ id: 'CardActivatedSuccessfully' }));
    } catch (e) {
      throw Error(e);
    }
  };

  const onRequestNewCard = async (body) => {
    const values = formatObjectKeysFromCamelToSnakeCase(body);

    try {
      await requestNewCard(values).unwrap();

      toast.success(intl.formatMessage({ id: 'NewCardRequestedSuccessfully' }));
    } catch (e) {
      throw Error(e);
    }
  };

  const onIssuePhysicalCard = async (body) => {
    try {
      await issuePhysicalCard(body).unwrap();

      toast.success(
        intl.formatMessage({ id: 'RequestForCardIssueSentSuccessfully' }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  return {
    onJoinToCardWaitList,
    isJoinToCardWaitListLoading,
    onTransferFromAccountToCard,
    isTransferFromAccountToCardMutationLoading,
    onWithdrawFromCardToAccount,
    isWithdrawFromCardToAccountLoading,
    onAddConsumerInSolarisAccount,
    isAddConsumerInSolarisAccountLoading,
    onActivateCard,
    isActivateCardLoading,
    onRequestNewCard,
    isRequestNewCardLoading,
    onIssuePhysicalCard,
    isIssuePhysicalCardLoading,
  };
};

export default useVisaMutations;
