import { memo } from 'react';

import cx from 'classnames';

import TruncatedText from '@shared/components/TruncatedText';

import Tooltip from '@components/Tooltip';

import styles from './StatusDot.module.scss';

const StatusDot = ({ label, labelPosition, tooltipStatus, color }) => {
  return (
    <div
      className={cx(styles.status_dot, {
        [styles['status_dot--left']]: labelPosition === 'left',
      })}
    >
      {tooltipStatus ? (
        <Tooltip content={tooltipStatus}>
          <div
            className={cx(
              styles.status_dot__dot,
              styles[`status_dot__dot--${color}`],
              'cursor-pointer',
            )}
          />
        </Tooltip>
      ) : (
        <div
          className={cx(
            styles.status_dot__dot,
            styles[`status_dot__dot--${color}`],
          )}
        />
      )}

      {!!label && (
        <TruncatedText>
          <>{label}</>
        </TruncatedText>
      )}
    </div>
  );
};

export default memo(StatusDot);
