import { createApi } from '@reduxjs/toolkit/query/react';
import { random } from 'lodash';

import { TOTAL_AVATARS } from '@shared/components/UserAvatar/userAvatar.constants';

import client from '@api/client';

export const referralAPI = createApi({
  reducerPath: 'referralAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    totalRevenue: builder.query({
      query: () => ({
        method: 'GET',
        url: 'referrals/earnings/total',
      }),
    }),
    referralHistory: builder.query({
      query: ({ limit, page }) => ({
        method: 'GET',
        url: 'referrals/',
        params: {
          page,
          limit,
        },
      }),
      transformResponse: (response) => {
        const data = response.Data.map((item) => ({
          ...item,
          avatar_id: random(1, TOTAL_AVATARS),
        }));

        return { meta: response.Meta, data };
      },
    }),
  }),
});

export const { useTotalRevenueQuery, useReferralHistoryQuery } = referralAPI;
