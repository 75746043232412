export const TOP_UP_METHODS = {
  SPOT_ACCOUNT: 'spot_account',
  OTHERS: 'others',
  SEPA_BANK_TRANSFER: 'sepa_bank_transfer',
  SEPA_INSTANT: 'sepa_instant',
  SWIFT_BANK_TRANSFER: 'swift_bank_transfer',
  ADVCASH_DEPOSIT: 'advcash_deposit',
  CREDIT_DEBIT_CARD: 'credit_debit_card',
  CRYPTO_DEPOSIT: 'crypto_deposit',
};

export const TRANSFER_RECIPIENTS = {
  SPOT_ACCOUNT: 'spot_account',
  USERS: 'users',
};
