import React, { memo, useState } from 'react';

import { useDotsPagination } from '@shared/hooks';

import { NOOP } from '@constants';

import { PaginationContainer, PaginationRange } from './components';

import styles from './Pagination.module.scss';

const Pagination = ({
  meta,
  customStyles = {},
  isMinimizedPagination,
  onPaginationChange = NOOP,
  onPaginationReset = NOOP,
  isShowPaginationArrows = true,
  isShowPageInfo,
}) => {
  const [pageIndex, setPageIndex] = useState(0);

  const pagesShownUpperLimit =
    Math.min(meta.limit * meta.page, meta.count) || 0;

  const totalPageCount = Math.ceil(meta.count / meta.limit) || 1;

  const activePageIndex = Math.max(pageIndex, 0);

  const paginationRange = useDotsPagination({
    currentPage: activePageIndex + 1,
    totalPageCount,
  });

  const handleSetPageIndex = (index) => {
    setPageIndex(index);
    onPaginationChange(index);
  };

  const handleSetFirstPage = () => {
    setPageIndex(0);
    onPaginationReset();
  };

  const handleSetLastPage = () => {
    const lastPage = totalPageCount - 1;

    setPageIndex(lastPage);
    onPaginationChange(lastPage);
  };

  const handleSetPreviousPage = () => {
    const previousPage = pageIndex - 1;

    handleSetPageIndex(previousPage);
    onPaginationChange(previousPage);
  };

  const handleSetNextPage = () => {
    const nextPage = pageIndex + 1;

    handleSetPageIndex(nextPage);
    onPaginationChange(nextPage);
  };

  if (!paginationRange) {
    return null;
  }

  return (
    <PaginationContainer
      meta={meta}
      customStyles={customStyles}
      pagesShownUpperLimit={pagesShownUpperLimit}
      isMinimizedPagination={isMinimizedPagination}
      isShowPageInfo={isShowPageInfo}
    >
      {isShowPaginationArrows && (
        <>
          {!isMinimizedPagination && (
            <button
              disabled={pageIndex === 0}
              className={styles.double_arrow}
              onClick={handleSetFirstPage}
            >
              {'<<'}
            </button>
          )}

          <button
            disabled={pageIndex === 0}
            className={styles.single_arrow}
            onClick={handleSetPreviousPage}
          >
            {'<'}
          </button>
        </>
      )}
      <PaginationRange
        activePageIndex={activePageIndex}
        paginationRange={paginationRange}
        handleSetPageIndex={handleSetPageIndex}
        onPaginationChange={onPaginationChange}
        isMinimizedPagination={isMinimizedPagination}
      />
      {isShowPaginationArrows && (
        <>
          <button
            className={styles.single_arrow}
            disabled={pageIndex + 1 === totalPageCount}
            onClick={handleSetNextPage}
          >
            {'>'}
          </button>

          {!isMinimizedPagination && (
            <button
              className={styles.double_arrow}
              disabled={pageIndex + 1 === totalPageCount}
              onClick={handleSetLastPage}
            >
              {'>>'}
            </button>
          )}
        </>
      )}
    </PaginationContainer>
  );
};

export default memo(Pagination);
