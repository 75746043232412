import React, { memo } from 'react';

import cx from 'classnames';

import { useValueColor } from '@shared/hooks';

import { PersonalData } from '../PersonalData';

import styles from './PersonalPercentChange.module.scss';

const PersonalPercentChange = ({
  percentValue,
  color,
  containerClassName,
  isShowPersonalData,
  isShowPercentSymbol = true,
}) => {
  const { color: defaultColor, isPositive } = useValueColor(percentValue);
  const prefix = isPositive ? '+' : '';

  const percent = `${prefix}${percentValue}${isShowPercentSymbol ? '%' : ''}`;

  return (
    <span
      style={{ color: color || defaultColor }}
      className={cx(
        'personal-percent-change',
        styles.percent_block,
        containerClassName,
      )}
    >
      <PersonalData data={percent} isShowPersonalData={isShowPersonalData} />
    </span>
  );
};

export default memo(PersonalPercentChange);
