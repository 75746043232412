import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { ORDERS_STATUS_COLORS } from '@shared/constants';

import StatusDot from '@components/StatusDot';

const OrdersStatus = ({ status }) => {
  const intl = useIntl();

  const statusItem = ORDERS_STATUS_COLORS[status];

  return (
    status && (
      <StatusDot
        color={statusItem.color}
        label={intl.formatMessage({ id: statusItem.label })}
        labelPosition="left"
      />
    )
  );
};

export default memo(OrdersStatus);
