import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormProvider } from 'react-hook-form';

import { FormActions, FormattedCurrency } from '@shared/components';
import { useCurrencyFormat } from '@shared/hooks';
import { Button, InputController } from '@shared/ui';

import {
  CURRENCY_PRECISION,
  PLACEHOLDER_VALUE,
} from '@pages/WalletsPage/Staking/staking.constants';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './UnstakeForm.module.scss';

const UnstakeForm = ({
  methods,
  handleSubmit,
  navigateToConfirmUnstaking,
  currency,
  setMaxValueHandler,
  amount,
  cancelUnstakingItem,
}) => {
  const intl = useIntl();

  const { isTabletDown } = useBreakpoints();

  const { formattedCurrency } = useCurrencyFormat(currency, PLACEHOLDER_VALUE);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(navigateToConfirmUnstaking)}
        className={styles.form}
      >
        <div>
          <div className={styles.subtitle}>
            <FormattedMessage id="StakeSidebarInfo" />
          </div>

          <div className={styles.amount}>
            <FormattedMessage id="AmountToUnstake" />:
            <div className={styles.balance}>
              <FormattedMessage id="Available" />:{' '}
              <span>
                <FormattedCurrency currency={currency} value={amount} />{' '}
                {currency?.toUpperCase()}
              </span>
            </div>
          </div>

          <InputController
            name="amount"
            onlyNumbers
            precision={CURRENCY_PRECISION}
            autoComplete="off"
            placeholder={formattedCurrency}
            suffix={
              <Button
                variant="outlined"
                color="green"
                size="xxs"
                onClick={setMaxValueHandler}
                customStyles={{ button: [styles.button_max] }}
              >
                <FormattedMessage id="Max" />
              </Button>
            }
            rules={{
              required: intl.formatMessage({ id: 'FieldRequired' }),
              max: {
                value: amount,
                message: intl.formatMessage({
                  id: 'YouHaveExceededMaxAvailableLimit',
                }),
              },
              min: {
                value: Number.MIN_VALUE,
                message: intl.formatMessage({
                  id: 'AmountShouldBeGreaterThanZero',
                }),
              },
            }}
            underlined
            bordered={false}
            inversion
            size="l"
            customStyles={styles}
            {...methods}
          />
        </div>

        <div className={styles.footer}>
          <FormActions
            submitText={<FormattedMessage id="Unstake" />}
            submitButtonProps={{
              size: isTabletDown ? 'm' : 'l',
              fill: true,
              color: 'dangerous',
              inversion: true,
            }}
            cancelButtonProps={{
              size: isTabletDown ? 'm' : 'l',
              fill: true,
              inversion: true,
            }}
            onCancel={cancelUnstakingItem}
            customStyles={styles}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default memo(UnstakeForm);
