import { useDispatch } from 'react-redux';

import { useBonusAccountSettings } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { availableContractsActions } from '../slices';

const useSelectPeriodRange = ({ coinSymbol }) => {
  const dispatch = useDispatch();

  const { isTabletDown } = useBreakpoints();

  const { periods } = useBonusAccountSettings();

  const selectPeriodRange = (selectedPeriod) => {
    const dispatchSelectedPeriod = (coinSymbol) => {
      dispatch(
        availableContractsActions.setSelectedPeriods({
          coinSymbol,
          period: selectedPeriod,
        }),
      );
    };

    isTabletDown
      ? dispatch(availableContractsActions.setSelectedPeriod(selectedPeriod))
      : dispatchSelectedPeriod(coinSymbol);
  };

  return {
    selectPeriodRange,
    periods,
  };
};

export default useSelectPeriodRange;
