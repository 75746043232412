import dateFormat from 'date-fns/format';

import { YEAR_MONTH_DAY_TIME_FORMAT } from '@shared/constants';

export const downloadCsv = (csvString, filename = 'filename', stx = null) => {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
  a.download = `${filename}.csv`;
  a.style.display = 'none';
  const doc = ((stx || {}).container || {}).ownerDocument || document;
  doc.body.appendChild(a);
  a.click();
  doc.body.removeChild(a);
};

export const getFilenameFormatter = (symbol, tableData = []) => {
  if (tableData.length === 0) return symbol;

  const firstDate = chartTableDateFormat(tableData[0].DT);
  const lastDate = chartTableDateFormat(tableData[tableData.length - 1].DT);

  return `${symbol} (${firstDate}_${lastDate})`;
};

export const chartTableDateFormat = (date) =>
  dateFormat(date, YEAR_MONTH_DAY_TIME_FORMAT);
