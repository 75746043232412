import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ItemTitle } from '@shared/components';
import { CRYPTO_CURRENCIES } from '@shared/constants';

import {
  useChartData,
  useReferralsTotalEarned,
} from '@pages/AccountPage/hooks';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { OverviewEarnWidget } from '../OverviewEarnWidget';

import { ReferralsTables } from './components/ReferralsTables';

import { REFERRAL_ROUTES } from 'src/routes.constants';

import mockData from 'src/mock.json';

const Referrals = () => {
  const { chartValue, coin, queryParams } = mockData.Referrals;

  const { chartData, color, value } = useChartData(chartValue);
  const { totalEarnedInPRDX } = useReferralsTotalEarned(queryParams, coin);

  const { symbol, value: selectedUserCurrency } = useSelector(currencySelector);

  const { ratesByPair: prdxToBtcRate } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.PRDX,
    toCoinSymbol: CRYPTO_CURRENCIES.BTC,
  });

  const { ratesByPair: prdxToSelectedCurrencyRate } = useCoinsRates({
    fromCoinSymbol: CRYPTO_CURRENCIES.PRDX,
    toCoinSymbol: selectedUserCurrency,
  });

  const totalEarnedInBTC = totalEarnedInPRDX * prdxToBtcRate;
  const totalEarnedInSelectedCurrency =
    totalEarnedInPRDX * prdxToSelectedCurrencyRate;

  return (
    <>
      <ItemTitle
        title={<FormattedMessage id="Referrals" />}
        arrowTitle={<FormattedMessage id="Details" />}
        navigation={{ to: REFERRAL_ROUTES.OVERVIEW }}
      />

      <OverviewEarnWidget
        percentChange={value}
        color={color}
        title={<FormattedMessage id="TotalReferralProfit" />}
        chartData={chartData}
        value={totalEarnedInBTC}
        valueCurrency={coin}
        totalValue={totalEarnedInSelectedCurrency}
        totalValueCurrency={selectedUserCurrency}
        totalValueSymbol={symbol}
      />

      <ReferralsTables />
    </>
  );
};

export default memo(Referrals);
