import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { addWeeks } from 'date-fns';
import Cookies from 'js-cookie';

import { AccountModal } from '@shared/components';
import { Button, ButtonArrow } from '@shared/ui';

import { SecurityLevelProgressBar } from '@pages/AccountPage/components';
import { useSecurityLevelColor } from '@pages/AccountPage/hooks';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal/useModal';

import { COOKIE_SKIP_SECURITY_LEVEL_CHECK, modalsIds } from '@constants';

import { ReactComponent as GoogleAuthIconSVG } from '@icons/google-auth-icon.svg';
import { ReactComponent as SMSAuthIconSVG } from '@icons/sms-auth-icon.svg';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

import styles from './SecurityLevelModal.module.scss';

const SecurityLevelModal = () => {
  const { isOpen, closeModal } = useModal(modalsIds.SECURITY_LEVEL_MODAL);

  const { isMobile } = useBreakpoints();

  const { color, colorName } = useSecurityLevelColor(undefined, {
    skip: !isOpen,
  });

  const handleSkipForNow = () => {
    Cookies.set(COOKIE_SKIP_SECURITY_LEVEL_CHECK, true, {
      expires: addWeeks(new Date(), 1),
    });

    closeModal();
  };

  return (
    <AccountModal
      title={<FormattedMessage id="HeyThere" />}
      onClose={closeModal}
      isOpen={isOpen}
      footer={
        <div className="d-flex flex-column align-items-center fw-500">
          <div className="mb-16">
            <FormattedMessage id="IUnderstandTheRisks" />
          </div>

          <Button
            customStyles={styles}
            type="button"
            variant="outlined"
            onClick={handleSkipForNow}
          >
            <FormattedMessage id="SkipForNow" />
          </Button>
        </div>
      }
    >
      <div
        className={classNames(
          'd-flex justify-content-between mb-16 px-16 py-14',
          styles.security_level_block,
        )}
      >
        <div className={classNames('fw-600 fs-16', styles.security_level)}>
          <FormattedMessage id="YourSecurityLevel" />:
        </div>

        <div className="d-flex align-items-center gap-20">
          <span className="fw-600" style={{ color }}>
            <FormattedMessage id={colorName} />
          </span>

          <div className={styles.progress_bar}>
            <SecurityLevelProgressBar />
          </div>
        </div>
      </div>

      <div className={classNames('fw-600 fs-16 mb-26', styles.description)}>
        <span>
          <FormattedMessage id="YourSecurityLevelDescription" />:
        </span>
      </div>

      <div
        className={classNames(
          'd-flex flex-column gap-22 mb-36',
          styles.security_buttons,
        )}
      >
        <ButtonArrow
          fill
          isShowArrowIcon={!isMobile}
          navigation={{ to: ACCOUNT_ROUTES.GOOGLE_AUTHENTICATION }}
          customStyles={styles}
          size="l"
          onClick={closeModal}
          color="primary"
          variant="contained"
        >
          <div className="d-flex align-items-center gap-10">
            <GoogleAuthIconSVG className={styles.button_icon} />
            <FormattedMessage id="GoogleAuthentication" />
          </div>
        </ButtonArrow>

        <ButtonArrow
          fill
          isShowArrowIcon={!isMobile}
          navigation={{ to: ACCOUNT_ROUTES.SMS_AUTHENTICATION }}
          size="l"
          onClick={closeModal}
          customStyles={styles}
          variant="contained"
          color="primary"
        >
          <div className="d-flex align-items-center gap-10">
            <SMSAuthIconSVG className={styles.button_icon} />
            <FormattedMessage id="SMSAuthentication" />
          </div>
        </ButtonArrow>
      </div>
    </AccountModal>
  );
};

export default memo(SecurityLevelModal);
