import React, { memo } from 'react';

import cx from 'classnames';

import styles from './LandingPageSubtitle.module.scss';

const LandingPageSubtitle = ({ className, children }) => {
  return <h2 className={cx(styles.subtitle, className)}>{children}</h2>;
};

export default memo(LandingPageSubtitle);
