import { ReactComponent as ButterFlySVG } from '@images/visa/butterfly-lines.svg';
import { ReactComponent as CrazyMonkeySVG } from '@images/visa/crazy-monkey.svg';
import { ReactComponent as MonkeySVG } from '@images/visa/monkey-lines.svg';
import { ReactComponent as RhinoSVG } from '@images/visa/rhino-lines.svg';
import { ReactComponent as TitanRhinoSVG } from '@images/visa/titan-rhino.svg';
import { ReactComponent as VelvetZebraSVG } from '@images/visa/velvet-zebra.svg';
import { ReactComponent as VibrantButterFlySVG } from '@images/visa/vibrant-butterfly.svg';
import { ReactComponent as ZebraSVG } from '@images/visa/zebra-lines.svg';

export const CARD_TYPE = {
  VIBRANT_BUTTERFLY: 'vibrant-butterfly',
  VELVET_ZEBRA: 'velvet-zebra',
  CRAZY_MONKEY: 'crazy-monkey',
  TITAN_RHINO: 'titan-rhino',
};

export const DIRECT_BASIC_PERCENT = 0.1;
export const DIRECT_ELITE_PERCENT = 0.2;

export const SECOND_LEVEL_BASIC_PERCENT = 0.2;
export const SECOND_LEVEL_ELITE_PERCENT = 0.4;

export const CARDS_SHIPMENT = {};

const BASIC_CARDS_BENEFITS = [
  { name: 'MinimumAmountPrdxLocked', value: '10000' },
  {
    name: 'TimeLimit',
    value: {
      intlId: 'NumMonths',
      intlValue: 6,
    },
  },
  {
    name: 'CardReferralProgram',
    data: [
      { name: 'LevelN', nameValue: '1', value: '0.10%' },
      { name: 'LevelN', nameValue: '2', value: '0.20%' },
    ],
  },
];

const BASIC_CARD_FEES_LIMITS = [
  { name: 'PhysicalCardsShipment', value: '12.95', currencySymbol: '€' },
  { name: 'MonthlyFee', value: '0.00', currencySymbol: '€' },
  { name: 'TransactionFee', value: '0.00%' },
  { name: 'AtmCommission', value: '3.00', currencySymbol: '€' },
  { name: 'DailyAtmWithdrawal', value: '200.00', currencySymbol: '€' },
  { name: 'DailySpendingLimit', value: '1,500.00', currencySymbol: '€' },
];

export const CARD_TYPE_MAP = {
  [CARD_TYPE.VIBRANT_BUTTERFLY]: {
    level: 1,
    imgSrc: 'vibrant-butterfly',
    icon: VibrantButterFlySVG,
    animalIcon: ButterFlySVG,
    intl: 'VibrantButterfly',
    directPercent: DIRECT_BASIC_PERCENT,
    secondLevelPercent: SECOND_LEVEL_BASIC_PERCENT,
    benefits: BASIC_CARDS_BENEFITS,
    feesLimits: BASIC_CARD_FEES_LIMITS,
  },
  [CARD_TYPE.VELVET_ZEBRA]: {
    level: 2,
    imgSrc: 'velvet-zebra',
    icon: VelvetZebraSVG,
    animalIcon: ZebraSVG,
    intl: 'VelvetZebra',
    directPercent: DIRECT_BASIC_PERCENT,
    secondLevelPercent: SECOND_LEVEL_BASIC_PERCENT,
    benefits: BASIC_CARDS_BENEFITS,
    feesLimits: BASIC_CARD_FEES_LIMITS,
  },
  [CARD_TYPE.CRAZY_MONKEY]: {
    level: 3,
    imgSrc: 'crazy-monkey',
    icon: CrazyMonkeySVG,
    animalIcon: MonkeySVG,
    intl: 'CrazyMonkey',
    directPercent: DIRECT_BASIC_PERCENT,
    secondLevelPercent: SECOND_LEVEL_BASIC_PERCENT,
    benefits: BASIC_CARDS_BENEFITS,
    feesLimits: BASIC_CARD_FEES_LIMITS,
  },
  [CARD_TYPE.TITAN_RHINO]: {
    level: 4,
    imgSrc: 'titan-rhino',
    icon: TitanRhinoSVG,
    animalIcon: RhinoSVG,
    intl: 'TitanRhino',
    directPercent: DIRECT_BASIC_PERCENT,
    secondLevelPercent: SECOND_LEVEL_BASIC_PERCENT,
    benefits: BASIC_CARDS_BENEFITS,
    feesLimits: BASIC_CARD_FEES_LIMITS,
  },
};

export const BASIC_CARDS_TYPES = [
  CARD_TYPE.VIBRANT_BUTTERFLY,
  CARD_TYPE.CRAZY_MONKEY,
  CARD_TYPE.VELVET_ZEBRA,
  CARD_TYPE.TITAN_RHINO,
];

// these codes come from the server. By adding to this object new existing codes the quantity of displayed card will be increase
export const VISA_CARD_CODES = {
  [CARD_TYPE.VIBRANT_BUTTERFLY]: 'BUTTEUCL',
  [CARD_TYPE.CRAZY_MONKEY]: 'MONKEUCL',
  [CARD_TYPE.VELVET_ZEBRA]: 'ZEBREUCL',
  [CARD_TYPE.TITAN_RHINO]: 'RHINEUCL',
};
