import { useDispatch, useSelector } from 'react-redux';

import cx from 'classnames';

import { selectedSettingIdSelector } from '@pages/WalletsPage/VisaCardSettings/VisaCardSettings.selectors';
import { visaCardSettingsActions } from '@pages/WalletsPage/VisaCardSettings/VisaCardSettings.slice';

import { ReactComponent as RightArrowIconSVG } from '@icons/arrow-right-button.svg';

import s from './MenuItemList.module.scss';

const MenuItemList = ({ items = [] }) => {
  const selectedSettingId = useSelector(selectedSettingIdSelector);

  const dispatch = useDispatch();

  const handleSelectSetting = (id) => {
    dispatch(visaCardSettingsActions.setSelectedSettingId(id));
  };

  return (
    <ul className={s.menu_list}>
      {items.map((item, index) => (
        <li
          className={cx(s.menu_item, {
            [s.active]: selectedSettingId === item.id,
          })}
          key={`settings-menu-item-${index}`}
          onClick={() => handleSelectSetting(item.id)}
        >
          <div className="d-flex align-items-center gap-16">
            <div className={s.icon}>{item.icon}</div>
            <div className={s.title}>{item.title}</div>
          </div>
          <div className="d-flex align-items-center gap-20">
            {item?.additionalInfo && <div>{item.additionalInfo}</div>}
            <div className={s.active_icon}>
              <RightArrowIconSVG />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default MenuItemList;
