import { useMemo } from 'react';

import { useGetFeesInfoQueryResult } from '@store/api/hooks';
import { useGetProfileInfoQuery } from '@store/api/profileAPI';
import { useGetUserWithdrawLimitQuery } from '@store/api/walletsAPI';

const useIsShowAccountHeader = () => {
  const { isSuccess: isSuccessProfileInfo } = useGetProfileInfoQuery();
  const { isSuccess: isSuccessFeesInfo } = useGetFeesInfoQueryResult();
  const { isSuccess: isSuccessUserWithdrawLimit } =
    useGetUserWithdrawLimitQuery();

  const isShowAccountHeader = useMemo(
    () =>
      [
        isSuccessProfileInfo,
        isSuccessFeesInfo,
        isSuccessUserWithdrawLimit,
      ].every(Boolean),
    [isSuccessFeesInfo, isSuccessProfileInfo, isSuccessUserWithdrawLimit],
  );

  return isShowAccountHeader;
};

export default useIsShowAccountHeader;
