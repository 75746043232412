import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ALL_FILTER_KEY } from '@shared/constants';

import {
  isGuestUserSelector,
  marketIdSelector,
} from '@store/settings/settingsSelectors';

import { useMarketsInfo } from '@api/hooks';
import {
  useGetOrderHistoryQuery,
  useLazyGetOrderHistoryQuery,
} from '@api/ordersAPI';
import { OrdersApiSelectors } from '@api/selectors';

const selectFromResult = (result, { withTradesByOrdersId = false }) => {
  const dataResult = {
    orders: OrdersApiSelectors.orderHistorySelector(result),
    trades: OrdersApiSelectors.orderTradesSelector(result),
    meta: OrdersApiSelectors.metaSelector(result),
    isOrderHistorySuccess: result.isSuccess,
  };

  if (withTradesByOrdersId) {
    dataResult.tradesByOrderIds =
      OrdersApiSelectors.tradesByOrderIdsSelector(result);
  }

  return dataResult;
};

export const useOrderHistory = (
  {
    accountId,
    page,
    limit,
    fromDate,
    toDate,
    status,
    sideParam,
    pairFrom,
    pairTo,
    isShowAll = true,
    withTradesByOrdersId = false,
  } = {},
  options = {},
) => {
  const marketId = useSelector(marketIdSelector);
  const isGuest = useSelector(isGuestUserSelector);

  const { marketInfo } = useMarketsInfo({ marketId });

  const result = useGetOrderHistoryQuery(
    {
      pairFrom:
        pairFrom ??
        (isShowAll ? ALL_FILTER_KEY : marketInfo?.market_coin_symbol),
      pairTo:
        pairTo ?? (isShowAll ? ALL_FILTER_KEY : marketInfo?.quote_coin_symbol),
      fromDate,
      toDate,
      limit,
      page,
      sideParam,
      status,
      accountId,
    },
    {
      pollingInterval: 3000,
      ...options,
      skip: isGuest || !accountId || options.skip,
    },
  );

  const data = useMemo(() => {
    return selectFromResult(result, { withTradesByOrdersId });
  }, [result, withTradesByOrdersId]);

  return data;
};

export const useLazyOrderHistory = (options) => {
  const [trigger, result] = useLazyGetOrderHistoryQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result, result.originalArgs);
  }, [result]);

  return [trigger, data];
};
