import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useForm } from 'react-hook-form';

import { ButtonGroup } from '@shared/components';
import {
  PIN_CODE_NUMBER_LENGTH,
  SMS_OTP_NUMBER_LENGTH,
} from '@shared/constants';
import { Button, OTPInputController } from '@shared/ui';

import {
  SettingTitle,
  SettingDescription,
} from '@pages/WalletsPage/VisaCardSettings/components';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as GoogleIconSVG } from '@icons/google-auth-icon.svg';
import { ReactComponent as SMSIconSVG } from '@icons/sms-auth-icon.svg';

import s from './ChangePin.module.scss';

const CHANGE_PIN_METHODS = {
  GOOGLE: 'google',
  SMS: 'sms',
};

const CHANGE_PIN_FIELDS = {
  SMS_CODE: 'sms_code',
  NEW_PIN: 'new_pin',
};

const ChangePin = () => {
  const intl = useIntl();
  const { isMobile } = useBreakpoints();

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      [CHANGE_PIN_FIELDS.SMS_CODE]: '',
      [CHANGE_PIN_FIELDS.NEW_PIN]: '',
    },
  });

  const userPhone = '+395079523355';

  const [selectedMethodId, setSelectedMethodId] = useState(
    CHANGE_PIN_METHODS.SMS,
  );

  const truncatedUserPhone = useMemo(() => {
    const firstDisplayedCharsQty = 3;
    const lastDisplayedCharsQty = 2;
    const asteriskQuantity =
      userPhone.length - (firstDisplayedCharsQty + lastDisplayedCharsQty);

    return `${userPhone.slice(0, firstDisplayedCharsQty)}${'*'.repeat(
      asteriskQuantity,
    )}${userPhone.slice(-lastDisplayedCharsQty)}`;
  }, [userPhone]);

  const items = [
    {
      id: CHANGE_PIN_METHODS.GOOGLE,
      value: (
        <div className="d-flex align-items-center gap-10">
          <GoogleIconSVG className={s.google_icon} />
          <span>
            <FormattedMessage id="Google" />
          </span>
        </div>
      ),
    },
    {
      id: CHANGE_PIN_METHODS.SMS,
      value: (
        <div className="d-flex align-items-center gap-10">
          <SMSIconSVG className={s.sms_icon} />
          <span>
            <FormattedMessage id="SMS" />
          </span>
        </div>
      ),
    },
  ];

  const handleSelectItem = ({ id }) => {
    setSelectedMethodId(id);
  };

  const handleResendCode = () => {
    // TODO: add resend endpoint
  };

  const handleChangePin = (values) => {
    // TODO: add resend endpoint
    console.log(values);
  };

  return (
    <div>
      <SettingTitle>
        <FormattedMessage id="ChangeCardPin" />
      </SettingTitle>

      <div className="my-xl-22 my-16">
        <SettingDescription>
          <FormattedMessage id="ChangeCardPinDescription" />
        </SettingDescription>
      </div>
      <ButtonGroup
        items={items}
        onClick={handleSelectItem}
        selectedItemId={selectedMethodId}
        color="quaternary"
        variant="contained"
        size={isMobile ? 's' : 'm'}
      />

      <div className={s.content}>
        {selectedMethodId === CHANGE_PIN_METHODS.SMS ? (
          <>
            <div className="mt-16">
              <SettingDescription>
                <FormattedMessage
                  id="ChangeCardPinSMSDescription"
                  values={{ phone: truncatedUserPhone }}
                />
              </SettingDescription>
            </div>
            <div className="mt-20">
              <Button variant="outlined" fill onClick={handleResendCode}>
                <FormattedMessage id="ResendCode" />
              </Button>
            </div>

            <form onSubmit={handleSubmit(handleChangePin)} className="mt-22">
              <div className={s.sms_field_wrapper}>
                <OTPInputController
                  numInputs={SMS_OTP_NUMBER_LENGTH}
                  name={CHANGE_PIN_FIELDS.SMS_CODE}
                  label={`${intl.formatMessage({ id: 'EnterCodeFromSMS' })}:`}
                  bordered
                  rounded
                  size={isMobile ? 'l' : 'xl'}
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                    minLength: {
                      value: SMS_OTP_NUMBER_LENGTH,
                      message: intl.formatMessage({ id: 'FieldRequired' }),
                    },
                  }}
                  {...methods}
                />
              </div>
              <div className={s.new_pin_wrapper}>
                <div className={s.sms_field_wrapper}>
                  <OTPInputController
                    numInputs={PIN_CODE_NUMBER_LENGTH}
                    inputType="password"
                    name={CHANGE_PIN_FIELDS.NEW_PIN}
                    label={`${intl.formatMessage({ id: 'NewPin' })}:`}
                    bordered={false}
                    underlined
                    size={isMobile ? 'l' : 'xl'}
                    rules={{
                      required: intl.formatMessage({ id: 'FieldRequired' }),
                      minLength: {
                        value: PIN_CODE_NUMBER_LENGTH,
                        message: intl.formatMessage({ id: 'FieldRequired' }),
                      },
                    }}
                    {...methods}
                  />
                </div>
              </div>
              <div className="mt-40 mb-20">
                <Button fill size="l" disabled={!methods.formState.isValid}>
                  <FormattedMessage id="ApplyPin" />
                </Button>
              </div>
            </form>
          </>
        ) : (
          <div>GOOGLE</div>
        )}
      </div>
    </div>
  );
};

export default ChangePin;
