import { forwardRef } from 'react';

import cx from 'classnames';

import s from '@shared/components/DatePicker/components/DatePickerField/DatePickerField.module.scss';
import { Input } from '@shared/ui';

import { ReactComponent as CalendarIconSVG } from '@icons/calendar-icon.svg';

const DatePickerField = forwardRef(({ onOpen, value, ...props }, ref) => {
  return (
    <Input
      ref={ref}
      readOnly
      value={value}
      onClick={onOpen}
      suffix={
        <div
          className={cx(s.calendar_icon, {
            [s.calendar_icon__inversion]: props.inversion,
          })}
          onClick={onOpen}
        >
          <CalendarIconSVG />
        </div>
      }
      {...props}
    />
  );
});

export default DatePickerField;
