import getUnixTime from 'date-fns/getUnixTime';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';

import {
  DEFAULT_SHOW_SENSITIVE_INFO,
  LOCAL_STORAGE_AUTH_TOKEN,
  LOCAL_STORAGE_SENSITIVE_INFO,
  SERVER_MESSAGE_TYPE,
} from '@constants';

import { format } from './numbers';
import { getIntlMessage, getServerMessageId } from './translations';

export const getUrlParams = (location) => {
  if (location.search) {
    let result = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((param) => {
        const [firstPart, secondPart] = param.split('=');
        result[firstPart] = secondPart;
      });

    return result;
  }
  return {};
};

export function truncateEmail(email) {
  return email.replace(
    /(\+?.{3})(.+)(\+?.{3})/g,
    (match, start, middle, end) => `${start}****${end}`,
  );
}

export function truncatePhoneNumber(phoneNumber) {
  return phoneNumber.replace(
    /(\+?.{2})(.+)(\+?.{2})/g,
    (match, start, middle, end) => `${start}********${end}`,
  );
}

export function getTransactionVolumeUSD24h(
  marketsInfo,
  marketStatistics,
  coinRates,
) {
  if (isEmpty(marketsInfo) || !marketStatistics || !coinRates) {
    return 0;
  }

  return Object.values(marketStatistics).reduce((sum, marketStatisticItem) => {
    const marketInfo = marketsInfo.find(
      (market) => market.id === marketStatisticItem.m,
    );
    const quoteCoinSymbol = marketInfo.quote_coin_symbol.toUpperCase();
    const volume = marketStatisticItem.qv * coinRates[quoteCoinSymbol].USD;

    return sum + volume;
  }, 0);
}

export const getIsTokenPartial = (decodedToken) => {
  return !decodedToken.sub && decodedToken.key && decodedToken.key !== '';
};

export const getIsShowSensitiveInfo = () => {
  const isSensitiveInfo = localStorage.getItem(LOCAL_STORAGE_SENSITIVE_INFO);

  if (isSensitiveInfo === 'true') {
    return true;
  }

  if (isSensitiveInfo === 'false') {
    return false;
  }

  return DEFAULT_SHOW_SENSITIVE_INFO;
};

export const getIsAuthorizedUser = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

  if (token) {
    const decodedToken = jwtDecode(token);

    return !getIsTokenPartial(decodedToken);
  }

  return false;
};

export const getUserId = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

  if (token) {
    const decodedToken = jwtDecode(token);

    return Number(decodedToken.sub);
  }

  return undefined;
};

export const getUserEmail = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

  if (token) {
    const decodedToken = jwtDecode(token);

    return decodedToken.email;
  }

  return undefined;
};

export const getUserRole = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

  if (token) {
    const decodedToken = jwtDecode(token);

    return decodedToken.role;
  }

  return undefined;
};

export const getErrorText = (error = {}) => {
  let text = '';

  if (typeof error === 'string') {
    text = error;
  }

  if (error.message) {
    text = error.message;
  }

  if (error.data || (error.response && error.response.data)) {
    const errorData = error.data || error.response.data;

    if (typeof errorData === 'string') {
      text = errorData;
    }

    if (errorData.error) {
      text = errorData.error;
    }
  }

  const messageId = getServerMessageId(text, SERVER_MESSAGE_TYPE.ERRORS);

  return getIntlMessage(messageId);
};

export const groupMarketDepthData = (data, precision) => {
  if (!data) {
    return [];
  }

  return data.reduce((arr, curr) => {
    const price = precision
      ? format(curr[0], {
          precision,
          noCommas: true,
        })
      : curr[0];
    const amount = curr[1];

    const samePriceIndex = arr.findIndex((item) => item[0] === price);

    if (samePriceIndex < 0) {
      arr.push([price, amount]);
    } else {
      const totalAmount = `${
        parseFloat(arr[samePriceIndex][1]) + parseFloat(amount)
      }`;

      arr[samePriceIndex] = [price, totalAmount];
    }

    return arr;
  }, []);
};

export const groupMarketDepthDataOld = (data, type) => {
  return data.reduce((arr, curr) => {
    const amountField = [type + 'volume'];
    const price = curr.value;
    const amount = curr[amountField];

    const samePriceIndex = arr.findIndex((item) => item.value === price);

    if (samePriceIndex < 0) {
      arr.push({ ...curr, value: price, [amountField]: amount });
    } else {
      const totalAmount =
        parseFloat(arr[samePriceIndex][amountField]) + parseFloat(amount);

      arr[samePriceIndex] = {
        ...curr,
        value: price,
        [amountField]: totalAmount,
      };
    }

    return arr;
  }, []);
};

// Function to process (sort and calculate cummulative volume)
export const normalizeMarketDepthData = (data, type, desc) => {
  const processedMarketDepthData = [];

  // Convert to data points
  const mappedData = data.map((item) => ({
    value: Number(item[0]),
    volume: Number(item[1]),
  }));

  // Sort data just in case
  mappedData.sort((a, b) => a.value - b.value);

  // Calculate cummulative volume
  if (desc) {
    for (let i = mappedData.length - 1; i >= 0; i--) {
      if (i < mappedData.length - 1) {
        mappedData[i].totalvolume =
          mappedData[i + 1].totalvolume + mappedData[i].volume;
      } else {
        mappedData[i].totalvolume = mappedData[i].volume;
      }

      const dp = {};

      dp['value'] = mappedData[i].value;
      dp[type + 'volume'] = mappedData[i].volume;
      dp[type + 'totalvolume'] = mappedData[i].totalvolume;
      dp['type'] = type;

      processedMarketDepthData.unshift(dp);
    }
  } else {
    for (let i = 0; i < mappedData.length; i++) {
      if (i > 0) {
        mappedData[i].totalvolume =
          mappedData[i - 1].totalvolume + mappedData[i].volume;
      } else {
        mappedData[i].totalvolume = mappedData[i].volume;
      }

      const dp = {};

      dp['value'] = mappedData[i].value;
      dp[type + 'volume'] = mappedData[i].volume;
      dp[type + 'totalvolume'] = mappedData[i].totalvolume;
      dp['type'] = type;

      processedMarketDepthData.push(dp);
    }
  }

  return processedMarketDepthData;
};

export const get24hAgoInSec = () => getUnixTime(new Date()) - 24 * 60 * 60;

export const getAsset = (
  list,
  itemValue,
  itemAccessor = 'value',
  iconAccessor = 'icon',
) => {
  const assetObject =
    list.find((item) => item[itemAccessor] === itemValue) ??
    list.find((item) => item[itemAccessor] === 'default');

  return assetObject?.[iconAccessor];
};

export const isCoinExistsInPair = (pair = '', coin = '') => {
  if (pair.length < coin.length) return false;

  const lowerCasePair = pair.toLowerCase();
  const lowerCaseCoin = coin.toLowerCase();

  const firstCoin = lowerCasePair.substring(0, lowerCaseCoin.length);
  const secondCoin = lowerCasePair.substring(
    lowerCasePair.length - lowerCaseCoin.length,
  );

  return firstCoin === lowerCaseCoin || secondCoin === lowerCaseCoin;
};

export function flat(obj, concatenator = '.') {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] !== 'object' || !obj[key]) {
      return {
        ...acc,
        [key]: obj[key],
      };
    }

    const flattenedChild = flat(obj[key], concatenator);

    return {
      ...acc,
      ...Object.keys(flattenedChild).reduce(
        (childAcc, childKey) => ({
          ...childAcc,
          [`${key}${concatenator}${childKey}`]: flattenedChild[childKey],
        }),
        {},
      ),
    };
  }, {});
}
