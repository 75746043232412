import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';

import { modalsIds } from '@constants';

import useModalManager from './useModalManager';

const ModalsContext = React.createContext();

const { Provider } = ModalsContext;

const ModalsProvider = ({ children }) => {
  const { modals, toggle, open, close, reset, register, changeParams } =
    useModalManager();

  useEffect(() => {
    Object.values(modalsIds).forEach((modal) => register(modal));
  }, []);

  if (isEmpty(modals)) return null;

  return (
    <Provider
      value={{
        modals,
        toggle,
        open,
        close,
        reset,
        changeParams,
      }}
    >
      {children}
    </Provider>
  );
};

export { ModalsContext };

export default ModalsProvider;
