import { ACCOUNT_SECURITY_SETTINGS } from '@shared/constants/account.constants';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

export const MINIMAL_VISIBLE_PROGRESS_BAR_LENGTH = 15;
export const PROFILE_NICKNAME_MAX_LENGTH = 20;

export const SECURITY_SETTINGS = [
  {
    key: ACCOUNT_SECURITY_SETTINGS.isGoogleTwoFaExists,
    title: 'GoogleAuthentication',
    shortTitle: 'GoogleAuthentication',
    description: 'GoogleAuthenticationDescription',
    shortDescription: 'GoogleAuthenticationShortDescription',
    enableButtonText: 'Enable',
    disableButtonText: 'Disable',
    linkTo: ACCOUNT_ROUTES.GOOGLE_AUTHENTICATION,
  },
  {
    key: ACCOUNT_SECURITY_SETTINGS.isSmsTwoFaExists,
    title: 'SMSAuthentication',
    shortTitle: 'SMSAuthentication',
    description: 'SMSAuthenticationDescription',
    shortDescription: 'SMSAuthenticationShortDescription',
    enableButtonText: 'Enable',
    disableButtonText: 'Disable',
    linkTo: ACCOUNT_ROUTES.SMS_AUTHENTICATION,
  },
  {
    key: ACCOUNT_SECURITY_SETTINGS.isTradePasswordExists,
    title: 'TradePassword',
    shortTitle: 'TradePassword',
    description: 'TradePasswordDescription',
    shortDescription: 'TradePasswordShortDescription',
    enableButtonText: 'Enable',
    disableButtonText: 'Disable',
    linkTo: ACCOUNT_ROUTES.TRADE_PASSWORD,
  },
  {
    key: ACCOUNT_SECURITY_SETTINGS.isDetectIpChange,
    title: 'DetectIPAddressChange',
    shortTitle: 'DetectIpChange',
    description: 'DetectIPAddressChangeDescription',
    shortDescription: 'DetectIPAddressChangeShortDescription',
    enableButtonText: 'Enable',
    disableButtonText: 'Disable',
  },
  {
    key: ACCOUNT_SECURITY_SETTINGS.isAntiPhishingExists,
    title: 'AntiPhishingCode',
    shortTitle: 'AntiPhishingCode',
    description: 'AntiPhishingCodeDescription',
    shortDescription: 'AntiPhishingCodeShortDescription',
    enableButtonText: 'Enable',
    disableButtonText: 'Disable',
    linkTo: ACCOUNT_ROUTES.ANTI_PHISHING_CODE,
  },
];
