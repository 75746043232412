import React, { memo } from 'react';

import { format } from 'date-fns';

import TruncatedText from '@shared/components/TruncatedText';

import styles from './DateItem.module.scss';

const DateItem = ({ date, formatType }) => {
  const formattedDate = format(new Date(date), formatType);

  return (
    <TruncatedText>
      <span className={styles.grey}>{formattedDate}</span>
    </TruncatedText>
  );
};

export default memo(DateItem);
