import { memo } from 'react';

import classNames from 'classnames';

import TruncatedText from '@shared/components/TruncatedText/TruncatedText';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';

import styles from './FileUploaderLoadedFiles.module.scss';

const FileUploaderLoadedFiles = ({ onRemoveFile, file }) => {
  const handleClick = (event) => {
    event.stopPropagation();

    return onRemoveFile(file);
  };

  return (
    <div
      className={classNames(
        'w-100 d-flex justify-content-center gap-36',
        styles.remove_file,
      )}
    >
      <TruncatedText>{file.name}</TruncatedText>

      <div className="cursor-pointer" onClick={handleClick}>
        <CrossThinIconSVG width={18} height={18} />
      </div>
    </div>
  );
};

export default memo(FileUploaderLoadedFiles);
