import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import Button from '@components/Button';
import Link from '@components/Link';
import SideBar from '@components/SideBar';
import Switch from '@components/Switch';

import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import useGridLayout, {
  defaultTemplates,
} from '@hooks/gridLayout/useGridLayout';
import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal';
import useSidebar from '@hooks/useSidebar';
import useTheme from '@hooks/useTheme';

import { modalsIds, sidebarIds, THEMES } from '@constants';

import { ReactComponent as AdvancedLayoutSVG } from '@images/advanced-layout.svg';
import { ReactComponent as BasicLayoutSVG } from '@images/basic-layout.svg';
import { ReactComponent as ClassicLayoutSVG } from '@images/classic-layout.svg';

import { ReactComponent as DesktopIconSVG } from '@icons/desktop-icon.svg';
import { ReactComponent as LaptopIconSVG } from '@icons/laptop-icon.svg';
import { ReactComponent as LockSVG } from '@icons/lock-icon.svg';
import { ReactComponent as SettingsIconSVG } from '@icons/settings-icon.svg';
import { ReactComponent as UnlockSVG } from '@icons/unlock-icon.svg';

import AuthPromptSection from './components/AuthPromptSection';

import { SERVICE_ROUTES, TRADE_ROUTES } from 'src/routes.constants';

const SideBarGridLayoutSettings = () => {
  const isGuest = useSelector(isGuestUserSelector);
  const { isMobile, isTablet } = useBreakpoints();

  const intl = useIntl();
  const { terminalTheme, setTerminalTheme } = useTheme();

  const {
    selectedTemplateId,
    isLockedLayout,
    lockGridLayout,
    isLayoutHasChanged,
    selectTemplateById,
    resetLayoutChanges,
  } = useGridLayout();

  const { openModal: openConfirmLeavePageModal } = useModal(
    modalsIds.CONFIRM_LEAVING_PAGE,
  );
  const { openModal: openSaveTemplateModal } = useModal(
    modalsIds.SAVE_TEMPLATE,
  );
  const { openModal: openSavedTemplatesListModal } = useModal(
    modalsIds.SAVED_TEMPLATES_LIST,
  );
  const { openModal: openClearTemplateModal } = useModal(
    modalsIds.CLEAR_TEMPLATE,
  );
  const { isOpen, toggleSidebar } = useSidebar(sidebarIds.GRID_LAYOUT_SETTINGS);

  const toggleTheme = () => {
    setTerminalTheme(
      terminalTheme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK,
    );
  };

  const handleLockLayoutClick = () => {
    if (isLayoutHasChanged) {
      openConfirmLeavePageModal({
        onLeavePage: () => {
          resetLayoutChanges();

          // needs to run lock after selectTemplateById setTimeout in the resetLayoutChanges function
          setTimeout(() => {
            lockGridLayout(true);
          }, 0);
        },
        onSaveChanges: () => {
          lockGridLayout(true);
        },
      });
    } else {
      lockGridLayout();
    }
  };

  const handleDefaultTemplateClick = (defaultTemplateId) => {
    if (isLayoutHasChanged) {
      openConfirmLeavePageModal({
        onLeavePage: () => selectTemplateById(defaultTemplateId),
        onSaveChanges: () => selectTemplateById(defaultTemplateId),
      });
    } else {
      selectTemplateById(defaultTemplateId);
    }
  };

  const handleSaveTemplateClick = () => openSaveTemplateModal();

  const handleSavedTemplatesListClick = () => openSavedTemplatesListModal();

  const handleClearTemplateClick = () => openClearTemplateModal();

  const toggleSideBarGridLayoutSettings = () => toggleSidebar();

  return (
    <>
      <div
        className={classnames('sidebar-grid-layout-settings', {
          active: isOpen,
        })}
        onClick={toggleSideBarGridLayoutSettings}
      >
        <SettingsIconSVG className="settings-icon" />

        {isLayoutHasChanged && <div className="settings-badge" />}
      </div>
      <SideBar
        className="header-grid-layout-settings-sidebar"
        open={isOpen}
        placement="right"
        handler={false}
        level={null}
        onClose={toggleSideBarGridLayoutSettings}
        withCloseButton={isTablet || isMobile}
        title={
          (isTablet || isMobile) && <FormattedMessage id="TerminalSettings" />
        }
      >
        <div className="sidebar-grid-layout-settings__content">
          <div className="header-sidebar-main">
            <div className="header-sidebar-item">
              <div className="title">
                <FormattedMessage id="LightDarkTheme" />
              </div>

              <Switch
                checked={terminalTheme === THEMES.DARK}
                onChange={toggleTheme}
                inversionColors
              />
            </div>
            {isTablet || isMobile ? (
              <div className="limited-possibility-info">
                <div className="d-flex flex-column limited-possibility-info__container">
                  <DesktopIconSVG className="limited-possibility-info__icon" />
                  <LaptopIconSVG className="limited-possibility-info__icon" />
                </div>

                <div className="d-flex flex-column">
                  <span className="limited-possibility-info__title">
                    <FormattedMessage id="UseDesktopAndLaptop" />
                  </span>
                  <span className="limited-possibility-info__text">
                    <FormattedMessage id="MobileDevicesHaveLimitedPossibilitiesPleaseUseDesktopOrLaptopToSeeAllExchangeTerminalOptions" />
                  </span>
                  <Link
                    to={TRADE_ROUTES.FEATURES}
                    className="text-decoration-underline"
                  >
                    <FormattedMessage id="ExchangeFeatures" />
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <div className="header-sidebar-item">
                  <div
                    className="layout-option"
                    onClick={() =>
                      handleDefaultTemplateClick(defaultTemplates.basic.id)
                    }
                  >
                    <div className="title">
                      <FormattedMessage id="Basic" />
                    </div>
                    <BasicLayoutSVG
                      className={classnames('layout-icon', {
                        active:
                          defaultTemplates.basic.id === selectedTemplateId,
                      })}
                    />
                  </div>

                  <div
                    className="layout-option"
                    onClick={() =>
                      handleDefaultTemplateClick(defaultTemplates.classic.id)
                    }
                  >
                    <div className="title">
                      <FormattedMessage id="Classic" />
                    </div>

                    <ClassicLayoutSVG
                      className={classnames('layout-icon', {
                        active:
                          defaultTemplates.classic.id === selectedTemplateId,
                      })}
                    />
                  </div>

                  <div
                    className="layout-option"
                    onClick={() =>
                      handleDefaultTemplateClick(defaultTemplates.advanced.id)
                    }
                  >
                    <div className="title">
                      <FormattedMessage id="Advanced" />
                    </div>

                    <AdvancedLayoutSVG
                      className={classnames('layout-icon', {
                        active:
                          defaultTemplates.advanced.id === selectedTemplateId,
                      })}
                    />
                  </div>
                </div>
                <div className="header-sidebar-item flex-column align-items-start">
                  <div className="d-flex justify-content-between align-items-sm-center w-100">
                    <Button
                      fill
                      textStart
                      className="header-sidebar-button title"
                      withTooltip={isLockedLayout || isGuest}
                      disabled={isLockedLayout || isGuest}
                      onClick={handleSaveTemplateClick}
                      tooltipConfig={{
                        placement: 'left',
                        content: intl.formatMessage({
                          id: isGuest ? 'LoginRequired' : 'LayoutLocked',
                        }),
                      }}
                    >
                      <div className="flex-1 d-flex justify-content-between">
                        <FormattedMessage id="SaveCurrentLayout" />
                      </div>
                    </Button>

                    {isLayoutHasChanged && (
                      <div className="changed-box">
                        <div className="changed-label">
                          <div className="changed-label__dot"></div>

                          <span className="changed-label__title">
                            <FormattedMessage id="Changed" />
                          </span>
                        </div>
                        <span
                          className="reset-button"
                          onClick={resetLayoutChanges}
                        >
                          <FormattedMessage id="Reset" />
                        </span>
                      </div>
                    )}
                  </div>

                  <Button
                    fill
                    textStart
                    withTooltip={isGuest}
                    disabled={isGuest}
                    className="header-sidebar-button title"
                    onClick={handleSavedTemplatesListClick}
                    tooltipConfig={{
                      placement: 'left',
                      content: intl.formatMessage({
                        id: 'LoginRequired',
                      }),
                    }}
                  >
                    <FormattedMessage id="MySaved" />
                  </Button>

                  <Button
                    fill
                    textStart
                    className="header-sidebar-button title"
                    withTooltip={isLockedLayout || isGuest}
                    disabled={isLockedLayout || isGuest}
                    onClick={handleClearTemplateClick}
                    tooltipConfig={{
                      placement: 'left',
                      content: intl.formatMessage({
                        id: isGuest ? 'LoginRequired' : 'LayoutLocked',
                      }),
                    }}
                  >
                    <FormattedMessage id="ClearLayout" />
                  </Button>
                </div>
                <div className="header-sidebar-item">
                  <Button
                    fill
                    textStart
                    className="lock-layout-button header-sidebar-button title"
                    onClick={handleLockLayoutClick}
                  >
                    <FormattedMessage id="LockLayout" />
                    <div>
                      {isLockedLayout ? (
                        <LockSVG className="lock-icon" />
                      ) : (
                        <UnlockSVG className="unlock-icon" />
                      )}
                    </div>
                  </Button>
                </div>
              </>
            )}
          </div>

          {isGuest && !(isTablet || isMobile) && <AuthPromptSection />}

          <div className="header-sidebar-footer">
            <div className="title">
              <FormattedMessage id="UsefulLinks" />
            </div>
            <div className="d-flex gap-8">
              <div className="flex-1">
                <Link to={SERVICE_ROUTES.ABOUT_LAYOUTS}>
                  <FormattedMessage id="AboutLayouts" />
                </Link>
                <Link to={SERVICE_ROUTES.PRE_MADE_LAYOUTS}>
                  <FormattedMessage id="PreMadeLayouts" />
                </Link>
                <Link to={SERVICE_ROUTES.LOCK_LAYOUT}>
                  <FormattedMessage id="LockLayout" />
                </Link>
              </div>
              <div className="flex-1">
                <Link to={SERVICE_ROUTES.USER_LAYOUTS}>
                  <FormattedMessage id="UserLayouts" />
                </Link>
                <Link to={SERVICE_ROUTES.SAVING_AND_USING}>
                  <FormattedMessage id="SavingAndUsing" />
                </Link>
                <Link to={SERVICE_ROUTES.CLEAR_LAYOUT}>
                  <FormattedMessage id="ClearLayout" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </SideBar>
    </>
  );
};

export default memo(SideBarGridLayoutSettings);
