import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button, Loader } from '@shared/ui';
import { copyToClipboard } from '@shared/utils';

import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import { ReactComponent as CopyIconSVG } from '@icons/copy-icon.svg';

import s from './CardNumber.module.scss';

const CardNumber = () => {
  const intl = useIntl();

  const [isShowFullCardNumber, setIsShowFullCardNumber] = useState(false);
  const [isShowPIN, setIsShowPIN] = useState(false);
  const [isShowCVV2, setIsShowCVV2] = useState(false);
  const [isPINLoading, setIsPINLoading] = useState(false);
  const [isCVV2Loading, setIsCVV2Loading] = useState(false);

  const card = useSelector(visaCardSelector);

  const truncatedCardNumber = useMemo(() => {
    const cardNumber = card.number;

    if (!cardNumber) return '';

    return `${cardNumber.slice(0, 4)} **** **** ${cardNumber.slice(-4)}`;
  }, [card.number]);

  if (!card.number) return null;

  const handleCopyToClipboard = async () => {
    await copyToClipboard({ intl, text: card.number });
  };

  const handleToggleFullCardNumber = async () => {
    setIsShowFullCardNumber((prevState) => !prevState);

    if (!isShowFullCardNumber) {
      await copyToClipboard({ intl, text: card.number });
    }
  };

  // TODO add real endpoint later
  const handleShowPIN = async () => {
    if (isShowPIN) {
      setIsShowPIN(false);
    } else {
      setIsPINLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsShowPIN(true);
      setIsPINLoading(false);
    }
  };

  // TODO add real endpoint later
  const handleShowCVV2 = async () => {
    if (isShowCVV2) {
      setIsShowCVV2(false);
    } else {
      setIsCVV2Loading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsShowCVV2(true);
      setIsCVV2Loading(false);
    }
  };

  return (
    <div>
      <div className={s.label}>
        <FormattedMessage id="CardNumber" />
      </div>
      <div className={s.content}>
        <div className="d-flex align-items-start gap-12">
          <div className={s.number} onClick={handleToggleFullCardNumber}>
            {isShowFullCardNumber ? card.number : truncatedCardNumber}
          </div>
          <div>
            <Button onClick={handleCopyToClipboard} size="xxs" variant="text">
              <CopyIconSVG />
            </Button>
          </div>
        </div>
        <div>
          <Button customStyles={s} onClick={handleShowPIN} variant="text">
            {isPINLoading ? (
              <Loader size="s" />
            ) : (
              <>
                {isShowPIN ? card.pinCode : <FormattedMessage id="ShowPIN" />}
              </>
            )}
          </Button>

          <Button customStyles={s} onClick={handleShowCVV2} variant="text">
            {isCVV2Loading ? (
              <Loader size="s" />
            ) : (
              <>
                {isShowCVV2 ? (
                  card.cvv2Code
                ) : (
                  <FormattedMessage id="ShowCVV2" />
                )}
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardNumber;
