import React, { memo } from 'react';

import { format } from '@utils/numbers';

import styles from './LiquidationAmountItem.module.scss';

const LiquidationAmountItem = ({
  coinSymbol,
  liquidationAmount,
  precision,
}) => {
  const value = format(liquidationAmount, {
    precision,
  });

  const numberValue = parseFloat(liquidationAmount);

  return (
    <span className={numberValue ? styles.black : styles.grey}>
      {value} {coinSymbol.toUpperCase()}
    </span>
  );
};

export default memo(LiquidationAmountItem);
