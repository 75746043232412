import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormProvider } from 'react-hook-form';

import { FormActions } from '@shared/components';
import { useCurrencyFormat } from '@shared/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as InfoIconIconSVG } from '@images/icons/info-icon.svg';

import { FormattedFinishingDate } from '../FormattedFinishingDate';
import { YouWillEarnItem } from '../YouWillEarnItem';

import styles from './ConfirmStakeForm.module.scss';

const ConfirmStakeForm = ({
  methods,
  handleSubmit,
  onSubmit,
  coinSymbol,
  periodPercent,
  launchedOn,
  watchPeriod,
  cancelStakingItem,
  watchAmountValue,
}) => {
  const { isTabletDown } = useBreakpoints();

  const { formattedCurrency: formattedAmount } = useCurrencyFormat(
    coinSymbol,
    watchAmountValue,
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div>
          <hr className={styles.divider} />

          <div className={styles.text}>
            <FormattedMessage id="Amount" />:
            <div className={styles.balance}>
              <span className="text-uppercase">
                {formattedAmount} {coinSymbol}
              </span>
            </div>
          </div>

          <div className={styles.text}>
            <FormattedMessage id="YouWillReceive" />:
            <div className={styles.balance}>
              <span className="text-uppercase">
                <YouWillEarnItem
                  coinSymbol={coinSymbol}
                  watchPeriod={watchPeriod}
                  periodPercent={periodPercent}
                  watchAmountValue={watchAmountValue}
                  isShowDepositedValue
                />
              </span>
            </div>
          </div>

          <div className={styles.text}>
            <FormattedMessage id="LaunchedOn" />:
            <div className={styles.balance}>
              <span>{launchedOn}</span>
            </div>
          </div>

          <div className={styles.text}>
            <FormattedMessage id="Finishing" />:
            <div className={styles.balance}>
              <span>
                <FormattedFinishingDate period={watchPeriod} />
              </span>
            </div>
          </div>

          <hr />

          <div className={styles.tooltip}>
            <InfoIconIconSVG width={12} height={12} />

            <div>
              <span className={styles.tooltip__title}>
                <FormattedMessage id="YourFundsWillBeLocked" />.
              </span>

              <br />

              <span className={styles.tooltip__suptitle}>
                <FormattedMessage id="YourRewardsWillBeUnlockedOnly" />
              </span>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <FormActions
            submitText={<FormattedMessage id="StakeConfirm" />}
            cancelText={<FormattedMessage id="Cancel" />}
            submitButtonProps={{
              size: isTabletDown ? 'm' : 'l',
              fill: true,
              inversion: true,
            }}
            cancelButtonProps={{
              size: isTabletDown ? 'm' : 'l',
              fill: true,
              inversion: true,
            }}
            onCancel={cancelStakingItem}
            customStyles={styles}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default memo(ConfirmStakeForm);
