import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import {
  Card,
  CardDetails,
  ItemTitle,
  PersonalFormattedCurrency,
} from '@shared/components';
import TruncatedText from '@shared/components/TruncatedText/TruncatedText';
import { CRYPTO_CURRENCIES } from '@shared/constants';

import {
  useCalculateStakedBalance,
  useCalculateStakedItem,
} from '@pages/WalletsPage/Staking/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { StakedDiagram, StakedItem } from './components';

import styles from './StakedBalance.module.scss';

const StakedBalance = () => {
  const { isMobile } = useBreakpoints();

  const {
    currency,
    resultItem,
    totalAmountInBTC,
    totalAmountInSelectedCurrency,
  } = useCalculateStakedBalance();

  const { stakedAssets } = useCalculateStakedItem(resultItem);

  const stakedItem = <StakedItem stakedAssets={stakedAssets} />;

  const mobileDetails = (
    <CardDetails>
      <div className="px-28 px-md-0">{stakedItem}</div>
    </CardDetails>
  );

  const stakedAssetsCount = stakedAssets?.length;

  return (
    <>
      <ItemTitle
        title={<FormattedMessage id="StakedBalance" />}
        isShowArrow={false}
      />

      <Card customStyles={styles} details={isMobile && mobileDetails}>
        <div className="row p-28">
          <div
            className={`col-12 col-md-${stakedAssetsCount ? 7 : 8} col-xl-12`}
          >
            <div className="row">
              <div
                className={`col-${stakedAssetsCount ? 8 : 7} col-md-${
                  stakedAssetsCount ? 9 : 7
                } col-xl-8`}
              >
                <div className={styles.title}>
                  <FormattedMessage id="EstimatedValue" />
                </div>

                <div className={styles.coin}>
                  <TruncatedText>
                    <PersonalFormattedCurrency
                      currency={CRYPTO_CURRENCIES.BTC}
                      value={totalAmountInBTC}
                    />
                  </TruncatedText>

                  <sup> {CRYPTO_CURRENCIES.BTC.toUpperCase()}</sup>
                </div>

                <div className={styles.currency}>
                  ≈{' '}
                  <PersonalFormattedCurrency
                    currency={currency}
                    value={totalAmountInSelectedCurrency}
                  />{' '}
                  {currency}
                </div>
              </div>

              <div
                className={`d-flex flex-column align-items-end align-items-md-${
                  stakedAssetsCount ? 'end' : 'center'
                } align-items-xl-end col-${stakedAssetsCount ? 4 : 5} col-md-${
                  stakedAssetsCount ? 3 : 5
                } col-xl-4`}
              >
                <StakedDiagram resultItem={resultItem} />
              </div>
            </div>
          </div>

          <div
            className={classNames(
              `col-12 col-md-${stakedAssetsCount ? 5 : 4} col-xl-12`,
              styles.desktop_staked,
            )}
          >
            {stakedItem}
          </div>
        </div>
      </Card>
    </>
  );
};

export default memo(StakedBalance);
