import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormProvider, useForm } from 'react-hook-form';

import { COUNTRY_CODE_NAME } from '@shared/components';
import { PhoneInputSelectController } from '@shared/components/PhoneInputSelectController';
import { Button } from '@shared/ui';

import { useProfileMutations } from '@api/hooks';

import styles from './PhoneNumberForm.module.scss';

const PhoneNumberForm = ({ onSendCode }) => {
  const intl = useIntl();

  const methods = useForm({
    defaultValues: { phone: '', [COUNTRY_CODE_NAME]: 'GB' },
  });
  const { handleSubmit } = methods;

  const { onGetCode, isLoadingGetCode } = useProfileMutations();

  const handleFormSubmit = async ({ phone }) => {
    try {
      const authId = await onGetCode({ phone });

      onSendCode(authId);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
        <PhoneInputSelectController
          name="phone"
          codeLabel={`${intl.formatMessage({ id: 'Country' })}:`}
          phoneLabel={`${intl.formatMessage({ id: 'PhoneNumber' })}:`}
          underlined
          bordered={false}
          size="l"
          {...methods}
        />
        <Button variant="outlined" isLoading={isLoadingGetCode} fill>
          <FormattedMessage id="GetCode" />
        </Button>
      </form>
    </FormProvider>
  );
};

export default memo(PhoneNumberForm);
