import s from './CountrySidebarItem.module.scss';

const CountrySidebarItem = ({ code, countryName, phoneCode }) => {
  const flagUrl = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`;

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center gap-16">
        <div className={s.flag_icon}>
          <img src={flagUrl} alt={code} />
        </div>
        <div className={s.country_title}>{countryName}</div>
      </div>
      <div className={s.phone_code}>{phoneCode}</div>
    </div>
  );
};

export default CountrySidebarItem;
