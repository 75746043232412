import { memo } from 'react';

import { Button } from '@shared/ui';

const ActionButton = ({ children, ...props }) => {
  return (
    <Button variant="outlined" {...props}>
      <div className="d-flex gap-6 align-items-center">
        <span className="fw-600">+</span> {children}
      </div>
    </Button>
  );
};

export default memo(ActionButton);
