import React, { memo } from 'react';

import {
  MyStakingTable,
  UnstakeItemSidebar,
} from '@pages/WalletsPage/Staking/components';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

const MyStakingTab = () => {
  const { toggleSidebar } = useSidebar(sidebarIds.WALLETS_UNSTAKE_ITEM);

  const toggleStakingSideBar = () => {
    toggleSidebar();
  };

  return (
    <>
      <MyStakingTable />

      <UnstakeItemSidebar toggleStakingSideBar={toggleStakingSideBar} />
    </>
  );
};

export default memo(MyStakingTab);
