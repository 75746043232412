export const ALL_PAIR_FILTER_KEY = 'all';

export const TRADING_BOTS_ACTIONS = {
  ARCHIVE: 'archive',
  DETAILS: 'details',
  SETTINGS: 'settings',
  UNARCHIVE: 'unarchive',
  LIQUIDATE: 'liquidate',
};

export const TRADING_BOTS_TABS_INDEX = {
  MY_BOTS: 0,
  ARCHIVED: 1,
  LIQUIDATED: 2,
};

export const TRADING_BOTS_STATUSES = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  LIQUIDATED: 'liquidated',
};
