import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

import { DEFAULT_REQUEST_INSTANT_BONUS_LIMIT } from '@constants';

export const bonusAccountAPI = createApi({
  reducerPath: 'bonusAccountAPI',
  baseQuery: client,
  tagTypes: ['MyContracts'],
  endpoints: (builder) => ({
    getBonusAccountSettings: builder.query({
      query: () => ({
        url: 'bonus-account/settings',
        method: 'GET',
      }),
    }),
    getMyContracts: builder.query({
      query: ({ limit = DEFAULT_REQUEST_INSTANT_BONUS_LIMIT }) => ({
        url: 'wallets/bonus-account/contracts',
        method: 'GET',
        params: {
          limit,
        },
      }),
      providesTags: ['MyContracts'],
    }),
    deposit: builder.mutation({
      query: (body) => ({
        url: 'wallets/bonus-account/deposit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MyContracts'],
    }),
  }),
});

export const {
  useGetBonusAccountSettingsQuery,
  useGetMyContractsQuery,
  useLazyGetMyContractsQuery,
  useDepositMutation,
} = bonusAccountAPI;
