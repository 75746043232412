import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '@shared/components';
import { Button } from '@shared/ui';

import useBreakpoints from '@hooks/useBreakpoints';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

import styles from './ChangePasswordCard.module.scss';

const ChangePasswordCard = () => {
  const { isMobile } = useBreakpoints();

  return (
    <Card
      classNames={[styles.card]}
      title={<FormattedMessage id="ChangePassword" />}
    >
      <div className={styles.change_password}>
        <div className={styles.change_password_text}>
          <FormattedMessage id="UsedForLogin" />
        </div>
        <div className={styles.button_container}>
          <Button
            fill={isMobile}
            navigation={{ to: ACCOUNT_ROUTES.CHANGE_PASSWORD }}
          >
            <FormattedMessage id="ChangePassword" />
            ...
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default memo(ChangePasswordCard);
