import React, { memo } from 'react';

import { getDaysInYear } from 'date-fns';

import { PersonalFormattedCurrency } from '@shared/components';

import {
  WEEK_IN_DAYS,
  FLEXIBLE,
  HALF_YEAR,
  WEEK,
  QUARTER,
  YEAR,
  MONTH,
  MONTH_IN_QUARTER,
  MONTH_IN_YEAR,
} from '@pages/WalletsPage/Wallets.constants';

const YouWillEarnItem = ({
  coinSymbol,
  periodPercent,
  watchAmountValue,
  watchPeriod: period,
  isShowDepositedValue = false,
}) => {
  const daysInCurrentYear = getDaysInYear(new Date().getFullYear());
  const getWeeksInYear = daysInCurrentYear / WEEK_IN_DAYS;

  const amount = Number(watchAmountValue);

  const amountForOneMonth = (amount * periodPercent) / MONTH_IN_YEAR;
  const amountForThreeMonth = (amount * periodPercent) / MONTH_IN_QUARTER;
  const amountForHalfYear = (amount * periodPercent) / 2;
  const amountForYear = amount * periodPercent;
  const flexAmount = amountForYear / daysInCurrentYear;
  const amountForWeek = amountForYear / getWeeksInYear;

  const periods = {
    [FLEXIBLE]: flexAmount,
    [WEEK]: amountForWeek,
    [MONTH]: amountForOneMonth,
    [QUARTER]: amountForThreeMonth,
    [HALF_YEAR]: amountForHalfYear,
    [YEAR]: amountForYear,
  };

  const youWillReceive = periods[period];
  const totalAmount = amount + youWillReceive;

  return (
    <>
      <PersonalFormattedCurrency
        currency={coinSymbol}
        value={isShowDepositedValue ? totalAmount : youWillReceive}
      />{' '}
      {coinSymbol}
    </>
  );
};

export default memo(YouWillEarnItem);
