import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router';

import { MenuActionsButton } from '@shared/components';
import { Button } from '@shared/ui';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import { WALLETS_ROUTES } from 'src/routes.constants';

import styles from './StakeActionItem.module.scss';

const actionDropdownItems = (
  amount,
  navigate,
  coinSymbol,
  openSidebar,
  stakingId,
) => [
  {
    id: 'history',
    title: (
      <span>
        <FormattedMessage id="History" />
        ...
      </span>
    ),
    onClick: () => {
      navigate(
        generatePath(WALLETS_ROUTES.STAKING_ASSETS_HISTORY, {
          stakingId,
        }),
      );
    },
  },
  {
    id: 'unstake',
    title: (
      <span>
        <FormattedMessage id="Unstake" />
      </span>
    ),
    onClick: () => openSidebar({ coinSymbol, amount }),
  },
];

const StakeActionItem = ({ stakingId, coinSymbol, amount }) => {
  const navigate = useNavigate();

  const { openSidebar } = useSidebar(sidebarIds.WALLETS_UNSTAKE_ITEM);

  const actionItems = useMemo(
    () =>
      actionDropdownItems(amount, navigate, coinSymbol, openSidebar, stakingId),
    [amount, navigate, coinSymbol, openSidebar, stakingId],
  );

  return (
    <div className="d-flex align-items-center justify-content-end">
      <Button size="s" customStyles={styles}>
        <FormattedMessage id="TopUp" />
      </Button>

      <MenuActionsButton
        menuItems={actionItems}
        customStyles={styles}
        variant="text"
        color="secondary"
      />
    </div>
  );
};

export default memo(StakeActionItem);
