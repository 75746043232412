import { createSlice, isAllOf } from '@reduxjs/toolkit';
import reactFastCompare from 'react-fast-compare';

import {
  getUserId,
  getIsAuthorizedUser,
  getIsShowSensitiveInfo,
  getUserEmail,
  getUserRole,
} from '@utils';

import {
  LOCALE_LIST,
  CURRENCY_LIST,
  DEFAULT_MARKET_ID,
  THEMES,
  LOCAL_STORAGE_MARKET_ID,
  LOCAL_STORAGE_TERMINAL_THEME,
  LOCAL_STORAGE_ACCOUNT_THEME,
  LOCAL_STORAGE_LOCALE,
  LOCAL_STORAGE_CURRENCY,
  LOCAL_STORAGE_SENSITIVE_INFO,
} from '@constants';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    terminalTheme:
      localStorage.getItem(LOCAL_STORAGE_TERMINAL_THEME) ?? THEMES.DARK,
    accountTheme:
      localStorage.getItem(LOCAL_STORAGE_ACCOUNT_THEME) ?? THEMES.ACCOUNT_LIGHT,
    isAccountTheme: false,
    locale:
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOCALE)) ?? LOCALE_LIST[0],
    currency:
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_CURRENCY)) ??
      CURRENCY_LIST[0],
    isWSConnected: false,
    marketId:
      localStorage.getItem(LOCAL_STORAGE_MARKET_ID) ?? DEFAULT_MARKET_ID,
    userId: getUserId(),
    userEmail: getUserEmail(),
    userRole: getUserRole(),
    isAuthorizedUser: getIsAuthorizedUser(),
    isShowSensitiveInfo: getIsShowSensitiveInfo(),
  },
  reducers: {
    setTerminalTheme: (state, action) => {
      state.terminalTheme = action.payload;
      localStorage.setItem(LOCAL_STORAGE_TERMINAL_THEME, action.payload);
    },
    setAccountTheme: (state, action) => {
      state.accountTheme = action.payload;
      localStorage.setItem(LOCAL_STORAGE_ACCOUNT_THEME, action.payload);
    },
    setIsAccountTheme: (state, action) => {
      state.isAccountTheme = action.payload;
    },
    setLocale: (state, action) => {
      if (!reactFastCompare(state.locale, action.payload)) {
        state.locale = action.payload;
        localStorage.setItem(
          LOCAL_STORAGE_LOCALE,
          JSON.stringify(action.payload),
        );
      }
    },
    setCurrency: (state, action) => {
      if (!reactFastCompare(state.currency, action.payload)) {
        state.currency = action.payload;
        localStorage.setItem(
          LOCAL_STORAGE_CURRENCY,
          JSON.stringify(action.payload),
        );
      }
    },
    setIsWSConnected: (state, action) => {
      if (state.isWSConnected !== action.payload) {
        state.isWSConnected = action.payload;
      }
    },
    setMarketId: (state, action) => {
      if (state.marketId !== action.payload) {
        state.marketId = action.payload;
        localStorage.setItem(LOCAL_STORAGE_MARKET_ID, action.payload);
      }
    },
    setUserId: (state, action) => {
      if (state.userId !== action.payload) {
        state.userId = action.payload;
      }
    },
    setUserEmail: (state, action) => {
      if (state.userEmail !== action.payload) {
        state.userEmail = action.payload;
      }
    },
    setUserRole: (state, action) => {
      if (state.userRole !== action.payload) {
        state.userRole = action.payload;
      }
    },
    setIsAuthorizedUser: (state, action) => {
      if (state.isAuthorizedUser !== action.payload) {
        state.isAuthorizedUser = action.payload;
      }
    },
    toggleShowSensitiveInfo: (state, action) => {
      state.isShowSensitiveInfo = !state.isShowSensitiveInfo;
      localStorage.setItem(
        LOCAL_STORAGE_SENSITIVE_INFO,
        state.isShowSensitiveInfo,
      );
    },
  },
});

const settingsActions = settingsSlice.actions;
const settingsReducer = settingsSlice.reducer;

const isAuthorizedUserAction = isAllOf(settingsActions.setIsAuthorizedUser);

export { settingsActions, settingsReducer, isAuthorizedUserAction };

export default settingsSlice;
