import cx from 'classnames';

import { COLUMN_ALIGN } from '@shared/components/Table/table.constants';

import { NOOP } from '@constants';

import s from './TableBodyCell.module.scss';

const TableBodyCell = ({
  children,
  cell,
  style,
  onRowClick = NOOP,
  ...props
}) => {
  const align = cell?.column?.columnDef?.align ?? COLUMN_ALIGN.LEFT;
  const isEnableTruncating = cell.column.columnDef.enableTruncating ?? true;
  const isClickableRow = onRowClick !== NOOP;
  const isClickableCell = cell.column.columnDef.isClickable ?? true;

  return (
    <td
      style={{
        maxWidth: cell?.column?.getSize(),
        minWidth: cell?.column?.getSize(),
        ...style,
      }}
      onClick={
        isClickableRow && isClickableCell ? () => onRowClick() : undefined
      }
      {...props}
    >
      <div className={cx(s.body_cell, s[`body_cell__${align}`])}>
        <span
          className={cx(s.body_cell__text, {
            [s.body_cell__text_truncated]: isEnableTruncating,
          })}
        >
          {children}
        </span>
      </div>
    </td>
  );
};

export default TableBodyCell;
