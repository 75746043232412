import { createSlice } from '@reduxjs/toolkit';

import {
  TRADE_FEES_DEFAULT_VALUES,
  ORDERS_ITEMS_PER_PAGE,
} from '@pages/OrderPages/constants';

const initialState = {
  filters: TRADE_FEES_DEFAULT_VALUES,
  pagination: {
    pageIndex: 0,
    pageSize: ORDERS_ITEMS_PER_PAGE,
  },
};

const tradeFeesSlice = createSlice({
  name: 'tradeFees',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    resetPagination: (state, action) => {
      state.pagination = initialState.pagination;
    },
  },
});

const tradeFeesActions = tradeFeesSlice.actions;
const tradeFeesReducer = tradeFeesSlice.reducer;

export { tradeFeesActions, tradeFeesReducer };

export default tradeFeesSlice;
