import React, { memo, useId, useMemo } from 'react';

import Table from '@components/gridTables/Table';

import { useTokenGrowth } from '@pages/AccountPage/Overview/OverviewEarnWidgets/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import tokenGrowthTableColumns from '../TokenGrowthTableColumns/TokenGrowthTableColumns';

const TokenGrowthTable = () => {
  const id = useId();

  const { tokenGrowth, isSuccess } = useTokenGrowth();

  const { isMobile } = useBreakpoints();

  const columns = useMemo(
    () => tokenGrowthTableColumns({ isMobile }),
    [isMobile],
  );

  return (
    <Table
      id={id}
      className="account-tables-swipe my-20"
      columns={columns}
      data={tokenGrowth}
      wideRowsS
      stripedRows={false}
      isLoading={!isSuccess}
    />
  );
};

export default memo(TokenGrowthTable);
