import { useIntl } from 'react-intl';

import cx from 'classnames';

import { CurrenciesSelect } from '@shared/components';
import { InputController } from '@shared/ui';

import s from './InputCurrenciesSelect.module.scss';

export const INPUT_CURRENCIES_ORIENTATION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

const InputCurrenciesSelect = ({
  inversion,
  customStyles = {},
  inputProps = {},
  selectProps = {},
  label,
  orientation = INPUT_CURRENCIES_ORIENTATION.HORIZONTAL,
  ...props
}) => {
  const intl = useIntl();

  return (
    <>
      {label && (
        <div
          className={cx(s.input_select_label, customStyles.input_select_label, {
            [s.input_select_label__inversion]: inversion,
          })}
        >
          {label}
        </div>
      )}

      <div
        className={cx(
          s.input_select,
          s[orientation],
          customStyles.input_select,
          {
            [s.inversion]: inversion,
          },
        )}
      >
        <div
          className={cx(
            s.input_select__input,
            customStyles.input_select__input,
          )}
        >
          <InputController
            inversion={inversion}
            autoComplete="off"
            onlyNumbers
            placeholder={intl.formatMessage({ id: 'ZeroFloatPlaceholder' })}
            customStyles={s}
            {...inputProps}
            {...props}
          />
        </div>
        <div
          className={cx(
            s.input_select__select,
            customStyles.input_select__select,
          )}
        >
          <CurrenciesSelect
            inversion={inversion}
            styles={{
              control: {
                paddingLeft:
                  orientation === INPUT_CURRENCIES_ORIENTATION.HORIZONTAL
                    ? '14px'
                    : '0px',
              },
            }}
            {...selectProps}
            {...props}
          />
        </div>
      </div>
    </>
  );
};

export default InputCurrenciesSelect;
