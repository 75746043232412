import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFormContext, useWatch } from 'react-hook-form';

import {
  CurrenciesSelect,
  InputCurrenciesSelect,
  transformToCurrencyOptions,
} from '@shared/components';
import { CRYPTO_CURRENCIES } from '@shared/constants';
import { useSpotTotalBalances } from '@shared/hooks';
import { Button, InputController, SliderController } from '@shared/ui';

import { SideBarLabel } from '@components/SideBar/components';

import { spotAccountFormDetailsSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';
import {
  IsMoreThanZeroValue,
  isProfitMoreThanZero,
} from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.validations';

import { currencySelector } from '@store/settings/settingsSelectors';

import { useCoinsInfo, useCoinsRates } from '@api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { format } from '@utils/numbers';

import { QUANTITY_MARKS } from '@constants';

import s from './SpotAccountForm.module.scss';

export const SPOT_ACCOUNT_FORM_FIELD_NAMES = {
  CURRENCY: 'currency',
  AMOUNT: 'amount',
  PERCENT: 'percent',
  ACCOUNT: 'recipient_account',
};

const SpotAccountForm = () => {
  const intl = useIntl();

  const mainCurrency = useSelector(currencySelector);
  const spotAccountFormDetails = useSelector(spotAccountFormDetailsSelector);

  const { isMobile } = useBreakpoints();

  const methods = useFormContext();

  const currencyWatch = useWatch({
    control: methods.control,
    name: SPOT_ACCOUNT_FORM_FIELD_NAMES.CURRENCY,
  });
  const percentWatch = useWatch({
    control: methods.control,
    name: SPOT_ACCOUNT_FORM_FIELD_NAMES.PERCENT,
  });

  const { totalBalance } = useSpotTotalBalances({
    coinSymbol: currencyWatch?.value,
    filedName: 'available',
  });

  const { coinsInfoMap } = useCoinsInfo({
    orderByTypeDirection: 'asc',
  });

  const currenciesMemo = useMemo(
    () =>
      transformToCurrencyOptions({
        currencies: coinsInfoMap,
        inversion: true,
        isShowFullName: isMobile,
        iconAccessor: 'icon',
        iconWidth: isMobile ? 28 : 20,
        iconHeight: isMobile ? 28 : 20,
      }),
    [coinsInfoMap, isMobile],
  );

  const formattedSpotTotalAvailableBalance = useMemo(
    () => format(totalBalance, { precision: currencyWatch?.digits }),
    [totalBalance, currencyWatch],
  );

  const { ratesByCoin: selectedCurrencyRates } = useCoinsRates({
    fromCoinSymbol: currencyWatch?.value,
  });

  const defaultCurrency = useMemo(
    () => currenciesMemo.find((cur) => cur.value === CRYPTO_CURRENCIES.BTC),
    [currenciesMemo],
  );

  useEffect(() => {
    if (!currenciesMemo.length) return;

    setTimeout(() => {
      methods.setValue(SPOT_ACCOUNT_FORM_FIELD_NAMES.CURRENCY, defaultCurrency);
    });
  }, [methods, currenciesMemo, defaultCurrency]);

  useEffect(() => {
    const percentOfAmount = totalBalance * (percentWatch / 100);

    const formattedPercentOfAmount = format(percentOfAmount, {
      precision: currencyWatch?.digits,
      noCommas: true,
    });

    methods.setValue(
      SPOT_ACCOUNT_FORM_FIELD_NAMES.AMOUNT,
      formattedPercentOfAmount,
    );
  }, [percentWatch, totalBalance, methods]);

  const handleSetMaxAmount = () => {
    methods.setValue(
      SPOT_ACCOUNT_FORM_FIELD_NAMES.AMOUNT,
      format(totalBalance, {
        precision: currencyWatch?.digits,
        noCommas: true,
      }),
      { shouldTouch: true },
    );
  };

  const inputProps = useMemo(
    () => ({
      bordered: false,
      name: SPOT_ACCOUNT_FORM_FIELD_NAMES.AMOUNT,
      precision: currencyWatch?.digits,
      size: 'l',
      underlined: true,
      rules: {
        required: intl.formatMessage({ id: 'FieldRequired' }),
        max: {
          value: totalBalance,
          message: intl.formatMessage({
            id: 'YouDontHaveEnoughFunds',
          }),
        },
        validate: {
          isMoreThanZero: IsMoreThanZeroValue(intl),
          isProfitMoreThanZero: isProfitMoreThanZero({
            intl,
            selectedCurrencyRates,
            mainCurrency,
            commission: spotAccountFormDetails.commission.value,
          }),
        },
      },
      suffix: (
        <div className="pe-12">
          <Button
            type="button"
            color="green"
            size="xxs"
            variant="outlined"
            onClick={handleSetMaxAmount}
          >
            MAX
          </Button>
        </div>
      ),
    }),
    [
      intl,
      currencyWatch,
      totalBalance,
      selectedCurrencyRates,
      mainCurrency,
      spotAccountFormDetails,
    ],
  );

  const selectProps = useMemo(
    () => ({
      currencies: currenciesMemo,
      name: SPOT_ACCOUNT_FORM_FIELD_NAMES.CURRENCY,
      size: 'l',
      underlined: true,
    }),
    [currenciesMemo],
  );

  return (
    <div>
      <div>
        {isMobile && (
          <div className="mb-24">
            <CurrenciesSelect
              inversion
              label={intl.formatMessage({ id: 'CoinLabel' })}
              {...selectProps}
              {...methods}
            />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <SideBarLabel>
              <FormattedMessage id="Amount" />:
            </SideBarLabel>
          </div>
          <div>
            <span className={s.small_label}>
              <FormattedMessage id="AvailableBalance" />:
            </span>
            <span className={s.small_bold_label}>
              {formattedSpotTotalAvailableBalance}{' '}
              {currencyWatch?.value?.toUpperCase()}
            </span>
          </div>
        </div>
        <div className="mt-10">
          {isMobile ? (
            <InputController
              inversion
              autoComplete="off"
              onlyNumbers
              placeholder={intl.formatMessage({ id: 'ZeroFloatPlaceholder' })}
              customStyles={s}
              {...inputProps}
              {...methods}
            />
          ) : (
            <InputCurrenciesSelect
              inputProps={inputProps}
              selectProps={selectProps}
              inversion
              {...methods}
            />
          )}
        </div>
      </div>

      <div className="mt-32">
        <div className="d-flex gap-30">
          <SideBarLabel>
            <FormattedMessage id="Quantity" />:
          </SideBarLabel>

          <div className="w-100">
            <SliderController
              name={SPOT_ACCOUNT_FORM_FIELD_NAMES.PERCENT}
              inversion
              marks={QUANTITY_MARKS}
              disabled={!totalBalance}
              {...methods}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpotAccountForm;
