import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '@shared/components';

import { ReactComponent as BuildingIconSVG } from '@icons/building-icon.svg';
import { ReactComponent as MouthpieceIconSVG } from '@icons/mouthpiece-icon.svg';

import styles from './NeedASpecialAccount.module.scss';

const SpecialAccountItem = memo(({ icon, title, linkTo, readMoreLinkTo }) => {
  return (
    <div className={styles.item}>
      {icon}
      <div className={styles.content}>
        <a href={linkTo} className={styles.title}>
          {title}
        </a>

        <a href={readMoreLinkTo} className={styles.link}>
          <FormattedMessage id="ReadMore" />
          ...
        </a>
      </div>
    </div>
  );
});

const NeedASpecialAccount = () => {
  return (
    <Card
      customStyles={styles}
      title={<FormattedMessage id="NeedASpecialAccount" />}
    >
      <div className={styles.card_content}>
        <SpecialAccountItem
          icon={<BuildingIconSVG />}
          title={<FormattedMessage id="OpenBusinessAccount" />}
          linkTo="/coming-soon"
          readMoreLinkTo="/coming-soon"
        />

        <hr />

        <SpecialAccountItem
          icon={<MouthpieceIconSVG />}
          title={<FormattedMessage id="OpenAffiliateAccount" />}
          linkTo="/coming-soon"
          readMoreLinkTo="/coming-soon"
        />
      </div>
    </Card>
  );
};

export default memo(NeedASpecialAccount);
