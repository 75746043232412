import React, { memo } from 'react';

import { InfoWidget, PersonalData } from '@shared/components';

import {
  useGetFeesInfoQueryResult,
  useGetProfileInfoQueryResult,
} from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { HeaderTitleInfo } from '../HeaderTitleInfo';

import { ACCOUNT_ROUTES } from '../../../../../routes.constants';

import styles from './TradingFeesWidget.module.scss';

const TradingFeesWidget = () => {
  const { tradingFee } = useGetFeesInfoQueryResult();

  const { isUserHasMaxLevel } = useGetProfileInfoQueryResult();

  const { isMobile } = useBreakpoints();

  const isLinkToVisible = !isMobile && !isUserHasMaxLevel;

  if (!tradingFee) {
    return null;
  }

  return (
    <InfoWidget
      customStyles={styles}
      suffix={<span className="percent-suffix">%</span>}
      title={<HeaderTitleInfo titleId="TradingFees" info="TradingFeesInfo" />}
      linkId="UpgradeLevel"
      linkTo={isLinkToVisible && ACCOUNT_ROUTES.KYC}
      contentClassName="gap-10"
      value={<PersonalData data={tradingFee} />}
    />
  );
};

export default memo(TradingFeesWidget);
