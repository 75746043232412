import { useGetTradeFeesQuery } from '@store/api/feesAPI';

import { TradeFeesApiSelectors } from '../selectors';

const useGetTradeFeesQueryResult = (args, options = {}) => {
  const result = useGetTradeFeesQuery(args, {
    ...options,
    skip: !args?.accountId || options.skip,
    selectFromResult: (result) => ({
      ...result,
      meta: TradeFeesApiSelectors.metaSelector(result),
      trades: TradeFeesApiSelectors.tradeFeesSelector(result),
    }),
  });

  return result;
};

export default useGetTradeFeesQueryResult;
