import { memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';

import {
  FormActions,
  FormattedCurrency,
  INPUT_CURRENCIES_ORIENTATION,
  InputCurrenciesSelect,
} from '@shared/components';
import { useCurrencyFormat } from '@shared/hooks';
import { Button, SliderController } from '@shared/ui';

import { SideBarLabel } from '@components/SideBar/components';

import { useCurrenciesInfo } from '@pages/WalletsPage/Staking/hooks';
import { PLACEHOLDER_VALUE } from '@pages/WalletsPage/Staking/staking.constants';

import { useCoinsInfo } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { PERCENT_PRECISION, QUANTITY_MARKS, sidebarIds } from '@constants';

import { DurationPeriodController } from '../DurationPeriod/DurationPeriod';
import { YouWillEarnItem } from '../YouWillEarnItem';

import styles from './StakeForm.module.scss';

const StakeForm = ({
  navigateToConfirmStaking,
  mainAvailableBalance,
  coinSymbol,
  availablePlanPeriods: periods,
  periodPercent,
  minLimit,
  maxLimit,
  closeSidebar,
  setMaxValueHandler,
  isSidebarOpen,
}) => {
  const intl = useIntl();

  const methods = useFormContext();

  const { control, handleSubmit } = methods;

  const { currencies } = useCurrenciesInfo(undefined, { skip: !isSidebarOpen });

  const { isMobile, isTabletDown } = useBreakpoints();

  const { coinInfo } = useCoinsInfo({ coinSymbol });

  const payCurrencyWatch = useWatch({ control, name: 'payCurrency' });
  const watchPeriod = useWatch({ control, name: 'period' });
  const watchAmountValue = useWatch({ control, name: 'pay' });

  const { sidebar } = useSidebar(sidebarIds.WALLETS_CREATE_STAKING_ITEM);

  // Setting default currency
  useEffect(() => {
    if (!!payCurrencyWatch) return;

    methods.setValue(
      'payCurrency',
      currencies.find(({ value }) => value === sidebar?.params?.payCurrency),
    );
  }, [currencies, methods, payCurrencyWatch, sidebar]);

  const precision = coinInfo?.digits;

  const { formattedCurrency: formattedMinLimit } = useCurrencyFormat(
    coinSymbol,
    minLimit,
  );

  const { formattedCurrency: formattedMaxLimit } = useCurrencyFormat(
    coinSymbol,
    maxLimit,
  );

  const percent = format(periodPercent * 100, {
    precision: PERCENT_PRECISION,
  });

  const placeholder = format(PLACEHOLDER_VALUE, {
    precision,
  });

  const commonFieldProps = {
    bordered: false,
    size: 'l',
    underlined: true,
    inversion: true,
  };

  const AmountComponent = (
    <div className={styles.amount}>
      <FormattedMessage id="Amount" />:
      <div className={styles.balance}>
        <FormattedMessage id={isMobile ? 'Available' : 'AvailableBalance'} />:{' '}
        <span>
          <FormattedCurrency
            value={mainAvailableBalance}
            currency={coinSymbol}
          />{' '}
          {coinSymbol}
        </span>
      </div>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(navigateToConfirmStaking)}
        className={styles.form}
      >
        <div>
          <div className={styles.subtitle}>
            <FormattedMessage id="StakeSidebarInfo" />
          </div>

          <InputCurrenciesSelect
            inputProps={{
              name: 'pay',
              precision: payCurrencyWatch?.digits,
              placeholder,
              rules: {
                required: intl.formatMessage({ id: 'FieldRequired' }),
                max: {
                  value: maxLimit,
                  message: intl.formatMessage(
                    {
                      id: 'YouHaveExceededMaxHoldingLimit',
                    },
                    { maxLimit },
                  ),
                },
                min: {
                  value: minLimit,
                  message: intl.formatMessage(
                    {
                      id: 'AmountShouldBeGreaterThanMinHoldingValue',
                    },
                    { minLimit },
                  ),
                },
              },
              suffix: (
                <Button
                  type="button"
                  size="xxs"
                  variant="outlined"
                  color="green"
                  onClick={setMaxValueHandler}
                  customStyles={{ button: [styles.button_max] }}
                >
                  <FormattedMessage id="Max" />
                </Button>
              ),
              label: isMobile && AmountComponent,
              ...commonFieldProps,
            }}
            selectProps={{
              name: 'payCurrency',
              currencies,
              label: isMobile && `${intl.formatMessage({ id: 'CoinLabel' })}:`,
              isSearchable: isMobile,
              ...commonFieldProps,
            }}
            label={!isMobile && AmountComponent}
            inversion
            orientation={
              isMobile ? INPUT_CURRENCIES_ORIENTATION.VERTICAL : undefined
            }
            {...methods}
          />

          <div className="mt-30">
            <div className="d-flex gap-30">
              <SideBarLabel>
                <FormattedMessage id="Quantity" />:
              </SideBarLabel>

              <div className="w-100">
                <SliderController
                  name="percent"
                  inversion
                  marks={QUANTITY_MARKS}
                  disabled={!mainAvailableBalance}
                  {...methods}
                />
              </div>
            </div>
          </div>

          <div className={styles.periods}>
            <span>
              <FormattedMessage id="Period" />:
            </span>

            <DurationPeriodController
              name="period"
              control={control}
              periods={periods}
            />
          </div>

          <hr />

          <div className={styles.info}>
            <FormattedMessage id="EstimatedReward" />:
            <span className={styles.reward}>+{percent}%</span>
          </div>

          <div className={styles.info}>
            <FormattedMessage id="YouWillEarn" />:
            <span>
              <YouWillEarnItem
                coinSymbol={coinSymbol}
                watchPeriod={watchPeriod}
                periodPercent={periodPercent}
                watchAmountValue={watchAmountValue}
              />
            </span>
          </div>

          <div className={styles.info}>
            <FormattedMessage id="MinHolding" />:{' '}
            <span>
              {formattedMinLimit ? (
                <>
                  {formattedMinLimit} {coinSymbol}
                </>
              ) : (
                <FormattedMessage id="NotSet" />
              )}
            </span>
          </div>

          <div className={classNames(styles.info, 'mb-24')}>
            <FormattedMessage id="MaxHolding" />:{' '}
            <span>
              {formattedMaxLimit ? (
                <>
                  {formattedMaxLimit} {coinSymbol}
                </>
              ) : (
                <FormattedMessage id="NotSet" />
              )}
            </span>
          </div>
        </div>

        <div className={styles.footer}>
          <FormActions
            submitText={<FormattedMessage id="Stake" />}
            cancelText={<FormattedMessage id="Cancel" />}
            submitButtonProps={{
              size: isTabletDown ? 'm' : 'l',
              fill: true,
              inversion: true,
            }}
            cancelButtonProps={{
              size: isTabletDown ? 'm' : 'l',
              fill: true,
              inversion: true,
            }}
            onCancel={closeSidebar}
            customStyles={styles}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default memo(StakeForm);
