import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { useNavigateBack } from '@shared/hooks';

import {
  useDeleteUnbindPhoneNumberMutation,
  useKybSendAccountInformationMutation,
  useKybSendAdditionalInformationMutation,
  useKybSendOperationalAddressMutation,
  useKybSendRegistrationAddressMutation,
  useKybSendSourceOfFundsMutation,
  useKybUploadDocCertificateOfIncorporationMutation,
  useKybUploadDocMemorandumMutation,
  useKybUploadDocDirectorsMutation,
  useKybUploadDocMembersMutation,
  useKybUploadDocOwnershipMutation,
  useKybUploadDocSanctionsMutation,
  useKycCustomerRegistrationMutation,
  useKycCustomerVerificationMutation,
  useKycPaymentDocumentVerificationMutation,
  usePostChangeAvatarMutation,
  usePostChangeNicknameMutation,
  usePostChangePasswordMutation,
  usePostEnableAntiPhishingCodeMutation,
  usePostSendSmsMutation,
  usePostSetTradePasswordMutation,
  usePostSmsAuthInitBindMutation,
  usePutBindPhoneNumberMutation,
  usePutDisableAntiPhishingCodeMutation,
  usePutDisableTradePasswordMutation,
  usePutOffGoogleAuthorizationMutation,
  usePutOnGoogleAuthorizationMutation,
} from '@api/profileAPI';
import { formatObjectKeysFromCamelToSnakeCase } from '@api/utils';

import { ACCOUNT_ROUTES } from '../../../routes.constants';

const useProfileMutations = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  const [sendSMS, { isLoading: isSendSMSLoading }] = usePostSendSmsMutation();
  const [
    kycCustomerRegistration,
    { isLoading: isKycCustomerRegistrationLoading },
  ] = useKycCustomerRegistrationMutation();
  const [
    kycCustomerVerification,
    { isLoading: isKycCustomerVerificationLoading },
  ] = useKycCustomerVerificationMutation();
  const [
    kycPaymentDocumentVerification,
    { isLoading: isKycPaymentDocumentVerificationLoading },
  ] = useKycPaymentDocumentVerificationMutation();
  const [changeAvatar, { isLoading: isChangeAvatarLoading }] =
    usePostChangeAvatarMutation();
  const [changeNickname, { isLoading: isChangeNicknameLoading }] =
    usePostChangeNicknameMutation();
  const [changePassword, { isLoading: isChangePasswordLoading }] =
    usePostChangePasswordMutation();
  const [disableOtp, { isLoading: isDisableOtpLoading }] =
    usePutOffGoogleAuthorizationMutation();
  const [enableOtp, { isLoading: isEnableOtpLoading }] =
    usePutOnGoogleAuthorizationMutation();
  const [bindPhoneNumber, { isBindPhoneNumberLoading }] =
    usePutBindPhoneNumberMutation();
  const [unbindPhoneNumber, { isLoading: isUnbindPhoneNumberLoading }] =
    useDeleteUnbindPhoneNumberMutation();
  const [setTradePassword, { isSetTradePasswordLoading }] =
    usePostSetTradePasswordMutation();
  const [disableTradePassword, { isDisableTradePasswordLoading }] =
    usePutDisableTradePasswordMutation();
  const [getCode, { isLoading: isLoadingGetCode }] =
    usePostSmsAuthInitBindMutation();
  const [setAntiPhishingCode, { isSetAntiPhishingCodeLoading }] =
    usePostEnableAntiPhishingCodeMutation();
  const [disableAntiPhishingCode, { isDisableAntiPhishingCodeLoading }] =
    usePutDisableAntiPhishingCodeMutation();

  const [
    kybSendAccountInformation,
    { isLoading: isKybSendAccountInformationLoading },
  ] = useKybSendAccountInformationMutation();
  const [
    kybSendRegistrationAddress,
    { isLoading: isKybSendRegistrationAddressLoading },
  ] = useKybSendRegistrationAddressMutation();
  const [
    kybSendOperationalAddress,
    { isLoading: isKybSendOperationalAddressLoading },
  ] = useKybSendOperationalAddressMutation();
  const [kybSendSourceOfFunds, { isLoading: isKybSendSourceOfFundsLoading }] =
    useKybSendSourceOfFundsMutation();
  const [
    kybSendAdditionalInformation,
    { isLoading: isKybSendAdditionalInformationLoading },
  ] = useKybSendAdditionalInformationMutation();

  const [
    kybUploadDocCertificateOfIncorporation,
    { isLoading: isKybUploadDocCertificateOfIncorporationLoading },
  ] = useKybUploadDocCertificateOfIncorporationMutation();
  const [
    kybUploadDocMemorandum,
    { isLoading: isKybUploadDocMemorandumLoading },
  ] = useKybUploadDocMemorandumMutation();
  const [kybUploadDocDirectors, { isLoading: isKybUploadDocDirectorsLoading }] =
    useKybUploadDocDirectorsMutation();
  const [kybUploadDocMembers, { isLoading: isKybUploadDocMembersLoading }] =
    useKybUploadDocMembersMutation();
  const [kybUploadDocOwnership, { isLoading: isKybUploadDocOwnershipLoading }] =
    useKybUploadDocOwnershipMutation();
  const [kybUploadDocSanctions, { isLoading: isKybUploadDocSanctionsLoading }] =
    useKybUploadDocSanctionsMutation();

  const onSendSMS = async () => {
    await sendSMS().unwrap();

    toast.success(intl.formatMessage({ id: 'SMSSent' }));
  };

  const onGetCode = async (data) => {
    const authId = await getCode(data).unwrap();

    toast.success(intl.formatMessage({ id: 'EnterReceivedSmsCode' }));

    return authId;
  };

  const onKycCustomerRegistration = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await kycCustomerRegistration(body).unwrap();
  };

  const onKycCustomerVerification = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await kycCustomerVerification(body).unwrap();
  };

  const onKycPaymentDocumentVerification = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await kycPaymentDocumentVerification(body).unwrap();
  };

  const onChangeAvatar = async (data) => {
    await changeAvatar(data).unwrap();

    toast.success(
      intl.formatMessage({ id: 'YouSuccessfullyUpdatedYourAvatar' }),
    );
  };

  const onChangeNickname = async (data) => {
    await changeNickname(data).unwrap();

    toast.success(
      intl.formatMessage({ id: 'YouSuccessfullyUpdatedYourNickname' }),
    );
  };

  const onChangePassword = async (data) => {
    await changePassword(data).unwrap();

    toast.success(intl.formatMessage({ id: 'NewPasswordSuccessfullyChanged' }));

    navigate(ACCOUNT_ROUTES.PROFILE);
  };

  const onDisableOtp = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await disableOtp(body).unwrap();

    toast.success(intl.formatMessage({ id: 'GoogleAuthenticationDisabled' }));

    navigateBack();
  };

  const onEnableOtp = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await enableOtp(body).unwrap();

    toast.success(intl.formatMessage({ id: 'GoogleAuthenticationEnabled' }));

    navigateBack();
  };

  const onBindPhoneNumber = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    try {
      await bindPhoneNumber(body).unwrap();

      toast.success(intl.formatMessage({ id: 'SMSAuthenticationEnabled' }));

      navigateBack();
    } catch (e) {
      console.log(e);
    }
  };

  const onUnbindPhoneNumber = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    try {
      await unbindPhoneNumber(body).unwrap();

      toast.success(intl.formatMessage({ id: 'SMSAuthenticationDisabled' }));

      navigateBack();
    } catch (e) {
      console.log(e);
    }
  };

  const onSetTradePassword = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await setTradePassword(body).unwrap();

    toast.success(intl.formatMessage({ id: 'TradePasswordIsEnabled' }));

    navigateBack();
  };

  const onDisableTradePassword = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    try {
      await disableTradePassword(body).unwrap();

      toast.success(intl.formatMessage({ id: 'TradePasswordIsDisabled' }));

      navigateBack();
    } catch (error) {
      console.log(error);
    }
  };

  const onSetAntiPhishingCode = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await setAntiPhishingCode(body).unwrap();

    toast.success(intl.formatMessage({ id: 'AntiPhishingCodeEnabled' }));

    navigateBack();
  };

  const onDisableAntiPhishingCode = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    await disableAntiPhishingCode(body).unwrap();

    toast.success(intl.formatMessage({ id: 'AntiPhishingCodeDisabled' }));

    navigateBack();
  };

  const onKybSendAccountInformation = async (data) => {
    try {
      await kybSendAccountInformation(data).unwrap();
      toast.success(
        intl.formatMessage({ id: 'KYBAccountInformationSentSuccess' }),
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  const onKybSendRegistrationAddress = async (data) => {
    try {
      await kybSendRegistrationAddress(data).unwrap();
      toast.success(
        intl.formatMessage({ id: 'KYBRegistrationAddressSentSuccess' }),
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  const onKybSendOperationalAddress = async (data) => {
    try {
      await kybSendOperationalAddress(data).unwrap();
      toast.success(
        intl.formatMessage({ id: 'KYBOperationalAddressSentSuccess' }),
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  const onKybSendSourceOfFunds = async (data) => {
    try {
      await kybSendSourceOfFunds(data).unwrap();
      toast.success(intl.formatMessage({ id: 'KYBSourceOfFundsSentSuccess' }));
    } catch (e) {
      throw new Error(e);
    }
  };

  const onKybSendAdditionalInformation = async (data) => {
    try {
      await kybSendAdditionalInformation(data).unwrap();
      toast.success(
        intl.formatMessage({ id: 'KYBAdditionalInformationSentSuccess' }),
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  const onKybUploadDocCertificateOfIncorporation = async (data) => {
    try {
      await kybUploadDocCertificateOfIncorporation(data).unwrap();

      toast.success(
        intl.formatMessage({
          id: 'KYBUploadDocCertificateOfIncorporationSuccess',
        }),
      );
    } catch (e) {
      throw new Error(e);
    }
  };
  const onKybUploadDocMemorandum = async (data) => {
    try {
      await kybUploadDocMemorandum(data).unwrap();

      toast.success(
        intl.formatMessage({ id: 'KYBUploadDocMemorandumSuccess' }),
      );
    } catch (e) {
      throw new Error(e);
    }
  };
  const onKybUploadDocDirectors = async (data) => {
    try {
      await kybUploadDocDirectors(data).unwrap();

      toast.success(intl.formatMessage({ id: 'KYBUploadDocDirectorsSuccess' }));
    } catch (e) {
      throw new Error(e);
    }
  };
  const onKybUploadDocMembers = async (data) => {
    try {
      await kybUploadDocMembers(data).unwrap();

      toast.success(intl.formatMessage({ id: 'KYBUploadDocMembersSuccess' }));
    } catch (e) {
      throw new Error(e);
    }
  };
  const onKybUploadDocOwnership = async (data) => {
    try {
      await kybUploadDocOwnership(data).unwrap();

      toast.success(intl.formatMessage({ id: 'KYBUploadDocOwnershipSuccess' }));
    } catch (e) {
      throw new Error(e);
    }
  };
  const onKybUploadDocSanctions = async (data) => {
    try {
      await kybUploadDocSanctions(data).unwrap();

      toast.success(intl.formatMessage({ id: 'KYBUploadDocSanctionsSuccess' }));
    } catch (e) {
      throw new Error(e);
    }
  };

  return {
    onSendSMS,
    isSendSMSLoading,
    onKycCustomerRegistration,
    isKycCustomerRegistrationLoading,
    onKycCustomerVerification,
    isKycCustomerVerificationLoading,
    onKycPaymentDocumentVerification,
    isKycPaymentDocumentVerificationLoading,
    onChangeAvatar,
    isChangeAvatarLoading,
    onChangeNickname,
    isChangeNicknameLoading,
    onChangePassword,
    isChangePasswordLoading,
    onDisableOtp,
    isDisableOtpLoading,
    onEnableOtp,
    isEnableOtpLoading,
    onBindPhoneNumber,
    isBindPhoneNumberLoading,
    onUnbindPhoneNumber,
    isUnbindPhoneNumberLoading,
    onSetTradePassword,
    isSetTradePasswordLoading,
    onDisableTradePassword,
    isDisableTradePasswordLoading,
    onGetCode,
    isLoadingGetCode,
    onSetAntiPhishingCode,
    isSetAntiPhishingCodeLoading,
    onDisableAntiPhishingCode,
    isDisableAntiPhishingCodeLoading,
    onKybSendAccountInformation,
    isKybSendAccountInformationLoading,
    onKybSendRegistrationAddress,
    isKybSendRegistrationAddressLoading,
    onKybSendOperationalAddress,
    isKybSendOperationalAddressLoading,
    onKybSendSourceOfFunds,
    isKybSendSourceOfFundsLoading,
    onKybSendAdditionalInformation,
    isKybSendAdditionalInformationLoading,
    onKybUploadDocCertificateOfIncorporation,
    isKybUploadDocCertificateOfIncorporationLoading,
    onKybUploadDocMemorandum,
    isKybUploadDocMemorandumLoading,
    onKybUploadDocDirectors,
    isKybUploadDocDirectorsLoading,
    onKybUploadDocMembers,
    isKybUploadDocMembersLoading,
    onKybUploadDocOwnership,
    isKybUploadDocOwnershipLoading,
    onKybUploadDocSanctions,
    isKybUploadDocSanctionsLoading,
  };
};

export default useProfileMutations;
