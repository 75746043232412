import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { Button } from '@shared/ui';

import s from './FileUploaderImagePreview.module.scss';

const FileUploaderImagePreview = ({ onRemoveFile, file }) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    if (file) setSrc(URL.createObjectURL(file));
  }, [file]);

  return (
    <div className={classNames(s.preview)}>
      <img className={s.preview__image} src={src} alt={file?.name} />

      <div className="d-flex justify-content-center">
        <Button
          customStyles={s}
          type="button"
          variant="text"
          size="l"
          onClick={onRemoveFile}
        >
          <FormattedMessage id="Delete" />
        </Button>
      </div>
    </div>
  );
};

export default FileUploaderImagePreview;
