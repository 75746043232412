import React, { memo, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { LandingPageSwiper } from '@pages/landings/components';
import defaultProductSlides from '@pages/landings/components/ProductsSwiper/defaultProductSlides';
import { ProductSwiperSlide } from '@pages/landings/components/ProductsSwiper/ProductSwiperSlide';

import useBreakpoints from '@hooks/useBreakpoints';

const ProductsSwiper = ({
  slides,
  customSlideStyles,
  customSpaceBetween,
  ...props
}) => {
  const { isLaptopDown, isTabletDown, isLargeTabletDown, isMobile } =
    useBreakpoints();

  const slideStyles = useMemo(() => {
    let width = 535,
      height = 750;

    if (isLaptopDown) {
      width = 410;
      height = 602;
    }

    if (isTabletDown) {
      width = 420;
      height = 532;
    }

    if (isLargeTabletDown) {
      width = 360;
      height = 532;
    }

    if (isMobile) {
      width = 332;
      height = 502;
    }

    return { width, height };
  }, [isLaptopDown, isTabletDown, isLargeTabletDown, isMobile]);

  const slidesMemo = useMemo(() => {
    if (isEmpty(slides)) {
      return defaultProductSlides(isMobile, isLargeTabletDown);
    }

    return slides;
  }, [slides, isMobile, isLargeTabletDown]);

  const spaceBetween = isMobile ? 16 : 40;

  return (
    <>
      <LandingPageSwiper
        slides={slidesMemo}
        SlideComponent={ProductSwiperSlide}
        slideStyles={customSlideStyles ?? slideStyles}
        slidesPerView="auto"
        spaceBetween={customSpaceBetween ?? spaceBetween}
        isShowScrollBar
        isWithSwiperCursor
        withExpandableSlides={!isMobile}
        {...props}
      />
    </>
  );
};

export default memo(ProductsSwiper);
