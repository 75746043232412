import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import styles from './MobileSecurityIndex.module.scss';

const MobileSecurityIndex = ({
  linkTo,
  isActive,
  onClick,
  shortTitle,
  title,
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <div onClick={onClick} className={styles.index_children}>
      <div className={styles.index_title}>
        <span className={classNames(styles.title)}>
          <FormattedMessage id={isMobile ? shortTitle : title} />
        </span>

        <div className={styles.security_status}>
          <FormattedMessage id={isActive ? 'Enabled' : 'Disabled'} />
        </div>
      </div>

      <DownIconSVG className={styles.arrow} height={8} width={16} to={linkTo} />
    </div>
  );
};

export default memo(MobileSecurityIndex);
