import { useSelector } from 'react-redux';

import { useCoinsRates, useGetReferralQueryResult } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

const useReferralsTotalEarned = (queryParams, coin) => {
  const { value: currency } = useSelector(currencySelector);

  const { totalRevenue: totalEarnedInPRDX } =
    useGetReferralQueryResult(queryParams);
  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: coin,
    toCoinSymbol: currency,
  });

  const totalEarnedInSelectedCurrency = totalEarnedInPRDX * ratesByPair;

  return { currency, totalEarnedInPRDX, totalEarnedInSelectedCurrency };
};

export default useReferralsTotalEarned;
