import { ALL_PAIR_FILTER_KEY } from './tradingBots.constants';

export function isEqualPair(firstPair, secondPair) {
  let isEqualPairFrom = false;
  let isEqualPairTo = false;

  if (firstPair.pairFrom === ALL_PAIR_FILTER_KEY || !firstPair.pairFrom) {
    isEqualPairFrom = true;
  }

  if (firstPair.pairTo === ALL_PAIR_FILTER_KEY || !secondPair.pairTo) {
    isEqualPairTo = true;
  }

  if (firstPair.pairTo === secondPair.pairTo) {
    isEqualPairTo = true;
  }

  if (firstPair.pairFrom === secondPair.pairFrom) {
    isEqualPairFrom = true;
  }

  return isEqualPairFrom && isEqualPairTo;
}

export const TradingBotsUtils = {
  isEqualPair,
};
