import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { isNil } from 'lodash';

import { BuyCryptoButton } from '@shared/components';

import Link from '@components/Link';

import styles from './AccountSideBar.module.scss';

const AccountSideBar = ({
  sidebarRoutes = [],
  isShowBuyCryptoButton = true,
}) => {
  const intl = useIntl();

  return (
    <nav className={styles.side_bar}>
      <ul>
        {sidebarRoutes.map((routeInfo, index) =>
          routeInfo ? (
            <li key={`${routeInfo.route}-${index}`}>
              <Link to={routeInfo.route} activeClassName={styles.active_item}>
                <span className={styles.nav_item_text}>
                  {intl.formatMessage({ id: routeInfo.titleId })}
                  {!isNil(routeInfo.counter) && ` (${routeInfo.counter})`}
                </span>
              </Link>
            </li>
          ) : (
            <hr key={index} />
          ),
        )}
      </ul>

      {isShowBuyCryptoButton && <BuyCryptoButton />}
    </nav>
  );
};

export default memo(AccountSideBar);
