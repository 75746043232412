import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';

import {
  CopyField,
  DownloadFromStoreButton,
  CustomQRCode,
} from '@shared/components';
import {
  GOOGLE_AUTH_APP_STORE_LINK,
  GOOGLE_AUTH_PLAY_MARKET_LINK,
} from '@shared/constants';
import { useNavigateBack } from '@shared/hooks';
import { Button, ButtonArrow } from '@shared/ui';
import { ARROW_DIRECTION } from '@shared/ui/ButtonArrow/ButtonArrow';

import { AccountFooter } from '@pages/AccountPage/components/AccountFooter';

import { useGetGoogleCodeQuery } from '@store/api/profileAPI';
import { userEmailSelector } from '@store/settings/settingsSelectors';

import { useProfileMutations } from '@api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import { ReactComponent as AppStoreSvg } from '@images/icons/apple-icon.svg';
import { ReactComponent as PlayMarketSvg } from '@images/icons/play-market-icon.svg';

import { GoogleAuthCard } from '../GoogleAuthCard';
import { OtpForm } from '../OtpForm';

import styles from './EnableGoogleAuth.module.scss';

const EnableGoogleAuth = () => {
  const { data: googleCode } = useGetGoogleCodeQuery();

  const { openModal } = useModal(modalsIds.QR_CODE_MODAL);

  const userEmail = useSelector(userEmailSelector);

  const methods = useForm({ defaultValues: { code: '', loginPassword: '' } });

  const {
    formState: { isValid },
    handleSubmit,
  } = methods;

  const { isMobile, isTabletDown, isLaptopUp } = useBreakpoints();

  const qrCodeSize = isTabletDown ? 125 : 190;

  const { onEnableOtp, isEnableOtpLoading } = useProfileMutations();

  const navigateBack = useNavigateBack();

  const handleFormSubmit = (values) => {
    onEnableOtp({ secretKey: googleCode, ...values });
  };

  const googleAuthCode = `otpauth://totp/${userEmail}?secret=${googleCode}&issuer=ParamountDax`;

  const copyFieldComponent = (
    <CopyField
      value={googleCode}
      isTruncatedValue={false}
      label={
        <>
          <FormattedMessage id="CopyCode" />:
        </>
      }
    />
  );

  return (
    <div className={styles.security_google_auth}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={styles.cards}>
            <GoogleAuthCard
              index="1"
              title={<FormattedMessage id="InstallGoogleAuth" />}
            >
              <div className={styles.install_google_auth}>
                <div className={styles.qr_code_container}>
                  <div className={styles.label}>
                    <FormattedMessage id="IosApp" />
                  </div>
                  <div
                    className={cx(styles.qr_code, styles.qr_code_install_app)}
                  >
                    <CustomQRCode
                      size={qrCodeSize}
                      value={GOOGLE_AUTH_APP_STORE_LINK}
                    />
                  </div>
                  <DownloadFromStoreButton
                    navigation={{ to: GOOGLE_AUTH_APP_STORE_LINK }}
                    icon={<AppStoreSvg />}
                    text={<FormattedMessage id="DownloadOnThe" />}
                    subText={<FormattedMessage id="AppStore" />}
                  />
                  {isMobile && (
                    <Button
                      onClick={() =>
                        openModal({
                          value: GOOGLE_AUTH_APP_STORE_LINK,
                          title: <FormattedMessage id="AppStore" />,
                        })
                      }
                      variant="text"
                      type="button"
                    >
                      <FormattedMessage id="ShowQRCode" />
                    </Button>
                  )}
                </div>
                <div className={styles.qr_code_container}>
                  <div className={styles.label}>
                    <FormattedMessage id="AndroidApp" />
                  </div>
                  <div
                    className={cx(styles.qr_code, styles.qr_code_install_app)}
                  >
                    <CustomQRCode
                      size={qrCodeSize}
                      value={GOOGLE_AUTH_PLAY_MARKET_LINK}
                    />
                  </div>
                  <DownloadFromStoreButton
                    navigation={{ to: GOOGLE_AUTH_PLAY_MARKET_LINK }}
                    icon={<PlayMarketSvg />}
                    text={<FormattedMessage id="GetItOn" />}
                    subText={<FormattedMessage id="GooglePlay" />}
                  />

                  {isMobile && (
                    <Button
                      onClick={() =>
                        openModal({
                          value: GOOGLE_AUTH_PLAY_MARKET_LINK,
                          title: <FormattedMessage id="AppStore" />,
                        })
                      }
                      variant="text"
                      type="button"
                    >
                      <FormattedMessage id="ShowQRCode" />
                    </Button>
                  )}
                </div>
              </div>
            </GoogleAuthCard>

            <GoogleAuthCard
              index="2"
              title={<FormattedMessage id="ScanQrCode" />}
            >
              <div className={styles.google_qr_code_container}>
                <div className={styles.google_qr_code}>
                  <div className={styles.label}>
                    <div>
                      <FormattedMessage id="QrCode" />
                    </div>

                    {isMobile && (
                      <Button
                        onClick={() =>
                          openModal({
                            value: googleAuthCode,
                            title: <FormattedMessage id="SecretKeyQr" />,
                          })
                        }
                        variant="text"
                        type="button"
                      >
                        <FormattedMessage id="ShowQRCode" />
                      </Button>
                    )}
                  </div>
                  <div className={styles.qr_code}>
                    {googleCode && (
                      <CustomQRCode size={qrCodeSize} value={googleAuthCode} />
                    )}
                  </div>
                </div>
                <div className={styles.description}>
                  <FormattedMessage id="QrCodeDescription" />
                  {isLaptopUp && copyFieldComponent}
                </div>
              </div>
              {isTabletDown && copyFieldComponent}
            </GoogleAuthCard>

            <GoogleAuthCard
              index="3"
              title={<FormattedMessage id="BackupTheSecretKey" />}
            >
              <div className={styles.backup_the_secret_key}>
                <div className={styles.label}>
                  <FormattedMessage id="BackupTheSecretKeyDescription" />
                </div>
                <CopyField
                  value={googleCode}
                  isTruncatedValue={false}
                  label={
                    <>
                      <FormattedMessage id="SecretCode" />:
                    </>
                  }
                />
              </div>
            </GoogleAuthCard>

            <GoogleAuthCard
              index="4"
              title={<FormattedMessage id="EnableGoogleAuthenticator" />}
            >
              <OtpForm />
            </GoogleAuthCard>
          </div>
          <AccountFooter>
            <div className={styles.footer}>
              <ButtonArrow
                type="button"
                fill={isMobile}
                onClick={navigateBack}
                variant="text"
                color="primary"
                direction={ARROW_DIRECTION.LEFT}
                size="l"
              >
                <FormattedMessage id="Back" />
              </ButtonArrow>

              <Button
                type="submit"
                fill={isMobile}
                isLoading={isEnableOtpLoading}
                disabled={isEnableOtpLoading || !isValid}
                color="secondary"
                size="l"
              >
                <div className="text-nowrap">
                  <FormattedMessage id="SubmitAndEnable" />
                </div>
              </Button>
            </div>
          </AccountFooter>
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(EnableGoogleAuth);
