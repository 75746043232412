import { createApi } from '@reduxjs/toolkit/query/react';
import omit from 'lodash/omit';

import client from '@api/client';

import { getFormData } from '@utils/api';

export const managementApiKeyAPI = createApi({
  reducerPath: 'managementApiKeyAPI',
  baseQuery: client,
  tagTypes: ['api-keys'],
  endpoints: (builder) => ({
    postGenerateApiKey: builder.mutation({
      query: (body) => ({
        url: '/apikeys-v2',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['api-keys'],
    }),
    addIpsToApiKeyWhitelist: builder.mutation({
      query: (body) => ({
        url: `/apikeys-v2/allowed-ip?otp_code=${body.otp_code}`,
        method: 'POST',
        body: omit(body, 'otp_code'),
      }),
      invalidatesTags: ['api-keys'],
    }),
    deleteIpsFromApiKeyWhitelist: builder.mutation({
      query: (body) => ({
        url: `/apikeys-v2/allowed-ip?otp_code=${body.otp_code}`,
        method: 'DELETE',
        body: omit(body, 'otp_code'),
      }),
      invalidatesTags: ['api-keys'],
    }),
    getApiKeys: builder.query({
      query: () => '/apikeys-v2',
      providesTags: ['api-keys'],
    }),
    deleteApiKey: builder.mutation({
      query: ({ id, otp_code }) => ({
        url: `/apikeys-v2?apikey=${id}&otp_code=${otp_code}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['api-keys'],
    }),
    getAPIKeyPermissions: builder.query({
      query: () => '/apikeys-v2/permissions',
      providesTags: ['api-keys'],
    }),
  }),
});

export const {
  usePostGenerateApiKeyMutation,
  useAddIpsToApiKeyWhitelistMutation,
  useDeleteIpsFromApiKeyWhitelistMutation,
  useGetApiKeysQuery,
  useDeleteApiKeyMutation,
  useGetAPIKeyPermissionsQuery,
} = managementApiKeyAPI;
