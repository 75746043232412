import React, { memo } from 'react';

import BigNumber from 'bignumber.js';
import classNames from 'classnames';

import { PersonalFormattedPercent } from '@shared/components';

import { PIE_CHART_COLORS } from '@pages/WalletsPage/Staking/staking.constants';

import { PERCENT_PRECISION } from '@constants';

import styles from './StakedItem.module.scss';

const Records = ({ item, index, percent }) => {
  const isOthersItems = item.label === 'Others';

  return (
    <div
      className="d-flex align-items-center justify-content-between"
      key={item.label}
    >
      <div className="d-flex align-items-center justify-content-between gap-16 gap-xl-24">
        <div
          className={styles.icon}
          style={{
            backgroundColor: PIE_CHART_COLORS[index],
          }}
        />

        <div
          className={classNames(styles.coin, {
            'text-capitalize': isOthersItems,
          })}
        >
          {item.label}
        </div>

        <div className={styles.name}>{item.coinFullname}</div>
      </div>

      <div className={styles.percent}>
        <PersonalFormattedPercent
          percent={percent}
          precision={PERCENT_PRECISION}
          roundingMode={BigNumber.ROUND_HALF_UP}
        />
        %
      </div>
    </div>
  );
};

export default memo(Records);
