import React, { memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { createColumnHelper } from '@tanstack/react-table';
import { useFormContext, useWatch } from 'react-hook-form';

import Table from '@components/gridTables/Table';

import { useWithdrawCurrencyFee } from '@pages/WalletsPage/hooks';
import {
  checkoutWithdrawDataSelector,
  withdrawCurrencySymbolSelector,
  withdrawPaymentMethodSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';
import { walletWithdrawActions } from '@pages/WalletsPage/Withdraw/Withdraw.slice';

import { useCoinsInfo } from '@store/api/hooks';

import { format } from '@utils/numbers';

import styles from './CoinCheckoutTable.module.scss';

const CoinCheckoutTable = () => {
  const intl = useIntl();
  const { control } = useFormContext();

  const watchAmount = useWatch({ control, name: 'amount' });

  const activePaymentMethod = useSelector(withdrawPaymentMethodSelector);
  const checkoutWithdrawData = useSelector(checkoutWithdrawDataSelector);
  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);

  const dispatch = useDispatch();

  const { coinInfo: currentCoinInfo } = useCoinsInfo({
    coinSymbol: selectedCurrencySymbol,
  });

  const precision = currentCoinInfo?.digits;

  const fee = useWithdrawCurrencyFee(
    activePaymentMethod,
    selectedCurrencySymbol,
  );

  const feeFormatted = format(fee, { precision });

  const profitFormatted = useMemo(
    () =>
      format(Number(watchAmount) >= fee ? Number(watchAmount) - fee : 0, {
        precision,
      }),
    [watchAmount, feeFormatted, precision],
  );

  useEffect(() => {
    const { fee: savedFee, profit: savedProfit } = checkoutWithdrawData;

    const currentProfit = Number(watchAmount) - fee;

    if (fee !== savedFee) {
      dispatch(walletWithdrawActions.setCheckoutWithdrawData({ fee }));
    }

    if (currentProfit !== savedProfit) {
      dispatch(
        walletWithdrawActions.setCheckoutWithdrawData({
          profit: currentProfit,
        }),
      );
    }
  }, [checkoutWithdrawData, fee, watchAmount]);

  const data = useMemo(
    () => [{ withdrawFee: feeFormatted, withdrawProfit: profitFormatted }],
    [feeFormatted, profitFormatted],
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('withdrawFee', {
        header: () => intl.formatMessage({ id: 'TransactionFee' }),
        cell: (info) => (
          <span className={styles.bold_text}>{info.getValue()}</span>
        ),
        enableSorting: false,
        headerAlign: 'start',
        cellAlign: 'start',
      }),
      columnHelper.accessor('withdrawProfit', {
        header: () => intl.formatMessage({ id: 'YouWillGet' }),
        cell: (info) => (
          <span className={styles.bold_text}>{info.getValue()}</span>
        ),
        enableSorting: false,
        headerAlign: 'start',
        cellAlign: 'start',
      }),
    ],
    [selectedCurrencySymbol],
  );

  return <Table wideRows data={data} columns={columns} />;
};

export default memo(CoinCheckoutTable);
