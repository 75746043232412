import cx from 'classnames';

import { CardMenuActions } from './CardMenuActions';

import styles from './Card.module.scss';

const Card = ({
  children,
  title,
  color = 'primary',
  classNames = [],
  details,
  actions,
  titleDetails,
  customStyles = {},
  ...props
}) => {
  return (
    <div
      className={cx(
        'v2-card',
        styles.card,
        styles[color],
        ...classNames,
        customStyles.card,
      )}
      {...props}
    >
      {title && (
        <div className={cx(styles.card_title, customStyles.card_title)}>
          {title}
        </div>
      )}
      {titleDetails}
      {actions && (
        <CardMenuActions customStyles={customStyles} actions={actions} />
      )}
      {children}
      {details}
    </div>
  );
};

export default Card;
