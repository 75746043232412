import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, orderBy } from 'lodash';

import { ApiSelectorsUtils } from '../utils';

const defaultUserLimitLevel = 0;
const defaultData = [];
const defaultDataObject = {};
const defaultWithdrawals = [];
const defaultDepositHistory = [];
const defaultMeta = {};

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);
const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);
const dataObjectSelector =
  ApiSelectorsUtils.createDataSelector(defaultDataObject);
const dataApiObjectSelector =
  ApiSelectorsUtils.createDataApiSelector(defaultDataObject);

const findWithdrawLimitByFeature = (data, feature, userLevel) =>
  Number(data.find((item) => item.feature === feature + userLevel)?.value) ||
  defaultUserLimitLevel;

const userWithdrawLimitSelector = createSelector(
  dataSelector,
  (data, userLevel) => userLevel,
  (data, userLevel, withdrawLimits) => withdrawLimits,
  (data, userLevel, withdrawLimits) => {
    let withdrawLimitBTC = Number(data.withdraw_limit) || defaultUserLimitLevel;
    let withdrawAdvCashLimitUSDT =
      Number(data.adv_cash_withdraw_limit) || defaultUserLimitLevel;
    let withdrawClearJunctionLimitUSDT =
      Number(data.clear_junction_withdraw_limit) || defaultUserLimitLevel;
    let withdrawDefaultLimitUSDT =
      Number(data.default_withdraw_limit) || defaultUserLimitLevel;

    if (withdrawLimitBTC === -1) {
      withdrawLimitBTC = findWithdrawLimitByFeature(
        withdrawLimits,
        'withdraw_limit_level_',
        userLevel,
      );
    }

    if (withdrawAdvCashLimitUSDT === -1) {
      withdrawAdvCashLimitUSDT = findWithdrawLimitByFeature(
        withdrawLimits,
        'adv_cash_withdraw_limit_level_',
        userLevel,
      );
    }

    if (withdrawClearJunctionLimitUSDT === -1) {
      withdrawClearJunctionLimitUSDT = findWithdrawLimitByFeature(
        withdrawLimits,
        'clear_junction_withdraw_limit_level_',
        userLevel,
      );
    }

    if (withdrawDefaultLimitUSDT === -1) {
      withdrawDefaultLimitUSDT = findWithdrawLimitByFeature(
        withdrawLimits,
        'default_withdraw_limit_level_',
        userLevel,
      );
    }

    return {
      withdrawLimitBTC,
      withdrawAdvCashLimitUSDT,
      withdrawClearJunctionLimitUSDT,
      withdrawDefaultLimitUSDT,
    };
  },
);

const withdrawLimitsSelector = createSelector(dataSelector, (data) => {
  const withdrawLimits = data.filter(
    (item) => item.feature.indexOf('withdraw_limit_level_') === 0,
  );

  return orderBy(withdrawLimits, 'feature');
});

const withdrawAddressesSelector = createSelector(dataSelector, (data) => data);

const withdrawalsSelector = createSelector(dataObjectSelector, (data) => {
  return data.withdrawals ?? defaultWithdrawals;
});

const total24hWithdrawSelector = createSelector(dataObjectSelector, (data) => {
  const { advcash, bitgo, clear_junction } = data;

  return {
    advCashUSDT: Number(advcash) || 0,
    bitgoBTC: Number(bitgo) || 0,
    clearJunctionUSDT: Number(clear_junction) || 0,
  };
});

const withdrawFiatFeesSelector = createSelector(dataObjectSelector, (data) => {
  const { adv_cash_fee, clear_junction_fee, default_withdraw_fee } = data;

  return {
    advCashFeeEUR: Number(adv_cash_fee) || 0,
    clearJunctionFeeEUR: Number(clear_junction_fee) || 0,
    defaultWithdrawFeeEUR: Number(default_withdraw_fee) || 0,
  };
});

const getClearJunctionWithdrawSettingsSelector = createSelector(
  dataApiObjectSelector,
  (data) => {
    const settings = {
      methods: [],
      firstName: data.firstName ?? '',
      lastName: data.lastName ?? '',
    };

    if (isEmpty(data)) return settings;

    const { requiredFields, allowedMethods } = data;

    settings.methods = Object.entries(allowedMethods).map(
      ([methodKey, methodTitle]) => ({
        value: methodKey,
        label: methodTitle,
        requiredFields: requiredFields[methodKey],
      }),
    );

    return settings;
  },
);

const depositHistorySelector = createSelector(dataObjectSelector, (data) => {
  return data.transactions ?? defaultDepositHistory;
});

const coinAddressSelector = createSelector(dataObjectSelector, (data) => ({
  address: data.address,
  coin: data.coin,
  id: data.id,
  label: data.name,
  userId: data.user_id,
}));

export const WalletsApiSelectors = {
  dataSelector,
  metaSelector,
  withdrawLimitsSelector,
  userWithdrawLimitSelector,
  withdrawAddressesSelector,
  withdrawalsSelector,
  total24hWithdrawSelector,
  withdrawFiatFeesSelector,
  getClearJunctionWithdrawSettingsSelector,
  depositHistorySelector,
  coinAddressSelector,
};
