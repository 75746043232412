import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressBar } from '@shared/ui';

import { ONE_DAY } from '@pages/WalletsPage/InstantBonus/components/FormattedPeriods/formattedPeriods.constants';
import { useGetDifferenceBetweenDays } from '@pages/WalletsPage/Staking/hooks';

import styles from './RemainingDaysItem.module.scss';

const RemainingDaysItem = ({ remainingDays, durationDays }) => {
  const {
    totalDays,
    elapsedDays,
    percentElapsedDays,
    startDateInMs,
    todayDateInMs,
  } = useGetDifferenceBetweenDays(remainingDays, durationDays);

  const passedDay = todayDateInMs > startDateInMs ? ONE_DAY : 0;

  return (
    <div className={styles.container}>
      <span className={styles.text}>
        {elapsedDays >= ONE_DAY ? elapsedDays : passedDay}{' '}
        <FormattedMessage id="From" />{' '}
        {totalDays < ONE_DAY ? ONE_DAY : totalDays}{' '}
        {totalDays < ONE_DAY ? (
          <FormattedMessage id="OneDay" />
        ) : (
          <FormattedMessage id="Days" />
        )}
      </span>

      <ProgressBar
        height={4}
        percentages={percentElapsedDays === 0 ? 1 : percentElapsedDays}
        isShowPercents={false}
        customStyles={styles}
        isPill
      />
    </div>
  );
};

export default memo(RemainingDaysItem);
