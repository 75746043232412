import { useMemo, useState } from 'react';

import { debounce } from 'lodash';

import Button from '@components/Button/Button';
import Input from '@components/Input/Input';

import { NOOP } from '@constants';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';
import { ReactComponent as SearchIconSVG } from '@icons/search-icon.svg';

import styles from './SearchInput.module.scss';

const SearchInput = ({ onSearchValueChange = NOOP }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchValueChange = (value) => {
    setSearchValue(value);
    debouncedOnSearchValueChange(value);
  };

  const debouncedOnSearchValueChange = useMemo(
    () =>
      debounce((value) => {
        onSearchValueChange(value.trim().toLowerCase());
      }, 300),
    [onSearchValueChange],
  );

  return (
    <Input
      customStyles={styles}
      onChange={handleSearchValueChange}
      value={searchValue}
      suffix={
        searchValue ? (
          <Button
            className={styles.suffix}
            customStyles={styles}
            onClick={() => handleSearchValueChange('')}
          >
            <CrossThinIconSVG className={styles.icon} width={18} height={18} />
          </Button>
        ) : (
          <div className={styles.suffix}>
            <SearchIconSVG className={styles.icon} />
          </div>
        )
      }
      rounded={false}
    />
  );
};

export default SearchInput;
