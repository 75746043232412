import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PercentChangeChart } from '@shared/components';
import { CRYPTO_CURRENCIES } from '@shared/constants';
import { useValueColor } from '@shared/hooks';

import Loader from '@components/Loader/Loader';

import { ALL_MARKETS_TO_DATE } from '@pages/landings/landings.constants';
import { AllCryptoMarketsSelectors } from '@pages/landings/MarketsPage/AllCryptoMarkets/allCryptoMarkets.selectors';

import { useCandles } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as ArrowRightSideSVG } from '@icons/arrow-right-side-icon.svg';
import { ReactComponent as ArrowRightSideShortSVG } from '@icons/arrow-right-side-short-icon.svg';

import styles from './PriceChartCell.module.scss';

const PriceChartCell = ({ currency, percentChange, isShowChart }) => {
  const { isLaptopDown, isTabletDown } = useBreakpoints();

  const selectedTimeFrameDate = useSelector(
    AllCryptoMarketsSelectors.selectedTimeFrameDateSelector,
  );

  const { color } = useValueColor(percentChange);

  const { candles, isCandlesFetching } = useCandles({
    symbol: `${currency}${CRYPTO_CURRENCIES.USDT}`,
    from: selectedTimeFrameDate,
    to: ALL_MARKETS_TO_DATE,
    limit: 24,
    interval: 1800,
  });

  const data = useMemo(() => {
    if (!candles?.c) {
      return [];
    }

    return candles.c.map((currentPrice, index) => ({
      value: currentPrice,
      date: candles.t[index],
    }));
  }, [candles]);

  const chartWidth = useMemo(() => {
    let width = 120;
    if (isLaptopDown) width = 100;

    return width;
  }, [isLaptopDown]);

  return (
    <div className={styles.price}>
      {isShowChart && (
        <>
          {isCandlesFetching ? (
            <Loader s />
          ) : (
            <PercentChangeChart
              color={color}
              data={data}
              width={chartWidth}
              height={25}
            />
          )}
        </>
      )}

      {!isTabletDown && (
        <div className={styles.icon}>
          <ArrowRightSideSVG className="price-chart-cell-arrow" />
          <ArrowRightSideShortSVG className="price-chart-cell-arrow-short" />
        </div>
      )}
    </div>
  );
};

export default memo(PriceChartCell);
