import { isToday } from 'date-fns';

import { NOTIFICATION_STATUS } from '@constants';

export const sortNotification = (notifications = []) => {
  const { todayNotifications, earlierNotifications } = notifications.reduce(
    (all, notification) => {
      if (isToday(new Date(notification.created_at))) {
        return {
          ...all,
          todayNotifications: [...all.todayNotifications, notification],
        };
      }

      return {
        ...all,
        earlierNotifications: [...all.earlierNotifications, notification],
      };
    },
    { todayNotifications: [], earlierNotifications: [] },
  );

  return { todayNotifications, earlierNotifications };
};

export const getUnreadNotificationIds = (notifications) => {
  return notifications
    .filter(
      (notification) => notification.status === NOTIFICATION_STATUS.UNREAD,
    )
    .map((notification) => notification.id);
};
