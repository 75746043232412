import React, { memo } from 'react';

import classnames from 'classnames';

import MobileTradingBlock from '@pages/TerminalPage/components/GridLayout/blocks/Trade/components/MobileTradingBlock';

import useBreakpoints from '@hooks/useBreakpoints';

import TerminalHeader from '../TerminalHeader';

import PrimaryHeader from './components/PrimaryHeader';
import SecondaryFooter from './components/SecondaryFooter';

const MainLayout = ({
  children,
  className,
  withTerminalFeatures = false,
  secondaryFooterClassName = '',
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <div className={classnames('page-container', className)}>
      <PrimaryHeader withTerminalFeatures={withTerminalFeatures} />

      {withTerminalFeatures && <TerminalHeader />}

      <div id="mobile-chart-container" />

      <div className="main-layout scrollable-layout">
        {children}

        {withTerminalFeatures && isMobile && <MobileTradingBlock />}

        <SecondaryFooter
          className={secondaryFooterClassName}
          withTerminalFeatures={withTerminalFeatures}
        />
      </div>
    </div>
  );
};

export default memo(MainLayout);
