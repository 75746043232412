import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TooltipHelpIcon from '@components/TooltipHelpIcon/TooltipHelpIcon';

import styles from './ProgressHeaderItem.module.scss';

const ProgressHeaderItem = () => {
  const intl = useIntl();

  return (
    <span className={styles.container}>
      <span className="me-4">
        <FormattedMessage id="Progress" />
      </span>

      <TooltipHelpIcon
        className={styles.info_icon}
        content={`${intl.formatMessage({
          id: 'Filled',
        })} / ${intl.formatMessage({ id: 'AccruedBonus' })}`}
      />
    </span>
  );
};

export default memo(ProgressHeaderItem);
