import { useEffect, useState } from 'react';

const useCursorCoordinates = ({ isActive = true }) => {
  const [cursorCoordinates, setCursorCoordinates] = useState({
    x: null,
    y: null,
  });

  const handleMouseMove = (e) => {
    setCursorCoordinates({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    if (isActive) {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
    }

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [isActive]);

  return cursorCoordinates;
};

export default useCursorCoordinates;
