import { useIntl } from 'react-intl';

import cx from 'classnames';

import { Button } from '@shared/ui';
import { copyToClipboard } from '@shared/utils';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as CopyIconSVG } from '@icons/copy-icon.svg';

import s from './RequisiteList.module.scss';

const RequisiteList = ({
  requisites = [],
  inversion,
  oddRowsHighlighted = true,
}) => {
  const intl = useIntl();

  const { isMobile } = useBreakpoints();

  const handleCopy = async (value) => {
    await copyToClipboard({ intl, text: value });
  };

  return (
    <ul className={cx(s.requisite_list, { [s.inversion]: inversion })}>
      {requisites.map((detail) => (
        <li
          key={detail.id}
          className={cx(s.row, {
            [s.row__highlighted]: oddRowsHighlighted,
          })}
        >
          <div>
            <div className={s.label}>{detail.label}</div>
            {isMobile && <p className={s.value}>{detail.value}</p>}
          </div>
          <div className="d-flex align-items-center gap-22">
            {!isMobile && <p className={s.value}>{detail.value}</p>}
            <Button
              type="button"
              variant="text"
              size="xxs"
              onClick={() => handleCopy(detail.value)}
            >
              <CopyIconSVG className={s.copy_icon} />
            </Button>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default RequisiteList;
