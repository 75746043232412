import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import classnames from 'classnames';

import GridTableDefault from '@components/gridTables/TableDefault';

import { useToggleFavouritePairMutation } from '@store/api/profileAPI';
import {
  isGuestUserSelector,
  localeSelector,
} from '@store/settings/settingsSelectors';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';

import { NOOP } from '@constants';
import { BLOCK_BREAKPOINTS } from '@constants/blockBreakpoints';
import { BLOCK_TYPES } from '@constants/blockTypes';

import marketsColumns from './components/columns';

import { MAIN_ROUTES } from 'src/routes.constants';

import styles from './TabContent.module.scss';

const TabContent = ({
  layoutItemRef,
  withoutHeader,
  data,
  setIsOpen = NOOP,
}) => {
  const [toggleFavouritePair] = useToggleFavouritePairMutation();

  const intl = useIntl();
  const navigate = useNavigate();

  const isGuest = useSelector(isGuestUserSelector);
  const locale = useSelector(localeSelector);

  const { isMiniX, isStandardX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.markets],
  );

  const columns = useMemo(
    () => marketsColumns(isMiniX, isStandardX, isGuest, toggleFavouritePair),
    [isMiniX, isStandardX, isGuest],
  );

  const handleCoinPairClick = ({ original }) => {
    const marketId = original.favourite_info.marketId;

    navigate(generatePath(MAIN_ROUTES.TERMINAL, { marketId }));

    setIsOpen(false);
  };

  const tableData = useMemo(() => {
    return data.map((item) => {
      const rowData = item.row_data || {};

      return {
        ...item,
        row_data: { isClickable: true, ...rowData },
      };
    });
  }, [data]);

  const noDataProps = useMemo(
    () => ({
      showIsGuest: true,
      guestLabel: intl.formatMessage({ id: 'ToAddFavorites' }),
    }),
    [locale],
  );

  return (
    <div
      className={classnames(styles.tab_content__table, {
        [styles.tab_content__table__without_header]: withoutHeader,
      })}
    >
      <GridTableDefault
        id="markets-block-table"
        onRowClick={handleCoinPairClick}
        columns={columns}
        data={tableData}
        noDataProps={noDataProps}
      />
    </div>
  );
};

export default memo(TabContent);
