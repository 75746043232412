import { MarketItem } from '@shared/components/MarketPairSelect/components';

export const transformToMarketOptions = ({ markets, inversion, ...props }) =>
  markets.map(({ id, market_coin_symbol, quote_coin_symbol, name }) => ({
    label: (
      <MarketItem
        inversion={inversion}
        marketCoinSymbol={market_coin_symbol}
        quoteCoinSymbol={quote_coin_symbol}
        markets={markets}
        {...props}
      />
    ),
    value: id,
    marketCoinSymbol: market_coin_symbol,
    quoteCoinSymbol: quote_coin_symbol,
    name,
  }));
