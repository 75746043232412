import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import {
  InfoWidget,
  PercentChangeChart,
  PersonalData,
  PersonalPercentChange,
  Widget,
} from '@shared/components';

import { useChartData } from '@pages/AccountPage/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './BotsProfitWidget.module.scss';
import mockData from 'src/mock.json';

const BotsProfitWidget = () => {
  const { profit, percent, dailyFilled, constantOpen } =
    mockData.BotsProfitWidget;

  const { color, chartData } = useChartData();

  const { isTabletDown } = useBreakpoints();

  return (
    <Widget className={styles.visa_card_widget}>
      <div className="d-flex">
        <InfoWidget
          customStyles={styles}
          title={<FormattedMessage id="BotsProfit" />}
          value={<PersonalData data={profit} />}
          suffix={
            <span className={classNames(styles.suffix, 'percent-suffix')}>
              %
            </span>
          }
        />

        <div
          className={classNames(
            'align-self-center me-16',
            isTabletDown ? 'mt-4' : 'mt-6',
          )}
        >
          <PersonalData
            data={
              <PercentChangeChart
                width={isTabletDown ? '100px' : '140px'}
                height="32px"
                data={chartData}
                color={color}
              />
            }
          />
        </div>

        <div className="mt-16">
          <PersonalPercentChange
            containerClassName={styles.percent}
            percentValue={percent}
          />
        </div>
      </div>

      <div className="d-flex gap-30">
        <div className={styles.bot_orders}>
          <FormattedMessage
            id={isTabletDown ? 'DailyFilled' : 'DailyFilledOrders'}
          />
          :{' '}
          <span className={styles.value}>
            <PersonalData data={dailyFilled} />
          </span>
        </div>

        <div className={styles.bot_orders}>
          <FormattedMessage
            id={isTabletDown ? 'ConstantOpen' : 'ConstantOpenOrders'}
          />
          :{' '}
          <span className={styles.value}>
            <PersonalData data={constantOpen} />
          </span>
        </div>
      </div>
    </Widget>
  );
};

export default memo(BotsProfitWidget);
