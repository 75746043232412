import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFormContext, useWatch } from 'react-hook-form';

import { CRYPTO_CURRENCIES } from '@shared/constants';
import { CheckboxController, InputController } from '@shared/ui';

import { withdrawCurrencySymbolSelector } from '@pages/WalletsPage/Withdraw/Withdraw.selectors';

import { isFieldRequired } from './AdditionalCoinFields.validations';

const AdditionalCoinFields = () => {
  const intl = useIntl();

  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);

  const methods = useFormContext();

  const { reset, control } = methods;

  const watchNoXrpTag = useWatch({ control, name: 'noTag' });
  const watchXrpTag = useWatch({ control, name: 'xrpTag' });
  const watchNoEosMemo = useWatch({ control, name: 'noMemo' });
  const watchEosMemo = useWatch({ control, name: 'eosMemo' });

  useEffect(() => {
    if (watchNoXrpTag && !!watchXrpTag) {
      reset((formState) => ({
        ...formState,
        xrpTag: '',
      }));
    }

    if (watchNoEosMemo && !!watchEosMemo) {
      reset((formState) => ({
        ...formState,
        eosMemo: '',
      }));
    }
  }, [watchNoXrpTag, watchNoEosMemo]);

  const specialCoinFields = {
    [CRYPTO_CURRENCIES.XRP]: [
      {
        type: 'input',
        name: 'xrpTag',
        label: `${intl.formatMessage({ id: 'XRPDestinationTag' })}:`,
        placeholderIntlId: 'XRPTagPlaceholder',
        rules: { required: isFieldRequired(watchXrpTag, !watchNoXrpTag, intl) },
        disabled: watchNoXrpTag,
      },
      {
        type: 'checkbox',
        name: 'noTag',
        label: intl.formatMessage({ id: 'NoXRPTag' }),
      },
    ],
    [CRYPTO_CURRENCIES.EOS]: [
      {
        type: 'input',
        name: 'eosMemo',
        label: `${intl.formatMessage({ id: 'EOSMemo' })}:`,
        placeholderIntlId: 'EOSMemoPlaceholder',
        rules: {
          required: isFieldRequired(watchEosMemo, !watchNoEosMemo, intl),
        },
        disabled: watchNoEosMemo,
      },
      {
        type: 'checkbox',
        name: 'noMemo',
        label: intl.formatMessage({ id: 'NoMemo' }),
      },
    ],
  };

  if (specialCoinFields.hasOwnProperty(selectedCurrencySymbol)) {
    return (
      <div className="row d-flex align-items-center flex-md-row flex-column-reverse">
        {specialCoinFields[selectedCurrencySymbol].map((field) => {
          if (field.type === 'input') {
            return (
              <div key={field.name} className="col-md-7 col-12 mt-24">
                <InputController
                  name={field.name}
                  placeholder={intl.formatMessage({
                    id: field.placeholderIntlId,
                  })}
                  label={field.label}
                  rules={field.rules}
                  bordered={false}
                  underlined
                  size="l"
                  disabled={field.disabled}
                  {...methods}
                />
              </div>
            );
          }

          if (field.type === 'checkbox') {
            return (
              <div key={field.name} className="col-md-5 col-12 mt-24 ">
                <CheckboxController
                  name={field.name}
                  label={field.label}
                  {...methods}
                />
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  }

  return null;
};

export default memo(AdditionalCoinFields);
