import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../utils';

const defaultTrades = [];
const defaultData = { trades: defaultTrades };
const defaultMeta = {
  page: 1,
  limit: 5,
};

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const tradeFeesSelector = createSelector(dataSelector, (data) => {
  return data.trades || defaultTrades;
});

export const TradeFeesApiSelectors = {
  tradeFeesSelector,
  metaSelector,
};
