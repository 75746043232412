export const INSTANT_BONUS_STATUS = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  PENDING_EXPIRATION: 'pending_expiration',
  EXPIRED: 'expired',
  PAYED: 'payed',
  PENDING: 'pending',
};

export const INSTANT_BONUS_STATUS_COLORS = {
  [INSTANT_BONUS_STATUS.ACTIVE]: { color: 'green', label: 'Active' },
  [INSTANT_BONUS_STATUS.INACTIVE]: { color: 'red', label: 'Inactive' },
  [INSTANT_BONUS_STATUS.PENDING_EXPIRATION]: {
    color: 'yellow',
    label: 'Pending',
  },
  [INSTANT_BONUS_STATUS.PENDING]: {
    color: 'yellow',
    label: 'Pending',
  },
  [INSTANT_BONUS_STATUS.PAYED]: {
    color: 'violet',
    label: 'Payed',
  },
  [INSTANT_BONUS_STATUS.EXPIRED]: { color: 'black', label: 'Expired' },
};

export const ALL = 'all';
