import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { KYC_LEVELS } from '@pages/AccountPage/constants';

import client from '@api/client';

import { getFormData } from '@utils/api';

export const profileAPI = createApi({
  reducerPath: 'profileAPI',
  baseQuery: client,
  tagTypes: ['FavouritePairs', 'Profile'],
  endpoints: (builder) => ({
    getGoogleCode: builder.query({
      query: () => ({
        url: 'security/2fa/google',
        method: 'POST',
      }),
    }),
    putOnGoogleAuthorization: builder.mutation({
      query: (body) => ({
        url: 'security/2fa/google/on',
        method: 'PUT',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    putOffGoogleAuthorization: builder.mutation({
      query: (body) => ({
        url: 'security/2fa/google/off',
        method: 'PUT',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    postSmsAuthInitBind: builder.mutation({
      query: (body) => ({
        url: 'security/2fa/sms',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    putBindPhoneNumber: builder.mutation({
      query: (body) => ({
        url: 'security/2fa/sms',
        method: 'PUT',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    postSendSms: builder.mutation({
      query: () => ({
        url: 'security/2fa/sms/send',
        method: 'POST',
        body: {},
      }),
    }),
    deleteUnbindPhoneNumber: builder.mutation({
      query: (body) => ({
        url: 'security/2fa/sms',
        method: 'DELETE',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    getFeesInfo: builder.query({
      query: () => 'fee',
    }),
    getProfileInfo: builder.query({
      query: () => 'profile',
      providesTags: ['Profile'],
      transformResponse: (response) => ({
        ...response,
        security_settings: {
          ...response.security_settings,
          // TODO: response.Settings.detect_ip_change field will be removed in scope of  https://paramoountdax.atlassian.net/browse/PDAX2-1563
          detect_ip_change: response.Settings.detect_ip_change === 'true',
        },
      }),
    }),
    getProfileLogs: builder.query({
      query: ({ page, limit }) => {
        return {
          url: `/profile/logs`,
          params: {
            page,
            limit,
          },
        };
      },
    }),
    postChangeAvatar: builder.mutation({
      query: (body) => ({
        url: 'profile/update-avatar',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    postChangeNickname: builder.mutation({
      query: (body) => ({
        url: 'profile/update-nickname',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    postChangePassword: builder.mutation({
      query: (body) => ({
        url: 'profile/password',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    getFavouritesPairs: builder.query({
      query: () => 'profile/favourites',
      providesTags: ['FavouritePairs'],
    }),
    toggleFavouritePair: builder.mutation({
      query: ({ pair }) => {
        const form = new FormData();

        form.append('pair', pair);

        return {
          url: 'profile/favourites',
          method: 'POST',
          body: form,
        };
      },
      invalidatesTags: ['FavouritePairs'],
    }),
    postSetTradePassword: builder.mutation({
      query: (body) => ({
        url: 'security/trade-password/on',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    putDisableTradePassword: builder.mutation({
      query: (body) => ({
        url: 'security/trade-password/off',
        method: 'PUT',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    postEnableDetectIp: builder.mutation({
      query: () => ({
        url: 'security/detect-ip/on',
        method: 'POST',
      }),
      invalidatesTags: ['Profile'],
    }),
    putDisableDetectIp: builder.mutation({
      query: () => ({
        url: 'security/detect-ip/off',
        method: 'PUT',
      }),
      invalidatesTags: ['Profile'],
    }),
    postEnableAntiPhishingCode: builder.mutation({
      query: (body) => ({
        url: 'security/anti-phishing/on',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    putDisableAntiPhishingCode: builder.mutation({
      query: (body) => ({
        url: 'security/anti-phishing/off',
        method: 'PUT',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile'],
    }),
    postSetIpChange: builder.mutation({
      query: (body) => ({
        url: '/profile/settings',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['api-keys'],
    }),
    getKycStatus: builder.query({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const result = await fetchWithBQ('/kyc/status');

        if (result.error?.status === 500) {
          delete result.error;
          result.data = {};
        }

        return result;
      },
      providesTags: ['KycStatus'],
    }),
    kycCustomerRegistration: builder.mutation({
      query: (body) => ({
        url: '/kyc/customer-registration',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile', 'KycStatus'],
    }),
    kycCustomerVerification: builder.mutation({
      query: (body) => ({
        url: '/kyc/document-verification',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile', 'KycStatus'],
    }),
    kycPaymentDocumentVerification: builder.mutation({
      query: (body) => ({
        url: '/kyc/payment-document-verification',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['Profile', 'KycStatus'],
    }),
    kybSendAccountInformation: builder.mutation({
      query: (body) => ({
        url: '/kyb/account-information',
        method: 'POST',
        body,
      }),
    }),
    kybSendRegistrationAddress: builder.mutation({
      query: (body) => ({
        url: '/kyb/registration-address',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybSendOperationalAddress: builder.mutation({
      query: (body) => ({
        url: '/kyb/operational-address',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybSendSourceOfFunds: builder.mutation({
      query: (body) => ({
        url: '/kyb/source-of-funds',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybSendAdditionalInformation: builder.mutation({
      query: (body) => ({
        url: '/kyb/additional-information',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybUploadDocCertificateOfIncorporation: builder.mutation({
      query: (body) => ({
        url: '/kyb/basic-verification/certificate-of-incorporation',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybUploadDocMemorandum: builder.mutation({
      query: (body) => ({
        url: '/kyb/basic-verification/memorandum-article-association',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybUploadDocDirectors: builder.mutation({
      query: (body) => ({
        url: '/kyb/basic-verification/register-directors',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybUploadDocMembers: builder.mutation({
      query: (body) => ({
        url: '/kyb/basic-verification/register-members',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybUploadDocOwnership: builder.mutation({
      query: (body) => ({
        url: '/kyb/basic-verification/ownership-structure',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    kybUploadDocSanctions: builder.mutation({
      query: (body) => ({
        url: '/kyb/basic-verification/sanctions-questionnaire',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    getKybUser: builder.query({
      query: () => ({
        url: '/kyb/user-kyb',
        method: 'GET',
      }),
    }),
    getKybSteps: builder.query({
      query: ({ userId }) => ({
        url: `kyb/steps/${userId}`,
      }),
    }),
    getCityByKeywordHelper: builder.query({
      query: ({ keyword }) => ({
        url: '/helpers/city',
        method: 'GET',
        params: {
          namePrefix: keyword,
          sort: 'name',
          offset: 0,
          limit: 10,
        },
      }),
    }),
  }),
});

// SELECTORS
const defaultUserLevel = 0;
const defaultFavouritePairs = [];

export const profileInfoSelector = (result) => result.data;

const lastLoginsLogsSelector = (result) => result?.data?.logs || [];

export const userSecuritySelector = (result) => {
  return result?.data?.security_settings || {};
};

export const userSelector = (result) => {
  return result?.data?.User || {};
};

export const favouritePairsSelector = (result) =>
  result.data ?? defaultFavouritePairs;
export const userAuthId = createSelector(
  profileInfoSelector,
  (profileInfo) => profileInfo?.Settings?.id,
);

export const userLastLoginSelector = createSelector(
  profileInfoSelector,
  (profileInfo) => profileInfo?.last_login,
);

export const userLevelSelector = createSelector(
  profileInfoSelector,
  (profileInfo) => profileInfo?.Settings?.user_level ?? defaultUserLevel,
);

export const userAvatarSelector = createSelector(
  profileInfoSelector,
  (profileInfo) => profileInfo?.User?.avatar,
);

export const userAvatarBase64Selector = createSelector(
  profileInfoSelector,
  (profileInfo) => `data:image/png;base64,${profileInfo?.User?.avatar}`,
);

export const userFirstNameSelector = createSelector(
  profileInfoSelector,
  (profileInfo) => profileInfo?.User?.first_name,
);

export const userNicknameSelector = createSelector(
  profileInfoSelector,
  (profileInfo) => profileInfo?.User?.nickname,
);

export const userReferralCodeSelector = createSelector(
  profileInfoSelector,
  (profileInfo) => profileInfo?.User?.referral_code,
);

export const isUserHasActiveSettingSelector = createSelector(
  userSecuritySelector,
  (_, settingKey) => settingKey,
  (userSecurity, settingKey) => {
    const isUserHasActiveSetting = userSecurity[settingKey];

    return isUserHasActiveSetting;
  },
);

export const isUserHasMaxLevelSelector = createSelector(
  profileInfoSelector,
  (profileInfo) => profileInfo?.Settings?.user_level === KYC_LEVELS.THIRD,
);

export const favouritePairsNamesSelector = createSelector(
  favouritePairsSelector,
  (favouritesPairs) => favouritesPairs.map((pair) => pair.pair),
);

export const lastLoginsSelector = createSelector(
  lastLoginsLogsSelector,
  (logs) => {
    return logs.map((log) => {
      const { country, agent } = JSON.parse(log.notes);

      return {
        idAddress: log.ip,
        device: agent,
        country,
        date: log.created_at,
      };
    });
  },
);

export const {
  useGetFeesInfoQuery,
  useGetProfileInfoQuery,
  useLazyGetProfileInfoQuery,
  useGetFavouritesPairsQuery,
  useToggleFavouritePairMutation,
  usePostChangeAvatarMutation,
  usePostChangeNicknameMutation,
  usePostChangePasswordMutation,
  useGetGoogleCodeQuery,
  usePutOnGoogleAuthorizationMutation,
  usePutOffGoogleAuthorizationMutation,
  usePostSmsAuthInitBindMutation,
  usePutBindPhoneNumberMutation,
  useDeleteUnbindPhoneNumberMutation,
  usePostSendSmsMutation,
  usePostSetTradePasswordMutation,
  usePutDisableTradePasswordMutation,
  usePostEnableAntiPhishingCodeMutation,
  usePutDisableAntiPhishingCodeMutation,
  usePostEnableDetectIpMutation,
  usePutDisableDetectIpMutation,
  useGetProfileLogsQuery,
  usePostSetIpChangeMutation,
  useGetKycStatusQuery,
  useKycCustomerRegistrationMutation,
  useKycCustomerVerificationMutation,
  useKycPaymentDocumentVerificationMutation,
  useKybSendAccountInformationMutation,
  useKybSendRegistrationAddressMutation,
  useKybSendOperationalAddressMutation,
  useKybSendSourceOfFundsMutation,
  useKybSendAdditionalInformationMutation,
  useKybUploadDocCertificateOfIncorporationMutation,
  useKybUploadDocMemorandumMutation,
  useKybUploadDocDirectorsMutation,
  useKybUploadDocMembersMutation,
  useKybUploadDocOwnershipMutation,
  useKybUploadDocSanctionsMutation,
  useGetKybUserQuery,
  useLazyGetCityByKeywordHelperQuery,
  useGetKybStepsQuery,
} = profileAPI;
