import { memo } from 'react';

import { Button } from '@shared/ui';

const DetailsButton = ({ children, ...props }) => {
  return (
    <Button variant="outlined" {...props}>
      {children}
      ...
    </Button>
  );
};

export default memo(DetailsButton);
