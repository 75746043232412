import { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useFormContext, useWatch } from 'react-hook-form';

import { PAYMENT_METHOD } from '@shared/constants';
import { InputController } from '@shared/ui';
import { validateEmail } from '@shared/validations';

import {
  checkoutWithdrawDataSelector,
  withdrawCurrencySymbolSelector,
  withdrawPaymentMethodSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';
import { walletWithdrawActions } from '@pages/WalletsPage/Withdraw/Withdraw.slice';
import { AmountInput } from '@pages/WalletsPage/Withdraw/WithdrawCoinBlock';

const WithdrawCoinFiat = () => {
  const intl = useIntl();

  const activePaymentMethod = useSelector(withdrawPaymentMethodSelector);
  const checkoutWithdrawData = useSelector(checkoutWithdrawDataSelector);
  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);

  const dispatch = useDispatch();

  const methods = useFormContext();

  const { control } = methods;

  const watchEmail = useWatch({ control, name: 'email' });
  const watchAmount = useWatch({ control, name: 'amount' });

  useEffect(() => {
    const { email: savedEmail, amount: savedAmount } = checkoutWithdrawData;

    if (watchEmail !== savedEmail) {
      dispatch(
        walletWithdrawActions.setCheckoutWithdrawData({ email: watchEmail }),
      );
    }

    if (watchAmount !== savedAmount) {
      dispatch(
        walletWithdrawActions.setCheckoutWithdrawData({ amount: watchAmount }),
      );
    }
  }, [watchEmail, watchAmount]);

  return (
    <>
      <div className="row mt-24">
        <div className="col-md-7 col-12">
          <AmountInput selectedCurrencySymbol={selectedCurrencySymbol} />
        </div>
      </div>
      {activePaymentMethod === PAYMENT_METHOD.ADVCASH && (
        <div className="row mt-24">
          <div className="col-12">
            <InputController
              name="email"
              placeholder={intl.formatMessage({ id: 'YourEmail' })}
              label={`${intl.formatMessage({ id: 'EmailAddress' })}:`}
              rules={{
                required: intl.formatMessage({ id: 'FieldRequired' }),
                validate: { format: validateEmail(intl) },
              }}
              bordered={false}
              underlined
              size="l"
              {...methods}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(WithdrawCoinFiat);
