import { BOT_TYPE, TYPE_BONUS_CONTRACT } from '@shared/constants';

import { ENTRY_PRICE_UPDATE_RATES } from '../constants';

export const BOT_BUTTON_GROUP_TYPES = [
  { id: BOT_TYPE.grid, value: TYPE_BONUS_CONTRACT[BOT_TYPE.grid] },
  { id: BOT_TYPE.trend, value: TYPE_BONUS_CONTRACT[BOT_TYPE.trend] },
];

export const BOT_CONSTANT_OPEN_ORDERS = {
  min: 2,
  max: 40,
};

export const MIN_PERCENT = 0.01;

export const MAX_PERCENT = 100;

export const DEFAULT_GRID_BOT_VALUES = {
  amount: '',
  lowPrice: '',
  highPrice: '',
  grid: 2,
  profitPercent: 0,
};

export const DEFAULT_TREND_BOT_VALUES = {
  buyEntryDistance: '',
  buyTakeProfitDistance: '',
  buySaveLossesDistance: '',
  sellEntryDistance: '',
  sellTakeProfitDistance: '',
  sellSaveLossesDistance: '',
  entryPriceUpdateRates: ENTRY_PRICE_UPDATE_RATES[0],
  amount: '',
  isSellSideEnabled: false,
};
