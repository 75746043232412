import React from 'react';

import { ReactComponent as BarIconSVG } from '@images/chartiq/chartStyles/bar.svg';
import { ReactComponent as BaselineIconSVG } from '@images/chartiq/chartStyles/baseline_delta.svg';
import { ReactComponent as CandleIconSVG } from '@images/chartiq/chartStyles/candle.svg';
import { ReactComponent as ColoredBarIconSVG } from '@images/chartiq/chartStyles/colored_bar.svg';
import { ReactComponent as ColoredHLCBarIconSVG } from '@images/chartiq/chartStyles/colored_hlc.svg';
import { ReactComponent as HeikinAshiIconSVG } from '@images/chartiq/chartStyles/heikinashi.svg';
import { ReactComponent as HistogramIconSVG } from '@images/chartiq/chartStyles/histogram.svg';
import { ReactComponent as HollowCandleIconSVG } from '@images/chartiq/chartStyles/hollow_candle.svg';
import { ReactComponent as KagiIconSVG } from '@images/chartiq/chartStyles/kagi.svg';
import { ReactComponent as LineIconSVG } from '@images/chartiq/chartStyles/line.svg';
import { ReactComponent as LineBreakIconSVG } from '@images/chartiq/chartStyles/linebreak.svg';
import { ReactComponent as MountainIconSVG } from '@images/chartiq/chartStyles/mountain.svg';
import { ReactComponent as PointFigureIconSVG } from '@images/chartiq/chartStyles/pandf.svg';
import { ReactComponent as RangeBarsIconSVG } from '@images/chartiq/chartStyles/rangebars.svg';
import { ReactComponent as RenkoIconSVG } from '@images/chartiq/chartStyles/renko.svg';
import { ReactComponent as ScatterplotIconSVG } from '@images/chartiq/chartStyles/scatterplot.svg';
import { ReactComponent as StepIconSVG } from '@images/chartiq/chartStyles/step.svg';
import { ReactComponent as VertexLineIconSVG } from '@images/chartiq/chartStyles/vertex_line.svg';
import { ReactComponent as VolumeCandleIconSVG } from '@images/chartiq/chartStyles/volume_candle.svg';
import { ReactComponent as BCHIconSVG } from '@images/coins/bch-icon.svg';
import { ReactComponent as BCHOutlineIconSVG } from '@images/coins/bch-outline-icon.svg';
import { ReactComponent as BTCIconSVG } from '@images/coins/btc-icon.svg';
import { ReactComponent as BTCOutlineIconSVG } from '@images/coins/btc-outline-icon.svg';
import { ReactComponent as CRVIconSVG } from '@images/coins/crv-icon.svg';
import { ReactComponent as CRVOutlineIconSVG } from '@images/coins/crv-outline-icon.svg';
import { ReactComponent as DASHIconSVG } from '@images/coins/dash-icon.svg';
import { ReactComponent as DASHOutlineIconSVG } from '@images/coins/dash-outline-icon.svg';
import { ReactComponent as DefaultCoinIconSVG } from '@images/coins/default-icon.svg';
import { ReactComponent as DefaultCoinOutlineIconSVG } from '@images/coins/default-outline-icon.svg';
import { ReactComponent as EOSIconSVG } from '@images/coins/eos-icon.svg';
import { ReactComponent as EOSOutlineIconSVG } from '@images/coins/eos-outline-icon.svg';
import { ReactComponent as ETHIconSVG } from '@images/coins/eth-icon.svg';
import { ReactComponent as ETHOutlineIconSVG } from '@images/coins/eth-outline-icon.svg';
import { ReactComponent as EURIconSVG } from '@images/coins/eur-icon.svg';
import { ReactComponent as EUROutlineIconSVG } from '@images/coins/eur-outline-icon.svg';
import { ReactComponent as LINKIconSVG } from '@images/coins/link-icon.svg';
import { ReactComponent as LINKOutlineIconSVG } from '@images/coins/link-outline-icon.svg';
import { ReactComponent as LTCIconSVG } from '@images/coins/ltc-icon.svg';
import { ReactComponent as LTCOutlineIconSVG } from '@images/coins/ltc-outline-icon.svg';
import { ReactComponent as MATICIconSVG } from '@images/coins/matic-icon.svg';
import { ReactComponent as MATICOutlineIconSVG } from '@images/coins/matic-outline-icon.svg';
import { ReactComponent as PRDXIconSVG } from '@images/coins/prdx-icon.svg';
import { ReactComponent as PRDXOutlineIconSVG } from '@images/coins/prdx-outline-icon.svg';
import { ReactComponent as SHIBIconSVG } from '@images/coins/shib-icon.svg';
import { ReactComponent as SHIBOutlineIconSVG } from '@images/coins/shib-outline-icon.svg';
import { ReactComponent as TBTCIconSVG } from '@images/coins/tbtc-icon.svg';
import { ReactComponent as TBTCOutlineIconSVG } from '@images/coins/tbtc-outline-icon.svg';
import { ReactComponent as USDTIconSVG } from '@images/coins/usdt-icon.svg';
import { ReactComponent as USDTOutlineIconSVG } from '@images/coins/usdt-outline-icon.svg';
import { ReactComponent as XRPIconSVG } from '@images/coins/xrp-icon.svg';
import { ReactComponent as XRPOutlineIconSVG } from '@images/coins/xrp-outline-icon.svg';
import { ReactComponent as ZRXIconSVG } from '@images/coins/zrx-icon.svg';
import { ReactComponent as ZRXOutlineIconSVG } from '@images/coins/zrx-outline-icon.svg';

export const UNAUTHORIZED = 401;
// export const ACCESS_DENIED = 403;

export const DEFAULT_PRECISION = 8;
export const FIAT_PRECISION = 2;
export const PERCENT_PRECISION = 2;
export const DEFAULT_MARKET_ID = 'btcusdt';
export const DEFAULT_COIN = 'btc';
export const DEFAULT_SHOW_SENSITIVE_INFO = true;
export const DEFAULT_REQUEST_LIMIT = 50;
export const DEFAULT_REQUEST_STAKING_LIMIT = 1000; // TODO temporarily set
export const DEFAULT_REQUEST_INSTANT_BONUS_LIMIT = 1000; // TODO temporarily set
export const DEFAULT_REQUEST_ANNOUNCEMENTS_LIMIT = 1000; // TODO temporarily set
export const ITEMS_PER_PAGE = 5;
export const ITEMS_PER_PAGE_NOTIFICATIONS = 10;
export const PERSONAL_DATA_PLUG = '***';
export const MARKETS_ALL_COINS = 'all_coins';
export const NO_SELECTED_INDEX = -1;

export const PRECISION_ITEMS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
];

export const FILE_FORMAT = {
  PDF: 'pdf',
  CSV: 'csv',
};

export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  ACCOUNT_LIGHT: 'account-light',
};

export const LOCALE_LIST = [
  { value: 'en', label: 'English' },
  { value: 'it', label: 'Italiano' },
];

export const CURRENCY_LIST = [
  {
    symbol: '$',
    label: 'USD',
    value: 'USD',
  },
  {
    symbol: '￥',
    label: 'CNY',
    value: 'CNY',
  },
  {
    symbol: '€',
    label: 'EUR',
    value: 'EUR',
  },
  {
    symbol: '¥',
    label: 'JPY',
    value: 'JPY',
  },
  {
    symbol: '₩',
    label: 'KRW',
    value: 'KRW',
  },
  {
    symbol: '₺',
    label: 'TRY',
    value: 'TRY',
  },
];

export const CURRENCY_LIST_VALUES = CURRENCY_LIST.map(
  (currency) => currency.value,
);

export const LOCAL_STORAGE_LAYOUT_SETTINGS = 'prdx:grid_layout_settings';
export const LOCAL_STORAGE_AUTH_TOKEN = 'prdx:auth_token';
export const LOCAL_STORAGE_MARKET_ID = 'prdx:market_id';
export const LOCAL_STORAGE_TERMINAL_THEME = 'prdx:terminal-theme';
export const LOCAL_STORAGE_ACCOUNT_THEME = 'prdx:account-theme';
export const LOCAL_STORAGE_LOCALE = 'prdx:locale';
export const LOCAL_STORAGE_CURRENCY = 'prdx:currency';
export const LOCAL_STORAGE_SENSITIVE_INFO = 'prdx:sensitive_info';
export const LOCAL_STORAGE_RUNNING_LINE = 'prdx:running_line';
export const LOCAL_STORAGE_MID = 'prdx:mid';
export const LOCAL_STORAGE_WALLETS_OVERVIEW_WIDGETS =
  'prdx:wallets_overview_widgets';
export const LOCAL_STORAGE_OVERVIEW_EARN_WIDGETS = 'prdx:overview_earn_widgets';
export const LOCAL_STORAGE_DEFAULT_USER_AVATAR = 'prdx:default_user_avatar';
export const LOCAL_STORAGE_IS_SHOW_WHAT_IS_ACCOUNT_CARD =
  'prdx:is_show_what_is_account_card';
export const LOCAL_STORAGE_SEARCH_MENU_RECENTLY = 'prdx:search_menu_recently';

export const COOKIE_REFERRAL_ID = 'referral_id';
export const COOKIE_SKIP_SECURITY_LEVEL_CHECK = 'skip_security_level_check';

export const modalsIds = {
  SAVE_TEMPLATE: 'save_template_modal',
  SAVED_TEMPLATES_LIST: 'saved_templates_list_modal',
  CLEAR_TEMPLATE: 'clear_template_modal',
  DOWNLOAD_MOBILE_APP: 'download_mobile_app_modal',
  CONFIRM_LEAVING_PAGE: 'confirm_leaving_page_modal',
  CONFIRM_CANCEL_OPEN_ORDER: 'confirm_cancel_open_order_modal',
  CONFIRM_CANCEL_ALL_OPEN_ORDERS: 'confirm_cancel_all_open_orders_modal',
  CONFIRM_CANCEL_OPEN_ORDERS_BY_PAIR:
    'confirm_cancel_open_orders_modal_by_pair',
  CHART_SHORTCUTS_DND: 'chart_shortcuts_dnd_modal',
  ORDER_HISTORY_TRADE_DETAILS: 'order_history_trade_details',
  WALLETS_WIDGETS: 'wallets_widgets',
  SECURITY_LEVEL_MODAL: 'security_level_modal',
  GOOGLE_2FA_MODAL: 'google_2_fa_modal',
  SMS_2FA_MODAL: 'sms_2_fa_modal',
  UPDATE_AVATAR_MODAL: 'update_avatar_modal',
  CONFIRM_MODAL: 'confirm_modal',
  CONFIRM_LIQUIDATE_BOT_MODAL: 'confirm_liquidate_bot_modal',
  WALLETS_WITHDRAW_ADVCASH_FAILED: 'wallets_withdraw_advcash_failed',
  DELETE_API_KEY_MODAL: 'delete_api_key_modal',
  TWO_FA_MODAL: 'two_fa_modal',
  CONFIRM_PHONE: 'confirm_phone_modal',
  CONFIRM_TRADE_MODAL: 'confirm_trade_modal',
  OVERVIEW_EARN_WIDGETS_MODAL: 'overview_earn_widgets_modal',
  EXPORT_FILE_MODAL: 'export_file_modal',
  QR_CODE_MODAL: 'qr_code_modal',
  ACCOUNT_ORDERS_HISTORY_TRADE_DETAILS: 'account_orders_history_trade_details',
  JOIN_WAIT_LIST: 'join_wait_list',
  CLOSE_CARD: 'close_card_modal',
  DATE_PICKER_CUSTOM_RANGE: 'date-picker_custom_range_modal',
  SUCCESS_SELECT_CUSTOM_RANGE: 'success_select_custom_range_modal',
  SUCCESS_JOIN_WAIT_LIST: 'success_join_wait_list',
};

export const sidebarIds = {
  PROFILE: 'profile_sidebar',
  NOTIFICATIONS: 'notifications_sidebar',
  NAVIGATION: 'navigation_sidebar',
  LOCALIZATION: 'localization_sidebar',
  GRID_LAYOUT_SETTINGS: 'grid_layout_settings_sidebar',
  COUNTRY_CODES: 'country_codes',
  WALLETS_DEPOSIT_SEPA: 'wallets_deposit_sepa_bank_sidebar',
  WALLETS_WITHDRAW_ADD_ADDRESS: 'wallets_withdraw_add_address',
  WALLETS_WITHDRAW_CONFIRM_ADVCASH: 'wallets_withdraw_confirm_advcash',
  WALLETS_WITHDRAW_BANK_TRANSFER: 'wallets_withdraw_bank_transfer',
  WALLETS_WITHDRAW_CONFIRM_BANK_TRANSFER:
    'wallets_withdraw_confirm_bank_transfer',
  BOT_DETAILS: 'bot_details',
  BOT_EDIT_SETTINGS: 'bot_edit_settings',
  WALLETS_CREATE_STAKING_ITEM: 'wallets_create_staking_item_sidebar',
  WALLETS_UNSTAKE_ITEM: 'wallets_unstake_item_sidebar',
  SPOT_ACCOUNT: 'spot_account_sidebar',
  TRANSFER: 'transfer_sidebar',
  PRODUCTS: 'products_sidebar',
  SEARCH: 'search_sidebar',
  TRANSFER_TO_EVOLVE: 'transfer_to_evolve',
  MAKE_A_WITHDRAWAL: 'make_a_withdrawal',
  ACTIVATE_CARD: 'activate_card_sidebar',
  TOP_UP_CARD: 'top_up_card_sidebar',
  CONFIRM_TOP_UP: 'confirm_top_up_sidebar',
  VISA_TRANSFER_MONEY: 'visa_transfer_money_sidebar',
  CONFIRM_TRANSFER_MONEY: 'confirm_transfer_money_sidebar',
  KYB_BASIC_INFO_ACCOUNT_INFO: 'account_information_sidebar',
  KYB_BASIC_INFO_REGISTRATION_ADDRESS: 'registration_address_sidebar',
  KYB_BASIC_INFO_OPERATIONAL_ADDRESS: 'operational_address_sidebar',
  KYB_BASIC_INFO_FUNDS_SOURCE: 'source_of_funds',
  KYB_BASIC_INFO_ADDITIONAL_INFO: 'additional_information_sidebar',
  KYB_UPLOAD_DOCUMENT: 'upload_document_sidebar',
};

// TODO: rename coin to currency
// replace strings to CRYPTO_CURRENCIES and FIAT_CURRENCIES constants
export const CRYPTO_COIN_LIST = [
  {
    iconOutline: <DefaultCoinOutlineIconSVG />,
    icon: <DefaultCoinIconSVG />,
    coin: 'default',
  },
  {
    iconOutline: <BCHOutlineIconSVG />,
    icon: <BCHIconSVG />,
    coin: 'bch',
  },
  {
    iconOutline: <BTCOutlineIconSVG />,
    icon: <BTCIconSVG />,
    coin: 'btc',
  },

  {
    iconOutline: <CRVOutlineIconSVG />,
    icon: <CRVIconSVG />,
    coin: 'crv',
  },
  {
    iconOutline: <DASHOutlineIconSVG />,
    icon: <DASHIconSVG />,
    coin: 'dash',
  },
  {
    iconOutline: <ETHOutlineIconSVG />,
    icon: <ETHIconSVG />,
    coin: 'eth',
  },
  {
    iconOutline: <EOSOutlineIconSVG />,
    icon: <EOSIconSVG />,
    coin: 'eos',
  },
  {
    iconOutline: <EUROutlineIconSVG />,
    icon: <EURIconSVG />,
    coin: 'eur',
  },
  {
    iconOutline: <LINKOutlineIconSVG />,
    icon: <LINKIconSVG />,
    coin: 'link',
  },
  {
    iconOutline: <LTCOutlineIconSVG />,
    icon: <LTCIconSVG />,
    coin: 'ltc',
  },
  {
    iconOutline: <MATICOutlineIconSVG />,
    icon: <MATICIconSVG />,
    coin: 'matic',
  },
  {
    iconOutline: <PRDXOutlineIconSVG />,
    icon: <PRDXIconSVG />,
    coin: 'prdx',
  },
  {
    iconOutline: <SHIBOutlineIconSVG />,
    icon: <SHIBIconSVG />,
    coin: 'shib',
  },
  {
    iconOutline: <TBTCOutlineIconSVG />,
    icon: <TBTCIconSVG />,
    coin: 'tbtc',
  },
  {
    iconOutline: <USDTOutlineIconSVG />,
    icon: <USDTIconSVG />,
    coin: 'usdt',
  },
  {
    iconOutline: <XRPOutlineIconSVG />,
    icon: <XRPIconSVG />,
    coin: 'xrp',
  },
  {
    iconOutline: <ZRXOutlineIconSVG />,
    icon: <ZRXIconSVG />,
    coin: 'zrx',
  },
];

export const CHART_STYLES_LIST = [
  {
    icon: <CandleIconSVG />,
    value: 'candle',
  },
  {
    icon: <BarIconSVG />,
    value: 'bar',
  },
  {
    icon: <ColoredBarIconSVG />,
    value: 'colored_bar',
  },
  {
    icon: <LineIconSVG />,
    value: 'line',
  },
  {
    icon: <VertexLineIconSVG />,
    value: 'vertex_line',
  },
  {
    icon: <StepIconSVG />,
    value: 'step',
  },
  {
    icon: <MountainIconSVG />,
    value: 'mountain',
  },
  {
    icon: <BaselineIconSVG />,
    value: 'baseline_delta',
  },
  {
    icon: <HollowCandleIconSVG />,
    value: 'hollow_candle',
  },
  {
    icon: <VolumeCandleIconSVG />,
    value: 'volume_candle',
  },
  {
    icon: <ColoredHLCBarIconSVG />,
    value: 'colored_hlc',
  },
  {
    icon: <ScatterplotIconSVG />,
    value: 'scatterplot',
  },
  {
    icon: <HistogramIconSVG />,
    value: 'histogram',
  },
  {
    icon: <HeikinAshiIconSVG />,
    value: 'heikinashi',
  },
  {
    icon: <KagiIconSVG />,
    value: 'kagi',
  },
  {
    icon: <LineBreakIconSVG />,
    value: 'linebreak',
  },
  {
    icon: <RenkoIconSVG />,
    value: 'renko',
  },
  {
    icon: <RangeBarsIconSVG />,
    value: 'rangebars',
  },
  {
    icon: <PointFigureIconSVG />,
    value: 'pandf',
  },
];

export const PRIMARY_KEYS = [
  'Up',
  'Down',
  'Right',
  'Left',
  'Shift',
  'Alt',
  'NumpadAdd',
  'NumpadSubtract',
  'Delete',
  'Del',
  'Esc',
  'Backslash',
  'Digit0',
  'Tab',
  'Enter',
  'Home',
  'End',
  'Backspace',
  'Escape',
  'Slash',
  'PageUp',
  'PageDown',
];

export const TEMPLATE_SAVE_TYPES = {
  EXISTING: 'existing',
  NEW: 'new',
};

export const NOOP = () => {};

export const ORDER_BOOK_SIDES = {
  bids: 'bids',
  asks: 'asks',
};

export const ORDER_BOOK_TABLE_SIDES = {
  SELL: 'sell',
  BUY: 'buy',
  BOTH: 'both',
};

export const AUTH_TYPES = {
  LOGIN: 'login',
  SMS: 'sms',
  GOOGLE: 'google',
};

export const UI_ORDER_TYPES = {
  SIMPLE: 'web-simple',
  BASIC: 'web-basic',
  ATC: 'web-atc',
};

export const SUBORDER_TYPE = {
  OCO: {
    SELL_STOP: 'sell_stop',
    BUY_LIMIT: 'buy_limit',
  },
  OTO: {
    STOP_LOSS: 'stop_loss',
    TAKE_PROFIT: 'take_profit',
  },
};

export const ORDER_TYPE = {
  OTO: 'oto',
  STRANGLE: 'strangle',
  STRADDLE: 'straddle',
  TRAILING_STOP: 'trailing_stop',
};

export const ORDER_FORM_TYPE = {
  MARKET: 'market',
  LIMIT: 'limit',
  STOP: 'stop',
  TRAILING_STOP: 'trailing_stop',
  STRANGLE: 'strangle',
  STRADDLE: 'straddle',
  LIMIT_OTO_BASIC: 'limit_oto_basic',
  STOP_OTO_BASIC: 'stop_oto_basic',
  LIMIT_OTO_ADVANCED: 'limit_oto_advanced',
  STOP_OTO_ADVANCED: 'stop_oto_advanced',
};

export const QUANTITY_MARKS = {
  0: '0%',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%',
};

export const BALANCE_ACTIONS = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  TRANSFER: 'transfer',
  TRADE: 'trade',
  DETAILS: 'details',
};

export const SINGLE_TAB_WIDTH = 120;
export const INACTIVE_TAB_WIDTH = 120;
export const ACTIVE_TAB_WIDTH = 120;

export const REGISTER_BUSINESS_ACCOUNT = 'business';

export const PERMISSION_STATE = {
  READ_ONLY: 'readOnly',
  NOT_ALLOWED: 'not_allowed',
  ALLOWED: 'allowed',
};

export const NOTIFICATION_STATUS = {
  UNREAD: 'unread',
  READ: 'read',
};

export const SERVER_MESSAGE_TYPE = {
  MESSAGES: 'ServerMessages',
  ERRORS: 'ServerErrors',
};
