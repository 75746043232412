import React, { memo } from 'react';

import TruncatedText from '@shared/components/TruncatedText/TruncatedText';

import styles from './OrdersId.module.scss';

const OrdersId = ({ id }) => {
  return (
    <TruncatedText>
      <span className={styles.orders_id}>{id}</span>
    </TruncatedText>
  );
};

export default memo(OrdersId);
