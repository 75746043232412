import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@components/Button';
import Modal from '@components/modals/Modal';

import {
  useCancelOpenOrderMutation,
  useReplaceOrderMutation,
} from '@api/ordersAPI';

import useModal from '@hooks/useModal/useModal';

import { SUBORDER_TYPE, modalsIds } from '@constants';

const ConfirmCancelOpenOrderModal = () => {
  const [cancelOpenOrder, { isLoading: isLoadingCancelOpenOrder }] =
    useCancelOpenOrderMutation();

  const [replaceOrder, { isLoading: isLoadingReplaceOrder }] =
    useReplaceOrderMutation();

  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.CONFIRM_CANCEL_OPEN_ORDER,
  );

  const {
    id,
    marketId,
    marketName,
    amount,
    type,
    isSuborder,
    side,
    originalOrder,
  } = modal.params;

  const isLoading = isLoadingCancelOpenOrder || isLoadingReplaceOrder;

  const cancelSubOrder = () => {
    if (
      type === SUBORDER_TYPE.OCO.BUY_LIMIT ||
      type === SUBORDER_TYPE.OCO.SELL_STOP
    ) {
      return cancelOpenOrder({ marketId, orderId: originalOrder.id });
    }

    const newOrder = {
      ...originalOrder,
    };

    if (type === SUBORDER_TYPE.OTO.TAKE_PROFIT) {
      newOrder.tp_price = 0;
      newOrder.tp_rel_price = 0;
    }

    if (type === SUBORDER_TYPE.OTO.STOP_LOSS) {
      newOrder.sl_price = 0;
      newOrder.sl_rel_price = 0;
    }

    return replaceOrder({
      marketId,
      orderId: originalOrder.id,
      side,
      order: newOrder,
    });
  };

  const handleCancelOpenOrder = async () => {
    if (isSuborder) {
      cancelSubOrder().unwrap().finally(closeModal);

      return;
    }

    cancelOpenOrder({
      marketId,
      orderId: id,
    })
      .unwrap()
      .finally(closeModal);
  };

  return (
    <Modal
      className="modal-sm"
      onClose={closeModal}
      afterClose={resetModal}
      visible={isOpen}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">
            <FormattedMessage id="ConfirmCancelOpenOrder" />
          </div>
        </div>
      }
      footer={
        <div>
          {isLoading ? (
            <Button isLoading fill dangerous rounded />
          ) : (
            <div className="d-flex justify-content-between">
              <Button fill dangerous rounded onClick={handleCancelOpenOrder}>
                <FormattedMessage id="YesConfirm" />
              </Button>

              <Button
                fill
                secondary
                rounded
                onClick={closeModal}
                className="ms-10"
              >
                <FormattedMessage id="No" />
              </Button>
            </div>
          )}
        </div>
      }
    >
      <FormattedMessage
        id="ConfirmCancelOpenOrderMessage"
        values={{
          pair: marketName,
          amount,
        }}
      />
    </Modal>
  );
};

export default memo(ConfirmCancelOpenOrderModal);
