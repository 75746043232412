import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '@shared/components';
import { Button } from '@shared/ui';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

import styles from './DeleteAccountCard.module.scss';

const DeleteAccountCard = () => {
  return (
    <Card
      classNames={[styles.card]}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <FormattedMessage id="DeleteAccount" />
          </div>
        </div>
      }
    >
      <div className={styles.delete_account}>
        <div>
          <FormattedMessage id="DeleteAccountDescription" />
        </div>

        <div className={styles.button_container}>
          <Button
            variant="outlined"
            color="dangerous"
            navigation={{ to: ACCOUNT_ROUTES.DELETE_ACCOUNT }}
            customStyles={styles}
          >
            <FormattedMessage id="Delete" />
            ...
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default memo(DeleteAccountCard);
