import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { ReactComponent as AdvcashIconSVG } from '@icons/advcash-icon.svg';
import { ReactComponent as IndacoinIconSVG } from '@icons/indacoin-icon.svg';

import s from './ExternalServices.module.scss';

const ExternalServices = () => {
  return (
    <div className={s.services}>
      <div className={cx(s.service_block, s.green)}>
        <div className={s.service_block__icon}>
          <AdvcashIconSVG />
        </div>
        <div>
          <div className={s.service_block__title}>
            <FormattedMessage id="Advcash" />
          </div>
          <div className={s.service_block__description}>
            <FormattedMessage id="ExternalServiceAdvcashDescription" />
          </div>
        </div>
      </div>

      <div className={cx(s.service_block, s.blue)}>
        <div className={s.service_block__icon}>
          <IndacoinIconSVG />
        </div>
        <div>
          <div className={s.service_block__title}>
            <FormattedMessage id="Indacoin" />
          </div>
          <div className={s.service_block__description}>
            <FormattedMessage id="ExternalServiceIndacoinDescription" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalServices;
