import { MONTHS } from '@shared/constants';

export const getMonthsMapped = ({ intl }) =>
  MONTHS.map((month, index) => ({
    value: month,
    label: intl.formatMessage({ id: month }),
    order: index,
    key: `month-key-${index}`,
  }));

export const getYearsMapped = () => {
  const startYear = new Date().getFullYear();
  const endYear = new Date(null).getFullYear();

  const years = [];

  for (let i = startYear; i >= endYear; i--) {
    years.push(i);
  }

  return years.map((year, index) => ({
    value: year,
    label: year,
    key: `year-key-${index}`,
  }));
};
