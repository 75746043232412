import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Footer.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <FormattedMessage id="Copyright" /> © {year}.{' '}
      <FormattedMessage id="AllRightsReserved" />
    </footer>
  );
};

export default memo(Footer);
