import { memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ChartTemplateNavbar,
  HeaderControls,
  MarketAnalytics,
} from '@components/CustomChart/components';

import useElementResize from '@hooks/useElementResize';

import styles from './CustomChartTemplate.module.scss';

export const CHART = 'chart';
export const ANALYTICS = 'analytics';

export const chartNavigation = [
  { title: <FormattedMessage id="Chart" />, name: CHART },
  { title: <FormattedMessage id="MarketAnalytics" />, name: ANALYTICS },
];

const CustomChartTemplate = ({
  stx,
  isFullScreen,
  config,
  toggleFullScreen,
}) => {
  const chartRef = useRef();
  const inputRef = useRef();
  const [activeTab, setActiveTab] = useState(chartNavigation[0]);

  const { height: chartHeight } = useElementResize(chartRef);

  return (
    <>
      {/* it helps to close CustomSelect menu when chart is clicked */}
      <input className={styles.hidden_input} ref={inputRef} />

      <cq-chart-instructions role="contentinfo"></cq-chart-instructions>

      <div className="ciq-nav" role="navigation">
        <ChartTemplateNavbar
          isFullScreen={isFullScreen}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          toggleFullScreen={toggleFullScreen}
        />

        <HeaderControls chartHeight={chartHeight} stx={stx} config={config} />
      </div>

      <div
        onClick={() => {
          inputRef.current?.focus();
        }}
        className="chart-wrapper"
      >
        <MarketAnalytics isHidden={activeTab.name === CHART} />

        <div ref={chartRef} className="ciq-chart-area" role="main">
          <div className="ciq-chart">
            <cq-palette-dock>
              <div className="palette-dock-container">
                <cq-drawing-palette
                  class="palette-drawing grid palette-hide"
                  docked="true"
                  orientation="vertical"
                  min-height="300"
                  cq-drawing-edit="none"
                ></cq-drawing-palette>
                <cq-drawing-settings
                  class="palette-settings"
                  docked="true"
                  hide="true"
                  orientation="horizontal"
                  min-height="40"
                  cq-drawing-edit="none"
                ></cq-drawing-settings>
              </div>
            </cq-palette-dock>

            <div className="chartContainer">
              <cq-chart-title cq-marker>
                <cq-symbol></cq-symbol>
                <cq-symbol-description></cq-symbol-description>
              </cq-chart-title>
              <cq-chartcontrol-group
                class="full-screen-show"
                cq-marker
              ></cq-chartcontrol-group>
              <cq-chart-legend></cq-chart-legend>
              <cq-loader></cq-loader>
            </div>
          </div>
        </div>

        <cq-abstract-marker cq-type="helicopter"></cq-abstract-marker>

        <cq-attribution></cq-attribution>

        <div className="cq-context-dialog">
          <cq-dialog>
            <cq-drawing-context></cq-drawing-context>
          </cq-dialog>

          <cq-dialog>
            <cq-study-context></cq-study-context>
          </cq-dialog>
        </div>

        <cq-side-panel></cq-side-panel>
      </div>
    </>
  );
};

export default memo(CustomChartTemplate);
