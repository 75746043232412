import { useContext } from 'react';

import { modalsIds } from '@constants';

import { ModalsContext } from './ModalsProvider';

const useModal = (modalId) => {
  const { modals, open, close, reset, changeParams } =
    useContext(ModalsContext);

  const openModal = (params) => {
    open(modalId, params);
  };

  const closeModal = () => {
    close(modalId);
  };

  const resetModal = () => {
    reset(modalId);
  };

  const changeModalParams = (params) => {
    changeParams(modalId, params);
  };

  const closeAllModals = () => {
    Object.values(modalsIds).forEach((modalId) => {
      if (modals[modalId].opened) {
        close(modalId);
      }
    });
  };

  return {
    modals,
    modal: modals[modalId],
    isOpen: modals[modalId]?.opened,
    openModal,
    closeModal,
    closeAllModals,
    resetModal,
    changeModalParams,
  };
};

export default useModal;
