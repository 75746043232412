import { useSelector } from 'react-redux';

import { useGetUserTradesQuery } from '@store/api/tradesAPI';
import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import { TradesApiSelectors } from '../selectors/tradesAPI.selectors';

const useGetUserTradesQueryResult = (args, options = {}) => {
  const isGuest = useSelector(isGuestUserSelector);

  const result = useGetUserTradesQuery(args, {
    ...options,
    skip: isGuest || !args?.accountId || options.skip,
    selectFromResult: (result) => ({
      ...result,
      trades: TradesApiSelectors.myTradesSelector(result),
    }),
  });

  return result;
};

export default useGetUserTradesQueryResult;
