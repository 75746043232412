import React, { memo } from 'react';

import classNames from 'classnames';

import { FormattedPercent, PersonalData } from '@shared/components';

import { YouWillEarnItem } from '@pages/WalletsPage/Staking/components/CreateStakeItemSidebar/components';

import styles from './StakeEstimationItem.module.scss';

const StakeEstimationItem = ({ amount, period, percent, coinSymbol }) => (
  <>
    <span className={styles.percent}>
      <FormattedPercent percent={percent} removeZeroEnd />%
    </span>

    <br />

    <span className={classNames('text-truncate', styles.value)}>
      <PersonalData
        data={
          <YouWillEarnItem
            coinSymbol={coinSymbol}
            periodPercent={percent / 100}
            watchAmountValue={amount}
            watchPeriod={period}
          />
        }
      />
    </span>
  </>
);

export default memo(StakeEstimationItem);
