import React from 'react';

// Import necessary ChartIQ library files
import { CIQ } from 'chartiq/js/advanced';
import 'chartiq/js/components';
import 'chartiq/js/addOns';
import getDefaultConfig from 'chartiq/js/defaultConfiguration';

import ChartTemplate from '@libs/chartiq/Chart/Template';

export { CIQ };

/**
 * This is a fully functional example showing how to load a chart with complete user interface.
 *
 * @export
 * @class Core
 * @extends {React.Component}
 */
export default class Core extends React.Component {
  /**
   * @constructor
   * @param {object} [props] React props
   * @param {object} [props.config] Configuration used for the chart.
   * @param {object} [props.resources] Object of resources passed into configuration to be applied
   * @param {Core~chartInitialized} [props.chartInitialized] Callback that fires when the chart is created
   */
  constructor(props) {
    super(props);
    const { config, resources } = props;

    this.container = React.createRef();

    const configObj = getDefaultConfig(resources);
    CIQ.extend(configObj, config);
    this.config = configObj;

    this.stx = null;
    this.uiContext = null;
  }

  componentDidMount() {
    if (this.init) return;
    const container = this.container.current;
    const { chartInitialized } = this.props;
    const { config } = this;

    portalizeContextDialogs(container);
    // Delay the call to createChartAndUI so any other chart components on the page
    // using multi chart setup have a chance to call portalizeContextDialogs
    window.setTimeout(() => {
      const uiContext = (this.uiContext = this.createChartAndUI({
        container,
        config,
      }));
      const chartEngine = (this.stx = uiContext.stx);

      this.setState({ stx: chartEngine, UIContext: uiContext });

      if (chartInitialized) {
        chartInitialized({ chartEngine, uiContext });
      }
    }, 0);

    this.init = true;
    this.rendered = false;
  }

  componentWillUnmount() {
    // Destroy the ChartEngine instance when unloading the component.
    // This will stop internal processes such as quotefeed polling.
    if (!this.stx || !this.rendered) return;

    this.stx.destroy();
    this.stx.draw = () => {};
  }

  createChartAndUI({ container, config }) {
    return new CIQ.UI.Chart().createChartAndUI({ container, config });
  }

  render() {
    this.rendered = true;
    const {
      props: { children },
      config,
    } = this;

    let template = <ChartTemplate config={config} />;
    const childrenCount = React.Children.count(children);
    if (childrenCount === 1) {
      template = React.cloneElement(children, { config });
    } else if (childrenCount > 1) {
      template = children;
    }
    return <cq-context ref={this.container}>{template}</cq-context>;
  }
}

/**
 * For applications that have more then one chart, keep single dialog of the same type
 * and move it outside context node to be shared by all chart components
 */
function portalizeContextDialogs(container) {
  container.querySelectorAll('cq-dialog').forEach((dialog) => {
    dialog.remove();
    if (!dialogPortalized(dialog)) {
      document.body.appendChild(dialog);
    }
  });
}

function dialogPortalized(el) {
  const tag = el.firstChild.nodeName.toLowerCase();
  let result = Array.from(document.querySelectorAll(tag)).some(
    (el) => !el.closest('cq-context'),
  );
  return result;
}

/**
 * @callback Core~chartInitialized
 * @param {CIQ.ChartEngine} chartEngine
 * @param {CIQ.UI.Context} uiContext
 */

// Adjustments to compensate for when webpack config is not available
(function initDynamicShare() {
  // Decorate the library function to avoid copying html2canvas.min.js to distribution to js/thirdparty directory
  const fullChart2PNG = CIQ.Share.fullChart2PNG;
  CIQ.Share.fullChart2PNG = function (stx, params, cb) {
    import('chartiq/js/thirdparty/html2canvas.min.js').then(() => {
      fullChart2PNG(stx, params, cb);
    });
  };
})();
