import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classnames from 'classnames';

import Button from '@components/Button/Button';
import Modal from '@components/modals/Modal';

import useModal from '@hooks/useModal/useModal';

import { isTranslationExists } from '@utils/translations';

import { NOOP, modalsIds } from '@constants';

import useOrderFields from './hooks/useOrderFields';

import styles from './ConfirmTradeModal.module.scss';

const ConfirmTradeModal = () => {
  const intl = useIntl();

  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.CONFIRM_TRADE_MODAL,
  );

  const { onSubmit = NOOP } = modal.params;

  const [isLoading, setIsLoading] = useState(false);
  const orderFields = useOrderFields(modal.params);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await onSubmit();
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }

    closeModal();
  };

  return (
    <Modal
      className="modal-sm"
      visible={isOpen}
      onClose={closeModal}
      afterClose={resetModal}
      title={<FormattedMessage id="ConfirmYourOrder" />}
      footer={
        <div>
          {isLoading ? (
            <Button isLoading fill primary rounded />
          ) : (
            <div className="d-flex justify-content-between">
              <Button fill primary rounded onClick={handleSubmit}>
                <FormattedMessage id="YesConfirm" />
              </Button>

              <Button
                fill
                secondary
                rounded
                onClick={closeModal}
                className="ms-10"
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </div>
          )}
        </div>
      }
    >
      <div className="d-flex flex-column">
        {orderFields.map(({ name, value }, index) => (
          <div
            key={`${name}_${index}`}
            className={classnames('d-flex justify-content-between', {
              [styles.field_title]: !value,
            })}
          >
            <span
              className={classnames(
                styles.field_name,
                'text-capitalize fs-14 fw-500',
              )}
            >
              {isTranslationExists(intl, name)
                ? `${intl.formatMessage({ id: name })}:`
                : name}
            </span>
            <span className={classnames(styles.field_value, 'fs-14 fw-600')}>
              {value}
            </span>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default memo(ConfirmTradeModal);
