import { memo } from 'react';

import classnames from 'classnames';

import { FieldController } from '@shared/components';

const RadioButton = ({
  label,
  children,
  className,
  controllerRef,
  isError,
  errorMessage,
  ...rest
}) => {
  const radioButtonId = rest.name ?? 'v2-radio-button';

  return (
    <>
      <div className={classnames('v2-radio-button', className)}>
        <input id={radioButtonId} type="radio" {...rest} />

        <label
          htmlFor={radioButtonId}
          className={classnames('v2-radio-button__label', {
            'v2-radio-button__label--unchecked': !rest.checked,
          })}
        >
          {label}
        </label>
      </div>

      {children}
    </>
  );
};

export const RadioButtonController = memo(({ onChange, ...props }) => {
  return <FieldController {...props} component={RadioButton} />;
});

export default memo(RadioButton);
