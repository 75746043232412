import React, { memo, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import RCDrawer from 'rc-drawer';

import 'rc-drawer/assets/index.css';
import { useScroll } from '@shared/hooks';
import { ButtonArrow } from '@shared/ui';
import {
  ARROW_DIRECTION,
  ARROW_TYPE,
} from '@shared/ui/ButtonArrow/ButtonArrow';

import { NOOP } from '@constants';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';

import styles from './SideBar.module.scss';

const SideBar = ({
  children,
  customStyles = {},
  className,
  wrapperClassName,
  arrowClassName,
  container,
  title,
  withCloseButton,
  withBackButton,
  onChange = NOOP,
  onBack = NOOP,
  onClose = NOOP,
  isManualScrollControl,
  ...rest
}) => {
  const sidebarRef = useRef();
  const { enableScroll, disableScroll } = useScroll();

  const handleStateChange = (isOpen) => {
    if (isOpen) {
      !isManualScrollControl && disableScroll();

      if (SideBar.openedSideBar) {
        SideBar.openedSideBar.onClose();
      }
      SideBar.openedSideBar = sidebarRef.current;
    } else {
      !isManualScrollControl && enableScroll();

      if (
        SideBar.openedSideBar &&
        SideBar.openedSideBar.props.className ===
          sidebarRef.current.props.className
      ) {
        SideBar.openedSideBar = undefined;
      }
    }

    onChange(isOpen);
  };

  useEffect(() => {
    return () => {
      SideBar.openedSideBar = undefined;
    };
  }, []);

  return (
    <RCDrawer
      ref={sidebarRef}
      className={classnames(styles.sidebar, customStyles.sidebar, className)}
      wrapperClassName={classnames(
        styles.sidebar__wrapper,
        customStyles.sidebar__wrapper,
        wrapperClassName,
      )}
      onChange={handleStateChange}
      onClose={onClose}
      getContainer={() =>
        document.querySelector(container || '#sidebar-container')
      }
      {...rest}
    >
      <div
        className={classnames(
          'v2-sidebar__content',
          styles.sidebar__content,
          customStyles.sidebar__content,
        )}
      >
        <div className="d-flex align-items-center justify-content-between py-24">
          {withBackButton && (
            <ButtonArrow
              arrowType={ARROW_TYPE.SHORT}
              direction={ARROW_DIRECTION.LEFT}
              inversion
              variant="text"
              onClick={onBack}
            >
              <FormattedMessage id="Back" />
            </ButtonArrow>
          )}
          {withCloseButton && (
            <div
              className={classnames(
                'ms-auto',
                styles.sidebar__close_button,
                customStyles.sidebar__close_button,
              )}
            >
              <div
                className={classnames(
                  styles.sidebar__close_button_icon,
                  customStyles.sidebar__close_button_icon,
                )}
                onClick={onClose}
              >
                <CrossThinIconSVG />
              </div>
            </div>
          )}
        </div>

        {title && (
          <h2
            className={classnames(
              'v2-sidebar__title',
              styles.sidebar__title,
              customStyles.sidebar__title,
            )}
          >
            {title}
          </h2>
        )}

        {children}
      </div>
    </RCDrawer>
  );
};

SideBar.openedSideBar = undefined;

export default memo(SideBar);
