import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import cx from 'classnames';
import { partition } from 'lodash';
import { getCountryCallingCode } from 'react-phone-number-input/input';

import {
  CountrySidebarItem,
  SearchBar,
} from '@shared/components/CountriesCodeSelect/components';
import {
  ORDERED_COUNTRIES_CODES,
  RECOMMENDED_COUNTRIES_CODES,
} from '@shared/components/CountriesCodeSelect/countriesCodeSelect.constants';

import SideBar from '@components/SideBar';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { NOOP, sidebarIds } from '@constants';

import s from './CountriesCodeSidebar.module.scss';

const CountriesCodeSidebar = ({
  onSelect,
  selectedCountryCode,
  onCloseSidebar = NOOP,
}) => {
  const intl = useIntl();

  const { closeSidebar, isOpen } = useSidebar(sidebarIds.COUNTRY_CODES);

  const { isMobile, isTablet } = useBreakpoints();

  const [searchKeyword, setSearchKeyword] = useState('');

  const searchBarRef = useRef();

  useEffect(() => {
    if (!isOpen || !searchBarRef.current) return;

    setTimeout(() => searchBarRef.current.focus());
  }, [isOpen]);

  const handleClose = () => {
    closeSidebar();
    onCloseSidebar();
  };

  const [recommendedCountries, otherCountries] = useMemo(
    () =>
      partition(ORDERED_COUNTRIES_CODES, (code) =>
        RECOMMENDED_COUNTRIES_CODES.includes(code),
      ),
    [],
  );

  const allCountries = useMemo(() => {
    const allCodes = [...recommendedCountries, null, ...otherCountries];

    const getCountryName = (code) =>
      intl.formatDisplayName(code, { type: 'region' });

    return allCodes.map((code) => {
      if (!code) return null;
      return {
        code,
        countryName: getCountryName(code),
        phoneCode: `+${getCountryCallingCode(code)}`,
        fullInfo: `${code}${getCountryName(code)}+${getCountryCallingCode(
          code,
        )}`.toLowerCase(),
      };
    });
  }, [recommendedCountries, otherCountries]);

  const filteredCountries = useMemo(() => {
    if (!searchKeyword) return allCountries;

    return allCountries.filter((country) =>
      country?.fullInfo?.includes(searchKeyword.toLowerCase()),
    );
  }, [allCountries, searchKeyword]);

  const handleSearch = (value) => {
    setSearchKeyword(value);
  };

  const handleSelectCountry = (countryCode) => {
    onSelect(countryCode);
    handleClose();
  };

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleClose}
      withCloseButton={isTablet || isMobile}
      withBackButton
      title={intl.formatMessage({ id: 'SelectCountry' })}
    >
      <div className="mt-16">
        <SearchBar ref={searchBarRef} onSearch={handleSearch} />
      </div>

      <ul className={s.countries_list}>
        {filteredCountries.map((country, index) => (
          <Fragment key={`country-code-${index}`}>
            {country ? (
              <li
                className={cx({
                  [s.active]: country.code === selectedCountryCode,
                })}
                onClick={() => handleSelectCountry(country.code)}
              >
                <CountrySidebarItem
                  code={country.code}
                  countryName={country.countryName}
                  phoneCode={country.phoneCode}
                />
              </li>
            ) : (
              <div className={s.separator} />
            )}
          </Fragment>
        ))}
      </ul>
    </SideBar>
  );
};

export default CountriesCodeSidebar;
