import { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';

import cx from 'classnames';

import { FormController } from '@shared/components';
import { Input } from '@shared/ui';

import { ReactComponent as EyeClosed } from '@assets/images/icons/eye-closed.svg';
import { ReactComponent as EyeOpen } from '@assets/images/icons/eye-open.svg';

import s from './PasswordInput.module.scss';

const PasswordInput = forwardRef(({ ...props }, ref) => {
  const intl = useIntl();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisible = () =>
    setIsPasswordVisible((prevState) => !prevState);

  const renderSuffix = () => {
    return (
      <div className={cx(s.eye_icon)} onClick={togglePasswordVisible}>
        {isPasswordVisible ? <EyeClosed /> : <EyeOpen />}
      </div>
    );
  };

  return (
    <Input
      ref={ref}
      type={isPasswordVisible ? 'text' : 'password'}
      suffix={renderSuffix()}
      placeholder={intl.formatMessage({ id: 'YourPassword' })}
      {...props}
    />
  );
});

export const PasswordInputController = (props) => {
  return <FormController component={PasswordInput} {...props} />;
};
export default PasswordInput;
