import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import { AccountModal } from '@shared/components';
import { Button } from '@shared/ui';

import useModal from '@hooks/useModal/useModal';

import { NOOP, modalsIds } from '@constants';

import { ReactComponent as InfoIconIconSVG } from '@images/icons/info-icon.svg';

import styles from './ApiKeyDeleteModal.module.scss';

const DeleteApiKeyModal = () => {
  const {
    modal,
    isOpen,
    closeModal: closeDeleteAPIKeyModal,
    resetModal,
  } = useModal(modalsIds.DELETE_API_KEY_MODAL);

  const { apiKey, onSubmit = NOOP } = modal.params;

  const { openModal: openTwoFAModal } = useModal(modalsIds.TWO_FA_MODAL);

  const handleSuccess = () => {
    closeDeleteAPIKeyModal();

    openTwoFAModal({
      onSubmit,
    });
  };

  return (
    <AccountModal
      title={<FormattedMessage id="DeleteApi" />}
      isOpen={isOpen}
      onClose={closeDeleteAPIKeyModal}
      afterClose={resetModal}
      footer={
        <div className="d-flex gap-10">
          <Button
            fill
            color="secondary"
            onClick={closeDeleteAPIKeyModal}
            size="l"
          >
            <FormattedMessage id="Cancel" />
          </Button>

          <Button
            fill
            color="dangerous"
            variant="outlined"
            onClick={handleSuccess}
            size="l"
          >
            <FormattedMessage id="Delete" />
          </Button>
        </div>
      }
    >
      <div className="d-flex flex-column ">
        <div
          className={classnames(
            'd-flex gap-14 py-16 px-26 mt-16 mb-24',
            styles.warning_card,
          )}
        >
          <div>
            <InfoIconIconSVG className={styles.icon} width={20} height={20} />
          </div>

          <span className={classnames('fw-600 fs-16', styles.warning_text)}>
            <FormattedMessage
              id="AreYouSureYouWantToDeleteApiKey"
              values={{ apiKeyName: apiKey?.name }}
            />
          </span>
        </div>

        <span>
          <FormattedMessage id="ApiKey" />:
        </span>

        <span
          className={classnames(
            'fw-600 fs-16 text-wrap',
            styles.api_public_key,
          )}
        >
          {apiKey?.public_key}
        </span>
      </div>
    </AccountModal>
  );
};

export default memo(DeleteApiKeyModal);
