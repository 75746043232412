import { forwardRef, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { FormController } from '@shared/components';
import { Button, Input } from '@shared/ui';
import { acceptedExt } from '@shared/utils/files';

import { ReactComponent as ClipIconSVG } from '@icons/clip-icon.svg';

import s from './InputFile.module.scss';

/**
 * This InputFile component can upload only ONE file
 * */

const InputFile = forwardRef(
  (
    {
      maxSizeMb,
      isInvalid,
      suffix,
      underlined,
      bordered,
      rounded,
      size,
      inversion,
      ...props
    },
    ref,
  ) => {
    const styleProps = { underlined, bordered, rounded, size, inversion };

    return (
      <Input
        isInvalid={isInvalid}
        customStyles={s}
        prefix={
          <div className="d-flex align-items-center gap-10">
            <AttachButton ref={ref} inversion={inversion} {...props} />
            {!!props.value?.name && (
              <div className={s.input_file__name}>{props.value?.name}</div>
            )}
          </div>
        }
        suffix={!!props.value?.name && suffix}
        disabled
        {...styleProps}
      />
    );
  },
);

const AttachButton = forwardRef(
  ({ onChange, acceptTypes = [], inversion, ...props }, ref) => {
    const hiddenInputRef = useRef();

    const handleClickLabel = () => hiddenInputRef.current?.click();

    const handleChange = (event) => onChange(event.target.files[0]);

    return (
      <div>
        <Button
          type="button"
          customStyles={s}
          size="s"
          variant="outlined"
          onClick={handleClickLabel}
          inversion={inversion}
        >
          <div className="d-flex align-items-center gap-10">
            <ClipIconSVG
              className={cx(s.clip_icon, {
                [s.clip_icon__inversion]: inversion,
              })}
            />
            <FormattedMessage id="Attach" />
            ...
          </div>
        </Button>
        <input
          ref={(e) => {
            ref(e);
            hiddenInputRef.current = e;
          }}
          className={s.input_file}
          type="file"
          accept={acceptedExt(acceptTypes)}
          {...props}
          onChange={handleChange}
          value={props.value?.filename}
        />
      </div>
    );
  },
);
export default InputFile;

export const InputFileController = (props) => {
  return <FormController component={InputFile} {...props} />;
};
