import { useGetKycStatusQuery } from '@api/profileAPI';
import { ProfileApiSelectors } from '@api/selectors';

const useGetKycStatusQueryResult = (args, options) => {
  const { status, isLoading: getKycStatusLoading } = useGetKycStatusQuery(
    undefined,
    {
      ...options,
      selectFromResult: (result) => ({
        ...result,
        status: ProfileApiSelectors.kycStatusSelector(result),
      }),
    },
  );

  return {
    status,
    getKycStatusLoading,
  };
};

export default useGetKycStatusQueryResult;
