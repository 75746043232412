import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { Button } from '@shared/ui';

import { WALLETS_ROUTES } from 'src/routes.constants';

import styles from './StartContractItem.module.scss';

const StartContractItem = ({ coinSymbol, selectedPeriod }) => {
  const navigate = useNavigate();

  const handleStartContract = () => {
    navigate(WALLETS_ROUTES.TRADING_BOTS_START, {
      state: { coinSymbol, selectedPeriod },
    });
  };

  return (
    <div className={styles.container}>
      <Button
        type="button"
        size="s"
        onClick={handleStartContract}
        customStyles={styles}
      >
        <FormattedMessage id="StartContract" />
      </Button>
    </div>
  );
};

export default memo(StartContractItem);
