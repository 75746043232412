import React, { memo, useId, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Table from '@components/gridTables/Table';

import { useVisaCard } from '@pages/AccountPage/Overview/OverviewEarnWidgets/hooks';

import { currencySelector } from '@store/settings/settingsSelectors';

import visaCardTableColumns from '../VisaCardTableColumns';

const VisaCardTable = () => {
  const id = useId();

  const { cardProfits, isSuccess } = useVisaCard();
  const { symbol, value } = useSelector(currencySelector);

  const columns = useMemo(
    () =>
      visaCardTableColumns({
        selectedCurrency: value,
        selectedCurrencySymbol: symbol,
      }),
    [symbol, value],
  );

  return (
    <Table
      id={id}
      className="account-tables-swipe my-20"
      columns={columns}
      data={cardProfits}
      wideRowsS
      stripedRows={false}
      isLoading={!isSuccess}
    />
  );
};

export default memo(VisaCardTable);
