import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

import { get24hAgoInSec } from '@utils';
import { getFormData } from '@utils/api';

import { DEFAULT_REQUEST_LIMIT } from '@constants';

export const ordersAPI = createApi({
  reducerPath: 'ordersApi',
  baseQuery: client,
  tagTypes: ['OpenOrder', 'OpenHistory'],
  endpoints: (builder) => ({
    createNewOrder: builder.mutation({
      query: ({ body, queryParams }) => {
        return {
          url: `orders/${queryParams.marketId}/${queryParams.side}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['OpenOrder'],
    }),
    getOpenOrders: builder.query({
      query: ({
        pair = 'all',
        accountId,
        since = 0,
        page = 1,
        limit = DEFAULT_REQUEST_LIMIT,
      } = {}) => {
        return {
          url: `orders/${pair}/open`,
          params: { account: accountId, since, page, limit },
        };
      },
      transformResponse: (response) => ({
        orders: response.data,
        meta: response.meta,
      }),
      providesTags: ['OpenOrder'],
    }),
    getClosedOrders: builder.query({
      query: ({
        pair = 'all',
        accountId,
        since = 0,
        page = 1,
        limit = DEFAULT_REQUEST_LIMIT,
      } = {}) => {
        return {
          url: `orders/${pair}/closed`,
          params: { account: accountId, since, page, limit },
        };
      },
      transformResponse: (response) => ({
        orders: response.data,
        meta: response.meta,
      }),
      providesTags: ['OpenOrder'],
    }),
    getOrderHistory: builder.query({
      query: ({
        accountId,
        page = 1,
        limit = DEFAULT_REQUEST_LIMIT,
        fromDate = get24hAgoInSec(),
        toDate = null,
        status = 'closed',
        sideParam,
        pairFrom,
        pairTo,
      }) => {
        return {
          url: `/users/orders`,
          params: {
            status,
            account: accountId,
            page,
            fromDate,
            toDate,
            limit,
            sideParam,
            market_coin_symbol: pairFrom,
            quote_coin_symbol: pairTo,
          },
        };
      },
      transformResponse: (response) => ({
        orders: response.Orders,
        trades: response.Trades,
        meta: response.Meta,
      }),
      providesTags: ['OpenHistory'],
    }),
    cancelOpenOrder: builder.mutation({
      query: ({ marketId, orderId }) => ({
        url: `/orders/${marketId}/${orderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OpenOrder'],
    }),
    replaceOrder: builder.mutation({
      query: ({ marketId, orderId, side, order }) => ({
        url: `/orders/${marketId}/${side}/${orderId}`,
        method: 'POST',
        body: getFormData(order),
      }),
      invalidatesTags: ['OpenOrder'],
    }),
    cancelAllOrders: builder.mutation({
      query: (marketId = null) => ({
        url: `users/orders/cancelAll${marketId ? `/${marketId}` : ''}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OpenOrder'],
    }),
    exportFile: builder.query({
      query: ({
        accountId,
        status,
        page = 1,
        fromDate = get24hAgoInSec(),
        toDate = null,
        format,
        type = 'closed',
        sideParam,
        pairFrom,
        pairTo,
      }) => {
        return {
          url: '/users/orders/export',
          params: {
            account: accountId,
            status,
            page,
            fromDate,
            toDate,
            format,
            type,
            sideParam,
            market_coin_symbol: pairFrom,
            quote_coin_symbol: pairTo,
          },
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useCreateNewOrderMutation,
  useGetOpenOrdersQuery,
  useLazyExportFileQuery,
  useGetOrderHistoryQuery,
  useLazyGetOrderHistoryQuery,
  useLazyGetOpenOrdersQuery,
  useCancelOpenOrderMutation,
  useCancelAllOrdersMutation,
  useGetClosedOrdersQuery,
  useReplaceOrderMutation,
} = ordersAPI;
