import { memo } from 'react';

import { PersonalData } from '../PersonalData';

import FormattedCurrency from './FormattedCurrency';

const PersonalFormattedCurrency = ({
  value,
  currency,
  formatOptions = {},
  ...props
}) => {
  return (
    <PersonalData {...props} data={value}>
      {(personalValue, isShowSensitiveData) =>
        isShowSensitiveData ? (
          <FormattedCurrency
            value={value}
            currency={currency}
            formatOptions={formatOptions}
          />
        ) : (
          personalValue
        )
      }
    </PersonalData>
  );
};

export default memo(PersonalFormattedCurrency);
