import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  InfoWidget,
  PersonalFormattedCurrency,
  PersonalPercentChange,
  Widget,
} from '@shared/components';

import PersonalProgressBar from '@components/PersonalProgressBar';

import styles from './AvailableEstimationWidget.module.scss';
import mockData from 'src/mock.json';

const AvailableEstimationWidget = () => {
  const { amount, currency, percent, percentages, inOrdersAmount } =
    mockData.AvailableEstimationWidget;

  return (
    <Widget className={styles.available_estimation}>
      <div className="d-flex justify-content-between">
        <InfoWidget
          customStyles={styles}
          title={<FormattedMessage id="AvailableEstimation" />}
          value={
            <PersonalFormattedCurrency currency={currency} value={amount} />
          }
          suffix={<span className={'value-suffix'}>{currency}</span>}
        />

        <PersonalPercentChange
          containerClassName={styles.percent}
          percentValue={percent}
        />
      </div>
      <div className="mb-2">
        <PersonalProgressBar
          isPill
          color="white"
          height={4}
          percentages={percentages}
          isShowPercents={false}
        />
      </div>

      <div className={styles.in_orders}>
        <div className="text-capitalize">
          <FormattedMessage id="InOrders" />:
        </div>

        <div className={styles.value}>
          <PersonalFormattedCurrency
            currency={currency}
            value={inOrdersAmount}
          />{' '}
          {currency}
        </div>
      </div>
    </Widget>
  );
};

export default memo(AvailableEstimationWidget);
