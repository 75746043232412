import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

export const botsAPI = createApi({
  reducerPath: 'botsAPI',
  baseQuery: client,
  tagTypes: ['Bots', 'BotsPnl', 'ArchivedBots'],
  endpoints: (builder) => ({
    getBots: builder.query({
      query: () => ({ method: 'GET', url: 'bots/' }),
      providesTags: ['Bots'],
    }),
    createNewBotVersionSettings: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: 'bots/',
        body,
      }),
      invalidatesTags: ['Bots', 'BotsPnl', 'ArchivedBots'],
    }),
    getArchivedBots: builder.query({
      query: () => ({
        method: 'GET',
        url: 'bots/',
        params: { status: 'archived' },
      }),
      providesTags: ['ArchivedBots'],
    }),
    getBotsPnl: builder.query({
      query: () => ({ method: 'GET', url: 'bots/pnl' }),
      providesTags: ['BotsPnl'],
    }),
    updateBotStatus: builder.mutation({
      query: ({ id, status }) => ({
        method: 'POST',
        url: 'bots/status',
        body: { id, status },
      }),
      invalidatesTags: ['Bots', 'BotsPnl', 'ArchivedBots'],
    }),
    getBotAnalyticsNumbers: builder.query({
      query: ({ botId }) => ({
        method: 'GET',
        url: `bots/analytics/${botId}/numbers`,
      }),
    }),
    getBotAnalyticsByVersion: builder.query({
      query: ({ botId, versionId }) => ({
        method: 'GET',
        url: `bots/analytics/${botId}/version/${versionId}`,
      }),
    }),
    getBotsSettings: builder.query({
      query: () => ({
        method: 'GET',
        url: `bots/settings`,
      }),
      transformResponse: (response) => {
        const { data } = response;

        return {
          ...response,
          data: {
            trend: data.trend,
            grid: data.grid,
            limits: data.Limits,
          },
        };
      },
    }),
    createBot: builder.mutation({
      query: (body) => ({
        url: 'bots/create',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetBotsQuery,
  useLazyGetBotsQuery,
  useGetBotsPnlQuery,
  useLazyGetBotsPnlQuery,
  useUpdateBotStatusMutation,
  useGetArchivedBotsQuery,
  useGetBotAnalyticsNumbersQuery,
  useGetBotAnalyticsByVersionQuery,
  useGetBotsSettingsQuery,
  useCreateBotMutation,
  useCreateNewBotVersionSettingsMutation,
} = botsAPI;
