import { memo, useMemo } from 'react';

import Table from '@components/gridTables/Table';

import useBreakpoints from '@hooks/useBreakpoints';

import { useLastTransactionsTable } from '../../hooks';
import { LastTransactionsTableColumns } from '../LastTransactionsTableColumns';

const LastTransactionsTable = () => {
  const { isTabletDown } = useBreakpoints();

  const { lastTransactionsTableData, isLastTransactionsTableSuccess } =
    useLastTransactionsTable();

  const columns = useMemo(
    () => LastTransactionsTableColumns({ isSwipeableTable: isTabletDown }),
    [isTabletDown],
  );

  return (
    <Table
      id="last-transactions-table"
      columns={columns}
      data={lastTransactionsTableData}
      wideRowsL
      isSwipeable={isTabletDown}
      isLoading={!isLastTransactionsTableSuccess}
      className="account-tables-swipe"
      swipeableTableProps={{
        stickyColumns: 1,
        breakpoints: [
          [600, 2],
          [700, 3],
          [900, 4],
          [1024, 5],
        ],
      }}
    />
  );
};

export default memo(LastTransactionsTable);
