import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useForm, useWatch } from 'react-hook-form';

import { GOOGLE_OTP_NUMBER_LENGTH } from '@shared/constants';
import { Button, OTPInputController } from '@shared/ui';

import { AUTH_ROUTES } from 'src/routes.constants';

const GoogleTwoFAForm = ({ isLoading, email, onSubmit }) => {
  const intl = useIntl();

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      emailCode: '',
    },
  });

  const { control, handleSubmit } = methods;

  const watchEmailCode = useWatch({ control, name: 'emailCode' });

  const submit = ({ emailCode }) => onSubmit({ OTPCode: emailCode });

  return (
    <form onSubmit={handleSubmit(submit)}>
      <OTPInputController
        control={control}
        name="emailCode"
        label={`${intl.formatMessage({ id: 'GoogleAuthenticationCode' })}:`}
        numInputs={GOOGLE_OTP_NUMBER_LENGTH}
        size="xl"
        rounded
        {...methods}
      />

      <div className="mt-32">
        <Button
          isLoading={isLoading}
          type="submit"
          fill
          size="l"
          disabled={watchEmailCode.length < GOOGLE_OTP_NUMBER_LENGTH}
        >
          <FormattedMessage id="Submit" />
        </Button>
      </div>

      <div className="mt-30">
        <Button
          variant="text"
          navigation={{ to: AUTH_ROUTES.RESET_2FA, state: { email } }}
          fill
        >
          <FormattedMessage id="TwoFactorAuthenticationLost" />?
        </Button>
      </div>
    </form>
  );
};

export default memo(GoogleTwoFAForm);
