import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Select } from '@shared/ui';

import { useEditAccountMutation } from '@store/api/accountsAPI';
import { useDefaultAccount, useSpotAccounts } from '@store/api/hooks';
import { isAccountThemeSelector } from '@store/settings/settingsSelectors';

import useTheme from '@hooks/useTheme';

import HeaderItem from '../HeaderItem';

import SelectedAccountItem from './SelectedAccountItem';

import styles from './AccountDropdown.module.scss';

const AccountsDropdown = ({ withDivider = false, headerItemClassName }) => {
  const [editAccount] = useEditAccountMutation();

  const isAccountTheme = useSelector(isAccountThemeSelector);

  const { themeStyles } = useTheme();

  const {
    defaultSpotAccount,
    isDefaultSpotAccountSuccess,
    isDefaultSpotAccountFetching,
  } = useDefaultAccount();
  const { spotAccountsInfo, spotAccountsInfoMap, isAccountsSuccess } =
    useSpotAccounts();

  const options = useMemo(() => {
    return spotAccountsInfoMap.map((spotAccount) => ({
      value: spotAccount.id,
      label: spotAccount.title,
    }));
  }, [spotAccountsInfoMap]);

  const specificComponents = useMemo(() => {
    return {
      SingleValue: (props) => (
        <SelectedAccountItem
          {...props}
          isLoading={isDefaultSpotAccountFetching}
        />
      ),
    };
  }, [isDefaultSpotAccountFetching]);

  const selectStyles = useMemo(
    () => ({
      option: (styles, { isSelected }) => ({
        ...styles,
        color: isSelected ? themeStyles['green'] : styles.backgroundColor,
      }),
      menuPortal: {
        width: 'max-content',
        minWidth: '100%',
      },
      menu: {
        marginTop: '6px',
        width: 'unset',
        fontSize: '16px',
        fontWeight: '500',
      },
      dropdownIndicator: {
        display: 'none',
      },
    }),
    [themeStyles],
  );

  const handleChangeAccount = useCallback(
    ({ value }) => {
      const accountInfo = spotAccountsInfo[value];

      const { origin } = accountInfo;

      editAccount({
        ...origin,
        is_default: true,
      });
    },
    [spotAccountsInfo],
  );

  if (!isDefaultSpotAccountSuccess || !isAccountsSuccess) {
    return null;
  }

  return (
    <HeaderItem withDivider={withDivider} className={headerItemClassName}>
      <Select
        isSearchable={false}
        options={options}
        inversion={isAccountTheme}
        specificComponents={specificComponents}
        value={options.find(({ value }) => value === defaultSpotAccount?.id)}
        onChange={handleChangeAccount}
        styles={selectStyles}
        customStyles={styles}
      />
    </HeaderItem>
  );
};

export default memo(AccountsDropdown);
