import React, { memo, useEffect, useState } from 'react';

const SwiperSlideSelect = ({ children, activeIndex }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('click', closeMenu);

    document.addEventListener('keydown', handleEscClick);

    return () => {
      document.removeEventListener('click', closeMenu);
      document.removeEventListener('keydown', handleEscClick);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      closeMenu();
    }, 0);
  }, [activeIndex]);

  const handleEscClick = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  const handleTouchStart = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <span onTouchStart={handleTouchStart} onClick={handleClick}>
      {React.cloneElement(children, { menuIsOpen: isOpen })}
    </span>
  );
};

export default memo(SwiperSlideSelect);
