import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useFormContext, useWatch } from 'react-hook-form';

import {
  InputCurrenciesSelect,
  transformToCurrencyOptions,
} from '@shared/components';
import { COIN_TYPE, FIAT_CURRENCIES } from '@shared/constants';
import { useSpotTotalBalances } from '@shared/hooks';
import { TextareaController } from '@shared/ui';
import { SelectController } from '@shared/ui/Select/Select';

import { SideBarLabel } from '@components/SideBar/components';

import {
  SelectedUserCard,
  FavoriteUserCard,
} from '@pages/WalletsPage/VisaCardDetails/components';
import { confirmTransferMoneyDetailsSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';
import { visaCardDetailsActions } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.slice';

import { useCoinsInfo } from '@api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { format } from '@utils/numbers';

import { ReactComponent as SearchIconSVG } from '@icons/search-icon.svg';

import s from './TransferToUser.module.scss';

export const TRANSFER_TO_USER_FIELD_NAMES = {
  USER: 'user',
  CURRENCY: 'user_currency',
  AMOUNT: 'user_amount',
  MESSAGE: 'user_message',
};

const TransferToUser = () => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { isMobile } = useBreakpoints();

  const methods = useFormContext();

  const transferMoneyDetails = useSelector(confirmTransferMoneyDetailsSelector);

  const userWatch = useWatch({
    control: methods.control,
    name: TRANSFER_TO_USER_FIELD_NAMES.USER,
  });

  const currencyWatch = useWatch({
    control: methods.control,
    name: TRANSFER_TO_USER_FIELD_NAMES.CURRENCY,
  });

  const [users, setUsers] = useState([]);
  const [favoriteUsers, setFavoriteUsers] = useState([]);

  const formattedUserOption = (user) => {
    return (
      <div className={s.user_option}>
        <span>{user.name}</span>
        {!isMobile && (
          <span className={s.user_option__email}>{user.email}</span>
        )}
      </div>
    );
  };

  const { coinsInfoMap } = useCoinsInfo({
    orderByTypeDirection: 'asc',
    filterByType: COIN_TYPE.FIAT,
  });

  const currenciesMemo = useMemo(
    () =>
      transformToCurrencyOptions({
        currencies: coinsInfoMap,
        inversion: true,
        isShowFullName: false,
      }),
    [coinsInfoMap],
  );

  const defaultCurrency = useMemo(
    () => currenciesMemo.find((cur) => cur.value === FIAT_CURRENCIES.EUR),
    [currenciesMemo],
  );

  useEffect(() => {
    const url = 'https://jsonplaceholder.typicode.com/users';

    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        const usersMapped = res.map((user) => ({ ...user, value: user.id }));

        setFavoriteUsers(usersMapped.slice(0, 4));
        setUsers(usersMapped.slice(4));
      });
  }, []);

  useEffect(() => {
    if (!currenciesMemo.length) return;

    setTimeout(() => {
      methods.setValue(TRANSFER_TO_USER_FIELD_NAMES.CURRENCY, defaultCurrency);
    });
  }, [methods, currenciesMemo, defaultCurrency]);

  useEffect(() => {
    if (!userWatch?.email) return;

    const userData = {
      userEmail: userWatch.email,
    };

    if (transferMoneyDetails.userEmail === userWatch.email) return;

    dispatch(visaCardDetailsActions.setConfirmTransferMoneyDetails(userData));
  }, [dispatch, methods, userWatch]);

  const isSelectedUserFavorite = useMemo(() => {
    if (!favoriteUsers.length || !transferMoneyDetails.userEmail) return false;

    return favoriteUsers
      .map((user) => user.email)
      .includes(transferMoneyDetails.userEmail);
  }, [favoriteUsers, transferMoneyDetails]);

  const { totalBalance } = useSpotTotalBalances({
    coinSymbol: currencyWatch?.value,
    filedName: 'available',
  });

  const formattedSpotTotalAvailableBalance = useMemo(
    () => format(totalBalance, { precision: currencyWatch?.digits }),
    [totalBalance, currencyWatch],
  );

  const userOptions = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: 'Favorites' }),
        options: favoriteUsers,
      },
      {
        label: intl.formatMessage({ id: 'ParamountdaxContacts' }),
        options: users,
      },
    ];
  }, [intl, users, favoriteUsers]);

  const handleSelectFavoriteUser = (user) => {
    methods.clearErrors(TRANSFER_TO_USER_FIELD_NAMES.USER);

    const userData = {
      userEmail: user.email,
    };

    dispatch(visaCardDetailsActions.setConfirmTransferMoneyDetails(userData));
  };

  const handleResetUser = () => {
    methods.setValue(TRANSFER_TO_USER_FIELD_NAMES.USER, '');

    dispatch(
      visaCardDetailsActions.setConfirmTransferMoneyDetails({ userEmail: '' }),
    );
  };

  return (
    <div>
      <SelectController
        bordered
        inversion
        name={TRANSFER_TO_USER_FIELD_NAMES.USER}
        options={userOptions}
        size="m"
        formatOptionLabel={formattedUserOption}
        placeholder={
          <span className={s.users_select__placeholder}>
            <SearchIconSVG />
            <FormattedMessage id="SearchPRDXEmail" />
            ...
          </span>
        }
        isClearable
        withDropdownIndicator={false}
        rules={{
          required:
            !isSelectedUserFavorite &&
            intl.formatMessage({ id: 'FieldRequired' }),
        }}
      />
      <div className="mt-20">
        {userWatch ? (
          <SelectedUserCard user={userWatch} onClearUser={handleResetUser} />
        ) : (
          <div className={s.favorite_users_container}>
            {favoriteUsers.map((user) => (
              <div
                key={`favorite-user-${user.id}`}
                className="w-100"
                onClick={() => handleSelectFavoriteUser(user)}
              >
                <FavoriteUserCard
                  user={user}
                  isSelected={user.email === transferMoneyDetails.userEmail}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mt-20">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <SideBarLabel>
              <FormattedMessage id="Amount" />:
            </SideBarLabel>
          </div>
          <div>
            <span className={s.small_label}>
              <FormattedMessage id="AvailableBalance" />:
            </span>
            <span className={s.small_bold_label}>
              {formattedSpotTotalAvailableBalance}{' '}
              {currencyWatch?.value?.toUpperCase()}
            </span>
          </div>
        </div>

        <InputCurrenciesSelect
          inputProps={{
            bordered: false,
            name: TRANSFER_TO_USER_FIELD_NAMES.AMOUNT,
            precision: currencyWatch?.digits,
            size: 'l',
            underlined: true,
            rules: {
              required: intl.formatMessage({ id: 'FieldRequired' }),
              max: {
                value: totalBalance,
                message: intl.formatMessage({
                  id: 'YouDontHaveEnoughFunds',
                }),
              },
            },
          }}
          selectProps={{
            currencies: currenciesMemo,
            name: TRANSFER_TO_USER_FIELD_NAMES.CURRENCY,
            size: 'l',
            underlined: true,
          }}
          inversion
          {...methods}
        />
      </div>

      <div className="mt-20">
        <TextareaController
          inversion
          bordered={false}
          underlined
          maxLength={200}
          name={TRANSFER_TO_USER_FIELD_NAMES.MESSAGE}
          placeholder={`${intl.formatMessage({
            id: 'AddYourMessageOptional',
          })}...`}
        />
      </div>
    </div>
  );
};

export default TransferToUser;
