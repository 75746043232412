import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { AccountModal, Table } from '@shared/components';
import {
  HEADER_HEIGHT,
  ROW_HEIGHT,
} from '@shared/components/Table/table.constants';

import { tradesColumns as columns } from '@pages/OrderPages/OrderHistory/components/OrderHistoryTableColumns/OrderHistoryTableColumns';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import styles from './AccountOrdersHistoryTradesModal.module.scss';

const AccountOrdersHistoryTradesModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.ACCOUNT_ORDERS_HISTORY_TRADE_DETAILS,
  );

  const { data } = modal.params;

  const { isTabletDown } = useBreakpoints();

  return (
    <AccountModal
      className={classNames('modal-xl', styles.modal)}
      visible={isOpen}
      onClose={closeModal}
      afterClose={resetModal}
      title={
        <>
          <FormattedMessage id="TotalTrades" />: {data?.total_amount?.total}
          <hr className={styles.divider} />
        </>
      }
    >
      <Table
        columns={columns}
        data={data?.trades}
        headerHeight={HEADER_HEIGHT.S}
        rowHeight={ROW_HEIGHT.M}
        isSwipeable={isTabletDown}
        pinnedColumnIDs={['timestamp']}
      />
    </AccountModal>
  );
};

export default memo(AccountOrdersHistoryTradesModal);
