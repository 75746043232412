import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TooltipHelpIcon from '@components/TooltipHelpIcon';

import styles from './RiskReward.module.scss';

const RiskReward = ({ riskReward }) => {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-stretch justify-content-end gap-6 mt-6">
      <span className={styles.risk_reward}>
        <FormattedMessage id="RiskReward" />
        {': '}
        <span className={styles.risk_reward__value}>{riskReward}</span>
      </span>

      <TooltipHelpIcon
        className="d-flex"
        content={intl.formatMessage({
          id: 'RiskRewardHelpContent',
        })}
      />
    </div>
  );
};

export default memo(RiskReward);
