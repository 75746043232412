import { useIntl } from 'react-intl';

import { toast } from 'react-toastify';

import { useEditAccountMutation, useTransferMutation } from '@api/accountsAPI';
import { formatObjectKeysFromCamelToSnakeCase } from '@api/utils';

const useAccountsMutations = () => {
  const intl = useIntl();

  const [transfer, { isLoading: isTransferLoading }] = useTransferMutation();
  const [editAccount, { isLoading: isEditAccountLoading }] =
    useEditAccountMutation();

  const onTransfer = async (body) => {
    const values = formatObjectKeysFromCamelToSnakeCase(body);

    try {
      await transfer(values).unwrap();

      toast.success(
        intl.formatMessage({
          id: 'TransferBetweenSubAccountsSuccessful',
        }),
      );
    } catch (e) {
      throw Error(e);
    }
  };

  const onEditAccount = async (body) => {
    try {
      await editAccount(body).unwrap();

      toast.success(
        intl.formatMessage({
          id: 'DefaultSubAccountWasChangedSuccessfully',
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  return {
    onTransfer,
    isTransferLoading,
    onEditAccount,
    isEditAccountLoading,
  };
};

export default useAccountsMutations;
