import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Card, FORM_ACTION_ORIENTATION } from '@shared/components';
import FormActions from '@shared/components/FormActions/FormActions';
import { PasswordInputController } from '@shared/ui';

import { useProfileMutations } from '@api/hooks';

import styles from './DisableAntiPhishingCode.module.scss';

const DisableAntiPhishingCode = () => {
  const methods = useForm({ defaultValues: { antiPhishingCode: '' } });
  const { handleSubmit } = methods;
  const intl = useIntl();

  const { onDisableAntiPhishingCode, isDisableAntiPhishingCodeLoading } =
    useProfileMutations();

  const handleFormSubmit = ({ antiPhishingCode }) => {
    onDisableAntiPhishingCode({ antiPhishingCode });
  };

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    size: 'l',
  };

  return (
    <div className={cx(styles.security_card, 'security-card')}>
      <Card
        customStyles={styles}
        title={<FormattedMessage id="DisableAntiPhishingCode" />}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <PasswordInputController
            name="antiPhishingCode"
            label={`${intl.formatMessage({
              id: 'YourAntiPhishingCodeInput',
            })}:`}
            placeholder={intl.formatMessage({
              id: 'EnterCode',
            })}
            rules={{
              required: intl.formatMessage({ id: 'CodeIsRequired' }),
            }}
            {...commonFieldProps}
            {...methods}
          />

          <div className="mt-22">
            <FormActions
              submitText={<FormattedMessage id="Disable" />}
              submitButtonProps={{
                color: 'dangerous',
                isLoading: isDisableAntiPhishingCodeLoading,
                disabled: isDisableAntiPhishingCodeLoading,
              }}
              cancelButtonProps={{
                variant: 'text',
              }}
              orientation={FORM_ACTION_ORIENTATION.VERTICAL}
            />
          </div>
        </form>
      </Card>
    </div>
  );
};

export default memo(DisableAntiPhishingCode);
