import { useState } from 'react';
import { useIntl } from 'react-intl';

import { useNotifications } from '@store/api/hooks';

import { sortNotification } from '@utils/notifications';
import { getServerNotification } from '@utils/translations';

import { ITEMS_PER_PAGE_NOTIFICATIONS } from '@constants';

const useNotificationsList = () => {
  const intl = useIntl();

  const [params, setParams] = useState({
    page: 1,
    limit: ITEMS_PER_PAGE_NOTIFICATIONS,
  });

  const {
    notifications,
    meta,
    isNotificationsLoading,
    isNotificationsSuccess,
    isNotificationsFetching,
  } = useNotifications(params, {
    refetchOnMountOrArgChange: true,
  });

  const translatedNotifications = notifications.map((item) => ({
    ...item,
    title: getServerNotification(intl, item.title),
    message: getServerNotification(intl, item.message),
  }));

  const { todayNotifications, earlierNotifications } = sortNotification(
    translatedNotifications,
  );

  const isCanLoadNext = () => {
    if (params.limit >= meta.count) {
      return false;
    }

    return true;
  };

  const loadNext = () => {
    setParams((current) => ({
      page: current.page,
      limit: current.limit + ITEMS_PER_PAGE_NOTIFICATIONS,
    }));
  };

  return {
    notifications,
    todayNotifications,
    earlierNotifications,
    isLoading: isNotificationsLoading,
    isSuccess: isNotificationsSuccess,
    isFetching: isNotificationsFetching,
    loadNext,
    isCanLoadNext,
  };
};

export default useNotificationsList;
