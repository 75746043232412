import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Tag } from '@shared/components';
import { FIAT_CURRENCIES, PAYMENT_METHOD } from '@shared/constants';
import { parseToLocalization } from '@shared/utils';

import { depositCurrencySymbolSelector } from '@pages/WalletsPage/Deposit/Deposit.selectors';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './FiatPaymentMethodsTabs.module.scss';

const FiatPaymentMethodsTabs = ({
  label,
  activePaymentMethod,
  setActivePaymentMethod,
}) => {
  const intl = useIntl();

  const selectedCurrencySymbol = useSelector(depositCurrencySymbolSelector);

  const { isTabletUp } = useBreakpoints();

  const advCashMethod = {
    id: PAYMENT_METHOD.ADVCASH,
    label: (
      <span className={styles.tab_label}>
        {intl.formatMessage({
          id: parseToLocalization(PAYMENT_METHOD.ADVCASH),
        })}
      </span>
    ),
  };

  const bankTransferMethod = {
    id: PAYMENT_METHOD.BANK_TRANSFER,
    label: (
      <span className={styles.tab_label}>
        {intl.formatMessage({
          id: parseToLocalization(PAYMENT_METHOD.BANK_TRANSFER),
        })}
      </span>
    ),
  };

  let methods = [bankTransferMethod];

  // TODO - remove this condition when USD deposit by AdvCash will be available
  if (selectedCurrencySymbol === FIAT_CURRENCIES.EUR) {
    methods = [advCashMethod, ...methods];
  }

  return (
    <div className={styles.methods_container}>
      {isTabletUp && <label>{label}</label>}
      <div className={styles.methods_tabs}>
        {methods.map((method) => (
          <Tag
            key={method.id}
            active={activePaymentMethod === method.id}
            title={method.label}
            onClick={() => setActivePaymentMethod(method.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(FiatPaymentMethodsTabs);
