import React, { memo, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FormProvider, useForm } from 'react-hook-form';

import { COIN_TYPE, PAYMENT_METHOD, WALLET_FEATURES } from '@shared/constants';
import { Button } from '@shared/ui';

import { FiatPaymentMethodsTabs } from '@pages/WalletsPage/components';
import {
  useGetWithdrawSystem,
  useUnblockedWalletFeatures,
} from '@pages/WalletsPage/hooks';
import {
  SideBarConfirmAdvcashWithdraw,
  SideBarWithdrawBankTransfer,
  SideBarWithdrawConfirmBankTransfer,
} from '@pages/WalletsPage/Withdraw/sidebars';
import {
  selectedCoinTypeSelector,
  withdrawCurrencySymbolSelector,
  withdrawPaymentMethodSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';
import { walletWithdrawActions } from '@pages/WalletsPage/Withdraw/Withdraw.slice';
import { CoinCheckoutTable } from '@pages/WalletsPage/Withdraw/WithdrawCoinBlock';
import { CoinBalanceTable } from '@pages/WalletsPage/Withdraw/WithdrawCoinBlock';
import { WithdrawCoinCrypto } from '@pages/WalletsPage/Withdraw/WithdrawCoinBlock';
import { WithdrawCoinFiat } from '@pages/WalletsPage/Withdraw/WithdrawCoinBlock';

import { useGetProfileInfoQueryResult, useWalletsMutations } from '@api/hooks';
import { useLazyWithdrawAdvcashValidateAddressQuery } from '@api/walletsAPI';

import useModal from '@hooks/useModal';
import useSidebar from '@hooks/useSidebar';

import { modalsIds, sidebarIds } from '@constants';

const transformValues = (values) => {
  let transformedValues = { ...values };

  if (values?.address?.value) {
    transformedValues = { ...transformedValues, address: values.address.value };
  }

  return transformedValues;
};

const WithdrawCoinContent = ({ coins }) => {
  const intl = useIntl();

  const { openModal: openSecurityLevelModal } = useModal(
    modalsIds.SECURITY_LEVEL_MODAL,
  );
  const { openModal: openTwoFAModal } = useModal(modalsIds.TWO_FA_MODAL);

  const dispatch = useDispatch();

  const activePaymentMethod = useSelector(withdrawPaymentMethodSelector);
  const selectedCoinType = useSelector(selectedCoinTypeSelector);
  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);

  const { onMakeWithdrawalCoin, isLoadingMakeWithdrawalCoin } =
    useWalletsMutations();
  const [validateAddress, { isError: isErrorValidateAddress }] =
    useLazyWithdrawAdvcashValidateAddressQuery();
  const { isTwoAuthExists } = useGetProfileInfoQueryResult();

  const isFiatCoin = selectedCoinType === COIN_TYPE.FIAT;

  const withdrawSystem = useGetWithdrawSystem(
    selectedCurrencySymbol,
    activePaymentMethod,
  );

  const walletFeatures = useUnblockedWalletFeatures();

  const selectedCoinInfo = useMemo(
    () => coins.find((coin) => coin.symbol === selectedCurrencySymbol),
    [coins, selectedCurrencySymbol],
  );

  const isWithdrawForSelectedCoinAvailable = useMemo(
    () => !selectedCoinInfo?.block_withdraw,
    [selectedCoinInfo],
  );

  const isWithdrawFeatureAvailable = walletFeatures.some(
    (feature) => feature === WALLET_FEATURES.WITHDRAW_ALL,
  );

  const isWithdrawFeatureForCoinTypeAvailable = walletFeatures.some(
    (feature) =>
      feature ===
      (isFiatCoin
        ? WALLET_FEATURES.WITHDRAW_FIAT
        : WALLET_FEATURES.WITHDRAW_CRYPTO),
  );

  const defaultFormValues = { address: null, amount: '' };

  const methods = useForm({ defaultValues: defaultFormValues });

  const { handleSubmit, reset, clearErrors } = methods;

  const resetForm = () => {
    reset(defaultFormValues);
    clearErrors();
  };

  const { openModal: openWalletsWithdrawAdvcashFailed } = useModal(
    modalsIds.WALLETS_WITHDRAW_ADVCASH_FAILED,
  );

  const { toggleSidebar: toggleAdvcashWithdrawSidebar } = useSidebar(
    sidebarIds.WALLETS_WITHDRAW_CONFIRM_ADVCASH,
  );
  const { toggleSidebar: toggleBankTransferWithdrawSidebar } = useSidebar(
    sidebarIds.WALLETS_WITHDRAW_BANK_TRANSFER,
  );

  // Open error modal if advcash withdraw is failed
  useEffect(() => {
    if (isErrorValidateAddress) {
      openWalletsWithdrawAdvcashFailed();
    }
  }, [isErrorValidateAddress]);

  // reset form after selected currency changing
  useEffect(() => resetForm(), [selectedCurrencySymbol]);

  const handleOpenTwoFAModal = (values) => {
    if (!isTwoAuthExists) {
      openSecurityLevelModal();
      return;
    }

    openTwoFAModal({
      onSubmit: async ({ OTPCode }) => {
        const withdrawData = {
          ...values,
          coinSymbol: selectedCurrencySymbol,
          system: withdrawSystem,
          otpCode: OTPCode,
        };

        if (isFiatCoin) {
          if (activePaymentMethod === PAYMENT_METHOD.ADVCASH) {
            try {
              await validateAddress({
                coin: selectedCurrencySymbol,
                address: withdrawData.email,
              }).unwrap();
            } catch (e) {
              console.log('e', e);
            }
          }
        }

        await onMakeWithdrawalCoin(withdrawData);

        resetForm();
      },
    });
  };

  const handleSetActivePaymentMethod = (paymentMethod) => {
    dispatch(walletWithdrawActions.setPaymentMethod(paymentMethod));
  };

  const handleConfirmBankTransfer = (values) => {
    handleOpenTwoFAModal(values);
  };

  const handleWithdrawClick = (values) => {
    const transformedValues = transformValues(values);

    if (isFiatCoin) {
      if (activePaymentMethod === PAYMENT_METHOD.BANK_TRANSFER) {
        toggleBankTransferWithdrawSidebar();
      }

      if (activePaymentMethod === PAYMENT_METHOD.ADVCASH) {
        toggleAdvcashWithdrawSidebar();
      }
    } else {
      handleOpenTwoFAModal(transformedValues);
    }
  };

  if (!isWithdrawForSelectedCoinAvailable) {
    return <FormattedMessage id="WithdrawCoinFeatureIsNotAvailable" />;
  }

  if (!isWithdrawFeatureAvailable) {
    return (
      <FormattedMessage
        id="WithdrawCoinTypeFeatureIsNotAvailable"
        values={{ type: selectedCoinType }}
      />
    );
  }

  if (!isWithdrawFeatureForCoinTypeAvailable) {
    return <FormattedMessage id="WithdrawCoinFeatureIsNotAvailable" />;
  }

  return (
    <div>
      {isFiatCoin && (
        <div className="mb-24">
          <FiatPaymentMethodsTabs
            activePaymentMethod={activePaymentMethod}
            setActivePaymentMethod={handleSetActivePaymentMethod}
            label={`${intl.formatMessage({ id: 'SelectWithdrawMethod' })}:`}
          />
        </div>
      )}

      <CoinBalanceTable />

      <div className="mt-24">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleWithdrawClick)}>
            <div className="container-fluid p-0">
              {isFiatCoin ? <WithdrawCoinFiat /> : <WithdrawCoinCrypto />}
              <div className="row mt-24 d-flex align-items-end">
                <div className="col-md-7 col-12">
                  <CoinCheckoutTable />
                </div>
                <div className="col-md-5 col-12 mt-md-0 mt-24">
                  <Button
                    fill
                    color="secondary"
                    isLoading={isLoadingMakeWithdrawalCoin}
                    size="l"
                  >
                    <FormattedMessage id="Withdraw" />
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      <SideBarConfirmAdvcashWithdraw onSubmit={handleOpenTwoFAModal} />
      <SideBarWithdrawBankTransfer />
      <SideBarWithdrawConfirmBankTransfer
        onSubmit={handleConfirmBankTransfer}
      />
    </div>
  );
};

export default memo(WithdrawCoinContent);
