import { COIN_TYPE, PAYMENT_METHOD, EXTERNAL_SYSTEM } from '@shared/constants';

import { useCoinsInfo } from '@store/api/hooks';

const useGetWithdrawSystem = (currencySymbol, paymentMethod) => {
  let system = EXTERNAL_SYSTEM.BITGO;

  const { coinInfo } = useCoinsInfo({ coinSymbol: currencySymbol });

  const isFiatCurrency = coinInfo?.type === COIN_TYPE.FIAT;

  if (isFiatCurrency) {
    if (paymentMethod === PAYMENT_METHOD.ADVCASH) {
      system = EXTERNAL_SYSTEM.ADVCASH;
    }

    if (paymentMethod === PAYMENT_METHOD.BANK_TRANSFER) {
      system = EXTERNAL_SYSTEM.CLEAR_JUNCTION;
    }
  }

  return system;
};

export default useGetWithdrawSystem;
