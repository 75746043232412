import { Children, cloneElement } from 'react';

import cx from 'classnames';

import s from './TabList.module.scss';

/**
 * tabsSpace can be: 'sm' | 'md' | 'lg'
 * */
const TabList = ({
  id,
  children,
  customStyles = {},
  bottomSeparator = true,
  tabsSpace = 'md',
  selectedTabIndex,
  scrollableTabs,
  onTabClick,
  suffix,
  inversion,
}) => {
  return (
    <div
      className={cx(s.tab_list_container, customStyles.tab_list_container, {
        [s.inversion]: inversion,
      })}
    >
      <ul
        className={cx(s.tab_list, customStyles.tab_list, s[tabsSpace], {
          [s.bottom_separator]: bottomSeparator,
          [s.scrollable_tabs]: scrollableTabs,
        })}
      >
        {Children.map(children, (child, tabIndex) =>
          child
            ? cloneElement(child, {
                id,
                selectedTabIndex,
                onTabClick,
                tabIndex,
              })
            : null,
        )}
      </ul>

      {suffix && <div className={customStyles.tab_list__suffix}>{suffix}</div>}
    </div>
  );
};

export default TabList;
