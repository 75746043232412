import { createSelector } from '@reduxjs/toolkit';

const featureSelector = (state) => state.tradingBots.liquidatedBots;

const filtersSelector = createSelector(featureSelector, (liquidatedBots) => {
  return liquidatedBots.filters;
});

const paginationSelector = createSelector(featureSelector, (liquidatedBots) => {
  return liquidatedBots.pagination;
});

export const LiquidatedBotsSelectors = {
  filtersSelector,
  paginationSelector,
};
