import React, { memo } from 'react';

import { components } from 'react-select';

import styles from './Option.module.scss';

const Option = ({ children, selectProps, ...props }) => {
  return (
    children && (
      <components.Option className={styles.option} {...props}>
        {children}
      </components.Option>
    )
  );
};

export default memo(Option);
