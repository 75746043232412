import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  botStatus: '',
};

const tradingBotsSlice = createSlice({
  name: 'tradingBots',
  initialState,
  reducers: {
    setBotStatus: (state, action) => {
      state.botStatus = action.payload;
    },
  },
});

const tradingBotsActions = tradingBotsSlice.actions;
const tradingBotsReducer = tradingBotsSlice.reducer;

export { tradingBotsActions, tradingBotsReducer };

export default tradingBotsSlice;
