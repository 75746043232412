import React, { memo } from 'react';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './VisaCard.module.scss';

const VisaCard = ({ title, cardType }) => {
  const { isTabletDown } = useBreakpoints();

  const getSrc = (src) => {
    const imageName = `${src}${isTabletDown ? '-mini' : ''}`;

    return `/images/visa/${imageName}.png`;
  };

  return (
    <div className={styles.visa_card}>
      {title && <div className={styles.title}>{title}</div>}

      <div>
        <img alt={cardType} src={getSrc(cardType)} />
      </div>
    </div>
  );
};

export default memo(VisaCard);
