import { memo, useId, useMemo } from 'react';

import Table from '@components/gridTables/Table';

import { useInstantBonusTable } from '@pages/AccountPage/hooks';
import instantBonusTableColumns from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/InstantBonus/components/instantBonusTableColumns';
import { CONTRACTS_PER_PAGE } from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/InstantBonus/instantBonus.constants';

const InstantBonusTable = () => {
  const id = useId();

  const { contracts, isInstantBonusSuccess } = useInstantBonusTable({
    limit: CONTRACTS_PER_PAGE,
  });

  const columns = useMemo(() => instantBonusTableColumns(), []);

  return (
    <Table
      id={id}
      className="account-tables-swipe my-20"
      columns={columns}
      data={contracts}
      wideRowsS
      stripedRows={false}
      isLoading={!isInstantBonusSuccess}
    />
  );
};

export default memo(InstantBonusTable);
