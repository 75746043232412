import React, { memo } from 'react';

import LocalizationItem from './components/LocalizationItem';

const LocalizationList = ({
  className,
  title,
  data,
  selectedItem,
  handleItemClick,
}) => {
  return (
    <div className={className}>
      <div className="title">{title}</div>

      {data.map((item) => (
        <LocalizationItem
          key={item.value}
          item={item}
          selectedItem={selectedItem}
          handleItemClick={handleItemClick}
        />
      ))}
    </div>
  );
};

export default memo(LocalizationList);
