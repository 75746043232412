import Centrifuge from 'centrifuge';

import { wssWebsocket } from '@configs';

class WSClient {
  client = new Centrifuge(wssWebsocket);

  connect() {
    this.disconnect();
    this.client.setConnectData({ 'user-agent': navigator.userAgent });
    this.client.connect();
  }

  disconnect() {
    if (this.isConnected()) {
      this.client.disconnect();
    }
  }

  isConnected() {
    return this.client.isConnected();
  }
}

const wsClient = new WSClient();

export { WSClient };

export default wsClient;
