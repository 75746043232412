export const getPriceFieldRules = (intl) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
  };
};

export const getAmountFieldRules = (intl) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
  };
};

export const getTotalFieldRules = (intl) => {
  return {
    validate: {
      min: (v) => {
        if (Number(v) <= 0) {
          return intl.formatMessage({ id: 'TotalLessThanZero' });
        }

        return true;
      },
    },
  };
};
