import { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { base64StringToBlob } from 'blob-util';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import { useForm, useWatch } from 'react-hook-form';

import { TableFilters } from '@shared/components';
import {
  ANY_FILTER_KEY,
  DAY_MONTH_YEAR_TIME_FORMAT,
  TRANSACTION_TYPES,
} from '@shared/constants';
import { SelectController } from '@shared/ui';

import { transformValues } from '@pages/OrderPages/OrderPages.utils';
import { FILENAME } from '@pages/WalletsPage/TransactionHistory/transactionHistory.constants';
import { TransactionHistorySelectors } from '@pages/WalletsPage/TransactionHistory/transactionHistory.selectors';

import { useDefaultAccount } from '@api/hooks';
import { useLazyExportTransactionsFileQuery } from '@api/transactionsAPI';

import useBreakpoints from '@hooks/useBreakpoints';

import { FILE_FORMAT } from '@constants';

const transactionTypes = [
  {
    value: ANY_FILTER_KEY,
    label: (
      <span className="fw-600">
        <FormattedMessage id="Any" />
      </span>
    ),
  },
  {
    value: TRANSACTION_TYPES.DEPOSIT,
    label: (
      <span className="fw-600">
        <FormattedMessage id="Deposit" />
      </span>
    ),
  },
  {
    value: TRANSACTION_TYPES.WITHDRAW,
    label: (
      <span className="fw-600">
        <FormattedMessage id="Withdraw" />
      </span>
    ),
  },
];

const TransactionHistoryFilters = ({ onFiltersChange, defaultValues }) => {
  const intl = useIntl();
  const [loaders, setLoaders] = useState({
    [FILE_FORMAT.PDF]: false,
    [FILE_FORMAT.CSV]: false,
  });

  const setLoader = (fileType, isLoading) => {
    setLoaders((prevState) => ({ ...prevState, [fileType]: isLoading }));
  };

  const { defaultSpotAccount } = useDefaultAccount();

  const [exportFile] = useLazyExportTransactionsFileQuery();

  const queryParams = useSelector(
    TransactionHistorySelectors.queryParamsSelector,
  );

  const params = useParams();

  const { isTabletDown } = useBreakpoints();

  const methods = useForm({
    defaultValues,
  });

  const typeWatch = useWatch({ control: methods.control, name: 'type' });

  useEffect(() => {
    const values = methods.getValues();

    onFiltersChange(transformValues(values));
  }, [typeWatch]);

  useEffect(() => {
    if (!typeWatch?.value) {
      methods.setValue(
        'type',
        transactionTypes.find((type) => type.value === defaultValues.type),
      );
    }
  }, [transactionTypes]);

  useEffect(() => {
    if (params.transactionType) {
      methods.setValue(
        'type',
        transactionTypes.find((type) => type.value === params.transactionType),
      );
    }
  }, [params.transactionType]);

  const handleOnFiltersChange = (data) => {
    onFiltersChange(transformValues(data));
  };

  const handleExportFile = async (fileType) => {
    setLoader(fileType, true);

    const resp = await exportFile({
      ...queryParams,
      format: fileType,
      accountId: defaultSpotAccount?.id,
    })
      .unwrap()
      .finally(() => setLoader(fileType, false));

    FileSaver.saveAs(
      base64StringToBlob(resp.data),
      `${
        FILENAME + format(new Date(), DAY_MONTH_YEAR_TIME_FORMAT)
      }.${fileType}`,
    );
  };

  return (
    <TableFilters
      clearAllButton
      datepicker
      exportPdf
      exportXsl
      onControlsClick={handleExportFile}
      onFiltersChange={handleOnFiltersChange}
      showAdvancedFilterButton={isTabletDown}
      loaders={loaders}
      isCustomRangeButtonDisplayed={false}
      {...methods}
    >
      {({ control }) => (
        <div className="d-flex align-items-center gap-12">
          <label htmlFor="type" className="d-none d-md-block me-12 xl-me-16">
            <FormattedMessage id="TransactionType" />:
          </label>

          <SelectController
            size="m"
            styles={{ control: { minWidth: '120px' } }}
            bordered
            name="type"
            control={control}
            options={transactionTypes}
            placeholder={`${intl.formatMessage({ id: 'Any' })}...`}
            {...methods}
          />
        </div>
      )}
    </TableFilters>
  );
};

export default memo(TransactionHistoryFilters);
