import { createSelector } from '@reduxjs/toolkit';

import {
  ApiSelectorsUtils,
  formatObjectKeysFromSnakeToCamelCase,
} from '@api/utils';

const dataSelector = ApiSelectorsUtils.createDataSelector();

const depositWithdrawBlockingSelector = createSelector(
  dataSelector,
  (data) => data?.DepositWithdrawBlocking,
);

const kycStatusSelector = createSelector(dataSelector, (data) => {
  if (!data) {
    return undefined;
  }

  let formattedDataObject = formatObjectKeysFromSnakeToCamelCase(data);

  formattedDataObject = Object.fromEntries(
    Object.entries(formattedDataObject).map(([key, value]) => {
      if (typeof value === 'string' && value.includes('{')) {
        value = JSON.parse(value);
      }

      return [key, value];
    }),
  );

  return formattedDataObject;
});

const getKybUserSelector = createSelector(dataSelector, (data) => {
  return data;
});

const getKybStepsStatusSelector = createSelector(dataSelector, (data) => {
  return {
    StepOneStatus: data?.StepOneStatus,
    StepTwoStatus: data?.StepTwoStatus,
    StepThreeStatus: data?.StepThreeStatus,
    StepFourStatus: data?.StepFourStatus,
  };
});

const getKybStepsSelector = createSelector(dataSelector, (data) => {
  return data;
});

export const ProfileApiSelectors = {
  depositWithdrawBlockingSelector,
  kycStatusSelector,
  getKybUserSelector,
  getKybStepsStatusSelector,
  getKybStepsSelector,
};
