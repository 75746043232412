import React, { memo, useMemo } from 'react';

import classNames from 'classnames';

import {
  DEFAULT_COIN_ICON_SIZE,
  DISPLAY_COINS_QUANTITY,
} from '@shared/constants';

import { getAsset } from '@utils';

import { CRYPTO_COIN_LIST } from '@constants';

import styles from './CoinsIconSet.module.scss';

const CoinsIconSet = ({
  coins,
  iconSize = DEFAULT_COIN_ICON_SIZE,
  iconOffset = 0,
  displayQuantity = DISPLAY_COINS_QUANTITY,
  iconStyle = 'icon',
  customStyles = {},
}) => {
  const getAssetIcon = (coin) => {
    const Component =
      getAsset(CRYPTO_COIN_LIST, coin, 'coin', iconStyle) ??
      CRYPTO_COIN_LIST[0][iconStyle];

    return React.cloneElement(Component, {
      width: iconSize,
      height: iconSize,
    });
  };

  const visibleCurrencies = useMemo(() => {
    return coins.slice(0, displayQuantity);
  }, [coins, displayQuantity]);

  return (
    <div
      className={classNames(
        styles.assets_container,
        'asset-container',
        customStyles.assets_container,
      )}
    >
      {visibleCurrencies.map((coin, index) => (
        <span
          style={{
            marginLeft: index > 0 ? -iconOffset : 0,
          }}
          className={classNames(styles.asset, customStyles.asset)}
          key={`${coin}-${index}`}
        >
          {getAssetIcon(coin)}
        </span>
      ))}
    </div>
  );
};

export default memo(CoinsIconSet);
