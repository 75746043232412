import { memo } from 'react';

import {
  ConfirmLiquidateBotModal,
  CustomRangeModal,
  QRCodeModal,
  SuccessCustomRangeModal,
} from '@shared/components';
import ConfirmModal from '@shared/components/ConfirmModal/ConfirmModal';

import { AccountOrdersHistoryTradesModal } from '@components/modals/AccountOrdersHistoryTradesModal';
import ClearTemplateModal from '@components/modals/ClearTemplateModal';
import ConfirmCancelAllOpenOrdersModal from '@components/modals/ConfirmCancelAllOpenOrdersModal';
import ConfirmCancelOpenOrderModal from '@components/modals/ConfirmCancelOpenOrderModal';
import ConfirmCancelOpenOrdersByPairModal from '@components/modals/ConfirmCancelOpenOrdersByPairModal';
import ConfirmLeavingPageModal from '@components/modals/ConfirmLeavingPageModal';
import DownloadMobileAppModal from '@components/modals/DownloadMobileAppModal';
import { ExportFileModal } from '@components/modals/ExportFileModal';
import OrderHistoryTradesModal from '@components/modals/OrderHistoryTradesModal/OrderHistoryTradesModal';
import SavedTemplatesModal from '@components/modals/SavedTemplatesModal';
import SaveTemplateModal from '@components/modals/SaveTemplateModal';

import DeleteApiKeyModal from '@pages/AccountPage/ApiManagement/components/ApiKeyDeleteModal';
import TwoFAModal from '@pages/AccountPage/ApiManagement/components/TwoFAModal';
import { OverviewEarnWidgetsModal } from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/OverviewEarnWidgetsModal';
import { UpdateAvatarModal } from '@pages/AccountPage/Profile';
import { SecurityLevelModal } from '@pages/AccountPage/Security';
import { ConfirmPhoneModal } from '@pages/AuthPages/PhoneConfirmation/components';
import { JoinWaitListModal } from '@pages/landings/CardPage/JoinWaitListModal';
import { SuccessJoinWaitListModal } from '@pages/landings/CardPage/SuccessJoinWaitListModal';
import ConfirmTradeModal from '@pages/TerminalPage/components/GridLayout/blocks/Trade/components/ConfirmTradeModal';
import { CloseCardModal } from '@pages/WalletsPage/VisaCardSettings';
import { WalletsWidgetsModal } from '@pages/WalletsPage/WalletsOverview';
import { WithdrawAdvcashFailedModal } from '@pages/WalletsPage/Withdraw';

const Modals = () => {
  return (
    <>
      <ConfirmLeavingPageModal />
      <SaveTemplateModal />
      <SavedTemplatesModal />
      <ClearTemplateModal />
      <DownloadMobileAppModal />
      <ConfirmTradeModal />
      <ConfirmCancelOpenOrderModal />
      <ConfirmCancelAllOpenOrdersModal />
      <ConfirmCancelOpenOrdersByPairModal />
      <OrderHistoryTradesModal />
      <WalletsWidgetsModal />
      <OverviewEarnWidgetsModal />
      <WithdrawAdvcashFailedModal />
      <SecurityLevelModal />
      <UpdateAvatarModal />
      <ConfirmModal />
      <ConfirmLiquidateBotModal />
      <DeleteApiKeyModal />
      <TwoFAModal />
      <ConfirmPhoneModal />
      <ExportFileModal />
      <QRCodeModal />
      <AccountOrdersHistoryTradesModal />
      <JoinWaitListModal />
      <CloseCardModal />
      <CustomRangeModal />
      <SuccessCustomRangeModal />
      <SuccessJoinWaitListModal />
    </>
  );
};

export default memo(Modals);
