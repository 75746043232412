import React, { Fragment, memo } from 'react';
import { useIntl } from 'react-intl';

import classnames from 'classnames';

import { TabList, TabPanel, TabPanels, Tab, Tabs } from '@components/Tabs';

import { isEven } from '@utils/numbers';

import { PRIMARY_KEYS } from '@constants';

import styles from './ChartShortcuts.module.scss';

const ChartShortcuts = ({ config }) => {
  const intl = useIntl();

  const drawingShortcuts = config.drawingTools.filter((tool) =>
    tool.hasOwnProperty('shortcut'),
  );

  const hotkeys = config.hotkeyConfig.hotkeys;

  const shortcutsTabs = [
    {
      label: `${intl.formatMessage({ id: 'DrawingTools' })} (${
        drawingShortcuts.length
      })`,
      name: 'drawing_tools',
      content: drawingShortcuts,
    },
    {
      label: `${intl.formatMessage({ id: 'Hotkeys' })} (${hotkeys.length})`,
      name: 'hotkeys',
      content: hotkeys,
    },
  ];

  const getShortcut = (item) => {
    if (item.shortcut) {
      return (
        <div className={styles.command_container}>
          <span
            className={classnames(
              styles.command_container__command,
              styles.command_container__command__primary_key,
            )}
          >
            Alt
          </span>
          <span
            className={classnames(
              styles.command_container__command,
              styles.command_container__command__plus,
            )}
          >
            +
          </span>
          <span
            className={classnames(
              styles.command_container__command,
              styles.command_container__command__additional_key,
            )}
          >
            {item.shortcut}
          </span>
        </div>
      );
    } else if (Array.isArray(item.commands)) {
      return item.commands.map((command, cmdIndex) => {
        if (command.includes('Arrow') || command.includes('Digit')) return null;

        const keys = command.length > 1 ? command.split('+') : command.split();
        const isPrimaryKey = (key) => PRIMARY_KEYS.includes(key);

        return (
          <div key={command + cmdIndex} className={styles.command_container}>
            {keys.map((key, index) => (
              <Fragment key={key + index}>
                <span
                  className={classnames(styles.command_container__command, {
                    [styles.command_container__command__primary_key]:
                      isPrimaryKey(key),
                    [styles.command_container__command__additional_key]:
                      !isPrimaryKey(key),
                  })}
                >
                  {key.replace('Key', '')}
                </span>

                {index + 1 !== keys.length && (
                  <span className={styles.command_container__command__plus}>
                    +
                  </span>
                )}
              </Fragment>
            ))}
            ;
          </div>
        );
      });
    }
    return null;
  };

  return (
    <div className={styles.chart_shortcuts}>
      <Tabs id="chart-shortcuts-tabs-id">
        <TabList>
          {shortcutsTabs.map((tab) => (
            <Tab key={tab.name}>{tab.label}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {shortcutsTabs.map((tab) => (
            <TabPanel key={tab.name}>
              <ul className={styles.list}>
                {tab.content.map((item, index) => (
                  <li
                    key={item.label}
                    className={classnames(styles.list__item, {
                      [styles['list__item--highlighted']]: isEven(index),
                    })}
                  >
                    <span className={styles.list__item__label}>
                      {item.label}
                    </span>
                    <div className={styles.list__item__commands}>
                      {getShortcut(item)}
                    </div>
                  </li>
                ))}
              </ul>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default memo(ChartShortcuts);
