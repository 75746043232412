import { useMemo } from 'react';

import { useCoinsInfo } from '@store/api/hooks';

const useCurrencyFullName = (coinSymbol) => {
  const { coinsInfo, isCoinsInfoSuccess } = useCoinsInfo();

  const fullCurrencyName = useMemo(() => {
    return coinsInfo?.[coinSymbol.toLowerCase()]?.name;
  }, [coinSymbol, coinsInfo]);

  return { fullCurrencyName, isSuccess: isCoinsInfoSuccess };
};

export default useCurrencyFullName;
