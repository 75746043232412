import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button } from '@shared/ui';

import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import { VISA_ROUTES } from '../../../../../routes.constants';

const ActivateCardButton = () => {
  const card = useSelector(visaCardSelector);

  const userHasNoCard = !card.type;

  const { openSidebar } = useSidebar(sidebarIds.ACTIVATE_CARD);

  const handleActivateCard = () => {
    if (userHasNoCard) return;

    openSidebar();
  };

  return (
    <Button
      variant="outlined"
      type="button"
      navigation={userHasNoCard ? { to: VISA_ROUTES.ORDER_CARD } : undefined}
      onClick={handleActivateCard}
    >
      <FormattedMessage id={userHasNoCard ? 'RequestCard' : 'ActivateCard'} />
    </Button>
  );
};

export default ActivateCardButton;
