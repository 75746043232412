import cx from 'classnames';

import s from './CountryOption.module.scss';

const CountryOption = ({
  countryCode,
  isShowFlagIcon = true,
  customStyles = {},
  children,
}) => {
  const flagUrl = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`;

  return (
    <div className={cx(s.country_option, customStyles.country_option)}>
      {isShowFlagIcon && (
        <div
          className={cx(
            s.country_option_flag,
            customStyles.country_option_flag,
          )}
        >
          <img src={flagUrl} alt={countryCode.toLowerCase()} />
        </div>
      )}
      <span
        className={cx(
          s.country_option_label,
          customStyles.country_option_label,
        )}
      >
        {children}
      </span>
    </div>
  );
};

export default CountryOption;
