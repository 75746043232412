import { createSlice } from '@reduxjs/toolkit';

import { LOCAL_STORAGE_OVERVIEW_EARN_WIDGETS } from '@constants';

import { INITIAL_SELECTED_WIDGETS } from './overviewEarnWidgets.constants';

const LOCAL_STORAGE_WIDGETS = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_OVERVIEW_EARN_WIDGETS),
);

const INITIAL_WIDGETS_STATE = LOCAL_STORAGE_WIDGETS || INITIAL_SELECTED_WIDGETS;

const overviewEarnWidgetsSlice = createSlice({
  name: 'earnWidgets',
  initialState: {
    widgets: INITIAL_WIDGETS_STATE,
  },
  reducers: {
    updateWidgets: (state, { payload }) => {
      const widgets = payload.map(({ widgetId, title, isActive }) => ({
        widgetId,
        title,
        isActive,
      }));

      localStorage.setItem(
        LOCAL_STORAGE_OVERVIEW_EARN_WIDGETS,
        JSON.stringify(widgets),
      );

      state.widgets = widgets;
    },
  },
});

const overviewEarnWidgetsActions = overviewEarnWidgetsSlice.actions;
const overviewEarnWidgetsReducer = overviewEarnWidgetsSlice.reducer;

export { overviewEarnWidgetsActions, overviewEarnWidgetsReducer };
export default overviewEarnWidgetsSlice;
