import { createSelector } from '@reduxjs/toolkit';
import { getUnixTime, endOfDay, startOfDay } from 'date-fns';

import { DateUtils } from '@shared/utils';

const featureSelector = (state) => state.transactionHistory;

const filtersSelector = createSelector(
  featureSelector,
  (transactionHistory) => {
    return transactionHistory.filters;
  },
);

const paginationSelector = createSelector(
  featureSelector,
  (transactionHistory) => {
    return transactionHistory.pagination;
  },
);

const queryParamsSelector = createSelector(
  filtersSelector,
  paginationSelector,
  (filters, { pageIndex, pageSize }) => ({
    ...filters,
    fromDate: DateUtils.removeNegativeTimeZoneUnixTime(
      getUnixTime(startOfDay(filters.datePeriod.fromDate)),
    ),
    toDate: getUnixTime(endOfDay(filters.datePeriod.toDate)),
    page: pageIndex + 1,
    limit: pageSize,
  }),
);

export const TransactionHistorySelectors = {
  filtersSelector,
  paginationSelector,
  queryParamsSelector,
};
