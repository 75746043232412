import React, { memo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import classnames from 'classnames';

import useOnClickOutside from '@hooks/useClickOutside';

import { NOOP } from '@constants';

import styles from './ChartDropdown.module.scss';

const ChartDropdown = ({
  nestedComponent,
  right = false,
  children,
  container,
  onOpen = NOOP,
  onClose = NOOP,
}) => {
  const triggerRef = useRef();
  const chartDropdownRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuTrigger = () => {
    if (isOpen) {
      setIsOpen(false);

      onClose();

      return;
    }

    onOpen();

    setIsOpen(true);
  };

  const dropdownRef = useRef(document.querySelector('#dropdown-container'));

  useOnClickOutside(
    [chartDropdownRef, dropdownRef, { current: container }],
    () => setIsOpen(false),
  );

  const content =
    typeof nestedComponent === 'function'
      ? nestedComponent({ isOpen, setIsOpen })
      : nestedComponent;

  return (
    <div ref={chartDropdownRef}>
      {children({ triggerRef, handleMenuTrigger, isOpen })}

      {container ? (
        createPortal(
          isOpen && (
            <div
              className={classnames(styles.chart_dropdown, {
                [styles['chart_dropdown--right']]: right,
              })}
            >
              {content}
            </div>
          ),
          container,
        )
      ) : (
        <>
          {isOpen && (
            <div className="position-relative">
              <div
                className={classnames(styles.chart_dropdown, {
                  [styles['chart_dropdown--right']]: right,
                })}
              >
                {content}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(ChartDropdown);
