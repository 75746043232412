import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { capitalizeFirstLetter } from '@shared/utils';

import { createTrailingStopTooltipData } from '@utils/orders';

import { ORDER_FORM_TYPE } from '@constants';

import {
  BUY_TEXT,
  MARKET_TEXT,
  SELL_TEXT,
  STOP_TEXT,
} from '../ConfirmTradeModal.constant';

import useOtoSuborderValue from './useOtoSuborderValue';

const useOrderFields = ({
  tradeType,
  order,
  currentMarket,
  currentPrice,
  queryParams,
}) => {
  const intl = useIntl();
  const otoSuborder = useOtoSuborderValue(order);

  const getOrderNumName = (num) =>
    `${intl.formatMessage({ id: 'OrderN' }, { num })}`;

  return useMemo(() => {
    let result = [];

    if (!tradeType || !order || !currentMarket) return result;

    const side = capitalizeFirstLetter(queryParams.side);
    const amount = order.amount;
    const marketCoinSymbol = currentMarket.market_coin_symbol.toUpperCase();
    const quoteCoinSymbol = currentMarket.quote_coin_symbol.toUpperCase();

    result.push({
      name: 'Description',
      value: `${side} ${amount} ${marketCoinSymbol}`,
    });

    switch (tradeType) {
      case ORDER_FORM_TYPE.MARKET:
        result.push({
          name: 'Price',
          value: `${MARKET_TEXT} ${quoteCoinSymbol}`,
        });
        break;

      case ORDER_FORM_TYPE.LIMIT_OTO_BASIC:
      case ORDER_FORM_TYPE.LIMIT:
        result.push({
          name: 'Price',
          value: `${order?.price} ${quoteCoinSymbol}`,
        });
        break;

      case ORDER_FORM_TYPE.STOP_OTO_BASIC:
      case ORDER_FORM_TYPE.STOP:
        const stopPriceValue = `${order?.stop_price} ${STOP_TEXT} ${quoteCoinSymbol}`;

        result.push({
          name: 'Price',
          value: stopPriceValue,
        });
        break;

      case ORDER_FORM_TYPE.TRAILING_STOP:
        const trailingStopData = createTrailingStopTooltipData(
          intl,
          currentMarket,
          order,
        );

        result.push(
          {
            name: 'ActivationPrice',
            value: trailingStopData.activationPrice,
          },
          {
            name: 'CallbackRate',
            value: trailingStopData.callbackRate,
          },
          {
            name: 'CallbackType',
            value: trailingStopData.callbackType,
          },
        );
        break;

      case ORDER_FORM_TYPE.STRADDLE:
        const straddleOrder1Price =
          order?.price === currentPrice ? MARKET_TEXT : order.price;
        const straddleOrder2Price =
          order?.sl_price === currentPrice ? MARKET_TEXT : order?.sl_price;

        result = [
          {
            name: getOrderNumName(1),
          },
          {
            name: 'Description',
            value: `${side} ${amount} ${marketCoinSymbol}`,
          },
          {
            name: 'Price',
            value: `${straddleOrder1Price} ${quoteCoinSymbol}`,
          },
          {
            name: getOrderNumName(2),
          },
          {
            name: 'Description',
            value: `${SELL_TEXT} ${amount} ${marketCoinSymbol}`,
          },
          {
            name: 'Price',
            value: `${straddleOrder2Price} ${quoteCoinSymbol}`,
          },
        ];
        break;

      case ORDER_FORM_TYPE.STRANGLE: {
        const strangleOrder1Price =
          order?.price === currentPrice ? MARKET_TEXT : order?.price;
        const strangleOrder2Price =
          order?.tp_price === currentPrice ? MARKET_TEXT : order?.tp_price;

        result = [
          {
            name: getOrderNumName(1),
          },
          {
            name: 'Description',
            value: `${side} ${amount} ${marketCoinSymbol}`,
          },
          {
            name: 'Price',
            value: `${strangleOrder1Price} ${quoteCoinSymbol}`,
          },
          {
            name: getOrderNumName(2),
          },
          {
            name: 'Description',
            value: `${BUY_TEXT} ${amount} ${marketCoinSymbol}`,
          },
          {
            name: 'Price',
            value: `${strangleOrder2Price} ${quoteCoinSymbol}`,
          },
        ];
        break;
      }

      case ORDER_FORM_TYPE.LIMIT_OTO_ADVANCED:
        result.push(
          {
            name: 'Price',
            value: `${order?.price} ${quoteCoinSymbol}`,
          },
          {
            name: 'OTO',
            value: otoSuborder.join(' / '),
          },
        );
        break;

      case ORDER_FORM_TYPE.STOP_OTO_ADVANCED:
        result.push(
          {
            name: 'Price',
            value: `${order?.stop_price} ${STOP_TEXT} ${quoteCoinSymbol}`,
          },
          {
            name: 'OTO',
            value: otoSuborder.join(' / '),
          },
        );
        break;

      default:
        break;
    }

    return result;
  }, [tradeType, currentMarket, order, currentPrice]);
};

export default useOrderFields;
