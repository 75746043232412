import { createSlice } from '@reduxjs/toolkit';

import {
  ORDERS_ITEMS_PER_PAGE,
  ORDER_HISTORY_FILTERS_DEFAULT_VALUES,
} from '@pages/OrderPages/constants';

const initialState = {
  filters: ORDER_HISTORY_FILTERS_DEFAULT_VALUES,
  pagination: {
    pageIndex: 0,
    pageSize: ORDERS_ITEMS_PER_PAGE,
  },
};

const orderHistorySlice = createSlice({
  name: 'orderHistory',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    resetPagination: (state, action) => {
      state.pagination = initialState.pagination;
    },
  },
});

const orderHistoryActions = orderHistorySlice.actions;
const orderHistoryReducer = orderHistorySlice.reducer;

export { orderHistoryReducer, orderHistoryActions };

export default orderHistorySlice;
