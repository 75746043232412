export const VISA_CARD_SETTINGS_IDS = {
  SPENDING_LIMIT: 'spending_limit',
  CARD_REQUISITES: 'card_requisites',
  CARD_NAME: 'card_name',
  CHANGE_PIN: 'change_pin',
  CLOSE_CARD: 'close_card',
};

export const CARD_REQUISITES_IDS = {
  SEPA: 'sepa',
  SEPA_INSTANT: 'sepa_instant',
  SWIFT: 'swift',
  EXTERNAL_SERVICES: 'external_services',
};
