import mockData from 'src/mock.json';

const useTokenGrowth = () => {
  const { tokenGrowth } = mockData.useTokenGrowth;

  return {
    tokenGrowth: tokenGrowth.map((token) => ({ ...token, date: new Date() })),
    isSuccess: true,
  };
};

export default useTokenGrowth;
