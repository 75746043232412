import React, { memo } from 'react';

import classnames from 'classnames';

const Tab = ({
  id,
  key,
  selectedTabIndex,
  onTabClick,
  tabIndex,
  isSelect,
  children,
  className,
}) => {
  return (
    <li
      key={`${id}-tab-${key}`}
      className={classnames('tabs__tab', className, {
        'tabs__tab--active': selectedTabIndex === tabIndex,
      })}
      onClick={() => {
        if (!isSelect) {
          onTabClick({ tabIndex, isSelect: false });
        }
      }}
    >
      {typeof children === 'function'
        ? children({
            id,
            key,
            selectedTabIndex,
            onTabClick,
            tabIndex,
            isSelect,
          })
        : children}
    </li>
  );
};

export default memo(Tab);
