import { useEffect } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'classnames';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  TableIsLoading,
  TableNoData,
  TablePagination,
} from '@shared/components/Table/components';
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeaderCell,
  TableHeaderRow,
} from '@shared/components/Table/components/ui';
import {
  HEADER_HEIGHT,
  ROW_HEIGHT,
  SWIPER_PAGINATION_SIZE,
  SWIPER_PAGINATION_VARIANT,
  VARIANTS_ROWS,
} from '@shared/components/Table/table.constants';
import { getColumnStyle } from '@shared/components/Table/table.utils';

import { ITEMS_PER_PAGE, NOOP } from '@constants';

import s from './TableSwipeable.module.scss';

const TableSwipeable = ({
  pinnedColumnIDs = [],
  data,
  columns,
  onRowClick = NOOP,
  // styles
  headerHeight = HEADER_HEIGHT.XS, // 'xs' | 's' | 'm' | 'l'
  rowHeight = ROW_HEIGHT.S, // 's' | 'm' | 'l' | 'xl'
  variantRows = VARIANTS_ROWS.STRIPED, // 'striped' | 'underlined' | 'underlined-light',
  swiperPaginationSize = SWIPER_PAGINATION_SIZE.M, // 'm' | 's'
  swiperPaginationVariant = SWIPER_PAGINATION_VARIANT.CONTAINED, // 'contained' | 'outlined'
  inversion,
  isColumnHeadersDisplayed = true,
  // for pagination
  onPaginationChange,
  isPaginationDisplayed,
  isPageCounterDisplayed,
  paginationAlign,
  meta = { count: 0, pageIndex: 0, limit: ITEMS_PER_PAGE },
  isLoading,
  // for NoData component
  noDataLabel,
}) => {
  const isClickableRow = onRowClick !== NOOP;

  const pageCount = Math.ceil(meta.count / meta.limit);

  const table = useReactTable({
    data,
    columns,
    pageCount,
    getSubRows: (row) => row.subRows,
    state: {
      pagination: {
        pageIndex: meta.pageIndex,
        pageSize: meta.limit,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onPaginationChange,
    manualPagination: true,
  });

  useEffect(() => {
    if (!pinnedColumnIDs.length) return;
    table.getHeaderGroups().forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => {
        if (
          !header.isPlaceholder &&
          header.column.getCanPin() &&
          pinnedColumnIDs.includes(header.id)
        ) {
          header.column.pin('left');
        } else {
          header.column.pin('right');
        }
      });
    });
  }, [table, pinnedColumnIDs]);

  return (
    <div>
      <div className={s.table_swipeable__pagination_wrapper}>
        <div
          id="tables-swiper-pagination"
          className={cx(
            s.table_swipeable__pagination,
            s[`table_swipeable__pagination_${swiperPaginationSize}`],
            s[`table_swipeable__pagination_${swiperPaginationVariant}`],
            {
              [s[
                `table_swipeable__pagination_${swiperPaginationVariant}__inversion`
              ]]: inversion,
            },
          )}
        />
      </div>
      <div className="position-relative">
        {isLoading && <TableIsLoading inversion={inversion} />}

        <div className={s.tables_wrapper}>
          <table
            className={cx(s[`table_swipeable__left__${variantRows}`], {
              [s[`table_swipeable__left__${variantRows}__inversion`]]:
                inversion,
            })}
          >
            <TableHead>
              {isColumnHeadersDisplayed &&
                table.getLeftHeaderGroups().map((headerGroup) => (
                  <TableHeaderRow
                    key={headerGroup.id}
                    headerHeight={headerHeight}
                  >
                    {headerGroup.headers.map((header) => (
                      <TableHeaderCell
                        key={header.id}
                        header={header}
                        inversion={inversion}
                        style={{ paddingLeft: '15px' }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHeaderCell>
                    ))}
                  </TableHeaderRow>
                ))}
            </TableHead>

            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableBodyRow
                  key={row.id}
                  rowHeight={rowHeight}
                  variantRows={variantRows}
                  isClickableRow={isClickableRow}
                  isSwipeableTable
                  inversion={inversion}
                >
                  {row.getLeftVisibleCells().map((cell) => (
                    <TableBodyCell
                      key={cell.id}
                      cell={cell}
                      style={{ paddingLeft: '15px' }}
                      onRowClick={
                        isClickableRow ? () => onRowClick(row) : undefined
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableBodyCell>
                  ))}
                </TableBodyRow>
              ))}
            </TableBody>
          </table>

          <Swiper
            spaceBetween={0}
            pagination={{
              clickable: true,
              el: '#tables-swiper-pagination',
            }}
            slidesPerView="auto"
            modules={[Pagination]}
          >
            {table
              .getRightHeaderGroups()[0]
              .headers.map((header, headerIndex) => (
                <SwiperSlide
                  key={header.id}
                  style={{ width: header.getSize() }}
                >
                  <table>
                    <TableHead>
                      {isColumnHeadersDisplayed && (
                        <TableHeaderRow headerHeight={headerHeight}>
                          <TableHeaderCell
                            key={header.id}
                            header={header}
                            style={getColumnStyle(
                              headerIndex,
                              table.getRightHeaderGroups()[0].headers.length,
                            )}
                            inversion={inversion}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHeaderCell>
                        </TableHeaderRow>
                      )}
                    </TableHead>

                    <TableBody>
                      {table.getRowModel().rows.map((row) => (
                        <TableBodyRow
                          key={row.id}
                          rowHeight={rowHeight}
                          variantRows={variantRows}
                          isClickableRow={isClickableRow}
                          isSwipeableTable
                          inversion={inversion}
                        >
                          <TableBodyCell
                            cell={row.getRightVisibleCells()[headerIndex]}
                            style={getColumnStyle(
                              headerIndex,
                              row.getRightVisibleCells().length,
                            )}
                            onRowClick={
                              isClickableRow ? () => onRowClick(row) : undefined
                            }
                          >
                            {flexRender(
                              row.getRightVisibleCells()[headerIndex].column
                                .columnDef.cell,
                              row
                                .getRightVisibleCells()
                                [headerIndex].getContext(),
                            )}
                          </TableBodyCell>
                        </TableBodyRow>
                      ))}
                    </TableBody>
                  </table>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        {!data.length && (
          <table className="w-100">
            <TableNoData
              table={table}
              inversion={inversion}
              noDataLabel={noDataLabel}
            />
          </table>
        )}
      </div>

      {isPaginationDisplayed && (
        <div className="mt-24">
          <TablePagination
            table={table}
            meta={meta}
            isLoading={isLoading}
            paginationAlign={paginationAlign}
            isPageCounterDisplayed={isPageCounterDisplayed}
            inversion={inversion}
          />
        </div>
      )}
    </div>
  );
};

export default TableSwipeable;
