import React, { memo } from 'react';

import { LTV_PERCENT_PRECISION } from '@pages/WalletsPage/InstantBonus/instantBonus.constants';

import { format } from '@utils/numbers';

import styles from './LTVItem.module.scss';

const LTVItem = ({ ltv }) => {
  const value = format(ltv, {
    precision: LTV_PERCENT_PRECISION,
  });

  const numberValue = format(ltv, {
    precision: LTV_PERCENT_PRECISION,
    returnNumber: true,
  });

  return (
    <span className={numberValue ? styles.black : styles.grey}>{value}%</span>
  );
};

export default memo(LTVItem);
