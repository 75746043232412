import {
  EMPTY_CHART_VALUES,
  MOCK_NEGATIVE_CHART_VALUES,
  MOCK_POSITIVE_CHART_VALUES,
} from '@shared/constants';
import { useValueColor } from '@shared/hooks';

import mockData from 'src/mock.json';

const useBotsSummary = () => {
  // TODO: remove when API will be ready https://paramoountdax.atlassian.net/browse/PDAX2-1688
  const { profitPercent, profitPercentChange } = mockData.useBotsSummary;

  const { color, isPositive, isZeroValue } = useValueColor(profitPercentChange);

  // TODO: remove mock data when API will be ready https://paramoountdax.atlassian.net/browse/PDAX2-1688
  const chartValues = isPositive
    ? MOCK_POSITIVE_CHART_VALUES
    : MOCK_NEGATIVE_CHART_VALUES;

  const totalProfitData = isZeroValue ? EMPTY_CHART_VALUES : chartValues;

  return {
    totalProfitData,
    totalProfitColor: color,
    profitPercent,
    profitPercentChange,
  };
};

export default useBotsSummary;
