import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { CIQ } from '@libs/chartiq/Chart';

import { YEAR_MONTH_DAY_FORMAT } from '@shared/constants';
import { Select } from '@shared/ui';

import Button from '@components/Button';
import CheckBox from '@components/CheckBox';
import Input from '@components/Input';
import SearchBar from '@components/SearchBar';
import { TabList, TabPanels, TabPanel, Tabs, Tab } from '@components/Tabs';

import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';
import { ReactComponent as RightChevronIconSVG } from '@icons/right-chevron-icon.svg';

import ChartColorPicker from './ChartColorPicker';

import styles from './ChartStudies.module.scss';

const ChartStudies = ({ stx, close }) => {
  const [dialogHelper, setDialogHelper] = useState({});
  const [allStudies, setAllStudies] = useState([]);
  const [searchMode, setSearchMode] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    const studiesCollection = CIQ.Studies.studyLibrary;

    if (!studiesCollection) return;

    setAllStudies(getAllStudies());
  }, []);

  const getAllStudies = () =>
    sortBy(
      Object.entries(CIQ.Studies.studyLibrary)
        .filter(([studyKey, studyValue]) => !!studyValue.name)
        .map(([studyKey, studyValue]) => ({
          ...studyValue,
          key: studyKey,
        })),
      ['name'],
    );

  const favouriteIndicators = [
    'ma',
    'ema',
    'wma',
    'boll',
    'avl',
    'trix',
    'sar',
  ];

  const isFavoriteIndicator = (indicator) =>
    favouriteIndicators.indexOf(
      favouriteIndicators.find((favIndic) =>
        indicator.name.toLowerCase().match(favIndic),
      ),
    ) !== -1;

  const main = allStudies.filter((indicator) => isFavoriteIndicator(indicator));

  const additional = allStudies.filter(
    (indicator) => !isFavoriteIndicator(indicator),
  );

  const studiesTabs = [
    {
      label: `${intl.formatMessage({ id: 'Main' })} (${main.length})`,
      name: 'main',
      indicators: main,
    },
    {
      label: `${intl.formatMessage({ id: 'Additional' })} (${
        additional.length
      })`,
      name: 'additional',
      indicators: additional,
    },
  ];

  const getStudyByName = (name) =>
    stx?.layout.studies &&
    Object.values(stx.layout.studies).find((value) => value.type === name);

  const getStudyDialogHelper = (name, sd) =>
    new CIQ.Studies.DialogHelper({ name, sd, stx });

  /** This function used for update dialog helper object after click
   * on new study, or for update current dialog helper object after change
   * any part of dialog helper (like inputs, outputs, parameters)*/

  const updateDialogHelper = (name) => {
    const studyName = name ?? dialogHelper.name;

    if (!studyName) return;

    const study = getStudyByName(studyName);
    let updatedDialogHelper = {};

    if (study) {
      updatedDialogHelper = getStudyDialogHelper(studyName, study);
    }

    setDialogHelper(updatedDialogHelper);
  };

  const handleUpdateStudy = (source, isInput, value) => {
    const updatedPart = isInput ? 'inputs' : 'parameters';

    const updatedData = dialogHelper[updatedPart].reduce((acc, part) => {
      let val = part.value;
      if (source.name === part.name) val = value;
      return { ...acc, [part.name]: val };
    }, {});

    dialogHelper.updateStudy({ [updatedPart]: updatedData });

    updateDialogHelper();
  };

  const generateStudyInputOrParameter = (input, isInput = false) => {
    if (input.type === 'number' || input.type === 'text') {
      return (
        <>
          <label className={styles.config__content__title}>
            {input.heading}
          </label>

          <Input
            onChange={(value) =>
              handleUpdateStudy(input, isInput, Number(value))
            }
            onlyNumbers={input.type === 'number'}
            value={input.value}
          />
        </>
      );
    } else if (input.type === 'checkbox') {
      return (
        <CheckBox
          onChange={(checked) => handleUpdateStudy(input, isInput, checked)}
          labelPosition="left"
          label={input.heading}
          checked={input.value}
        />
      );
    } else if (input.type === 'select') {
      const options = Object.entries(input.options).map(([key, value]) => ({
        value: key,
        label: value,
      }));

      return (
        <>
          <label className={styles.config__content__title}>
            {input.heading}
          </label>

          <Select
            onChange={({ value }) => handleUpdateStudy(input, isInput, value)}
            options={options}
            value={options.find(({ value }) => value === input.value)}
            bordered
            size="m"
            styles={{ menu: { fontSize: '13px', fontWeight: '400' } }}
            customStyles={{ select_wrapper: 'w-100' }}
          />
        </>
      );
    } else if (input.type === 'date' || input.type === 'time') {
      return (
        <>
          <label className={styles.config__content__title}>
            {input.heading}
          </label>

          <input
            onChange={(value) => handleUpdateStudy(input, isInput, value)}
            type={input.type}
            placeholder={
              input.value ??
              (input.type === 'date' ? YEAR_MONTH_DAY_FORMAT : 'hh:mm:ss')
            }
          />
        </>
      );
    } else {
      return input.value;
    }
  };

  const isSelectedIndicator = (indicator) =>
    stx?.layout.studies &&
    !!Object.values(stx.layout.studies).find(
      (study) => study.type === indicator.key,
    );

  const handleStudyClickForShowConfig = (e, indicator) => {
    const isClickOnCheckbox = !!e.target.closest('.studies-list__checkbox');

    if (isClickOnCheckbox) return;

    const studyName = indicator.key;

    updateDialogHelper(studyName);
  };

  const handleSelectIndicator = (indicator) => {
    const studyName = indicator.key;
    const studyInputs = indicator?.inputs ?? null;
    const studyOutputs = indicator?.outputs ?? null;

    let study = getStudyByName(studyName);

    if (!study) {
      CIQ.Studies.addStudy(stx, studyName, studyInputs, studyOutputs);
    } else {
      CIQ.Studies.removeStudy(stx, study);
    }

    updateDialogHelper(studyName);
  };

  const handleResetStudy = () => {
    const dialogParts = ['inputs', 'outputs', 'parameters'];
    let updatedData = {};

    dialogParts.forEach((dialogPart) => {
      if (!isEmpty(dialogHelper[dialogPart])) {
        let key = 'defaultValue';
        if (dialogPart === 'inputs') key = 'defaultInput';
        if (dialogPart === 'outputs') key = 'defaultOutput';

        const updatedPart = dialogHelper[dialogPart].reduce(
          (acc, cur) => ({
            ...acc,
            [cur.name]: cur[key],
          }),
          {},
        );

        updatedData = { ...updatedData, [dialogPart]: updatedPart };
      }
    });

    dialogHelper.updateStudy(updatedData);

    updateDialogHelper();
  };

  const handleChangeColor = (outputName, updatedColor) => {
    const outputsReduced = dialogHelper.outputs.reduce((acc, output) => {
      let color = output.color;
      if (output.name === outputName) color = updatedColor;
      return { ...acc, [output.name]: color };
    }, {});

    dialogHelper.updateStudy({ outputs: outputsReduced });

    updateDialogHelper();
  };

  const handleSearchStudy = (keyword) => {
    let studies = getAllStudies();

    if (keyword) {
      studies = studies.filter((item) =>
        item.name.toLowerCase().includes(keyword.toLowerCase()),
      );
    }

    setAllStudies(studies);
  };

  return (
    <div className={styles.chart_studies}>
      <div className={styles.header}>
        <h1 className={styles.header__title}>
          {intl.formatMessage({ id: 'SettingUpIndicators' })}
        </h1>

        <Button className={styles.header__close_button} onClick={close}>
          <CrossThinIconSVG />
        </Button>
      </div>

      <div className={styles.tabs}>
        <Tabs id="chart-studies-id">
          <TabList
            containerClassName="mx-12"
            className={classnames('tabs__tab-list--with-searchbar', {
              hidden: searchMode,
            })}
            suffix={
              <div className="tabs__searchbar">
                <SearchBar
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  onSearch={handleSearchStudy}
                  isUseOnClickOutside
                />
              </div>
            }
          >
            {studiesTabs.map((tab) => (
              <Tab key={tab.name}>{tab.label}</Tab>
            ))}
          </TabList>

          <TabPanels>
            {studiesTabs.map((tab) => (
              <TabPanel key={tab.name}>
                <div className={styles.body}>
                  <div className={styles.item}>
                    <p className={styles.item__title}>
                      {`${intl.formatMessage({ id: 'Selected' })} (${
                        stx?.layout.studies
                          ? Object.keys(stx.layout.studies).length
                          : 0
                      })`}
                    </p>

                    <ul className={styles.list}>
                      {tab.indicators.map((indicator, index) => (
                        <li
                          key={index + 'studies-list-' + indicator.indicator_id}
                          className={styles.list__item}
                          onClick={(e) =>
                            handleStudyClickForShowConfig(e, indicator)
                          }
                        >
                          <div className="d-flex align-items-center">
                            <CheckBox
                              className="studies-list__checkbox"
                              onChange={() => handleSelectIndicator(indicator)}
                              checked={isSelectedIndicator(indicator)}
                            />

                            <label className={styles.list__label}>
                              {indicator.name}
                            </label>
                          </div>

                          <RightChevronIconSVG
                            className={styles.list__chevron}
                            width={5}
                            height={10}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className={classnames(styles.item, styles.config)}>
                    {!isEmpty(dialogHelper) && (
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <div className={styles.config__title}>
                            {dialogHelper.title}
                          </div>

                          <div className={styles.config__content}>
                            {!!dialogHelper?.inputs?.length && (
                              <div className={styles.config__content__section}>
                                {dialogHelper.inputs.map((input, index) => (
                                  <div
                                    key={input.name + index}
                                    className={styles.config__content__item}
                                  >
                                    {generateStudyInputOrParameter(input, true)}
                                  </div>
                                ))}
                              </div>
                            )}

                            {!!dialogHelper?.outputs?.length && (
                              <div className={styles.config__content__section}>
                                {dialogHelper.outputs.map((output, index) => (
                                  <div
                                    key={output.name + index}
                                    className={styles.config__content__item}
                                  >
                                    <label
                                      className={styles.config__content__title}
                                    >
                                      {output.heading}
                                    </label>

                                    <ChartColorPicker
                                      color={
                                        output.color?.color ?? output.color
                                      }
                                      onChange={(color) =>
                                        handleChangeColor(output.name, color)
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            )}

                            {!!dialogHelper?.parameters?.length && (
                              <div className={styles.config__content__section}>
                                {dialogHelper.parameters.map(
                                  (parameter, index) => (
                                    <div
                                      key={parameter.name + index}
                                      className={styles.config__content__item}
                                    >
                                      {generateStudyInputOrParameter(parameter)}
                                    </div>
                                  ),
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className={styles.config__content__buttons}>
                          <Button onClick={handleResetStudy} secondary rounded>
                            <FormattedMessage id="Reset" />
                          </Button>

                          <Button onClick={close} rounded primary>
                            <FormattedMessage id="Save" />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default memo(ChartStudies);
