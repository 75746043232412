import { memo, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Table } from '@shared/components';
import {
  HEADER_HEIGHT,
  PAGINATION_ALIGN,
  ROW_HEIGHT,
  VARIANTS_ROWS,
} from '@shared/components/Table/table.constants';
import { usePagination } from '@shared/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { ITEMS_PER_PAGE } from '@constants';

import { marketsTableColumns } from './components';

import { MAIN_ROUTES } from '../../../../routes.constants';

import styles from './MarketsTable.module.scss';

const MarketsTable = ({
  data = [],
  inversion,
  includedColumnsKeys = [], // which columns should be added and in which order
  isShowChart = true,
  customStyles,
  isMarketDetailsRoute,
  ...props
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });

  const navigate = useNavigate();

  const { isLaptopDown, isTabletDown, isLargeTabletDown } = useBreakpoints();

  const chartCellSize = useMemo(() => {
    let size = 30;
    if (isShowChart) {
      size = 130;

      if (isLaptopDown) size = 100;
      if (isLargeTabletDown) size = 80;
    }

    return size;
  }, [isShowChart, isLaptopDown, isLargeTabletDown]);

  const { pageData } = usePagination(pagination, data);

  const meta = {
    pageIndex: pagination.pageIndex,
    limit: pagination.pageSize,
    count: data.length,
  };

  const columnsMemo = useMemo(() => {
    return marketsTableColumns({
      inversion,
      includedColumnsKeys,
      isShowChart,
      chartCellSize,
      customStyles,
      isMarketDetailsRoute,
    });
  }, [
    inversion,
    includedColumnsKeys,
    isShowChart,
    chartCellSize,
    customStyles,
    isMarketDetailsRoute,
  ]);

  const rowHeightMemo = useMemo(() => {
    let height = ROW_HEIGHT.XL;
    if (isLaptopDown) height = ROW_HEIGHT.L;
    if (isTabletDown) height = ROW_HEIGHT.M;
    return height;
  }, [isLaptopDown, isTabletDown]);

  const handleRowClick = ({ original }) => {
    navigate(generatePath(MAIN_ROUTES.TERMINAL, { marketId: original.id }));
  };

  const handlePaginationChange = (updater) => {
    setPagination(updater(pagination));
  };

  return (
    <Table
      data={pageData}
      columns={columnsMemo}
      variantRows={VARIANTS_ROWS.UNDERLINED}
      headerHeight={isTabletDown ? HEADER_HEIGHT.S : HEADER_HEIGHT.M}
      rowHeight={rowHeightMemo}
      onRowClick={handleRowClick}
      customStyles={styles}
      inversion={inversion}
      onPaginationChange={handlePaginationChange}
      isPaginationDisplayed
      paginationAlign={
        isTabletDown ? PAGINATION_ALIGN.RIGHT : PAGINATION_ALIGN.CENTER
      }
      meta={meta}
      {...props}
    />
  );
};

export default memo(MarketsTable);
