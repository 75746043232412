import { useMemo } from 'react';

import {
  useCoinsInfo,
  useDefaultAccount,
  useTransactions,
} from '@store/api/hooks';

const useLastTransactionsTable = () => {
  const { defaultSpotAccount } = useDefaultAccount();

  const { coinsInfo, isCoinsInfoSuccess } = useCoinsInfo();

  const { transactions, isTransactionsSuccess } = useTransactions({
    limit: 10,
    page: 1,
    accountId: defaultSpotAccount?.id,
  });

  const data = useMemo(() => {
    return {
      isLastTransactionsTableSuccess:
        isTransactionsSuccess && isCoinsInfoSuccess,
      lastTransactionsTableData: transactions.map((transaction) => {
        const coinSymbol = transaction.coin_symbol;
        const amount = transaction.amount || 0;
        const coinInfo = coinsInfo?.[coinSymbol];
        const precision = coinInfo?.digits;

        return {
          asset: coinSymbol,
          type: transaction.tx_type,
          details: {
            address: transaction.address,
            txid: transaction.txid,
          },
          amount: {
            amount,
            precision,
          },
          date: transaction.created_at,
          status: transaction.status,
        };
      }),
    };
  }, [coinsInfo, transactions, isTransactionsSuccess, isCoinsInfoSuccess]);

  return data;
};

export default useLastTransactionsTable;
