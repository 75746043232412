import { useDispatch } from 'react-redux';

import { CARD_TYPE } from '@shared/constants';
import { Select } from '@shared/ui';

import { VisaCardMock } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.mocks';
import { visaCardDetailsActions } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.slice';

export const FLEXIBLE = 'flexible';
export const WEEK = 'week';
export const MONTH = 'month';
export const QUARTER = 'quarter';
export const HALF_YEAR = 'half_year';
export const YEAR = 'year';
export const WEEKS_OF_MONTH = 4;
export const WEEK_IN_DAYS = 7;
export const MONTH_IN_DAYS = 30;
export const QUARTER_IN_DAYS = 90;
export const HALF_YEAR_IN_DAYS = 182;
export const ONE_YEAR = 1;
export const MONTH_IN_QUARTER = 4;
export const MONTH_IN_YEAR = 12;

// For StoryBook
export const VisaCardWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const options = Object.values(CARD_TYPE).map((cardType) => ({
    label: cardType,
    value: cardType,
  }));

  const defaultValue = {
    label: 'undefined',
    value: undefined,
  };

  const handleChange = ({ value }) => {
    dispatch(
      visaCardDetailsActions.setVisaCard({
        ...VisaCardMock.card,
        type: value,
      }),
    );
  };

  return (
    <div>
      <div>{children}</div>

      <div className="mt-20">
        <Select
          bordered
          defaultValue={defaultValue}
          onChange={handleChange}
          options={[...options, defaultValue]}
        />
      </div>
    </div>
  );
};
