import { CARD_TYPE_MAP } from '@shared/constants';

import { subtractPercent } from './util';

export function calculateDirectEarn(directReferrals, monthlySpend, cardType) {
  const cardInfo = CARD_TYPE_MAP[cardType];

  if (!cardInfo) return 0;

  return (
    directReferrals * subtractPercent(monthlySpend, cardInfo.directPercent)
  );
}

export function calculateSecondLevelEarn(
  secondLevelReferrals,
  directReferrals,
  monthlySpend,
  cardType,
) {
  const cardInfo = CARD_TYPE_MAP[cardType];

  if (!cardInfo) return 0;

  return (
    secondLevelReferrals *
    directReferrals *
    subtractPercent(monthlySpend, cardInfo.secondLevelPercent)
  );
}

export const getCardTypeByLevel = (level) => {
  return Object.keys(CARD_TYPE_MAP).find(
    (key) => CARD_TYPE_MAP[key].level === level,
  );
};

export const VisaCardsUtils = {
  calculateDirectEarn,
  calculateSecondLevelEarn,
  getCardTypeByLevel,
};
