import { createColumnHelper } from '@tanstack/react-table';

import { ColumnHeaderItem } from '@shared/components';

import {
  AssetItem,
  EstimationItem,
  IdItem,
} from '@pages/WalletsPage/components';

import { ProgressItem } from '../components';

const { accessor } = createColumnHelper();

const instantBonusTableColumns = () => [
  accessor('id', {
    header: () => <ColumnHeaderItem id="Contract" />,
    cell: (info) => {
      const id = info.getValue();

      return <IdItem id={id} />;
    },
    maxSize: 30,
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
    enableTruncating: false,
  }),
  accessor('asset', {
    header: () => <ColumnHeaderItem id="Asset" />,
    cell: (info) => {
      const { symbol } = info.getValue();

      return <AssetItem coinSymbol={symbol} iconHeight={28} iconWidth={28} />;
    },
    maxSize: 130,
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
  }),
  accessor('estimation', {
    header: () => <ColumnHeaderItem id="Bonus" />,
    cell: (info) => {
      const estimation = info.getValue();

      return <EstimationItem estimation={estimation} />;
    },
    maxSize: 30,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
  }),
  accessor('progress', {
    header: () => <ColumnHeaderItem id="Filled" />,
    cell: (info) => {
      const { progressPercent } = info.getValue();

      return <ProgressItem progressPercent={progressPercent} />;
    },
    maxSize: 120,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
    enableTruncating: false,
  }),
];

export default instantBonusTableColumns;
