import { format } from '@utils/numbers';

import { ORDER_FORM_TYPE } from '@constants';

export const getPriceFieldRules = (
  intl,
  currentPrice,
  currentMarket,
  basicOrderFormType,
  isBuyMode,
) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      value: (value) => {
        const currentPriceValue = Number(currentPrice) || 0;
        const isLimitOrder =
          basicOrderFormType === ORDER_FORM_TYPE.LIMIT_OTO_BASIC;

        if (
          ((!isBuyMode && isLimitOrder) || (isBuyMode && !isLimitOrder)) &&
          Number(value) <= currentPriceValue
        ) {
          return intl.formatMessage(
            { id: 'PriceHigherThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        if (
          ((isBuyMode && isLimitOrder) || (!isBuyMode && !isLimitOrder)) &&
          Number(value) >= currentPriceValue
        ) {
          return intl.formatMessage(
            { id: 'PriceLowerThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }
      },
    },
  };
};

export const getAmountFieldRules = (intl) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
  };
};

export const getTotalFieldRules = (intl) => {
  return {
    validate: {
      min: (v) => {
        if (Number(v) <= 0) {
          return intl.formatMessage({ id: 'TotalLessThanZero' });
        }

        return true;
      },
    },
  };
};

export const getTpPriceFieldRules = (
  intl,
  currentMarket,
  isBuyMode,
  isTakeProfit,
  priceWatch,
) => {
  return {
    required: isTakeProfit && intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      value: (value) => {
        if (Number(value) <= 0) {
          return intl.formatMessage({ id: 'MinTpPrice' });
        }

        if (!isBuyMode && Number(value) >= priceWatch) {
          return intl.formatMessage(
            { id: 'TpPriceLowerThanCurrent' },
            {
              marketPrice: format(priceWatch, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        if (isBuyMode && Number(value) <= priceWatch) {
          return intl.formatMessage(
            { id: 'TpPriceHigherThanCurrent' },
            {
              marketPrice: format(priceWatch, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }
      },
    },
  };
};

export const getSlPriceFieldRules = (
  intl,
  currentMarket,
  isBuyMode,
  isStopLoss,
  priceWatch,
) => {
  return {
    required: isStopLoss && intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      value: (value) => {
        if (Number(value) <= 0) {
          return intl.formatMessage({ id: 'MinSlPrice' });
        }

        if (!isBuyMode && Number(value) <= priceWatch) {
          return intl.formatMessage(
            { id: 'SlPriceHigherThanCurrent' },
            {
              marketPrice: format(priceWatch, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        if (isBuyMode && Number(value) >= priceWatch) {
          return intl.formatMessage(
            { id: 'SlPriceLowerThanCurrent' },
            {
              marketPrice: format(priceWatch, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }
      },
    },
  };
};
