import { createSelector } from '@reduxjs/toolkit';

import { BOT_ACCOUNT, BONUS_ACCOUNT } from '@shared/constants';

import { ApiSelectorsUtils } from '@api/utils';

const defaultAccounts = [];
const defaultSpotAccount = undefined;

const dataAccountsSelector =
  ApiSelectorsUtils.createDataSelector(defaultAccounts);

const dataDefaultSpotAccountSelector =
  ApiSelectorsUtils.createDataSelector(defaultSpotAccount);

const defaultSpotAccountSelector = createSelector(
  dataDefaultSpotAccountSelector,
  (spotAccount) => {
    if (!spotAccount) {
      return defaultSpotAccount;
    }

    return {
      ...spotAccount,
      id: spotAccount.ID,
      created_at: new Date(spotAccount.CreatedAt),
      updated_at: new Date(spotAccount.UpdatedAt),
      origin: spotAccount,
    };
  },
);

const accountsSelector = createSelector(dataAccountsSelector, (accounts) => {
  return accounts
    .map((account) => ({
      ...account.sub_account,
      id: account.sub_account.ID,
      created_at: new Date(account.sub_account.CreatedAt),
      updated_at: new Date(account.sub_account.UpdatedAt),
      origin: account.sub_account,
    }))
    .filter(
      (account) =>
        account.title !== BOT_ACCOUNT && account.title !== BONUS_ACCOUNT,
    );
});

const spotAccountInfoFromSpotAccountsInfoMapSelector = createSelector(
  (spotAccountsInfoMap) => spotAccountsInfoMap,
  (spotAccountsInfoMap, accountId) => accountId,
  (spotAccountsInfoMap, accountId) => {
    return spotAccountsInfoMap.find(({ id }) => id === parseInt(accountId));
  },
);

export const AccountsApiSelectors = {
  dataDefaultSpotAccountSelector,
  dataAccountsSelector,
  defaultSpotAccountSelector,
  accountsSelector,
  spotAccountInfoFromSpotAccountsInfoMapSelector,
};
