import { memo } from 'react';

import classNames from 'classnames';
import QRCode from 'qrcode.react';

import { PLAY_MARKET_MOBILE_APP_LINK } from '@shared/constants';

import Link from '@components/Link';
import Modal from '@components/modals/Modal';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import { ReactComponent as MobileAppAndroidDownloadSVG } from '@images/mobile-app-android-download.svg';
import { ReactComponent as MobileAppIOSDownloadSVG } from '@images/mobile-app-ios-download.svg';

import styles from './DownloadMobileAppModal.module.scss';

const DownloadMobileAppModal = () => {
  const { isOpen, closeModal } = useModal(modalsIds.DOWNLOAD_MOBILE_APP);

  return (
    <Modal
      className={classNames('modal-xl', styles.modal)}
      visible={isOpen}
      onClose={closeModal}
    >
      <div className="container-fluid">
        <div className="row gap-20">
          <div className="col px-0 d-flex flex-column align-items-center">
            <QRCode
              level="M"
              size={269}
              includeMargin
              value="https://apps.apple.com/ua/app/paramountdax/id1585379080"
            />

            <Link
              className="mt-20"
              to="https://apps.apple.com/ua/app/paramountdax/id1585379080"
              isNewTab
            >
              <MobileAppIOSDownloadSVG className="mobile-app-ios-download" />
            </Link>
          </div>

          <div className="col px-0 d-flex flex-column align-items-center">
            <QRCode
              level="M"
              size={269}
              includeMargin
              value={PLAY_MARKET_MOBILE_APP_LINK}
            />

            <Link className="mt-20" to={PLAY_MARKET_MOBILE_APP_LINK} isNewTab>
              <MobileAppAndroidDownloadSVG className="mobile-app-android-download" />
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(DownloadMobileAppModal);
