import React, { memo } from 'react';

import { ProgressBar } from '@shared/ui';

import { format } from '@utils/numbers';

import styles from './ProgressItem.module.scss';

const ProgressItem = ({ progressPercent, currentBonusPercent }) => {
  const formattedPercent = format(progressPercent, {
    precision: 0,
  });

  return (
    <div className={styles.progress_item}>
      <ProgressBar
        isPill
        percentages={formattedPercent}
        height={4}
        customStyles={styles}
      />
      <span className="mx-4">/</span>
      <span>{currentBonusPercent}%</span>
    </div>
  );
};

export default memo(ProgressItem);
