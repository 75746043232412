import { memo } from 'react';
import { useIntl } from 'react-intl';

import { SelectController } from '@shared/ui';

import { ENTRY_PRICE_UPDATE_RATES } from '@pages/WalletsPage/constants';

import styles from './BotTrendEntryPriceUpdate.module.scss';

const BotTrendEntryPriceUpdate = ({ control, isDisabled }) => {
  const intl = useIntl();

  return (
    <SelectController
      control={control}
      name="entryPriceUpdateRates"
      label={intl.formatMessage({ id: 'EntryPriceUpdateRate' })}
      rules={{
        required: intl.formatMessage({ id: 'FieldRequired' }),
      }}
      options={ENTRY_PRICE_UPDATE_RATES}
      disabled={isDisabled}
      underlined
      size="m"
      inversion
      customStyles={styles}
    />
  );
};

export default memo(BotTrendEntryPriceUpdate);
