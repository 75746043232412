import React, { memo } from 'react';

import { components } from 'react-select';

import styles from './MenuList.module.scss';

const MenuList = ({ children, ...props }) => {
  return (
    <components.MenuList className={styles.menu_list} {...props}>
      {children}
    </components.MenuList>
  );
};

export default memo(MenuList);
