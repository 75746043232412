import { useMemo } from 'react';

import { useGetCandlesQuery, useLazyGetCandlesQuery } from '@api/candlesAPI';

const selectFromResult = (result, { symbol } = {}) => {
  const dataResult = {
    isCandlesSuccess: result.isSuccess,
    isCandlesFetching: result.isFetching,
  };

  dataResult.candles = symbol ? result?.data : undefined;

  return dataResult;
};

export const useCandles = (
  { symbol, from, to, interval, limit, period, scope } = {},
  options,
) => {
  const result = useGetCandlesQuery(
    { symbol, from, to, interval, limit, period, scope },
    options,
  );

  const data = useMemo(() => {
    return selectFromResult(result, { symbol });
  }, [result, symbol]);

  return data;
};

export const useLazyCandles = (options) => {
  const [trigger, result] = useLazyGetCandlesQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result, result.originalArgs);
  }, [result]);

  return [trigger, data];
};
