import React, { memo } from 'react';

import TruncatedText from '@shared/components/TruncatedText/TruncatedText';

import styles from './OrdersPair.module.scss';

const OrdersPair = ({ pair }) => {
  return (
    <TruncatedText>
      <span className={styles.bold}>{pair || '---'}</span>
    </TruncatedText>
  );
};

export default memo(OrdersPair);
