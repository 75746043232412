import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import TruncatedText from '@shared/components/TruncatedText';

import useBreakpoints from '@hooks/useBreakpoints';

const BonusContractHeaderItem = () => {
  const intl = useIntl();

  const { isTabletDown } = useBreakpoints();

  return (
    <TruncatedText>
      <span>
        {intl.formatMessage({ id: 'Bonus' })}

        {isTabletDown ? '/' : <br />}

        {intl.formatMessage({ id: 'Contract' })}
      </span>
    </TruncatedText>
  );
};

export default memo(BonusContractHeaderItem);
