import { useMemo } from 'react';

import { keyBy } from 'lodash';

import {
  useGetMarketsInfoQuery,
  useLazyGetMarketsInfoQuery,
} from '@api/marketsAPI';
import { MarketsApiSelectors } from '@api/selectors';

const selectFromResult = (result, { marketId } = {}) => {
  const dataResult = {
    isMarketsInfoSuccess: result.isSuccess,
  };

  const marketsInfoMap = MarketsApiSelectors.dataSelector(result);

  dataResult.marketInfo = marketId
    ? MarketsApiSelectors.marketInfoFromMarketsInfoMapSelector(
        marketsInfoMap,
        marketId,
      )
    : undefined;

  dataResult.marketsInfo = marketsInfoMap.length
    ? keyBy(marketsInfoMap, 'id')
    : undefined;
  dataResult.marketsInfoMap = marketsInfoMap;

  return dataResult;
};

export const useMarketsInfo = ({ marketId } = {}, options) => {
  const result = useGetMarketsInfoQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result, { marketId });
  }, [result, marketId]);

  return data;
};

export const useLazyMarketsInfo = (options) => {
  const [trigger, result] = useLazyGetMarketsInfoQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result, result.originalArgs);
  }, [result]);

  return [trigger, data];
};
