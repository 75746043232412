import { useMemo } from 'react';

import {
  useGetDefaultAccountQuery,
  useLazyGetDefaultAccountQuery,
} from '@api/accountsAPI';
import { AccountsApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isDefaultSpotAccountSuccess: result.isSuccess,
    isDefaultSpotAccountFetching: result.isFetching,
  };

  dataResult.defaultSpotAccount =
    AccountsApiSelectors.defaultSpotAccountSelector(result);

  dataResult.defaultMarginAccount = { id: 'margin' };

  return dataResult;
};

export const useDefaultAccount = (args, options) => {
  const result = useGetDefaultAccountQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export const useLazyDefaultSpotAccount = (options) => {
  const [trigger, result] = useLazyGetDefaultAccountQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return [trigger, data];
};
