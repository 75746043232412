import { memo, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { format as dateFormat } from 'date-fns';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import { CRYPTO_CURRENCIES, DAY_MONTH_YEAR_FORMAT } from '@shared/constants';

import SideBar from '@components/SideBar';

import { useAvailablePlansTable } from '@pages/WalletsPage/Staking/hooks';

import { useAccountBalances, useDefaultAccount } from '@store/api/hooks';
import { useCreateStakingMutation } from '@store/api/stakingAPI';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { DEFAULT_PRECISION, NOOP, sidebarIds } from '@constants';

import { ConfirmStakeForm, StakeForm } from './components';

import styles from './CreateStakeItemSidebar.module.scss';

const CreateStakeItemSidebar = ({ isGrid, onSubmitSuccess = NOOP }) => {
  const [isConfirmStaking, setIsConfirmStaking] = useState(false);

  const { state } = useLocation();

  const intl = useIntl();

  const { isTabletDown } = useBreakpoints();

  const { sidebar, isOpen, closeSidebar, openSidebar } = useSidebar(
    sidebarIds.WALLETS_CREATE_STAKING_ITEM,
  );

  const { defaultSpotAccount } = useDefaultAccount(undefined, {
    skip: !isOpen,
  });

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      pay: '',
      percent: 0,
      period: sidebar?.params?.period?.period,
    },
  });

  const { control, handleSubmit, setValue, reset } = methods;

  const watchPeriod = useWatch({ control, name: 'period' });
  const watchAmountValue = useWatch({ control, name: 'pay' });
  const watchPayCurrency = useWatch({ control, name: 'payCurrency' });
  const percentWatch = useWatch({ control, name: 'percent' });

  const [createStakeItem] = useCreateStakingMutation();

  const { coinBalance } = useAccountBalances(
    {
      coinSymbol: watchPayCurrency?.value ?? CRYPTO_CURRENCIES.USDT,
      accountId: defaultSpotAccount?.id,
    },
    { skip: !isOpen },
  );

  const mainAvailableBalance = Number.parseFloat(coinBalance?.available) || 0;

  const isAllPeriodsSelected = isTabletDown && !isGrid;

  const {
    limits: availablePlansLimits,
    periods: availablePlanPeriods,
    isAllPeriodsSelected: isAvailablePlanPeriodsSelected,
  } = useAvailablePlansTable({ isAllPeriodsSelected });

  const periodPercent = useMemo(
    () =>
      availablePlanPeriods.find(({ period }) => period === watchPeriod)
        ?.percent[watchPayCurrency?.value],
    [availablePlanPeriods, watchPayCurrency, watchPeriod],
  );

  const coinSymbol = watchPayCurrency?.value?.toUpperCase();
  const selectedLimit = availablePlansLimits?.[watchPayCurrency?.value];
  const minLimit = selectedLimit?.min;
  const maxLimit = selectedLimit?.max;
  const youWillEarn =
    Number(watchAmountValue) + Number(watchAmountValue * periodPercent);
  const launchedOn = dateFormat(new Date(), DAY_MONTH_YEAR_FORMAT);

  const stakeCurrencyPrecision = useMemo(
    () => watchPayCurrency?.digits || DEFAULT_PRECISION,
    [watchPayCurrency],
  );

  const periodPreset = sidebar?.params;

  useEffect(() => {
    if (isOpen) {
      setValue(
        'period',
        isAvailablePlanPeriodsSelected
          ? periodPreset?.globalPeriod
          : periodPreset?.period?.period,
      );
    } else {
      reset();
      setIsConfirmStaking(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const percentOfAmount = format(
      (mainAvailableBalance * percentWatch) / 100,
      {
        precision: stakeCurrencyPrecision,
        removeZeroEnd: true,
        returnNumber: true,
      },
    );

    methods.setValue('pay', percentOfAmount);
  }, [
    methods,
    mainAvailableBalance,
    maxLimit,
    percentWatch,
    stakeCurrencyPrecision,
  ]);

  // reset percent slider after currency change
  useEffect(() => {
    if (!watchPayCurrency?.value) return;

    methods.setValue('percent', 0);
  }, [methods, watchPayCurrency?.value]);

  const formValues = state?.formValues;

  // set period from link state
  useEffect(() => {
    if (!formValues?.period?.period) return;

    const periodFromLink = formValues.period.period;

    if (watchPeriod !== periodFromLink) {
      setValue('period', periodFromLink);
    }
  }, [watchPeriod, formValues]);

  useEffect(() => {
    formValues && openSidebar(formValues);

    return () => {
      closeSidebar();
    };
  }, [formValues]);

  const setMaxValueHandler = () => {
    setValue('percent', 0);

    const payValue =
      mainAvailableBalance > maxLimit ? maxLimit : mainAvailableBalance;

    const formattedPayValue = format(payValue, {
      precision: stakeCurrencyPrecision,
      returnNumber: true,
      removeZeroEnd: true,
    });

    setTimeout(() => setValue('pay', formattedPayValue));
  };

  const onBackHandler = () => {
    setIsConfirmStaking(false);
  };

  const navigateToConfirmStaking = () => {
    setIsConfirmStaking(true);
  };

  const cancelStakingItem = () => {
    closeSidebar();

    setIsConfirmStaking(false);
  };

  const closeSidebarHandler = () => {
    closeSidebar();

    setValue('percent', 0);
  };

  const onSubmit = async () => {
    try {
      await createStakeItem({
        period: watchPeriod,
        amount: watchAmountValue,
        coin_symbol: watchPayCurrency?.value,
      }).unwrap();

      closeSidebar();
      setIsConfirmStaking(false);
      onSubmitSuccess();

      toast.success(
        intl.formatMessage({
          id: 'StakeItemWasSuccessfullyCreated',
        }),
      );
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      onClose={closeSidebarHandler}
      withCloseButton
      title={
        isConfirmStaking ? (
          <>
            <span className="text-capitalize">
              <FormattedMessage id="ConfirmStaking" />
            </span>{' '}
            {watchPayCurrency?.value?.toUpperCase()}
          </>
        ) : (
          <FormattedMessage id="Stake" />
        )
      }
      withBackButton={isConfirmStaking}
      onBack={onBackHandler}
      customStyles={styles}
    >
      <FormProvider {...methods}>
        {isConfirmStaking ? (
          <ConfirmStakeForm
            cancelStakingItem={cancelStakingItem}
            coinSymbol={coinSymbol}
            youWillEarn={youWillEarn}
            handleSubmit={handleSubmit}
            launchedOn={launchedOn}
            methods={methods}
            onSubmit={onSubmit}
            watchPeriod={watchPeriod}
            periodPercent={periodPercent}
            watchAmountValue={watchAmountValue}
          />
        ) : (
          <StakeForm
            availablePlanPeriods={availablePlanPeriods}
            coinSymbol={coinSymbol}
            mainAvailableBalance={mainAvailableBalance}
            maxLimit={maxLimit}
            minLimit={minLimit}
            navigateToConfirmStaking={navigateToConfirmStaking}
            periodPercent={periodPercent}
            closeSidebar={closeSidebar}
            setMaxValueHandler={setMaxValueHandler}
            isSidebarOpen={isOpen}
          />
        )}
      </FormProvider>
    </SideBar>
  );
};

export default memo(CreateStakeItemSidebar);
