import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

export const transactionsAPI = createApi({
  reducerPath: 'transactionsAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: ({
        accountId,
        page,
        type,
        limit,
        fromDate = 0,
        toDate = new Date(),
      }) => ({
        method: 'GET',
        url: 'users/transactions',
        params: {
          account: accountId,
          page,
          type,
          fromDate,
          toDate,
          limit,
        },
      }),
    }),
    exportTransactionsFile: builder.query({
      query: ({
        accountId,
        status = 'reports',
        page = 1,
        fromDate = 0,
        toDate = null,
        format,
        type,
        fileType = 'reports',
      }) => ({
        url: 'users/transactions/export',
        params: {
          account: accountId,
          status,
          page,
          fromDate,
          toDate,
          format,
          fileType,
          transactionType: type,
        },
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTransactionsQuery, useLazyExportTransactionsFileQuery } =
  transactionsAPI;
