import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';
import subscriptions from '@api/ws/subscriptions';

export const balancesAPI = createApi({
  reducerPath: 'balancesAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    streamBalances: builder.query({
      query: () => 'wallets/balances',
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(
        userId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded;

          subscriptions.subscribeBalances(userId, ({ data, reason }) => {
            updateCachedData((draftState) => {
              if (reason === 'error') {
                return null;
              }

              if (reason === 'unsubscribe') {
                return draftState;
              }

              return { ...draftState, ...data };
            });
          });
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        await cacheEntryRemoved;

        subscriptions.unsubscribeBalances();
      },
    }),
  }),
});

export const { useStreamBalancesQuery } = balancesAPI;
