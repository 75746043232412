import mockData from 'src/mock.json';

const useLaunchpadAssets = () => {
  const { assets } = mockData.useLaunchpadAssets;
  return {
    assets,
    isSuccess: true,
  };
};

export default useLaunchpadAssets;
