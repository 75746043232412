import { memo } from 'react';
import { useIntl } from 'react-intl';

import cx from 'classnames';

import { useNavigateBack } from '@shared/hooks';
import { Button } from '@shared/ui';

import { NOOP } from '@constants';

import styles from './FormActions.module.scss';

export const FORM_ACTION_ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

/**
 * onCancel by default return to the previous page (useNavigateBack hook)
 * */
const FormActions = ({
  submitButtonProps = {},
  submitText,
  onSubmit = NOOP,
  cancelButtonProps = {},
  cancelText,
  onCancel,
  orientation = FORM_ACTION_ORIENTATION.HORIZONTAL,
  inversion,
  customStyles = {},
}) => {
  const intl = useIntl();

  const navigateBack = useNavigateBack();

  return (
    <div
      className={cx(
        styles.form_actions,
        styles[orientation],
        customStyles.form_actions,
      )}
    >
      <Button
        type="submit"
        onClick={onSubmit}
        size="l"
        fill
        inversion={inversion}
        color="secondary"
        {...submitButtonProps}
      >
        {submitText || intl.formatMessage({ id: 'Submit' })}
      </Button>

      <Button
        type="button"
        onClick={onCancel || navigateBack}
        size="l"
        fill
        inversion={inversion}
        variant="outlined"
        {...cancelButtonProps}
      >
        {cancelText || intl.formatMessage({ id: 'Cancel' })}
      </Button>
    </div>
  );
};

export default memo(FormActions);
