import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import {
  InfoWidget,
  PersonalData,
  PersonalFormattedCurrency,
  Widget,
} from '@shared/components';
import { FIAT_CURRENCIES } from '@shared/constants';

import styles from './VisaCardWidget.module.scss';
import mockData from 'src/mock.json';

const VisaCardWidget = () => {
  const { cardType, cardNumber, cardBalance } = mockData.VisaCardWidget;

  const abbreviatedCardNumber = `**** ${cardNumber.slice(-4)}`;
  const title = `${cardType} ${abbreviatedCardNumber}`;

  return (
    <Widget className={styles.visa_card_widget}>
      <InfoWidget
        customStyles={styles}
        title={<PersonalData data={title} />}
        value={
          <PersonalFormattedCurrency
            currency={FIAT_CURRENCIES.EUR}
            value={cardBalance}
          />
        }
        suffix={
          <span className={classNames(styles.suffix, 'value-suffix')}>€</span>
        }
      />

      <div className={styles.sub_title}>
        <PersonalData data={<FormattedMessage id="VirtualVisaCard" />} />
      </div>
    </Widget>
  );
};

export default memo(VisaCardWidget);
