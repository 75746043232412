import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import cx from 'classnames';
import { useForm, useWatch } from 'react-hook-form';

import { Card, FORM_ACTION_ORIENTATION } from '@shared/components';
import FormActions from '@shared/components/FormActions/FormActions';
import { PasswordInputController } from '@shared/ui';
import { validateConfirmPassword, validatePassword } from '@shared/validations';

import { useProfileMutations } from '@api/hooks';

import styles from './EnableTradePassword.module.scss';

const EnableTradePassword = () => {
  const methods = useForm({
    defaultValues: { newPassword: '', confirmPassword: '' },
  });
  const { handleSubmit, control } = methods;

  const watchNewPassword = useWatch({ control, name: 'newPassword' });

  const intl = useIntl();

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    size: 'l',
  };

  const { onSetTradePassword, isSetTradePasswordLoading } =
    useProfileMutations();

  const handleFormSubmit = ({ newPassword }) => {
    onSetTradePassword({ tradePassword: newPassword });
  };

  return (
    <div className={cx('security-card', styles.security_card)}>
      <Card
        customStyles={styles}
        title={<FormattedMessage id="EnableTradePassword" />}
      >
        <div className="security-card-description">
          <FormattedMessage id="EnableTradePasswordDescription" />
        </div>

        <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <PasswordInputController
            name="newPassword"
            label={`${intl.formatMessage({
              id: 'TradePassword',
            })}:`}
            placeholder={intl.formatMessage({
              id: 'EnterPassword',
            })}
            rules={{
              required: intl.formatMessage({ id: 'PasswordIsRequired' }),
              validate: {
                format: validatePassword(intl),
              },
            }}
            {...commonFieldProps}
            {...methods}
          />

          <PasswordInputController
            name="confirmPassword"
            label={`${intl.formatMessage({
              id: 'RepeatTradePassword',
            })}:`}
            placeholder={intl.formatMessage({
              id: 'EnterPassword',
            })}
            rules={{
              required: intl.formatMessage({ id: 'PasswordIsRequired' }),
              validate: {
                confirmPassword: validateConfirmPassword(
                  watchNewPassword,
                  intl,
                ),
              },
            }}
            {...commonFieldProps}
            {...methods}
          />

          <FormActions
            submitText={<FormattedMessage id="SubmitAndEnable" />}
            submitButtonProps={{
              isLoading: isSetTradePasswordLoading,
              disabled: isSetTradePasswordLoading,
            }}
            cancelButtonProps={{
              variant: 'text',
            }}
            orientation={FORM_ACTION_ORIENTATION.VERTICAL}
          />
        </form>
      </Card>
    </div>
  );
};

export default memo(EnableTradePassword);
