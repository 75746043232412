import React, { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  FormActions,
  SideBarBlock,
  SideBarInfoPoint,
  SideBarList,
} from '@shared/components';
import { parseToLocalization } from '@shared/utils';

import SideBar from '@components/SideBar';

import {
  bankTransferDetailsSelector,
  bankTransferTypeSelector,
  checkoutWithdrawDataSelector,
  userDataSelector,
  withdrawCurrencySymbolSelector,
  withdrawPaymentMethodSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';

import { useCoinsInfo } from '@store/api/hooks';

import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { sidebarIds } from '@constants';

import styles from './SideBarWithdrawConfirmBankTransfer.module.scss';

const SideBarWithdrawConfirmBankTransfer = ({ onSubmit }) => {
  const intl = useIntl();

  const selectedCurrency = useSelector(withdrawCurrencySymbolSelector);
  const bankTransferType = useSelector(bankTransferTypeSelector);
  const { amount, fee, profit } = useSelector(checkoutWithdrawDataSelector);
  const activePaymentMethod = useSelector(withdrawPaymentMethodSelector);
  const bankTransferDetails = useSelector(bankTransferDetailsSelector);
  const userData = useSelector(userDataSelector);

  const { toggleSidebar: toggleBankTransferSidebar } = useSidebar(
    sidebarIds.WALLETS_WITHDRAW_BANK_TRANSFER,
  );
  const {
    toggleSidebar: toggleBankTransferConfirmSidebar,
    isOpen: isSidebarBankTransferConfirmOpen,
  } = useSidebar(sidebarIds.WALLETS_WITHDRAW_CONFIRM_BANK_TRANSFER);

  const { coinInfo } = useCoinsInfo(
    { coinSymbol: selectedCurrency },
    { skip: !isSidebarBankTransferConfirmOpen },
  );

  const precision = coinInfo?.digits;

  const transferDetails = [
    {
      id: 'paymentMethod',
      label: `${intl.formatMessage({ id: 'PaymentMethod' })}:`,
      value: intl.formatMessage({
        id: parseToLocalization(activePaymentMethod),
      }),
    },
    {
      id: 'amount',
      label: `${intl.formatMessage({ id: 'Amount' })}:`,
      value: `${format(amount, {
        precision,
      })} ${selectedCurrency.toUpperCase()}`,
    },
    {
      id: 'commission',
      label: `${intl.formatMessage({ id: 'Commission' })}:`,
      value: `${format(fee, {
        precision,
      })} ${selectedCurrency.toUpperCase()}`,
    },
    {
      id: 'profit',
      label: `${intl.formatMessage({ id: 'YouWillGet' })}:`,
      value: `${format(profit, {
        precision,
      })} ${selectedCurrency.toUpperCase()}`,
    },
  ];

  const bankDetails = useMemo(
    () => [
      {
        id: 'transferType',
        label: `${intl.formatMessage({ id: 'TransferType' })}:`,
        value: bankTransferType?.label,
      },
      {
        id: 'name',
        label: `${intl.formatMessage({ id: 'FirstName' })}:`,
        value: `${userData.firstName} ${userData.lastName}`,
      },
      ...bankTransferDetails.map((detail) => ({
        ...detail,
        label: `${intl.formatMessage({ id: detail.id })}:`,
      })),
    ],
    [bankTransferType, userData, bankTransferDetails],
  );

  const handleConfirmTransfer = async () => {
    let bankDetails = {
      payment: bankTransferType.value,
      firstName: userData.firstName,
      lastName: userData.lastName,
    };

    bankTransferDetails.forEach((detail) => {
      bankDetails[detail.id] = detail.value;
    });

    const transferData = {
      amount: amount,
      data: bankDetails,
    };

    onSubmit(transferData);

    toggleBankTransferConfirmSidebar();
  };

  return (
    <SideBar
      open={isSidebarBankTransferConfirmOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={toggleBankTransferConfirmSidebar}
      onBack={toggleBankTransferSidebar}
      withCloseButton
      withBackButton
      title={intl.formatMessage({ id: 'ConfirmTransfer' })}
    >
      <div className={styles.content}>
        <div>
          <SideBarBlock>
            <SideBarList items={transferDetails} />
          </SideBarBlock>

          <SideBarBlock>
            <SideBarList items={bankDetails} />
          </SideBarBlock>

          <SideBarBlock>
            <SideBarInfoPoint
              title={intl.formatMessage({ id: 'YourFundsWillBeIn' })}
              description={intl.formatMessage(
                { id: 'BankTransferDescription' },
                { bank: bankTransferType?.label },
              )}
            />
          </SideBarBlock>
        </div>

        <div>
          <FormActions
            submitText={<FormattedMessage id="YesConfirm" />}
            cancelText={<FormattedMessage id="Cancel" />}
            submitButtonProps={{ type: 'button', fill: true, inversion: true }}
            cancelButtonProps={{ fill: true, inversion: true }}
            onSubmit={handleConfirmTransfer}
            onCancel={toggleBankTransferConfirmSidebar}
            customStyles={styles}
          />
        </div>
      </div>
    </SideBar>
  );
};

export default memo(SideBarWithdrawConfirmBankTransfer);
