import React, { memo } from 'react';

import { useUserAuthBy } from '@shared/hooks';

import { ChangeEmailCard } from './ChangeEmailCard';
import { ChangePasswordCard } from './ChangePasswordCard';
import { ChangePhoneCard } from './ChangePhoneCard';
import { DeleteAccountCard } from './DeleteAccountCard';
import NeedASpecialAccount from './NeedASpecialAccount/NeedASpecialAccount';
import { ProfileInfoCard } from './ProfileInfoCard';
import { SubscribeToUpdates } from './SubscribeToUpdates';

import styles from './ProfilePage.module.scss';

const Profile = () => {
  const { isAuthByPhone } = useUserAuthBy();

  return (
    <div className="row">
      <div className="col-12 col-md-8 col-xl-6 offset-xl-2 mb-30 mb-md-0">
        <div className={styles.profile_content}>
          <ProfileInfoCard />

          <ChangePasswordCard />

          {isAuthByPhone ? <ChangePhoneCard /> : <ChangeEmailCard />}

          <DeleteAccountCard />
        </div>
      </div>

      <div className="col-12 col-md-4 col-xl-3 d-flex flex-column gap-30">
        <SubscribeToUpdates />

        <NeedASpecialAccount />
      </div>
    </div>
  );
};

export default memo(Profile);
