import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { createLogger } from 'redux-logger';

import { overviewEarnWidgetsReducer } from '@pages/AccountPage/Overview/OverviewEarnWidgets/overviewEarnWidgets.slice';
import { kybReducer } from '@pages/AccountPage/Verification/KYB/KYB.slice';
import { kycReducer } from '@pages/AccountPage/Verification/KYC/KYC.slice';
import { allCryptoMarketsReducer } from '@pages/landings/MarketsPage/AllCryptoMarkets/allCryptoMarkets.slice';
import { announcementReducer } from '@pages/LegacyPages/AnnouncementPage/AnnouncementPage.slice';
import { announcementsReducer } from '@pages/LegacyPages/AnnouncementsPage/AnnouncementsPage.slice';
import {
  orderHistoryReducer,
  tradeFeesReducer,
  tradeHistoryReducer,
} from '@pages/OrderPages/slices';
import { botStartReducer } from '@pages/WalletsPage/BotStart/botStart.slice';
import { walletDepositReducer } from '@pages/WalletsPage/Deposit/Deposit.slice';
import {
  availableContractsReducer,
  expiredContractsReducer,
  myContractsReducer,
} from '@pages/WalletsPage/InstantBonus/slices';
import {
  historyLaunchpadReducer,
  watchlistLaunchpadReducer,
} from '@pages/WalletsPage/Launchpad/slices';
import { availablePlansReducer } from '@pages/WalletsPage/Staking/slices';
import { archivedBotsReducer } from '@pages/WalletsPage/TradingBots/ArchivedBotsTab/archivedBots.slice';
import { liquidatedBotsReducer } from '@pages/WalletsPage/TradingBots/LiquidatedBotsTab/liquidatedBotsTab.slice';
import { myBotsReducer } from '@pages/WalletsPage/TradingBots/MyBotsTab/myBots.slice';
import { tradingBotsReducer } from '@pages/WalletsPage/TradingBots/tradingBots.slice';
import { transactionHistoryReducer } from '@pages/WalletsPage/TransactionHistory';
import { visaCardDetailsReducer } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.slice';
import { visaCardSettingsReducer } from '@pages/WalletsPage/VisaCardSettings/VisaCardSettings.slice';
import { walletsWidgetsReducer } from '@pages/WalletsPage/WalletsOverview/Widgets/walletsWidgetsSlice';
import { walletWithdrawReducer } from '@pages/WalletsPage/Withdraw/Withdraw.slice';

import { gridBlocksReducer } from '@store/gridBlocks/gridBlocksSlice';
import {
  isAuthorizedUserAction,
  settingsReducer,
} from '@store/settings/settingsSlice';

import { accountsAPI } from '@api/accountsAPI';
import { announcementsAPI } from '@api/announcementsAPI';
import { authAPI } from '@api/authAPI';
import { balancesAPI } from '@api/balancesAPI';
import { bonusAccountAPI } from '@api/bonusAccountAPI';
import { botsAPI } from '@api/botsAPI';
import { candlesAPI } from '@api/candlesAPI';
import { coinsAPI } from '@api/coinsAPI';
import { featuresAPI } from '@api/featuresAPI';
import { feesAPI } from '@api/feesAPI';
import { launchpadAPI } from '@api/launchpadAPI';
import { managementApiKeyAPI } from '@api/managementApiKeyAPI';
import { marketsAPI } from '@api/marketsAPI';
import { notificationsAPI } from '@api/notificationsAPI';
import { ordersAPI } from '@api/ordersAPI';
import { platformTokenAPI } from '@api/platformTokenAPI';
import { profileAPI } from '@api/profileAPI';
import { referralAPI } from '@api/referralAPI';
import { stakingAPI } from '@api/stakingAPI';
import { supportAPI } from '@api/supportAPI';
import { tradesAPI } from '@api/tradesAPI';
import { transactionsAPI } from '@api/transactionsAPI';
import { visaAPI } from '@api/visaAPI';
import { walletsAPI } from '@api/walletsAPI';

import { getErrorText } from '@utils';
import router from '@utils/router';

import { IS_DEVELOPMENT } from '@configs';

import {
  COOKIE_SKIP_SECURITY_LEVEL_CHECK,
  LOCAL_STORAGE_AUTH_TOKEN,
  LOCAL_STORAGE_MARKET_ID,
} from '@constants';

import wsClient from './api/ws/client';

import { AUTH_ROUTES } from 'src/routes.constants';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    toast.error(getErrorText(action.payload));
  }

  return next(action);
};

const logger = createLogger({
  collapsed: true,
  logger: console,
});

const APIS = [
  stakingAPI,
  coinsAPI,
  platformTokenAPI,
  marketsAPI,
  notificationsAPI,
  profileAPI,
  balancesAPI,
  featuresAPI,
  feesAPI,
  authAPI,
  ordersAPI,
  tradesAPI,
  walletsAPI,
  announcementsAPI,
  botsAPI,
  transactionsAPI,
  bonusAccountAPI,
  managementApiKeyAPI,
  launchpadAPI,
  referralAPI,
  supportAPI,
  accountsAPI,
  candlesAPI,
  visaAPI,
];

const reducers = APIS.reduce((acc, { reducerPath, reducer }) => {
  return {
    ...acc,
    [reducerPath]: reducer,
  };
}, {});

export const middlewares = APIS.map(({ middleware }) => middleware);

export const combinedReducer = combineReducers({
  settings: settingsReducer,
  orders: combineReducers({
    ordersHistory: orderHistoryReducer,
    tradesHistory: tradeHistoryReducer,
    tradeFees: tradeFeesReducer,
  }),
  tradingBots: combineReducers({
    tradingBots: tradingBotsReducer,
    myBots: myBotsReducer,
    archivedBots: archivedBotsReducer,
    liquidatedBots: liquidatedBotsReducer,
  }),
  botStart: botStartReducer,
  instantBonus: combineReducers({
    availableContracts: availableContractsReducer,
    expiredContracts: expiredContractsReducer,
    myContracts: myContractsReducer,
  }),
  stakingAssets: combineReducers({
    availablePlans: availablePlansReducer,
  }),
  launchpad: combineReducers({
    historyLaunchpad: historyLaunchpadReducer,
    watchlistLaunchpad: watchlistLaunchpadReducer,
  }),
  announcements: announcementsReducer,
  announcement: announcementReducer,
  gridBlocks: gridBlocksReducer,
  transactionHistory: transactionHistoryReducer,
  visaCardDetails: visaCardDetailsReducer,
  visaCardSettings: visaCardSettingsReducer,
  walletsWidgets: walletsWidgetsReducer,
  walletsWithdraw: walletWithdrawReducer,
  walletsDeposit: walletDepositReducer,
  kyc: kycReducer,
  kyb: kybReducer,
  overview: combineReducers({
    earnWidgets: overviewEarnWidgetsReducer,
  }),
  allCryptoMarkets: allCryptoMarketsReducer,
  ...reducers,
});

export const rootReducer = (state, action) => {
  // reset store on logout
  if (isAuthorizedUserAction(action) && !action.payload) {
    // stakingAPI.util.resetApiState();
    // coinsAPI.util.resetApiState();
    // platformTokenAPI.util.resetApiState();
    // marketsAPI.util.resetApiState();
    // notificationsAPI.util.resetApiState();
    // profileAPI.util.resetApiState();
    // balancesAPI.util.resetApiState();
    // featuresAPI.util.resetApiState();
    // authAPI.util.resetApiState();

    wsClient.client.setToken('');

    router.navigate(AUTH_ROUTES.LOGIN, { replace: true });

    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_MARKET_ID);

    Cookies.remove(COOKIE_SKIP_SECURITY_LEVEL_CHECK);

    state = undefined;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      // .concat(logger)
      .concat(middlewares),
  devTools: IS_DEVELOPMENT,
});

setupListeners(store.dispatch);

export default store;
