import React, { memo } from 'react';

const TabPanel = ({ id, key, selectedTabIndex, tabIndex, children }) => {
  if (selectedTabIndex === tabIndex) {
    return (
      <div key={`${id}-tab-panel-${key}`} className="tabs__tabpanel">
        {children}
      </div>
    );
  }

  return null;
};

export default memo(TabPanel);
