import { memo, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useForm, useWatch } from 'react-hook-form';

import {
  Card,
  CurrenciesSelect,
  transformToCurrencyOptions,
} from '@shared/components';

import Breakpoint from '@components/Breakpoint';

import { commonStyles } from '@pages/WalletsPage/components';
import {
  withdrawCoinsSelector,
  withdrawCurrencySymbolSelector,
} from '@pages/WalletsPage/Withdraw/Withdraw.selectors';
import { walletWithdrawActions } from '@pages/WalletsPage/Withdraw/Withdraw.slice';

import { WithdrawCoinContent } from './WithdrawCoinContent';

import styles from './WithdrawCoinBlock.module.scss';

const CURRENCY_FIELD_NAME = 'currency';
const WithdrawCoinBlock = () => {
  const dispatch = useDispatch();

  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);
  const coins = useSelector(withdrawCoinsSelector);

  const methods = useForm();

  const selectedCurrencyWatch = useWatch({
    control: methods.control,
    name: CURRENCY_FIELD_NAME,
  });

  const currencies = useMemo(
    () => transformToCurrencyOptions({ currencies: coins }),
    [coins],
  );

  useEffect(() => {
    return () => {
      dispatch(walletWithdrawActions.setWithdrawCurrencySymbol(''));
    };
  }, []);

  useEffect(() => {
    if (selectedCurrencyWatch === undefined) return; // to be able to reset field when value === null after clear

    if (selectedCurrencySymbol === selectedCurrencyWatch?.value) return;

    dispatch(
      walletWithdrawActions.setWithdrawCurrencySymbol(
        selectedCurrencyWatch?.value,
      ),
    );
  }, [selectedCurrencyWatch]);

  return (
    <Card>
      <div className={commonStyles.coin_block_part}>
        <div className="d-flex align-items-center">
          <Breakpoint size="md" direction="up">
            <label className={styles.label}>
              <FormattedMessage id="CoinToWithdraw" />:
            </label>
          </Breakpoint>
          <div className="w-100">
            <CurrenciesSelect
              currencies={currencies}
              name={CURRENCY_FIELD_NAME}
              bordered
              grouped
              isClearable={true}
              {...methods}
            />
          </div>
        </div>
      </div>
      {!!selectedCurrencySymbol && (
        <div className={commonStyles.coin_block_part}>
          <WithdrawCoinContent coins={coins} />
        </div>
      )}
    </Card>
  );
};

export default memo(WithdrawCoinBlock);
