import { useMemo } from 'react';

import { useGetApiKeysQuery } from '@api/managementApiKeyAPI';
import { managementApiKeySelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isApiKeysSuccess: result.isSuccess,
  };

  dataResult.apiKeysList =
    managementApiKeySelectors.apiKeysListSelector(result);

  return dataResult;
};

const useApiKeys = (args, options) => {
  const result = useGetApiKeysQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useApiKeys;
