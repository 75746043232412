import React, { memo } from 'react';

import classnames from 'classnames';

import { NOOP } from '@constants';

import { ReactComponent as HorizontalLineIconSVG } from '@icons/horizontal-line-icon.svg';

const LocalizationItem = ({ item, selectedItem, handleItemClick = NOOP }) => {
  return (
    <div
      key={item.value}
      className={classnames('sidebar-item', {
        active: selectedItem.value === item.value,
      })}
      onClick={(e) => handleItemClick(e, item)}
    >
      <div className="flex-4 sidebar-item-label">{item.label}</div>

      <div className="flex-1 d-flex align-items-center justify-content-center">
        <HorizontalLineIconSVG className="horizontal-line-icon" />
      </div>

      <div className="flex-1 sidebar-item-value">
        {item.symbol ?? item.value}
      </div>
    </div>
  );
};

export default memo(LocalizationItem);
