import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Loader from '@components/Loader';

import { useCoinsInfo, useMarketsInfo } from '@store/api/hooks';

import { useStreamMarketStatisticsQuery } from '@api/marketsAPI';

import HighlightItem from './components/HighlightItem';
import NewListingsItem from './components/NewListingsItem';
import TopGainersItem from './components/TopGainersItem';
import TopVolumeItem from './components/TopVolumeItem';

const MarketsStats = () => {
  const defaultQuoteCoin = 'usdt';
  const { data: marketStatistics } = useStreamMarketStatisticsQuery();

  const { marketsInfo, marketsInfoMap, isMarketsInfoSuccess } =
    useMarketsInfo();

  const { coinsInfoMap, isCoinsInfoSuccess } = useCoinsInfo({
    orderByTypeDirection: 'asc',
  });

  const [filteredMarkets, setFilteredMarkets] = useState({
    highlight: [[], []],
    newListings: [],
    topGainers: [],
    topVolume: [],
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (marketStatistics && isMarketsInfoSuccess && isCoinsInfoSuccess) {
      const mappedMarkets = Object.values(marketStatistics).map(
        (marketStat) => ({
          ...marketStat,
          marketData: marketsInfo?.[marketStat.m],
        }),
      );

      const highlightMarkets = mappedMarkets.filter(
        (marketStat) => marketStat.marketData.highlight,
      );

      const newListingCoins = coinsInfoMap
        .filter((coin) => coin.new_listing)
        .map((coinStat) => ({
          ...coinStat,
          marketStaticData: Object.values(marketStatistics).find(
            (marketStat) =>
              marketStat.m === `${coinStat.symbol}${defaultQuoteCoin}`,
          ),
          marketData: marketsInfoMap.find(
            (marketInfo) => marketInfo.market_coin_symbol === coinStat.symbol,
          ),
        }));

      setFilteredMarkets({
        highlight: [highlightMarkets.slice(0, 3), highlightMarkets.slice(3, 6)],
        newListings: newListingCoins.slice(0, 3),
        topGainers: mappedMarkets
          .filter(
            (market) =>
              Number(market.ch) >= 0 &&
              market.marketData.quote_coin_symbol === 'usdt',
          )
          .sort((a, b) => b.ch - a.ch)
          .slice(0, 3),
        topVolume: mappedMarkets
          .filter((market) => market.marketData.quote_coin_symbol === 'usdt')
          .sort((a, b) => b.qv - a.qv)
          .slice(0, 3),
      });

      if (!isLoaded) {
        setIsLoaded(true);
      }
    }
  }, [marketStatistics, isMarketsInfoSuccess, isCoinsInfoSuccess]);

  return (
    <div className="markets-stats">
      <div className="markets-stats-item">
        <span className="title">
          <FormattedMessage id="Highlight" />
        </span>

        {!isLoaded ? (
          <div className="d-flex align-items-center justify-content-center">
            <Loader xs />
          </div>
        ) : (
          <div className="markets-list">
            <div className="flex-1 pe-8">
              {filteredMarkets.highlight[0].map((market) => (
                <HighlightItem key={market.id} item={market} />
              ))}
            </div>

            <div className="flex-1 pe-8">
              {filteredMarkets.highlight[1].map((market) => (
                <HighlightItem key={market.id} item={market} />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="markets-stats-item">
        <span className="title">
          <FormattedMessage id="NewListings" />
        </span>

        {!isLoaded ? (
          <div className="d-flex align-items-center justify-content-center">
            <Loader xs />
          </div>
        ) : (
          <div className="markets-list flex-column">
            {filteredMarkets.newListings.map((market) => (
              <NewListingsItem key={market.id} item={market} />
            ))}
          </div>
        )}
      </div>

      <div className="markets-stats-item">
        <span className="title">
          <FormattedMessage id="TopGainers" />
        </span>

        {!isLoaded ? (
          <div className="d-flex align-items-center justify-content-center">
            <Loader xs />
          </div>
        ) : (
          <div className="markets-list flex-column">
            {filteredMarkets.topGainers.map((market) => (
              <TopGainersItem key={market.id} item={market} />
            ))}
          </div>
        )}
      </div>

      <div className="markets-stats-item">
        <span className="title">
          <FormattedMessage id="TopVolume" />
        </span>

        {!isLoaded ? (
          <div className="d-flex align-items-center justify-content-center">
            <Loader xs />
          </div>
        ) : (
          <div className="markets-list flex-column">
            {filteredMarkets.topVolume.map((market) => (
              <TopVolumeItem key={market.id} item={market} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(MarketsStats);
