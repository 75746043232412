import { useIntl } from 'react-intl';

import { endOfDay } from 'date-fns';
import dateFormat from 'date-fns/format';
import { toast } from 'react-toastify';

import { YEAR_MONTH_DAY_FORMAT } from '@shared/constants';

import {
  useChangeNotificationsStatusMutation,
  useDeleteNotificationMutation,
  useDeleteNotificationsMutation,
} from '@api/notificationsAPI';

const useNotificationMutations = () => {
  const intl = useIntl();

  const [deleteNotification, { isLoading: isDeleteNotificationLoading }] =
    useDeleteNotificationMutation();

  const [deleteNotifications, { isLoading: isDeleteNotificationsLoading }] =
    useDeleteNotificationsMutation();

  const [
    changeNotificationsStatus,
    { isLoading: isChangeNotificationsStatusLoading },
  ] = useChangeNotificationsStatusMutation();

  const onDeleteNotification = async (notificationId) => {
    await deleteNotification({ notificationId }).unwrap();

    toast.success(
      intl.formatMessage({
        id: 'NotificationDeletedSuccessfully',
      }),
    );
  };

  const onDeleteNotifications = async () => {
    const fromDate = 0;
    const toDate = dateFormat(endOfDay(new Date()), YEAR_MONTH_DAY_FORMAT);

    await deleteNotifications({ from: fromDate, to: toDate }).unwrap();

    toast.success(
      intl.formatMessage({
        id: 'NotificationsDeletedSuccessfully',
      }),
    );
  };

  const onChangeNotificationsStatus = async ({ ids, status }) => {
    await changeNotificationsStatus({
      ids,
      status,
    }).unwrap();
  };

  return {
    onDeleteNotification,
    isDeleteNotificationLoading,
    onDeleteNotifications,
    isDeleteNotificationsLoading,
    onChangeNotificationsStatus,
    isChangeNotificationsStatusLoading,
  };
};

export default useNotificationMutations;
