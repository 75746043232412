import { useSelector } from 'react-redux';

import { useAvailablePlansTable } from '@pages/WalletsPage/Staking/hooks';
import { AvailablePlansSelectors } from '@pages/WalletsPage/Staking/selectors';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

const useSetSidebarPreset = ({ coinSymbol }) => {
  const { periods } = useAvailablePlansTable();

  const selectedPeriods = useSelector(
    AvailablePlansSelectors.selectedPeriodsSelector,
  );

  const selectedGlobalPeriod = useSelector(
    AvailablePlansSelectors.selectedPeriodSelector,
  );

  const selectedPeriod = selectedPeriods[coinSymbol] ?? periods[0];

  const { toggleSidebar } = useSidebar(sidebarIds.WALLETS_CREATE_STAKING_ITEM);

  const toggleStakingSidebar = () => {
    toggleSidebar({
      payCurrency: coinSymbol,
      period: selectedPeriod,
      globalPeriod: selectedGlobalPeriod,
    });
  };

  return { toggleStakingSidebar };
};

export default useSetSidebarPreset;
