import { useEffect, useState, memo } from 'react';

import classNames from 'classnames';
import { Pagination, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { NOOP } from '@constants';

import TableDataCell from '../components/TableDataCell';
import TableHead from '../components/TableHead';

import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/controller';

const TableWithSwipe = ({
  id,
  headers,
  headerSize = 's', // m, l
  rows,
  stripedRows,
  underlinedRows,
  scrollableWidth,
  width,
  paginationRef,
  sortingState,
  isShowColumnsOnEmptyData = true,
  isNoData = true,
  breakpoints = [
    [400, 2],
    [600, 3],
    [800, 4],
    [1000, 5],
  ],
  wideRows = false,
  wideRowsS = false,
  wideRowsL = false,
  wideRowsXL = false,
  inversion,
  isLoading = false,
  onRowClick = NOOP,
  onRowMouseDown = NOOP,
  onRowTouchStart = NOOP,
  onMouseEnterRow = NOOP,
  onMouseLeaveRow = NOOP,
}) => {
  const [headerSwiper, setHeaderSwiper] = useState(null);
  const [bodySwiper, setBodySwiper] = useState(null);
  const [quantitySlidesPerView, setQuantitySlidesPerView] = useState(1);
  // const [lastVisibleIndex, setLastVisibleIndex] = useState(-1);

  useEffect(() => {
    const size = breakpoints.reduce(
      (value, breakpoint) => (width >= breakpoint[0] ? breakpoint[1] : value),
      1,
    );

    setQuantitySlidesPerView(headers.length < size ? headers.length : size);
  }, [width, breakpoints, headers.length]);

  const renderHeader = (index) => {
    const header = headers[index];

    return (
      <table className="w-100">
        <thead>
          <tr>
            <TableHead header={header} sortingState={sortingState} />
          </tr>
        </thead>
      </table>
    );
  };

  const renderRow = (index) => {
    return (
      <table className="w-100">
        <tbody className="fs-13">
          {rows.map((row, i) => {
            const cell = row[index];

            return (
              <tr
                className={classNames({
                  'table-default__row--inversion': inversion,
                  'table-default__row--underlined': underlinedRows,
                })}
                key={`${id}-row-${i}`}
              >
                <TableDataCell
                  index={i}
                  cell={cell}
                  stripedRows={stripedRows}
                  wideRows={wideRows}
                  wideRowsS={wideRowsS}
                  wideRowsL={wideRowsL}
                  wideRowsXL={wideRowsXL}
                  onRowClick={onRowClick}
                  onRowMouseDown={onRowMouseDown}
                  onRowTouchStart={onRowTouchStart}
                  onMouseEnterRow={onMouseEnterRow}
                  onMouseLeaveRow={onMouseLeaveRow}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  // const updateLastVisibleIndex = (swiperElement) => {
  //   const index = swiperElement.slides.reduce(
  //     (lastItemIndex, slideElement, index) => {
  //       if (slideElement.classList.contains('swiper-slide-visible')) {
  //         return index;
  //       }

  //       return lastItemIndex;
  //     },
  //     -1,
  //   );

  //   setLastVisibleIndex(index);
  // };

  return (
    <div style={{ width: scrollableWidth }}>
      <div>
        {(!isNoData || (isNoData && isShowColumnsOnEmptyData)) && (
          <div
            className={classNames(
              `table-default__thead table-default__thead--size-${headerSize}`,
              {
                'table-default__thead--inversion': inversion,
                'table-default__thead--underlined': underlinedRows,
              },
            )}
          >
            <Swiper
              onSwiper={setHeaderSwiper}
              watchSlidesProgress
              slidesPerView={quantitySlidesPerView}
              spaceBetween={0}
              modules={[Controller]}
              controller={{ control: bodySwiper }}
              // onResize={updateLastVisibleIndex}
              // onSlideChangeTransitionEnd={updateLastVisibleIndex}
            >
              {headers.map((header, index) => (
                <SwiperSlide
                  key={`${id}-${header.id}-${index}`}
                  // className={classnames(header.id, {
                  //   'last-visible-column': lastVisibleIndex === index,
                  // })}
                >
                  {renderHeader(index)}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {!isLoading && (
          <Swiper
            onSwiper={setBodySwiper}
            controller={{ control: headerSwiper }}
            // watchSlidesProgress
            slidesPerView={quantitySlidesPerView}
            spaceBetween={0}
            pagination={{
              el: paginationRef.current,
              clickable: true,
            }}
            modules={[Pagination, Controller]}
          >
            {headers.map((header, index) => (
              <SwiperSlide
                key={`${id}-${header.id}-${index}`}
                // className={classnames(header.id, {
                //   'last-visible-column': lastVisibleIndex === index,
                // })}
              >
                {renderRow(index)}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default memo(TableWithSwipe);
