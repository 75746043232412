import { createSlice } from '@reduxjs/toolkit';

import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

const initialState = {
  [TABLE_FILTERS_NAMES.SEARCHBAR]: '',
  selectedPeriod: null,
  selectedPeriods: {},
};

const availablePlansSlice = createSlice({
  name: 'availablePlans',
  initialState,
  reducers: {
    setSelectedPeriods: (state, action) => {
      state.selectedPeriods[action.payload.coinSymbol] = action.payload;
    },
    resetSelectedPeriods: (state, action) => {
      state.selectedPeriods = initialState.selectedPeriods;
    },
    setSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload;
    },
    resetSelectedPeriod: (state, action) => {
      state.selectedPeriod = initialState.selectedPeriod;
    },
    setSearch: (state, action) => {
      state[TABLE_FILTERS_NAMES.SEARCHBAR] = action.payload;
    },
    resetSearch: (state, action) => {
      state.search = initialState.search;
    },
  },
});

const availablePlansActions = availablePlansSlice.actions;
const availablePlansReducer = availablePlansSlice.reducer;

export { availablePlansActions, availablePlansReducer };

export default availablePlansSlice;
