import { useIntl } from 'react-intl';

import { useWatch } from 'react-hook-form';

import {
  CountriesCodeSelect,
  COUNTRY_CODE_DEFAULT,
  COUNTRY_CODE_NAME,
} from '@shared/components';
import { InputController } from '@shared/ui';
import { validatePhoneNumber } from '@shared/validations';

import s from './PhoneInputSelectController.module.scss';

const PhoneInputSelectController = ({
  control,
  name,
  codeLabel,
  phoneLabel,
  onCloseSidebar,
  ...props
}) => {
  const intl = useIntl();

  const watchCountryCode = useWatch({ control, name: COUNTRY_CODE_NAME });

  return (
    <div className={s.phone}>
      <div className={s.phone__country_code_select}>
        <CountriesCodeSelect
          control={control}
          styles={{ control: { fontSize: '16px' } }}
          label={codeLabel}
          onCloseSidebar={onCloseSidebar}
          {...props}
        />
      </div>

      <div className="w-100">
        <InputController
          control={control}
          name={name}
          isPhoneInput
          country={watchCountryCode?.code || COUNTRY_CODE_DEFAULT}
          rules={{
            required: intl.formatMessage({ id: 'FieldRequired' }),
            validate: validatePhoneNumber(intl),
          }}
          customStyles={s}
          label={phoneLabel}
          {...props}
        />
      </div>
    </div>
  );
};

export default PhoneInputSelectController;
