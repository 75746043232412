import { useMemo } from 'react';

import cx from 'classnames';

import { Button } from '@shared/ui';

import { ReactComponent as ArrowLeftShortSVG } from '@icons/arrow-left-side-icon.svg';
import { ReactComponent as ArrowRightSVG } from '@icons/arrow-right-icon.svg';
import { ReactComponent as ArrowRightWithoutStickSVG } from '@icons/arrow-right-without-stick-icon.svg';

import s from './ButtonArrow.module.scss';

export const ARROW_DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left',
};

export const ARROW_TYPE = {
  LONG: 'long',
  SHORT: 'short',
  WITHOUT_STICK: 'without_stick',
};

const ButtonArrow = ({
  children,
  isShowArrowIcon = true,
  direction = ARROW_DIRECTION.RIGHT,
  arrowType = ARROW_TYPE.LONG,
  arrowProps,
  customStyles = {},
  ...props
}) => {
  const isDefaultDirection = direction === ARROW_DIRECTION.RIGHT;

  const arrowIcon = useMemo(() => {
    if (arrowType === ARROW_TYPE.SHORT) {
      return (
        <div className={s.short_arrow}>
          <ArrowLeftShortSVG {...arrowProps} />
        </div>
      );
    }

    if (arrowType === ARROW_TYPE.WITHOUT_STICK) {
      return <ArrowRightWithoutStickSVG {...arrowProps} />;
    }

    return <ArrowRightSVG width={50} height={16} {...arrowProps} />;
  }, [arrowType, arrowProps]);

  return (
    <Button {...props} customStyles={customStyles}>
      <div
        className={cx(
          s.button_arrow,
          s[props.color],
          s[props.variant],
          customStyles.button_arrow,
          {
            [s.inversion]: props.inversion,
            'flex-row-reverse': !isDefaultDirection,
          },
        )}
      >
        {children}

        {isShowArrowIcon && (
          <div className={cx(s.arrow, s[direction], customStyles.arrow)}>
            {arrowIcon}
          </div>
        )}
      </div>
    </Button>
  );
};

export default ButtonArrow;
