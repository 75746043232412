import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import LoginOrRegister from '@components/LoginOrRegister';

import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import styles from './NoData.module.scss';

const NoData = ({
  showIsGuest = false,
  guestLabel,
  inversion = false,
  className,
  style,
  label,
}) => {
  const isGuest = useSelector(isGuestUserSelector);

  return (
    <div
      style={style}
      className={classnames(styles.no_data, className, {
        [styles['no_data--inversion']]: inversion,
      })}
    >
      {showIsGuest && isGuest ? (
        <>
          <LoginOrRegister />
          {guestLabel}
        </>
      ) : (
        <span>{label ?? <FormattedMessage id="NoRecords" />}</span>
      )}
    </div>
  );
};

export default memo(NoData);
