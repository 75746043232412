import React, { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import styles from './Placeholder.module.scss';

const Placeholder = ({ children, selectProps, ...props }) => {
  const isInversion = selectProps.inversion;
  const size = selectProps.size;

  return (
    <components.Placeholder
      className={cx(styles.placeholder, styles[size], {
        [styles.inversion]: isInversion,
      })}
      {...props}
    >
      {children}
    </components.Placeholder>
  );
};

export default memo(Placeholder);
