import React, { memo } from 'react';

import cx from 'classnames';

import styles from './LandingHeading.module.scss';

const LandingHeading = ({
  children,
  size = 'l', // xl
  inversion,
  customStyles = {},
}) => {
  return (
    <div
      className={cx(styles.heading, styles[size], customStyles.heading, {
        [styles.inversion]: inversion,
      })}
    >
      {children}
    </div>
  );
};

export default memo(LandingHeading);
