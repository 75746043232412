import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';

import { COLUMN_ALIGN } from '@shared/components/Table/table.constants';

const { accessor } = createColumnHelper();

export const visaCardTransactionsColumns = ({
  mainCurrencySymbol,
  isLaptopDown,
  isMobile,
}) => {
  let excludedColumns = [];
  let columns = [
    accessor('date', {
      header: () => <FormattedMessage id="Date" />,
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
      enableSorting: false,
      maxSize: 45,
    }),
    accessor('description', {
      header: () => <FormattedMessage id="Description" />,
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
      enableSorting: false,
    }),
    accessor('type', {
      header: () => <FormattedMessage id="Type" />,
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
      enableSorting: false,
    }),
    accessor('amount', {
      header: () => (
        <span>
          <FormattedMessage id="Amount" /> ({mainCurrencySymbol})
        </span>
      ),
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
      enableSorting: false,
      align: COLUMN_ALIGN.RIGHT,
      maxSize: 120,
    }),
    accessor('cashback', {
      header: () => (
        <span>
          <FormattedMessage id="Cashback" /> ({mainCurrencySymbol})
        </span>
      ),
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
      enableSorting: false,
      align: COLUMN_ALIGN.RIGHT,
      maxSize: 120,
    }),
    accessor('balance', {
      header: () => (
        <span>
          <FormattedMessage id="Balance" /> ({mainCurrencySymbol})
        </span>
      ),
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
      enableSorting: false,
      align: COLUMN_ALIGN.RIGHT,
      maxSize: 120,
    }),
  ];

  if (isLaptopDown) {
    excludedColumns = ['type'];
  }

  if (isMobile) {
    excludedColumns = ['type', 'cashback', 'balance'];
  }

  return columns.filter(
    (column) => !excludedColumns.includes(column.accessorKey),
  );
};
