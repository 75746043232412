import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../utils';

const defaultData = {};
const defaultTransactions = [];
const defaultMeta = {};

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const metaSelector = ApiSelectorsUtils.createMetaSelector(defaultMeta);

const transactionsSelector = createSelector(dataSelector, (data) => {
  return data.transactions ?? defaultTransactions;
});

export const TransactionsApiSelectors = {
  dataSelector,
  metaSelector,
  transactionsSelector,
};
