import { memo } from 'react';

import { AccountPagesTabs } from '@shared/components';

import { referralTablesTabs } from './ReferralsTablesTabs';

const ReferralsTables = () => {
  return (
    <div className="mt-20">
      <AccountPagesTabs items={referralTablesTabs} />
    </div>
  );
};

export default memo(ReferralsTables);
