import React, { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import styles from './Menu.module.scss';

const Menu = ({ children, selectProps, customStyles = {}, ...props }) => {
  const isInversion = selectProps.inversion;

  // class menu is needed for animate menu closing.
  return (
    <components.Menu
      className={cx(styles.custom_menu, 'menu', customStyles.custom_menu, {
        [styles.inversion]: isInversion,
      })}
      {...props}
    >
      {children}
    </components.Menu>
  );
};

export default memo(Menu);
