import React, { memo } from 'react';

import { ReactComponent as InfoIconSVG } from '@icons/info-icon.svg';

import styles from './SideBarInfoPoint.module.scss';

const SideBarInfoPoint = ({ title, description }) => {
  return (
    <div className={styles.container}>
      <InfoIconSVG className={styles.icon} />
      <div>
        <span className={styles.title}>{title}</span>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default memo(SideBarInfoPoint);
