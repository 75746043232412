import { FormattedMessage, useIntl } from 'react-intl';

import { AccountModal } from '@shared/components';
import { Button } from '@shared/ui';

import useModal from '@hooks/useModal';

import { modalsIds } from '@constants';

import { ReactComponent as InfoIconSVG } from '@icons/info-icon.svg';
import { ReactComponent as LockCardIconSVG } from '@icons/lock-icon.svg';

import s from './CloseCardModal.module.scss';

const CloseCardModal = () => {
  const intl = useIntl();
  const { isOpen, closeModal, resetModal } = useModal(modalsIds.CLOSE_CARD);

  const handleLockCard = () => {
    // TODO: add real endpoint
    console.log('handleLockCard');
  };

  const handleCloseCard = () => {
    // TODO: add real endpoint
    console.log('handleCloseCard');
  };

  return (
    <AccountModal
      title={`${intl.formatMessage({ id: 'CloseCard' })}`}
      isOpen={isOpen}
      onClose={closeModal}
      afterClose={resetModal}
      footer={
        <div>
          <div className="d-flex align-items-center justify-content-between gap-18 flex-lg-row flex-column">
            <Button fill color="secondary" onClick={handleLockCard} size="l">
              <div className="d-flex align-items-center gap-16">
                <LockCardIconSVG className={s.lock_icon} />
                <FormattedMessage id="LockCard" />
              </div>
            </Button>
            <Button
              fill
              variant="outlined"
              color="dangerous"
              onClick={handleCloseCard}
              size="l"
            >
              <FormattedMessage id="CloseCard" />
            </Button>
          </div>
          <div className="w-100 d-flex justify-content-center mt-10">
            <Button size="l" variant="text" onClick={() => closeModal()}>
              <FormattedMessage id="Cancel" />
            </Button>
          </div>
        </div>
      }
    >
      <div className={s.content}>
        <InfoIconSVG className={s.icon} />
        <div>
          <span className={s.text}>
            <FormattedMessage id="CloseCardDescription" />
          </span>
        </div>
      </div>
    </AccountModal>
  );
};

export default CloseCardModal;
