import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, generatePath, useMatch } from 'react-router-dom';

import classNames from 'classnames';

import { TogglePersonalDataButton } from '@shared/components';
import { Button, ButtonArrow } from '@shared/ui';

import { AccountPagesLayout } from '@components/layouts/AccountPagesLayout';

import { AddNewAccountButton } from '@pages/WalletsPage/components/AddNewAccountButton';

import { useSpotAccounts } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { SpotAccountSideBar } from './components';

import {
  MAIN_ROUTES,
  SERVICE_ROUTES,
  WALLETS_ROUTES,
} from '../../routes.constants';

import styles from './WalletsPage.module.scss';
import mockData from 'src/mock.json';

const StartNewBotButton = ({ isTradingBotsRoute }) => {
  const { isMobile } = useBreakpoints();

  const isMinimized = isMobile && !isTradingBotsRoute;

  const isMinimizedTradingBots = isMobile && isTradingBotsRoute;

  return (
    <div
      className={classNames('d-flex justify-content-end', {
        [styles.start_new_button]: isMinimized,
        [styles.trading_bots_button]: isTradingBotsRoute,
      })}
    >
      <Button
        isCircle={isMinimized}
        navigation={{ to: WALLETS_ROUTES.TRADING_BOTS_START }}
        color={isTradingBotsRoute ? 'primary' : 'secondary'}
      >
        {isMinimized ? (
          '+'
        ) : (
          <FormattedMessage
            id={isMinimizedTradingBots ? 'StartNew' : 'StartNewBot'}
          />
        )}
      </Button>
    </div>
  );
};

const WalletsPage = () => {
  const { marginAccountsQuantity } = mockData.WalletsPage;

  const { spotAccountsLength } = useSpotAccounts();

  const isSingleSpotAccountPage = useMatch(
    WALLETS_ROUTES.SPOT_ACCOUNTS_DETAILS,
  );
  const isSpotAccountsPage = useMatch(WALLETS_ROUTES.SPOT_ACCOUNTS);
  const isWalletOverviewPage = useMatch(WALLETS_ROUTES.OVERVIEW);
  const isInstantBonusPage = useMatch(WALLETS_ROUTES.INSTANT_BONUS);
  const isTradingBotsPage = useMatch(WALLETS_ROUTES.TRADING_BOTS);
  const isTradingBotsDetailsPage = useMatch(
    WALLETS_ROUTES.TRADING_BOTS_DETAILS,
  );
  const isStartBotPage = useMatch(WALLETS_ROUTES.TRADING_BOTS_START);
  const isWithdrawPage = useMatch(WALLETS_ROUTES.WITHDRAW);
  const isDepositPage = useMatch(WALLETS_ROUTES.DEPOSIT);
  const isStakingPage = useMatch(WALLETS_ROUTES.STAKING);
  const isStakingHistoryPage = useMatch(WALLETS_ROUTES.STAKING_ASSETS_HISTORY);
  const isLaunchpadPage = useMatch(WALLETS_ROUTES.LAUNCHPAD);
  const isMainAccountPage = useMatch(WALLETS_ROUTES.MAIN_ACCOUNT);
  const isVisaCardSettingsPage = useMatch(WALLETS_ROUTES.VISA_CARD_SETTINGS);

  const { isTabletDown, isMobile } = useBreakpoints();

  const isShowFeesLink = isWithdrawPage || isDepositPage;
  const isShowHeader = !isSingleSpotAccountPage && !isStakingHistoryPage;
  const isShowBackArrow =
    !!isTradingBotsDetailsPage || !!isStartBotPage || !!isVisaCardSettingsPage;

  const routes = [
    {
      route: WALLETS_ROUTES.OVERVIEW,
      titleId: 'Overview',
    },
    {
      route: WALLETS_ROUTES.MAIN_ACCOUNT,
      titleId: 'MainAccount',
    },
    {
      route: WALLETS_ROUTES.SPOT_ACCOUNTS,
      titleId: 'SpotAccounts',
      counter: spotAccountsLength,
    },
    {
      route: WALLETS_ROUTES.MARGIN_ACCOUNTS,
      titleId: 'MarginAccounts',
      counter: marginAccountsQuantity,
    },
    {
      route: WALLETS_ROUTES.VISA_CARD,
      titleId: 'VisaCard',
    },
    null,
    {
      route: WALLETS_ROUTES.INSTANT_BONUS,
      titleId: 'InstantBonus',
    },
    {
      route: WALLETS_ROUTES.TRADING_BOTS,
      titleId: 'TradingBots',
    },
    {
      route: WALLETS_ROUTES.LAUNCHPAD,
      titleId: 'Launchpad',
    },
    {
      route: WALLETS_ROUTES.STAKING,
      titleId: 'Staking',
    },
    null,
    {
      route: WALLETS_ROUTES.DEPOSIT,
      titleId: 'Deposit',
    },
    {
      route: WALLETS_ROUTES.WITHDRAW,
      titleId: 'Withdraw',
    },
    {
      route: generatePath(WALLETS_ROUTES.TRANSACTION_HISTORY),
      titleId: 'TransactionHistory',
    },
  ];

  return (
    <>
      <AccountPagesLayout
        sidebarRoutes={routes}
        isShowHeader={isShowHeader}
        isShowBackArrow={isShowBackArrow}
        headerControl={
          <>
            {isWalletOverviewPage && (
              <div className="d-flex align-items-center justify-content-between">
                <TogglePersonalDataButton />

                <Button navigation={{ to: WALLETS_ROUTES.DEPOSIT }}>
                  <FormattedMessage id="Deposit" />
                </Button>
              </div>
            )}

            {isStakingPage && (
              <div className="d-flex align-items-center justify-content-end justify-content-xl-between">
                <div className={isTabletDown ? 'me-26' : undefined}>
                  <TogglePersonalDataButton />
                </div>

                <Button navigation={{ to: WALLETS_ROUTES.DEPOSIT }}>
                  <FormattedMessage id="Deposit" />
                </Button>
              </div>
            )}

            {(isInstantBonusPage || isStartBotPage) && (
              <div className="d-flex align-items-center justify-content-end">
                <Button navigation={{ to: WALLETS_ROUTES.DEPOSIT }}>
                  <FormattedMessage id="Deposit" />
                </Button>
              </div>
            )}

            {isLaunchpadPage && (
              <div className="d-flex align-items-center justify-content-end">
                <Button navigation={{ to: generatePath(MAIN_ROUTES.TERMINAL) }}>
                  <FormattedMessage
                    id={isMobile ? 'Platform' : 'GoToPlatform'}
                  />
                </Button>
              </div>
            )}

            {isShowFeesLink && (
              <div className="d-flex align-items-center justify-content-end">
                <ButtonArrow
                  navigation={{ to: SERVICE_ROUTES.FEES }}
                  variant="text"
                  color="primary"
                >
                  <FormattedMessage id="Fees" />
                </ButtonArrow>
              </div>
            )}

            {(isTradingBotsPage || isTradingBotsDetailsPage) &&
              !isStartBotPage && (
                <div className="d-flex align-items-center justify-content-end">
                  <StartNewBotButton isTradingBotsRoute={isTradingBotsPage} />
                </div>
              )}

            {(isMainAccountPage || isSpotAccountsPage) && (
              <div className="d-flex align-items-center gap-14 gap-xl-30 justify-content-end justify-content-xl-between">
                <TogglePersonalDataButton />

                <AddNewAccountButton />
              </div>
            )}
          </>
        }
      >
        <Outlet />
      </AccountPagesLayout>

      <SpotAccountSideBar />
    </>
  );
};

export default memo(WalletsPage);
