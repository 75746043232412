import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import cx from 'classnames';

import LoginOrRegister from '@components/LoginOrRegister/LoginOrRegister';

import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import s from './TableNoData.module.scss';

const TableNoData = ({
  table,
  showIsGuest = false,
  guestLabel,
  noDataLabel,
  inversion,
}) => {
  const columnsQuantity = table.getAllColumns().length;

  const isGuest = useSelector(isGuestUserSelector);

  return (
    <tbody
      className={cx(s.table_no_data, {
        [s.inversion]: inversion,
      })}
    >
      <tr>
        <td colSpan={columnsQuantity}>
          <>
            {showIsGuest && isGuest ? (
              <>
                <LoginOrRegister />
                {guestLabel}
              </>
            ) : (
              <>{noDataLabel ?? <FormattedMessage id="NoRecords" />}</>
            )}
          </>
        </td>
      </tr>
    </tbody>
  );
};

export default TableNoData;
