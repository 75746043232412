import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormProvider } from 'react-hook-form';

import { FormActions, FormattedCurrency } from '@shared/components';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './ConfirmUnstakeForm.module.scss';

const ConfirmUnstakeForm = ({
  methods,
  handleSubmit,
  currency,
  onSubmit,
  watchAmount,
  amount,
  cancelUnstakingItem,
}) => {
  const { isTabletDown } = useBreakpoints();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div>
          <div className={styles.subtitle}>
            <FormattedMessage id="StakeSidebarInfo" />
          </div>

          <hr />

          <div className={styles.text}>
            <FormattedMessage id="Amount" />:
            <div className={styles.balance}>
              <span className="text-uppercase">
                <FormattedCurrency currency={currency} value={watchAmount} />{' '}
                {currency}
              </span>
            </div>
          </div>

          <div className={styles.text}>
            <FormattedMessage id="YouWillReceive" />:
            <div className={styles.balance}>
              <span className="text-uppercase">
                <FormattedCurrency currency={currency} value={watchAmount} />{' '}
                {currency}
              </span>
            </div>
          </div>

          <div className={styles.text}>
            <FormattedMessage id="UnstakePeriod" />:
            <div className={styles.balance}>
              <span>
                5 - 10{' '}
                <span className="text-lowercase">
                  <FormattedMessage id="Days" />
                </span>
              </span>
            </div>
          </div>

          <div className={styles.text}>
            <FormattedMessage id="Fee" />:
            <div className={styles.balance}>
              <span className="text-uppercase">
                <FormattedCurrency currency={currency} value={amount} />{' '}
                {currency}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <FormActions
            submitText={<FormattedMessage id="StakeConfirm" />}
            submitButtonProps={{
              size: isTabletDown ? 'm' : 'l',
              fill: true,
              color: 'dangerous',
              inversion: true,
            }}
            cancelButtonProps={{
              size: isTabletDown ? 'm' : 'l',
              fill: true,
              inversion: true,
            }}
            onCancel={cancelUnstakingItem}
            customStyles={styles}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default memo(ConfirmUnstakeForm);
