import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Link from '@components/Link';

import { isAccountThemeSelector } from '@store/settings/settingsSelectors';

import { NOOP } from '@constants';

import { ReactComponent as RightChevronIconSVG } from '@icons/right-chevron-icon.svg';

import { MAIN_ROUTES } from 'src/routes.constants';

import styles from './ProductsMenuAllMarkets.module.scss';

const ProductsMenuAllMarkets = ({ onCloseSideBar = NOOP }) => {
  const isAccountTheme = useSelector(isAccountThemeSelector);

  return (
    <Link
      className={styles.link}
      to={MAIN_ROUTES.MARKETS}
      inversion={isAccountTheme}
    >
      <div className="d-flex align-items-center">
        <span onClick={onCloseSideBar}>
          <FormattedMessage id="AllMarkets" />
        </span>

        <div className={styles.icon}>
          <RightChevronIconSVG width={5.5} height={9.5} />
        </div>
      </div>
    </Link>
  );
};

export default memo(ProductsMenuAllMarkets);
