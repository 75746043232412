import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import { CoinNetworks } from '@pages/WalletsPage/components';
import { withdrawCurrencySymbolSelector } from '@pages/WalletsPage/Withdraw/Withdraw.selectors';
import {
  AmountInput,
  WithdrawAddressesSelect,
} from '@pages/WalletsPage/Withdraw/WithdrawCoinBlock';

import { AdditionalCoinFields } from './AdditionalCoinFields';

const WithdrawCoinCrypto = () => {
  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);

  const isUSDTCoin = selectedCurrencySymbol === CRYPTO_CURRENCIES.USDT;

  return (
    <>
      {isUSDTCoin && (
        <div className="row">
          <div className="col-12">
            <CoinNetworks />
          </div>
        </div>
      )}
      <div className="row mt-24">
        <div className="col-12">
          <WithdrawAddressesSelect />
        </div>
      </div>

      <AdditionalCoinFields />

      <div className="row mt-24">
        <div className="col-md-7 col-12">
          <AmountInput selectedCurrencySymbol={selectedCurrencySymbol} />
        </div>
      </div>
    </>
  );
};

export default memo(WithdrawCoinCrypto);
