import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';
import classnames from 'classnames';

import { chartTableDateFormat } from '@utils/chartiq';
import { format, isEven } from '@utils/numbers';

import { DEFAULT_PRECISION } from '@constants';

const columnHelper = createColumnHelper();

const chartTableViewColumns = (
  showAdditionalColumns,
  additionalColumns,
  marketPrecision = DEFAULT_PRECISION,
) => {
  const allColumns = [
    columnHelper.accessor('DT', {
      header: () => <FormattedMessage id="DT" />,
      cell: (info) => (
        <span className="fs-12">{chartTableDateFormat(info.getValue())}</span>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('Open', {
      header: () => <FormattedMessage id="Open" />,
      cell: (info) => (
        <span>
          {typeof info.getValue() === 'number'
            ? format(info.getValue(), { precision: marketPrecision })
            : info.getValue()}
        </span>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('High', {
      header: () => <FormattedMessage id="High" />,
      cell: (info) => (
        <span>
          {typeof info.getValue() === 'number'
            ? format(info.getValue(), { precision: marketPrecision })
            : info.getValue()}
        </span>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('Low', {
      header: () => <FormattedMessage id="Low" />,
      cell: (info) => (
        <span className="fw-500">
          {typeof info.getValue() === 'number'
            ? format(info.getValue(), { precision: marketPrecision })
            : info.getValue()}
        </span>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('Close', {
      header: () => <FormattedMessage id="Close" />,
      cell: (info) => (
        <span className="color-white-opacity-06">
          {typeof info.getValue() === 'number'
            ? format(info.getValue(), { precision: marketPrecision })
            : info.getValue()}
        </span>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('Volume', {
      header: () => <FormattedMessage id="Volume" />,
      cell: (info) => (
        <span className="fw-700">
          {typeof info.getValue() === 'number'
            ? format(info.getValue(), { precision: marketPrecision })
            : info.getValue()}
        </span>
      ),
      enableSorting: false,
    }),
  ];

  if (showAdditionalColumns) {
    additionalColumns.forEach((col, index) => {
      const column = columnHelper.accessor(col, {
        header: () => col,
        cell: (info) => (
          <span
            className={classnames('fw-500', {
              'color-white-opacity-06': isEven(index),
            })}
          >
            {info.getValue() ?? '-'}
          </span>
        ),
        enableSorting: false,
      });

      allColumns.push(column);
    });
  }

  return allColumns;
};

export default chartTableViewColumns;
