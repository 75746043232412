import { createSelector } from '@reduxjs/toolkit';

import { WIDGETS } from './widgets.constants';

const walletsWidgetsSelector = (state) => {
  return state.walletsWidgets;
};

export const widgetsSelector = createSelector(
  walletsWidgetsSelector,
  (walletsWidgets) => {
    return walletsWidgets.widgets;
  },
);

export const activeWidgetsSelector = createSelector(
  widgetsSelector,
  (widgets) => {
    return widgets
      .filter((widget) => widget.isActive)
      .map((widget) =>
        WIDGETS.find(({ widgetId }) => widget.widgetId === widgetId),
      );
  },
);
