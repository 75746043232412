import { memo, useState, useId } from 'react';

import classnames from 'classnames';

import { FieldController } from '@shared/components';

import styles from './Switch.module.scss';

const Switch = ({
  checked,
  onChange,
  inversionColors = false,
  customStyles = {},
  controllerRef,
  isError,
  errorMessage,
  ...rest
}) => {
  const id = useId();
  const [virtualChecked, setVirtualChecked] = useState(false);

  const isChecked = checked || virtualChecked;

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    } else {
      setVirtualChecked((prevState) => !prevState);
    }
  };

  return (
    <>
      <input
        id={id}
        type="checkbox"
        checked={isChecked}
        className={styles.switch_checkbox}
        onChange={handleOnChange}
        {...rest}
      />
      <label
        htmlFor={id}
        className={classnames(
          'switch-label',
          styles.switch_label,
          {
            [classnames('checked', styles.checked)]: isChecked,
            [styles.inversion]: inversionColors,
            [customStyles.checked]: isChecked,
            [styles.disabled]: rest.disabled,
          },
          customStyles.switch_label,
        )}
      >
        <span
          className={classnames(
            styles.switch_button,
            customStyles.switch_button,
          )}
        />
      </label>
    </>
  );
};

export const SwitchController = memo((props) => {
  return (
    <FieldController {...props} component={Switch} valueAccessor="checked" />
  );
});

export default memo(Switch);
