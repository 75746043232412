import { ACCOUNT_SECURITY_SETTINGS } from '@shared/constants/account.constants';

import {
  activeSecuritySettingsSelector,
  isTwoAuthExistsSelector,
  securityLevelPercentSelector,
  securitySettingsSelector,
  userPhoneSelector,
} from '@pages/AccountPage/account.selectors';

import {
  isUserHasActiveSettingSelector,
  isUserHasMaxLevelSelector,
  useGetProfileInfoQuery,
  useLazyGetProfileInfoQuery,
  userAvatarBase64Selector,
  userAvatarSelector,
  userFirstNameSelector,
  userLastLoginSelector,
  userLevelSelector,
  userNicknameSelector,
  userReferralCodeSelector,
} from '@store/api/profileAPI';

import { ProfileApiSelectors } from '@api/selectors';

const selectFromResult = (result) => ({
  ...result,
  securitySettings: securitySettingsSelector(result),
  activeSecuritySettings: activeSecuritySettingsSelector(result),
  securityLevelPercent: securityLevelPercentSelector(result),
  userKycLevel: userLevelSelector(result),
  userAvatar: userAvatarSelector(result),
  userAvatarSrc: userAvatarBase64Selector(result),
  userFirstName: userFirstNameSelector(result),
  userNickname: userNicknameSelector(result),
  userReferralCode: userReferralCodeSelector(result),
  userLastLogin: userLastLoginSelector(result),
  isTwoAuthExists: isTwoAuthExistsSelector(result),
  depositWithdrawBlocking:
    ProfileApiSelectors.depositWithdrawBlockingSelector(result),
  isGoogleAuthActive: isUserHasActiveSettingSelector(
    result,
    ACCOUNT_SECURITY_SETTINGS.isGoogleTwoFaExists,
  ),
  isSMSAuthActive: isUserHasActiveSettingSelector(
    result,
    ACCOUNT_SECURITY_SETTINGS.isSmsTwoFaExists,
  ),
  userPhone: userPhoneSelector(result),
  isUserHasMaxLevel: isUserHasMaxLevelSelector(result),
});

export const useGetProfileInfoQueryResult = (args, options) => {
  const result = useGetProfileInfoQuery(args, {
    ...options,
    selectFromResult,
  });

  return result;
};

export const useLazyGetProfileInfoQueryResult = () => {
  const result = useLazyGetProfileInfoQuery({
    selectFromResult,
  });

  return result;
};
