import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shared/ui';

import { useSetSidebarPreset } from '@pages/WalletsPage/Staking/hooks';

import styles from './StakeItem.module.scss';

const StakeItem = ({ coinSymbol }) => {
  const { toggleStakingSidebar } = useSetSidebarPreset({ coinSymbol });

  return (
    <div className={styles.container}>
      <Button
        type="button"
        size="s"
        onClick={toggleStakingSidebar}
        customStyles={styles}
      >
        <FormattedMessage id="Stake" />
      </Button>
    </div>
  );
};

export default memo(StakeItem);
