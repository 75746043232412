import { FormattedMessage } from 'react-intl';

import { AccountModal } from '@shared/components';
import { Button } from '@shared/ui';

import useModal from '@hooks/useModal';

import { modalsIds } from '@constants';

import s from './SuccessCustomRangeModal.module.scss';

const SuccessCustomRangeModal = () => {
  const { modal, isOpen, closeModal } = useModal(
    modalsIds.SUCCESS_SELECT_CUSTOM_RANGE,
  );

  const { fromDate, toDate } = modal.params;

  return (
    <AccountModal
      title={<FormattedMessage id="Success" />}
      onClose={closeModal}
      isOpen={isOpen}
    >
      <div>
        <div className="fw-500">
          <FormattedMessage
            id="CustomRangeHistoryRequestSuccess"
            values={{ fromDate, toDate }}
          />
        </div>
        <div className="mt-30 d-flex justify-content-center">
          <Button
            customStyles={s}
            onClick={closeModal}
            color="secondary"
            size="l"
          >
            <FormattedMessage id="Close" />
          </Button>
        </div>
      </div>
    </AccountModal>
  );
};

export default SuccessCustomRangeModal;
