import { FormattedMessage } from 'react-intl';

import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';

import {
  OrdersAmount,
  OrdersFee,
  OrdersPair,
  OrdersPrice,
  OrdersSide,
  OrdersStatus,
  OrdersTotalAmount,
  OrdersType,
  OrdersId,
  OrdersDate,
  OrdersFilledPercent,
} from '@shared/components';
import { COLUMN_ALIGN } from '@shared/components/Table/table.constants';
import { Button } from '@shared/ui';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import styles from './OrderHistoryTableColumns.module.scss';

const { accessor } = createColumnHelper();

const orderHistoryTableColumns = ({
  openTradesHistoryDetailsModal,
  isTabletDown,
}) => [
  accessor('side', {
    header: () => <FormattedMessage id="Side" />,
    cell: (info) => {
      const { side, isHideSide } = info.getValue();

      return <OrdersSide side={side} isHideSide={isHideSide} />;
    },
    enableSorting: false,
    maxSize: isTabletDown ? 30 : 60,
  }),
  accessor('pair', {
    header: () => <FormattedMessage id="Pair" />,
    cell: (info) => {
      const pair = info.getValue();

      return <OrdersPair pair={pair} />;
    },
    enableSorting: false,
    maxSize: 100,
  }),
  accessor('id', {
    header: () => <FormattedMessage id="Id" />,
    cell: (info) => {
      const id = info.getValue();

      return <OrdersId id={id} />;
    },
    enableSorting: false,
  }),
  accessor('type', {
    header: () => <FormattedMessage id="OrderType" />,
    cell: (info) => {
      const {
        type,
        price,
        activationPrice,
        callbackRate,
        callbackType,
        isHideType,
        isSuborder,
      } = info.getValue();

      return (
        <OrdersType
          type={type}
          price={price}
          activationPrice={activationPrice}
          callbackRate={callbackRate}
          callbackType={callbackType}
          isHideType={isHideType}
          isSuborder={isSuborder}
        />
      );
    },
    enableSorting: false,
  }),
  accessor('amount_info', {
    header: () => <FormattedMessage id="Amount" />,
    cell: (info) => {
      const { amount, coinSymbol } = info.getValue();

      return <OrdersAmount amount={amount} coinSymbol={coinSymbol} />;
    },
    enableSorting: false,
    align: COLUMN_ALIGN.RIGHT,
  }),
  accessor('price_info', {
    header: () => <FormattedMessage id="Price" />,
    cell: (info) => {
      const { price, coinSymbol } = info.getValue();

      return <OrdersPrice price={price} coinSymbol={coinSymbol} />;
    },
    enableSorting: false,
    align: COLUMN_ALIGN.RIGHT,
  }),
  accessor('filled_percent', {
    header: () => <FormattedMessage id="Filled" />,
    cell: (info) => {
      const filledPercent = info.getValue();

      return (
        <OrdersFilledPercent percent={filledPercent} isShowProgress={false} />
      );
    },
    enableSorting: false,
    align: COLUMN_ALIGN.RIGHT,
  }),
  accessor('total_amount', {
    header: () => <FormattedMessage id="Total" />,
    cell: (info) => {
      const { total, coinSymbol } = info.getValue();

      return <OrdersTotalAmount coinSymbol={coinSymbol} total={total} />;
    },
    enableSorting: false,
    align: COLUMN_ALIGN.RIGHT,
  }),

  accessor('created_at', {
    header: () => <FormattedMessage id="Date" />,
    cell: (info) => {
      const { createdAt } = info.getValue();

      return <OrdersDate date={createdAt} />;
    },
    enableSorting: false,
    align: COLUMN_ALIGN.RIGHT,
  }),
  accessor('status', {
    header: () => <FormattedMessage id="Status" />,
    cell: (info) => {
      const { status } = info.getValue();

      const statusItem = status.toLowerCase();

      return <OrdersStatus status={statusItem} />;
    },
    enableSorting: false,
    align: COLUMN_ALIGN.RIGHT,
  }),
  accessor('controls', {
    header: () => <FormattedMessage id="Trades" />,
    cell: (info) => {
      const isExpanded = info.row.getIsExpanded();

      return (
        <div className="d-flex justify-content-end">
          {isTabletDown ? (
            <Button
              variant="text"
              onClick={() =>
                openTradesHistoryDetailsModal({
                  data: info.row.original,
                })
              }
            >
              <FormattedMessage id="Details" />
              ...
            </Button>
          ) : (
            <Button variant="text" onClick={() => info.row.toggleExpanded()}>
              <DownIconSVG
                width={12}
                height={6}
                className={classNames(styles.icon, {
                  'order-history-block__expand-button--opened': isExpanded,
                })}
              />
            </Button>
          )}
        </div>
      );
    },
    align: COLUMN_ALIGN.RIGHT,
    enableSorting: false,
  }),
];

export const tradesColumns = [
  accessor('timestamp', {
    header: () => <FormattedMessage id="Date" />,
    cell: (info) => {
      const date = info.getValue();

      return <OrdersDate date={date} />;
    },
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
  }),
  accessor('price_info', {
    header: () => <FormattedMessage id="Price" />,
    cell: (info) => {
      const { price, coinSymbol } = info.getValue();

      return <OrdersPrice price={price} coinSymbol={coinSymbol} />;
    },
    enableSorting: false,
  }),
  accessor('volume_info', {
    header: () => <FormattedMessage id="Filled" />,
    cell: (info) => {
      const { volume, coinSymbol } = info.getValue();

      return <OrdersAmount amount={volume} coinSymbol={coinSymbol} />;
    },
    enableSorting: false,
  }),
  accessor('total_amount', {
    header: () => <FormattedMessage id="Total" />,
    cell: (info) => {
      const { total, coinSymbol } = info.getValue();

      return <OrdersTotalAmount coinSymbol={coinSymbol} total={total} />;
    },
    enableSorting: false,
  }),
  accessor('fee_info', {
    header: () => <FormattedMessage id="Fee" />,
    cell: (info) => {
      const { fee, coinSymbol } = info.getValue();

      return <OrdersFee coinSymbol={coinSymbol} value={fee} />;
    },
    enableSorting: false,
  }),
];

export default orderHistoryTableColumns;
