import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { AccountModal, WidgetsForm } from '@shared/components';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import { OverviewEarnWidgetsSelectors } from '../../overviewEarnWidgets.selectors';
import { overviewEarnWidgetsActions } from '../../overviewEarnWidgets.slice';

const OverviewEarnWidgetsModal = () => {
  const { isOpen, closeModal } = useModal(
    modalsIds.OVERVIEW_EARN_WIDGETS_MODAL,
  );

  const widgets = useSelector(OverviewEarnWidgetsSelectors.widgetsSelector);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(overviewEarnWidgetsActions.updateWidgets(values.widgets));

    closeModal();
  };

  return (
    <AccountModal
      isOpen={isOpen}
      title={<FormattedMessage id="Widgets" />}
      onClose={closeModal}
    >
      <WidgetsForm
        widgets={widgets}
        onSubmit={handleSubmit}
        onCancel={closeModal}
      />
    </AccountModal>
  );
};

export default memo(OverviewEarnWidgetsModal);
