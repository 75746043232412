import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldController } from '@shared/components';

import CheckBox from '@components/CheckBox';
import Link from '@components/Link';

import { SERVICE_ROUTES } from 'src/routes.constants';

import styles from './BotAcceptSettingsCheckBox.module.scss';

const BotAcceptSettingsCheckBox = ({ isShowError, isChecked, onChange }) => {
  return (
    <div className={styles.wrapper}>
      <CheckBox
        checked={isChecked}
        className={styles.check_box}
        containerClassName={styles.check_box_container}
        label={
          <span className={styles.content}>
            <FormattedMessage id="IHaveRead" />{' '}
            <Link
              to={SERVICE_ROUTES.INSTANT_BONUS_TERMS}
              inversion
              className="text-decoration-underline"
            >
              <FormattedMessage id="ParamountDaxSettingsServiceAgreement" />
            </Link>
          </span>
        }
        onChange={onChange}
      />
      {!isChecked && isShowError && (
        <span className={styles.error}>
          <FormattedMessage id="PleaseAcceptSettingsServiceAgreement" />
        </span>
      )}
    </div>
  );
};

export const BotAcceptSettingsCheckBoxController = (props) => {
  return (
    <FieldController
      {...props}
      valueAccessor="isChecked"
      component={BotAcceptSettingsCheckBox}
    />
  );
};

export default memo(BotAcceptSettingsCheckBox);
