import React, { memo, useState } from 'react';

import StopForm from './components/StopForm';
import TrailingStopForm from './components/TrailingStopForm';

const Container = ({
  layoutItemId,
  buyTab,
  currentMarket,
  currentPrice,
  quoteAvailableBalance,
  marketAvailableBalance,
  layoutItemRef,
}) => {
  const [isTrailing, setIsTrailing] = useState(false);

  return (
    <>
      {isTrailing ? (
        <TrailingStopForm
          layoutItemId={layoutItemId}
          buyTab={buyTab}
          currentMarket={currentMarket}
          currentPrice={currentPrice}
          setIsTrailing={setIsTrailing}
          layoutItemRef={layoutItemRef}
        />
      ) : (
        <StopForm
          buyTab={buyTab}
          currentMarket={currentMarket}
          currentPrice={currentPrice}
          quoteAvailableBalance={quoteAvailableBalance}
          marketAvailableBalance={marketAvailableBalance}
          setIsTrailing={setIsTrailing}
          layoutItemRef={layoutItemRef}
        />
      )}
    </>
  );
};

export default memo(Container);
