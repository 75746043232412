import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import { useForm, useWatch } from 'react-hook-form';

import { useLastTradeInfo } from '@shared/hooks';

import { useGetFeesInfoQueryResult } from '@store/api/hooks';
import { marketIdSelector } from '@store/settings/settingsSelectors';

import useDidMountEffect from '@hooks/useDidMountEffect';

import { format } from '@utils/numbers';

import { DEFAULT_PRECISION, ORDER_FORM_TYPE } from '@constants';

import FormInput from '../../FormInput';
import FormSlider from '../../FormSlider';
import SubmitFormButton from '../../SubmitFormButton';
import { useBothForms } from '../hooks';

import { useCalculateLimitFormValues } from './hooks';
import {
  getAmountFieldRules,
  getPriceFieldRules,
  getTotalFieldRules,
} from './LimitForm.validations';

const LimitForm = ({
  buyTab,
  currentMarket,
  currentPrice,
  quoteAvailableBalance,
  marketAvailableBalance,
  layoutItemRef,
}) => {
  const intl = useIntl();

  const { feeInfo } = useGetFeesInfoQueryResult();

  const [fee, setFee] = useState(0);

  const { isShowBothForms } = useBothForms({ layoutItemRef });

  const {
    control,
    setValue,
    formState: { isSubmitted, errors, dirtyFields },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      amount: '',
      price: '',
      total: '',
    },
  });

  const marketId = useSelector(marketIdSelector);

  const amountWatch = useWatch({ control, name: 'amount' });
  const priceWatch = useWatch({ control, name: 'price' });
  const totalWatch = useWatch({ control, name: 'total' });

  const tradeChangingInfo = useLastTradeInfo();

  useEffect(() => {
    let limitFee =
      (buyTab && Number(priceWatch) > Number(tradeChangingInfo.value)) ||
      (!buyTab && Number(priceWatch) <= Number(tradeChangingInfo.value))
        ? feeInfo.taker_fee
        : feeInfo.maker_fee;
    let updatedFee = limitFee * priceWatch * amountWatch;

    if (isNaN(updatedFee)) {
      updatedFee = 0;
    }

    if (fee !== updatedFee) {
      setFee(updatedFee);
    }
  }, [
    feeInfo.taker_fee,
    feeInfo.maker_fee,
    amountWatch,
    priceWatch,
    tradeChangingInfo.value,
    buyTab,
  ]);

  useEffect(() => {
    reset();
  }, [marketId]);

  useEffect(() => {
    if (currentPrice && isEmpty(dirtyFields)) {
      handleChangePrice(
        format(currentPrice, {
          precision: currentMarket.quote_precision_format,
          noCommas: true,
          removeZeroEnd: true,
        }),
        {
          shouldDirty: false,
        },
      );
    }
  }, [
    dirtyFields,
    currentPrice,
    currentMarket?.market_coin_symbol,
    currentMarket?.quote_coin_symbol,
  ]);

  const handleChangePrice = (value, options) => {
    setValue('price', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
      ...options,
    });
  };

  const priceDependencyUpdates = (value) => {
    if (amountWatch) {
      const updatedTotal = format(value * amountWatch, {
        precision: currentMarket.quote_precision_format,
        noCommas: true,
        removeZeroEnd: true,
      });

      setValue('total', Number(updatedTotal) ? updatedTotal : '', {
        shouldValidate: isSubmitted,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const handleChangeAmount = (value) => {
    setValue('amount', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });

    amountDependencyUpdates(value);
  };

  const amountDependencyUpdates = (value) => {
    if (priceWatch) {
      const updatedTotal = format(priceWatch * value, {
        precision: currentMarket?.quote_precision_format,
        noCommas: true,
        removeZeroEnd: true,
      });

      setValue('total', Number(updatedTotal) ? updatedTotal : '', {
        shouldValidate: isSubmitted,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const handleChangeTotal = (value) => {
    setValue('total', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const totalDependencyUpdates = (value) => {
    if (priceWatch) {
      const updatedAmount = format(value / priceWatch, {
        precision: currentMarket?.market_precision_format,
        noCommas: true,
        removeZeroEnd: true,
      });

      setValue('amount', Number(updatedAmount) ? updatedAmount : '', {
        shouldValidate: isSubmitted,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const handleMinusPlusClick = (name, isMinus = false) => {
    let value = 0;
    let decimal = DEFAULT_PRECISION;

    switch (name) {
      case 'price': {
        if (priceWatch) value = Number(priceWatch);

        decimal = currentMarket?.quote_precision_format;

        break;
      }
      case 'amount': {
        if (amountWatch) value = Number(amountWatch);

        decimal = currentMarket?.market_precision_format;

        break;
      }
      case 'total': {
        if (totalWatch) value = Number(totalWatch);

        decimal = currentMarket?.quote_precision_format;

        break;
      }

      default:
        break;
    }

    const step = Number(Math.pow(0.1, decimal).toFixed(decimal));

    value = Number(value.toFixed(decimal));
    value = isMinus ? Math.max(0, value - step) : value + step;
    value = value.toFixed(decimal);

    switch (name) {
      case 'price': {
        handleChangePrice(value);
        priceDependencyUpdates(value);

        break;
      }

      case 'amount': {
        handleChangeAmount(value);
        amountDependencyUpdates(value);

        break;
      }

      case 'total': {
        handleChangeTotal(value);
        totalDependencyUpdates(value);

        break;
      }

      default:
        break;
    }
  };

  useCalculateLimitFormValues({
    isBuyTab: buyTab,
    layoutItemRef,
    handleChangeAmount,
    handleChangePrice,
    handleChangeTotal,
  });

  useDidMountEffect(() => {
    reset();
  }, [isShowBothForms]);

  return (
    <>
      <FormInput
        control={control}
        name="price"
        onlyNumbers
        plusminus
        label={
          <span>
            <FormattedMessage id="Price" />:
          </span>
        }
        errors={errors}
        rules={getPriceFieldRules(intl)}
        appendLabel={currentMarket?.quote_coin_symbol}
        precision={currentMarket?.quote_precision_format}
        placeholder={format(0, {
          precision: currentMarket?.quote_precision_format,
        })}
        layoutItemRef={layoutItemRef}
        dependencyUpdates={priceDependencyUpdates}
        onMinusPlusClick={handleMinusPlusClick}
      />

      <FormInput
        control={control}
        name="amount"
        onlyNumbers
        plusminus
        label={
          <span>
            <FormattedMessage id="Amount" />:
          </span>
        }
        rules={getAmountFieldRules(intl)}
        errors={errors}
        appendLabel={currentMarket?.market_coin_symbol}
        precision={currentMarket?.market_precision_format}
        placeholder={format(0, {
          precision: currentMarket?.market_precision_format,
        })}
        layoutItemRef={layoutItemRef}
        dependencyUpdates={amountDependencyUpdates}
        onMinusPlusClick={handleMinusPlusClick}
      />

      {/* on the side change we should also update amount or quantity */}
      {/* on the total onblur for some reason amount is changed */}

      <FormSlider
        layoutItemRef={layoutItemRef}
        isBuyTab={buyTab}
        currentPrice={priceWatch}
        quoteAvailableBalance={quoteAvailableBalance}
        marketAvailableBalance={marketAvailableBalance}
        currentMarket={currentMarket}
        absoluteValue={amountWatch}
        onChange={handleChangeAmount}
      />

      <FormInput
        control={control}
        name="total"
        onlyNumbers={true}
        plusminus
        label={
          <span>
            <FormattedMessage id="Total" />:
          </span>
        }
        errors={errors}
        rules={getTotalFieldRules(intl)}
        appendLabel={currentMarket?.quote_coin_symbol}
        precision={currentMarket?.quote_precision_format}
        placeholder={format(0, {
          precision: currentMarket?.quote_precision_format,
        })}
        layoutItemRef={layoutItemRef}
        dependencyUpdates={totalDependencyUpdates}
        onMinusPlusClick={handleMinusPlusClick}
      />

      <SubmitFormButton
        tradeType={ORDER_FORM_TYPE.LIMIT}
        buyTab={buyTab}
        currentMarket={currentMarket}
        currentPrice={currentPrice}
        fee={fee}
        getValues={getValues}
        handleSubmit={handleSubmit}
        layoutItemRef={layoutItemRef}
      />
    </>
  );
};

export default memo(LimitForm);
