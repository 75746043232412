import React, { memo } from 'react';

import classNames from 'classnames';

import { ReactComponent as ConfirmedIconSVG } from '@icons/confirmed-icon.svg';

import styles from './IndexIcon.module.scss';

const IndexIcon = ({ isActive, index, isShowVerticalLine }) => {
  return (
    <div className={classNames(styles.index_icon, isActive && styles.active)}>
      <div className={styles.index}>{index}</div>
      {isActive && (
        <div className={styles.confirmed_icon}>
          <ConfirmedIconSVG />
        </div>
      )}
      {isShowVerticalLine && 'border-0' && (
        <div className={styles.vertical_line}></div>
      )}
    </div>
  );
};

export default memo(IndexIcon);
