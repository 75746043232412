import { forwardRef, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';
import { FileDrop } from 'react-file-drop';

import { FormController } from '@shared/components';
import { Loader } from '@shared/ui';
import {
  FileUploaderImagePreview,
  FileUploaderPlaceholder,
  FileUploaderTextPreview,
} from '@shared/ui/FileUploader/components';
import { acceptedExt } from '@shared/utils';

import { NOOP } from '@constants';

import s from './FileUploader.module.scss';

/**
 * This FileUploader can upload only ONE file
 * */
const FileUploader = forwardRef(
  (
    {
      withImagePreview,
      acceptTypes = [],
      onRemoveFile = NOOP,
      onChange,
      maxSizeMb,
      customStyles = {},
      uploadIcon,
      isInvalid,
      ...props
    },
    ref,
  ) => {
    const fileInputRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (!props.value) return;

      setIsLoading(false);
    }, [props.value]);

    const onFileInputChange = (event) => {
      const { files } = event.target;

      if (!files || !files?.length) return;

      onChange(files[0]);

      setIsLoading(true);
    };

    const onTargetClick = () => {
      if (!props.value) {
        fileInputRef.current.click();
      }
    };

    const handleDragEnter = () => setIsHovered(true);

    const handleDragLeave = () => setIsHovered(false);

    const handleOnDrop = (files) => {
      onFileInputChange({ target: { files } });

      setIsHovered(false);
    };

    const onInputClick = (event) => {
      event.target.value = '';
    };

    const handleChange = (event) => {
      onChange(event.target.files[0]);

      setIsLoading(true);
    };

    return (
      <div>
        <input
          ref={(e) => {
            ref(e);
            fileInputRef.current = e;
          }}
          className={s.input_file}
          onChange={handleChange}
          onClick={onInputClick}
          accept={acceptedExt(acceptTypes)}
          type="file"
          {...props}
          value={props.value?.filename}
        />

        <FileDrop
          onTargetClick={onTargetClick}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleOnDrop}
        >
          <div
            className={cx(s.file_uploader, customStyles.file_uploader, {
              [s.file_uploader__hovered]: isHovered,
              [s.file_uploader__image_preview]: withImagePreview,
            })}
          >
            {isLoading && (
              <div className={s.loading_wrapper}>
                <Loader />
                <FormattedMessage id="Loading" />
                ...
              </div>
            )}
            {!isLoading && (
              <>
                {!props.value ? (
                  <FileUploaderPlaceholder
                    withImagePreview={withImagePreview}
                    uploadIcon={uploadIcon}
                    customStyles={customStyles}
                  />
                ) : (
                  <Preview
                    withImagePreview={withImagePreview}
                    file={props.value}
                    onRemoveFile={onRemoveFile}
                  />
                )}
              </>
            )}
          </div>
        </FileDrop>
      </div>
    );
  },
);

const Preview = ({ withImagePreview, ...props }) => {
  if (withImagePreview) {
    return <FileUploaderImagePreview {...props} />;
  }

  return <FileUploaderTextPreview {...props} />;
};

export default FileUploader;

export const FileUploaderController = (props) => {
  return <FormController component={FileUploader} {...props} />;
};
