import { memo, useCallback, useId, useLayoutEffect } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import classNames from 'classnames';

import styles from './PercentChangeChart.module.scss';

const PercentChangeChart = ({
  data,
  color,
  width,
  height,
  paddingLeft = -5,
  paddingRight = -5,
  className,
  dataSeries = [{ name: 'value' }],
  colors = [],
  fillOpacity = 1,
  strokeColor,
  fillColor,
  isOnlyShowOnViewport = false,
}) => {
  const id = useId();

  const createSeries = useCallback(
    (chart, fieldName) => {
      const series = chart.series.push(new am4charts.LineSeries());

      series.dataFields.valueY = fieldName;
      series.dataFields.dateX = 'date';
      series.fillOpacity = fillOpacity;

      const seriesColor =
        colors.find((item) => item.name === fieldName)?.color || color;

      series.stroke = am4core.color(strokeColor ?? seriesColor);
      series.fill = am4core.color(fillColor ?? seriesColor);
    },
    [color, colors, fillColor, fillOpacity, strokeColor],
  );

  useLayoutEffect(() => {
    const chart = am4core.create(id, am4charts.XYChart);
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    if (chart.logo) {
      chart.logo.disabled = true;
    }

    chart.data = data;
    dataSeries.forEach((item) => {
      createSeries(chart, item.name);
    });

    chart.series.each(function (series) {
      series.onlyShowOnViewport = isOnlyShowOnViewport;
    });
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.paddingLeft = paddingLeft;
    chart.paddingRight = paddingRight;
    chart.marginLeft = 0;

    valueAxis.renderer.labels.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;

    return () => {
      chart.dispose();
    };
  }, [
    color,
    createSeries,
    data,
    dataSeries,
    id,
    isOnlyShowOnViewport,
    paddingLeft,
    paddingRight,
  ]);

  return (
    <div
      id={id}
      className={classNames(styles.chart, className)}
      style={{ width, height }}
    />
  );
};

export default memo(PercentChangeChart);
