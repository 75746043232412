import { useEffect, useState } from 'react';

import {
  getCellHeight,
  getCellWidth,
} from '@pages/TerminalPage/components/GridLayout/gridLayoutHelper';

import useGridLayout from '@hooks/gridLayout/useGridLayout';
import useElementResize from '@hooks/useElementResize';

const defaultValue = {
  isMiniX: false,
  isStandardX: false,
  isLandscapeX: false,
  isMiniY: false,
  isStandardY: false,
  isLandscapeY: false,
};

const useBlockBreakpoints = (elementRef, config) => {
  const getBreakpoints = () => {
    if (!elementRef.current) {
      return defaultValue;
    }

    const layoutContainer = document.querySelector('.layout-container');

    const { width: layoutContainerWidth, height: layoutContainerHeight } =
      layoutContainer.getBoundingClientRect();

    const cellWidth = getCellWidth(
      layoutContainerWidth,
      colsAmount,
      LAYOUT_ITEM_MARGIN,
      LAYOUT_PADDING,
    );
    const cellHeight = getCellHeight(
      layoutContainerHeight,
      rowsAmount,
      LAYOUT_ITEM_MARGIN,
      LAYOUT_PADDING,
    );

    const layoutItemElementRect = elementRef.current.getBoundingClientRect();

    const cellsX = Math.round(layoutItemElementRect.width / cellWidth);
    const cellsY = Math.round(layoutItemElementRect.height / cellHeight);

    const { standard, landscape } = config;

    let updatedView = defaultValue;

    if (cellsX < standard.width) {
      updatedView = { ...updatedView, isMiniX: true };
    } else if (cellsX >= standard.width && cellsX < landscape.width) {
      updatedView = { ...updatedView, isStandardX: true };
    } else {
      updatedView = { ...updatedView, isLandscapeX: true };
    }

    if (cellsY < standard.height) {
      updatedView = { ...updatedView, isMiniY: true };
    } else if (cellsY >= standard.height && cellsY < landscape.height) {
      updatedView = { ...updatedView, isStandardY: true };
    } else {
      updatedView = { ...updatedView, isLandscapeY: true };
    }

    return updatedView;
  };

  const LAYOUT_ITEM_MARGIN = [0, 0];
  const LAYOUT_PADDING = [0, 0];

  const blockDimensions = useElementResize(elementRef);
  const { rowsAmount, colsAmount } = useGridLayout();

  const [view, setView] = useState(getBreakpoints);

  useEffect(() => {
    setView(getBreakpoints());
  }, [blockDimensions]);

  return view;
};

export default useBlockBreakpoints;
