import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  AccountModal,
  FORM_ACTION_ORIENTATION,
  FormActions,
} from '@shared/components';

import useModal from '@hooks/useModal/useModal';

import { NOOP, modalsIds } from '@constants';

import styles from './ConfirmLiquidateBotModal.module.scss';

const ConfirmLiquidateBotModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.CONFIRM_LIQUIDATE_BOT_MODAL,
  );

  const { onSubmit = NOOP } = modal.params;

  const handleSubmit = () => {
    closeModal();

    onSubmit();
  };

  return (
    <AccountModal
      title={<FormattedMessage id="Confirmation" />}
      onClose={closeModal}
      afterClose={resetModal}
      isOpen={isOpen}
      footer={
        <FormActions
          submitText={<FormattedMessage id="YesConfirm" />}
          submitButtonProps={{
            type: 'button',
            color: 'dangerous',
          }}
          onSubmit={handleSubmit}
          cancelButtonProps={{
            variant: 'text',
          }}
          onCancel={closeModal}
          orientation={FORM_ACTION_ORIENTATION.VERTICAL}
        />
      }
    >
      <div className={styles.description}>
        <div className="text-center mb-10">
          <FormattedMessage id="AfterThat" />:
        </div>
        <div>
          1. <FormattedMessage id="BotFundsWillBeMoved" />
        </div>
        <div>
          2. <FormattedMessage id="YouCantActivate" />
        </div>
        <div>
          3. <FormattedMessage id="ToUseTheSameSettings" />
        </div>
      </div>
    </AccountModal>
  );
};

export default memo(ConfirmLiquidateBotModal);
