import React, { memo } from 'react';

import { components } from 'react-select';

const IndicatorsContainer = ({ children, selectProps, ...props }) => {
  const isSearchable = selectProps.isSearchable;
  const isClearable = selectProps.isClearable;
  const isDirtyField = !!selectProps.inputValue || !!selectProps.value;
  const isLoading = selectProps.isLoading;

  const [CrossChild, LoaderChild, SeparatorChild, DropdownChild] = children;

  const renderIndicator = () => {
    if (isLoading) return LoaderChild;
    if (isSearchable && isDirtyField && isClearable) return CrossChild;
    return DropdownChild;
  };

  return (
    <components.IndicatorsContainer {...props}>
      {renderIndicator()}
    </components.IndicatorsContainer>
  );
};

export default memo(IndicatorsContainer);
