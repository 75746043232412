import { forwardRef } from 'react';

import cx from 'classnames';
import RCSlider from 'rc-slider';

import { FormController } from '@shared/components';

import s from './Slider.module.scss';

const Slider = forwardRef(({ inversion, ...props }, ref) => {
  return (
    <div className={cx(s.slider, { [s.inversion]: inversion })}>
      <RCSlider ref={ref} {...props} />
    </div>
  );
});

export const SliderController = (props) => {
  return <FormController component={Slider} {...props} />;
};

export default Slider;
