export const terminalThemeSelector = (state) => state.settings.terminalTheme;
export const accountThemeSelector = (state) => state.settings.accountTheme;
export const isAccountThemeSelector = (state) => state.settings.isAccountTheme;
export const localeSelector = (state) => state.settings.locale;
export const currencySelector = (state) => state.settings.currency;
export const isWSConnectedSelector = (state) => state.settings.isWSConnected;
export const marketIdSelector = (state) => state.settings.marketId;
export const userIdSelector = (state) => state.settings.userId;
export const userEmailSelector = (state) => state.settings.userEmail;
export const userRoleSelector = (state) => state.settings.userRole;
export const isAuthorizedUserSelector = (state) =>
  state.settings.isAuthorizedUser;
export const isGuestUserSelector = (state) => !state.settings.isAuthorizedUser;
export const isShowSensitiveInfoSelector = (state) =>
  state.settings.isShowSensitiveInfo;
