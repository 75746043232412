import { useState } from 'react';

const initState = {
  opened: false,
  params: {},
};

const useSidebarsManager = () => {
  const [sidebars, setSidebars] = useState({});

  const register = (sidebarId) => {
    setSidebars((prevState) => ({
      ...prevState,
      [sidebarId]: initState,
    }));
  };

  const toggle = (sidebarId, params) => {
    setSidebars((prevState) => ({
      ...prevState,
      [sidebarId]: {
        ...prevState[sidebarId],
        opened: !prevState[sidebarId].opened,
        params: params ?? prevState[sidebarId].params,
      },
    }));
  };

  const open = (sidebarId, params) => {
    setSidebars((prevState) => ({
      ...prevState,
      [sidebarId]: {
        ...prevState[sidebarId],
        opened: true,
        params: params ?? prevState[sidebarId].params,
      },
    }));
  };

  const close = (sidebarId, params) => {
    setSidebars((prevState) => ({
      ...prevState,
      [sidebarId]: {
        ...prevState[sidebarId],
        opened: false,
        params: params ?? prevState[sidebarId].params,
      },
    }));
  };

  return { sidebars, open, close, toggle, register };
};
export default useSidebarsManager;
