import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Button from '@components/Button';
import Modal from '@components/modals/Modal';
import NoData from '@components/NoData';

import useGridLayout from '@hooks/gridLayout/useGridLayout';
import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import TemplatesListItem from './components/TemplatesListItem';

import styles from './SavedTemplatesModal.module.scss';

const SavedTemplatesModal = () => {
  const { isOpen, closeModal } = useModal(modalsIds.SAVED_TEMPLATES_LIST);

  const { templates, updateTemplates } = useGridLayout();

  const handleDragEnd = ({ source, reason, destination }) => {
    if (reason === 'DROP') {
      if (!destination) {
        return;
      }

      const updatedTemplates = cloneDeep(templates);

      const [removedTemplate] = updatedTemplates.splice(source.index, 1);

      updatedTemplates.splice(destination.index, 0, removedTemplate);

      updateTemplates(updatedTemplates);
    }
  };

  return (
    <Modal
      className={classNames('modal-sm', styles.modal)}
      visible={isOpen}
      onClose={closeModal}
      title={<FormattedMessage id="MySaved" />}
      footer={
        <Button fill secondary rounded onClick={closeModal}>
          <FormattedMessage id="Cancel" />
        </Button>
      }
    >
      {isEmpty(templates) ? (
        <NoData />
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="templates-list">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {templates.map((template, templateIndex) => (
                  <Draggable
                    key={`${template.label}-${templateIndex}`}
                    draggableId={`${template.label}-${templateIndex}`}
                    index={templateIndex}
                  >
                    {(provided, snapshot) => (
                      <TemplatesListItem
                        provided={provided}
                        snapshot={snapshot}
                        item={template}
                      />
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Modal>
  );
};

export default memo(SavedTemplatesModal);
