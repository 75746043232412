import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';
import debounce from 'lodash/debounce';
import { useWatch } from 'react-hook-form';

import {
  ExportFileButtons,
  DatePicker,
  MarketsPair,
  SearchBar,
} from '@shared/components/TableFilters/components';
import { excludeTableFilterProps } from '@shared/components/TableFilters/TableFilters.utils';
import { Button } from '@shared/ui';

import useBreakpoints from '@hooks/useBreakpoints';

import AdvancedFiltersButton from './components/AdvancedFiltersButton/AdvancedFiltersButton';

import s from './TableFilters.module.scss';

export const TABLE_FILTERS_NAMES = {
  SEARCHBAR: 'keyword',
  DATE_PICKER: 'date_range',
  MARKETS_PAIR: {
    FROM: 'markets_pair_from',
    TO: 'markets_pair_to',
  },
};

const TableFilters = ({
  control,
  onFiltersChange,
  onControlsClick,
  customStyles = {},
  showAdvancedFilterButton,
  loaders = {},
  isCustomRangeButtonDisplayed,
  children,
  ...props
}) => {
  // Only used when window size is less than 1440px
  const [isFieldsHidden, setIsFieldsHidden] = useState(true);

  const searchWatch = useWatch({
    control,
    name: TABLE_FILTERS_NAMES.SEARCHBAR,
  });

  const dateRangeWatch = useWatch({
    control,
    name: TABLE_FILTERS_NAMES.DATE_PICKER,
  });

  const marketPairFromWatch = useWatch({
    control,
    name: TABLE_FILTERS_NAMES.MARKETS_PAIR.FROM,
  });

  const marketPairToWatch = useWatch({
    control,
    name: TABLE_FILTERS_NAMES.MARKETS_PAIR.TO,
  });

  const debouncedSearch = useRef(
    debounce(() => {
      const values = props.getValues();
      onFiltersChange(values);
    }, 300),
  ).current;

  // searchWatch in useEffect separately to debounce value
  useEffect(() => {
    debouncedSearch(searchWatch);
  }, [searchWatch]);

  useEffect(() => {
    const values = props.getValues();
    onFiltersChange(values);
  }, [dateRangeWatch, marketPairFromWatch, marketPairToWatch]);

  useEffect(() => {
    return () => debouncedSearch.cancel();
  }, [debouncedSearch]);

  const { isMobile } = useBreakpoints();

  const componentProps = excludeTableFilterProps(props);

  const handleResetFields = () => {
    props.reset(props.formState.defaultValues);
  };

  const toggleExposeFields = () => setIsFieldsHidden((prevState) => !prevState);

  const isSearchBarDisplayed = useMemo(() => {
    if (!props.searchbar) return false;
    if (showAdvancedFilterButton) return !isMobile;
    return true;
  }, [props.searchbar, showAdvancedFilterButton, isMobile]);

  const isDatePickerDisplayed = useMemo(() => {
    if (!props.datepicker) return false;
    if (showAdvancedFilterButton) return !isMobile && !props.searchbar;
    return true;
  }, [props.datepicker, props.searchbar, showAdvancedFilterButton, isMobile]);

  const isClearAllDisplayed = useMemo(() => {
    if (!props.clearAllButton) return false;
    return !showAdvancedFilterButton;
  }, [props.clearAllButton, showAdvancedFilterButton]);

  const isMarketsPairDisplayed = useMemo(() => {
    if (!props.marketsPair) return false;
    return !showAdvancedFilterButton;
  }, [props.marketsPair, showAdvancedFilterButton]);

  return (
    <div>
      <div className={cx(s.table_filters, customStyles.table_filters)}>
        <div className={s.fields}>
          {isSearchBarDisplayed && (
            <SearchBar
              name={TABLE_FILTERS_NAMES.SEARCHBAR}
              control={control}
              customStyles={customStyles.searchbar}
              {...componentProps}
            />
          )}
          {isDatePickerDisplayed && (
            <DatePicker
              name={TABLE_FILTERS_NAMES.DATE_PICKER}
              control={control}
              isCustomRangeButtonDisplayed={isCustomRangeButtonDisplayed}
              {...componentProps}
            />
          )}
          {isMarketsPairDisplayed && (
            <MarketsPair
              nameFrom={TABLE_FILTERS_NAMES.MARKETS_PAIR.FROM}
              nameTo={TABLE_FILTERS_NAMES.MARKETS_PAIR.TO}
              control={control}
              {...componentProps}
            />
          )}
          <>
            {!showAdvancedFilterButton &&
              children &&
              children({
                control,
                onFiltersChange,
                getValues: props.getValues,
              })}
          </>
          {isClearAllDisplayed && (
            <Button
              type="button"
              size="m"
              variant="text"
              onClick={handleResetFields}
            >
              <FormattedMessage id="ClearAll" />
            </Button>
          )}

          {showAdvancedFilterButton && (
            <AdvancedFiltersButton
              type="button"
              onClick={toggleExposeFields}
              active={!isFieldsHidden}
            />
          )}
        </div>
        {(props.exportPdf || props.exportXsl) && (
          <div className={s.controls}>
            <ExportFileButtons
              loaders={loaders}
              exportPdf={props.exportPdf}
              exportXsl={props.exportXsl}
              onControlsClick={onControlsClick}
            />
          </div>
        )}
      </div>

      {!isFieldsHidden && (
        <div className={s.table_filters__exposed_fields}>
          {props.searchbar && !isSearchBarDisplayed && (
            <SearchBar
              name={TABLE_FILTERS_NAMES.SEARCHBAR}
              control={control}
              customStyles={customStyles.searchbar}
              {...componentProps}
            />
          )}
          {props.datepicker && !isDatePickerDisplayed && (
            <DatePicker
              name={TABLE_FILTERS_NAMES.DATE_PICKER}
              control={control}
              {...componentProps}
            />
          )}
          {props.marketsPair && !isMarketsPairDisplayed && (
            <MarketsPair
              nameFrom={TABLE_FILTERS_NAMES.MARKETS_PAIR.FROM}
              nameTo={TABLE_FILTERS_NAMES.MARKETS_PAIR.TO}
              control={control}
              {...componentProps}
            />
          )}
          <>
            {showAdvancedFilterButton &&
              children &&
              children({
                control,
                onFiltersChange,
                getValues: props.getValues,
              })}
          </>
          {props.clearAllButton && !isClearAllDisplayed && (
            <Button
              type="button"
              size="m"
              variant="text"
              onClick={handleResetFields}
            >
              <FormattedMessage id={isMobile ? 'Clear' : 'ClearAll'} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default TableFilters;
