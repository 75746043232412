import { useState, useEffect } from 'react';

const useDelayLoading = (isLoading, delay = 500) => {
  const [isLoadingSlow, setIsLoadingSlow] = useState(false);

  useEffect(() => {
    let timeout;

    if (isLoading) {
      timeout = setTimeout(() => {
        setIsLoadingSlow(true);
      }, delay);
    } else {
      setIsLoadingSlow(false);
      clearTimeout(timeout);
    }

    return () => clearTimeout(timeout);
  }, [isLoading, delay]);

  return isLoadingSlow;
};

export default useDelayLoading;
