import { useMemo } from 'react';

import { useMediaQuery } from 'react-responsive';

import useTheme from '@hooks/useTheme';

const useBreakpoints = () => {
  const {
    themeStyles: {
      'breakpoint-md': breakpointMD,
      'breakpoint-lg': breakpointLG,
      'breakpoint-xl': breakpointXL,
      'breakpoint-xxl': breakpointXXL,
      'breakpoint-3xl': breakpoint3XL,
    },
  } = useTheme();

  const md = Number.parseInt(breakpointMD);
  const lg = Number.parseInt(breakpointLG);
  const xl = Number.parseInt(breakpointXL);
  const xxl = Number.parseInt(breakpointXXL);
  const xxxl = Number.parseInt(breakpoint3XL);

  const isLargeDesktop = useMediaQuery({ minWidth: xxxl });
  const isDesktop = useMediaQuery({ minWidth: xxl });
  const isLaptop = useMediaQuery({ minWidth: xl, maxWidth: xxl - 0.2 });
  const isLargeTablet = useMediaQuery({ minWidth: lg, maxWidth: xl - 0.2 });
  const isTablet = useMediaQuery({ minWidth: md, maxWidth: xl - 0.2 });
  const isMobile = useMediaQuery({ maxWidth: md - 0.2 });

  const isLaptopDown = useMediaQuery({ maxWidth: xxl - 0.2 });
  const isTabletDown = useMediaQuery({ maxWidth: xl - 0.2 });
  const isLargeTabletDown = useMediaQuery({ maxWidth: lg - 0.2 });

  const isLaptopUp = useMediaQuery({ minWidth: xl });
  const isLargeTabletUp = useMediaQuery({ minWidth: lg });
  const isTabletUp = useMediaQuery({ minWidth: md });

  // Why - 0.2? https://getbootstrap.com/docs/5.0/layout/breakpoints/#max-width

  const breakpoints = useMemo(() => {
    return {
      isDesktop,
      isLaptop,
      isTablet,
      isMobile,
      isLaptopDown,
      isTabletDown,
      isLaptopUp,
      isTabletUp,
      isLargeTabletDown,
      isLargeTabletUp,
      isLargeDesktop,
      isLargeTablet,
    };
  }, [
    isDesktop,
    isLaptop,
    isTablet,
    isMobile,
    isLaptopDown,
    isTabletDown,
    isLaptopUp,
    isTabletUp,
    isLargeTabletDown,
    isLargeTabletUp,
    isLargeDesktop,
    isLargeTablet,
  ]);

  return breakpoints;
};

export default useBreakpoints;
