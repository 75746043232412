import React, { forwardRef, memo } from 'react';

import classnames from 'classnames';

import Loader from '@components/Loader';
import Tooltip from '@components/Tooltip';

import useLongPress from '@hooks/useLongPress';

import { NOOP } from '@constants';

import styles from './Button.module.scss';

const Button = forwardRef(
  (
    {
      children,
      className,
      fill = false,
      lg = false,
      s = false,
      textStart = false,
      primary = false,
      secondary = false,
      dangerous = false,
      outlinePrimary = false,
      outlineSecondary = false,
      outlineDangerous = false,
      accountPrimary = false,
      accountOutline = false,
      rounded = false,
      roundedS = false,
      disabled = false,
      isLoading = false,
      onClick = NOOP,
      onLongPress = NOOP,
      withTooltip = false,
      content = '',
      tooltipConfig = {},
      ...rest
    },
    ref,
  ) => {
    const longPressEvents = useLongPress(onLongPress, onClick);

    const Component = (
      <button
        ref={ref}
        className={classnames(styles.button, className, {
          [styles.fill]: fill,
          [styles.s]: s,
          [styles.sm]: !s && !lg, // by default
          [styles.lg]: lg,
          [styles.text_start]: textStart,
          [styles.primary]: primary,
          [styles.disabled]: disabled || isLoading,
          [styles.rounded_s]: roundedS,
          [styles.rounded_sm]: rounded,
          [styles.secondary]: secondary,
          [styles.dangerous]: dangerous,
          [styles.outline_primary]: outlinePrimary,
          [styles.outline_secondary]: outlineSecondary,
          [styles.outline_dangerous]: outlineDangerous,
          [styles.account_primary]: accountPrimary,
          [styles.account_outline]: accountOutline,
        })}
        disabled={disabled || isLoading}
        {...longPressEvents}
        {...rest}
      >
        {isLoading && (
          <Loader
            className="me-10"
            dark={primary || secondary || dangerous}
            xs
          />
        )}
        {children}
      </button>
    );

    if (withTooltip) {
      return (
        <Tooltip
          {...tooltipConfig}
          className={classnames(tooltipConfig.className, {
            'w-100': fill,
          })}
        >
          {Component}
        </Tooltip>
      );
    }

    return Component;
  },
);

export default memo(Button);
