import { memo, useMemo } from 'react';

import { useCurrencyIcon } from '@shared/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

const CoinIconCell = ({ coinSymbol, iconAccessor = 'icon' }) => {
  const { isTabletDown, isLaptopDown } = useBreakpoints();

  const coinIconSize = useMemo(() => {
    let iconSize = 36;
    if (isLaptopDown) iconSize = 28;
    if (isTabletDown) iconSize = 24;

    return iconSize;
  }, [isLaptopDown, isTabletDown]);

  return useCurrencyIcon(
    coinSymbol,
    {
      width: coinIconSize,
      height: coinIconSize,
      style: {
        minWidth: coinIconSize,
        minHeight: coinIconSize,
        maxWidth: coinIconSize,
        maxHeight: coinIconSize,
      },
    },
    iconAccessor,
  );
};

export default memo(CoinIconCell);
