import React, { memo } from 'react';

import classNames from 'classnames';

import { PersonalData } from '@shared/components';

import styles from './PercentChange.module.scss';

const PercentChange = ({ value, customStyles = {} }) => {
  const getColor = (value) => {
    if (value === 0) {
      return 'color-dark-grey';
    }

    if (value < 0) {
      return 'color-red';
    }
  };

  return (
    <span
      className={classNames(styles.percent_change, customStyles.percent_change)}
    >
      {value > 0 && '+'}
      <span className={getColor(value)}>
        <PersonalData data={value} /> %
      </span>
    </span>
  );
};

export default memo(PercentChange);
