import React, { memo } from 'react';
import { useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { BLOCK_BREAKPOINTS } from 'src/constants/blockBreakpoints';
import { BLOCK_TYPES } from 'src/constants/blockTypes';

import Input from '@components/Input';

import useBlockBreakpoints from '@hooks/useBlockBreakpoints';
import useBreakpoints from '@hooks/useBreakpoints';

import { NOOP } from '@constants';

import FieldContainer from '../FieldContainer';

import InputControls from './components/InputControls';

import styles from './FormInput.module.scss';

const FormInput = ({
  label,
  appendLabel,
  select,
  selectId,
  plusminus,
  control,
  name,
  rules,
  appendOptions,
  selectedAppend,
  errors,
  layoutItemRef,
  disabled = false,
  placeholder = '',
  isWhitePlaceholder = false,
  isSelectSearchable = true,
  dependencyUpdates = NOOP,
  onMinusPlusClick = NOOP,
  onChangeSelectedAppend = NOOP,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const { isMobile, isLaptop } = useBreakpoints();

  const { isMiniX, isLandscapeX } = useBlockBreakpoints(
    layoutItemRef,
    BLOCK_BREAKPOINTS[BLOCK_TYPES.trading],
  );

  const isSmallInput = (isLaptop || isMobile) && isLandscapeX;
  const isShowPrefixInput = !isMobile || (isLaptop && isLandscapeX) || isMiniX;

  return (
    <FieldContainer label={label} layoutItemRef={layoutItemRef}>
      <div
        className={classNames(styles.form_input, {
          [styles.form_input__error]: errors[name],
          [styles.form_input__focused]: isFocused,
        })}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field, field: { onChange, onBlur } }) => (
            <Input
              {...field}
              fill
              rounded={false}
              disabled={disabled}
              textCenter={isShowPrefixInput}
              prefix={
                isShowPrefixInput ? (
                  <span className="ms-10 text-nowrap">{label}</span>
                ) : null
              }
              inputContainerClassName={classNames(styles.input_container, {
                [styles.input_container__s]: isSmallInput,
                [styles.input_container__white_placeholder]: isWhitePlaceholder,
              })}
              size={isSmallInput ? 's' : 'm'}
              placeholder={placeholder}
              onFocus={(e) => setIsFocused(true)}
              autoComplete="off"
              onChange={(value, e) => {
                onChange(value);

                dependencyUpdates(value);
              }}
              onBlur={(e) => {
                onBlur(e);

                setIsFocused(false);
              }}
              {...rest}
            />
          )}
        />

        <InputControls
          name={name}
          select={select}
          selectId={selectId}
          plusminus={plusminus}
          appendLabel={appendLabel}
          appendOptions={appendOptions}
          selectedAppend={selectedAppend}
          isSelectSearchable={isSelectSearchable}
          disabled={disabled}
          onMinusPlusClick={onMinusPlusClick}
          onSelect={onChangeSelectedAppend}
        />
      </div>

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className="mt-2">
            <span className="color-red">{message}</span>
          </div>
        )}
      />
    </FieldContainer>
  );
};

export default memo(FormInput);
