import { memo } from 'react';

import { flexRender } from '@tanstack/react-table';
import classnames from 'classnames';

import SortIcon from '../components/SortIcon';

const TableSortableHeader = ({
  header,
  headerAlignClass,
  sortingState = [],
  ...rest
}) => {
  const sortingColumnState = sortingState.find(
    (col) => col.id === header.column.id,
  );

  return (
    <div
      className={classnames(
        `table-default__sortable-header table-default__cell--${headerAlignClass}-align`,
        {
          'table-default__sortable-header--sorted': sortingColumnState,
        },
      )}
      onClick={header.column.getToggleSortingHandler()}
      {...rest}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
      <SortIcon sorting={sortingColumnState} />
    </div>
  );
};

export default memo(TableSortableHeader);
