import React, { memo } from 'react';

import cx from 'classnames';

import { useValueColor } from '@shared/hooks';

import styles from './PercentChangeCell.module.scss';

const PercentChangeCell = ({ percentValue, customStyles = {} }) => {
  const { color, isPositive } = useValueColor(percentValue);
  const prefix = isPositive ? '+' : '';

  const percent = `${prefix}${percentValue}%`;

  return (
    <span
      className={cx(styles.percent_change, customStyles.percent_change)}
      style={{ color }}
    >
      {percent}
    </span>
  );
};

export default memo(PercentChangeCell);
