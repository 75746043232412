export const IsMoreThanZeroValue = (intl) => (amount) => {
  if (Number(amount) > 0) {
    return true;
  }

  return intl.formatMessage({ id: 'DepositAmountValidation' });
};

export const isProfitMoreThanZero =
  ({ intl, selectedCurrencyRates, mainCurrency, commission }) =>
  (amount) => {
    const selectedCurrencyRate = Number(
      selectedCurrencyRates?.[mainCurrency.value.toUpperCase()],
    );
    if (!selectedCurrencyRate || !amount) return 0;

    const amountInMainCurrency = amount * selectedCurrencyRate;

    const profit = amountInMainCurrency - commission;
    if (Number(profit) > 0) {
      return true;
    }

    return intl.formatMessage({ id: 'ToHaveProfitAmountShouldBeMore' });
  };
