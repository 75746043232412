import { useMemo } from 'react';

import { transformToCurrencyOptions } from '@shared/components';

import { useCoinsInfo } from '@api/hooks';

import useAvailablePlansTable from './useAvailablePlansTable';

const useCurrenciesInfo = (args, options) => {
  const { availablePlans } = useAvailablePlansTable(undefined, options);
  const { coinsInfoMap } = useCoinsInfo();

  const availableToStakeCurrencies = useMemo(() => {
    if (!availablePlans.length || !coinsInfoMap.length) return [];
    return coinsInfoMap.filter(
      (coin) =>
        !!availablePlans.find((plan) => plan.asset.coinSymbol === coin.symbol),
    );
  }, [availablePlans, coinsInfoMap]);

  const currenciesMemo = useMemo(() => {
    return transformToCurrencyOptions({
      currencies: availableToStakeCurrencies,
      inversion: true,
      isShowFullName: false,
      iconAccessor: 'icon',
      iconWidth: 28,
      iconHeight: 28,
    });
  }, [availableToStakeCurrencies]);

  return { currencies: currenciesMemo };
};

export default useCurrenciesInfo;
