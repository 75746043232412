import {
  getChannelBalances,
  getChannelCoinRates,
  getChannelMarketDepth,
  getChannelMarketStatistics,
  getChannelNotifications,
  getChannelTrades,
} from '@api/ws/channels';
import wsClient from '@api/ws/client';

class Subscriptions {
  subscriptions = {
    coinRates: null,
    marketStatistics: null,
    notifications: null,
    balances: null,
    marketDepth: null,
    trades: null,
  };

  subscribeCoinRates(callback) {
    if (this.subscriptions.coinRates) {
      return;
    }

    const channel = getChannelCoinRates();

    const subscription = wsClient.client.subscribe(channel);

    console.log('subscribe coins value');

    subscription.on('publish', (message) => {
      const { data } = message;

      callback({ data, reason: 'publish' });
    });

    subscription.on('error', (errContext) => {
      console.log('error: ', errContext);

      callback({ reason: 'error' });
    });

    subscription.on('unsubscribe', (context) => {
      console.log('unsubscribe: ', context);
      this.subscriptions.coinRates.removeAllListeners();
      this.subscriptions.coinRates = null;

      callback({ reason: 'unsubscribe' });
    });

    this.subscriptions.coinRates = subscription;
  }

  subscribeMarketStatistics(callback) {
    if (this.subscriptions.marketStatistics) {
      return;
    }

    const channel = getChannelMarketStatistics();

    const subscription = wsClient.client.subscribe(channel);

    console.log('subscribe market statistics');

    subscription.on('publish', (message) => {
      const { data } = message;

      callback({ data, reason: 'publish' });
    });

    subscription.on('error', (errContext) => {
      console.log('error: ', errContext);

      callback({ reason: 'error' });
    });

    subscription.on('unsubscribe', (context) => {
      console.log('unsubscribe: ', context);
      this.subscriptions.marketStatistics.removeAllListeners();
      this.subscriptions.marketStatistics = null;

      callback({ reason: 'unsubscribe' });
    });

    this.subscriptions.marketStatistics = subscription;
  }

  subscribeNotifications(userId, callback) {
    if (this.subscriptions.notifications) {
      return;
    }

    const channel = getChannelNotifications(userId);

    const subscription = wsClient.client.subscribe(channel);

    console.log('subscribe notifications');

    subscription.on('publish', (message) => {
      const { data } = message;

      callback({ data, reason: 'publish' });
    });

    subscription.on('error', (errContext) => {
      console.log('error: ', errContext);

      callback({ reason: 'error' });
    });

    subscription.on('unsubscribe', (context) => {
      console.log('unsubscribe: ', context);
      this.subscriptions.notifications.removeAllListeners();
      this.subscriptions.notifications = null;

      callback({ reason: 'unsubscribe' });
    });

    this.subscriptions.notifications = subscription;
  }

  subscribeBalances(userId, callback) {
    if (this.subscriptions.balances) {
      return;
    }

    const channel = getChannelBalances(userId);

    const subscription = wsClient.client.subscribe(channel);

    console.log('subscribe balances');

    subscription.on('publish', (message) => {
      const { data } = message;

      callback({ data, reason: 'publish' });
    });

    subscription.on('error', (errContext) => {
      console.log('error: ', errContext);

      callback({ reason: 'error' });
    });

    subscription.on('unsubscribe', (context) => {
      console.log('unsubscribe: ', context);
      this.subscriptions.balances.removeAllListeners();
      this.subscriptions.balances = null;

      callback({ reason: 'unsubscribe' });
    });

    this.subscriptions.balances = subscription;
  }

  subscribeMarketDepth(marketId, callback, { reconnect = false }) {
    if (reconnect) {
      this.unsubscribeMarketDepth();
    } else if (this.subscriptions.marketDepth) {
      return;
    }

    const channel = getChannelMarketDepth(marketId);

    const subscription = wsClient.client.subscribe(channel);

    console.log('subscribe market depth');

    subscription.on('publish', (message) => {
      const { data } = message;

      callback({ data, reason: 'publish' });
    });

    subscription.on('error', (errContext) => {
      console.log('error: ', errContext);

      callback({ reason: 'error' });
    });

    subscription.on('unsubscribe', (context) => {
      console.log('unsubscribe: ', context);
      this.subscriptions.marketDepth.removeAllListeners();
      this.subscriptions.marketDepth = null;

      callback({ reason: 'unsubscribe' });
    });

    this.subscriptions.marketDepth = subscription;
  }

  subscribeTrades(marketId, callback, { reconnect = false }) {
    if (reconnect) {
      this.unsubscribeTrades();
    } else if (this.subscriptions.trades) {
      return;
    }

    const channel = getChannelTrades(marketId);

    const subscription = wsClient.client.subscribe(channel);

    console.log('subscribe trades');

    subscription.on('publish', (message) => {
      const { data } = message;

      callback({ data, reason: 'publish' });
    });

    subscription.on('error', (errContext) => {
      console.log('error: ', errContext);

      callback({ reason: 'error' });
    });

    subscription.on('unsubscribe', (context) => {
      console.log('unsubscribe: ', context);
      this.subscriptions.trades.removeAllListeners();
      this.subscriptions.trades = null;

      callback({ reason: 'unsubscribe' });
    });

    this.subscriptions.trades = subscription;
  }

  unsubscribeCoinRates() {
    if (!this.subscriptions.coinRates) {
      return;
    }

    this.subscriptions.coinRates.unsubscribe();
  }

  unsubscribeMarketStatistics() {
    if (!this.subscriptions.marketStatistics) {
      return;
    }

    this.subscriptions.marketStatistics.unsubscribe();
  }

  unsubscribeNotifications() {
    if (!this.subscriptions.notifications) {
      return;
    }

    this.subscriptions.notifications.unsubscribe();
  }

  unsubscribeBalances() {
    if (!this.subscriptions.balances) {
      return;
    }

    this.subscriptions.balances.unsubscribe();
  }

  unsubscribeMarketDepth() {
    if (!this.subscriptions.marketDepth) {
      return;
    }

    this.subscriptions.marketDepth.unsubscribe();
  }

  unsubscribeTrades() {
    if (!this.subscriptions.trades) {
      return;
    }

    this.subscriptions.trades.unsubscribe();
  }
}

const subscriptions = new Subscriptions();

export default subscriptions;
