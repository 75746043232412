import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

const getURL = ({ symbol, from, to, interval, limit, scope }) => {
  let url = `candles/${symbol}?`;

  if (from) {
    url += `&from=${Math.floor(from / 1000)}`;
  }

  if (to) {
    url += `&to=${Math.floor(to / 1000)}`;
  }

  if (interval) url += `&interval=${interval}`;

  if (scope) {
    url += `&scope=${scope}`;
  }

  url += `&limit=${limit}`;

  return url;
};

export const candlesAPI = createApi({
  reducerPath: 'candlesAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    getCandles: builder.query({
      query: ({ symbol, from, to, interval, limit, scope }) => ({
        url: getURL({ symbol, from, to, interval, limit, scope }),
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCandlesQuery, useLazyGetCandlesQuery } = candlesAPI;
