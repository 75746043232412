import { forwardRef } from 'react';

import cx from 'classnames';

import { FormController } from '@shared/components';

import s from './Checkbox.module.scss';

const Checkbox = forwardRef(
  ({ label, customStyles = {}, inversion, isInvalid, ...props }, ref) => {
    return (
      <label
        className={cx(s.checkbox_wrapper, customStyles.checkbox_wrapper, {
          [s.inversion]: inversion,
        })}
      >
        <input
          ref={ref}
          className={cx(s.checkbox, customStyles.checkbox)}
          type="checkbox"
          aria-invalid={isInvalid}
          checked={props?.checked || props?.value}
          {...props}
        />
        {!!label && (
          <span className={cx(s.checkbox_label, customStyles.checkbox_label)}>
            {label}
          </span>
        )}
      </label>
    );
  },
);

export default Checkbox;

export const CheckboxController = (props) => {
  return <FormController component={Checkbox} {...props} />;
};
