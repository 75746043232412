import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Table from '@components/gridTables/Table';
import Modal from '@components/modals/Modal';

import { tradesColumns as columns } from '@pages/TerminalPage/components/GridLayout/blocks/OrderHistory/OrderHistoryColumns';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import styles from './OrderHistoryTradesModal.module.scss';

const OrderHistoryTradesModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.ORDER_HISTORY_TRADE_DETAILS,
  );

  const { isLargeTabletDown } = useBreakpoints();

  const { data } = modal.params;

  return (
    <Modal
      visible={isOpen}
      onClose={closeModal}
      afterClose={resetModal}
      className="modal-xl"
      title={
        <div className="d-flex flex-wrap">
          <div className={styles.title}>
            <FormattedMessage id="TotalTrades" />: {data?.total_amount?.total}{' '}
          </div>

          <span className={styles.order_id}>
            <FormattedMessage id="Id" />: {data?.id}
          </span>
        </div>
      }
      customStyles={styles}
    >
      <Table
        isSwipeable={isLargeTabletDown}
        id="order-history-trades-id"
        columns={columns}
        data={data?.trades}
        wideRowsS
        className={isLargeTabletDown ? styles.slider_table : styles.table}
        swipeableTableProps={{
          stickyColumns: 1,
        }}
      />
    </Modal>
  );
};

export default memo(OrderHistoryTradesModal);
