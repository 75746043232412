export const EARNING_HISTORY_COLUMN_NAMES = {
  USER: 'user',
  LEVEL_A_REVENUE: 'levelARevenue',
  LEVEL_B_REVENUE: 'levelBRevenue',
  LEVEL_C_REVENUE: 'levelCRevenue',
  TOTAL_REVENUE: 'totalRevenue',
  DATA_AND_TIME: 'dataAndTime',
  EARNING_IN_COIN: 'earningInCoin',
};

export const HISTORY_COLUMN_NAMES = {
  DATE_OF_INVITE_LEVEL_A: 'dataOfInviteLevelA',
  LEVEL_A_INVITED: 'levelAInvited',
  LEVEL_B_INVITED: 'levelBInvited',
  LEVEL_C_INVITED: 'levelCInvited',
  TOTAL_INVITED: 'totalInvited',
};
