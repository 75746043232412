import React from 'react';
import { Provider } from 'react-redux';

import Bugsnag from '@bugsnag/js';
import { createRoot } from 'react-dom/client';

import { bigsnagConfig } from '@libs/bugsnag';

import ErrorPage from '@pages/ErrorPage';

import store from '@store/store';

import GridLayoutProvider from '@hooks/gridLayout/GridLayoutProvider';

import LocalizationProvider from '@translations/LocalizationProvider';

import App from './App';

import '@assets/css/index.scss';

Bugsnag.start(bigsnagConfig);

const ErrorBoundary = Bugsnag.getPlugin('react');

const onError = (event) => {
  console.log('bugsnag event: ', event);
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Why removed StrictMode aka React 18 useEffect runs twice:
// Beginning with React 18, when in development mode, the components will be mounted, unmounted, and then mounted once again in StrictMode.
// This only occurs in the development mode; it does not occur in the production mode.
// This was introduced so that in the future, when React decides to offer a feature that allows it to add or delete an area of the UI while still maintaining the state, this will help it do so. For instance, when moving between tabs, maintaining the state of the preceding tab might assist avoid the execution of effects such as API calls that are not essential.

root.render(
  // <StrictMode>
  <ErrorBoundary FallbackComponent={ErrorPage} onError={onError}>
    <Provider store={store}>
      <LocalizationProvider>
        <GridLayoutProvider>
          <App />
        </GridLayoutProvider>
      </LocalizationProvider>
    </Provider>
  </ErrorBoundary>,
  // </StrictMode>
);
