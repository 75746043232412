import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { Card, FormattedPercent } from '@shared/components';
import { useCurrencyIcon } from '@shared/hooks';
import { Button } from '@shared/ui';

import { useSetSidebarPreset } from '@pages/WalletsPage/Staking/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { PERCENT_PRECISION } from '@constants';

import { AvailablePlansPeriodButtons } from '../AvailablePlansPeriodButtons';

import styles from './AvailablePlansCard.module.scss';

const AvailablePlansCard = ({
  availablePlan,
  className,
  customStyles = {},
  currencyIconSize = {
    width: 42,
    height: 42,
  },
  onClick,
  buttonText,
  FooterElement,
  withHover = true, // should buttons change color or no
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const coinSymbol = availablePlan.asset.coinSymbol;

  const { isTabletDown } = useBreakpoints();

  const { toggleStakingSidebar } = useSetSidebarPreset({ coinSymbol });

  const icon = useCurrencyIcon(coinSymbol, currencyIconSize);

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    toggleStakingSidebar();
  };

  return (
    <div className={cx('col-12 col-md-4', className)}>
      <Card
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        customStyles={{ ...styles, ...customStyles }}
      >
        <div className={cx(styles.title_wrapper, customStyles.title_wrapper)}>
          <div className={styles.icon}>{icon}</div>

          <div>
            <div className={cx(styles.title, customStyles.title)}>
              {coinSymbol}
            </div>

            <div className={cx(styles.subtitle, customStyles.subtitle)}>
              {availablePlan.asset.fullName}
            </div>
          </div>
        </div>

        <div className={cx(styles.periods, customStyles.periods)}>
          <AvailablePlansPeriodButtons
            coinSymbol={coinSymbol}
            isSwiperActive
            activePeriod={availablePlan.period.active}
            isAllPeriodsSelected={false}
            isHovered={withHover ? isHovered : undefined}
          />
        </div>

        <div className={cx(styles.estimation, customStyles.estimation)}>
          <FormattedPercent
            percent={availablePlan.estimation}
            precision={PERCENT_PRECISION}
            removeZeroEnd
          />
          %*
          <div className={cx(styles.rewards, customStyles.rewards)}>
            <FormattedMessage
              id={isTabletDown ? 'YearlyRewards' : 'YearlyRewardsWithDivider'}
              values={{ br: <br /> }}
            />
          </div>
        </div>

        <div className="mb-12">
          <Button
            fill
            onClick={handleClick}
            size="l"
            color={
              withHover ? (isHovered ? 'secondary' : 'primary') : 'primary'
            }
            inversion={withHover ? isHovered : false}
            customStyles={{ button: [customStyles.stake_button] }}
          >
            {buttonText ?? <FormattedMessage id="Stake" />}
          </Button>
        </div>

        <div className={styles.footer}>
          {FooterElement ?? (
            <>
              * <FormattedMessage id="YearlyRewards" />
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default memo(AvailablePlansCard);
