import { format } from '@utils/numbers';

export const getTpPriceFieldRules = (
  intl,
  currentPrice,
  currentMarket,
  isBuyMode,
) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      tpPrice: (value) => {
        const currentPriceValue = Number(currentPrice) || 0;

        if (!isBuyMode && Number(value) <= currentPriceValue) {
          return intl.formatMessage(
            { id: 'PriceHigherThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        if (isBuyMode && Number(value) >= currentPriceValue) {
          return intl.formatMessage(
            { id: 'PriceLowerThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        return true;
      },
    },
  };
};

export const getSlPriceFieldRules = (
  intl,
  currentPrice,
  currentMarket,
  isBuyMode,
) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      slPrice: (value) => {
        const currentPriceValue = Number(currentPrice) || 0;

        if (isBuyMode && Number(value) <= currentPriceValue) {
          return intl.formatMessage(
            { id: 'PriceHigherThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        if (!isBuyMode && Number(value) >= currentPriceValue) {
          return intl.formatMessage(
            { id: 'PriceLowerThanCurrentPrice' },
            {
              marketPrice: format(currentPriceValue, {
                precision: currentMarket.quote_precision_format,
              }),
              coin: currentMarket.quote_coin_symbol.toUpperCase(),
            },
          );
        }

        return true;
      },
    },
  };
};
