import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FormProvider, useForm } from 'react-hook-form';

import { ButtonGroup, FormActions } from '@shared/components';
import { Loader } from '@shared/ui';

import SideBar from '@components/SideBar';
import {
  SideBarDescription,
  SideBarLabel,
} from '@components/SideBar/components';

import {
  TransferRecipients,
  VisaCardBlock,
} from '@pages/WalletsPage/VisaCardDetails/components';
import { SPOT_ACCOUNT_FORM_FIELD_NAMES } from '@pages/WalletsPage/VisaCardDetails/components/sidebars/components/SpotAccountForm/SpotAccountForm';
import { TRANSFER_TO_USER_FIELD_NAMES } from '@pages/WalletsPage/VisaCardDetails/components/sidebars/SideBarTransferMoney/components/TransferToUser/TransferToUser';
import { TRANSFER_RECIPIENTS } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.constants';
import {
  transferRecipientSelector,
  visaCardSelector,
} from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';
import { visaCardDetailsActions } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.slice';

import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { CURRENCY_LIST, sidebarIds } from '@constants';

const SideBarTransferMoney = () => {
  const intl = useIntl();

  const card = useSelector(visaCardSelector);
  const transferRecipient = useSelector(transferRecipientSelector);

  const { isOpen, closeSidebar } = useSidebar(sidebarIds.VISA_TRANSFER_MONEY);
  const { openSidebar: openConfirmTransferSidebar } = useSidebar(
    sidebarIds.CONFIRM_TRANSFER_MONEY,
  );

  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: {
      [SPOT_ACCOUNT_FORM_FIELD_NAMES.AMOUNT]: '',
      [SPOT_ACCOUNT_FORM_FIELD_NAMES.PERCENT]: 0,
      [SPOT_ACCOUNT_FORM_FIELD_NAMES.ACCOUNT]: '',
      [TRANSFER_TO_USER_FIELD_NAMES.AMOUNT]: '',
    },
  });

  const { reset, handleSubmit } = methods;

  const handleCloseSideBar = () => {
    reset();
    closeSidebar();
  };

  const transferRecipients = useMemo(
    () => [
      {
        id: TRANSFER_RECIPIENTS.SPOT_ACCOUNT,
        value: <FormattedMessage id="SpotAccount" />,
      },
      { id: TRANSFER_RECIPIENTS.USERS, value: <FormattedMessage id="Users" /> },
    ],
    [],
  );

  const handleSelectTransferRecipient = ({ id: recipient }) => {
    dispatch(visaCardDetailsActions.setTransferRecipient(recipient));
  };

  const handleTransfer = (values) => {
    let transferDetails = {};

    if (transferRecipient === TRANSFER_RECIPIENTS.SPOT_ACCOUNT) {
      const userAccount = values[SPOT_ACCOUNT_FORM_FIELD_NAMES.ACCOUNT];
      const amount = format(values[SPOT_ACCOUNT_FORM_FIELD_NAMES.AMOUNT], {
        precision: values[SPOT_ACCOUNT_FORM_FIELD_NAMES.CURRENCY].digits,
      });
      const currency =
        values[SPOT_ACCOUNT_FORM_FIELD_NAMES.CURRENCY].value.toUpperCase();

      transferDetails = {
        userAccount,
        amount,
        currency,
      };

      dispatch(
        visaCardDetailsActions.setSpotAccountFormDetails(transferDetails),
      );
    }

    if (transferRecipient === TRANSFER_RECIPIENTS.USERS) {
      const amount = format(values[TRANSFER_TO_USER_FIELD_NAMES.AMOUNT], {
        precision: values[TRANSFER_TO_USER_FIELD_NAMES.CURRENCY].digits,
      });
      const currencyValue =
        values[TRANSFER_TO_USER_FIELD_NAMES.CURRENCY].value.toUpperCase();
      const currency =
        CURRENCY_LIST.find((cur) => cur.value === currencyValue)?.symbol ||
        currencyValue;
      const message = values[TRANSFER_TO_USER_FIELD_NAMES.MESSAGE];

      transferDetails = { amount, currency, message };

      dispatch(
        visaCardDetailsActions.setConfirmTransferMoneyDetails(transferDetails),
      );
    }

    closeSidebar();
    openConfirmTransferSidebar();
  };

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleCloseSideBar}
      withCloseButton
      title={intl.formatMessage({ id: 'TransferMoney' })}
    >
      {!card.type ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit(handleTransfer)}
          className="h-100 d-flex flex-column align-items-start justify-content-between"
        >
          <FormProvider {...methods}>
            <div>
              <div className="mt-12">
                <SideBarDescription>
                  <FormattedMessage id="TransferMoneyDescription" />
                </SideBarDescription>
              </div>

              <div className="mt-30">
                <SideBarLabel>
                  <FormattedMessage id="TransferFrom" />:
                </SideBarLabel>

                <VisaCardBlock />
              </div>

              <div className="mt-30">
                <SideBarLabel>
                  <FormattedMessage id="TransferTo" />:
                </SideBarLabel>

                <div className="mt-16">
                  <ButtonGroup
                    color="secondary"
                    inversion
                    variant="outlined"
                    items={transferRecipients}
                    onClick={handleSelectTransferRecipient}
                    selectedItemId={transferRecipient}
                  />
                </div>
              </div>

              <div className="mt-30">
                <TransferRecipients />
              </div>
            </div>

            <div className="w-100 pt-40 pb-80">
              <FormActions
                inversion
                submitText={
                  transferRecipient === TRANSFER_RECIPIENTS.SPOT_ACCOUNT ? (
                    <FormattedMessage id="Transfer" />
                  ) : (
                    <FormattedMessage id="Confirm" />
                  )
                }
                onCancel={handleCloseSideBar}
              />
            </div>
          </FormProvider>
        </form>
      )}
    </SideBar>
  );
};

export default SideBarTransferMoney;
