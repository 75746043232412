import cx from 'classnames';

import s from './Badge.module.scss';

const Badge = ({
  children,
  size = 'l',
  color = 'primary',
  variant = 'outlined',
  isCircle,
  inversion,
  customStyles = {},
}) => {
  return (
    <div
      className={cx(
        s.badge,
        customStyles.badge,
        s[size],
        s[color],
        s[variant],
        {
          [s.is_circle]: isCircle,
          [s.inversion]: inversion,
        },
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
