import { useRef, useLayoutEffect } from 'react';

const useRunAfterUpdate = () => {
  const afterPaintRef = useRef(null);

  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });

  return (fn) => (afterPaintRef.current = fn);
};

export default useRunAfterUpdate;
