import React, { memo } from 'react';

import classNames from 'classnames';

import styles from './Widget.module.scss';

const Widget = ({ children, className }) => {
  return (
    <div className={classNames('widget', styles.widget, className)}>
      {children}
    </div>
  );
};

export default memo(Widget);
