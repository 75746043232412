import { createSelector } from '@reduxjs/toolkit';

import { PERMISSION_STATE } from '@constants';

import { ApiSelectorsUtils } from '../utils';

const defaultData = [];

const dataSelector = ApiSelectorsUtils.createDataSelector(defaultData);

const apiKeysListSelector = createSelector(dataSelector, (apiKeys) => {
  const defaultPermission = PERMISSION_STATE.READ_ONLY;

  return apiKeys.map((apiKey) => {
    const allowPermissions = Object.keys(apiKey.permissions).reduce(
      (acc, key, index, self) => {
        if (apiKey.permissions[key] !== PERMISSION_STATE.NOT_ALLOWED) {
          acc.push(key.split('_')[0]);
        }

        if (index === self.length - 1 && !acc.length) {
          acc.push(defaultPermission);
        }

        return acc;
      },
      [],
    );

    return {
      name: {
        name: apiKey.name,
        id: apiKey.id,
        apiKey,
      },
      permissions: allowPermissions,
      createdAt: apiKey.created_at,
      actions: apiKey,
    };
  });
});

export const managementApiKeySelectors = {
  apiKeysListSelector,
};
