import { memo, useRef } from 'react';

import {
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import useElementResize from '@hooks/useElementResize';

import { ITEMS_PER_PAGE, NOOP } from '@constants';

import { TablePagination } from './components/TableFooter';
import TableDefaultBody from './TableDefaultBody';
import TableDefaultHeader from './TableDefaultHeader';

const GridTableDefault = ({
  tableRef,
  id,
  data,
  columns,
  className,
  customStyles,
  style = {},
  tableHeight,
  isShowPagination = false,
  isShowPaginationArrows = true,
  isShowColumnsOnEmptyData = true,
  isMinimizedPagination,
  headerSize, // s, m, l
  inversion,
  underlinedRows,
  meta = { count: 0, page: 1, limit: ITEMS_PER_PAGE },
  sortingState = [],
  collapseHeader = false,
  onPaginationChange = NOOP,
  handleSortingChange = NOOP,
  ...rest
}) => {
  const tablePaginationRef = useRef();
  const tableHeaderRef = useRef();

  const { height: tableHeaderHeight } = useElementResize(tableHeaderRef);
  const { height: tablePaginationHeight } = useElementResize(
    tablePaginationRef,
    { isBorderBoxSize: true },
  );

  const scrollableHeight = tableHeight
    ? tableHeight - tablePaginationHeight
    : undefined;

  const bodyHeight = scrollableHeight
    ? scrollableHeight - tableHeaderHeight
    : undefined;

  const pageCount = Math.ceil(meta.count / meta.limit);
  const isNoData = !data.length;

  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      sorting: sortingState,
      pagination: {
        pageIndex: meta.page - 1,
        pageSize: meta.limit,
      },
    },
    onSortingChange: handleSortingChange,
    onPaginationChange,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualSorting: true,
    manualPagination: true,
  });

  if (tableRef) {
    tableRef.current = table;
  }

  return (
    <div
      className={className}
      style={{ height: isNoData ? 'auto' : tableHeight, ...style }}
    >
      <div
        style={{
          height: isNoData ? 'auto' : scrollableHeight,
          overflowY: 'auto',
        }}
      >
        <table className="table-default">
          {(!isNoData || (isNoData && isShowColumnsOnEmptyData)) && (
            <TableDefaultHeader
              ref={tableHeaderRef}
              id={id}
              table={table}
              collapseHeader={collapseHeader}
              sortingState={sortingState}
              headerSize={headerSize}
              inversion={inversion}
              underlinedRows={underlinedRows}
            />
          )}

          <TableDefaultBody
            id={id}
            table={table}
            data={data}
            tableHeight={bodyHeight}
            inversion={inversion}
            underlinedRows={underlinedRows}
            {...rest}
          />
        </table>
      </div>

      <div ref={tablePaginationRef}>
        {isShowPagination && (
          <TablePagination
            table={table}
            meta={meta}
            customStyles={customStyles}
            isShowPaginationArrows={isShowPaginationArrows}
            isMinimizedPagination={isMinimizedPagination}
          />
        )}
      </div>
    </div>
  );
};

export default memo(GridTableDefault);
