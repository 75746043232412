import { memo, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Webcam from 'react-webcam';

import { Button } from '@shared/ui';

import { NOOP } from '@constants';

import styles from './WebcamBox.module.scss';

const WebcamBox = ({ takeSelfie, resetSelfie = NOOP }) => {
  const intl = useIntl();

  const [isSelfieCapturingOn, setIsSelfieCapturingOn] = useState(false);
  const [isCameraAllowedAndWorks, setIsCameraAllowedAndWorks] = useState(false);
  const [webCameraError, setWebCameraError] = useState();
  const [selfieImageBase64, setSelfieImageBase64] = useState();

  const webcamRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(checkIsCameraAllowedAndWorks, 1000);

    if (isCameraAllowedAndWorks) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isCameraAllowedAndWorks]);

  const webcamWidth =
    containerRef.current?.getBoundingClientRect().width ?? 400;

  const checkIsCameraAllowedAndWorks = async () => {
    if (isCameraAllowedAndWorks) return;

    // Check for browser compatibility
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Check for webcam availability
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          setIsCameraAllowedAndWorks(true);

          stream.getTracks().forEach((track) => track.stop());
        })
        .catch(function (error) {
          setWebCameraError(
            intl.formatMessage(
              { id: 'kycSelfieWarning' },
              { error: error.message },
            ),
          );
          setIsCameraAllowedAndWorks(false);
        });
    } else {
      setWebCameraError(intl.formatMessage({ id: 'BrowserNotSupportsWebcam' }));
      setIsCameraAllowedAndWorks(false);
    }
  };

  const handleTakeSelfie = () => {
    const screenshot = webcamRef.current?.getScreenshot();

    setSelfieImageBase64(screenshot);
    takeSelfie(screenshot);
  };

  const handleResetSelfie = () => {
    setSelfieImageBase64(null);
    resetSelfie(null);
  };

  const renderInstructions = () => (
    <div className={styles.instruction}>
      <p>
        <FormattedMessage id="kycSelfieInstruction" />
      </p>
      <div className="mt-16">
        <Button
          type="button"
          inversion
          variant="outlined"
          onClick={() => setIsSelfieCapturingOn((prevState) => !prevState)}
        >
          <FormattedMessage id="Start" />
        </Button>
      </div>
    </div>
  );

  const renderWebcamNotWorkingWarning = () => (
    <div className={styles.instruction}>
      <p>{webCameraError}</p>
    </div>
  );

  const renderWebcam = () => (
    <>
      <Webcam
        ref={webcamRef}
        width={webcamWidth}
        screenshotFormat="image/jpeg"
        audio={false}
      />
      <div className="mt-20">
        <Button
          type="button"
          inversion
          variant="text"
          onClick={handleTakeSelfie}
        >
          <FormattedMessage id="takeSelfie" />
        </Button>
      </div>
    </>
  );

  const renderPreview = () => (
    <>
      <img src={selfieImageBase64} alt="selfie" />
      <div className="mt-20">
        <Button
          type="button"
          inversion
          variant="text"
          onClick={handleResetSelfie}
        >
          <FormattedMessage id="takeAnotherSelfie" />
        </Button>
      </div>
    </>
  );

  return (
    <div className={styles.webcam_container} ref={containerRef}>
      {isSelfieCapturingOn ? (
        isCameraAllowedAndWorks ? (
          <div className={styles.webcam_wrapper}>
            {!selfieImageBase64 ? renderWebcam() : renderPreview()}
          </div>
        ) : (
          renderWebcamNotWorkingWarning()
        )
      ) : (
        renderInstructions()
      )}
    </div>
  );
};

export default memo(WebcamBox);
