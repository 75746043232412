import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { CARD_TYPE_MAP } from '@shared/constants';

import styles from './CardItem.module.scss';

const CardItem = ({ cardType, iconWidth, iconHeight, customStyles = {} }) => {
  const CardIconSvg = CARD_TYPE_MAP[cardType].icon;

  return (
    <div className={classNames(styles.card_item, customStyles.card_item)}>
      <CardIconSvg width={iconWidth} height={iconHeight} />

      <FormattedMessage id={CARD_TYPE_MAP[cardType].intl} />
    </div>
  );
};

export default memo(CardItem);
