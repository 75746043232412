import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  getMonthsMapped,
  getYearsMapped,
} from '@shared/components/DatePicker/datePicker.utils';
import { Select } from '@shared/ui';

import s from './MonthElement.module.scss';

const MonthElement = ({
  month,
  onMonthSelect,
  onYearSelect,
  yearsAndMonthsSelectable,
}) => {
  const intl = useIntl();

  const monthOptions = useMemo(() => getMonthsMapped({ intl }), [intl]);

  const yearOptions = useMemo(() => getYearsMapped(), []);

  const handleMonthChange = ({ value }) => {
    onMonthSelect(month, value);
  };

  const handleYearChange = ({ value }) => {
    onYearSelect(month, value);
  };

  const selectedMonth = monthOptions[new Date(month?._d).getMonth()];

  const selectedYear = useMemo(() => {
    const selectedYearValue = new Date(month?._d).getFullYear();

    return yearOptions.find(({ value }) => value === selectedYearValue);
  }, [yearOptions, month]);

  if (yearsAndMonthsSelectable) {
    return (
      <div className={s.container}>
        <div className={s.months_selector}>
          <Select
            value={selectedMonth}
            options={monthOptions}
            onChange={handleMonthChange}
            isSearchable={false}
            size="m"
          />
        </div>

        <div className={s.years_selector}>
          <Select
            value={selectedYear}
            defaultValue={yearOptions[0]}
            options={yearOptions}
            onChange={handleYearChange}
            isSearchable={false}
            size="m"
          />
        </div>
      </div>
    );
  }

  return <span className={s.month}>{month.format('MMMM YYYY')}</span>;
};

export default MonthElement;
