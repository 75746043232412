import { useMemo } from 'react';

import { useGetArchivedBotsQuery } from '@api/botsAPI';
import { BotsApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isSuccessArchivedBots: result.isSuccess,
    isFetchingArchivedBots: result.isFetching,
    refetchArchivedBots: result.refetch,
  };

  dataResult.archivedBots = BotsApiSelectors.archivedBotsSelector(result);
  dataResult.liquidatedBots = BotsApiSelectors.liquidatedBotsSelector(result);

  return dataResult;
};

const useArchivedBots = (args, options) => {
  const result = useGetArchivedBotsQuery(args, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useArchivedBots;
