import React, { useMemo } from 'react';

import { getAsset } from '@utils';

import { CRYPTO_COIN_LIST } from '@constants';

const useCurrencyIcon = (currency, iconProps, iconAccessor = 'icon') => {
  const icon = useMemo(() => {
    const IconComponent =
      getAsset(
        CRYPTO_COIN_LIST,
        currency?.toLowerCase(),
        'coin',
        iconAccessor,
      ) ?? CRYPTO_COIN_LIST[0][iconAccessor];

    return React.cloneElement(IconComponent, iconProps);
  }, [currency, iconAccessor, iconProps]);

  return icon;
};

export default useCurrencyIcon;
