import React, { memo, useMemo } from 'react';

import cx from 'classnames';

import { useCurrencyIcon } from '@shared/hooks';

import { useCoinsInfo } from '@store/api/hooks';

import TruncatedText from '../TruncatedText/TruncatedText';

import styles from './CurrencyItem.module.scss';

const CurrencyItem = ({
  coinSymbol,
  isShowFullName = true,
  iconWidth = 24,
  iconHeight = 24,
  iconAccessor = 'iconOutline',
  className,
  paymentMethod, // need to show payment method in brackets near the currency symbol (USD (ADVCASH))
  inversion,
  customStyles = {},
  isShowCurrencyIcon = true,
  isShowSymbol = true,
}) => {
  const { coinsInfo } = useCoinsInfo();

  const currencyIcon = useCurrencyIcon(
    coinSymbol,
    {
      width: iconWidth,
      height: iconHeight,
      style: {
        minWidth: iconWidth,
        minHeight: iconHeight,
        maxWidth: iconWidth,
        maxHeight: iconHeight,
      },
    },
    iconAccessor,
  );

  const fullCurrencyName = useMemo(() => {
    if (!isShowFullName) return '';

    return coinsInfo?.[coinSymbol]?.name;
  }, [coinSymbol, coinsInfo, isShowFullName]);

  return (
    <div
      id={`currency-item-${coinSymbol}`}
      // TODO: rename customStyles.container to customStyles.currency_item and styles too
      className={cx(styles.container, className, customStyles.container, {
        [styles.inversion]: inversion,
      })}
    >
      {isShowCurrencyIcon && currencyIcon}

      {isShowSymbol && (
        <TruncatedText className={customStyles.currency_symbol}>
          <span>
            <span
              className={cx(
                styles.bold_text,
                styles.uppercase,
                customStyles.bold_text,
              )}
            >
              {coinSymbol}
            </span>

            {paymentMethod && (
              <span className={styles.bold_text}> ({paymentMethod})</span>
            )}
          </span>
        </TruncatedText>
      )}

      {isShowFullName && (
        <TruncatedText className={customStyles.currency_full_name}>
          <span className={cx(styles.thin_text, customStyles.thin_text)}>
            {fullCurrencyName}
          </span>
        </TruncatedText>
      )}
    </div>
  );
};

export default memo(CurrencyItem);
