import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import cx from 'classnames';

import { FormattedCurrency } from '@shared/components';

import { AllCryptoMarketsSelectors } from '@pages/landings/MarketsPage/AllCryptoMarkets/allCryptoMarkets.selectors';

import { useCoinsRates } from '@api/hooks';

import styles from './VolumeCell.module.scss';

const VolumeCell = ({
  currency,
  volume,
  customStyles = {},
  isShowConvertedVolume,
  isShowOnlyConvertedVolume,
}) => {
  const selectedCurrency = useSelector(
    AllCryptoMarketsSelectors.selectedCurrencySelector,
  );

  const selectedCurrencySymbol = useSelector(
    AllCryptoMarketsSelectors.selectedCurrencySymbolSelector,
  );

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: currency,
    toCoinSymbol: selectedCurrency,
  });

  const ConvertedVolume = (
    <>
      {selectedCurrencySymbol}
      <FormattedCurrency
        currency={selectedCurrency}
        value={volume * ratesByPair}
      />
    </>
  );

  if (isShowOnlyConvertedVolume) {
    return (
      <div className={cx(styles.volume, customStyles.volume)}>
        {ConvertedVolume}
      </div>
    );
  }

  return (
    <div className={cx(styles.volume, customStyles.volume)}>
      {volume}
      {isShowConvertedVolume && (
        <>
          {' '}
          /
          <span
            className={cx(
              styles.converted_volume,
              customStyles.converted_volume,
            )}
          >
            {ConvertedVolume}
          </span>
        </>
      )}
    </div>
  );
};

export default memo(VolumeCell);
