import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import classNames from 'classnames';

import { ButtonArrow } from '@shared/ui';

import { LandingPageContainer } from '@pages/landings/components';

import useBreakpoints from '@hooks/useBreakpoints';

import { AUTH_ROUTES } from '../../../../routes.constants';

import styles from './LandingGetStarted.module.scss';

const LandingGetStarted = ({ title, customStyles = {} }) => {
  const { isTabletDown, isLaptopDown } = useBreakpoints();

  const buttonSize = useMemo(() => {
    if (isTabletDown) return 'l';
    if (isLaptopDown) return 'xl';
    return 'xxl';
  }, [isLaptopDown, isTabletDown]);
  return (
    <LandingPageContainer>
      <div className={classNames('row', styles.get_started)}>
        <div
          className={classNames(
            'col-12 col-md-8',
            styles.title,
            customStyles.title,
          )}
        >
          {title ? title : <FormattedMessage id="SignUpToday" />}
        </div>

        <div className={classNames('col-12 col-md-4', styles.button_block)}>
          <ButtonArrow
            customStyles={styles}
            navigation={{ to: generatePath(AUTH_ROUTES.REGISTER) }}
            size={buttonSize}
            variant="contained"
            color="secondary"
          >
            <FormattedMessage id="GetStarted" />
          </ButtonArrow>
        </div>
      </div>
    </LandingPageContainer>
  );
};

export default memo(LandingGetStarted);
