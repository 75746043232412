import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useForm, useWatch } from 'react-hook-form';

import { Card } from '@shared/components';
import { Button, PasswordInputController } from '@shared/ui';
import {
  validateConfirmPassword,
  validateNewPassword,
  validatePassword,
} from '@shared/validations';

import { useProfileMutations } from '@api/hooks';

import { ACCOUNT_ROUTES } from 'src/routes.constants';

import styles from './ChangePassword.module.scss';

const ChangePassword = () => {
  const intl = useIntl();

  const { onChangePassword, isChangePasswordLoading } = useProfileMutations();

  const methods = useForm({
    defaultValues: { current: '', newPassword: '', confirmPassword: '' },
  });

  const { control, getValues, handleSubmit } = methods;

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    size: 'l',
  };

  const watchNewPassword = useWatch({ control, name: 'newPassword' });

  const handleFormSubmit = ({ current, newPassword }) => {
    onChangePassword({ current, new: newPassword });
  };

  return (
    <Card
      classNames={[styles.card]}
      title={<FormattedMessage id="CreateNewPassword" />}
    >
      <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={styles.form_container}>
          <div className={styles.input_container}>
            <PasswordInputController
              name="current"
              label={`${intl.formatMessage({
                id: 'CurrentPassword',
              })}:`}
              placeholder={intl.formatMessage({
                id: 'PleaseEnterTheCurrentPassword',
              })}
              rules={{
                required: intl.formatMessage({ id: 'PasswordIsRequired' }),
                validate: { format: validatePassword(intl) },
              }}
              {...commonFieldProps}
              {...methods}
            />
          </div>
          <div className={styles.input_container}>
            <PasswordInputController
              name="newPassword"
              label={`${intl.formatMessage({
                id: 'NewPassword',
              })}:`}
              placeholder={intl.formatMessage({
                id: 'PleaseEnterTheNewPassword',
              })}
              rules={{
                required: intl.formatMessage({ id: 'PasswordIsRequired' }),
                validate: {
                  format: validatePassword(intl),
                  currentPassword: validateNewPassword(getValues, intl),
                },
              }}
              {...commonFieldProps}
              {...methods}
            />
          </div>
          <div className={styles.input_container}>
            <PasswordInputController
              name="confirmPassword"
              label={`${intl.formatMessage({
                id: 'ConfirmNewPassword',
              })}:`}
              placeholder={intl.formatMessage({
                id: 'PleaseConfirmThePassword',
              })}
              rules={{
                required: intl.formatMessage({ id: 'PasswordIsRequired' }),
                validate: {
                  format: validatePassword(intl),
                  confirmPassword: validateConfirmPassword(
                    watchNewPassword,
                    intl,
                  ),
                },
              }}
              {...commonFieldProps}
              {...methods}
            />
          </div>
        </div>

        <Button
          fill
          color="secondary"
          type="submit"
          isLoading={isChangePasswordLoading}
        >
          <FormattedMessage id="Change" />
        </Button>
        <div className="mt-26">
          <Button
            variant="text"
            isLoading={isChangePasswordLoading}
            navigation={{ to: ACCOUNT_ROUTES.PROFILE }}
          >
            <FormattedMessage id="Back" />
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default memo(ChangePassword);
