import React from 'react';
import { createPortal } from 'react-dom';

import classnames from 'classnames';

import Chart, { CIQ } from '@libs/chartiq/Chart';

import CustomChartTemplate from './CustomChartTemplate';
import { getCustomConfig } from './resources';

import styles from './CustomChart.module.scss';

// https://github.com/ChartIQ/chartiq-react-app - React project/guide
// https://live.demo.chartiq.com/#demo.chartiq.com - live demo
// https://jsfiddle.net/user/chartiq/fiddles - demos
// https://documentation.chartiq.com - documentation
// https://documentation.chartiq.com/tutorial-Changelog.html - changelog

export default class CustomChart extends React.Component {
  constructor(props) {
    super(props);

    this.config = { ...getCustomConfig(props), ...props.config };

    this.postInit = this.postInit.bind(this);
    this.toggleFullScreen = this.toggleFullScreen.bind(this);

    this.state = {
      chart: new CIQ.UI.Chart(),
      stx: null,
      uiContext: null,
      isFullScreen: false,
    };
  }

  componentWillUnmount() {
    // Destroy the ChartEngine instance when unloading the component.
    // This will stop internal processes such as quotefeed polling.
    const { stx } = this.state;

    if (stx) {
      stx.destroy();
      stx.draw = () => {};
    }
  }

  componentDidUpdate(prevProps) {
    const { locale, isDarkTheme } = this.props;
    const { stx } = this.state;

    if (locale !== prevProps.locale) {
      CIQ.I18N.setLanguage(stx, locale);
    }

    if (isDarkTheme !== prevProps.isDarkTheme) {
      document
        .querySelector('cq-context')
        .classList[isDarkTheme ? 'add' : 'remove']('ciq-night');

      stx.setThemeSettings();
    }

    return true;
  }

  postInit({ chartEngine, uiContext }) {
    if (this.props.chartInitialized) {
      this.props.chartInitialized({ chartEngine, uiContext });
    }

    CIQ.I18N.setLanguage(chartEngine, this.props.locale);

    document
      .querySelector('cq-context')
      .classList[this.props.isDarkTheme ? 'add' : 'remove']('ciq-night');

    chartEngine.setThemeSettings();

    this.setState({ stx: chartEngine, uiContext: uiContext });

    Object.assign(window, { stx: chartEngine, CIQ });

    chartEngine.prepend('draw', () => {
      const percent = this.props.percentChange ?? 0;

      const color =
        percent >= 0 ? 'rgba(35, 229, 219, 0.12)' : 'rgba(242, 51, 69, 0.18)';

      chartEngine.setStyle('stx_mountain_chart', 'color', color);

      chartEngine.setStyle('stx_mountain_chart', 'borderTopColor', '#fff');
      chartEngine.setStyle('stx_mountain_chart', 'backgroundColor', color);
    });
  }

  toggleFullScreen() {
    const { isMobile } = this.props;
    const { isFullScreen } = this.state;

    if (!isMobile) {
      const chartBlockElement = document.getElementById(this.config.i);

      chartBlockElement.classList[isFullScreen ? 'remove' : 'add']('full-size');
    } else {
      setTimeout(() => {
        this.state.stx.resizeChart();
        this.state.stx.resizeCanvas();
        this.state.stx.draw();
      }, 3000);
    }

    this.setState((state) => ({
      ...state,
      isFullScreen: !state.isFullScreen,
    }));
  }

  render() {
    const {
      children,
      isMobile,
      customStyles = {},
      themeStyles = {},
    } = this.props;
    const { stx, isFullScreen } = this.state;

    let template = (
      <CustomChartTemplate
        stx={stx}
        isFullScreen={isFullScreen}
        toggleFullScreen={this.toggleFullScreen}
      />
    );

    const childrenCount = React.Children.count(children);

    if (childrenCount === 1) {
      template = React.cloneElement(children, {
        stx,
        isFullScreen,
        toggleFullScreen: this.toggleFullScreen,
      });
    } else if (childrenCount > 1) {
      template = children;
    }

    const layoutHeaderHeight = themeStyles['layout-header-height'];

    return (
      <>
        <div
          id={this.config.chartId}
          className={classnames(
            styles.chart_container,
            customStyles.chart_container,
          )}
        >
          <Chart config={this.config} chartInitialized={this.postInit}>
            {template}
          </Chart>
        </div>

        {isFullScreen &&
          isMobile &&
          createPortal(
            <div
              id={this.config.chartId}
              className={classnames(
                styles.chart_container,
                customStyles.chart_container,
              )}
              style={{
                position: 'fixed',
                width: '100%',
                zIndex: 1000,
                top: layoutHeaderHeight,
                height: `${
                  window.innerHeight - Number.parseFloat(layoutHeaderHeight)
                }px`,
              }}
            >
              <Chart config={this.config} chartInitialized={this.postInit}>
                {template}
              </Chart>
            </div>,
            document.getElementById('modal-container'),
          )}
      </>
    );
  }
}
