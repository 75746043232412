import { differenceInDays, endOfDay, startOfDay } from 'date-fns';

const useGetDifferenceBetweenDays = (startDate, endDate) => {
  const startDateInMs = startOfDay(new Date(startDate)).getTime();
  const expiredDateInMs = endOfDay(new Date(endDate)).getTime();
  const todayDateInMs = startOfDay(new Date()).getTime();

  const totalDays = differenceInDays(expiredDateInMs, startDateInMs);

  const daysBetweenTotalAndToday = differenceInDays(
    expiredDateInMs,
    todayDateInMs,
  );

  let elapsedDays = totalDays - daysBetweenTotalAndToday;

  if (elapsedDays > totalDays) {
    elapsedDays = totalDays;
  }

  const percentElapsedDays =
    daysBetweenTotalAndToday >= 1 ? (elapsedDays / totalDays) * 100 : 100;

  return {
    elapsedDays,
    totalDays,
    percentElapsedDays,
    todayDateInMs,
    startDateInMs,
  };
};

export default useGetDifferenceBetweenDays;
