import { useMemo } from 'react';

import { WalletsApiSelectors } from '@store/api/selectors';
import { useGetWithdrawLimitsQuery } from '@store/api/walletsAPI';

import useKYCLevelsStatuses from './useKYCLevelsStatuses';

const useKYCWithdrawLimits = () => {
  const { withdrawLimits } = useGetWithdrawLimitsQuery(undefined, {
    selectFromResult: (result) => ({
      withdrawLimits: WalletsApiSelectors.withdrawLimitsSelector(result),
    }),
  });

  const { kycLevelsStatuses } = useKYCLevelsStatuses();

  const kycLevelsLimits = useMemo(() => {
    return kycLevelsStatuses.map(({ value, isActive }) => {
      const withdrawLimit = withdrawLimits.find(
        (item) => item.feature === `withdraw_limit_level_${value}`,
      );

      return {
        kycLevel: value,
        isCurrentKycLevel: isActive,
        withdrawLimit: Number(withdrawLimit?.value) || 0,
      };
    });
  }, [kycLevelsStatuses, withdrawLimits]);

  return kycLevelsLimits;
};

export default useKYCWithdrawLimits;
