import { createSlice } from '@reduxjs/toolkit';

import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

const initialState = {
  [TABLE_FILTERS_NAMES.SEARCHBAR]: '',
};

const historyLaunchpadSlice = createSlice({
  name: 'historyLaunchpad',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state[TABLE_FILTERS_NAMES.SEARCHBAR] = action.payload;
    },
    resetSearch: (state, action) => {
      state.search = initialState.search;
    },
  },
});

const historyLaunchpadActions = historyLaunchpadSlice.actions;
const historyLaunchpadReducer = historyLaunchpadSlice.reducer;

export { historyLaunchpadActions, historyLaunchpadReducer };

export default historyLaunchpadSlice;
