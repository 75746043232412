import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import cx from 'classnames';

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
} from '@shared/components';

import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';
import {
  CardName,
  CardRequisites,
  ChangePin,
  CloseCard,
  SpendingLimit,
} from '@pages/WalletsPage/VisaCardSettings/components';
import { MenuItemList } from '@pages/WalletsPage/VisaCardSettings/components/SettingsMenu/components';
import { VISA_CARD_SETTINGS_IDS } from '@pages/WalletsPage/VisaCardSettings/VisaCardSettings.constants';
import { selectedSettingIdSelector } from '@pages/WalletsPage/VisaCardSettings/VisaCardSettings.selectors';

import { currencySelector } from '@store/settings/settingsSelectors';

import useBreakpoints from '@hooks/useBreakpoints';

import { format } from '@utils/numbers';

import { FIAT_PRECISION } from '@constants';

import { ReactComponent as CreditCardIconSVG } from '@icons/credit-card-icon.svg';
import { ReactComponent as CrossIconSVG } from '@icons/cross-icon.svg';
import { ReactComponent as InfoIconSVG } from '@icons/info-icon.svg';
import { ReactComponent as MoneyIconSVG } from '@icons/money-icon.svg';
import { ReactComponent as PinCodeIconSVG } from '@icons/pin-code-icon.svg';

import s from './SettingsMenu.module.scss';

const SettingsMenu = () => {
  const card = useSelector(visaCardSelector);
  const currency = useSelector(currencySelector);
  const selectedSettingId = useSelector(selectedSettingIdSelector);

  const { isTabletDown } = useBreakpoints();

  const mainSettings = useMemo(
    () => [
      {
        id: VISA_CARD_SETTINGS_IDS.SPENDING_LIMIT,
        icon: (
          <div className={s.money_icon}>
            <MoneyIconSVG />
          </div>
        ),
        title: <FormattedMessage id="SpendingLimit" />,
        additionalInfo: (
          <div className="fs-12">
            {format(card.spendingLimit, {
              precision: FIAT_PRECISION,
            })}{' '}
            {currency.symbol}
          </div>
        ),
        content: <SpendingLimit />,
      },
      {
        id: VISA_CARD_SETTINGS_IDS.CARD_REQUISITES,
        icon: (
          <div className={s.info_icon}>
            <InfoIconSVG />
          </div>
        ),
        title: <FormattedMessage id="CardRequisites" />,
        content: <CardRequisites />,
      },
      {
        id: VISA_CARD_SETTINGS_IDS.CARD_NAME,
        icon: (
          <div className={s.credit_card_icon}>
            <CreditCardIconSVG />
          </div>
        ),
        title: <FormattedMessage id="CardName" />,
        additionalInfo: (
          <Tag color="green" title={<FormattedMessage id="New" />} />
        ),
        content: <CardName />,
      },
    ],
    [card.spendingLimit, currency.symbol],
  );

  const securitySettings = useMemo(
    () => [
      {
        id: VISA_CARD_SETTINGS_IDS.CHANGE_PIN,
        icon: (
          <div className={s.pin_code_icon}>
            <PinCodeIconSVG />
          </div>
        ),
        title: <FormattedMessage id="ChangeYourPin" />,
        content: <ChangePin />,
      },
      {
        id: VISA_CARD_SETTINGS_IDS.CLOSE_CARD,
        icon: (
          <div className={s.cross_icon}>
            <CrossIconSVG />
          </div>
        ),
        title: <FormattedMessage id="CloseCard" />,
        content: <CloseCard />,
      },
    ],
    [],
  );

  const allSettings = useMemo(
    () => [...mainSettings, ...securitySettings],
    [mainSettings, securitySettings],
  );

  if (isTabletDown) {
    return (
      <Tabs>
        <TabList>
          {allSettings.map((setting) => (
            <Tab key={`tab-${setting.id}`}>
              <div className={s.setting_tab}>{setting.icon}</div>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {allSettings.map((setting) => (
            <TabPanel key={`tab-panel-${setting.id}`}>
              <div className={s.setting_panel}>{setting.content}</div>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    );
  }

  return (
    <div className="row">
      <div className="col-4">
        <div className={s.settings_menu}>
          <div className={s.menu_label}>
            <FormattedMessage id="Main" />
          </div>
          <MenuItemList items={mainSettings} />
          <div className={s.menu_label}>
            <FormattedMessage id="Security" />
          </div>
          <MenuItemList items={securitySettings} />
        </div>
      </div>
      <div
        className={cx(s.setting_content_wrapper, {
          'col-6': selectedSettingId !== VISA_CARD_SETTINGS_IDS.CHANGE_PIN,
          'col-4': selectedSettingId === VISA_CARD_SETTINGS_IDS.CHANGE_PIN,
        })}
      >
        <div className={s.setting_content}>
          {
            allSettings.find((setting) => setting.id === selectedSettingId)
              ?.content
          }
        </div>
      </div>
    </div>
  );
};

export default SettingsMenu;
