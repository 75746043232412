import React, { memo, useId, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Table from '@components/gridTables/Table';

import { useTransactionsHistoryTable } from '@pages/WalletsPage/hooks';
import { TransactionHistoryTableColumns } from '@pages/WalletsPage/TransactionHistory/components';

import useBreakpoints from '@hooks/useBreakpoints';

import { TransactionHistorySelectors } from '../../transactionHistory.selectors';
import { transactionHistoryActions } from '../../transactionHistory.slice';

const TransactionHistoryTable = () => {
  const id = useId();
  const dispatch = useDispatch();

  const { isTabletDown } = useBreakpoints();

  const pagination = useSelector(
    TransactionHistorySelectors.paginationSelector,
  );

  const {
    transactionsHistoryData,
    transactionsHistoryMeta,
    isTransactionsHistoryTableSuccess,
  } = useTransactionsHistoryTable();

  const columns = useMemo(
    () => TransactionHistoryTableColumns({ isSwipeableTable: isTabletDown }),
    [isTabletDown],
  );

  const handlePaginationChange = (updater) => {
    const newState = updater(pagination);

    dispatch(transactionHistoryActions.setPagination(newState));
  };

  return (
    <Table
      id={id}
      columns={columns}
      meta={transactionsHistoryMeta}
      data={transactionsHistoryData}
      wideRowsL
      isSwipeable={isTabletDown}
      isShowPagination
      onPaginationChange={handlePaginationChange}
      isLoading={!isTransactionsHistoryTableSuccess}
      className="account-tables-swipe"
      swipeableTableProps={{
        stickyColumns: 1,
        breakpoints: [
          [600, 2],
          [700, 3],
          [900, 4],
          [1024, 5],
        ],
      }}
    />
  );
};

export default memo(TransactionHistoryTable);
