import React, { memo, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@components/Button';
import Modal from '@components/modals/Modal';

import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import useGridLayout from '@hooks/gridLayout/useGridLayout';
import useModal from '@hooks/useModal/useModal';
import useNavigationBlocker from '@hooks/useNavigationBlocker';

import { NOOP, modalsIds } from '@constants';

const ConfirmLeavingPageModal = () => {
  const isCancelNavigationRef = useRef(true); // closeModal runs after onClose/onSubmit actions, so we should to have flag to control when blocker.reset() is necessary

  const { modal, isOpen, openModal, closeModal, resetModal } = useModal(
    modalsIds.CONFIRM_LEAVING_PAGE,
  );
  const { openModal: openSaveTemplateModal } = useModal(
    modalsIds.SAVE_TEMPLATE,
  );

  const { onLeavePage = NOOP, onSaveChanges = NOOP } = modal.params;

  const isGuest = useSelector(isGuestUserSelector);

  const intl = useIntl();

  const { isLayoutHasChanged, resetLayoutChanges, lockGridLayout } =
    useGridLayout();

  const blocker = useNavigationBlocker({ isBlocking: isLayoutHasChanged });

  const handleClose = () => {
    closeModal();

    if (isCancelNavigationRef.current) {
      blocker.reset();
    }
  };

  const handleLeavePage = async () => {
    resetLayoutChanges();

    // needs to run lock after selectTemplateById setTimeout in the resetLayoutChanges function
    setTimeout(() => {
      lockGridLayout(true);
    }, 0);

    isCancelNavigationRef.current = false;

    closeModal();

    onLeavePage();

    blocker.proceed();
  };

  const handleSaveChanges = async () => {
    isCancelNavigationRef.current = false;

    closeModal();

    openSaveTemplateModal({
      onClose: () => {
        if (!isCancelNavigationRef.current) {
          blocker.reset();
        }

        isCancelNavigationRef.current = true;
      },
      onSubmit: () => {
        lockGridLayout(true);

        onSaveChanges();

        blocker.proceed();

        isCancelNavigationRef.current = true;
      },
    });
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      openModal();
    }
  }, [blocker.state]);

  return (
    <Modal
      className="modal-sm"
      visible={isOpen}
      onClose={handleClose}
      afterClose={resetModal}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">
            <FormattedMessage id="UnsavedChanges" />
          </div>
        </div>
      }
      footer={
        <div className="d-flex justify-content-between">
          <Button
            fill
            primary
            rounded
            withTooltip={isGuest}
            disabled={isGuest}
            onClick={handleSaveChanges}
            tooltipConfig={{
              content: intl.formatMessage({
                id: 'LoginRequired',
              }),
            }}
          >
            <FormattedMessage id="Yes" />
          </Button>

          <Button
            fill
            secondary
            rounded
            onClick={handleLeavePage}
            className="ms-10"
          >
            <FormattedMessage id="No" />
          </Button>
        </div>
      }
    >
      <FormattedMessage id="UnsavedChangesMessage" />
    </Modal>
  );
};

export default memo(ConfirmLeavingPageModal);
