import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Card, FORM_ACTION_ORIENTATION } from '@shared/components';
import FormActions from '@shared/components/FormActions/FormActions';
import { PasswordInputController } from '@shared/ui';

import { useProfileMutations } from '@api/hooks';

import styles from './EnableAntiPhishingCode.module.scss';

const EnableAntiPhishingCode = () => {
  const methods = useForm({ defaultValues: { antiPhishingCode: '' } });
  const { handleSubmit } = methods;

  const intl = useIntl();

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    size: 'l',
  };

  const { onSetAntiPhishingCode, isSetAntiPhishingCodeLoading } =
    useProfileMutations();

  const handleFormSubmit = ({ antiPhishingKey }) => {
    onSetAntiPhishingCode({ antiPhishingKey });
  };

  return (
    <div className={cx('security-card', styles.security_card)}>
      <Card
        customStyles={styles}
        title={<FormattedMessage id="AntiPhishingCode" />}
      >
        <div className="security-card-description">
          <FormattedMessage id="YourAntiPhishingCodeDescription" />
        </div>

        <div className={styles.form}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <PasswordInputController
              name="antiPhishingKey"
              label={`${intl.formatMessage({
                id: 'YourAntiPhishingCodeInput',
              })}:`}
              placeholder={intl.formatMessage({
                id: 'EnterCode',
              })}
              rules={{
                required: intl.formatMessage({ id: 'CodeIsRequired' }),
              }}
              {...commonFieldProps}
              {...methods}
            />

            <div className="mt-22">
              <FormActions
                submitText={<FormattedMessage id="SubmitAndEnable" />}
                submitButtonProps={{
                  isLoading: isSetAntiPhishingCodeLoading,
                  disabled: isSetAntiPhishingCodeLoading,
                }}
                cancelButtonProps={{
                  variant: 'text',
                }}
                orientation={FORM_ACTION_ORIENTATION.VERTICAL}
              />
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default memo(EnableAntiPhishingCode);
