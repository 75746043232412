import React, { memo, Fragment } from 'react';

import CoinPercentDynamics from '@components/CoinPercentDynamics';

import {
  marketStatisticsByMarketIdSelector,
  useStreamMarketStatisticsQuery,
} from '@api/marketsAPI';

import { format } from '@utils/numbers';

import { ReactComponent as HorizontalLineIconSVG } from '@icons/horizontal-line-icon.svg';

const RunningLineItem = ({ item }) => {
  const { marketStatistic } = useStreamMarketStatisticsQuery(undefined, {
    selectFromResult: (result) => ({
      marketStatistic: marketStatisticsByMarketIdSelector(result, item.id),
    }),
  });

  return (
    <Fragment key={item.id}>
      <div className="slide-container">
        <div className="slide" key={item.id}>
          <span className="pair-name">{item.name}</span>

          <span className="pair-price">
            {format(marketStatistic.c, {
              precision: item.quote_precision_format,
            })}
          </span>

          <CoinPercentDynamics percent={marketStatistic?.ch} />
        </div>
      </div>

      <div className="slide-container">
        <HorizontalLineIconSVG className="horizontal-line-icon" />
      </div>
    </Fragment>
  );
};

export default memo(RunningLineItem);
