import { useDispatch, useSelector } from 'react-redux';

import {
  terminalThemeSelector,
  accountThemeSelector,
} from '@store/settings/settingsSelectors';
import { settingsActions } from '@store/settings/settingsSlice';

import themeStyles from '@assets/css/theme/_exports.scss';

export default function useTheme() {
  const terminalTheme = useSelector(terminalThemeSelector);
  const accountTheme = useSelector(accountThemeSelector);

  const dispatch = useDispatch();

  const setTerminalTheme = (theme) => {
    dispatch(settingsActions.setTerminalTheme(theme));
  };

  const setAccountTheme = (theme) => {
    dispatch(settingsActions.setAccountTheme(theme));
  };

  return {
    themeStyles,
    terminalTheme,
    accountTheme,
    setTerminalTheme,
    setAccountTheme,
  };
}
