import { forwardRef, useId } from 'react';

import cx from 'classnames';

import { FormController } from '@shared/components';

import s from './RadioInput.module.scss';

const RadioInput = forwardRef(
  (
    {
      label,
      inputValue,
      labelPosition = 'right',
      size = 'm',
      customStyles = {},
      inversion,
      isInvalid,
      value,
      ...props
    },
    ref,
  ) => {
    const id = useId();

    return (
      <>
        <div
          className={cx(
            s.radio_input_container,
            customStyles.radio_input_container,
            s[labelPosition],
          )}
        >
          <input
            className={cx(
              s[size],
              s.radio_input_field,
              customStyles.radio_input_field,
              {
                [s.invalid]: isInvalid,
              },
            )}
            ref={ref}
            id={id}
            type="radio"
            value={inputValue}
            checked={inputValue === value}
            {...props}
          />

          <label
            htmlFor={id}
            className={cx(s.radio_input_label, customStyles.radio_input_label)}
          >
            {label}
          </label>
        </div>
      </>
    );
  },
);

export default RadioInput;

export const RadioInputController = (props) => {
  return (
    <FormController
      isErrorDisplayed={false}
      component={RadioInput}
      {...props}
    />
  );
};
