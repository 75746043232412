import { createApi } from '@reduxjs/toolkit/query/react';
import reactFastCompare from 'react-fast-compare';

import client from '@api/client';
import subscriptions from '@api/ws/subscriptions';

export const coinsAPI = createApi({
  reducerPath: 'coinsAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    getCoinsInfo: builder.query({
      query: () => 'coins',
      transformResponse: (response) =>
        response
          .filter((coinInfo) => coinInfo.status === 'active')
          .sort((a, b) => new Intl.Collator().compare(a.symbol, b.symbol)),
    }),
    streamCoinRates: builder.query({
      queryFn: () => ({ data: undefined }),
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded;

          subscriptions.subscribeCoinRates(({ data, reason }) => {
            updateCachedData((draftState) => {
              if (reason === 'error') {
                return null;
              }

              if (reason === 'unsubscribe') {
                return draftState;
              }

              if (!draftState || !reactFastCompare(draftState, data)) {
                return data;
              }
            });
          });
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        await cacheEntryRemoved;

        subscriptions.unsubscribeCoinRates();
      },
    }),
  }),
});

export const {
  useGetCoinsInfoQuery,
  useLazyGetCoinsInfoQuery,
  useStreamCoinRatesQuery,
} = coinsAPI;
