import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import TruncatedText from '../TruncatedText/TruncatedText';

const ColumnHeaderItem = ({ id }) => {
  const intl = useIntl();

  return (
    <TruncatedText>
      <span>{intl.formatMessage({ id })}</span>
    </TruncatedText>
  );
};

export default memo(ColumnHeaderItem);
