import { useMemo } from 'react';

import cx from 'classnames';

import { DefaultAvatar } from '@shared/components';

import s from './FavoriteUserCard.module.scss';

const FavoriteUserCard = ({ user, isSelected }) => {
  const truncatedEmail = useMemo(() => {
    return `${user.email.slice(0, 4)}***${user.email.slice(-4)}`;
  }, [user.email]);

  return (
    <div className={cx(s.user_card, { [s.active]: isSelected })}>
      <div className={s.user_card__avatar}>
        <DefaultAvatar avatarId={user.id} />
      </div>
      <div className={s.user_card__name}>{user.name}</div>
      <div className={s.user_card__email}>{truncatedEmail}</div>
    </div>
  );
};

export default FavoriteUserCard;
