export const DEFAULT_FORMAT = 'yyyy_MM_dd_HHmmss';

export const DAY_MONTH_YEAR_TIME_FORMAT = 'dd-MM-yyyy HH:mm:ss';

export const YEAR_MONTH_DAY_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const YEAR_MONTH_DAY_FORMAT = 'yyyy-MM-dd';

export const MONTH_DAY_YEAR_FORMAT = 'MMM d, yyyy';

export const YEAR_MONTH_DD_FORMAT = 'yyyy-MM-DD';

export const DAY_MONTH_YEAR_FORMAT = 'dd.MM.yyyy';

export const MONTH_YEAR_FORMAT = 'MM/yy';

export const TIME_FORMAT = 'HH:mm:ss';

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
