import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button } from '@shared/ui';
import { copyToClipboard } from '@shared/utils';

import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import { ReactComponent as CopyIconSVG } from '@icons/copy-icon.svg';
import { ReactComponent as InfoOutlineIconSVG } from '@icons/info-outline-icon.svg';
import { ReactComponent as SettingsIconSVG } from '@icons/settings-icon.svg';
import { ReactComponent as StatisticIconSVG } from '@icons/statistic-icon.svg';

import { WALLETS_ROUTES } from '../../../../../routes.constants';

import s from './VisaCardControls.module.scss';

const VisaCardControls = () => {
  const [isCardInfoDisplayed, setIsCardInfoDisplayed] = useState(false);

  const { openSidebar: openTopUpCardSidebar } = useSidebar(
    sidebarIds.TOP_UP_CARD,
  );
  const { openSidebar: openTransferMoneySidebar } = useSidebar(
    sidebarIds.VISA_TRANSFER_MONEY,
  );

  const intl = useIntl();

  const card = useSelector(visaCardSelector);

  const commonTooltipConfig = {
    placement: 'top',
  };

  const handleOpenTopUpSidebar = () => {
    openTopUpCardSidebar();
  };

  const handleOpenTransferMoneySidebar = () => {
    openTransferMoneySidebar();
  };

  const handleToggleCardInfo = () => {
    setIsCardInfoDisplayed((prevState) => !prevState);
  };

  const handleSaveInClipBoard = async () => {
    await copyToClipboard({ intl, text: card.number });
  };

  const isButtonsDisabled = useMemo(() => !card.type, [card]);

  return (
    <div>
      <div className={s.controls}>
        <div className={s.controls__top_up}>
          <Button
            disabled={isButtonsDisabled}
            customStyles={s}
            onClick={handleOpenTopUpSidebar}
          >
            <FormattedMessage id="TopUp" />
          </Button>
        </div>
        <div className={s.controls__transfer}>
          <Button
            variant="outlined"
            disabled={isButtonsDisabled}
            customStyles={s}
            onClick={handleOpenTransferMoneySidebar}
          >
            <FormattedMessage id="Transfer" />
            ...
          </Button>
        </div>
        <Button
          customStyles={s}
          isCircle
          variant="outlined"
          tooltipConfig={{
            content: intl.formatMessage({ id: 'CardInfo' }),
            ...commonTooltipConfig,
          }}
          disabled={isButtonsDisabled}
          onClick={handleToggleCardInfo}
        >
          <InfoOutlineIconSVG />
        </Button>
        <Button
          customStyles={s}
          isCircle
          variant="outlined"
          tooltipConfig={{
            content: intl.formatMessage({ id: 'Statistics' }),
            ...commonTooltipConfig,
          }}
          // disabled={isButtonsDisabled}
          disabled
        >
          <StatisticIconSVG />
        </Button>
        <Button
          customStyles={s}
          isCircle
          variant="outlined"
          tooltipConfig={{
            content: intl.formatMessage({ id: 'Settings' }),
            ...commonTooltipConfig,
          }}
          // this condition add to be able to disable button (tag <a> isn't possible to disable)
          navigation={
            !isButtonsDisabled
              ? { to: WALLETS_ROUTES.VISA_CARD_SETTINGS }
              : undefined
          }
          disabled={isButtonsDisabled}
        >
          <SettingsIconSVG />
        </Button>
      </div>
      {isCardInfoDisplayed && (
        <div onClick={handleSaveInClipBoard} className={s.card_number}>
          {card.number}
          <CopyIconSVG />
        </div>
      )}
    </div>
  );
};

export default VisaCardControls;
