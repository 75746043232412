import React, { memo } from 'react';

import { FormattedCurrency } from '@shared/components';

import styles from './TotalAmountUntilExpiration.module.scss';

const TotalAmountUntilExpiration = ({
  totalAmountUntilExpiration,
  coinSymbol,
}) => {
  return (
    <span
      className={
        Number(totalAmountUntilExpiration) ? styles.black : styles.grey
      }
    >
      <FormattedCurrency
        currency={coinSymbol}
        value={totalAmountUntilExpiration}
      />
    </span>
  );
};

export default memo(TotalAmountUntilExpiration);
