import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { ORDER_SIDES } from '@shared/constants';
import { useMarketFormat } from '@shared/hooks';

import {
  orderBookControlsSelector,
  selectedOrderBookItemSelector,
} from '@store/gridBlocks/gridBlocksSelectors';
import { gridBlocksActions } from '@store/gridBlocks/gridBlocksSlice';
import { marketIdSelector } from '@store/settings/settingsSelectors';

import { NOOP } from '@constants';

import { useBothForms } from '../../hooks';

const useCalculateLimitFormValues = ({
  isBuyTab = true,
  layoutItemRef,
  handleChangePrice = NOOP,
  handleChangeAmount = NOOP,
  handleChangeTotal = NOOP,
}) => {
  const dispatch = useDispatch();

  const marketId = useSelector(marketIdSelector);
  const { selectedOrderBookItem } = useSelector(selectedOrderBookItemSelector);

  // TODO: rename to isShowOrdersFromBook
  const { showOrdersFromBook: isShowOrdersFromBook } = useSelector(
    orderBookControlsSelector(selectedOrderBookItem?.layoutItemId),
  );

  const { isShowBothForms } = useBothForms({ layoutItemRef });

  const formatOptions = {
    returnNumber: true,
  };

  const { formattedValue: formattedPriceValue } = useMarketFormat({
    marketId,
    type: 'quote',
    value: selectedOrderBookItem?.price,
    formatOptions,
  });

  const { formattedValue: formattedAmountValue } = useMarketFormat({
    marketId,
    type: 'market',
    value: selectedOrderBookItem?.amount,
    formatOptions,
  });

  const { formattedValue: formattedTotalValue } = useMarketFormat({
    marketId,
    type: 'quote',
    value: formattedPriceValue * formattedAmountValue,
    formatOptions,
  });

  const isSelectedOrderbookItemBuy =
    selectedOrderBookItem?.side === ORDER_SIDES.BUY;

  useEffect(() => {
    if (isShowOrdersFromBook && selectedOrderBookItem) {
      if (
        !isShowBothForms ||
        (isShowBothForms && isBuyTab === isSelectedOrderbookItemBuy)
      ) {
        handleChangePrice(formattedPriceValue);

        handleChangeAmount(formattedAmountValue);

        handleChangeTotal(formattedTotalValue);

        dispatch(gridBlocksActions.setSelectedOrderBookItem(undefined));
      }
    }
  }, [selectedOrderBookItem]);
};

export default useCalculateLimitFormValues;
