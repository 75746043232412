import { useMemo } from 'react';

import { useMarketsInfo } from '@api/hooks';

const useConvertCurrencies = ({ fromCurrency, toCurrency }) => {
  const { marketsInfoMap } = useMarketsInfo();

  const addSymbolAndName = (currency, index) => ({
    id: `${currency}-${index}`,
    symbol: currency,
    name: currency.toUpperCase(),
  });

  const fromCurrencies = useMemo(() => {
    let filteredFromCurrencies;

    // if toCurrency is set left only currencies in pair with toCurrency
    if (toCurrency) {
      filteredFromCurrencies = marketsInfoMap
        .filter(
          (market) =>
            market.market_coin_symbol === toCurrency ||
            market.quote_coin_symbol === toCurrency,
        )
        .reduce((allMarkets, market) => {
          const pairOfToCurrency =
            market.market_coin_symbol === toCurrency
              ? market.quote_coin_symbol
              : market.market_coin_symbol;

          allMarkets.add(pairOfToCurrency);

          return allMarkets;
        }, new Set());

      filteredFromCurrencies = Array.from(filteredFromCurrencies);
    } else {
      // else put on fromCurrencies all currencies
      const fromCurrenciesSet = marketsInfoMap.reduce((allMarkets, market) => {
        allMarkets.add(market.market_coin_symbol);
        allMarkets.add(market.quote_coin_symbol);

        return allMarkets;
      }, new Set());

      filteredFromCurrencies = Array.from(fromCurrenciesSet);
    }

    return filteredFromCurrencies.map(addSymbolAndName);
  }, [marketsInfoMap, toCurrency]);

  const toCurrencies = useMemo(() => {
    let filteredToCurrencies;

    // if toCurrency is set left only toCurrency in toCurrencies
    if (toCurrency) {
      filteredToCurrencies = [toCurrency];
    } else {
      // else left only currencies in pair with fromCurrency
      const toCurrenciesSet = marketsInfoMap
        .filter(
          (market) =>
            market.market_coin_symbol === fromCurrency ||
            market.quote_coin_symbol === fromCurrency,
        )
        .reduce((allMarkets, market) => {
          const pairOfFromCurrency =
            market.market_coin_symbol === fromCurrency
              ? market.quote_coin_symbol
              : market.market_coin_symbol;

          allMarkets.add(pairOfFromCurrency);

          return allMarkets;
        }, new Set());

      filteredToCurrencies = Array.from(toCurrenciesSet);
    }

    return filteredToCurrencies.map(addSymbolAndName);
  }, [marketsInfoMap, toCurrency, fromCurrency]);

  return { fromCurrencies, toCurrencies };
};

export default useConvertCurrencies;
