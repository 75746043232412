import React, { memo } from 'react';

import TruncatedText from '@shared/components/TruncatedText';

import { format } from '@utils/numbers';

import styles from './TotalItem.module.scss';

const TotalItem = ({ total, precision }) => {
  const formattedTotal = format(total, {
    precision,
  });

  return (
    <TruncatedText>
      <span className={Number(total) ? styles.black : styles.grey}>
        {formattedTotal}
      </span>
    </TruncatedText>
  );
};

export default memo(TotalItem);
