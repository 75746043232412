import { createSlice } from '@reduxjs/toolkit';

import {
  ITEMS_PER_PAGE_ANNOUNCEMENTS,
  ALL_TOPIC,
} from '@pages/LegacyPages/AnnouncementsPage/announcements.constants';

const defaultPagination = {
  limit: ITEMS_PER_PAGE_ANNOUNCEMENTS,
  page: 1,
};

const initialState = {
  pagination: defaultPagination,
  announcements: [],
  announcementsMeta: {},
  isSuccess: false,
  topic: ALL_TOPIC,
};

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    resetPagination: (state) => {
      state.pagination = defaultPagination;
    },
    setTopic: (state, action) => {
      state.topic = action.payload;
    },
    setAnnouncements: (state, action) => {
      const { announcements, meta, isSuccess } = action.payload;

      state.announcements = announcements;
      state.announcementsMeta = meta;
      state.isSuccess = isSuccess;
    },
  },
});

const announcementsActions = announcementsSlice.actions;
const announcementsReducer = announcementsSlice.reducer;

export { announcementsActions, announcementsReducer };

export default announcementsSlice;
