import { useMemo } from 'react';

import { keyBy } from 'lodash';

import { useGetCoinsInfoQuery, useLazyGetCoinsInfoQuery } from '@api/coinsAPI';
import { CoinsApiSelectors } from '@api/selectors';

const selectFromResult = (
  result,
  {
    searchValue = '',
    coinSymbol,
    orderByTypeDirection = '',
    filterByType = '',
  } = {},
) => {
  const dataResult = {
    isCoinsInfoSuccess: result.isSuccess,
  };

  const originCoinsInfoMap = CoinsApiSelectors.dataSelector(result);
  let coinsInfoMap = CoinsApiSelectors.dataSelector(result);

  if (searchValue) {
    coinsInfoMap = CoinsApiSelectors.coinsInfoMapBySearchValueSelector(
      coinsInfoMap,
      searchValue,
    );
  }

  if (filterByType) {
    coinsInfoMap = CoinsApiSelectors.coinsInfoMapFilterByTypeSelector(
      coinsInfoMap,
      filterByType,
    );
  }

  if (orderByTypeDirection) {
    coinsInfoMap = CoinsApiSelectors.coinsInfoMapOrderByTypeDirectionSelector(
      coinsInfoMap,
      orderByTypeDirection,
    );
  }

  dataResult.coinInfo = coinSymbol
    ? CoinsApiSelectors.coinInfoFromCoinsInfoMapSelector(
        coinsInfoMap,
        coinSymbol,
      )
    : undefined;

  dataResult.coinsInfo = coinsInfoMap.length
    ? keyBy(coinsInfoMap, 'symbol')
    : undefined;

  dataResult.originCoinsInfo = originCoinsInfoMap.length
    ? keyBy(originCoinsInfoMap, 'symbol')
    : undefined;

  dataResult.coinsInfoMap = coinsInfoMap;
  dataResult.originCoinsInfoMap = originCoinsInfoMap;

  return dataResult;
};

export const useCoinsInfo = (
  {
    searchValue = '',
    coinSymbol,
    orderByTypeDirection = '',
    filterByType = '',
  } = {},
  options,
) => {
  const result = useGetCoinsInfoQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result, {
      searchValue,
      coinSymbol,
      orderByTypeDirection,
      filterByType,
    });
  }, [result, searchValue, coinSymbol, orderByTypeDirection, filterByType]);

  return data;
};

export const useLazyCoinsInfo = (options) => {
  const [trigger, result] = useLazyGetCoinsInfoQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result, result.originalArgs);
  }, [result]);

  return [trigger, data];
};
