import React, { memo } from 'react';

import classnames from 'classnames';

import HeaderDivider from './HeaderDivider';

const HeaderItem = ({ children, className, withDivider = false, ...rest }) => {
  return (
    <>
      <div className={classnames('header-item', className)} {...rest}>
        {children}
      </div>

      {withDivider && <HeaderDivider />}
    </>
  );
};

export default memo(HeaderItem);
