import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

export const featuresAPI = createApi({
  reducerPath: 'featuresAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    getFeatureRecaptcha: builder.query({
      query: () => 'features/recaptcha',
      transformResponse: (response) => response === 'true',
    }),
  }),
});

export const { useGetFeatureRecaptchaQuery } = featuresAPI;
