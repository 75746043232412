import { memo } from 'react';

import { Card } from '@shared/components';

import { KYC_LEVELS } from '@pages/AccountPage/constants';
import { useKYCWithdrawLimits } from '@pages/AccountPage/hooks';

import { useGetProfileInfoQueryResult } from '@api/hooks';

import { CardTitle } from './CardTitle';
import { IndexIcon } from './IndexIcon';
import { KycLevelInfo } from './KycLevelInfo';

import styles from './AccountVerification.module.scss';

const AccountVerification = ({
  title,
  isShowLevelAdditionalInfo,
  withBackground = true,
}) => {
  const { userKycLevel } = useGetProfileInfoQueryResult();

  const kycWithdrawLimits = useKYCWithdrawLimits();

  const isAtLeastFirstKycLevel = userKycLevel > KYC_LEVELS.ZERO;

  return (
    <Card
      classNames={[
        styles.card,
        isAtLeastFirstKycLevel && withBackground && styles.active,
      ]}
      title={<CardTitle title={title} />}
    >
      <div className="d-flex flex-column gap-28">
        {kycWithdrawLimits.map(
          ({ kycLevel, withdrawLimit, isCurrentKycLevel }, index) => {
            const isActive = kycLevel <= userKycLevel;
            const isShowStartButton = kycLevel === userKycLevel + 1;
            const isLastItem = kycWithdrawLimits.length - 1 === index;

            return (
              <div key={kycLevel} className="d-flex gap-24">
                <IndexIcon
                  isActive={isActive}
                  index={kycLevel}
                  isShowVerticalLine={!isLastItem}
                />

                <KycLevelInfo
                  withdrawLimit={withdrawLimit}
                  kycLevel={kycLevel}
                  isShowBottomLine={isLastItem}
                  isCurrentKycLevel={isCurrentKycLevel}
                  isShowStartButton={isShowStartButton}
                  isShowLevelAdditionalInfo={isShowLevelAdditionalInfo}
                />
              </div>
            );
          },
        )}
      </div>
    </Card>
  );
};

export default memo(AccountVerification);
