import { forwardRef, memo } from 'react';

import classnames from 'classnames';

import { NOOP } from '@constants';

import TableDataCell from '../components/TableDataCell';
import TableHead from '../components/TableHead';

const TableFixed = forwardRef(
  (
    {
      id,
      headers,
      headerSize = 's', // m, l
      rows,
      stripedRows,
      underlinedRows,
      className,
      sortingState,
      inversion,
      isShowColumnsOnEmptyData = true,
      isNoData = true,
      wideRows = false,
      wideRowsS = false,
      wideRowsL = false,
      wideRowsXL = false,
      onRowClick = NOOP,
      onRowMouseDown = NOOP,
      onRowTouchStart = NOOP,
      onMouseEnterRow = NOOP,
      onMouseLeaveRow = NOOP,
    },
    ref,
  ) => {
    return (
      <div ref={ref}>
        <table className={classnames('table-default', className)}>
          {(!isNoData || (isNoData && isShowColumnsOnEmptyData)) && (
            <thead
              className={classnames(
                `table-default__thead table-default__thead--size-${headerSize}`,
                {
                  'table-default__thead--inversion': inversion,
                  'table-default__thead--underlined': underlinedRows,
                },
              )}
            >
              <tr>
                {headers.map((header) => (
                  <TableHead
                    key={`${id}-${header.id}`}
                    style={
                      {
                        // width: header.getSize(), it needs to sync width with default table, but it generates issue with freezes on block resizing
                      }
                    }
                    header={header}
                    sortingState={sortingState}
                  />
                ))}
              </tr>
            </thead>
          )}

          <tbody className="fs-13">
            {rows.map((row, index) => {
              return (
                <tr
                  className={classnames({
                    'table-default__row--inversion': inversion,
                    'table-default__row--underlined': underlinedRows,
                  })}
                  key={`${id}-row-${index}`}
                >
                  {row.map((cell) => (
                    <TableDataCell
                      key={`${id}-${cell.id}`}
                      index={index}
                      cell={cell}
                      stripedRows={stripedRows}
                      wideRows={wideRows}
                      wideRowsS={wideRowsS}
                      wideRowsL={wideRowsL}
                      wideRowsXL={wideRowsXL}
                      onRowClick={onRowClick}
                      onRowMouseDown={onRowMouseDown}
                      onRowTouchStart={onRowTouchStart}
                      onMouseEnterRow={onMouseEnterRow}
                      onMouseLeaveRow={onMouseLeaveRow}
                    />
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  },
);

export default memo(TableFixed);
