import { useMemo } from 'react';

import { BOT_TYPE } from '@shared/constants';

import { useGetBotsSettingsQuery } from '@api/botsAPI';
import { BotsApiSettingsSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const dataResult = {
    isBotsSettingsSuccess: result.isSuccess,
  };

  dataResult[BOT_TYPE.trend] =
    BotsApiSettingsSelectors.trendBotsSettingsSelector(result);
  dataResult[BOT_TYPE.grid] =
    BotsApiSettingsSelectors.gridBotsSettingsSelector(result);

  return dataResult;
};

const useBotsSettings = (args, options) => {
  const result = useGetBotsSettingsQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useBotsSettings;
