export const CRYPTO_CURRENCIES = {
  BTC: 'btc',
  ETH: 'eth',
  PRDX: 'prdx',
  USDT: 'usdt',
  XRP: 'xrp',
  EOS: 'eos',
};

export const FIAT_CURRENCIES = {
  USD: 'usd',
  EUR: 'eur',
};

// Example: BTC/USDT
// BTC - is market
// USDT - is quote

export const FORMAT_TYPES = {
  quote: 'quote_precision_format',
  market: 'market_precision_format',
};
