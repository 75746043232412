import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '@shared/components';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './GoogleAuthCard.module.scss';

const GoogleAuthCard = ({ index, isActive, title, children }) => {
  const { isTabletDown, isLaptopUp } = useBreakpoints();

  return (
    <Card classNames={[styles.card, isActive && styles.active]}>
      <div className="flex-1">
        <div className="d-flex">
          <div className={styles.icon}>
            <div className={styles.index}>{index}</div>
          </div>

          <div className={styles.content}>
            <div>
              <FormattedMessage id="Step" /> {index}
            </div>
            {Boolean(title) && (
              <div className={styles.title}>
                <div className={styles.text}>{title}</div>
              </div>
            )}
            {children && isLaptopUp && (
              <div className={styles.description}>{children}</div>
            )}
          </div>
        </div>
      </div>

      {children && isTabletDown && (
        <div className={styles.description}>{children}</div>
      )}
    </Card>
  );
};

export default memo(GoogleAuthCard);
