// import quoteFeedSimulator from 'chartiq/examples/feeds/quoteFeedSimulator';
// import marker from 'chartiq/examples/markers/markersSample';
import getDefaultConfig from 'chartiq/js/defaultConfiguration';
// import PerfectScrollbar from 'chartiq/js/thirdparty/perfect-scrollbar.esm.js';
// import EmojiPopover from 'chartiq/js/thirdparty/emoji-popover.es.js';

// import 'chartiq/examples/feeds/symbolLookupChartIQ';

// Symbol mapping to market definition
// import 'chartiq/examples/markets/marketDefinitionsSample';
// import 'chartiq/examples/markets/marketSymbologySample';

// Translation file
// import 'chartiq/examples/translations/translationSample';

// Example Marker files
// import 'chartiq/examples/markers/videoSample';

// Plugins

// Crypto, L2 Heat Map, Market Depth,
// Important: Uncomment marketDepth in config.plugins below when enabling this plug-in.
// import "chartiq/plugins/activetrader/cryptoiq";

// ScriptIQ
// import "chartiq/plugins/scriptiq/scriptiq";

// SignalIQ
// import 'chartiq/plugins/signaliq/signaliqDialog';
// import 'chartiq/plugins/signaliq/signaliq-marker';
// import 'chartiq/plugins/signaliq/signaliq-paintbar';

// import "chartiq/plugins/studybrowser";

// Trading Central: Technical Insights
// import "chartiq/plugins/technicalinsights/components";

// TFC plugin
// Important: Uncomment tfc in config.plugins below when enabling this plug-in.
// import "chartiq/plugins/tfc/tfc-loader";
// import "chartiq/plugins/tfc/tfc-demo"; /* if using demo account class */

// Time Span Events
// Important: Uncomment timeSpanEventPanel in config.plugins below when enabling this plug-in.
// import "chartiq/plugins/timespanevent/timespanevent";
// import "chartiq/plugins/timespanevent/examples/timeSpanEventSample"; /* if using sample */

// Trading Central: Analyst Views
// import "chartiq/plugins/analystviews/components";

// Visual Earnings
// Important: Uncomment visualEarnings in config.plugins below when enabling this plug-in.
// import "chartiq/plugins/visualearnings/visualearnings";

// Uncomment the following for the L2 simulator (required for the crypto sample and MarketDepth addOn)
// import "chartiq/examples/feeds/L2_simulator"; /* for use with cryptoiq */

const customResources = {
  // quoteFeed: quoteFeedSimulator,
  // markerFeed: marker.MarkersSample,
  // scrollStyle: PerfectScrollbar, // use improved component scrollbar appearance https://perfectscrollbar.com
  // emojiPicker: EmojiPopover,
};

function getCustomConfig({
  chartId,
  initialSymbol,
  onChartReady,
  resources = customResources,
} = {}) {
  const config = getDefaultConfig(resources);

  config.chartId = chartId || '_custom-chart';
  config.initialSymbol = initialSymbol || {
    symbol: 'AAPL',
    name: 'Apple Inc',
    exchDisp: 'NASDAQ',
  };

  config.onChartReady = onChartReady;

  // Disable crossSection plugins by default
  // It can throw errors if when loaded with time series charts.
  config.plugins.crossSection = null;

  return config;
}

export { getCustomConfig };
