import { getCountryCallingCode } from 'react-phone-number-input/input';

import s from './CountrySelectOption.module.scss';

const CountrySelectOption = ({ code }) => {
  const flagUrl = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`;

  return (
    <div className={s.option}>
      <div className={s.icon}>
        <img src={flagUrl} alt={code} />
      </div>
      <div className={s.title}>+{getCountryCallingCode(code)}</div>
    </div>
  );
};

export default CountrySelectOption;
