import React, { memo } from 'react';

import cx from 'classnames';

import { ReactComponent as HorizontalLineIconSVG } from '@icons/horizontal-line-icon.svg';
import { ReactComponent as InfoIconSVG } from '@icons/info-icon.svg';

import styles from './InformationBlock.module.scss';

/**
 * items = ['item 1', 'item 2', 'item 3', 'item 4']
 * */
const InformationBlock = ({
  title,
  items = [],
  withIcon = true,
  inversion,
  customStyles = {},
}) => {
  return (
    <div
      className={cx(customStyles.information_block, {
        [styles.inversion]: inversion,
      })}
    >
      {(withIcon || title) && (
        <div className={cx(customStyles.header, styles.header)}>
          {withIcon && <InfoIconSVG className={styles.attention_icon} />}
          {title && <p>{title}</p>}
        </div>
      )}
      {items.map((item, index) => (
        <div className={cx(customStyles.item, styles.item)} key={index}>
          <div className={styles.line_icon}>
            <HorizontalLineIconSVG />
          </div>
          <p>{item}</p>
        </div>
      ))}
    </div>
  );
};

export default memo(InformationBlock);
