import React, { useEffect } from 'react';

import { TransferSideBar } from '@shared/components';

import { sidebarIds } from '@constants';

import useSidebarsManager from './useSidebarsManager';

const SidebarsContext = React.createContext();

const { Provider } = SidebarsContext;

const SidebarsProvider = ({ children }) => {
  const { sidebars, toggle, open, close, register } = useSidebarsManager();

  useEffect(() => {
    Object.values(sidebarIds).forEach((value) => register(value));
  }, []);

  return (
    <Provider
      value={{
        sidebars,
        toggle,
        open,
        close,
      }}
    >
      {children}

      <TransferSideBar />
    </Provider>
  );
};

export { SidebarsContext };

export default SidebarsProvider;
