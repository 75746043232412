import { forwardRef, useMemo } from 'react';

import { State } from 'country-state-city';

import { FormController } from '@shared/components';
import { Select } from '@shared/ui';
import { Menu } from '@shared/ui/Select/components';

export const getCountryStatesOptions = (countryCode) => {
  return State.getStatesOfCountry(countryCode).map((state) => ({
    ...state,
    value: state.isoCode,
    label: state.name,
  }));
};

const StatesSelect = forwardRef(
  ({ countryCode, customStyles, ...props }, ref) => {
    const options = useMemo(() => {
      return getCountryStatesOptions(countryCode);
    }, [countryCode]);

    const SpecificCustomMenu = ({ children, ...rest }) => {
      return (
        <Menu customStyles={customStyles} {...rest}>
          {children}
        </Menu>
      );
    };

    return (
      <Select
        ref={ref}
        options={options}
        specificComponents={{
          Menu: SpecificCustomMenu,
        }}
        {...props}
      />
    );
  },
);

export default StatesSelect;

export const StatesSelectController = (props) => {
  return <FormController component={StatesSelect} {...props} />;
};
