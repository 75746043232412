import { useMemo } from 'react';
import { generatePath } from 'react-router';

import cx from 'classnames';

import Link from '@components/Link/Link';

import { ReactComponent as SearchIconSVG } from '@icons/search-icon.svg';

import { HightLightText } from './HightLightText';

import { MAIN_ROUTES } from 'src/routes.constants';

import styles from './SearchMenuItem.module.scss';

export const SearchMenuItemMarket = ({ itemData, hightLightText, onClick }) => {
  const isHightLightText = typeof hightLightText === 'string';

  const path = useMemo(
    () => generatePath(MAIN_ROUTES.TERMINAL, { marketId: itemData.id }),
    [itemData.id],
  );

  return (
    <Link
      className={cx(styles.item, 'd-flex gap-10 align-items-center')}
      to={path}
      onClick={() => onClick(itemData)}
    >
      <SearchIconSVG />

      {isHightLightText ? (
        <HightLightText text={itemData.title} hightLightText={hightLightText} />
      ) : (
        itemData.title
      )}
    </Link>
  );
};
