import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { bootIntercom, shutdownIntercom, updateIntercom } from '@libs/intercom';

import {
  isAuthorizedUserSelector,
  userEmailSelector,
  userIdSelector,
} from '@store/settings/settingsSelectors';

import router from '@utils/router';

const Routes = () => {
  const isAuthorizedUser = useSelector(isAuthorizedUserSelector);
  const userId = useSelector(userIdSelector);
  const userEmail = useSelector(userEmailSelector);

  useEffect(() => {
    router.subscribe((state) => {
      updateIntercom();

      document.querySelector('.scrollable-layout')?.scrollTo(0, 0);
    });
  }, []);

  useEffect(() => {
    if (isAuthorizedUser) {
      bootIntercom({
        email: userEmail,
        name: '',
        user_id: userId,
        created_at: new Date().getTime() / 1000,
      });
    } else {
      shutdownIntercom();

      // you can bootIntercom anonymous intercom here
    }
  }, [isAuthorizedUser]);

  return <RouterProvider router={router} />;
};

export default memo(Routes);
