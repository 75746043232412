const calculateBonus = (totalBonus, progressPercent) => {
  if (progressPercent >= 25 && progressPercent < 50) {
    return {
      currentBonus: totalBonus * 0.25,
      currentBonusPercent: 25,
    };
  }

  if (progressPercent >= 50 && progressPercent < 75) {
    return {
      currentBonus: totalBonus * 0.5,
      currentBonusPercent: 50,
    };
  }

  if (progressPercent >= 75 && progressPercent < 100) {
    return {
      currentBonus: totalBonus * 0.75,
      currentBonusPercent: 75,
    };
  }

  if (progressPercent >= 100) {
    return {
      currentBonus: totalBonus,
      currentBonusPercent: 100,
    };
  }

  return {
    currentBonus: 0,
    currentBonusPercent: 0,
  };
};

export const ContractsUtils = {
  calculateBonus,
};
