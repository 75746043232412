import React, { memo, useRef } from 'react';

import useElementResize from '@hooks/useElementResize';

import ChartTemplateNavbar from './components/ChartTemplateNavbar';
import HeaderControls from './components/HeaderControls';

const BotChartTemplate = ({
  config,
  stx,
  isFullSize,
  onFullSizeClick,
  hideMenuPeriodicityItems,
}) => {
  const chartRef = useRef();
  const { height: chartHeight } = useElementResize(chartRef);

  return (
    <>
      <div className="ciq-nav">
        <div className="ciq-nav" role="navigation">
          <ChartTemplateNavbar
            isFullSize={isFullSize}
            onFullSizeClick={onFullSizeClick}
          />

          <HeaderControls
            chartHeight={chartHeight}
            stx={stx}
            config={config}
            hideMenuPeriodicityItems={hideMenuPeriodicityItems}
          />
        </div>
      </div>

      <div ref={chartRef} className="ciq-chart-area" role="main">
        <div className="ciq-chart">
          <div className="chartContainer">
            <cq-chart-title cq-marker>
              <cq-symbol></cq-symbol>
              <cq-symbol-description></cq-symbol-description>
            </cq-chart-title>
            <cq-hu-static></cq-hu-static>

            <cq-loader></cq-loader>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(BotChartTemplate);
