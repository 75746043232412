import cx from 'classnames';

import { RadioInputController } from '@shared/ui/RadioInputGroup/components';

import s from './RadioInputGroup.module.scss';

/**
 * Example of items:
 * items = [
 *   {value: 'some_value', label: 'String Label'},
 *   {value: 'other_value', label: '<div>Other component value</div>'},
 * ]
 * */
const RadioInputGroup = ({ items = [], customStyles = {}, ...props }) => {
  const { error } = props.getFieldState(props.name, props.formState);

  return (
    <div>
      <div className={cx(s.radio_input_group, customStyles.radio_input_group)}>
        {items.map(({ value, label, ...item }, index) => (
          <RadioInputController
            key={value + index}
            label={label}
            inputValue={value}
            customStyles={customStyles}
            {...item}
            {...props}
          />
        ))}
      </div>
      {error && <div className="color-red mt-2">{error.message}</div>}
    </div>
  );
};

export default RadioInputGroup;
