import { useContext } from 'react';

import { sidebarIds } from '@constants';

import { SidebarsContext } from './SidebarsProvider';

const useSidebar = (sidebarId) => {
  const { sidebars, toggle, open, close } = useContext(SidebarsContext);

  const toggleSidebar = (params, forceSidebarId) => {
    toggle(forceSidebarId ?? sidebarId, params);
  };

  const openSidebar = (params, forceSidebarId) => {
    open(forceSidebarId ?? sidebarId, params);
  };

  const closeSidebar = (params, forceSidebarId) => {
    close(forceSidebarId ?? sidebarId, params);
  };

  const closeAllSidebars = () => {
    Object.values(sidebarIds).forEach((sidebarId) => {
      if (sidebars[sidebarId].opened) {
        closeSidebar(sidebars[sidebarId].params, sidebarId);
      }
    });
  };

  return {
    sidebars,
    sidebar: sidebars[sidebarId],
    isOpen: sidebars[sidebarId]?.opened,
    isClosed: !sidebars[sidebarId]?.opened,
    toggleSidebar,
    openSidebar,
    closeSidebar,
    closeAllSidebars,
  };
};

export default useSidebar;
