import { createSlice } from '@reduxjs/toolkit';

import {
  TRANSACTION_HISTORY_DEFAULT_VALUES,
  TRANSACTION_HISTORY_LIMIT,
} from './transactionHistory.constants';

const initialState = {
  filters: TRANSACTION_HISTORY_DEFAULT_VALUES,
  pagination: {
    pageIndex: 0,
    pageSize: TRANSACTION_HISTORY_LIMIT,
  },
};

const transactionHistorySlice = createSlice({
  name: 'transactionHistory',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    resetPagination: (state, action) => {
      state.pagination = initialState.pagination;
    },
  },
});

const transactionHistoryActions = transactionHistorySlice.actions;
const transactionHistoryReducer = transactionHistorySlice.reducer;

export { transactionHistoryActions, transactionHistoryReducer };

export default transactionHistorySlice;
