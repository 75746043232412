import { Children, cloneElement, useEffect, useId, useState } from 'react';

import { NOOP } from '@constants';

/**
 * @tabActiveIndex
 * @onTabIndexChanged
 * these props can be used if you need to check tab changes in
 * parent component (see trades block like example)
 * */

const Tabs = ({ children, tabActiveIndex = 0, onTabIndexChanged = NOOP }) => {
  const id = useId();

  const [selectedTabIndex, setSelectedTabIndex] = useState(tabActiveIndex);

  useEffect(() => {
    if (selectedTabIndex !== tabActiveIndex) {
      setSelectedTabIndex(tabActiveIndex);
    }
  }, [tabActiveIndex]);

  const onTabClick = ({ tabIndex, isSelect }) => {
    setSelectedTabIndex(tabIndex);
    onTabIndexChanged({ tabIndex, isSelect });
  };

  return Children.map(children, (child) =>
    cloneElement(child, {
      id,
      selectedTabIndex,
      onTabClick,
    }),
  );
};

export default Tabs;
