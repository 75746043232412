import { createSelector } from '@reduxjs/toolkit';

import { OVERVIEW_EARN_WIDGETS } from './overviewEarnWidgets.constants';

const widgetsSelector = (state) => {
  return state.overview.earnWidgets.widgets;
};

const activeWidgetsSelector = createSelector(widgetsSelector, (widgets) => {
  return widgets
    .filter((widget) => widget.isActive)
    .map((widget) =>
      OVERVIEW_EARN_WIDGETS.find(
        ({ widgetId }) => widget.widgetId === widgetId,
      ),
    );
});

export const OverviewEarnWidgetsSelectors = {
  widgetsSelector,
  activeWidgetsSelector,
};
