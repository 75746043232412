import { WalletsApiSelectors } from '../selectors';
import { useGetWithdrawalsQuery } from '../walletsAPI';

const useGetWithdrawalsQueryResult = (args, options = {}) => {
  const result = useGetWithdrawalsQuery(args, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      withdrawals: WalletsApiSelectors.withdrawalsSelector(result),
      meta: WalletsApiSelectors.metaSelector(result),
    }),
  });

  return result;
};

export default useGetWithdrawalsQueryResult;
