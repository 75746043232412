import React, { memo } from 'react';

import cx from 'classnames';

import styles from './SectionCaption.module.scss';

const SectionCaption = ({ inversion, children }) => {
  return (
    <div
      className={cx(styles.caption, {
        [styles.inversion]: inversion,
      })}
    >
      {children}
    </div>
  );
};

export default memo(SectionCaption);
