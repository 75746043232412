import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFormContext, useWatch } from 'react-hook-form';

import { Button } from '@shared/ui';

import { SideBarCaption } from '@components/SideBar/components';

import { SpotAccountForm } from '@pages/WalletsPage/VisaCardDetails/components';
import { SpotAccountMethodDetails } from '@pages/WalletsPage/VisaCardDetails/components/sidebars/SideBarTopUpCard/components';
import { TOP_UP_METHODS } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.constants';
import { topUpMethodSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

const TopUpMethods = () => {
  const topUpMethod = useSelector(topUpMethodSelector);

  const methods = useFormContext();

  const currencyWatch = useWatch({
    control: methods.control,
    name: 'currency',
  });

  const amountWatch = useWatch({ control: methods.control, name: 'amount' });

  if (topUpMethod === TOP_UP_METHODS.SPOT_ACCOUNT) {
    return (
      <>
        <SpotAccountForm />

        <div className="mt-32">
          <SpotAccountMethodDetails
            selectedCurrency={currencyWatch}
            amount={amountWatch}
          />
        </div>
      </>
    );
  }

  const otherTopUpMethodsLinks = [
    {
      id: TOP_UP_METHODS.SEPA_BANK_TRANSFER,
      label: <FormattedMessage id="SEPABankTransfer" />,
      link: '/',
    },
    {
      id: TOP_UP_METHODS.SEPA_INSTANT,
      label: <FormattedMessage id="SEPAInstant" />,
      link: '/',
    },
    {
      id: TOP_UP_METHODS.SWIFT_BANK_TRANSFER,
      label: <FormattedMessage id="SWIFTBankTransfer" />,
      link: '/',
    },
    {
      id: TOP_UP_METHODS.ADVCASH_DEPOSIT,
      label: <FormattedMessage id="AdvcashDeposit" />,
      link: '/',
    },
    {
      id: TOP_UP_METHODS.CREDIT_DEBIT_CARD,
      label: <FormattedMessage id="CreditOrDebitCard" />,
      link: '/',
    },
    {
      id: TOP_UP_METHODS.CRYPTO_DEPOSIT,
      label: <FormattedMessage id="CryptoDeposit" />,
      link: '/',
    },
  ];

  return (
    <div>
      <SideBarCaption>
        <FormattedMessage id="OtherWaysToTopUp" />
      </SideBarCaption>
      <div className="mt-10 d-flex justify-content-between">
        <div>
          {otherTopUpMethodsLinks.slice(0, 3).map((method) => (
            <Button
              key={method.id}
              size="s"
              inversion
              variant="text"
              navigation={{ to: method.link }}
            >
              {method.label}
            </Button>
          ))}
        </div>
        <div>
          {otherTopUpMethodsLinks.slice(3).map((method) => (
            <Button
              key={method.id}
              size="s"
              inversion
              variant="text"
              navigation={{ to: method.link }}
            >
              {method.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopUpMethods;
