import { useSelector } from 'react-redux';

import { useGetFeesInfoQuery } from '@store/api/profileAPI';
import { isGuestUserSelector } from '@store/settings/settingsSelectors';

import { FeesApiSelectors } from '../selectors';

const useGetFeesInfoQueryResult = ({
  capitalizeDiscountLevel = false,
} = {}) => {
  const isGuest = useSelector(isGuestUserSelector);

  const result = useGetFeesInfoQuery(undefined, {
    skip: isGuest,
    selectFromResult: (result) => ({
      ...result,
      feeInfo: FeesApiSelectors.dataSelector(result),
      discountLevel: FeesApiSelectors.discountLevelSelector(
        result,
        capitalizeDiscountLevel,
      ),
      tradingFee: FeesApiSelectors.tradingFeeSelector(result),
    }),
  });

  return result;
};

export default useGetFeesInfoQueryResult;
