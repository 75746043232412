import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { InfoWidget, PersonalData, Widget } from '@shared/components';
import { ACCOUNT_GROUP, CRYPTO_CURRENCIES } from '@shared/constants';
import { useSpotTotalBalances, useTotalBalance } from '@shared/hooks';

import Slider from '@components/Slider/Slider';

import { useSpotAccounts } from '@api/hooks';

import { format } from '@utils/numbers';

import styles from './SpotMarginComparisonWidget.module.scss';
import mockData from 'src/mock.json';

const SpotMarginComparisonWidget = () => {
  const { Margin } = mockData.SpotMarginComparisonWidget;
  const { spotAccountsLength } = useSpotAccounts();

  const currency = CRYPTO_CURRENCIES.BTC;

  const { totalBalance } = useTotalBalance({
    coinSymbol: currency,
    accountId: ACCOUNT_GROUP.MAIN,
  });

  const { totalBalance: spotTotalBalance } = useSpotTotalBalances({
    coinSymbol: currency,
  });

  const spotTotalBalancePercent = useMemo(() => {
    if (totalBalance <= 0) return 0;

    const percent = (spotTotalBalance * 100) / totalBalance;

    if (isNaN(percent)) return 0;

    return format(percent, {
      precision: 0,
    });
  }, [spotTotalBalance, totalBalance]);

  return (
    <Widget className={styles.spot_margin_widget}>
      <div className="d-flex gap-30 gap-xl-45">
        <InfoWidget
          customStyles={styles}
          title={
            <>
              <FormattedMessage id="Spot" /> (
              <PersonalData data={spotAccountsLength} />)
            </>
          }
          value={<PersonalData data={spotTotalBalancePercent} />}
          suffix={
            <span className={classNames(styles.suffix, 'percent-suffix fs-14')}>
              %
            </span>
          }
        />

        <InfoWidget
          customStyles={styles}
          title={
            <>
              <FormattedMessage id="Margin" /> (
              <PersonalData data={Margin.quantity} />)
            </>
          }
          value={<PersonalData data={Margin.percent} />}
          suffix={
            <span className={classNames(styles.suffix, 'percent-suffix fs-14')}>
              %
            </span>
          }
        />
      </div>

      <PersonalData
        data={
          <Slider
            customStyles={styles}
            step={2}
            value={spotTotalBalancePercent}
            disabled
            min={0}
            max={100}
          />
        }
      />
    </Widget>
  );
};

export default memo(SpotMarginComparisonWidget);
