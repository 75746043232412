import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { SecurityLevelProgressBar } from '@pages/AccountPage/components';
import { useSecurityLevelColor } from '@pages/AccountPage/hooks';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

import styles from './MySecurityLevelWidget.module.scss';

const MySecurityLevelWidget = ({ title }) => {
  const { activeSecuritySettings, securitySettings } =
    useGetProfileInfoQueryResult();

  const { color, colorName } = useSecurityLevelColor();

  return (
    <div className={styles.widget}>
      <div className={styles.title}>{title}</div>
      <div className={styles.security_info}>
        <div className={styles.progress_bar_info}>
          <div style={{ color }} className={styles.level_name}>
            <FormattedMessage id={colorName} />
          </div>
          <div className={styles.level}>
            <span className={styles.current}>
              {activeSecuritySettings.length}
            </span>
            <span className={styles.max}>/{securitySettings.length}</span>
          </div>
        </div>
        <SecurityLevelProgressBar inversion />
      </div>
    </div>
  );
};

export default memo(MySecurityLevelWidget);
