import { useMemo } from 'react';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

import { KYC_LEVELS } from '../constants';

const DEFAULT_KYC_LEVELS = [
  KYC_LEVELS.FIRST,
  KYC_LEVELS.SECOND,
  KYC_LEVELS.THIRD,
];

const useKYCLevelsStatuses = (kycLevels = DEFAULT_KYC_LEVELS) => {
  const { userKycLevel } = useGetProfileInfoQueryResult();

  const kycLevelsStatuses = useMemo(() => {
    return kycLevels.map((level) => ({
      value: level,
      isActive: level === userKycLevel,
    }));
  }, [kycLevels, userKycLevel]);

  return { kycLevelsStatuses, userKycLevel };
};

export default useKYCLevelsStatuses;
