import { endOfDay, startOfDay, subDays } from 'date-fns';

import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';
import {
  ALL_FILTER_KEY,
  CRYPTO_CURRENCIES,
  ORDER_STATUS,
} from '@shared/constants';

export const ORDERS_ITEMS_PER_PAGE = 10;

export const HISTORY_FILENAME = 'report';
export const TRADE_FEES_FILENAME = 'trade_fees_report';

export const BTC_COIN = CRYPTO_CURRENCIES.BTC;
export const USDT_COIN = CRYPTO_CURRENCIES.USDT;

const TODAY_DATE = new Date();
const THIRTY_DAYS = 30;

const FROM_DATE = startOfDay(subDays(TODAY_DATE, THIRTY_DAYS)).getTime();
const TO_DATE = endOfDay(TODAY_DATE).getTime();

export const ORDER_HISTORY_FILTERS_DEFAULT_VALUES = {
  datePeriod: {
    fromDate: FROM_DATE,
    toDate: TO_DATE,
  },
  [TABLE_FILTERS_NAMES.DATE_PICKER]: {
    startDate: FROM_DATE,
    endDate: TO_DATE,
  },
  pairFrom: ALL_FILTER_KEY,
  pairTo: ALL_FILTER_KEY,
  sideParam: ALL_FILTER_KEY,
  status: ORDER_STATUS.FILLED,
};

export const TRADE_HISTORY_FILTERS_DEFAULT_VALUES = {
  datePeriod: {
    fromDate: FROM_DATE,
    toDate: TO_DATE,
  },
  pairFrom: ALL_FILTER_KEY,
  pairTo: ALL_FILTER_KEY,
  sideParam: ALL_FILTER_KEY,
};

export const TRADE_FEES_DEFAULT_VALUES = {
  datePeriod: {
    fromDate: FROM_DATE,
    toDate: TO_DATE,
  },
  pairFrom: ALL_FILTER_KEY,
  pairTo: ALL_FILTER_KEY,
  sideParam: ALL_FILTER_KEY,
};
