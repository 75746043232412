import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { ColumnHeaderItem, PersonalData } from '@shared/components';
import { DAY_MONTH_YEAR_TIME_FORMAT } from '@shared/constants';

import {
  AmountItem,
  AssetItem,
  DateItem,
  TypeItem,
  DetailsItem,
  StatusItem,
} from '@pages/WalletsPage/components';
import { TRANSACTION_HISTORY_STATUS_COLOR } from '@pages/WalletsPage/TransactionHistory/transactionHistory.constants';

const { accessor } = createColumnHelper();

const LastTransactionsTableColumns = ({ isSwipeableTable }) => [
  accessor('asset', {
    header: () => <ColumnHeaderItem id="Asset" />,
    cell: (info) => {
      const coinSymbol = info.getValue();

      return (
        <PersonalData
          data={
            <AssetItem
              isShowSymbol={!isSwipeableTable}
              isShowFullName={!isSwipeableTable}
              coinSymbol={coinSymbol}
            />
          }
        />
      );
    },
    maxSize: 85,
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
  }),
  ...(isSwipeableTable
    ? [
        accessor('asset', {
          header: () => null,
          cell: (info) => {
            const coinSymbol = info.getValue();

            return (
              <PersonalData
                data={
                  <AssetItem
                    isShowFullName
                    isShowCurrencyIcon={false}
                    coinSymbol={coinSymbol}
                  />
                }
              />
            );
          },
          maxSize: 85,
          cellAlign: 'start',
          headerAlign: 'start',
          enableSorting: false,
        }),
      ]
    : []),

  accessor('type', {
    header: () => <ColumnHeaderItem id="Type" />,
    cell: (info) => {
      const type = info.getValue();

      return <TypeItem type={type} />;
    },
    maxSize: 60,
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
  }),
  accessor('details', {
    header: () => <ColumnHeaderItem id="Details" />,
    cell: (info) => {
      const { address, txid } = info.getValue();

      return (
        <DetailsItem address={address} txid={txid} isShowPersonalData={false} />
      );
    },
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
  }),
  accessor('amount', {
    header: () => <ColumnHeaderItem id="Amount" />,
    cell: (info) => {
      const { amount, precision } = info.getValue();

      return (
        <PersonalData
          data={<AmountItem amount={amount} precision={precision} />}
        />
      );
    },
    maxSize: 60,
    enableSorting: false,
  }),
  accessor('date', {
    header: () => <ColumnHeaderItem id="Date" />,
    cell: (info) => {
      const date = info.getValue();

      return (
        <PersonalData
          data={
            <DateItem date={date} formatType={DAY_MONTH_YEAR_TIME_FORMAT} />
          }
        />
      );
    },
    maxSize: 60,
    enableSorting: false,
  }),
  accessor('status', {
    header: () => <ColumnHeaderItem id="Status" />,
    cell: (info) => {
      const status = info.getValue();

      return (
        <StatusItem
          status={status}
          statusColors={TRANSACTION_HISTORY_STATUS_COLOR}
        />
      );
    },
    maxSize: 30,
    enableSorting: false,
  }),
];

export default LastTransactionsTableColumns;
