import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  SideBarBlock,
  SideBarInfoPoint,
  SideBarList,
} from '@shared/components';
import { Button } from '@shared/ui';

import SideBar from '@components/SideBar';

import {
  spotAccountFormDetailsSelector,
  visaCardSelector,
} from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import s from './SideBarConfirmTopUp.module.scss';

const SideBarConfirmTopUp = () => {
  const intl = useIntl();

  const { openSidebar: openTopUpCardSidebar } = useSidebar(
    sidebarIds.TOP_UP_CARD,
  );
  const { isOpen, closeSidebar } = useSidebar(sidebarIds.CONFIRM_TOP_UP);

  const visaCard = useSelector(visaCardSelector);
  const spotAccountFormDetails = useSelector(spotAccountFormDetailsSelector);

  const TopUpDetails = useMemo(
    () => [
      {
        id: 'fromSpotAccount',
        label: `${intl.formatMessage({ id: 'FromSpotAccount' })}:`,
        value: 'All', // TODO: figure out if need to add selector with spot accounts
      },
      {
        id: 'toCardNumber',
        label: `${intl.formatMessage({ id: 'ToCardNumber' })}:`,
        value: visaCard.number,
      },
      {
        id: 'amount',
        label: `${intl.formatMessage({ id: 'Amount' })}:`,
        value: `${spotAccountFormDetails.amount} ${spotAccountFormDetails.currency}`,
      },
      {
        id: 'currentPrice',
        label: `${intl.formatMessage({ id: 'CurrentPrice' })}:`,
        value: spotAccountFormDetails.currentPrice,
      },
      {
        id: 'Commission',
        label: `${intl.formatMessage({ id: 'Commission' })}:`,
        value: spotAccountFormDetails.commission.formattedValue,
      },
      {
        id: 'youWillReceive',
        label: `${intl.formatMessage({ id: 'YouWillReceive' })}:`,
        value: spotAccountFormDetails.profit,
      },
      {
        id: 'date',
        label: `${intl.formatMessage({ id: 'Date' })}:`,
        value: `${intl.formatDate(new Date())}`,
      },
    ],
    [intl, visaCard, spotAccountFormDetails],
  );

  const handleCloseSideBar = () => closeSidebar();

  const handleOpenTopUpCardSidebar = () => openTopUpCardSidebar();

  const handleConfirm = () => {
    // TODO: add endpoint to confirm
    closeSidebar();
  };

  return (
    <SideBar
      withBackButton
      onBack={handleOpenTopUpCardSidebar}
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleCloseSideBar}
      withCloseButton
      title={intl.formatMessage({ id: 'ConfirmTopUp' })}
    >
      <div className={s.sidebar}>
        <div>
          <div className="mt-10">
            <SideBarBlock>
              <SideBarList items={TopUpDetails} />
            </SideBarBlock>
          </div>

          <div className="mt-10">
            <SideBarBlock>
              <SideBarInfoPoint
                title={intl.formatMessage({
                  id: 'ConfirmTopUpInfoTitle',
                })}
                description={intl.formatMessage({
                  id: 'ConfirmTopUpInfoDescription',
                })}
              />
            </SideBarBlock>
          </div>
        </div>

        <div className={s.controls}>
          <Button
            fill
            type="button"
            color="secondary"
            size="l"
            inversion
            onClick={handleConfirm}
          >
            <FormattedMessage id="YesConfirm" />
          </Button>
          <Button
            fill
            type="button"
            onClick={handleCloseSideBar}
            inversion
            size="l"
            variant="outlined"
          >
            <FormattedMessage id="Cancel" />
          </Button>
        </div>
      </div>
    </SideBar>
  );
};

export default SideBarConfirmTopUp;
