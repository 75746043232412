import { createSelector } from '@reduxjs/toolkit';

import { ApiSelectorsUtils } from '../utils';

const defaultData = [];
const defaultCardBalance = 0;

const availableCardsSelector = createSelector(
  ApiSelectorsUtils.createDataSelector(defaultData),
  (cards) => {
    // TODO understand if it's necessary filter by is_allowed flag
    // const availableCards = cards.filter(({ is_allowed }) => is_allowed);

    return cards.map(({ card }) => ({ id: card.Id, ...card }));
  },
);

const availableCardsMappedSelector = createSelector(
  ApiSelectorsUtils.createDataSelector(defaultData),
  (cards) => {
    const availableCards = cards.filter((card) => card.is_allowed);

    return availableCards.reduce(
      (acc, cur) => ({ ...acc, [cur.card.code]: cur.card }),
      {},
    );
  },
);

const cardBalanceSelector =
  ApiSelectorsUtils.createDataSelector(defaultCardBalance);

const cardDetails = ApiSelectorsUtils.createDataSelector(defaultData);

const cardAccountDetails = ApiSelectorsUtils.createDataSelector(defaultData);

export const VisaApiSelectors = {
  availableCardsSelector,
  availableCardsMappedSelector,
  cardBalanceSelector,
  cardDetails,
  cardAccountDetails,
};
