import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DownloadFromStoreButton } from '@shared/components';
import { PLAY_MARKET_MOBILE_APP_LINK } from '@shared/constants';

import { ReactComponent as PlayMarketSvg } from '@images/icons/play-market-icon.svg';

const DownloadFromPlayMarketButton = ({ ...props }) => {
  const getIconSize = () => {
    if (props.size === 'l') {
      return { width: 20, height: 21 };
    }

    return { width: 16, height: 17 };
  };

  const { width, height } = getIconSize();

  return (
    <DownloadFromStoreButton
      navigation={{ to: PLAY_MARKET_MOBILE_APP_LINK }}
      icon={<PlayMarketSvg width={width} height={height} />}
      text={<FormattedMessage id="GetItOn" />}
      subText={<FormattedMessage id="GooglePlay" />}
      variant="contained"
      color="primary"
      {...props}
    />
  );
};

export default memo(DownloadFromPlayMarketButton);
