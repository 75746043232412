import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { FreeMode, Mousewheel, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AddWidgetPlaceholder } from '@shared/components';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import { activeWidgetsSelector } from './walletsWidgets.selectors';

import styles from './Widgets.module.scss';

import 'swiper/css';

const SMALL_SPACE_BETWEEN = 20;

const Widgets = ({ estimatedCurrency, onEstimatedCurrencyChange }) => {
  const activeWidgets = useSelector(activeWidgetsSelector);
  const {
    isMobile,
    isLargeTabletUp,
    isTabletUp,
    isLargeTabletDown,
    isLargeTablet,
    isLaptop,
  } = useBreakpoints();
  const { openModal } = useModal(modalsIds.WALLETS_WIDGETS);
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    swiperRef.current.swiper?.slideTo?.(0, 0);
  }, [activeWidgets.length]);

  const handleClick = () => {
    openModal();
  };

  const isMenuVisible = isLargeTabletUp && isLargeTabletDown;

  const spaceBetween = isMenuVisible ? SMALL_SPACE_BETWEEN : 0;

  const getSlidesOffsetAfter = () => {
    if (isMobile) {
      return SMALL_SPACE_BETWEEN;
    }

    return 36;
  };

  const getSpaceBetweenSlides = (index) => {
    if (isMobile || (isTabletUp && isLargeTabletDown)) {
      return SMALL_SPACE_BETWEEN;
    }

    if (isLaptop) {
      return index === 0 ? 36 : SMALL_SPACE_BETWEEN;
    }

    return SMALL_SPACE_BETWEEN;
  };

  return (
    <div className={styles.widgets}>
      <Swiper
        ref={swiperRef}
        modules={[Mousewheel, FreeMode, Scrollbar]}
        spaceBetween={spaceBetween}
        touchAngle={70}
        slidesOffsetAfter={getSlidesOffsetAfter()}
        freeMode={{ momentumRatio: 0.5 }}
        mousewheel={{ forceToAxis: true, thresholdDelta: 2 }}
        slidesPerView="auto"
        grabCursor
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
        }}
      >
        {activeWidgets.map((widget, index) => {
          const Component = widget.component;

          return (
            <SwiperSlide
              style={{
                width: 'auto',
                ...(isMenuVisible
                  ? {}
                  : { marginLeft: getSpaceBetweenSlides(index) }),
              }}
              key={widget.widgetId}
            >
              <Component
                estimatedCurrency={estimatedCurrency}
                onEstimatedCurrencyChange={onEstimatedCurrencyChange}
                activeIndex={activeIndex}
              />
            </SwiperSlide>
          );
        })}
        <SwiperSlide
          key="add-widget"
          style={{
            width: '145px',
            ...(isMenuVisible ? {} : { marginLeft: getSpaceBetweenSlides() }),
            ...(activeWidgets.length === 0 && isLargeTablet
              ? { marginLeft: getSpaceBetweenSlides(0) }
              : {}),
          }}
        >
          <div className={styles.add_widget}>
            <AddWidgetPlaceholder customStyles={styles} onClick={handleClick} />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default memo(Widgets);
