import { memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import {
  AccountModal,
  CountriesSelectController,
  getCountriesOptions,
} from '@shared/components';
import { Button, InputController } from '@shared/ui';
import { validateEmail } from '@shared/validations';

import { useVisaMutations } from '@api/hooks';

import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import styles from './JoinWaitListModal.module.scss';

const JoinWaitListModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.JOIN_WAIT_LIST,
  );

  const { onJoinToCardWaitList, isJoinToCardWaitListLoading } =
    useVisaMutations();

  const { selectedCountryCode } = modal.params;

  const intl = useIntl();
  const methods = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      country: '',
    },
  });
  const { setValue, reset, handleSubmit } = methods;

  const { openModal: openSuccessJoinWaitlistModal } = useModal(
    modalsIds.SUCCESS_JOIN_WAIT_LIST,
  );

  const transformValues = (values) => ({
    ...values,
    country: values.country.label,
  });

  const handleFormSubmit = async (values) => {
    try {
      await onJoinToCardWaitList(transformValues(values));

      closeModal();
      openSuccessJoinWaitlistModal();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectedCountryCode) {
      const selectedCountry = getCountriesOptions().find(
        ({ value }) =>
          value.toLowerCase() === selectedCountryCode.toLowerCase(),
      );

      setValue('country', selectedCountry);
    }
  }, [selectedCountryCode, isOpen, setValue]);

  const commonFieldProps = {
    bordered: false,
    underlined: true,
    size: 'l',
    customStyles: styles,
  };

  const handleAfterClose = () => {
    reset();
    resetModal();
  };

  return (
    <AccountModal
      title={
        <span className={styles.title}>
          <FormattedMessage id="JoinTheWaitList" />
        </span>
      }
      className={styles.modal}
      onClose={closeModal}
      afterClose={handleAfterClose}
      isOpen={isOpen}
    >
      <div className={styles.container}>
        <div className={styles.description_block}>
          <div className={styles.description}>
            <FormattedMessage id="JoinTheWaitListDescription" />
          </div>

          <div className={styles.annotation}>
            <FormattedMessage id="JoinTheWaitListAnnotation" />
          </div>
        </div>

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className={classNames('d-flex flex-column', styles.form)}
        >
          <div className="d-flex flex-column flex-md-row gap-24">
            <div className="w-100">
              <InputController
                name="first_name"
                placeholder={`${intl.formatMessage({ id: 'FirstName' })}...`}
                rules={{
                  required: intl.formatMessage({ id: 'FieldRequired' }),
                }}
                {...commonFieldProps}
                {...methods}
              />
            </div>

            <div className="w-100">
              <InputController
                name="last_name"
                placeholder={`${intl.formatMessage({ id: 'LastName' })}...`}
                rules={{
                  required: intl.formatMessage({ id: 'FieldRequired' }),
                }}
                {...commonFieldProps}
                {...methods}
              />
            </div>
          </div>

          <InputController
            name="email"
            type="email"
            placeholder={`${intl.formatMessage({ id: 'EnterYourEmail' })}...`}
            rules={{
              required: intl.formatMessage({ id: 'FieldRequired' }),
              validate: {
                format: validateEmail(intl),
              },
            }}
            {...commonFieldProps}
            {...methods}
          />

          <CountriesSelectController
            name="country"
            rules={{
              required: intl.formatMessage({ id: 'FieldRequired' }),
            }}
            {...commonFieldProps}
            {...methods}
          />

          <div className="mt-6">
            <Button
              isLoading={isJoinToCardWaitListLoading}
              size="l"
              fill
              color="secondary"
              type="submit"
            >
              <FormattedMessage id="Join" />
            </Button>
          </div>
        </form>
      </div>
    </AccountModal>
  );
};

export default memo(JoinWaitListModal);
