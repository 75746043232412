import React, { memo } from 'react';

import { format } from 'date-fns';

import { YEAR_MONTH_DAY_TIME_FORMAT } from '@shared/constants';

import styles from './ExpiredDateItem.module.scss';

const ExpiredDateItem = ({ expiredDate }) => {
  const value = format(new Date(expiredDate), YEAR_MONTH_DAY_TIME_FORMAT);

  return <span className={styles.grey}>{value}</span>;
};

export default memo(ExpiredDateItem);
