import React, { memo } from 'react';

import cx from 'classnames';

import styles from './AuthLayoutContainer.module.scss';

const AuthLayoutContainer = ({ className, children }) => {
  return <div className={cx(className, styles.container)}>{children}</div>;
};

export default memo(AuthLayoutContainer);
