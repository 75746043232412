import { FormattedMessage } from 'react-intl';

import { DefaultAvatar } from '@shared/components';
import { Button } from '@shared/ui';

import { ReactComponent as CheckMarkIconSVG } from '@icons/check-mark-icon-small.svg';
import { ReactComponent as CrossThinIconSVG } from '@icons/cross-thin-icon.svg';
import { ReactComponent as SaveIconSVG } from '@icons/save-icon.svg';

import s from './SelectedUserCard.module.scss';

const SelectedUserCard = ({ user, onClearUser }) => {
  const handleSaveUserToFavorites = () => {
    console.log('handleSaveUserToFavorites');
  };

  const handleClearSelectedUser = () => {
    onClearUser();
  };

  return (
    <div>
      <div className={s.user_card}>
        <div className={s.user_card__info}>
          <div>
            <DefaultAvatar avatarId={user.id} width={48} height={48} />
          </div>
          <div>
            <div className={s.user_card__name}>{user.name}</div>
            <div className={s.user_card__email}>{user.email}</div>
          </div>
        </div>
        <div>
          <CheckMarkIconSVG />
        </div>
      </div>

      <div className={s.user_card__controls}>
        <Button
          type="button"
          onClick={handleSaveUserToFavorites}
          variant="text"
          size="xs"
          inversion
          customStyles={s}
        >
          <div className="d-flex align-items-center gap-10">
            <SaveIconSVG className={s.control_icon} />
            <FormattedMessage id="SaveToFavorites" />
          </div>
        </Button>
        <Button
          type="button"
          onClick={handleClearSelectedUser}
          variant="text"
          size="xs"
          inversion
          customStyles={s}
        >
          <div className="d-flex align-items-center gap-8">
            <FormattedMessage id="Clear" />
            <CrossThinIconSVG className={s.control_icon} />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SelectedUserCard;
