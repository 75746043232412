import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useBots, useBotsPnl } from '@store/api/hooks';

import { MyBotsSelectors } from '../MyBotsTab/myBots.selectors';

import useBotsTableData from './useBotsTableData';

const selectFromResult = ({
  botsMap,
  paginatedBots,
  filteredBotsLength,
  isBotsSuccess,
  isBotsPnlSuccess,
}) => {
  const dataResult = {
    myBots: paginatedBots,
    myBotsCount: filteredBotsLength,
    allMyBotsLength: botsMap.length,
    isMyBotsSuccess: isBotsSuccess && isBotsPnlSuccess,
  };

  return dataResult;
};

const useMyBotsTableData = (args, options) => {
  const filters = useSelector(MyBotsSelectors.filtersSelector);
  const pagination = useSelector(MyBotsSelectors.paginationSelector);

  const { botsMap, isBotsSuccess } = useBots(undefined, options);
  const { isBotsPnlSuccess } = useBotsPnl(undefined, options);
  const { paginatedBots, filteredBotsLength } = useBotsTableData(
    {
      filters,
      pagination,
      bots: botsMap,
    },
    options,
  );

  const data = useMemo(() => {
    return selectFromResult({
      botsMap,
      paginatedBots,
      filteredBotsLength,
      isBotsSuccess,
      isBotsPnlSuccess,
    });
  }, [
    botsMap,
    paginatedBots,
    filteredBotsLength,
    isBotsSuccess,
    isBotsPnlSuccess,
  ]);

  return data;
};

export default useMyBotsTableData;
