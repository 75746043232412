import { Children, cloneElement, memo } from 'react';

const TabPanels = ({ selectedTabIndex, children }) => {
  const tabs = Children.map(children, (child, tabIndex) => {
    return child
      ? cloneElement(child, {
          selectedTabIndex,
          tabIndex,
        })
      : null;
  });

  return tabs;
};

export default memo(TabPanels);
