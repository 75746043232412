import { createApi } from '@reduxjs/toolkit/query/react';
import reactFastCompare from 'react-fast-compare';

import client from '@api/client';
import subscriptions from '@api/ws/subscriptions';

import { get24hAgoInSec } from '@utils';

import { DEFAULT_REQUEST_LIMIT } from '@constants';

export const tradesAPI = createApi({
  reducerPath: 'tradesApi',
  baseQuery: client,
  endpoints: (builder) => ({
    getUserTrades: builder.query({
      query: ({
        marketId,
        accountId,
        since = get24hAgoInSec(),
        page = 1,
        limit = DEFAULT_REQUEST_LIMIT,
      }) => {
        return {
          url: `/trades/${marketId}`,
          params: { account: accountId, since, page, limit },
        };
      },
      transformResponse: (response) => response.data,
    }),
    getTradesHistory: builder.query({
      query: ({
        accountId,
        status = 'trades',
        type = 'trades',
        page = 1,
        sideParam,
        fromDate = get24hAgoInSec(),
        toDate = null,
        limit = DEFAULT_REQUEST_LIMIT,
        takerSideParam,
        pairFrom,
        pairTo,
      }) => {
        return {
          url: 'users/trades',
          params: {
            status,
            type,
            account: accountId,
            page,
            fromDate,
            toDate,
            limit,
            sideParam,
            takerSideParam,
            market_coin_symbol: pairFrom,
            quote_coin_symbol: pairTo,
          },
        };
      },
    }),
    getCustomRangeHistory: builder.query({
      query: ({ fromDate, toDate }) => ({
        url: 'users/trades/history-request',
        params: { fromDate, toDate },
      }),
    }),
    streamTrades: builder.query({
      queryFn: () => ({ data: undefined }),
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(
        marketId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded;

          subscriptions.subscribeTrades(
            marketId,
            ({ data, reason }) => {
              updateCachedData((draftState) => {
                if (reason === 'error') {
                  return null;
                }

                if (reason === 'unsubscribe') {
                  return draftState;
                }

                if (!draftState || !reactFastCompare(draftState.trades, data)) {
                  return { trades: data, marketId };
                }
              });
            },
            { reconnect: true },
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        await cacheEntryRemoved;

        // subscriptions.unsubscribeTrades();
      },
    }),
    exportFile: builder.query({
      query: ({
        accountId,
        status = 'trades',
        type = 'trades',
        page = 1,
        fromDate = get24hAgoInSec(),
        toDate = null,
        format,
        takerSideParam,
        pairFrom,
        pairTo,
      }) => {
        return {
          url: '/users/orders/export',
          params: {
            account: accountId,
            status,
            type,
            page,
            fromDate,
            toDate,
            format,
            takerSideParam,
            market_coin_symbol: pairFrom,
            quote_coin_symbol: pairTo,
          },
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useStreamTradesQuery,
  useGetTradesHistoryQuery,
  useLazyGetCustomRangeHistoryQuery,
  useGetUserTradesQuery,
  useLazyExportFileQuery,
} = tradesAPI;
