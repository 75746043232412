import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import cx from 'classnames';

import { FormattedCurrency } from '@shared/components';

import { AllCryptoMarketsSelectors } from '@pages/landings/MarketsPage/AllCryptoMarkets/allCryptoMarkets.selectors';

import { useCoinsRates } from '@api/hooks';

import styles from './PriceCell.module.scss';

const PriceCell = ({
  price,
  priceCurrency,
  customStyles = {},
  isShowConvertedPrice,
  isShowOnlyConvertedPrice,
}) => {
  const selectedCurrency = useSelector(
    AllCryptoMarketsSelectors.selectedCurrencySelector,
  );

  const selectedCurrencySymbol = useSelector(
    AllCryptoMarketsSelectors.selectedCurrencySymbolSelector,
  );

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: priceCurrency,
    toCoinSymbol: selectedCurrency,
  });

  const ConvertedPrice = (
    <>
      {selectedCurrencySymbol}
      <FormattedCurrency
        currency={selectedCurrency}
        value={price * ratesByPair}
      />
    </>
  );

  if (isShowOnlyConvertedPrice) {
    return (
      <div className={cx(styles.price, customStyles.price)}>
        {ConvertedPrice}
      </div>
    );
  }

  return (
    <div className={cx(styles.price, customStyles.price)}>
      <FormattedCurrency currency={priceCurrency} value={price} />
      {isShowConvertedPrice && (
        <>
          {' '}
          /
          <span
            className={cx(styles.converted_price, customStyles.converted_price)}
          >
            {ConvertedPrice}
          </span>
        </>
      )}
    </div>
  );
};

export default memo(PriceCell);
