import BigNumber from 'bignumber.js';

import { format, removeZerosFromEnd } from '@utils/numbers';

import { PERCENT_PRECISION } from '@constants';

import { MAX_PERCENT, MIN_PERCENT } from './botStart.constants';

const minInvestment = (intl, investmentLimits, investAsset) => (value) => {
  const investValue = Number(value);

  if (Number(investValue) < investmentLimits.min) {
    return intl.formatMessage(
      {
        id: 'MinAmount',
      },
      {
        value: format(investmentLimits.min, {
          precision: investAsset.precision,
        }),
      },
    );
  }

  return true;
};

const maxInvestment =
  (intl, investmentLimits, investAsset, { pairFrom, pairTo }) =>
  (value) => {
    const investValue = Number(value);

    if (investValue > investmentLimits.max) {
      return intl.formatMessage(
        {
          id: 'MaxAmount',
        },
        {
          value: format(investmentLimits.max, {
            precision: investAsset.precision,
          }),
        },
      );
    }

    if (investValue > investAsset.balance) {
      return intl.formatMessage(
        {
          id: 'MaxInvestmentFunds',
        },
        {
          coin: investAsset.isMarketCoin
            ? pairTo.toUpperCase()
            : pairFrom.toUpperCase(),
          suggestedCoin: investAsset.isMarketCoin
            ? pairFrom.toUpperCase()
            : pairTo.toUpperCase(),
        },
      );
    }

    return true;
  };

const minLowPrice = (intl, minMarketPrice, precision) => (v) => {
  const lowPrice = Number(v);

  if (minMarketPrice > lowPrice) {
    return `${intl.formatMessage({ id: 'botsErrorMinPrice' })} ${format(
      minMarketPrice,
      { precision },
    )}`;
  }

  return true;
};

const maxLowPrice = (intl, highPriceValue) => (v) => {
  const highPrice = Number(highPriceValue) || 0;
  const lowPrice = Number(v);

  if (highPrice <= lowPrice) {
    return intl.formatMessage({
      id: 'LowestShouldBeLowerThanHighestPrice',
    });
  }

  return true;
};

const minHighPrice = (intl, lowPriceValue) => (v) => {
  const lowPrice = Number(lowPriceValue) || 0;
  const highPrice = Number(v);

  if (lowPrice >= highPrice) {
    return intl.formatMessage({
      id: 'HighestShouldBeHigherThanLowestPrice',
    });
  }

  return true;
};

const maxHighPrice = (intl, maxMarketPrice, precision) => (v) => {
  const highPrice = Number(v);

  if (maxMarketPrice < highPrice) {
    return `${intl.formatMessage({
      id: 'MaximumPriceIs',
    })} ${format(maxMarketPrice, { precision })}`;
  }

  return true;
};

const minEntryDistance =
  (intl, isValidate = true) =>
  (v) => {
    if (!isValidate) {
      return true;
    }

    if (v < MIN_PERCENT) {
      return intl.formatMessage(
        {
          id: 'MinAmount',
        },
        { value: format(MIN_PERCENT, { precision: PERCENT_PRECISION }) },
      );
    }

    return true;
  };

const minTakeSaveLossesDistance =
  (intl, takerFee, isValidate = true) =>
  (v) => {
    if (!isValidate) {
      return true;
    }

    const takerFeeBN = new BigNumber(takerFee);
    const takerFeePercent = takerFeeBN.multipliedBy(100).toNumber();

    if (takerFeePercent > v) {
      return intl.formatMessage(
        {
          id: 'MinAmount',
        },
        { value: removeZerosFromEnd(takerFeePercent) },
      );
    }

    return true;
  };

const maxPercent =
  (intl, isValidate = true) =>
  (v) => {
    if (!isValidate) {
      return true;
    }

    if (v > MAX_PERCENT) {
      return intl.formatMessage(
        {
          id: 'MaxAmount',
        },
        { value: format(MAX_PERCENT, { precision: PERCENT_PRECISION }) },
      );
    }

    return true;
  };

export const BotStartValidations = {
  minInvestment,
  maxInvestment,
  minLowPrice,
  maxLowPrice,
  minHighPrice,
  maxHighPrice,
  minEntryDistance,
  maxPercent,
  minTakeSaveLossesDistance,
};
