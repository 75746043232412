import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import { ItemTitle } from '@shared/components';

import LastTransactionsTable from './LastTransactionsTable/LastTransactionsTable';

import { WALLETS_ROUTES } from 'src/routes.constants';

import styles from './LastTransactions.module.scss';

const LastTransactions = () => {
  return (
    <div>
      <ItemTitle
        title={
          <div className={styles.title}>
            <FormattedMessage id="LastTransactions" />
          </div>
        }
        arrowTitle={<FormattedMessage id="TransactionHistory" />}
        navigation={{ to: generatePath(WALLETS_ROUTES.TRANSACTION_HISTORY) }}
      />

      <LastTransactionsTable />
    </div>
  );
};

export default memo(LastTransactions);
