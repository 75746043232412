import { useEffect, useMemo } from 'react';

import { useWatch } from 'react-hook-form';
import flags from 'react-phone-number-input/flags';

import {
  CountriesCodeSidebar,
  CountrySelectOption,
} from '@shared/components/CountriesCodeSelect/components';
import { ORDERED_COUNTRIES_CODES } from '@shared/components/CountriesCodeSelect/countriesCodeSelect.constants';
import { SelectController } from '@shared/ui';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

export const COUNTRY_CODE_NAME = 'area_code';
export const COUNTRY_CODE_DEFAULT = 'US';

const CountriesCodeSelect = ({
  control,
  name = COUNTRY_CODE_NAME,
  onCloseSidebar,
  ...props
}) => {
  const { openSidebar } = useSidebar(sidebarIds.COUNTRY_CODES);

  const selectedCountryCode = useWatch({ control, name });

  const flagIcon = (code) => {
    if (!!code && flags?.[code]) {
      return flags[code](code);
    } else {
      return 'flag';
    }
  };

  const countryCodeOptions = useMemo(() => {
    return ORDERED_COUNTRIES_CODES.map((code) => ({
      value: code,
      label: <CountrySelectOption code={code} flagIcon={flagIcon(code)} />,
      code,
    }));
  }, []);

  useEffect(() => {
    const defaultValue =
      props.formState?.defaultValues?.[COUNTRY_CODE_NAME] ||
      COUNTRY_CODE_DEFAULT;

    props.setValue(
      name,
      countryCodeOptions.find(({ code }) => code === defaultValue),
    );
  }, [countryCodeOptions, name]);

  const handleSelectCountry = (countryCode) => {
    props.setValue(
      name,
      countryCodeOptions.find(({ code }) => code === countryCode),
    );
  };

  return (
    <div>
      <div className="cursor-pointer" onClick={() => openSidebar()}>
        <SelectController
          control={control}
          name={name}
          options={countryCodeOptions}
          isDisabled
          {...props}
        />
      </div>

      <CountriesCodeSidebar
        onSelect={handleSelectCountry}
        selectedCountryCode={selectedCountryCode?.code}
        onCloseSidebar={onCloseSidebar}
      />
    </div>
  );
};

export default CountriesCodeSelect;
