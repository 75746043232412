export const openOrdersControlsSelector = (layoutItemId) => (state) => {
  return {
    showAll:
      state.gridBlocks.openOrdersControls.blockIdsWithShowAll[layoutItemId] ??
      false,
  };
};

export const orderHistoryControlsSelector = (layoutItemId) => (state) => {
  return {
    showAll:
      state.gridBlocks.orderHistoryControls.blockIdsWithShowAll[layoutItemId] ??
      false,
    expand:
      state.gridBlocks.orderHistoryControls.blockIdsWithExpand[layoutItemId],
  };
};

export const balancesControlsSelector = (layoutItemId) => (state) => {
  return {
    showSmallAmount:
      state.gridBlocks.balancesControls.blockIdsWithShowSmallAmount[
        layoutItemId
      ] ?? false,
    showEstimatedValue:
      state.gridBlocks.balancesControls.blockIdsWithShowEstimatedValue[
        layoutItemId
      ] ?? false,
  };
};
export const orderBookControlsSelector = (layoutItemId) => (state) => {
  return {
    pricePrecision:
      state.gridBlocks.orderBookControls.blockIdsWithPricePrecisionValue[
        layoutItemId
      ],
    showAverageAndTotal:
      state.gridBlocks.orderBookControls.blockIdsWithShowAverageAndTotal[
        layoutItemId
      ] ?? false,
    showOrdersFromBook:
      state.gridBlocks.orderBookControls.blockIdsWithShowOrdersFromBook[
        layoutItemId
      ] ?? false,
  };
};
export const selectedOrderBookItemSelector = (state) => {
  return {
    selectedOrderBookItem: state.gridBlocks.selectedOrderBookItem,
  };
};
