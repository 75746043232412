export const GOOGLE_AUTH_APP_STORE_LINK =
  'https://itunes.apple.com/us/app/google-authenticator/id388497605';

export const GOOGLE_AUTH_PLAY_MARKET_LINK =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';

export const PLAY_MARKET_MOBILE_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.paramountdax.exchange';

export const APP_STORE_MOBILE_APP_LINK =
  'https://apps.apple.com/ua/app/paramountdax/id1585379080';
