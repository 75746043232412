import React, { memo } from 'react';

import { components } from 'react-select';

import styles from './CustomMenuList.module.scss';

const { MenuList } = components;

const CustomMenuList = ({ children, ...props }) => {
  return (
    <MenuList className={styles.menu_list} {...props}>
      {children}
    </MenuList>
  );
};

export default memo(CustomMenuList);
