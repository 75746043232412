import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isShowSensitiveInfoSelector } from '@store/settings/settingsSelectors';

import { truncateEmail, truncatePhoneNumber } from '@utils';

import { PERSONAL_DATA_PLUG } from '@constants';

const TRUNCATE_SETTINGS = {
  email: truncateEmail,
  phone: truncatePhoneNumber,
  default: () => PERSONAL_DATA_PLUG,
};

const PersonalData = ({
  type = 'default',
  data,
  isShowPersonalData = true,
  children,
}) => {
  const isShowSensitiveInfo = useSelector(isShowSensitiveInfoSelector);
  const truncate = TRUNCATE_SETTINGS[type];
  const truncatedData = useMemo(
    () => (isShowSensitiveInfo && isShowPersonalData ? data : truncate(data)),
    [data, isShowPersonalData, isShowSensitiveInfo, truncate],
  );

  if (children) {
    return children(truncatedData, isShowSensitiveInfo);
  }

  return truncatedData;
};

export default memo(PersonalData);
