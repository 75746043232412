import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import { LAYOUT_IDS, TERMINAL_URL_QUERIES } from '@shared/constants';

import {
  DEPOSIT_ROUTES,
  SERVICE_ROUTES,
  MAIN_ROUTES,
  TRADE_ROUTES,
  VISA_ROUTES,
  WALLETS_ROUTES,
  REFERRAL_ROUTES,
} from 'src/routes.constants';

export const ONE_THOUSAND = 1000;
export const TOTAL_VALUE_PRECISION = 2;

export const TRADE_LINKS = [
  {
    id: 1,
    name: <FormattedMessage id="SimpleConverter" />,
    path: MAIN_ROUTES.SIMPLE_CONVERTER,
  },
  {
    id: 2,
    name: <FormattedMessage id="BasicExchange" />,
    path: generatePath(MAIN_ROUTES.TERMINAL),
  },
  {
    id: 3,
    name: <FormattedMessage id="Advanced" />,
    path: `${generatePath(MAIN_ROUTES.TERMINAL)}?${
      TERMINAL_URL_QUERIES.LAYOUT_ID
    }=${LAYOUT_IDS.ADVANCED}`,
  },
  {
    id: 4,
    name: <FormattedMessage id="Classic" />,
    path: `${generatePath(MAIN_ROUTES.TERMINAL)}?${
      TERMINAL_URL_QUERIES.LAYOUT_ID
    }=${LAYOUT_IDS.CLASSIC}`,
  },
  {
    id: 5,
    name: <FormattedMessage id="MarginTrading" />,
    path: TRADE_ROUTES.MARGIN_TRADING,
  },
  {
    id: 6,
    name: <FormattedMessage id="OTCPlatform" />,
    path: TRADE_ROUTES.OTC_PLATFORM,
  },
  {
    id: 7,
    name: <FormattedMessage id="Features" />,
    path: TRADE_ROUTES.FEATURES,
  },
];

export const PRODUCT_LINKS = [
  {
    id: 1,
    name: <FormattedMessage id="BuyCrypto" />,
    path: DEPOSIT_ROUTES.CARD,
  },
  {
    id: 2,
    name: <FormattedMessage id="VisaCards" />,
    path: VISA_ROUTES.ORDER_CARD,
  },
  {
    id: 3,
    name: <FormattedMessage id="TradingBots" />,
    path: WALLETS_ROUTES.TRADING_BOTS,
  },
  {
    id: 4,
    name: <FormattedMessage id="InstantBonus" />,
    path: WALLETS_ROUTES.INSTANT_BONUS,
  },
  {
    id: 5,
    name: <FormattedMessage id="LoanCrypto" />,
    path: WALLETS_ROUTES.LOAN_CRYPTO,
  },
  {
    id: 6,
    name: <FormattedMessage id="StakingAssets" />,
    path: WALLETS_ROUTES.STAKING,
  },
  {
    id: 7,
    name: <FormattedMessage id="ReferralProgram" />,
    path: REFERRAL_ROUTES.OVERVIEW,
  },
  {
    id: 8,
    name: <FormattedMessage id="Launchpad" />,
    path: WALLETS_ROUTES.LAUNCHPAD,
  },
];

export const ABOUT_LINKS = [
  {
    id: 1,
    name: <FormattedMessage id="AboutUs" />,
    path: SERVICE_ROUTES.ABOUT_US,
  },
  {
    id: 2,
    name: <FormattedMessage id="PRDXToken" />,
    path: SERVICE_ROUTES.PRDX_TOKEN,
  },
  {
    id: 3,
    name: <FormattedMessage id="ProfitSharing" />,
    path: SERVICE_ROUTES.PROFIT_SHARING,
  },
  {
    id: 4,
    name: <FormattedMessage id="TokenListing" />,
    path: SERVICE_ROUTES.TOKEN_LISTING,
  },
  {
    id: 5,
    name: <FormattedMessage id="Blog" />,
    path: SERVICE_ROUTES.BLOG,
  },
  {
    id: 6,
    name: <FormattedMessage id="Careers" />,
    path: SERVICE_ROUTES.CAREERS,
  },
  {
    id: 7,
    name: <FormattedMessage id="Announcements" />,
    path: SERVICE_ROUTES.ANNOUNCEMENTS,
  },
  {
    id: 8,
    name: <FormattedMessage id="ContactUs" />,
    path: SERVICE_ROUTES.CONTACT_US,
  },
];

export const LEGAL_LINKS = [
  {
    id: 1,
    name: <FormattedMessage id="AML/KYC" />,
    path: SERVICE_ROUTES.KYC,
  },
  {
    id: 2,
    name: <FormattedMessage id="GDPR" />,
    path: SERVICE_ROUTES.GDPR,
  },
  {
    id: 3,
    name: <FormattedMessage id="RiskDisclosure" />,
    path: SERVICE_ROUTES.RISK_DISCLOSURE,
  },
  {
    id: 4,
    name: <FormattedMessage id="LicenseAgreement" />,
    path: SERVICE_ROUTES.LICENSE_AGREEMENT,
  },
  {
    id: 5,
    name: <FormattedMessage id="InstantBonusTerms" />,
    path: SERVICE_ROUTES.INSTANT_BONUS_TERMS,
  },
];

export const SUPPORT_LINKS = [
  {
    id: 1,
    name: <FormattedMessage id="LiveSupport" />,
    path: SERVICE_ROUTES.LIVE_SUPPORT,
  },
  {
    id: 2,
    name: <FormattedMessage id="SupportCenter" />,
    path: SERVICE_ROUTES.SUPPORT,
  },
  {
    id: 3,
    name: <FormattedMessage id="Fees" />,
    path: SERVICE_ROUTES.FEES,
  },
];
