import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

import { getFormData } from '@utils/api';

export const walletsAPI = createApi({
  reducerPath: 'walletsAPI',
  baseQuery: client,
  tagTypes: [
    '24hWithdrawTotal',
    'Wallets',
    'WithdrawAddresses',
    'Withdrawals',
    'DepositCoinAddress',
  ],
  endpoints: (builder) => ({
    getUserWithdrawLimit: builder.query({
      query: () => ({ method: 'GET', url: 'wallets/withdraw-limits/user' }),
    }),
    getWithdrawLimits: builder.query({
      query: () => ({ method: 'GET', url: 'wallets/withdraw-limits' }),
    }),
    getDayPnlChange: builder.query({
      query: ({ accountId }) => ({
        url: 'wallets/pnl/24h',
        method: 'GET',
        params: { account: accountId },
      }),
    }),
    createCoinAddress: builder.mutation({
      query: ({ coinSymbol }) => ({
        url: `wallets/create-address/${coinSymbol}`,
        method: 'POST',
      }),
      invalidatesTags: ['DepositCoinAddress'],
    }),
    getAddressByCoinSymbol: builder.query({
      query: (coinSymbol) => ({
        url: `wallets/get-address-by-coin/${coinSymbol}`,
        method: 'GET',
      }),
      providesTags: ['DepositCoinAddress'],
    }),
    getAllAddresses: builder.query({
      query: () => ({
        url: 'wallets/get-all-addresses',
        method: 'GET',
      }),
    }),
    getDepositHistory: builder.query({
      query: (params) => ({
        url: `wallets/deposits`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => ({
        transactions: response.Transactions,
        meta: response.Meta,
      }),
    }),
    advCashDepositCoinRequest: builder.mutation({
      query: ({ coinSymbol, body }) => ({
        url: `advCash/deposit/request/${coinSymbol}`,
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    clearJunctionDepositRequest: builder.mutation({
      query: ({ body }) => ({
        url: 'cj/deposit/request/sepa',
        method: 'POST',
        body: getFormData(body),
      }),
    }),
    getWithdrawAddresses: builder.query({
      query: () => ({
        url: 'wallets/addresses',
        method: 'GET',
      }),
      providesTags: ['WithdrawAddresses'],
    }),
    createWithdrawAddress: builder.mutation({
      query: (body) => ({
        url: 'wallets/address',
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['WithdrawAddresses'],
    }),
    getWithdrawals: builder.query({
      query: (params) => ({
        url: 'wallets/withdrawals',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => ({
        withdrawals: response.WithdrawRequests,
        meta: response.Meta,
      }),
      providesTags: ['Withdrawals'],
    }),
    get24hWithdrawTotal: builder.query({
      query: () => ({
        url: 'wallets/withdraw-total',
        method: 'GET',
      }),
      providesTags: ['24hWithdrawTotal'],
    }),
    getWithdrawFiatFees: builder.query({
      query: () => ({
        url: 'wallets/withdraw/fiat/fees',
        method: 'GET',
      }),
    }),
    getClearJunctionWithdrawSettings: builder.query({
      query: () => ({
        url: 'cj/withdraw/settings',
        method: 'GET',
      }),
    }),
    makeWithdrawalCoin: builder.mutation({
      query: ({ coin, body }) => ({
        url: `wallets/withdraw/${coin}`,
        method: 'POST',
        body: getFormData(body),
      }),
      invalidatesTags: ['24hWithdrawTotal', 'Withdrawals'],
    }),
    withdrawAdvcashValidateAddress: builder.query({
      query: (params) => ({
        url: 'advCash/validate-address',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetDayPnlChangeQuery,
  useCreateCoinAddressMutation,
  useGetAddressByCoinSymbolQuery,
  useGetAllAddressesQuery,
  useGetDepositHistoryQuery,
  useAdvCashDepositCoinRequestMutation,
  useClearJunctionDepositRequestMutation,
  useGetUserWithdrawLimitQuery,
  useGetWithdrawLimitsQuery,
  useGet24hWithdrawTotalQuery,
  useGetWithdrawAddressesQuery,
  useCreateWithdrawAddressMutation,
  useGetWithdrawalsQuery,
  useGetWithdrawFiatFeesQuery,
  useGetClearJunctionWithdrawSettingsQuery,
  useMakeWithdrawalCoinMutation,
  useLazyWithdrawAdvcashValidateAddressQuery,
} = walletsAPI;
