import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { sum, takeWhile } from 'lodash';

import { percentOfNumber } from '@shared/utils';

import useCalculateStakedBalance from './useCalculateStakedBalance';

import { VISIBLE_ELEMENTS } from '../staking.constants';

const useCalculateStakedItem = (resultItem) => {
  const { isLoaded } = useCalculateStakedBalance();

  const intl = useIntl();

  const sortedStakedAssets = resultItem.sort((a, b) => b.value - a.value);

  const getOtherCoins = (coinsInBtc, totalSumInBtc, topCoinsLength) => {
    const otherCoins = coinsInBtc.slice(topCoinsLength, resultItem.length);
    const otherCoinsSum = sum(otherCoins.map(({ value }) => value));
    const percent =
      otherCoinsSum === 0 || totalSumInBtc === 0
        ? 0
        : percentOfNumber(otherCoinsSum, totalSumInBtc);

    return percent > 0
      ? [
          {
            label: intl.formatMessage({ id: 'Others' }),
            percent,
          },
        ]
      : [];
  };

  const getVisibleCoins = (coinsInBtc, totalSumInBtc) => {
    const coinsPercents = coinsInBtc.map(
      ({ value, coinSymbol, coinFullname }) => {
        const percent =
          value === 0 || totalSumInBtc === 0
            ? 0
            : percentOfNumber(value, totalSumInBtc);

        return {
          label: coinSymbol.toUpperCase(),
          coinFullname,
          percent,
        };
      },
    );

    const visibleTopCoins = takeWhile(coinsPercents, (item, index) => {
      return item.percent > 1 && index < VISIBLE_ELEMENTS;
    });

    return visibleTopCoins;
  };

  const stakedAssets = useMemo(() => {
    if (!isLoaded) {
      return [];
    }

    const totalSumInBtc = sum(sortedStakedAssets.map(({ value }) => value));

    const visibleTopCoins = getVisibleCoins(sortedStakedAssets, totalSumInBtc);

    return [
      ...visibleTopCoins,
      ...getOtherCoins(
        sortedStakedAssets,
        totalSumInBtc,
        visibleTopCoins.length,
      ),
    ];
  }, [isLoaded, sortedStakedAssets]);

  return {
    stakedAssets,
  };
};

export default useCalculateStakedItem;
