import React, { memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { format } from 'date-fns';
import { useForm } from 'react-hook-form';

import { FormActions } from '@shared/components';
import { ACCOUNT_GROUP, ACCOUNT_TYPE } from '@shared/constants';
import { InputController, TextareaController } from '@shared/ui';

import SideBar from '@components/SideBar';
import { SwitchController } from '@components/Switch/Switch';

import {
  useCreateAccountMutation,
  useEditAccountMutation,
} from '@store/api/accountsAPI';
import { useSpotAccounts } from '@store/api/hooks';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import styles from './SpotAccountSideBar.module.scss';

const MAX_TITLE_LENGTH = 40;
const MAX_COMMENT_LENGTH = 200;

const DEFAULT_VALUES = {
  title: '',
  comment: '',
  deposit_allowed: false,
  withdrawal_allowed: false,
  transfer_allowed: true,
};

const SpotAccountSideBar = () => {
  const { sidebar, closeSidebar, isOpen } = useSidebar(sidebarIds.SPOT_ACCOUNT);

  const accountId = sidebar?.params?.accountId;

  const { spotAccountInfo } = useSpotAccounts(
    {
      accountId,
    },
    { skip: !isOpen },
  );

  const intl = useIntl();

  const placeholderAccountTitle = `${format(
    new Date(),
    'dd-MM-yyyy',
  )} (${intl.formatMessage({ id: 'DefaultTitle' })})`;

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (spotAccountInfo) {
      reset({
        title: spotAccountInfo.title,
        comment: spotAccountInfo.comment,
        deposit_allowed: spotAccountInfo.deposit_allowed,
        withdrawal_allowed: spotAccountInfo.withdrawal_allowed,
        transfer_allowed: spotAccountInfo.transfer_allowed,
      });

      return;
    }

    reset(DEFAULT_VALUES);
  }, [spotAccountInfo]);

  const isEditAccount = Boolean(sidebar?.params?.accountId);

  const [createAccount, { isLoading: isLoadingCreateAccount }] =
    useCreateAccountMutation();

  const [editAccount, { isLoading: isLoadingEditAccount }] =
    useEditAccountMutation();

  const handleClose = () => {
    closeSidebar(sidebar?.params);

    reset();
  };

  const onSubmit = async ({ title, comment, transfer_allowed }) => {
    if (isEditAccount) {
      const { origin } = spotAccountInfo;

      await editAccount({
        ...origin,
        title: title || format(new Date(), 'dd-MM-yyyy'),
        comment,
        transfer_allowed,
      }).unwrap();
    } else {
      await createAccount({
        title: title || format(new Date(), 'dd-MM-yyyy'),
        comment,
        transfer_allowed,
        market_type: ACCOUNT_TYPE.SPOT,
        account_group: ACCOUNT_GROUP.MAIN,
      }).unwrap();
    }

    handleClose();
  };

  const commonFieldProps = {
    inversion: true,
    underlined: true,
    bordered: false,
    size: 'l',
    customStyles: styles,
  };

  return (
    <SideBar
      open={isOpen}
      placement="right"
      handler={false}
      level={null}
      onClose={handleClose}
      forceRender
      withCloseButton
      isManualScrollControl
      title={
        <FormattedMessage
          id={isEditAccount ? 'EditAccount' : 'CreateAccount'}
        />
      }
    >
      <div className={styles.sidebar}>
        <form
          className="d-flex h-100 justify-content-between flex-column"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-10">
            <div className="fs-16 mb-32">
              <FormattedMessage id="YouCanChangeDefaultTitle" />
            </div>

            <InputController
              name="title"
              placeholder={placeholderAccountTitle}
              rules={{
                required: intl.formatMessage({ id: 'FieldRequired' }),
              }}
              maxLength={MAX_TITLE_LENGTH}
              {...commonFieldProps}
              {...methods}
            />

            <div className="mt-30">
              <hr />
            </div>

            <div className="d-flex flex-column gap-20 mb-12">
              <div className={styles.allow_controller}>
                <FormattedMessage id="AllowDeposit" />

                <SwitchController
                  inversionColors
                  name="deposit_allowed"
                  disabled
                  control={control}
                />
              </div>
              <div className={styles.allow_controller}>
                <FormattedMessage id="AllowWithdraw" />

                <SwitchController
                  inversionColors
                  name="withdrawal_allowed"
                  disabled
                  control={control}
                />
              </div>
              <div className={styles.allow_controller}>
                <FormattedMessage id="AllowTransfer" />

                <SwitchController
                  inversionColors
                  name="transfer_allowed"
                  control={control}
                />
              </div>
            </div>

            <hr />

            <TextareaController
              name="comment"
              placeholder={`${intl.formatMessage({
                id: 'AccountDescriptionOptional',
              })}...`}
              maxLength={MAX_COMMENT_LENGTH}
              {...commonFieldProps}
              {...methods}
            />
          </div>

          <div className="py-40">
            <FormActions
              inversion
              submitText={
                <FormattedMessage id={isEditAccount ? 'Edit' : 'Create'} />
              }
              submitButtonProps={{
                isLoading: isLoadingCreateAccount || isLoadingEditAccount,
                disabled:
                  isLoadingCreateAccount || isLoadingEditAccount || !isValid,
              }}
              onCancel={handleClose}
              customStyles={styles}
            />
          </div>
        </form>
      </div>
    </SideBar>
  );
};

export default memo(SpotAccountSideBar);
