import React from 'react';

import { ErrorMessage } from '@hookform/error-message';

const FieldErrorMessage = (props) => {
  return (
    <ErrorMessage
      render={({ message }) => <div className="color-red mt-2">{message}</div>}
      {...props}
    />
  );
};

export default FieldErrorMessage;
