import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { sum } from 'lodash';

import { percentOfNumber } from '@shared/utils';

import { PIE_CHART_TOP_COINS } from '../overview.constants';

import mockData from 'src/mock.json';

export const useTopAssets = () => {
  const orderedBalancesMap = mockData.PortfolioCoinsWidget.balances;

  const intl = useIntl();

  const getVisibleCoins = (accountBalancesInCurrency, totalSumInCurrency) => {
    const visibleTopCoins = accountBalancesInCurrency.slice(
      0,
      PIE_CHART_TOP_COINS,
    );

    const visibleTopCoinsPercentage = visibleTopCoins.map(
      ({ inCurrency, symbol }) => ({
        label: symbol.toUpperCase(),
        percent: percentOfNumber(inCurrency, totalSumInCurrency),
      }),
    );

    return visibleTopCoinsPercentage;
  };

  const getOtherCoins = (accountBalancesInCurrency, totalSumInCurrency) => {
    const otherCoins = accountBalancesInCurrency.slice(
      PIE_CHART_TOP_COINS,
      orderedBalancesMap.length,
    );
    const otherCoinsSum = sum(otherCoins.map(({ inCurrency }) => inCurrency));
    const percent = percentOfNumber(otherCoinsSum, totalSumInCurrency);

    return percent > 0
      ? [
          {
            label: intl.formatMessage({ id: 'Others' }),
            percent,
          },
        ]
      : [];
  };

  const topAssets = useMemo(() => {
    if (!orderedBalancesMap.length) {
      return [];
    }

    const totalSumInCurrency = sum(
      orderedBalancesMap.map(({ inCurrency }) => inCurrency),
    );

    return [
      ...getVisibleCoins(orderedBalancesMap, totalSumInCurrency),
      ...getOtherCoins(orderedBalancesMap, totalSumInCurrency),
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedBalancesMap]);

  return { topAssets, totalAssets: orderedBalancesMap.length };
};
