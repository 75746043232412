import {
  EMPTY_CHART_VALUES,
  MOCK_NEGATIVE_CHART_VALUES,
  MOCK_POSITIVE_CHART_VALUES,
} from '@shared/constants';
import { useValueColor } from '@shared/hooks';

const useChartData = (value = 0) => {
  const { isPositiveValue, isZeroValue, color } = useValueColor(value);

  // TODO: remove when API will be ready
  const chartValues = isPositiveValue
    ? MOCK_POSITIVE_CHART_VALUES
    : MOCK_NEGATIVE_CHART_VALUES;

  const chartData = isZeroValue ? EMPTY_CHART_VALUES : chartValues;

  return { color, value, chartData, isZeroValue };
};

export default useChartData;
