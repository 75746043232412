import React, { memo, useMemo } from 'react';

import { CurrencyItem } from '@shared/components';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './CoinNameCell.module.scss';

const CoinNameCell = ({ symbol, ...props }) => {
  const { isTabletDown, isLaptopDown } = useBreakpoints();

  const coinIconSize = useMemo(() => {
    let iconSize = 36;
    if (isLaptopDown) iconSize = 28;
    if (isTabletDown) iconSize = 24;

    return iconSize;
  }, [isLaptopDown, isTabletDown]);

  return (
    <CurrencyItem
      customStyles={styles}
      iconAccessor="icon"
      coinSymbol={symbol}
      iconWidth={coinIconSize}
      iconHeight={coinIconSize}
      {...props}
    />
  );
};

export default memo(CoinNameCell);
