import React, { memo, useMemo, useState } from 'react';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { Select as SelectVirtualized } from 'react-select-virtualized';

import { FieldController } from '@shared/components';

import Loader from '@components/Loader';

import {
  CustomControl,
  CustomClearIndicator,
  CustomCreateLabel,
  CustomDropdownIndicator,
  CustomIndicatorsContainer,
  CustomGroupHeading,
  CustomMenu,
  CustomMenuList,
  CustomNoOptionsMessage,
  CustomOption,
  CustomPlaceholder,
  CustomInput,
} from './components';

import styles from './CustomSelect.module.scss';

/**
 * PROPS:
 *
 * options - [{value: 'value', label: 'Label'}]
 * optionsGroup = [{ label: 'Group Name Header 1', options: options }]
 *
 * async:
 * loadOptions = (input, callback) => {getSmt(input); callback(result)}
 *
 * Option customizing in react-select and react-select-virtualized is different.
 * To customize react-select option enough just pass a jsx component. But to customize
 * react-select-virtualized option you should pass formatOptionLabel method. And you need
 * also customize SingleValue component and pass it in the specificComponents.
 *
 * Example:
 * const formatOptionLabel = ({ value }) => <strong>{value}</strong>
 *
 * const SpecificSingleValue = (props) => {
 *   const { value } = props.data;
 *
 *   return <strong>{value}</strong>;
 * };
 *
 * <CustomSelect
 *   virtualized
 *   formatOptionLabel={formatOptionLabel}
 *   specificComponents={{
 *     SingleValue: SpecificSingleValue,
 *   }}
 *   ...
 * />
 * */

const CustomSelect = ({
  options,
  loadOptions,
  defaultOptions,
  creatable,
  virtualized,
  grouped,
  specificComponents,
  valueAccessor = 'value',
  // styles
  transparent,
  inversion,
  chevronInversion,
  bordered,
  underlined,
  size = 'l', // m (medium), l (large) - select height (m: 38px, l: 48px)
  // form control
  controllerRef,
  isError,
  errorMessage,
  onChange,
  ...props
}) => {
  const [uniqueId] = useState(
    () => 'select_' + Math.random().toFixed(5).slice(2),
  );

  const isAsyncSelect = !!loadOptions;

  const handleChange = (selectedOption) => {
    onChange(selectedOption?.[valueAccessor]);
  };

  /**
   * to animate menu closing need to clone menu component,
   * add him class and animate closing of this class
   * and then remove it from DOM
   */
  const handleMenuClose = () => {
    const menuEl = document.querySelector(`#${uniqueId} .menu`);
    const containerEl = menuEl?.parentElement;
    const clonedMenuEl = menuEl?.cloneNode(true);

    if (!clonedMenuEl) return; // safeguard

    clonedMenuEl.classList.add('menu--close');
    clonedMenuEl.addEventListener('animationend', () => {
      containerEl?.removeChild(clonedMenuEl);
    });

    containerEl?.appendChild(clonedMenuEl);
  };

  const selectConfigs = {
    id: uniqueId,
    inversion,
    chevronInversion,
    bordered,
    underlined,
    size,
    invalid: !!errorMessage,
    unstyled: true,
    classNamePrefix: 'react-select',
    menuPosition: 'fixed',
    menuPlacement: 'auto',
    menuPortalTarget: document.body,
    blurInputOnSelect: true,
    onChange: handleChange,
    onMenuClose: handleMenuClose,
    styles: {
      ...(props?.styles || {}),
      singleValue: (base) => ({
        ...base,
        ...(props?.styles?.singleValue || {}),
      }),
      control: (base) => ({
        ...base,
        cursor: 'pointer',
        ...(props?.styles?.control || {}),
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
        ...(props?.styles?.menuPortal || {}),
      }),
      menu: (base) => ({
        ...base,
        fontWeight: '600',
        ...(props?.styles?.menu || {}),
      }),
      input: (base) => ({
        ...base,
        ...(props?.styles?.input || {}),
      }),
      container: (base) => ({ ...base, ...(props?.styles?.container || {}) }),
      noOptionsMessage: (base) => ({
        ...base,
        ...(props?.styles?.noOptionsMessage || {}),
      }),
      placeholder: (base) => ({
        ...base,
        ...(props?.styles?.placeholder || {}),
      }),
      indicatorsContainer: (base) => ({
        ...base,
        ...(props?.styles?.indicatorsContainer || {}),
      }),
      dropdownIndicator: (base) => ({
        ...base,
        ...(props?.styles?.dropdownIndicator || {}),
      }),
    },
  };

  const [asyncOptions, setAsyncOptions] = useState([]);

  const isController = !!controllerRef;

  const selectedOption = useMemo(() => {
    /**
     * should return undefined if is not Controller and no selected value,
     * and return null if is a Controller for reset programmatically selected option
     * */
    if (!props.value && props.value !== 0)
      return isController ? null : undefined;

    const getSelectedOption = (options) =>
      options.find((option) => option[valueAccessor] === props.value) || null;

    const currentOptions = isAsyncSelect ? asyncOptions : options;

    if (grouped) {
      const flatOptions = currentOptions.reduce(
        (acc, cur) => acc.concat(cur.options),
        [],
      );

      return getSelectedOption(flatOptions);
    }

    return getSelectedOption(currentOptions);
  }, [
    isController,
    asyncOptions,
    grouped,
    isAsyncSelect,
    options,
    props.value,
    valueAccessor,
  ]);

  const loadOptionsRequest = async (keyword) => {
    const options = await loadOptions(keyword);

    setAsyncOptions(options);

    return options;
  };

  const selectComponents = {
    Control: CustomControl,
    Menu: CustomMenu,
    MenuList: CustomMenuList,
    GroupHeading: CustomGroupHeading,
    Option: CustomOption,
    IndicatorsContainer: CustomIndicatorsContainer,
    DropdownIndicator: CustomDropdownIndicator,
    ClearIndicator: CustomClearIndicator,
    LoadingIndicator: () => <Loader xs />,
    NoOptionsMessage: CustomNoOptionsMessage,
    Placeholder: CustomPlaceholder,
    Input: CustomInput,
    ...specificComponents,
  };

  let SelectComponent = (
    <Select
      components={selectComponents}
      options={options}
      {...props}
      {...selectConfigs}
      value={selectedOption}
    />
  );

  if (isAsyncSelect) {
    if (creatable) {
      SelectComponent = (
        <AsyncCreatableSelect
          components={selectComponents}
          defaultOptions={defaultOptions}
          loadOptions={loadOptionsRequest}
          formatCreateLabel={(inputValue) => (
            <CustomCreateLabel inputValue={inputValue} />
          )}
          {...props}
          {...selectConfigs}
          value={selectedOption}
        />
      );
    }

    SelectComponent = (
      <AsyncSelect
        components={selectComponents}
        defaultOptions={defaultOptions}
        loadOptions={loadOptionsRequest}
        {...props}
        {...selectConfigs}
        value={selectedOption}
      />
    );
  }

  if (creatable) {
    SelectComponent = (
      <CreatableSelect
        components={selectComponents}
        formatCreateLabel={(inputValue) => (
          <CustomCreateLabel inputValue={inputValue} />
        )}
        options={options}
        {...props}
        {...selectConfigs}
        value={selectedOption}
      />
    );
  }

  if (virtualized) {
    SelectComponent = (
      <SelectVirtualized
        components={selectComponents}
        options={options}
        {...props}
        {...selectConfigs}
        value={selectedOption}
      />
    );
  }

  return (
    <>
      {props?.label && <label className={styles.label}>{props.label}</label>}
      {SelectComponent}
      {errorMessage && <span className={styles.error}>{errorMessage}</span>}
    </>
  );
};

export const CustomSelectController = memo((props) => {
  return <FieldController component={CustomSelect} {...props} />;
});

export default memo(CustomSelect);
