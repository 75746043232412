import React, { memo, useEffect, useId, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Table from '@components/gridTables/Table';

import {
  AvailablePlansPeriodButtons,
  availablePlansTableColumns,
} from '@pages/WalletsPage/Staking/components';
import {
  useAvailablePlansTable,
  useSelectedGlobalPeriod,
} from '@pages/WalletsPage/Staking/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { availablePlansActions } from '../../slices';

const AvailablePlansTable = () => {
  const id = useId();

  const dispatch = useDispatch();

  const { isTabletDown, isMobile } = useBreakpoints();

  const { availablePlans, periods, isAvailablePlansSuccess } =
    useAvailablePlansTable({ isAllPeriodsSelected: isTabletDown });

  const { selectedGlobalPeriod } = useSelectedGlobalPeriod();

  const columns = useMemo(
    () => availablePlansTableColumns({ isTabletDown }),
    [isTabletDown],
  );

  useEffect(() => {
    if (periods) {
      dispatch(availablePlansActions.setSelectedPeriod(periods?.[0]?.period));
    }
  }, [periods]);

  const isPeriodButtonsVisible = !isMobile && isTabletDown;

  const periodButtons = (
    <AvailablePlansPeriodButtons
      activePeriod={selectedGlobalPeriod?.period}
      isAllPeriodsSelected
      isSwiperActive={isMobile}
    />
  );

  return (
    <>
      {isPeriodButtonsVisible && <div className="mb-24">{periodButtons}</div>}

      <Table
        id={id}
        columns={columns}
        data={availablePlans}
        wideRowsL
        isSwipeable={isMobile}
        isLoading={!isAvailablePlansSuccess}
        className="account-tables-swipe"
        swipeableTableProps={{
          stickyColumns: 1,
        }}
        extraHeaderComponent={periodButtons}
      />
    </>
  );
};

export default memo(AvailablePlansTable);
