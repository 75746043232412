import React, { memo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { FreeMode, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useSpotTotalBalances } from '@shared/hooks';

import { useSpotAccounts } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar/useSidebar';

import { sidebarIds } from '@constants';

import { MainAccount } from '../MainAccount';
import { SubAccounts } from '../SubAccounts';

import { WALLETS_ROUTES } from 'src/routes.constants';

import styles from './AccountsSwiper.module.scss';
import mockData from 'src/mock.json';
import 'swiper/css';

const SMALL_SPACE_BETWEEN = 20;
const LARGE_SPACE_BETWEEN = 36;

const { MarginAccounts } = mockData.AccountsSwiper;

const AccountsSwiper = ({ estimatedCurrency }) => {
  const swiperRef = useRef();

  const { isMobile, isTabletUp, isLargeTabletUp, isLargeTabletDown, isLaptop } =
    useBreakpoints();

  const formatOptions = {
    returnNumber: true,
  };

  const { formattedTotalBalance: spotAvailableBalance } = useSpotTotalBalances({
    coinSymbol: estimatedCurrency,
    filedName: 'available',
    formatOptions,
  });

  const { openSidebar: openSpotAccountSidebar } = useSidebar(
    sidebarIds.SPOT_ACCOUNT,
  );
  const { spotAccountsLength } = useSpotAccounts();
  const { openSidebar: openTransferSidebar } = useSidebar(sidebarIds.TRANSFER);

  const isMenuVisible = isLargeTabletUp && isLargeTabletDown;

  const responsiveSpaceBetween = isLargeTabletDown
    ? SMALL_SPACE_BETWEEN
    : LARGE_SPACE_BETWEEN;

  const spaceBetween = isMenuVisible ? responsiveSpaceBetween : 0;

  const ACCOUNTS = [
    {
      id: 1,
      component: MainAccount,
    },
    {
      id: 2,
      component: SubAccounts,
      count: spotAccountsLength,
      availableValue: spotAvailableBalance,
      titleId: 'SpotAccounts',
      detailsLink: WALLETS_ROUTES.SPOT_ACCOUNTS,
      actions: [
        {
          name: 'AddSpotAccount',
          title: (
            <>
              <FormattedMessage id="AddSpotAccount" />
              ...
            </>
          ),
          onClick: () => {
            openSpotAccountSidebar();
          },
        },
        {
          name: 'Transfer',
          title: (
            <>
              <FormattedMessage id="Transfer" />
            </>
          ),
          onClick: openTransferSidebar,
        },
      ],
    },
    {
      id: 3,
      component: SubAccounts,
      count: MarginAccounts.count,
      availableValue: MarginAccounts.availableValue,
      titleId: 'MarginAccounts',
      detailsLink: WALLETS_ROUTES.MARGIN_ACCOUNTS,
      actions: [
        {
          name: 'AddMarginAccount',
          title: (
            <>
              <FormattedMessage id="AddMarginAccount" />
              ...
            </>
          ),
          onClick: () => {
            // TODO: add margin create account sidebar
          },
        },
        {
          name: 'Transfer',
          title: (
            <>
              <FormattedMessage id="Transfer" />
            </>
          ),
          onClick: () => {},
        },
      ],
    },
  ];

  const getSpaceBetweenSlides = (index) => {
    if (isMobile || (isTabletUp && isLargeTabletDown)) {
      return SMALL_SPACE_BETWEEN;
    }

    if (isLaptop) {
      return index === 0 ? LARGE_SPACE_BETWEEN : SMALL_SPACE_BETWEEN;
    }

    return SMALL_SPACE_BETWEEN;
  };

  const getSlidesOffsetAfter = () => {
    if (isMobile) {
      return SMALL_SPACE_BETWEEN;
    }

    return 36;
  };

  return (
    <div className={styles.widgets}>
      <Swiper
        ref={swiperRef}
        modules={[Mousewheel, FreeMode]}
        spaceBetween={spaceBetween}
        touchAngle={70}
        freeMode={{ momentumRatio: 0.5 }}
        slidesOffsetAfter={getSlidesOffsetAfter()}
        mousewheel={{ forceToAxis: true, thresholdDelta: 2 }}
        slidesPerView="auto"
        grabCursor
      >
        {ACCOUNTS.map(({ component, id, ...props }, index) => {
          const Component = component;

          return (
            <SwiperSlide
              style={{
                width: 'auto',
                ...(isMenuVisible
                  ? {}
                  : { marginLeft: getSpaceBetweenSlides(index) }),
              }}
              key={id}
            >
              <Component currency={estimatedCurrency} {...props} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default memo(AccountsSwiper);
