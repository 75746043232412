import { FormattedMessage } from 'react-intl';

import { ReactComponent as UploadFileIconSVG } from '@icons/upload-file-icon.svg';

export const FileUploaderPlaceHolder = ({ customStyles = {} }) => {
  return (
    <>
      <div>
        <UploadFileIconSVG
          className={customStyles.upload_icon}
          width={32}
          height={26}
        />
      </div>
      <div className="d-flex flex-column fw-600">
        <div>
          <FormattedMessage id="DragAndDropYourFile" />
        </div>
        <div>
          <FormattedMessage id="ClickToUpload" />
        </div>
      </div>
    </>
  );
};
