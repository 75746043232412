import React, { useMemo, memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import { copyToClipboard } from '@shared/utils';

import Button from '@components/Button';
import Table from '@components/gridTables/Table';

import { useMarketsInfo } from '@store/api/hooks';
import { marketIdSelector } from '@store/settings/settingsSelectors';

import {
  chartTableDateFormat,
  downloadCsv,
  getFilenameFormatter,
} from '@utils/chartiq';
import { isTranslationExists } from '@utils/translations';

import { ReactComponent as CopyIconSVG } from '@images/chartiq/copy-icon.svg';
import { ReactComponent as CrossOpacityIconSVG } from '@images/chartiq/cross-opacty-icon.svg';
import { ReactComponent as DownloadIconSVG } from '@images/chartiq/download-icon.svg';
import { ReactComponent as PlusThinIconSVG } from '@images/chartiq/plus-thin-icon.svg';

import chartTableViewColumns from './columns';

import styles from './ChartTableView.module.scss';

const prepareDataForObtainTextSnapshot = (columns, tableData) => {
  const columnsMapped = columns.map((col) => col.accessorKey);
  const filteredTableData = [];

  tableData.forEach((data) => {
    filteredTableData.push(
      Object.fromEntries(
        Object.entries(data).filter(([key, value]) =>
          columnsMapped.includes(key),
        ),
      ),
    );
  });

  return { columnsMapped, filteredTableData };
};

const getTextTableSnapshot = (columns, tableData, intl) => {
  const { filteredTableData, columnsMapped } = prepareDataForObtainTextSnapshot(
    columns,
    tableData,
  );

  const headersToString = columnsMapped.reduce((acc, next, index) => {
    const headerTitle = isTranslationExists(intl, next)
      ? intl.formatMessage({ id: next })
      : next;

    const isLastHeader = index === columns.length - 1;

    return acc + `"${headerTitle}"${isLastHeader ? '' : ', '}`;
  }, '');

  return filteredTableData.reduce((acc, next) => {
    let row = '';
    columnsMapped.forEach((col, index) => {
      const cell = col === 'DT' ? chartTableDateFormat(next.DT) : next[col];
      row += `"${cell}"${index !== columnsMapped.length - 1 ? ', ' : ''}`;
    });
    return acc + row + '\n';
  }, `${headersToString}\n`);
};

const ChartTableView = ({
  stx,
  show,
  closeTable,
  chartHeight,
  initialSymbol,
}) => {
  const [showAdditionalColumns, setShowAdditionalColumns] = useState(false);

  const intl = useIntl();

  const marketId = useSelector(marketIdSelector);

  const { marketInfo } = useMarketsInfo({ marketId });

  let tableData = [];

  const toolbarHeader = 37;

  if (stx?.chart.dataSegment) {
    tableData = stx.chart.dataSegment.filter((item) => !!item).reverse();
  }

  const additionalColumns = useMemo(() => {
    if (!stx?.chart?.dataSegment) return [];

    function hasData(name) {
      return stx.chart.dataSegment.some((data) => data && data[name]);
    }

    function getDataNames(study) {
      return Object.keys(study.outputMap).filter(hasData);
    }

    return Object.values(stx.layout.studies || {})
      .filter((study) => !study.signalData || study.signalData.reveal)
      .reduce((acc, item) => acc.concat(getDataNames(item)), []);
  }, [stx]);

  const columns = useMemo(
    () =>
      chartTableViewColumns(
        showAdditionalColumns,
        additionalColumns,
        marketInfo?.market_precision_format,
      ),
    [
      showAdditionalColumns,
      additionalColumns,
      marketInfo?.market_precision_format,
    ],
  );

  const handleCopy = async () => {
    const textTableSnapshot = getTextTableSnapshot(columns, tableData, intl);

    await copyToClipboard({ intl, text: textTableSnapshot });
  };

  const handleDownloadCsv = () => {
    const textTableSnapshot = getTextTableSnapshot(columns, tableData, intl);

    const filename = getFilenameFormatter(initialSymbol.symbol, tableData);
    downloadCsv(textTableSnapshot, filename, stx);
  };

  const tableViewControls = [
    {
      name: 'copy',
      icon: <CopyIconSVG />,
      title: <FormattedMessage id="Copy" />,
      handleClick: handleCopy,
    },
    {
      name: 'download',
      icon: <DownloadIconSVG />,
      title: <FormattedMessage id="Download" />,
      handleClick: handleDownloadCsv,
    },
    {
      name: 'additional_columns',
      icon: <PlusThinIconSVG />,
      title: <FormattedMessage id="AdditionalColumns" />,
      handleClick: () => setShowAdditionalColumns((prevState) => !prevState),
    },
    {
      name: 'close',
      icon: <CrossOpacityIconSVG />,
      handleClick: closeTable,
    },
  ];

  return (
    <div className={styles.chart_table_view}>
      <div className={styles.header}>
        <div className={styles.header__title}>{initialSymbol.name}</div>
        <div className={styles.header__header_controls}>
          {tableViewControls.map((control, index) => (
            <Button
              onClick={control.handleClick}
              key={control.name + index}
              className={classnames(
                styles.header__header_controls__control_btn,
                {
                  [styles['header__header_controls__control_btn--active']]:
                    showAdditionalColumns &&
                    control.name === 'additional_columns',
                },
              )}
            >
              {control.icon}
              {control.title && <span>{control.title}</span>}
            </Button>
          ))}
        </div>
      </div>

      <Table
        id="chartiq-table-view-id"
        isSwipeable
        columns={columns}
        data={tableData}
        tableHeight={chartHeight - toolbarHeader}
        swipeableTableProps={{
          stickyColumns: 1,
        }}
      />
    </div>
  );
};

export default memo(ChartTableView);
