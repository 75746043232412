import { CardItem } from '@shared/components';
import { CARD_TYPE } from '@shared/constants';

const DEFAULT_VISA_CARD_OPTIONS = [
  {
    value: CARD_TYPE.VIBRANT_BUTTERFLY,
  },
  {
    value: CARD_TYPE.VELVET_ZEBRA,
  },
  {
    value: CARD_TYPE.CRAZY_MONKEY,
  },
  {
    value: CARD_TYPE.TITAN_RHINO,
  },
];

export const visaCardOptions = ({ customStyles, iconWidth, iconHeight }) => {
  return DEFAULT_VISA_CARD_OPTIONS.map(({ value }) => ({
    value,
    label: (
      <CardItem
        customStyles={customStyles}
        cardType={value}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
      />
    ),
  }));
};
