import React, { memo } from 'react';

import classNames from 'classnames';

import TruncatedText from '@shared/components/TruncatedText';

import styles from './OrdersTotalAmount.module.scss';

const OrdersTotalAmount = ({ total, coinSymbol, numberValueClassName }) => {
  return (
    <TruncatedText>
      <span
        className={classNames(
          {
            [styles.bold]: Number(total) !== 0,
            [styles.grey]: Number(total) === 0,
          },
          numberValueClassName,
        )}
      >
        {total}
      </span>{' '}
      <span className={styles.thin}>{coinSymbol}</span>
    </TruncatedText>
  );
};

export default memo(OrdersTotalAmount);
