import mockData from 'src/mock.json';

export const MOCK_POSITIVE_CHART_VALUES =
  mockData.chartConstants.mockPositiveChartValues.map((value, index) => ({
    date: index + 1,
    value,
  }));

export const MOCK_NEGATIVE_CHART_VALUES =
  mockData.chartConstants.mockNegativeChartValues.map((value, index) => ({
    date: index + 1,
    value,
  }));

export const EMPTY_CHART_VALUES = new Array(7).fill(0).map((value, index) => ({
  date: index + 1,
  value,
}));

export const MOCK_COLUMN_SERIES_VALUES =
  mockData.chartConstants.mockColumnSeriesValues;
