import { format } from '@utils/numbers';

const ONE_THOUSAND = 1000;

// 1000 -> 1 000
// 100000 -> 10 000
export const formatNumberToGroups = (
  value,
  groupSeparator = ' ',
  precision,
  removeZeroEnd = true,
) =>
  format(value, {
    precision,
    removeZeroEnd,
    noCommas: true,
    formatSettings: { groupSeparator },
  });

export const formatNumberToK = (num) => {
  if (typeof num === 'number' && num >= ONE_THOUSAND) {
    let result = '';

    if (num >= ONE_THOUSAND) {
      result = Math.floor(num / ONE_THOUSAND) + 'K';
    }

    result = result.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

    return result;
  }

  return num.toString();
};

export function isNumeric(str) {
  if (typeof str !== 'string') return false;

  return !isNaN(str) && !isNaN(parseFloat(str));
}
