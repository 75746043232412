import React, { memo } from 'react';

import classnames from 'classnames';

import Button from '@components/Button';

import { ReactComponent as StarIconSVG } from '@icons/star-icon.svg';

import styles from './FavouriteMarketButton.module.scss';

const FavouriteMarketButton = ({
  isActive = false,
  isGuest = false,
  width = 16,
  height = 15,
  ...props
}) => {
  return (
    <Button className={styles.favourite_market_button} {...props}>
      <StarIconSVG
        width={width}
        height={height}
        className={classnames(styles.star_icon, {
          [styles.star_icon__active]: isActive,
          [styles.star_icon__disabled]: isGuest,
        })}
      />
    </Button>
  );
};

export default memo(FavouriteMarketButton);
