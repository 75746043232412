import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

export const platformTokenAPI = createApi({
  reducerPath: 'platformTokenAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    getReferralEarnings: builder.query({
      query: () => 'referral-earnings-all',
    }),
  }),
});

export const { useGetReferralEarningsQuery } = platformTokenAPI;
