import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@components/Button';

import { ReactComponent as ExtendIconSVG } from '@icons/extend-icon.svg';
import { ReactComponent as ShrinkIconSVG } from '@icons/shrink-icon.svg';

import styles from './ChartTemplateNavbar.module.scss';

const ChartTemplateNavbar = ({ isFullSize, onFullSizeClick }) => {
  return (
    <Button className={styles.full_size_button} onClick={onFullSizeClick}>
      <span>
        <FormattedMessage id={isFullSize ? 'SmallSize' : 'FullSize'} />
      </span>

      {isFullSize ? (
        <ShrinkIconSVG width={14} height={14} />
      ) : (
        <ExtendIconSVG width={14} height={14} />
      )}
    </Button>
  );
};

export default memo(ChartTemplateNavbar);
