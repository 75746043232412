import React, { memo, useMemo } from 'react';

import { FreeMode, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ButtonGroup, FieldController } from '@shared/components';
import { Loader } from '@shared/ui';

import { FormattedPeriods } from '@pages/WalletsPage/Staking/components';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './DurationPeriod.module.scss';

const ADDITIONAL_SLIDES_SPACE = 70;

const DurationPeriod = ({ value, onChange, periods }) => {
  const { isMobile } = useBreakpoints();

  const periodsList = useMemo(
    () =>
      periods?.map(({ period }) => ({ id: `period_id_${period}`, period })) ||
      [],
    [periods],
  );

  const selectedPeriodId = useMemo(
    () => periodsList.find(({ period }) => period === value)?.id,
    [periodsList, value],
  );

  const handleSelectPeriodRange = ({ period }) => onChange(period);

  if (!periodsList.length || !selectedPeriodId) {
    return <Loader />;
  }

  const buttons = (
    <ButtonGroup
      items={periodsList}
      selectedItemId={selectedPeriodId}
      onClick={handleSelectPeriodRange}
      valueKey="period"
      variant="outlined"
      color="tertiary"
      inversion
      size="xs"
      customStyles={styles}
    >
      {(period) => <FormattedPeriods period={period} />}
    </ButtonGroup>
  );

  if (isMobile) {
    return (
      <Swiper
        modules={[Mousewheel, FreeMode]}
        slidesOffsetAfter={ADDITIONAL_SLIDES_SPACE}
        freeMode={{ momentumRatio: 0.5 }}
        mousewheel={{ forceToAxis: true, thresholdDelta: 2 }}
      >
        <SwiperSlide style={{ width: '100%' }}>{buttons}</SwiperSlide>
      </Swiper>
    );
  }

  return buttons;
};

export const DurationPeriodController = memo(({ onChange, ...props }) => {
  return <FieldController {...props} component={DurationPeriod} />;
});

export default memo(DurationPeriod);
