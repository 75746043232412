import { memo, useMemo } from 'react';

import { FORMAT_TYPES } from '@shared/constants';

import { useMarketsInfo } from '@store/api/hooks';

import { format } from '@utils/numbers';

const FormattedPairCurrency = ({ pairId, formatType = 'quote', value }) => {
  const { marketInfo } = useMarketsInfo({ marketId: pairId });

  const formattedCurrency = useMemo(() => {
    const formatKey = FORMAT_TYPES[formatType];

    return format(value, {
      precision: marketInfo?.[formatKey],
    });
  }, [formatType, marketInfo, value]);

  return formattedCurrency;
};

export default memo(FormattedPairCurrency);
