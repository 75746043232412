import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';

import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

import Dropdown from '@components/Dropdown';
import Input from '@components/Input';

import useGridLayout from '@hooks/gridLayout/useGridLayout';
import useModal from '@hooks/useModal';

import { modalsIds } from '@constants';

import { ReactComponent as MovingIconSVG } from '@icons/moving-icon.svg';
import { ReactComponent as ThreeHorizontalDotsIconSVG } from '@icons/three-horizontal-dots.svg';

import styles from './TemplatesListItem.module.scss';

const TemplatesListItem = ({ provided, snapshot, item: { id, label } }) => {
  const { openModal } = useModal(modalsIds.CONFIRM_LEAVING_PAGE);
  const intl = useIntl();

  const {
    templates,
    updateTemplates,
    selectTemplateById,
    selectedTemplateId,
    isLayoutHasChanged,
  } = useGridLayout();

  const [editMode, setEditMode] = useState(false);
  const [templateName, setTemplateName] = useState(label);

  const menuItems = [
    {
      title: intl.formatMessage({ id: 'RenameLayout' }),
      onClick: (e) => {
        setEditMode(true);
      },
    },
    {
      title: intl.formatMessage({ id: 'DeleteLayout' }),
      withDivider: true,
      disabled: selectedTemplateId === id,
      onClick: (e) => {
        updateTemplates(templates.filter((template) => template.id !== id));
      },
    },
  ];

  const handleItemClick = () => {
    if (isLayoutHasChanged) {
      openModal({
        onLeavePage: () => selectTemplateById(id),
        onSaveChanges: (updatedTemplateId) => {
          if (updatedTemplateId !== id) {
            selectTemplateById(id);
          }
        },
      });
    } else {
      selectTemplateById(id);
    }
  };

  const handleSaveTemplateNameChanges = () => {
    const updatedTemplates = cloneDeep(templates);
    const currentTemplate = updatedTemplates.find(
      (template) => template.id === id,
    );

    if (currentTemplate.label !== templateName) {
      currentTemplate.label = templateName;

      updateTemplates(updatedTemplates);
    }

    setEditMode(false);
  };

  return (
    <div
      className={classnames(styles.templates_list_item, {
        [styles['templates_list_item--dragging']]: snapshot.isDragging,
      })}
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div className="d-flex align-items-center">
        <div className={styles.moving_item} {...provided.dragHandleProps}>
          <MovingIconSVG />
        </div>

        <div className="d-flex align-items-center">
          <div
            className={classnames(styles.circle, {
              [styles['circle--selected']]: selectedTemplateId === id,
            })}
            onClick={handleItemClick}
          />

          <div className={styles.template_name}>
            {editMode ? (
              <Input
                className={styles.template_input}
                autoFocus
                value={templateName}
                onChange={setTemplateName}
                onBlur={handleSaveTemplateNameChanges}
              />
            ) : (
              <div onClick={handleItemClick}>{label}</div>
            )}
          </div>
        </div>
      </div>

      <Dropdown id={`item-dropdown-id-${id}`} menuItems={menuItems}>
        {({ triggerRef, handleMenuTrigger, isOpen }) => (
          <div
            ref={triggerRef}
            className={classnames(styles.options_item, {
              [styles['options_item--active']]: isOpen,
            })}
            onClick={handleMenuTrigger}
          >
            <ThreeHorizontalDotsIconSVG />
          </div>
        )}
      </Dropdown>
    </div>
  );
};

export default memo(TemplatesListItem);
