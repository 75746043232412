import { useMemo } from 'react';

import { pickBy } from 'lodash';

import { Button } from '@shared/ui';

import Accordion from '../Accordion/Accordion';

import s from './MenuNavigation.module.scss';

/**
 * See this component with dark background
 *
 * This component import Accordion, so it accept all props for this component
 * Example of items:
 *
 * const items = [
 *   { label: 'title 1', children: 'text', buttonProps: {navigation: {to: '/link_1'}}, links: [] },
 *   { label: 'title 2', children: 'text', links: [{name: 'Name Link 2', to: 'link_2_1'}] },
 * ];
 * */

const MenuNavigation = ({ items, onLinkClick, ...props }) => {
  const isHeaderLink = (item) =>
    item?.buttonProps?.hasOwnProperty('navigation');

  const collapseProps = ['label', 'extra', 'collapsible'];

  const filteredItemProps = (item) =>
    pickBy(item, (value, key) => collapseProps.includes(key));

  const accordionItems = useMemo(
    () =>
      items.map((item) => {
        if (isHeaderLink(item)) {
          return {
            ...filteredItemProps(item),
            label: (
              <Button
                fill
                variant="text"
                customStyles={s}
                onClick={onLinkClick}
                size="xs"
                {...item?.buttonProps}
              >
                {item.label}
              </Button>
            ),
            children: null,
          };
        }

        return {
          ...filteredItemProps(item),
          label: <div className={s.menu_label}>{item.label}</div>,
          children: (
            <LinksContainer links={item.links} onLinkClick={onLinkClick} />
          ),
        };
      }),
    [items],
  );

  return (
    <Accordion
      items={accordionItems}
      customStyles={s}
      inversion
      accordion={false}
      {...props}
    />
  );
};

const LinksContainer = ({ links = [], onLinkClick }) =>
  links.map(({ name, buttonProps, ...link }, index) => (
    <div key={`link_${name}_${index}`}>
      <Button
        fill
        variant="text"
        customStyles={{ button: [s.nested_links] }}
        onClick={onLinkClick}
        {...buttonProps}
      >
        {name}
      </Button>
      {link.withDivider && <div className={s.divider} />}
    </div>
  ));

export default MenuNavigation;
