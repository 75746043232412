import React, { Fragment, memo } from 'react';

import classNames from 'classnames';

import styles from './Stepper.module.scss';

const Stepper = ({ steps, customStyles = {} }) => {
  return (
    <div className={classNames(styles.stepper, customStyles.stepper)}>
      {steps.map((step, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <div className={classNames(styles.edge, customStyles.edge)}></div>
          )}

          <div
            className={classNames(
              styles.element,
              customStyles.element,
              step.isActive && {
                [styles.active]: true,
                [customStyles.active]: true,
              },
            )}
          >
            {step.isActive && <span>{step.value}</span>}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default memo(Stepper);
