import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { ItemTitle } from '@shared/components';
import { CRYPTO_CURRENCIES } from '@shared/constants';

import {
  useCalculateTotalEarnings,
  useChartData,
} from '@pages/AccountPage/hooks';
import { ActionButton } from '@pages/AccountPage/Overview/OverviewEarnWidgets/components/ActionButton';
import { CreateStakeItemSidebar } from '@pages/WalletsPage/Staking/components';
import { useSetSidebarPreset } from '@pages/WalletsPage/Staking/hooks';
import { WALLETS_STAKING_TABS } from '@pages/WalletsPage/Staking/staking.constants';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import { OverviewEarnWidget } from '../OverviewEarnWidget';

import { StakingTable } from './components';

import { MAIN_ROUTES, WALLETS_ROUTES } from 'src/routes.constants';

import mockData from 'src/mock.json';

const Staking = () => {
  const { chartValue, coin } = mockData.Staking;

  const navigate = useNavigate();

  const { currency, totalEarningsInBTC, totalEarningsInSelectedCurrency } =
    useCalculateTotalEarnings();

  const { chartData, color, value } = useChartData(chartValue);

  const { symbol, value: selectedUserCurrency } = useSelector(currencySelector);

  const { toggleStakingSidebar } = useSetSidebarPreset({
    coinSymbol: CRYPTO_CURRENCIES.BTC,
  });

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: currency,
    toCoinSymbol: selectedUserCurrency,
  });

  const handleSubmitSuccess = () => {
    navigate(WALLETS_ROUTES.STAKING, {
      state: {
        activeTabIndex: WALLETS_STAKING_TABS.MY_STAKING,
      },
    });
  };

  return (
    <>
      <ItemTitle
        title={<FormattedMessage id="StakingAssets" />}
        arrowTitle={<FormattedMessage id="Details" />}
        navigation={{ to: MAIN_ROUTES.STAKING_ASSETS }}
      />

      <OverviewEarnWidget
        percentChange={value}
        color={color}
        title={<FormattedMessage id="TotalStakingEarnings" />}
        chartData={chartData}
        value={totalEarningsInBTC}
        valueCurrency={coin}
        totalValue={totalEarningsInSelectedCurrency * ratesByPair}
        totalValueCurrency={selectedUserCurrency}
        totalValueSymbol={symbol}
      />

      <StakingTable />

      <ActionButton onClick={toggleStakingSidebar}>
        <FormattedMessage id="AddNewPlan" />
      </ActionButton>

      <CreateStakeItemSidebar onSubmitSuccess={handleSubmitSuccess} />
    </>
  );
};

export default memo(Staking);
