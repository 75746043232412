import React, { memo, useId, useMemo } from 'react';

import Table from '@components/gridTables/Table';

import { myStakingTableColumns } from '@pages/WalletsPage/Staking/components';
import { useMyStakingTable } from '@pages/WalletsPage/Staking/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

const MyStakingTable = () => {
  const id = useId();

  const { isTabletDown } = useBreakpoints();

  const { stakingList, isMyStakingSuccess } = useMyStakingTable();

  const columns = useMemo(() => myStakingTableColumns(), []);

  return (
    <Table
      id={id}
      columns={columns}
      data={stakingList}
      wideRowsL
      isSwipeable={isTabletDown}
      isLoading={!isMyStakingSuccess}
      className="account-tables-swipe"
      swipeableTableProps={{
        stickyColumns: 1,
        breakpoints: [
          [600, 2],
          [700, 3],
          [900, 4],
          [1024, 5],
        ],
      }}
    />
  );
};

export default memo(MyStakingTable);
