import { forwardRef } from 'react';

import cx from 'classnames';
import ReactOTPInput from 'react-otp-input';

import { FormController } from '@shared/components';

import s from './OTPInput.module.scss';

const OTPInput = forwardRef(
  (
    {
      label,
      numInputs = 4,
      // styles
      rounded,
      bordered = true,
      underlined,
      size = 'm', // 's' | 'l' | 'xl'
      inversion,
      customStyles = {},
      ...props
    },
    ref,
  ) => {
    return (
      <>
        {label && (
          <label
            className={cx(
              s.otp_input__label,
              s[`otp_input__label_${size}`],
              customStyles.otp_input__label,
              {
                [s.label_inversion]: inversion,
              },
            )}
          >
            {label}
          </label>
        )}
        <ReactOTPInput
          numInputs={numInputs}
          renderInput={(inputProps) => <input ref={ref} {...inputProps} />}
          containerStyle={cx(
            s.otp_input__container,
            customStyles.otp_input__container,
            s[size],
            {
              [s.rounded]: rounded,
              [s.bordered]: bordered,
              [s.underlined]: underlined,
              [s.inversion]: inversion,
            },
          )}
          inputStyle={cx(s.otp_input__field, customStyles.otp_input__field)}
          {...props}
        />
      </>
    );
  },
);

export default OTPInput;

export const OTPInputController = (props) => {
  return <FormController component={OTPInput} {...props} />;
};
