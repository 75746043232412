import React, { memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useForm, useWatch } from 'react-hook-form';

import CheckBox from '@components/CheckBox';
import TooltipHelpIcon from '@components/TooltipHelpIcon';

import { marketIdSelector } from '@store/settings/settingsSelectors';

import useDidMountEffect from '@hooks/useDidMountEffect';

import { format } from '@utils/numbers';

import {
  DEFAULT_PRECISION,
  ORDER_FORM_TYPE,
  PERCENT_PRECISION,
} from '@constants';

import FieldContainer from '../../../FieldContainer';
import FormInput from '../../../FormInput';
import SubmitFormButton from '../../../SubmitFormButton';
import { useBothForms } from '../../hooks';

import {
  getActivationPriceFieldRules,
  getAmountFieldRules,
  getCallbackRateFieldRules,
} from './TrailingStopForm.validations';

const TrailingStopForm = ({
  layoutItemId,
  buyTab,
  currentMarket,
  currentPrice,
  setIsTrailing,
  layoutItemRef,
  isOrientationVertical,
}) => {
  const intl = useIntl();

  const marketId = useSelector(marketIdSelector);

  const {
    control,
    setValue,
    trigger,
    formState: { isSubmitted, errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      amount: '',
      activationPrice: '',
      callbackRate: '',
      callbackType: 'percentage',
    },
  });

  const { isShowBothForms } = useBothForms({ layoutItemRef });

  const amountWatch = useWatch({ control, name: 'amount' });
  const activationPriceWatch = useWatch({ control, name: 'activationPrice' });
  const callbackRateWatch = useWatch({ control, name: 'callbackRate' });
  const callbackTypeWatch = useWatch({ control, name: 'callbackType' });

  const callbackPrecision =
    callbackTypeWatch === 'percentage'
      ? PERCENT_PRECISION
      : currentMarket.quote_precision_format;

  const trailingOptions = [
    { label: '%', value: 'percentage' },
    {
      label: currentMarket?.quote_coin_symbol?.toUpperCase(),
      value: 'absolute',
    },
  ];

  useEffect(() => {
    reset();
  }, [marketId]);

  useEffect(() => {
    if (isSubmitted) {
      trigger(['activationPrice', 'callbackRate']);
    }
  }, [currentPrice]);

  useEffect(() => {
    if (isSubmitted) {
      trigger('activationPrice');
    }
  }, [buyTab]);

  useEffect(() => {
    const updatedCallbackRate = format(callbackRateWatch, {
      precision:
        callbackTypeWatch === 'percentage'
          ? PERCENT_PRECISION
          : currentMarket.quote_precision_format,
      noCommas: true,
      removeZeroEnd: true,
    });

    setValue(
      'callbackRate',
      Number(updatedCallbackRate) ? updatedCallbackRate : '',
      { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true },
    );
  }, [callbackTypeWatch]);

  const handleChangeActivationPrice = (value) => {
    setValue('activationPrice', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeCallbackType = (selectedCallbackTypeId) => {
    setValue('callbackType', selectedCallbackTypeId, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleChangeAmount = (value) => {
    setValue('amount', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleMinusPlusClick = (name, isMinus = false) => {
    let value = 0;
    let decimal = DEFAULT_PRECISION;

    switch (name) {
      case 'activationPrice': {
        if (activationPriceWatch) value = Number(activationPriceWatch);

        decimal = currentMarket?.quote_precision_format;

        break;
      }
      case 'amount': {
        if (amountWatch) value = Number(amountWatch);

        decimal = currentMarket?.market_precision_format;

        break;
      }

      default:
        break;
    }

    const step = Number(Math.pow(0.1, decimal).toFixed(decimal));

    value = Number(value.toFixed(decimal));
    value = isMinus ? Math.max(0, value - step) : value + step;
    value = value.toFixed(decimal);

    switch (name) {
      case 'activationPrice': {
        handleChangeActivationPrice(value);

        break;
      }

      case 'amount': {
        handleChangeAmount(value);

        break;
      }

      default:
        break;
    }
  };

  useDidMountEffect(() => {
    reset();
  }, [isShowBothForms]);

  return (
    <>
      <div>
        <FormInput
          control={control}
          name="activationPrice"
          onlyNumbers
          plusminus
          label={
            <span>
              <FormattedMessage id="Activation" />:
            </span>
          }
          errors={errors}
          rules={getActivationPriceFieldRules(
            intl,
            currentPrice,
            currentMarket,
            buyTab,
          )}
          appendLabel={currentMarket?.quote_coin_symbol}
          precision={currentMarket?.quote_precision_format}
          placeholder={format(0, {
            precision: currentMarket?.quote_precision_format,
          })}
          layoutItemRef={layoutItemRef}
          onMinusPlusClick={handleMinusPlusClick}
        />

        <FieldContainer layoutItemRef={layoutItemRef}>
          <div className="d-flex gap-8 my-8">
            <CheckBox
              checked
              label={
                <span className="fs-13">
                  <FormattedMessage id="TrailingStop" />
                </span>
              }
              onChange={(value, e) => setIsTrailing(false)}
            />

            <TooltipHelpIcon
              content={
                <div>
                  <div>
                    <FormattedMessage id="TrailingStopHelpContent1" />
                  </div>
                  <br />
                  <div>
                    <FormattedMessage id="TrailingStopHelpContent2" />
                  </div>
                </div>
              }
            />
          </div>
        </FieldContainer>

        <FormInput
          control={control}
          name="callbackRate"
          onlyNumbers
          label={
            <span>
              <FormattedMessage id="Callback" />:
            </span>
          }
          select
          selectId={`input-controls-select-${layoutItemId}-${
            buyTab ? 'buy' : 'sell'
          }`}
          isSelectSearchable={false}
          appendOptions={trailingOptions}
          selectedAppend={callbackTypeWatch}
          errors={errors}
          rules={getCallbackRateFieldRules(
            intl,
            currentPrice,
            currentMarket,
            callbackTypeWatch,
          )}
          precision={callbackPrecision}
          placeholder={format(0, {
            precision: callbackPrecision,
          })}
          layoutItemRef={layoutItemRef}
          onChangeSelectedAppend={handleChangeCallbackType}
        />
      </div>

      <FormInput
        control={control}
        name="amount"
        onlyNumbers
        plusminus
        label={
          <span>
            <FormattedMessage id="Amount" />:
          </span>
        }
        rules={getAmountFieldRules(intl)}
        errors={errors}
        appendLabel={currentMarket?.market_coin_symbol}
        precision={currentMarket?.market_precision_format}
        placeholder={format(0, {
          precision: currentMarket?.market_precision_format,
        })}
        layoutItemRef={layoutItemRef}
        onMinusPlusClick={handleMinusPlusClick}
      />

      <SubmitFormButton
        isHideFee
        tradeType={ORDER_FORM_TYPE.TRAILING_STOP}
        buyTab={buyTab}
        currentMarket={currentMarket}
        currentPrice={currentPrice}
        getValues={getValues}
        handleSubmit={handleSubmit}
        layoutItemRef={layoutItemRef}
      />
    </>
  );
};

export default memo(TrailingStopForm);
