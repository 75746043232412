import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import { TRANSACTION_TYPES } from '@shared/constants';
import { ButtonArrow } from '@shared/ui';

import WithdrawHistoryTable from './WithdrawHistoryTable/WithdrawHistoryTable';

import { WALLETS_ROUTES } from '../../../../routes.constants';

import styles from './WithdrawHistory.module.scss';

const WithdrawHistory = () => {
  return (
    <div>
      <div className={styles.header}>
        <h2>
          <FormattedMessage id="WithdrawHistory" />
        </h2>

        <ButtonArrow
          navigation={{
            to: generatePath(WALLETS_ROUTES.TRANSACTION_HISTORY, {
              transactionType: TRANSACTION_TYPES.WITHDRAW,
            }),
          }}
          variant="text"
          color="primary"
        >
          <FormattedMessage id="ViewAllHistory" />
        </ButtonArrow>
      </div>

      <WithdrawHistoryTable />
    </div>
  );
};

export default memo(WithdrawHistory);
