import React, { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import styles from './CustomMenu.module.scss';

const { Menu } = components;

const CustomMenu = ({ children, selectProps, customStyles = {}, ...props }) => {
  const isInversion = selectProps.inversion;

  // class menu is needed for animate menu closing.
  return (
    <Menu
      className={cx(styles.custom_menu, 'menu', customStyles.custom_menu, {
        [styles.inversion]: isInversion,
      })}
      {...props}
    >
      {children}
    </Menu>
  );
};

export default memo(CustomMenu);
