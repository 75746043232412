export const getAmountFieldRules = (intl) => {
  return {
    required: intl.formatMessage({ id: 'FieldRequired' }),
    validate: {
      min: (v) => {
        if (Number(v) <= 0) {
          return intl.formatMessage({ id: 'FieldRequired' });
        }
      },
    },
  };
};
