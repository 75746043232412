import React, { memo } from 'react';

import { format } from 'date-fns';

import { DAY_MONTH_YEAR_FORMAT } from '@shared/constants';

import styles from './StakeDateItem.module.scss';

const StakeDateItem = ({ start, expired }) => {
  const startDate = format(new Date(start), DAY_MONTH_YEAR_FORMAT);
  const expiredDate = format(new Date(expired), DAY_MONTH_YEAR_FORMAT);

  return (
    <>
      <span className={styles.date}>{startDate}</span>
      <br />
      <span className={styles.date}>{expiredDate}</span>
    </>
  );
};

export default memo(StakeDateItem);
