import React, { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import Breakpoint from '@components/Breakpoint';
import Button from '@components/Button';

import { ReactComponent as SoundOffIconSVG } from '@icons/sound-off-icon.svg';
import { ReactComponent as SoundOnIconSVG } from '@icons/sound-on-icon.svg';

import styles from './SoundButton.module.scss';

const SoundButton = ({ className }) => {
  const [isSoundOff, setIsSoundOff] = useState(false);

  const toggleSoundState = (e) => setIsSoundOff((prevState) => !prevState);

  return (
    <Button
      s
      className={classnames(styles.sound_button, className)}
      onClick={toggleSoundState}
    >
      {isSoundOff ? <SoundOffIconSVG /> : <SoundOnIconSVG />}

      <Breakpoint size="lg" direction="up">
        <span className="ms-8">
          <FormattedMessage id="Sound" />
        </span>
      </Breakpoint>
    </Button>
  );
};

export default memo(SoundButton);
