import {
  BYTES_IN_ONE_MB,
  FORBIDDEN_FILE_TYPES,
  NAME_REG_EXP,
} from '@shared/constants';

function getFileExtension(fileName, acceptableFileTypes) {
  const splitFileName = fileName.split('.').pop();
  const fileExtension = `.${splitFileName}`.toLowerCase();
  const isAcceptableFileTypesExist = acceptableFileTypes.length > 0;

  if (isAcceptableFileTypesExist) {
    return acceptableFileTypes.find(
      (acceptableType) => acceptableType === fileExtension,
    );
  }

  return FORBIDDEN_FILE_TYPES.find((fileType) => fileType !== fileExtension);
}

function getFileError(file, fileType, sizeMb) {
  const fileName = file.name;
  const splitFileName = fileName.split('.');
  const isAtLeastOneComaInFileName = splitFileName.length < 2;
  const isNotAllowedFileType = isAtLeastOneComaInFileName || !fileType;
  const isHasUnSupportedSymbols = !splitFileName[0].match(NAME_REG_EXP);
  const isSizeError = sizeMb && file.size > sizeMb * BYTES_IN_ONE_MB;

  if (isNotAllowedFileType) {
    return { fileName, error: 'IsNotAllowedFileType' };
  }

  if (isHasUnSupportedSymbols) {
    return { fileName, error: 'HasUnsupportedSymbols' };
  }

  if (isSizeError) {
    return { fileName, error: 'FileSizeIsMoreThan', sizeMb };
  }

  return null;
}

export function validateFile(file, acceptableFileTypes, size) {
  const extension = getFileExtension(file.name, acceptableFileTypes);
  const error = getFileError(file, extension, size);

  return error;
}

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const base64ToFile = (base64String, filename) => {
  if (!base64String) return null;

  const arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);

  let n = bstr.length;

  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export async function fetchImageAsFile(avatarId, toast) {
  try {
    const res = await import(`@images/avatars/avatar-${avatarId}.png`);

    const response = await fetch(res.default);

    const blob = await response.blob();

    const file = new File([blob], 'example.png', { type: 'image' });

    return file;
  } catch (error) {
    toast.error('Error fetching the image');
  }
}

export const acceptedExt = (types) => {
  if (types === undefined) return '';
  return types.map((type) => `${type.toLowerCase()}`).join(',');
};
