import { useEffect } from 'react';

// TODO: maybe we can remove it
const useScroll = () => {
  useEffect(() => {
    return () => {
      enableScroll();
    };
  }, []);

  const disableScroll = () => {
    document.body.style.overflowY = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflowY = '';
  };

  return { disableScroll, enableScroll };
};

export default useScroll;
