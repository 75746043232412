export const MAIN_ROUTES = {
  ROOT: '/',
  HOME: '/home',
  TERMINAL: '/terminal/:marketId?',
  AUTH: '/auth',
  ACCOUNT: '/account',
  WALLETS: '/wallets',
  ORDERS: '/orders',
  DEPOSIT: '/deposit',
  REFERRAL: '/referral',
  SERVICE: '/service',
  SIMPLE_CONVERTER: '/simple-converter',
  MARKETS: '/markets',
  COMING_SOON: '/coming-soon',
  APPROVE_ACTION: '/action/:action_id/approve/:key', // link from email
  EMAIL_CONFIRMED: '/email/confirm/:token', // link from email
  RESET_PASSWORD: '/forgot/:reset_token', // link from email
  CARD: '/card',
  TRADING_BOTS: '/trading-bots',
  STAKING_ASSETS: '/staking-assets',
};

export const AUTH_ROUTES = {
  LOGIN: MAIN_ROUTES.AUTH + '/login',
  RESET_2FA: MAIN_ROUTES.AUTH + '/reset-2fa',
  REGISTER: MAIN_ROUTES.AUTH + '/register/:referral_id?',
  FORGOT_PASSWORD: MAIN_ROUTES.AUTH + '/forgot-password',
  EMAIL_CONFIRMATION: MAIN_ROUTES.AUTH + '/email-confirmation',
  CONFIRM_PHONE: MAIN_ROUTES.AUTH + '/confirm-phone',
  IP_CONFIRMED: MAIN_ROUTES.AUTH + '/ip-confirmed',
};

export const ACCOUNT_ROUTES = {
  OVERVIEW: MAIN_ROUTES.ACCOUNT + '/overview',
  PROFILE: MAIN_ROUTES.ACCOUNT + '/profile',
  REFERRAL_PROGRAM: MAIN_ROUTES.ACCOUNT + '/referral-program',
  SECURITY: MAIN_ROUTES.ACCOUNT + '/security',
  CHANGE_PASSWORD: MAIN_ROUTES.ACCOUNT + '/change-password',
  LAST_LOGINS: MAIN_ROUTES.ACCOUNT + '/last-logins',
  NOTIFICATIONS: MAIN_ROUTES.ACCOUNT + '/notifications',
  API_MANAGEMENT: MAIN_ROUTES.ACCOUNT + '/api',
  API_KEY_SETTINGS: MAIN_ROUTES.ACCOUNT + '/api/settings/:apiKeyId',
  CONFIRM_NEW_API_KEY: MAIN_ROUTES.ACCOUNT + '/api/confirm-new-api-key',
  PRIVATE_API_KEY: MAIN_ROUTES.ACCOUNT + '/api/private-api-key',
  VERIFICATION: MAIN_ROUTES.ACCOUNT + '/verification',
  KYC: MAIN_ROUTES.ACCOUNT + '/verification/kyc',
  GOOGLE_AUTHENTICATION: MAIN_ROUTES.ACCOUNT + '/security/google-auth',
  SMS_AUTHENTICATION: MAIN_ROUTES.ACCOUNT + '/security/sms-auth',
  TRADE_PASSWORD: MAIN_ROUTES.ACCOUNT + '/security/trade-password',
  ANTI_PHISHING_CODE: MAIN_ROUTES.ACCOUNT + '/security/anti-phishing-code',
  DELETE_ACCOUNT: MAIN_ROUTES.COMING_SOON,
};

export const WALLETS_ROUTES = {
  OVERVIEW: MAIN_ROUTES.WALLETS + '/overview',
  MAIN_ACCOUNT: MAIN_ROUTES.WALLETS + '/main-account',
  SPOT_ACCOUNTS: MAIN_ROUTES.WALLETS + '/spot-accounts',
  SPOT_ACCOUNTS_DETAILS: MAIN_ROUTES.WALLETS + '/spot-accounts/:spotAccountId',
  INSTANT_BONUS: MAIN_ROUTES.WALLETS + '/instant-bonus',
  TRADING_BOTS: MAIN_ROUTES.WALLETS + '/trading-bots',
  TRADING_BOTS_START: MAIN_ROUTES.WALLETS + '/trading-bots/start-bot',
  TRADING_BOTS_DETAILS: MAIN_ROUTES.WALLETS + '/trading-bots/:botId',
  STAKING: MAIN_ROUTES.WALLETS + '/staking',
  STAKING_ASSETS_HISTORY: MAIN_ROUTES.WALLETS + '/staking/:stakingId',
  DEPOSIT: MAIN_ROUTES.WALLETS + '/deposit',
  WITHDRAW: MAIN_ROUTES.WALLETS + '/withdraw',
  TRANSACTION_HISTORY:
    MAIN_ROUTES.WALLETS + '/transaction-history/:transactionType?',
  LAUNCHPAD: MAIN_ROUTES.WALLETS + '/launchpad',
  LAUNCHPAD_DETAILS: MAIN_ROUTES.WALLETS + '/launchpad/:launchpadId',
  LAUNCHPAD_BUY: MAIN_ROUTES.COMING_SOON,
  MARGIN_ACCOUNTS: MAIN_ROUTES.COMING_SOON,
  LOAN_CRYPTO: MAIN_ROUTES.COMING_SOON,
  VISA_CARD: MAIN_ROUTES.WALLETS + '/visa-card',
  VISA_CARD_SETTINGS: MAIN_ROUTES.WALLETS + '/visa-card/settings',
  VISA_LAST_TRANSACTIONS: MAIN_ROUTES.COMING_SOON,
  STAKING_TOP_UP: MAIN_ROUTES.COMING_SOON,
};

export const ORDERS_ROUTES = {
  OPEN_ORDERS: MAIN_ROUTES.ORDERS + '/open',
  ORDER_HISTORY: MAIN_ROUTES.ORDERS + '/history',
  TRADE_HISTORY: MAIN_ROUTES.ORDERS + '/trade-history',
  TRADE_FEE: MAIN_ROUTES.ORDERS + '/trade-fee',
};

export const DEPOSIT_ROUTES = {
  CARD: MAIN_ROUTES.DEPOSIT + '/card',
  BANK: MAIN_ROUTES.DEPOSIT + '/bank',
  ADVCASH: MAIN_ROUTES.DEPOSIT + '/advcash',
};

export const REFERRAL_ROUTES = {
  REFERRAL_HISTORY: MAIN_ROUTES.REFERRAL + '/history',
  EARNING_HISTORY: MAIN_ROUTES.REFERRAL + '/earnings',
  OVERVIEW: MAIN_ROUTES.REFERRAL + '/overview',
  ABOUT_REFERRAL_PROGRAM: MAIN_ROUTES.COMING_SOON,
  INVITE_FRIENDS: MAIN_ROUTES.COMING_SOON,
  BANNERS: MAIN_ROUTES.COMING_SOON,
};

export const SIMPLE_CONVERTER_ROUTES = {
  MARKET: MAIN_ROUTES.SIMPLE_CONVERTER + '/market',
  LIMIT: MAIN_ROUTES.SIMPLE_CONVERTER + '/limit',
};

export const MARKETS_ROUTES = {
  ALL: MAIN_ROUTES.MARKETS + '/all',
  DETAILS: MAIN_ROUTES.MARKETS + '/:currency',
};

export const TRADE_ROUTES = {
  FULL_SIZE: MAIN_ROUTES.COMING_SOON,
  MARGIN_TRADING: MAIN_ROUTES.COMING_SOON,
  OTC_PLATFORM: MAIN_ROUTES.COMING_SOON,
  FEATURES: MAIN_ROUTES.COMING_SOON,
  PORTFOLIO: MAIN_ROUTES.COMING_SOON,
};

export const TOKEN_ROUTES = {
  PRDX_BURN: MAIN_ROUTES.COMING_SOON,
  TOKEN_GROWTH: MAIN_ROUTES.COMING_SOON,
};

export const SETTINGS_ROUTES = { SETTINGS: MAIN_ROUTES.COMING_SOON }; // not sure what is it in the Profile sidebar

export const VISA_ROUTES = {
  HOME: MAIN_ROUTES.CARD + '/home',
  ORDER_CARD: MAIN_ROUTES.CARD + '/order',
  CARD_REFERRAL_PROGRAM: MAIN_ROUTES.CARD + '/referral-program',
  AFFILIATE_MERCHANTS: MAIN_ROUTES.COMING_SOON,
  MERCHANT_PROGRAM: MAIN_ROUTES.COMING_SOON,
  FIND_YOUR_OWN_RARITY: MAIN_ROUTES.COMING_SOON,
};

export const SERVICE_ROUTES = {
  GLOSSARY: MAIN_ROUTES.SERVICE + '/glossary',
  TERM: MAIN_ROUTES.SERVICE + '/term',
  INSTANT_BONUS_TERMS: MAIN_ROUTES.SERVICE + '/instant-bonus-terms',
  ANNOUNCEMENTS: MAIN_ROUTES.SERVICE + '/announcements',
  ANNOUNCEMENT: MAIN_ROUTES.SERVICE + '/announcements/:announcementId',
  ABOUT_US: MAIN_ROUTES.SERVICE + '/about-us',
  TOKEN_LISTING: MAIN_ROUTES.SERVICE + '/token-listing',
  CONTACT_US: MAIN_ROUTES.SERVICE + '/contact-us',
  CAREERS: MAIN_ROUTES.SERVICE + '/careers',
  POLICY: MAIN_ROUTES.COMING_SOON,
  RISK_DISCLOSURE: MAIN_ROUTES.COMING_SOON,
  LICENSE_AGREEMENT: MAIN_ROUTES.COMING_SOON,
  COOKIE: MAIN_ROUTES.COMING_SOON,
  KYC: MAIN_ROUTES.COMING_SOON,
  GDPR: MAIN_ROUTES.COMING_SOON,
  SUPPORT: MAIN_ROUTES.COMING_SOON,
  LIVE_SUPPORT: MAIN_ROUTES.COMING_SOON,
  FEES: MAIN_ROUTES.COMING_SOON,
  ARTICLE: MAIN_ROUTES.COMING_SOON,
  BLOG: MAIN_ROUTES.COMING_SOON,
  PROFIT_SHARING: MAIN_ROUTES.COMING_SOON,
  PRDX_TOKEN: MAIN_ROUTES.COMING_SOON,
  ABOUT_BOTS: MAIN_ROUTES.COMING_SOON,
  ABOUT_LAYOUTS: MAIN_ROUTES.COMING_SOON,
  PRE_MADE_LAYOUTS: MAIN_ROUTES.COMING_SOON,
  LOCK_LAYOUT: MAIN_ROUTES.COMING_SOON,
  USER_LAYOUTS: MAIN_ROUTES.COMING_SOON,
  SAVING_AND_USING: MAIN_ROUTES.COMING_SOON,
  CLEAR_LAYOUT: MAIN_ROUTES.COMING_SOON,
  FAQ: MAIN_ROUTES.COMING_SOON,
  ACADEMY: MAIN_ROUTES.COMING_SOON,
};

export const SOCIAL_MEDIA_LINKS = {
  FACEBOOK: 'https://www.facebook.com/Paramountdax-2024636494500768',
  TWITTER: 'https://twitter.com/ParamountDax',
  INSTAGRAM: 'https://www.instagram.com/paramountdax',
  MEDIUM: 'https://medium.com/@paramountdax',
  TELEGRAM: 'https://t.me/joinchat/LNehJxUX9sVoZabNrnaEZA',
  LINKEDIN: 'https://www.linkedin.com/company/14050955/admin/feed/posts/',
  BITCOINTALK: 'https://bitcointalk.org/index.php?topic=5137789.1040',
};

export const EXTERNAL_LINKS = {
  SEPA_COUNTRIES_LIST:
    'https://support.paramountdax.com/hc/en-us/articles/360021970739-What-is-SEPA-',
  SUBMIT_REQUEST: 'https://support.paramountdax.com/hc/en-us/requests/new',
  APPLY_FOR_LISTING:
    'https://docs.google.com/forms/d/1cfmNg3PuGNtTR31CAKnSpLGj14yUXO3z3U24ZfyoKkw/viewform?edit_requested=true',
  STAKING_COUNTRIES_LIST: MAIN_ROUTES.COMING_SOON,
};

export const ROUTES_NAMES = {
  [ACCOUNT_ROUTES.OVERVIEW]: 'AccountOverview',
  [ACCOUNT_ROUTES.PROFILE]: 'Profile',
  [ACCOUNT_ROUTES.REFERRAL_PROGRAM]: 'ReferralProgram',
  [ACCOUNT_ROUTES.SECURITY]: 'SecuritySettings',
  [ACCOUNT_ROUTES.VERIFICATION]: 'AccountVerification',
  [ACCOUNT_ROUTES.API_MANAGEMENT]: 'APIManagement',
  [ACCOUNT_ROUTES.CHANGE_PASSWORD]: 'ChangePassword',
  [ACCOUNT_ROUTES.LAST_LOGINS]: 'LastLogins',
  [ACCOUNT_ROUTES.NOTIFICATIONS]: 'Notifications',
  [ACCOUNT_ROUTES.API_KEY_SETTINGS]: 'APIKeySettings',
  [ACCOUNT_ROUTES.GOOGLE_AUTHENTICATION]: 'GoogleAuthentication',
  [ACCOUNT_ROUTES.SMS_AUTHENTICATION]: 'SMSAuthentication',
  [ACCOUNT_ROUTES.TRADE_PASSWORD]: 'TradePasswordHeader',
  [ACCOUNT_ROUTES.ANTI_PHISHING_CODE]: 'AntiPhishingCode',

  [ORDERS_ROUTES.OPEN_ORDERS]: 'OpenOrders',
  [ORDERS_ROUTES.ORDER_HISTORY]: 'OrderHistory',
  [ORDERS_ROUTES.TRADE_HISTORY]: 'TradeHistory',
  [ORDERS_ROUTES.TRADE_FEE]: 'TradeFee',

  [WALLETS_ROUTES.OVERVIEW]: 'WalletOverview',
  [WALLETS_ROUTES.MAIN_ACCOUNT]: 'MainAccount',
  [WALLETS_ROUTES.SPOT_ACCOUNTS]: 'SpotAccounts',
  [WALLETS_ROUTES.MARGIN_ACCOUNTS]: 'MarginAccounts',
  [WALLETS_ROUTES.INSTANT_BONUS]: 'InstantBonus',
  [WALLETS_ROUTES.TRADING_BOTS]: 'TradingBots',
  [WALLETS_ROUTES.TRADING_BOTS_START]: 'StartBot',
  [WALLETS_ROUTES.TRADING_BOTS_DETAILS]: 'TradingBotsDetails',
  [WALLETS_ROUTES.STAKING]: 'StakingAssets',
  [WALLETS_ROUTES.DEPOSIT]: 'Deposit',
  [WALLETS_ROUTES.WITHDRAW]: 'Withdraw',
  [WALLETS_ROUTES.TRANSACTION_HISTORY]: 'TransactionHistory',
  [WALLETS_ROUTES.LAUNCHPAD]: 'Launchpad',
  [WALLETS_ROUTES.LAUNCHPAD_DETAILS]: 'LaunchpadDetails',
  [WALLETS_ROUTES.VISA_CARD]: 'ParamountDaxCard',
  [WALLETS_ROUTES.VISA_CARD_SETTINGS]: 'CardSettings',

  [DEPOSIT_ROUTES.CARD]: 'BuyCryptoWithCard',
  [DEPOSIT_ROUTES.BANK]: 'DepositFiatByBankTransfer',
  [DEPOSIT_ROUTES.ADVCASH]: 'AdvCashDeposit',

  [REFERRAL_ROUTES.OVERVIEW]: 'ReferralProgram',
  [REFERRAL_ROUTES.REFERRAL_HISTORY]: 'ReferralHistory',
  [REFERRAL_ROUTES.EARNING_HISTORY]: 'EarningsHistory',
  [REFERRAL_ROUTES.BANNERS]: 'Banners',
};

export const ROUTES_SHORT_NAMES = {
  [DEPOSIT_ROUTES.CARD]: 'BuyWithCard',
  [DEPOSIT_ROUTES.BANK]: 'BankDeposit',
  [DEPOSIT_ROUTES.ADVCASH]: 'AdvcashDeposit',

  [WALLETS_ROUTES.OVERVIEW]: 'Overview',
  [WALLETS_ROUTES.VISA_CARD]: 'VisaCard',
  [WALLETS_ROUTES.STAKING]: 'Staking',
};

const { TERMINAL, ROOT, ...mainRoutesRest } = MAIN_ROUTES;

export const ACCOUNTS_THEME_ROUTES = {
  MAIN_ROUTES: mainRoutesRest,
  AUTH_ROUTES,
  ACCOUNT_ROUTES,
  WALLETS_ROUTES,
  ORDERS_ROUTES,
  DEPOSIT_ROUTES,
  REFERRAL_ROUTES,
  SIMPLE_CONVERTER_ROUTES,
  MARKETS_ROUTES,
  TRADE_ROUTES,
  TOKEN_ROUTES,
  SETTINGS_ROUTES,
  VISA_ROUTES,
  SERVICE_ROUTES,
};
