import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import { Button } from '@shared/ui';

import useBreakpoints from '@hooks/useBreakpoints';

import { NOOP } from '@constants';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import s from './TableExpandButton.module.scss';

const TableExpandButton = ({
  isExpanded,
  onToggleExpanded,
  onOpenDetailsModal = NOOP,
  inversion,
}) => {
  const { isTabletDown } = useBreakpoints();

  return isTabletDown && onOpenDetailsModal !== NOOP ? (
    <Button
      size="s"
      variant="text"
      customStyles={s}
      onClick={onOpenDetailsModal}
    >
      <FormattedMessage id="Details" />
      ...
    </Button>
  ) : (
    <Button size="s" variant="text" customStyles={s} onClick={onToggleExpanded}>
      <DownIconSVG
        width={12}
        height={6}
        className={cx(s.icon, {
          [s.icon__expanded]: isExpanded,
          [s.inversion]: inversion,
        })}
      />
    </Button>
  );
};

export default TableExpandButton;
