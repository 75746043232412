import { isNumber } from 'lodash';

import { useCoinsInfo } from '@store/api/hooks';

import { format } from '@utils/numbers';

import { DEFAULT_PRECISION, FIAT_PRECISION } from '@constants';

const getFormattedBotPnl = (coinsInfo) => (botPnL) => {
  const isNoPnl = botPnL === null;

  if (isNoPnl) return '--';

  const currency = botPnL.currency;

  const coinPrecision = coinsInfo?.[currency]?.digits;

  const precision = isNumber(coinPrecision) ? coinPrecision : DEFAULT_PRECISION;

  const formattedBotPnl = botPnL.isContract
    ? format(botPnL.pnl, { precision: FIAT_PRECISION })
    : format(botPnL.botProfit, { precision });

  return formattedBotPnl;
};

export const useFormattedBotPnlCallback = () => {
  const { coinsInfo } = useCoinsInfo();

  return getFormattedBotPnl(coinsInfo);
};

const useFormattedBotPnl = (botPnL) => {
  const formatBotPnl = useFormattedBotPnlCallback();

  return {
    formattedBotPnl: formatBotPnl(botPnL),
    isPercent: botPnL?.isContract,
  };
};

export default useFormattedBotPnl;
