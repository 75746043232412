import { createApi } from '@reduxjs/toolkit/query/react';

import client from '@api/client';

export const launchpadAPI = createApi({
  reducerPath: 'launchpadAPI',
  baseQuery: client,
  endpoints: (builder) => ({
    launchpadProjects: builder.query({
      query: () => ({
        url: '/launchpad',
        method: 'GET',
      }),
    }),
    launchpadProject: builder.query({
      query: ({ projectId }) => ({
        url: `/admin/launchpad/${projectId}`,
        method: 'GET',
      }),
    }),
    buyLaunchpadProject: builder.mutation({
      query: ({ projectId }) => ({
        url: `/launchpad/${projectId}/buy`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLaunchpadProjectsQuery,
  useLaunchpadProjectQuery,
  useBuyLaunchpadProjectMutation,
} = launchpadAPI;
