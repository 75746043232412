import { VisaApiSelectors } from '../selectors/visaAPI.selectors';
import { useGetUserCurrentCardBalanceQuery } from '../visaAPI';

const useGetUserCurrentCardBalanceQueryResult = (options = {}) => {
  return useGetUserCurrentCardBalanceQuery(undefined, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      cardBalance: VisaApiSelectors.cardBalanceSelector(result),
    }),
  });
};

export default useGetUserCurrentCardBalanceQueryResult;
