import { memo, useId } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import { Widget, InfoItem, PersonalData, PieChart } from '@shared/components';

import { isShowSensitiveInfoSelector } from '@store/settings/settingsSelectors';

import { format } from '@utils/numbers';

import { ReactComponent as NoCoinsPieChartSVG } from '@icons/no-coins-pie-chart.svg';

import { useTopAssets } from '../../hooks';

import { PIE_CHART_COLORS } from '../../overview.constants';

import styles from './PortfolioCoinsWidget.module.scss';

const PortfolioCoinsWidget = () => {
  const chartId = useId();
  const intl = useIntl();
  const { topAssets, totalAssets } = useTopAssets();
  const isShowSensitiveInfo = useSelector(isShowSensitiveInfoSelector);

  const NO_ASSETS = [
    { label: intl.formatMessage({ id: 'NoAssets' }), percent: 100 },
  ];

  const isAssetsExist = !isEmpty(topAssets);

  const chartData = isAssetsExist ? topAssets : NO_ASSETS;

  return (
    <Widget className={styles.portfolio_coins_widget}>
      <div className={styles.content}>
        <div className={styles.chart}>
          {isAssetsExist && isShowSensitiveInfo ? (
            <PieChart
              chartId={chartId}
              data={chartData}
              colors={PIE_CHART_COLORS}
            />
          ) : (
            <NoCoinsPieChartSVG width={84} height={84} />
          )}
        </div>

        <div className={styles.portfolio_coins}>
          <InfoItem
            strong
            title={
              <>
                <FormattedMessage id="Portfolio" /> -{' '}
                <FormattedMessage
                  id="Coin"
                  values={{ itemCount: totalAssets }}
                />
              </>
            }
          >
            {isAssetsExist ? (
              <div className={styles.coins}>
                {topAssets.map(({ label, percent }, index) => (
                  <div key={index} style={{ color: PIE_CHART_COLORS[index] }}>
                    {label} -{' '}
                    <PersonalData data={format(percent, { precision: 1 })} />%
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.no_coins}>
                <FormattedMessage
                  id="NoDataToBuildGraph"
                  values={{ br: <br /> }}
                />
              </div>
            )}
          </InfoItem>
        </div>
      </div>
    </Widget>
  );
};

export default memo(PortfolioCoinsWidget);
