import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import cx from 'classnames';

import {
  SideBarConfirmTopUp,
  SideBarTopUpCard,
} from '@pages/WalletsPage/VisaCardDetails/components';
import { VisaCardMock } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.mocks';
import { visaCardDetailsActions } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.slice';
import {
  CardInfoBlock,
  SettingsMenu,
} from '@pages/WalletsPage/VisaCardSettings/components';

import useBreakpoints from '@hooks/useBreakpoints';

import s from './VisaCardSettings.module.scss';

const VisaCardSettings = () => {
  const { isTabletDown } = useBreakpoints();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(visaCardDetailsActions.setVisaCard(VisaCardMock.card));
  }, [dispatch]);

  return (
    <div>
      <div className={cx({ [s.bordered]: !isTabletDown })}>
        <CardInfoBlock />
      </div>
      <SettingsMenu />

      <SideBarTopUpCard />
      <SideBarConfirmTopUp />
    </div>
  );
};

export default VisaCardSettings;
