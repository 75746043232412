import { endOfDay, getTime, startOfDay } from 'date-fns';

export const dateShouldBeLessOrHigher = (errorMessage, getValues) => () => {
  const { from_year, from_month, to_year, to_month } = getValues();

  const getFromDate = () => {
    const year = from_year.value;
    const month = from_month.order;

    return getTime(startOfDay(new Date().setFullYear(year, month, 1)));
  };

  const getToDate = () => {
    const year = to_year.value;
    const month = to_month.order;

    return getTime(endOfDay(new Date().setFullYear(year, month, 1)));
  };

  const isBefore = getFromDate() < getToDate();

  return isBefore || errorMessage;
};
