import React, { memo, useMemo } from 'react';

import cx from 'classnames';

import { useCurrencyIcon } from '@shared/hooks';

import { useCoinsInfo } from '@api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import styles from './MarketPairCell.module.scss';

const MarketPairCell = ({
  marketInfo,
  inversion,
  iconAccessor = 'icon',
  isShowIcon = true,
  isShowOnlyMarketName,
  isShowOnlyMarketNameAndFullName,
}) => {
  const { coinsInfoMap } = useCoinsInfo();

  const { isLaptopDown, isTabletDown } = useBreakpoints();

  const iconSize = useMemo(() => {
    let size = 36;
    if (isLaptopDown) size = 28;
    if (isTabletDown) size = 24;

    return size;
  }, [isLaptopDown, isTabletDown]);

  const marketCoinSymbol = useMemo(
    () => marketInfo?.market_coin_symbol,
    [marketInfo],
  );
  const quoteCoinSymbol = useMemo(
    () => marketInfo?.quote_coin_symbol,
    [marketInfo],
  );

  const { fullMarketName, fullQuoteName } = useMemo(() => {
    const findCurrencyName = (currencySymbol) => {
      return coinsInfoMap.find((coin) => coin.symbol === currencySymbol)?.name;
    };

    const fullMarketName = findCurrencyName(marketCoinSymbol);

    const fullQuoteName = findCurrencyName(quoteCoinSymbol);

    return { fullMarketName, fullQuoteName };
  }, [coinsInfoMap, marketCoinSymbol, quoteCoinSymbol]);

  const currencyIcon = useCurrencyIcon(
    marketCoinSymbol,
    {
      width: iconSize,
      height: iconSize,
      style: {
        minWidth: iconSize,
        minHeight: iconSize,
        maxWidth: iconSize,
        maxHeight: iconSize,
      },
    },
    iconAccessor,
  );

  let MarketPairComponent = (
    <>
      <div className="d-flex align-items-center gap-12">
        {isShowIcon && <span className={styles.icon}>{currencyIcon}</span>}
        <span className={styles.market_name}>{marketInfo?.name}</span>
      </div>
      <span className={styles.market_fullname}>
        {fullMarketName} / {fullQuoteName}
      </span>
    </>
  );

  if (isShowOnlyMarketName) {
    MarketPairComponent = (
      <span className={styles.market_name}>{marketInfo?.name}</span>
    );
  }

  if (isShowOnlyMarketNameAndFullName) {
    MarketPairComponent = (
      <>
        <div className="d-flex align-items-center gap-12">
          <span className={styles.market_name}>{marketInfo?.name}</span>
        </div>
        <span className={styles.market_fullname}>
          {fullMarketName} / {fullQuoteName}
        </span>
      </>
    );
  }

  return (
    <div
      className={cx(styles.markets_pair_cell, {
        [styles.inversion]: inversion,
      })}
    >
      {MarketPairComponent}
    </div>
  );
};

export default memo(MarketPairCell);
