import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useWatch } from 'react-hook-form';

import {
  CurrenciesSelect,
  transformToCurrencyOptions,
} from '@shared/components';
import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';
import { ALL_OPTIONS } from '@shared/components/TableFilters/TableFilters.constants';

import { useCoinsInfo, useMarketsInfo } from '@api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

const MarketsPair = ({ nameFrom, nameTo, ...props }) => {
  const intl = useIntl();

  const { isDesktop } = useBreakpoints();

  const { marketsInfoMap } = useMarketsInfo();
  const { coinsInfoMap } = useCoinsInfo({
    orderByTypeDirection: 'asc',
  });

  const marketCurrencies = useMemo(() => {
    let marketCurrencySymbols = marketsInfoMap.map(
      (market) => market.market_coin_symbol,
    );

    marketCurrencySymbols = [...new Set(marketCurrencySymbols)];

    const coins = [
      ALL_OPTIONS,
      ...coinsInfoMap.filter((coin) =>
        marketCurrencySymbols.includes(coin.symbol),
      ),
    ];

    return transformToCurrencyOptions({
      currencies: coins,
      isShowFullName: false,
      isShowCurrencyIcon: isDesktop,
    });
  }, [marketsInfoMap, coinsInfoMap, isDesktop]);

  const quoteCurrencies = useMemo(() => {
    let quoteCurrencySymbols = marketsInfoMap.map(
      (market) => market.quote_coin_symbol,
    );

    quoteCurrencySymbols = [...new Set(quoteCurrencySymbols)];

    const coins = [
      ALL_OPTIONS,
      ...coinsInfoMap.filter((coin) =>
        quoteCurrencySymbols.includes(coin.symbol),
      ),
    ];

    return transformToCurrencyOptions({
      currencies: coins,
      isShowFullName: false,
      isShowCurrencyIcon: isDesktop,
    });
  }, [marketsInfoMap, coinsInfoMap, isDesktop]);

  const marketFromWatch = useWatch({
    control: props.control,
    name: TABLE_FILTERS_NAMES.MARKETS_PAIR.FROM,
  });

  const marketToWatch = useWatch({
    control: props.control,
    name: TABLE_FILTERS_NAMES.MARKETS_PAIR.TO,
  });

  const setDefaultValues = () => {
    props.setValue(TABLE_FILTERS_NAMES.MARKETS_PAIR.FROM, marketCurrencies[0]);
    props.setValue(TABLE_FILTERS_NAMES.MARKETS_PAIR.TO, quoteCurrencies[0]);
  };

  useEffect(() => {
    if (!marketCurrencies.length || !quoteCurrencies.length) return;

    setTimeout(() => {
      setDefaultValues();
    }, 0);
  }, [marketsInfoMap, coinsInfoMap]);

  // to handle reset event
  useEffect(() => {
    if (!!marketFromWatch || !!marketToWatch) return;

    setDefaultValues();
  }, [marketFromWatch, marketToWatch]);

  return (
    <div className="d-flex align-items-center mx-0 md-me-24 xxl-me-40">
      <span className="me-12 xl-me-16">
        <FormattedMessage id="Pair" />:
      </span>

      <CurrenciesSelect
        styles={{ control: { paddingLeft: '14px', minWidth: '120px' } }}
        currencies={marketCurrencies}
        size="m"
        name={nameFrom}
        bordered
        placeholder={`${intl.formatMessage({ id: 'PairFrom' })}...`}
        defaulValue={marketCurrencies[0]}
        {...props}
      />

      <span className="mx-10">/</span>

      <CurrenciesSelect
        styles={{ control: { paddingLeft: '14px', minWidth: '120px' } }}
        currencies={quoteCurrencies}
        size="m"
        name={nameTo}
        bordered
        placeholder={`${intl.formatMessage({ id: 'PairTo' })}...`}
        defaulValue={quoteCurrencies[0]}
        {...props}
      />
    </div>
  );
};

export default MarketsPair;
