import React, { memo, useEffect } from 'react';

import {
  PaginationContainer,
  PaginationRange,
} from '@shared/components/Pagination/components';
import { useDotsPagination } from '@shared/hooks';

import styles from './TablePagination.module.scss';

const TablePagination = ({
  table,
  meta,
  customStyles,
  isShowPaginationArrows,
  isMinimizedPagination,
}) => {
  const pagesShownUpperLimit =
    Math.min(meta.limit * meta.page, meta.count) || 0;

  const totalPageCount = table.getPageCount() || 1;

  const activePageIndex = Math.max(table.getState().pagination.pageIndex, 0);

  const paginationRange = useDotsPagination({
    currentPage: activePageIndex + 1,
    totalPageCount,
  });

  useEffect(() => {
    table.resetExpanded();
  }, [activePageIndex, table]);

  const handleSetPageIndex = (index) => {
    table.setPageIndex(index);
  };

  const handleSetLastPage = () => {
    handleSetPageIndex(totalPageCount - 1);
  };

  const handleSetFirstPage = () => {
    handleSetPageIndex(0);
  };

  if (!paginationRange) {
    return null;
  }

  return (
    <PaginationContainer
      meta={meta}
      customStyles={customStyles}
      pagesShownUpperLimit={pagesShownUpperLimit}
      isMinimizedPagination={isMinimizedPagination}
    >
      {isShowPaginationArrows && (
        <>
          {!isMinimizedPagination && (
            <button
              disabled={!table.getCanPreviousPage()}
              className={styles.double_arrow}
              onClick={handleSetFirstPage}
            >
              {'<<'}
            </button>
          )}
          <button
            disabled={!table.getCanPreviousPage()}
            className={styles.single_arrow}
            onClick={() => table.previousPage()}
          >
            {'<'}
          </button>
        </>
      )}

      <PaginationRange
        activePageIndex={activePageIndex}
        paginationRange={paginationRange}
        handleSetPageIndex={handleSetPageIndex}
        isMinimizedPagination={isMinimizedPagination}
      />

      {isShowPaginationArrows && (
        <>
          <button
            className={styles.single_arrow}
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            {'>'}
          </button>
          {!isMinimizedPagination && (
            <button
              className={styles.double_arrow}
              disabled={!table.getCanNextPage()}
              onClick={handleSetLastPage}
            >
              {'>>'}
            </button>
          )}
        </>
      )}
    </PaginationContainer>
  );
};

export default memo(TablePagination);
