import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';

import { Card, FORM_ACTION_ORIENTATION } from '@shared/components';
import FormActions from '@shared/components/FormActions/FormActions';
import { SMS_OTP_NUMBER_LENGTH } from '@shared/constants';
import { OTPInputController } from '@shared/ui';

import { useProfileMutations } from '@api/hooks';

import { PhoneNumberForm } from '../../PhoneNumberForm';

import styles from './EnableSMSAuth.module.scss';

const EnableSMSAuth = () => {
  const intl = useIntl();

  const methods = useForm({ defaultValues: { code: '' } });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { onBindPhoneNumber, isBindPhoneNumberLoading } = useProfileMutations();

  const [authId, setAuthId] = useState();

  const handleSendCode = (authId) => {
    setAuthId(authId);
  };

  const handleFormSubmit = ({ code }) => {
    onBindPhoneNumber({ code, id: authId });
  };

  return (
    <div className={cx('security-card', styles.security_card)}>
      <Card
        customStyles={styles}
        title={<FormattedMessage id="EnableSMSAuthentication" />}
      >
        <div className="security-card-description">
          <FormattedMessage id="EnableSMSAuthenticationDescription" />
        </div>

        <div className={styles.form}>
          <PhoneNumberForm onSendCode={handleSendCode} />

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <OTPInputController
                name="code"
                label={`${intl.formatMessage({ id: 'EnterCodeFromSMS' })}:`}
                numInputs={SMS_OTP_NUMBER_LENGTH}
                size="xl"
                rounded
                rules={{
                  required: intl.formatMessage({ id: 'FieldRequired' }),
                  minLength: {
                    value: SMS_OTP_NUMBER_LENGTH,
                    message: intl.formatMessage({ id: 'FieldRequired' }),
                  },
                }}
                {...methods}
              />

              <div className="mt-30">
                <FormActions
                  submitText={<FormattedMessage id="SubmitAndEnable" />}
                  submitButtonProps={{
                    isLoading: isBindPhoneNumberLoading,
                    disabled: isBindPhoneNumberLoading || !isValid,
                  }}
                  cancelButtonProps={{
                    variant: 'text',
                  }}
                  orientation={FORM_ACTION_ORIENTATION.VERTICAL}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </Card>
    </div>
  );
};

export default memo(EnableSMSAuth);
