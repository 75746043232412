import { useMemo } from 'react';

const usePagination = (pagination, data) => {
  const pageIndex = pagination.pageIndex + 1;
  const pageSize = pagination.pageSize;

  const pageData = useMemo(() => {
    const indexOfLastItem = pageIndex * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;

    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    return currentItems;
  }, [data, pageIndex, pageSize]);

  return { pageData };
};

export default usePagination;
