import { forwardRef, useState } from 'react';

import cx from 'classnames';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { Select as SelectVirtualized } from 'react-select-virtualized';

import { FormController } from '@shared/components';

import Loader from '@components/Loader';

import {
  ClearIndicator,
  Control,
  CreateLabel,
  DropdownIndicator,
  IndicatorsContainer,
  GroupHeading,
  Menu,
  MenuList,
  NoOptionsMessage,
  Option,
  Placeholder,
  Input,
} from './components';

import s from './Select.module.scss';

/**
 * PROPS:
 *
 * options - [{value: 'value', label: 'Label'}]
 * optionsGroup = [{ label: 'Group Name Header 1', options: options }]
 *
 * async:
 * loadOptions = (input, callback) => {getSmt(input); callback(result)}
 *
 * Option customizing in react-select and react-select-virtualized is different.
 * To customize react-select option enough just pass a jsx component. But to customize
 * react-select-virtualized option you should pass formatOptionLabel method. And you need
 * also customize SingleValue component and pass it in the specificComponents.
 *
 * Example:
 * const formatOptionLabel = ({ value }) => <strong>{value}</strong>
 *
 * const SpecificSingleValue = (props) => {
 *   const { value } = props.data;
 *
 *   return <strong>{value}</strong>;
 * };
 *
 * <Select
 *   virtualized
 *   formatOptionLabel={formatOptionLabel}
 *   specificComponents={{
 *     SingleValue: SpecificSingleValue,
 *   }}
 *   ...
 * />
 * */

const Select = forwardRef(
  (
    {
      options,
      loadOptions,
      creatable,
      virtualized,
      specificComponents,

      // styles
      inversion,
      bordered,
      underlined,
      size = 'l', // m (medium), l (large) - select height (m: 38px, l: 48px)
      styles = {},

      // for control
      isInvalid,
      customStyles = {},
      ...props
    },
    ref,
  ) => {
    const [uniqueId] = useState(
      () => 'select_' + Math.random().toFixed(5).slice(2),
    );

    const isAsyncSelect = !!loadOptions;

    /*
     * to animate menu closing need to clone menu component,
     * add him class and animate closing of this class
     * and then remove it from DOM
     */
    const handleMenuClose = () => {
      const menuEl = document.querySelector(`#${uniqueId} .menu`);
      const containerEl = menuEl?.parentElement;
      const clonedMenuEl = menuEl?.cloneNode(true);

      if (!clonedMenuEl) return; // safeguard

      clonedMenuEl.classList.add('menu--close');
      clonedMenuEl.addEventListener('animationend', () => {
        containerEl?.removeChild(clonedMenuEl);
      });

      containerEl?.appendChild(clonedMenuEl);
    };

    const selectConfigs = {
      ref,
      id: uniqueId,
      inversion,
      bordered,
      underlined,
      size,
      invalid: isInvalid,
      unstyled: true,
      withDropdownIndicator: true,
      classNamePrefix: 'react-select',
      menuPosition: 'fixed',
      menuPlacement: 'auto',
      menuPortalTarget: document.body,
      //   blurInputOnSelect: true,
      // onChange: handleChange,
      onMenuClose: handleMenuClose,
      styles: {
        ...(styles || {}),
        singleValue: (base) => ({
          ...base,
          ...(styles?.singleValue || {}),
        }),
        control: (base) => ({
          ...base,
          cursor: 'pointer',
          ...(styles.control || {}),
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          ...(styles.menuPortal || {}),
        }),
        menu: (base) => ({
          ...base,
          fontWeight: '600',
          ...(styles?.menu || {}),
        }),
        // input: (base) => ({
        //   ...base,
        //   ...(styles?.input || {}),
        // }),
        // container: (base) => ({ ...base, ...(props?.styles?.container || {}) }),
        // noOptionsMessage: (base) => ({
        //   ...base,
        //   ...(styles?.noOptionsMessage || {}),
        // }),
        placeholder: (base) => ({
          ...base,
          ...(styles?.placeholder || {}),
        }),
        // indicatorsContainer: (base) => ({
        //   ...base,
        //   ...(styles?.indicatorsContainer || {}),
        // }),
        dropdownIndicator: (base) => ({
          ...base,
          ...(styles?.dropdownIndicator || {}),
        }),
      },
      ...props,
    };

    const selectComponents = {
      Control,
      ClearIndicator,
      Menu,
      MenuList,
      GroupHeading,
      Option,
      IndicatorsContainer,
      DropdownIndicator,
      LoadingIndicator: () => <Loader xs />,
      NoOptionsMessage,
      Placeholder,
      Input,
      ...specificComponents,
    };

    let SelectComponent = (
      <ReactSelect
        components={selectComponents}
        options={options}
        {...selectConfigs}
      />
    );

    if (isAsyncSelect) {
      if (creatable) {
        SelectComponent = (
          <AsyncCreatableSelect
            components={selectComponents}
            loadOptions={loadOptions}
            formatCreateLabel={(inputValue) => (
              <CreateLabel inputValue={inputValue} />
            )}
            {...selectConfigs}
          />
        );
      } else {
        SelectComponent = (
          <AsyncSelect
            components={selectComponents}
            loadOptions={loadOptions}
            {...selectConfigs}
          />
        );
      }
    }

    if (creatable) {
      SelectComponent = (
        <CreatableSelect
          components={selectComponents}
          formatCreateLabel={() => null}
          options={options}
          {...selectConfigs}
        />
      );
    }

    if (virtualized) {
      SelectComponent = (
        <SelectVirtualized
          components={selectComponents}
          options={options}
          {...props}
          {...selectConfigs}
        />
      );
    }

    return (
      <div
        className={cx(s.select_wrapper, customStyles.select_wrapper, {
          [s.inversion]: inversion,
        })}
      >
        {props?.label && <label>{props.label}</label>}
        {SelectComponent}
      </div>
    );
  },
);

export const SelectController = (props) => {
  return <FormController component={Select} {...props} />;
};

export default Select;
