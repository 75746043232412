import React, { forwardRef, memo } from 'react';

import classnames from 'classnames';
import RCSlider from 'rc-slider';

import 'rc-slider/assets/index.css';

import { FieldController } from '@shared/components';

import styles from './Slider.module.scss';

const Slider = forwardRef(
  (
    {
      id,
      marks = {},
      value = 0,
      withoutLine = false,
      withoutMarks = false,
      containerClassName,
      sliderContainerClassName,
      customStyles = {},
      onChange,
      className,
      controllerRef,
      isError,
      errorMessage,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={classnames(
          styles.slider,
          customStyles.slider,
          containerClassName,
          {
            [styles.slider__no_marks]:
              !Object.entries(marks).length || withoutMarks,
          },
        )}
      >
        {withoutLine ? (
          <div className="d-flex justify-content-between">
            {Object.entries(marks)
              .sort((a, b) => Number(a[0]) - Number(b[0]))
              .map(([key, val]) => (
                <label
                  key={key}
                  className={classnames(
                    styles.slider__marks,
                    customStyles.slider__marks,
                    {
                      [styles.active]: value === Number(key),
                      [customStyles.active]: value === Number(key),
                    },
                  )}
                  onClick={() => onChange(Number(key))}
                >
                  {val}
                </label>
              ))}
          </div>
        ) : (
          <div
            className={classnames(
              styles.slider__container,
              customStyles.slider__container,
            )}
          >
            <RCSlider
              ref={ref}
              id={id}
              marks={marks}
              value={value}
              onChange={onChange}
              {...rest}
            />
          </div>
        )}
      </div>
    );
  },
);

export const SliderController = memo((props) => {
  return <FieldController {...props} component={Slider} />;
});

export default memo(Slider);
