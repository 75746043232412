import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { isEmpty } from 'lodash';

import {
  Card,
  PersonalData,
  CoinsIconSet,
  InfoItem,
  InfoWidget,
  TotalValue,
  MenuActionsButton,
} from '@shared/components';
import {
  ACCOUNT_GROUP,
  DISPLAY_COINS_QUANTITY,
  MAIN_ACCOUNT_ID,
} from '@shared/constants';
import { useTotalBalance, useOrderedBalancesByRate } from '@shared/hooks';
import { Button, ButtonArrow } from '@shared/ui';

import Breakpoint from '@components/Breakpoint';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar/useSidebar';

import { sidebarIds } from '@constants';

import { WALLETS_ROUTES } from 'src/routes.constants';

import styles from './MainAccount.module.scss';

const MainAccount = ({ currency }) => {
  const navigate = useNavigate();

  const { isLargeTabletDown } = useBreakpoints();

  const { openSidebar } = useSidebar(sidebarIds.TRANSFER);

  const { orderedBalancesMap } = useOrderedBalancesByRate({
    accountId: ACCOUNT_GROUP.MAIN,
  });
  const {
    formattedTotalBalance: formattedEstimatedValue,
    totalBalanceCoinSymbol: estimatedValueCoinSymbol,
  } = useTotalBalance({ coinSymbol: currency, accountId: ACCOUNT_GROUP.MAIN });
  const { formattedTotalBalance, totalBalanceCoinSymbol } = useTotalBalance({
    accountId: ACCOUNT_GROUP.MAIN,
  });

  const assetsNames = useMemo(() => {
    return orderedBalancesMap.map((asset) => asset.symbol);
  }, [orderedBalancesMap]);

  const restIconsLength = assetsNames.length - DISPLAY_COINS_QUANTITY;

  const accountId = { accountId: MAIN_ACCOUNT_ID };

  const menuItems = useMemo(() => {
    const items = [
      {
        name: 'transfer',
        title: <FormattedMessage id="Transfer" />,
        onClick: () => {
          openSidebar(accountId);
        },
      },
    ];

    if (isLargeTabletDown) {
      items.unshift({
        name: 'withdraw',
        title: <FormattedMessage id="Withdraw" />,
        onClick: () => {
          navigate(WALLETS_ROUTES.WITHDRAW);
        },
      });
    }

    return items;
  }, [isLargeTabletDown]);

  const handleOpenSidebar = () => {
    openSidebar(accountId);
  };

  return (
    <div className={styles.card}>
      <Card
        title={
          <div className={styles.title}>
            <div>
              <FormattedMessage id="MainAccount" />
            </div>
          </div>
        }
      >
        <div className={styles.container}>
          <div className="d-flex flex-column justify-content-between">
            <div className="mb-24">
              <InfoWidget
                title={<FormattedMessage id="AvailableValue" />}
                isTruncateValue
                value={<PersonalData data={formattedEstimatedValue} />}
                suffix={
                  <span className="value-suffix text-uppercase">
                    {estimatedValueCoinSymbol}
                  </span>
                }
              />

              <TotalValue
                customStyles={styles}
                value={formattedTotalBalance}
                symbol={totalBalanceCoinSymbol.toUpperCase()}
              />
            </div>

            <ButtonArrow
              navigation={{ to: WALLETS_ROUTES.MAIN_ACCOUNT }}
              color="primary"
              variant="outlined"
              customStyles={styles}
            >
              <FormattedMessage id="Details" />
            </ButtonArrow>
          </div>

          <div className="d-flex flex-column justify-content-between">
            <InfoItem title={<FormattedMessage id="AvailableAssets" />}>
              {isEmpty(assetsNames) ? (
                <div className={styles.no_assets}>
                  <PersonalData data={0} />
                </div>
              ) : (
                <div className={styles.assets}>
                  <CoinsIconSet
                    coins={assetsNames}
                    iconOffset={isLargeTabletDown ? 10 : 14}
                    iconSize={isLargeTabletDown ? 24 : 36}
                  />
                  {restIconsLength > 0 && (
                    <>
                      <Breakpoint size="lg" direction="up">
                        <Button
                          navigation={{ to: WALLETS_ROUTES.MAIN_ACCOUNT }}
                          isCircle
                          variant="outlined"
                        >
                          +{restIconsLength}
                        </Button>
                      </Breakpoint>
                      <Breakpoint size="lg" direction="down">
                        <div className={styles.mobile_rest_icons}>
                          +{restIconsLength}
                        </div>
                      </Breakpoint>
                    </>
                  )}
                </div>
              )}
            </InfoItem>

            <div className={styles.assets_info_buttons}>
              <Button navigation={{ to: WALLETS_ROUTES.DEPOSIT }}>
                <FormattedMessage id="Deposit" />
              </Button>
              <Breakpoint size="lg" direction="up">
                <Button
                  navigation={{ to: WALLETS_ROUTES.WITHDRAW }}
                  variant="outlined"
                >
                  <FormattedMessage id="Withdraw" />
                </Button>
              </Breakpoint>
              <Breakpoint size="xl" direction="up">
                <Button
                  variant="outlined"
                  type="button"
                  onClick={handleOpenSidebar}
                >
                  <FormattedMessage id="Transfer" />
                </Button>
              </Breakpoint>

              <Breakpoint size="xl" direction="down">
                <MenuActionsButton menuItems={menuItems} />
              </Breakpoint>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default memo(MainAccount);
