import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFormContext, useWatch } from 'react-hook-form';

import { InputController } from '@shared/ui';

import {
  SpotAccountForm,
  TransferToSpotAccountDetails,
  TransferToUser,
} from '@pages/WalletsPage/VisaCardDetails/components';
import { SPOT_ACCOUNT_FORM_FIELD_NAMES } from '@pages/WalletsPage/VisaCardDetails/components/sidebars/components/SpotAccountForm/SpotAccountForm';
import { TRANSFER_RECIPIENTS } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.constants';
import { transferRecipientSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

const TransferRecipients = () => {
  const intl = useIntl();

  const transferRecipient = useSelector(transferRecipientSelector);

  const methods = useFormContext();

  const currencyWatch = useWatch({
    control: methods.control,
    name: 'currency',
  });

  if (transferRecipient === TRANSFER_RECIPIENTS.SPOT_ACCOUNT) {
    return (
      <>
        <InputController
          name={SPOT_ACCOUNT_FORM_FIELD_NAMES.ACCOUNT}
          label={intl.formatMessage({ id: 'SpotAccount' })}
          placeholder={intl.formatMessage({ id: 'TypeTheRecipientsAccount' })}
          control={methods.control}
          rules={{
            required: intl.formatMessage({ id: 'FieldRequired' }),
          }}
          size="l"
          bordered={false}
          underlined
          inversion
        />

        <div className="mt-32">
          <SpotAccountForm />
        </div>

        <div className="mt-32">
          <TransferToSpotAccountDetails selectedCurrency={currencyWatch} />
        </div>
      </>
    );
  }

  return <TransferToUser />;
};

export default TransferRecipients;
