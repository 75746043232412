import { memo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';

import { Button } from '@shared/ui';

import SideBar from '@components/SideBar';

import {
  currencySelector,
  localeSelector,
} from '@store/settings/settingsSelectors';
import { settingsActions } from '@store/settings/settingsSlice';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { LOCALE_LIST, CURRENCY_LIST, sidebarIds } from '@constants';

import { ReactComponent as HorizontalLineIconSVG } from '@icons/horizontal-line-icon.svg';

import LocalizationList from './components/LocalizationList';

const SideBarLocalization = ({
  withLocale = true,
  withCurrency = true,
  isNested = false,
}) => {
  const { isOpen, toggleSidebar } = useSidebar(sidebarIds.LOCALIZATION);
  const { toggleSidebar: toggleNavigationSidebar } = useSidebar(
    sidebarIds.NAVIGATION,
  );

  const dispatch = useDispatch();

  const locale = useSelector(localeSelector);
  const currency = useSelector(currencySelector);

  const { isTabletDown } = useBreakpoints();

  const [selectedLocale, setSelectedLocale] = useState(locale);
  const [selectedCurrency, setSelectedCurrency] = useState(currency);

  const isHorizontalLineVisible = withLocale && withCurrency;

  useEffect(() => {
    setSelectedLocale(locale);
    setSelectedCurrency(currency);
  }, [isOpen, locale, currency]);

  const handleLocaleItemClick = (e, localeItem) => {
    if (isTabletDown) {
      setSelectedLocale(localeItem);

      return;
    }

    dispatch(settingsActions.setLocale(localeItem));
  };

  const handleCurrencyItemClick = (e, currencyItem) => {
    if (isTabletDown) {
      setSelectedCurrency(currencyItem);

      return;
    }

    dispatch(settingsActions.setCurrency(currencyItem));
  };

  const handleApply = () => {
    if (selectedLocale.value !== locale.value) {
      dispatch(settingsActions.setLocale(selectedLocale));
    }

    if (selectedCurrency.value !== currency.value) {
      dispatch(settingsActions.setCurrency(selectedCurrency));
    }

    if (isNested) {
      toggleNavigationSidebar();
    } else {
      toggleSidebar();
    }
  };

  const showBackButton = isTabletDown && isNested;

  return (
    <>
      <div
        className={classnames('sidebar-localization', {
          active: isOpen,
        })}
        onClick={toggleSidebar}
      >
        {withLocale && (
          <div className="selected-locale">{selectedLocale.value}</div>
        )}

        {isHorizontalLineVisible && (
          <HorizontalLineIconSVG className="horizontal-line-icon" />
        )}

        {withCurrency && (
          <div className="selected-currency">{selectedCurrency.value}</div>
        )}
      </div>

      <SideBar
        className="header-localization-sidebar"
        open={isOpen}
        placement="right"
        handler={false}
        level={null}
        onClose={toggleSidebar}
        withCloseButton={isTabletDown}
        withBackButton={showBackButton}
        onBack={() => toggleNavigationSidebar()}
        title={isTabletDown && <FormattedMessage id="LanguageAndCurrency" />}
      >
        <div className="sidebar-localization__content">
          <div className="header-sidebar-main">
            <div className="localization-container">
              {withLocale && (
                <LocalizationList
                  className="locale-section"
                  title={<FormattedMessage id="Language" />}
                  data={LOCALE_LIST}
                  selectedItem={selectedLocale}
                  handleItemClick={handleLocaleItemClick}
                />
              )}

              {withCurrency && (
                <LocalizationList
                  className="currency-section"
                  title={<FormattedMessage id="Currency" />}
                  data={CURRENCY_LIST}
                  selectedItem={selectedCurrency}
                  handleItemClick={handleCurrencyItemClick}
                />
              )}
            </div>
          </div>

          {isTabletDown && (
            <div className="header-sidebar-footer">
              <Button
                shape="square"
                fill
                inversion
                variant="outlined"
                color="tertiary"
                onClick={handleApply}
              >
                <FormattedMessage id="Apply" />
              </Button>
            </div>
          )}
        </div>
      </SideBar>
    </>
  );
};

export default memo(SideBarLocalization);
