import { memo } from 'react';
import { useIntl } from 'react-intl';

import { useFormContext } from 'react-hook-form';

import { ACCOUNT_GROUP } from '@shared/constants';
import { Button, InputController } from '@shared/ui';

import {
  isAmountAvailable,
  isMaxWithdrawAmountForUser,
  isMinWithdrawAmountForCoin,
} from '@pages/WalletsPage';
import { useUserWithdrawLimit } from '@pages/WalletsPage/hooks';

import { useAccountBalances, useCoinsInfo } from '@store/api/hooks';

import { format } from '@utils/numbers';

const AmountInput = ({ selectedCurrencySymbol }) => {
  const intl = useIntl();

  const methods = useFormContext();

  const { setValue } = methods;

  const { coinInfo: currentCoinInfo } = useCoinsInfo({
    coinSymbol: selectedCurrencySymbol,
  });
  const { coinBalance } = useAccountBalances({
    coinSymbol: selectedCurrencySymbol,
    accountId: ACCOUNT_GROUP.MAIN,
  });

  const availableCoinBalance = Number.parseFloat(coinBalance?.available) || 0;

  const { userWithdrawLimit } = useUserWithdrawLimit(selectedCurrencySymbol);

  const handleMaxAmountClick = () => {
    let maxAmount = availableCoinBalance;

    if (availableCoinBalance >= userWithdrawLimit) {
      maxAmount = userWithdrawLimit;
    }

    setValue('amount', maxAmount, {
      shouldValidate: true,
    });
  };

  const precision = currentCoinInfo?.digits;

  const placeholder = precision ? format(0, { precision }) : '0.00';

  return (
    <InputController
      name="amount"
      label={`${intl.formatMessage({ id: 'Amount' })}:`}
      placeholder={`${placeholder}...`}
      onlyNumbers
      precision={precision}
      autoComplete="off"
      rules={{
        required: intl.formatMessage({ id: 'FieldRequired' }),
        validate: {
          isAmountAvailable: isAmountAvailable(
            availableCoinBalance,
            selectedCurrencySymbol,
            intl,
          ),
          isMin: isMinWithdrawAmountForCoin(currentCoinInfo, intl),
          isMax: isMaxWithdrawAmountForUser(
            userWithdrawLimit,
            selectedCurrencySymbol,
            intl,
          ),
        },
      }}
      suffix={
        <Button
          type="button"
          onClick={handleMaxAmountClick}
          variant="outlined"
          color="green"
          size="xxs"
        >
          MAX
        </Button>
      }
      bordered={false}
      underlined
      size="l"
      {...methods}
    />
  );
};

export default memo(AmountInput);
