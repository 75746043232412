import { memo, useRef, useState } from 'react';

import classNames from 'classnames';

import { isTextTruncated } from '@shared/utils';

import Tooltip from '@components/Tooltip';

const TruncatedText = ({ children, className }) => {
  const triggerRef = useRef();

  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(isTextTruncated(triggerRef.current));
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const handleClickOutside = () => {
    setIsVisible(false);
  };

  return (
    <Tooltip
      triggerRef={triggerRef}
      manualTrigger
      visible={isVisible}
      className={classNames('text-truncate', className)}
      onClick={handleMouseEnter}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClickOutside={handleClickOutside}
      content={children}
    >
      {children}
    </Tooltip>
  );
};

export default memo(TruncatedText);
