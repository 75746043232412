import React, { memo, useMemo } from 'react';
import { useState } from 'react';

import classnames from 'classnames';
import dateFormat from 'date-fns/format';

import Button from '@components/Button';

import { useNotificationMutations } from '@store/api/hooks';

import { NOTIFICATION_STATUS } from '@constants';

import { ReactComponent as CrossIconSVG } from '@icons/cross-icon.svg';

import styles from './Notification.module.scss';

const Notification = ({
  item,
  customStyles = {},
  isDateAtBottom,
  isShowDeleteButton,
  isBigIcon = false,
  dateFormatString = 'HH:mm:ss',
}) => {
  const { onDeleteNotification, onChangeNotificationsStatus } =
    useNotificationMutations();

  const [isLoading, setIsLoading] = useState(false);

  const isReadStatus = item.status === NOTIFICATION_STATUS.READ;

  const handleNotificationClick = async (item) => {
    if (item.status !== NOTIFICATION_STATUS.UNREAD || isLoading) {
      return;
    }

    setIsLoading(true);

    await onChangeNotificationsStatus({
      ids: [item.id],
      status: NOTIFICATION_STATUS.READ,
    });

    setIsLoading(false);
  };

  const handleDeleteNotification = () => {
    onDeleteNotification(item.id);
  };

  const renderDate = useMemo(
    () => (
      <span
        className={classnames(
          styles.notification__date,
          customStyles.notification__date,
        )}
      >
        {dateFormat(new Date(item.created_at), dateFormatString)}
      </span>
    ),
    [item.created_at],
  );

  const crossIconSize = isBigIcon ? 14 : 12;

  const deleteButton = (
    <Button
      className={classnames(
        styles.notification__close_btn,
        customStyles.notification__close_btn,
      )}
      onClick={handleDeleteNotification}
    >
      <CrossIconSVG width={crossIconSize} height={crossIconSize} />
    </Button>
  );

  return (
    <div
      key={item.id}
      className={classnames(
        'd-flex flex-column',
        styles.notification,
        customStyles.notification,
        {
          [styles['notification--unread']]: !isReadStatus,
          [customStyles['notification--unread']]: !isReadStatus,
        },
      )}
      onClick={() => handleNotificationClick(item)}
    >
      <div
        className={classnames(
          'd-flex align-items-baseline',
          styles.notification__container,
          customStyles.notification__container,
        )}
      >
        <div className="flex-1 pe-8">
          <span
            className={classnames(
              'fw-700',
              styles.notification__title,
              customStyles.notification__title,
            )}
          >
            {item.title}.
          </span>{' '}
          <span>{item.message}</span>
        </div>

        <div
          className={classnames('d-flex', {
            'flex-column': !isDateAtBottom,
            'align-items-center': isDateAtBottom,
            'align-items-end': !isDateAtBottom,
          })}
        >
          <div className="d-flex align-items-center">
            {!isReadStatus && (
              <div
                className={classnames(
                  styles.notification__is_new_badge,
                  customStyles.notification__is_new_badge,
                  {
                    'me-4': !isDateAtBottom && !isShowDeleteButton,
                  },
                )}
              />
            )}

            {!isDateAtBottom && renderDate}

            {isShowDeleteButton && !isReadStatus && (
              <div className={styles.delete_button}>{deleteButton}</div>
            )}
          </div>

          {isShowDeleteButton && isReadStatus && deleteButton}
        </div>
      </div>

      {isDateAtBottom && renderDate}
    </div>
  );
};

export default memo(Notification);
