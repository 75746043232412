import dateFormat from 'date-fns/format';
import secondsToMilliseconds from 'date-fns/secondsToMilliseconds';

import { YEAR_MONTH_DAY_TIME_FORMAT } from '@shared/constants';
import { capitalizeFirstLetter } from '@shared/utils';

import { ORDER_TYPE, PERCENT_PRECISION, SUBORDER_TYPE } from '@constants';

import { format } from './numbers';

export const isTrailingStopOrderActivated = ({ stop_price }) => {
  return Boolean(Number(stop_price));
};

export const isTrailingStopOrderActivationImmediately = ({
  ts_activation_price,
}) => {
  return !Boolean(Number(ts_activation_price));
};

export const getOrderId = (order) => {
  let orderId = order.id;

  if (
    order.type === ORDER_TYPE.OTO ||
    order.type === ORDER_TYPE.TRAILING_STOP
  ) {
    orderId = order.init_order_id ?? order.id;
  } else if (
    order.type === ORDER_TYPE.STRANGLE ||
    order.type === SUBORDER_TYPE.OTO.TAKE_PROFIT
  ) {
    orderId = order.tp_order_id ?? order.id;
  } else if (
    order.type === ORDER_TYPE.STRADDLE ||
    order.type === SUBORDER_TYPE.OTO.STOP_LOSS
  ) {
    orderId = order.sl_order_id ?? order.id;
  }

  return orderId;
};

export const getOrderType = ({ type, stop, oto_type }) => {
  if (type === ORDER_TYPE.STRANGLE) {
    return 'limit_oco';
  }

  if (type === ORDER_TYPE.STRADDLE) {
    return 'stop_oco';
  }

  if (type === ORDER_TYPE.TRAILING_STOP) {
    return ORDER_TYPE.TRAILING_STOP;
  }

  if (stop !== 'none') {
    if (type === ORDER_TYPE.OTO) {
      return `stop_${type}`;
    }

    return 'stop';
  }

  if (type === ORDER_TYPE.OTO) {
    return `${oto_type}_${type}`;
  }

  return type;
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'untouched':
    case 'pending':
      return 'yellow';
    case 'filled':
      return 'green';
    case 'cancelled':
      return 'red';
    case 'partially_filled':
      return 'orange';
    default:
      return '';
  }
};

export const localizeStatusName = (status, intl) => {
  switch (status) {
    case 'untouched':
      return intl.formatMessage({ id: 'Untouched' });
    case 'pending':
      return intl.formatMessage({ id: 'Pending' });
    case 'filled':
      return intl.formatMessage({ id: 'Filled' });
    case 'cancelled':
      return intl.formatMessage({ id: 'Cancelled' });
    case 'partially_filled':
      return intl.formatMessage({ id: 'Partial' });
    default:
      return '';
  }
};

export const getPrice = ({
  type,
  stop,
  price,
  stop_price,
  tp_price,
  sl_price,
}) => {
  if (type === ORDER_TYPE.STRANGLE) {
    return tp_price;
  }

  if (type === ORDER_TYPE.STRADDLE) {
    return sl_price;
  }

  if (type === ORDER_TYPE.TRAILING_STOP) {
    return stop_price;
  }

  if (stop !== 'none') {
    return stop_price;
  }

  return price;
};

export const getFilledAmount = ({
  type,
  filled_amount,
  tp_filled_amount,
  sl_filled_amount,
}) => {
  if (type === ORDER_TYPE.STRANGLE) {
    return tp_filled_amount;
  }

  if (type === ORDER_TYPE.STRADDLE) {
    return sl_filled_amount;
  }

  return filled_amount;
};

export const getFilledPercent = (order) => {
  const filledAmount = Number(getFilledAmount(order));
  const amount = Number(getAmount(order));

  if (filledAmount && amount) {
    return (filledAmount / amount) * 100;
  }

  return 0;
};

const getFilledPercentWithValue = (filledAmount, amount) => {
  if (filledAmount && amount) {
    return (filledAmount / amount) * 100;
  }

  return 0;
};

export const getAmount = ({ type, amount, tp_amount, sl_amount }) => {
  let showedAmount = amount;

  if (type === ORDER_TYPE.STRANGLE) {
    showedAmount = tp_amount;
  }

  if (type === ORDER_TYPE.STRADDLE) {
    showedAmount = sl_amount;
  }

  return showedAmount;
};

export const getSide = ({ type, side }) => {
  if (type === ORDER_TYPE.STRANGLE) {
    return 'sell';
  }

  if (type === ORDER_TYPE.STRADDLE) {
    return 'buy';
  }

  return side;
};

export const isWithTildeAndAtSymbols = (order, suborder) => {
  if (order.type === ORDER_TYPE.STRADDLE) {
    return true;
  }

  if (order.type === ORDER_TYPE.OTO && suborder) {
    return suborder.type === SUBORDER_TYPE.OTO.STOP_LOSS;
  }

  if (order.stop !== 'none') {
    if (order.type === ORDER_TYPE.OTO) {
      return !suborder;
    }

    return true;
  }

  if (
    order.type === ORDER_TYPE.TRAILING_STOP &&
    isTrailingStopOrderActivated(order)
  ) {
    return true;
  }

  return false;
};

export const getSuborders = (order) => {
  const suborders = [];
  const {
    market_id,
    created_at,
    type,
    sl_order_id,
    sl_price,
    sl_amount,
    sl_filled_amount,
    sl_status,
    tp_order_id,
    tp_price,
    tp_amount,
    tp_filled_amount,
    tp_status,
  } = order;

  if (type === ORDER_TYPE.STRANGLE) {
    const price = Number(sl_price);
    const amount = Number(sl_amount);
    const suborder = {
      id: sl_order_id,
      side: 'buy',
      created_at,
      market_id,
      status: sl_status,
      filledPercent: getFilledPercentWithValue(
        Number(sl_filled_amount),
        amount,
      ),
      amount,
      price,
      total: price * amount,
      type: SUBORDER_TYPE.OCO.BUY_LIMIT,
    };

    suborders.push(suborder);
  } else if (type === ORDER_TYPE.STRADDLE) {
    const price = Number(tp_price);
    const amount = Number(tp_amount);
    const suborder = {
      id: tp_order_id,
      side: 'sell',
      created_at,
      market_id,
      status: tp_status,
      filledPercent: getFilledPercentWithValue(
        Number(tp_filled_amount),
        amount,
      ),
      amount,
      price,
      total: price * amount,
      type: SUBORDER_TYPE.OCO.SELL_STOP,
    };

    suborders.push(suborder);
  } else if (type === ORDER_TYPE.OTO) {
    const isMainOrderBuySide = order.side === 'buy';

    if (Number(sl_price)) {
      const price = Number(sl_price);
      const amount = Number(sl_amount);
      const slOrder = {
        id: sl_order_id,
        side: isMainOrderBuySide ? 'sell' : 'buy',
        created_at,
        market_id,
        status: sl_status,
        filledPercent: getFilledPercentWithValue(
          Number(sl_filled_amount),
          amount,
        ),
        amount,
        price,
        total: price * amount,
        type: SUBORDER_TYPE.OTO.STOP_LOSS,
      };

      suborders.push(slOrder);
    }

    if (Number(tp_price)) {
      const price = Number(tp_price);
      const amount = Number(tp_amount);
      const tpOrder = {
        id: tp_order_id,
        side: isMainOrderBuySide ? 'sell' : 'buy',
        created_at,
        market_id,
        status: tp_status,
        filledPercent: getFilledPercentWithValue(
          Number(tp_filled_amount),
          amount,
        ),
        amount,
        price,
        total: price * amount,
        type: SUBORDER_TYPE.OTO.TAKE_PROFIT,
      };

      suborders.push(tpOrder);
    }
  }
  return suborders;
};

export const getRiskReward = (isBuyTab, orderPrice, belowPrice, abovePrice) => {
  let risk = 0;
  let reward = 0;

  if (orderPrice >= abovePrice || orderPrice <= belowPrice) return 0;

  if (isBuyTab) {
    risk = (belowPrice - orderPrice) / orderPrice;
    reward = (abovePrice - orderPrice) / orderPrice;
  } else {
    risk = (orderPrice - abovePrice) / orderPrice;
    reward = (orderPrice - belowPrice) / orderPrice;
  }
  const ratio = reward / Math.abs(risk);

  return `1/${format(ratio, { precision: 1 })}`;
};

export const getStatus = ({ type, status, tp_status, sl_status }) => {
  if (type === ORDER_TYPE.STRANGLE) {
    return tp_status ?? status;
  }
  if (type === ORDER_TYPE.STRADDLE) {
    return sl_status ?? status;
  }
  return status;
};

export const createOrdersTable = ({
  generalData: { intl, marketInfo },
  orderData: {
    id,
    side,
    created_at,
    market_id,
    status,
    filledPercent,
    amount,
    price,
    total,
    type,
    isSuborder,
    trailingTooltipData = {},
  },
  uiConfig: {
    withTildeAndAtSymbols,
    isHideSide,
    isHideType,
    stripedRowsState,
    isHideControls,
  },
  originalOrder,
}) => ({
  row_data: {
    className: 'fs-14',
    stripedRowsState,
    isSuborder,
  },
  id,
  side: { side, isHideSide },
  pair: isSuborder ? '' : marketInfo?.name,
  type: { type, isSuborder, isHideType, ...trailingTooltipData },
  amount_info: {
    amount: format(amount, {
      precision: marketInfo?.market_precision_format,
    }),
    coinSymbol: marketInfo?.market_coin_symbol,
  },
  price_info: {
    price: `${withTildeAndAtSymbols ? '@ ' : ''}
        ${format(price, {
          precision: marketInfo?.quote_precision_format,
        })}`,
    coinSymbol: marketInfo?.quote_coin_symbol,
  },
  filled_percent: format(filledPercent, {
    precision: PERCENT_PRECISION,
  }),
  total_amount: {
    numberValue: format(total, {
      precision: marketInfo?.quote_precision_format,
      returnNumber: true,
    }),
    total: `${withTildeAndAtSymbols ? '~ ' : ''}${format(total, {
      precision: marketInfo?.quote_precision_format,
    })}`,
    coinSymbol: marketInfo?.quote_coin_symbol,
  },
  created_at: {
    createdAt: dateFormat(new Date(created_at), YEAR_MONTH_DAY_TIME_FORMAT),
    status: localizeStatusName(status, intl),
    dotColor: getStatusColor(status),
  },
  status: {
    status: localizeStatusName(status, intl),
    dotColor: getStatusColor(status),
  },
  controls: {
    isHideControls,
    marketId: market_id,
    marketName: marketInfo?.name,
    type,
    side,
    id,
    isSuborder,
    amount: format(amount, {
      precision: marketInfo?.market_precision_format,
    }),
    originalOrder,
  },
});

export const createTradesTable = (marketInfo, trade) => ({
  fee_info: {
    fee: format(trade.fee_amount, { removeZeroEnd: true }),
    coinSymbol:
      trade.side === 'buy' // TODO: check trade.side or trade.taker_side
        ? marketInfo?.market_coin_symbol
        : marketInfo?.quote_coin_symbol,
  },
  price_info: {
    price: format(trade.price, {
      precision: marketInfo?.quote_precision_format,
    }),
    coinSymbol: marketInfo?.quote_coin_symbol,
  },
  volume_info: {
    volume: format(trade.volume, {
      precision: marketInfo?.market_precision_format,
    }),
    coinSymbol: marketInfo?.market_coin_symbol,
  },
  total_amount: {
    total: format(trade.quote_volume, {
      precision: marketInfo?.quote_precision_format,
    }),
    coinSymbol: marketInfo?.quote_coin_symbol,
  },
  timestamp: dateFormat(
    new Date(secondsToMilliseconds(trade.timestamp)),
    YEAR_MONTH_DAY_TIME_FORMAT,
  ),
});

export const createTrailingStopTooltipData = (intl, marketInfo, order) => {
  const callbackType = order.ts_price_type;
  const callbackRate =
    callbackType === 'absolute' ? order.ts_price : order.ts_price * 100;
  const isActivated = isTrailingStopOrderActivated(order);
  const isImmediately = isTrailingStopOrderActivationImmediately(order);
  const isWithSymbols = isWithTildeAndAtSymbols(order);

  return {
    price: isActivated
      ? `${isWithSymbols ? '@ ' : ''} ${format(order.stop_price, {
          precision: marketInfo?.quote_precision_format,
        })} ${marketInfo?.quote_coin_symbol.toUpperCase()}`
      : intl.formatMessage({ id: 'NotActivated' }),
    activationPrice: isImmediately
      ? intl.formatMessage({ id: 'Immediately' })
      : `${format(order.ts_activation_price, {
          precision: marketInfo?.quote_precision_format,
        })} ${marketInfo?.quote_coin_symbol.toUpperCase()}`,
    callbackType: intl.formatMessage({
      id: capitalizeFirstLetter(callbackType),
    }),
    callbackRate: `${format(callbackRate, {
      precision:
        callbackType === 'absolute'
          ? marketInfo?.quote_precision_format
          : PERCENT_PRECISION,
    })}${
      callbackType === 'absolute'
        ? ' ' + marketInfo?.quote_coin_symbol.toUpperCase()
        : '%'
    }`,
  };
};

export const getCompletedOrderSide = (order, isTrendBot) => {
  const orderSide = order.side;

  if (isTrendBot && order.client_order_id.includes('hedge.')) {
    if (order.client_order_id.includes('.SL')) return 'stopLoss';
    if (order.client_order_id.includes('.TP')) return 'takeProfit';
  }

  return orderSide;
};
