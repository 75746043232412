import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import Link from '@components/Link';

import styles from './InfoWidget.module.scss';

const InfoWidget = ({
  title,
  value,
  linkId,
  linkTo,
  suffix,
  children,
  tooltip,
  titleClassName,
  contentClassName,
  valueClassName,
  isTruncateValue,
  customStyles = {},
}) => {
  return (
    <div className={cx('info-widget', styles.widget, customStyles.widget)}>
      <div
        className={cx(
          styles.title,
          'info-widget-title',
          titleClassName,
          customStyles.title,
        )}
      >
        {title}
      </div>
      <div className={cx(styles.content, contentClassName)}>
        {children ? (
          children
        ) : (
          <>
            <div
              title={tooltip}
              className={cx(
                styles.value,
                'info-widget-value',
                isTruncateValue && 'text-truncate',
                valueClassName,
                customStyles.value,
              )}
            >
              <span>{value}</span>
            </div>

            <span
              className={cx(
                styles.suffix,
                'info-widget-value',
                customStyles.suffix,
              )}
            >
              {suffix}
            </span>
          </>
        )}
        {linkTo && (
          <Link className={customStyles.link} to={linkTo}>
            <FormattedMessage id={linkId} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default memo(InfoWidget);
