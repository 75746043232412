import cx from 'classnames';

import useTheme from '@hooks/useTheme';

import { format } from '@utils/numbers';

import { PERCENT_PRECISION } from '@constants';

import s from './ProgressBar.module.scss';

const ProgressBar = ({
  percentages,
  percentPrecision = PERCENT_PRECISION,
  isShowOnZeroPercent,
  isShowPercents = true,
  height = 3,
  inversion,
  isPill,
  color,
  customStyles = {},
}) => {
  const { themeStyles } = useTheme();

  return (
    <div className={cx(s.progress__container, customStyles.progress_container)}>
      {percentages > 0 || isShowOnZeroPercent ? (
        <>
          <div
            className={cx(s.progress, customStyles.progress, {
              [s.inversion]: inversion,
            })}
            style={{
              height,
              borderRadius: isPill && themeStyles['border-radius'],
            }}
          >
            <div
              className={cx(s.progress__bar, customStyles.progress_bar)}
              style={{
                width: `${percentages}%`,
                backgroundColor: color,
                borderRadius: isPill && themeStyles['border-radius'],
              }}
            />
          </div>
          {isShowPercents && (
            <span className={customStyles.progress_label}>
              {format(percentages, { precision: percentPrecision })}%
            </span>
          )}
        </>
      ) : (
        '-'
      )}
    </div>
  );
};

export default ProgressBar;
