import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormProvider, useForm } from 'react-hook-form';

import { Card, FORM_ACTION_ORIENTATION } from '@shared/components';
import FormActions from '@shared/components/FormActions/FormActions';

import { useProfileMutations } from '@api/hooks';

import OtpForm from '../OtpForm/OtpForm';

import styles from './DisableGoogleAuth.module.scss';

const DisableGoogleAuth = () => {
  const methods = useForm({ defaultValues: { code: '', loginPassword: '' } });
  const { handleSubmit } = methods;

  const { onDisableOtp, isDisableOtpLoading } = useProfileMutations();

  const handleFormSubmit = (values) => {
    onDisableOtp(values);
  };

  return (
    <div className={styles.disable_google_auth}>
      <Card title={<FormattedMessage id="DisableGoogleAuthentication" />}>
        <div className={styles.description}>
          <FormattedMessage id="DisableGoogleAuthenticationDescription" />
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className={styles.form}>
              <OtpForm />
            </div>

            <FormActions
              submitText={<FormattedMessage id="Disable" />}
              submitButtonProps={{
                isLoading: isDisableOtpLoading,
                disabled: isDisableOtpLoading,
                variant: 'contained',
                color: 'dangerous',
              }}
              cancelButtonProps={{
                variant: 'text',
              }}
              orientation={FORM_ACTION_ORIENTATION.VERTICAL}
            />
          </form>
        </FormProvider>
      </Card>
    </div>
  );
};

export default memo(DisableGoogleAuth);
