import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useNavigateBack } from '@shared/hooks';
import { ButtonArrow } from '@shared/ui';
import { ARROW_DIRECTION } from '@shared/ui/ButtonArrow/ButtonArrow';

import styles from './AccountHeader.module.scss';

const AccountHeader = ({
  pageName = 'Accounts',
  isShowBackArrow = false,
  headerControl,
}) => {
  const navigateBack = useNavigateBack();

  return (
    <div className={styles.header}>
      <div className={styles.caption}>
        {isShowBackArrow && (
          <ButtonArrow
            onClick={navigateBack}
            direction={ARROW_DIRECTION.LEFT}
            color="primary"
            variant="text"
            customStyles={styles}
          />
        )}

        <div className={styles.page_name}>
          <FormattedMessage id={pageName} />
        </div>
      </div>
      <div className={styles.controls_container}>
        {!!headerControl && headerControl}
      </div>
    </div>
  );
};

export default memo(AccountHeader);
