import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import Link from '@components/Link';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as LogoSymbolSVG } from '@images/paramountdax-logo-symbol.svg';
import { ReactComponent as LogoTitleSVG } from '@images/paramountdax-logo.svg';

import { ReactComponent as MasterCardIconSVG } from '@icons/mastercard-icon.svg';
import { ReactComponent as VisaCardIconSVG } from '@icons/visacard-icon.svg';

import {
  ABOUT_LINKS,
  LEGAL_LINKS,
  PRODUCT_LINKS,
  TRADE_LINKS,
  SUPPORT_LINKS,
} from './accountFooter.constants';
import { MAIN_ROUTES } from 'src/routes.constants';

import styles from './AccountFooter.module.scss';

const AccountFooter = ({ customStyles = {} }) => {
  const year = new Date().getFullYear();

  const { isTabletDown } = useBreakpoints();

  return (
    <footer className={classnames(styles.footer, customStyles.footer)}>
      <div className="row gy-20">
        <div className="col-4 col-md-2 col-xl-2">
          <Link
            to={MAIN_ROUTES.HOME}
            className="d-flex align-items-center mt-2 mt-xl-4"
          >
            <LogoSymbolSVG width="40" height="31" className={styles.logo} />

            <LogoTitleSVG className={styles.title} />
          </Link>
        </div>

        <div className="col-4 col-md-2 col-xl-2">
          <h5>
            <FormattedMessage id="Trade" />
          </h5>

          <div className={styles.divider} />

          {TRADE_LINKS.map((el) => (
            <div key={el.id} className={styles.text}>
              <Link to={el.path}>{el.name}</Link>
            </div>
          ))}
        </div>

        <div className="col-4 col-md-2 col-xl-2">
          <h5>
            <FormattedMessage id="Products" />
          </h5>

          <div className={styles.divider} />

          {PRODUCT_LINKS.map((el) => (
            <div key={el.id} className={styles.text}>
              <Link to={el.path}>{el.name}</Link>
            </div>
          ))}
        </div>

        <div className="col-4 col-md-2 col-xl-2">
          <h5>
            <FormattedMessage id="About" />
          </h5>

          <div className={styles.divider} />

          {ABOUT_LINKS.map((el) => (
            <div key={el.id} className={styles.text}>
              <Link to={el.path}>{el.name}</Link>
            </div>
          ))}
        </div>

        <div className="col-4 col-md-2 col-xl-2">
          <h5>
            <FormattedMessage id="Legal" />
          </h5>

          <div className={styles.divider} />

          {LEGAL_LINKS.map((el) => (
            <div key={el.id} className={styles.text}>
              <Link to={el.path}>{el.name}</Link>
            </div>
          ))}
        </div>

        <div className="col-4 col-md-2 col-xl-2">
          <h5>
            <FormattedMessage id="Support" />
          </h5>

          <div className={styles.divider} />

          {SUPPORT_LINKS.map((el) => (
            <div key={el.id} className={styles.text}>
              <Link to={el.path}>{el.name}</Link>
            </div>
          ))}
        </div>
      </div>

      <hr />

      <div className="d-flex align-items-center justify-content-between">
        <div className="col-6">
          <span className={styles.copyright}>
            <FormattedMessage id="Copyright" /> © {year}.{' '}
            <FormattedMessage id="AllRightsReserved" />
          </span>
        </div>

        <div className="col-end-6">
          <span className={styles.powered}>
            <FormattedMessage id="PoweredBy" />:
          </span>

          <VisaCardIconSVG className={isTabletDown ? 'mx-4' : 'mx-16'} />

          <MasterCardIconSVG />
        </div>
      </div>
    </footer>
  );
};

export default memo(AccountFooter);
