import { useStreamTradesQuery } from '@store/api/tradesAPI';

import { TradesApiSelectors } from '../selectors/tradesAPI.selectors';

const useGetStreamTradesQueryResult = (arg, options = {}) => {
  const result = useStreamTradesQuery(arg, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      trades: TradesApiSelectors.tradesSelector(result),
      marketTradesId: TradesApiSelectors.marketTradesIdSelector(result),
    }),
  });

  return result;
};

export default useGetStreamTradesQueryResult;
