import { memo } from 'react';

import ChartMainHeader from '@pages/TerminalPage/components/GridLayout/blocks/Chart/ChartMainHeader';

import useBreakpoints from '@hooks/useBreakpoints';

const TerminalHeader = () => {
  const { isMobile } = useBreakpoints();

  if (!isMobile) return null;

  return <ChartMainHeader />;
};

export default memo(TerminalHeader);
