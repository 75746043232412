import { useId, useMemo, useState } from 'react';

import Table from '@components/gridTables/Table';

import {
  lastLoginsSelector,
  useGetProfileLogsQuery,
} from '@store/api/profileAPI';

import useBreakpoints from '@hooks/useBreakpoints';

import LastLoginsTableColumns from './LastLoginsTableColumns';

const LastLoginsTable = ({ isShowPagination = true, limit = 10 }) => {
  const tableId = useId();

  const { isMobile } = useBreakpoints();

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: limit,
  });

  const { logs, meta, isSuccess } = useGetProfileLogsQuery(
    {
      page: pageIndex + 1,
      limit: pageSize,
    },
    {
      selectFromResult: (result) => ({
        ...result,
        logs: lastLoginsSelector(result),
        meta: result?.data?.meta,
      }),
    },
  );

  const columns = useMemo(() => LastLoginsTableColumns(), []);

  return (
    <Table
      id={tableId}
      columns={columns}
      meta={meta}
      data={logs}
      wideRows
      isSwipeable={isMobile}
      isShowPagination={isShowPagination}
      onPaginationChange={setPagination}
      isLoading={!isSuccess}
      className="account-tables-swipe"
      swipeableTableProps={{
        stickyColumns: 1,
        breakpoints: [[500, 2]],
      }}
    />
  );
};

export default LastLoginsTable;
