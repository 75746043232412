import { createSlice } from '@reduxjs/toolkit';

import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

import { ITEMS_PER_PAGE } from '@constants';

import { ALL_PAIR_FILTER_KEY } from '../tradingBots.constants';

export const ARCHIVED_BOTS_FILTERS_DEFAULT_VALUES = {
  pairFrom: ALL_PAIR_FILTER_KEY,
  pairTo: ALL_PAIR_FILTER_KEY,
  [TABLE_FILTERS_NAMES.SEARCHBAR]: '',
};

const initialState = {
  filters: ARCHIVED_BOTS_FILTERS_DEFAULT_VALUES,
  pagination: {
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  },
};

const archivedBotsSlice = createSlice({
  name: 'archivedBots',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    resetPagination: (state, action) => {
      state.pagination = initialState.pagination;
    },
  },
});

const archivedBotsActions = archivedBotsSlice.actions;
const archivedBotsReducer = archivedBotsSlice.reducer;

export { archivedBotsReducer, archivedBotsActions };

export default archivedBotsSlice;
