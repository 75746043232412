import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { keyBy } from 'lodash';

import {
  isGuestUserSelector,
  userIdSelector,
} from '@store/settings/settingsSelectors';

import { useStreamBalancesQuery } from '@api/balancesAPI';
import { useCoinsInfo } from '@api/hooks';
import { BalancesAPISelectors } from '@api/selectors';

export const accountBalancesSelectFromResult = (
  result,
  {
    accountId,
    coinSymbol,
    searchValue = '',
    isHideZeroTotalBalances = false,
    orderByTotalBalanceDirection = '',
    coinsInfo = {},
    isCoinsInfoSuccess = false,
    isGuest,
    filterByType = '',
  } = {},
) => {
  const dataResult = {
    isSuccessAccountBalances: result.isSuccess && isCoinsInfoSuccess,
  };

  const balances = BalancesAPISelectors.dataSelector(result);
  const accountBalances = BalancesAPISelectors.balancesByAccountIdSelector(
    balances,
    accountId,
  );

  let accountBalancesMap = Object.values(accountBalances ?? {});

  if (isGuest) {
    accountBalancesMap = Object.values(coinsInfo).map((coinInfo) => ({
      coinInfo,
      available: 0,
      in_btc: 0,
      in_orders: 0,
      locked: 0,
      name: coinInfo.name,
      symbol: coinInfo.symbol,
      total_balance: 0,
    }));
  } else {
    // show balance only which has coin info
    // attach coin info to the balance
    // filter by searchValue
    accountBalancesMap = accountBalancesMap.reduce((all, balance) => {
      const balanceCoinInfo = coinsInfo?.[balance.symbol];

      if (balanceCoinInfo) {
        if (
          searchValue &&
          !balanceCoinInfo.symbol.toLowerCase().includes(searchValue) &&
          !balanceCoinInfo.name.toLowerCase().includes(searchValue)
        ) {
          return all;
        }

        return [
          ...all,
          {
            ...balance,
            coinInfo: balanceCoinInfo,
          },
        ];
      }

      return all;
    }, []);
  }

  if (filterByType) {
    accountBalancesMap =
      BalancesAPISelectors.accountBalancesMapFilterByTypeSelector(
        accountBalancesMap,
        filterByType,
      );
  }

  if (isHideZeroTotalBalances) {
    accountBalancesMap =
      BalancesAPISelectors.accountBalancesMapByIsHideZeroTotalBalancesSelector(
        accountBalancesMap,
      );
  }

  if (orderByTotalBalanceDirection) {
    accountBalancesMap =
      BalancesAPISelectors.accountBalancesMapOrderByTotalBalanceDirectionSelector(
        accountBalancesMap,
        orderByTotalBalanceDirection,
      );
  }

  dataResult.coinBalance = coinSymbol
    ? BalancesAPISelectors.coinBalanceFromAccountBalancesMapSelector(
        accountBalancesMap,
        coinSymbol,
      )
    : undefined;

  dataResult.accountBalances = accountBalancesMap.length
    ? keyBy(accountBalancesMap, 'symbol')
    : undefined;
  dataResult.accountBalancesMap = accountBalancesMap;
  return dataResult;
};

const useAccountBalances = (
  {
    accountId,
    coinSymbol,
    searchValue = '',
    isHideZeroTotalBalances = false,
    orderByTotalBalanceDirection = '',
    filterByType = '',
  } = {},
  options = {},
) => {
  const userId = useSelector(userIdSelector);
  const isGuest = useSelector(isGuestUserSelector);

  const { coinsInfo, isCoinsInfoSuccess } = useCoinsInfo();

  const result = useStreamBalancesQuery(userId, {
    ...options,
    skip:
      isGuest || accountId === undefined || accountId === null || options.skip,
  });

  const data = useMemo(() => {
    return accountBalancesSelectFromResult(result, {
      accountId,
      coinSymbol,
      searchValue,
      isHideZeroTotalBalances,
      orderByTotalBalanceDirection,
      coinsInfo,
      isCoinsInfoSuccess,
      isGuest,
      filterByType,
    });
  }, [
    result,
    accountId,
    coinSymbol,
    searchValue,
    isHideZeroTotalBalances,
    orderByTotalBalanceDirection,
    coinsInfo,
    isCoinsInfoSuccess,
    isGuest,
    filterByType,
  ]);

  return data;
};

export default useAccountBalances;
