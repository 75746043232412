import { CIQ } from '@libs/chartiq/Chart';

export default () => {
  CIQ.Account.TFCAccount = function () {
    this.config = {
      showOpenOrdersWhenTFCClosed: true,
    };
    this.balances = {};
    this.positions = {};
    this.trades = {};
    this.openOrders = {};
  };

  CIQ.inheritsFrom(CIQ.Account.TFCAccount, CIQ.Account);

  return CIQ.Account.TFCAccount;
};
