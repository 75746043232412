import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

const isContractExists = (value, filters) => {
  const stringValue = typeof value === 'string' ? value : value.toString();

  return stringValue
    .toLowerCase()
    .includes(filters?.[TABLE_FILTERS_NAMES.SEARCHBAR]?.toLowerCase()?.trim());
};

const isSomeContractExists = (filterParams, filters) => {
  return filterParams.some((argument) =>
    WalletsUtils.isContractExists(argument, filters),
  );
};

export const WalletsUtils = {
  isContractExists,
  isSomeContractExists,
};
