import { createElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { CARD_TYPE_MAP } from '@shared/constants';
import { Button, ProgressBar } from '@shared/ui';
import { VisaCardsUtils } from '@shared/utils';

import { ActivateCardButton } from '@pages/WalletsPage/VisaCardDetails/components';
import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import useBreakpoints from '@hooks/useBreakpoints';
import useTheme from '@hooks/useTheme';

import { format } from '@utils/numbers';

import { FIAT_PRECISION } from '@constants';

import { VISA_ROUTES } from '../../../../../routes.constants';

import s from './VisaCardProgress.module.scss';

const VisaCardProgress = ({ currentAmount, amountLimit }) => {
  const { themeStyles } = useTheme();
  const { isTabletDown } = useBreakpoints();

  const card = useSelector(visaCardSelector);

  const isCardDeactivated = !card.type;

  const nextLevelCardType = useMemo(() => {
    if (isCardDeactivated) {
      return VisaCardsUtils.getCardTypeByLevel(1);
    }

    const currentCardLevel = CARD_TYPE_MAP[card.type].level;

    return VisaCardsUtils.getCardTypeByLevel(currentCardLevel + 1);
  }, [card, isCardDeactivated]);

  const amountPercentage = useMemo(() => {
    return (currentAmount * 100) / amountLimit;
  }, [amountLimit, currentAmount]);

  return (
    <div className="w-100 d-flex align-items-center gap-24">
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between">
          <span className={s.title}>
            <FormattedMessage id="NextCardLevel" />
          </span>
          <Button
            customStyles={s}
            variant="text"
            navigation={{ to: VISA_ROUTES.HOME }}
            size="xs"
          >
            {nextLevelCardType && (
              <FormattedMessage id={CARD_TYPE_MAP[nextLevelCardType].intl} />
            )}
          </Button>
        </div>
        <div className="mt-8">
          <ProgressBar
            percentages={amountPercentage}
            color={themeStyles.dark}
            isShowPercents={false}
            isShowOnZeroPercent
            height={4}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-10">
          <span className={s.title__gray}>
            <FormattedMessage id="DepositProgress" />
          </span>
          <span className={s.amount}>
            {format(currentAmount, { precision: FIAT_PRECISION })} /{' '}
            {format(amountLimit, { precision: FIAT_PRECISION })}
          </span>
        </div>
      </div>
      {nextLevelCardType && !isTabletDown && (
        <div className="d-flex align-items-center gap-36">
          {nextLevelCardType && !isTabletDown && (
            <div>{createElement(CARD_TYPE_MAP[nextLevelCardType].icon)}</div>
          )}
          {isCardDeactivated && <ActivateCardButton />}
        </div>
      )}
    </div>
  );
};

export default VisaCardProgress;
