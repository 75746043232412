import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { AccountModal } from '@shared/components';
import { Button } from '@shared/ui';

import useModal from '@hooks/useModal';

import { modalsIds } from '@constants';

import { ReactComponent as SuccessIconSVG } from '@icons/confirmed-primary-icon.svg';

import { MAIN_ROUTES } from '../../../../routes.constants';

import s from './SuccessJoinWaitListModal.module.scss';

const SuccessJoinWaitListModal = () => {
  const { isOpen, closeModal } = useModal(modalsIds.SUCCESS_JOIN_WAIT_LIST);

  const navigate = useNavigate();

  const handleClick = () => {
    closeModal();
    navigate(generatePath(MAIN_ROUTES.TERMINAL));
  };

  return (
    <AccountModal
      title={
        <div className={s.title}>
          <SuccessIconSVG className={s.icon} />
          <span>
            <FormattedMessage id="Success" />
          </span>
        </div>
      }
      className={s.modal}
      onClose={closeModal}
      isOpen={isOpen}
      footer={
        <Button color="secondary" size="l" onClick={handleClick}>
          <FormattedMessage id="GoToTerminal" />
        </Button>
      }
    >
      <FormattedMessage id="SuccessJoinWaitListModalText" />
    </AccountModal>
  );
};

export default SuccessJoinWaitListModal;
