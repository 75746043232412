import TooltipHelpIcon from '@components/TooltipHelpIcon/TooltipHelpIcon';

export const COIN_TYPE = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
};

export const ACCOUNT_GROUP = {
  MAIN: 'main',
  BONUS: 'bonus',
  CARD_PAYMENT: 'card_payment',
};

export const ACCOUNT_TYPE = {
  SPOT: 'spot',
  MARGIN: 'margin',
};

export const BOT_ACCOUNT = 'Bot Account';
export const BONUS_ACCOUNT = 'Bonus Account';

export const MAIN_ACCOUNT_ID = 0;

export const ACCOUNT_GROUP_COLOR = {
  [ACCOUNT_GROUP.MAIN]: 'green',
  [ACCOUNT_GROUP.BONUS]: 'yellow',
  [ACCOUNT_GROUP.CARD_PAYMENT]: 'yellow',
};

export const ACCOUNT_STATUS = {
  ACTIVE: 'active',
};

export const ACCOUNT_STATUS_COLOR = {
  [ACCOUNT_STATUS.ACTIVE]: 'green',
};

export const WALLET_DEPOSIT_STATUSES = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
  FAILED: 'failed',
  PENDING: 'pending',
};

export const WALLET_DEPOSIT_STATUS_COLOR = {
  [WALLET_DEPOSIT_STATUSES.CONFIRMED]: 'green',
  [WALLET_DEPOSIT_STATUSES.UNCONFIRMED]: 'black',
  [WALLET_DEPOSIT_STATUSES.FAILED]: 'red',
  [WALLET_DEPOSIT_STATUSES.PENDING]: 'yellow',
};

export const WALLET_WITHDRAW_STATUSES = {
  FAILED: 'failed',
  USER_APPROVED: 'user_approved',
  PENDING: 'pending',
  COMPLETED: 'completed',
};

export const WALLET_WITHDRAW_STATUS_COLOR = {
  [WALLET_WITHDRAW_STATUSES.FAILED]: 'red',
  [WALLET_WITHDRAW_STATUSES.USER_APPROVED]: 'black',
  [WALLET_WITHDRAW_STATUSES.COMPLETED]: 'green',
  [WALLET_WITHDRAW_STATUSES.PENDING]: 'yellow',
};

export const PAYMENT_METHOD = {
  ADVCASH: 'advcash',
  BANK_TRANSFER: 'bank_transfer',
};

export const WALLET_FEATURES = {
  DEPOSIT_ALL: 'block_deposit',
  DEPOSIT_CRYPTO: 'deposit_crypto',
  DEPOSIT_FIAT: 'deposit_fiat',
  WITHDRAW_ALL: 'block_withdraw',
  WITHDRAW_CRYPTO: 'withdraw_crypto',
  WITHDRAW_FIAT: 'withdraw_fiat',
};

export const EXTERNAL_SYSTEM = {
  BITGO: 'bitgo',
  ADVCASH: 'advcash',
  CLEAR_JUNCTION: 'clear_junction',
};

export const EXTERNAL_SYSTEM_LABEL = {
  [EXTERNAL_SYSTEM.ADVCASH]: 'Advcash',
  [EXTERNAL_SYSTEM.CLEAR_JUNCTION]: 'SEPA',
};

export const INTERNAL_EMAILS = {
  SUPPORT: 'support@paramountdax.com',
  LEGAL: 'legal@paramountdax.com',
};

export const INTERNAL_COIN_ADDRESSES = {
  XRP: 'r9tzmXjejSv6qsx93QmWKoBQbKEd7oFBWR',
  EOS: 'z1sqhaxmmogr',
};

export const ONE_MINUTE_SECONDS = 60;
export const ONE_HOUR_SECONDS = 60 * 60;
export const ONE_DAY_SECONDS = 60 * 60 * 24;

export const bankTransferDetails = ({
  clearJunctionDepositInformation,
  intl,
}) => {
  if (!clearJunctionDepositInformation) return [];

  const { AccountHolderName, Address, BankName, Iban, Swift } =
    clearJunctionDepositInformation.bankDetails;

  return [
    {
      id: 'beneficiary_name',
      label: `${intl.formatMessage({ id: 'BankDepositReceiverName' })}:`,
      value: AccountHolderName,
      type: 'main',
    },
    {
      id: 'iban',
      label: `${intl.formatMessage({ id: 'BankDepositIBAN' })}:`,
      value: Iban,
      type: 'main',
    },
    {
      id: 'ref_code',
      label: (
        <div className="d-flex gap-6">
          <span>{intl.formatMessage({ id: 'BankDepositReferenceCode' })}:</span>
          <TooltipHelpIcon
            content={intl.formatMessage({
              id: 'BankDepositReferenceCodeTooltip',
            })}
          />
        </div>
      ),
      value: clearJunctionDepositInformation.clientRefCode,
      type: 'main',
      tooltip: 'You must include the Reference Code when making a deposit',
    },
    {
      id: 'bank_name',
      label: `${intl.formatMessage({ id: 'BankDepositBankName' })}:`,
      value: BankName,
      type: 'additional',
    },
    {
      id: 'swift',
      label: `${intl.formatMessage({ id: 'BankDepositSwiftBIC' })}:`,
      value: Swift,
      type: 'additional',
    },
    {
      id: 'bank_address',
      label: `${intl.formatMessage({ id: 'BankDepositBankAddress' })}:`,
      value: Address,
      type: 'additional',
    },
  ];
};
