import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';

import cx from 'classnames';

import { Badge, MenuNavigation, UserAvatar } from '@shared/components';
// eslint-disable-next-line import/order
import { useUserAuthBy, useTotalBalance } from '@shared/hooks';

import { Button } from '@shared/ui';

import SideBar from '@components/SideBar';

import { useMyContractsTable } from '@pages/WalletsPage/InstantBonus/hooks';
import { useMyStakingTable } from '@pages/WalletsPage/Staking/hooks';
import { useMyBotsTableData } from '@pages/WalletsPage/TradingBots/hooks';

import {
  useCoinsInfo,
  useSpotAccounts,
  useGetProfileInfoQueryResult,
  useDefaultAccount,
  useOpenOrders,
} from '@store/api/hooks';
import {
  isAccountThemeSelector,
  userIdSelector,
} from '@store/settings/settingsSelectors';

import { useLogoutMutation } from '@api/authAPI';

import useGridLayout from '@hooks/gridLayout/useGridLayout';
import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal';
import useSidebar from '@hooks/useSidebar';

import { truncateEmail, truncatePhoneNumber } from '@utils';
import { format } from '@utils/numbers';

import { modalsIds, sidebarIds } from '@constants';

import { ReactComponent as PowerIconSVG } from '@icons/power-icon.svg';
import { ReactComponent as ProfileIconSVG } from '@icons/profile-icon.svg';

import {
  ACCOUNT_ROUTES,
  DEPOSIT_ROUTES,
  ORDERS_ROUTES,
  REFERRAL_ROUTES,
  SETTINGS_ROUTES,
  WALLETS_ROUTES,
} from 'src/routes.constants';

import s from './SideBarProfile.module.scss';
import mockData from 'src/mock.json';

const { marginAccountsAmount, visaCardAmount, coinSymbol } =
  mockData.SideBarProfile;

const SideBarProfile = () => {
  const userId = useSelector(userIdSelector);
  const isAccountTheme = useSelector(isAccountThemeSelector);

  const { isMobile, isTablet, isTabletDown } = useBreakpoints();

  const { isLayoutHasChanged, resetLayoutChanges } = useGridLayout();

  const { openModal: openConfirmLeavePageModal } = useModal(
    modalsIds.CONFIRM_LEAVING_PAGE,
  );

  const { isOpen, toggleSidebar, closeSidebar } = useSidebar(
    sidebarIds.PROFILE,
  );

  const { defaultSpotAccount } = useDefaultAccount(undefined, {
    skip: !isOpen,
  });

  const { spotAccountsLength } = useSpotAccounts(undefined, { skip: !isOpen });

  const { totalBalance: totalBalanceBTC } = useTotalBalance(
    {
      coinSymbol,
      accountId: defaultSpotAccount?.id,
    },
    { skip: !isOpen },
  );

  const { coinInfo: coinInfoBTC } = useCoinsInfo(
    {
      coinSymbol,
    },
    { skip: !isOpen },
  );

  const { userNickname, userKycLevel } = useGetProfileInfoQueryResult(
    undefined,
    { skip: !isOpen },
  );

  const {
    meta: { count: openOrdersCount },
  } = useOpenOrders({ accountId: defaultSpotAccount?.id }, { skip: !isOpen });
  const { myBots } = useMyBotsTableData(undefined, { skip: !isOpen });
  const { stakingList } = useMyStakingTable(undefined, { skip: !isOpen });
  const { myContracts } = useMyContractsTable(undefined, { skip: !isOpen });

  const { isAuthByPhone, userAuthByValue } = useUserAuthBy(undefined, {
    skip: !isOpen,
  });

  const [logout] = useLogoutMutation();

  const intl = useIntl();

  const referralProgramLinks = [
    {
      title: <FormattedMessage id="AboutReferralProgram" />,
      link: REFERRAL_ROUTES.ABOUT_REFERRAL_PROGRAM,
    },
    {
      title: <FormattedMessage id="InviteFriends" />,
      link: REFERRAL_ROUTES.INVITE_FRIENDS,
    },
    {
      title: <FormattedMessage id="ReferralHistory" />,
      link: REFERRAL_ROUTES.REFERRAL_HISTORY,
    },
    {
      title: <FormattedMessage id="EarningsHistory" />,
      link: REFERRAL_ROUTES.EARNING_HISTORY,
    },
  ];

  const menuItems = [
    {
      label: intl.formatMessage({ id: 'MyAccount' }),
      links: [
        {
          name: intl.formatMessage({ id: 'Dashboard' }),
          buttonProps: { navigation: { to: ACCOUNT_ROUTES.OVERVIEW } },
        },
        {
          name: intl.formatMessage({ id: 'Profile' }),
          buttonProps: { navigation: { to: ACCOUNT_ROUTES.PROFILE } },
        },
        {
          name: intl.formatMessage({ id: 'ReferralProgram' }),
          buttonProps: { navigation: { to: REFERRAL_ROUTES.OVERVIEW } },
        },
      ],
    },
    {
      label: intl.formatMessage({ id: 'Services' }),
      links: [
        {
          name: intl.formatMessage({ id: 'BuyCrypto' }),
          buttonProps: { navigation: { to: DEPOSIT_ROUTES.CARD } },
        },
      ],
    },
    {
      label: intl.formatMessage({ id: 'Wallets' }),
      extra: (
        <span className={s.text_opacity}>
          ≈ {format(totalBalanceBTC, { precision: coinInfoBTC?.digits })}{' '}
          {coinSymbol}
        </span>
      ),
      links: [
        {
          name: intl.formatMessage({ id: 'Overview' }),
          buttonProps: { navigation: { to: WALLETS_ROUTES.OVERVIEW } },
        },
        {
          name: intl.formatMessage({ id: 'MainAccount' }),
          buttonProps: { navigation: { to: WALLETS_ROUTES.MAIN_ACCOUNT } },
        },
        {
          name: (
            <>
              {intl.formatMessage({ id: 'SpotAccounts' })}
              <span className={cx(s.link_value, s.text_opacity)}>
                {spotAccountsLength}
              </span>
            </>
          ),
          buttonProps: { navigation: { to: WALLETS_ROUTES.SPOT_ACCOUNTS } },
        },
        {
          name: (
            <>
              {intl.formatMessage({ id: 'MarginAccounts' })}
              <span className={cx(s.link_value, s.text_opacity)}>
                {marginAccountsAmount}
              </span>
            </>
          ),
          buttonProps: { navigation: { to: WALLETS_ROUTES.MARGIN_ACCOUNTS } },
        },
        {
          name: (
            <>
              {intl.formatMessage({ id: 'VisaCard' })}
              <span className={cx(s.link_value, s.text_opacity)}>
                {visaCardAmount}
              </span>
            </>
          ),
          buttonProps: { navigation: { to: WALLETS_ROUTES.VISA_CARD } },
          withDivider: true,
        },
        {
          name: (
            <>
              {intl.formatMessage({ id: 'InstantBonus' })}
              <span className={cx(s.link_value, s.text_opacity)}>
                {myContracts.length}
              </span>
            </>
          ),
          buttonProps: { navigation: { to: WALLETS_ROUTES.INSTANT_BONUS } },
        },
        {
          name: (
            <>
              {intl.formatMessage({ id: 'TradingBots' })}
              <span className={cx(s.link_value, s.text_opacity)}>
                {myBots.length}
              </span>
            </>
          ),
          buttonProps: { navigation: { to: WALLETS_ROUTES.TRADING_BOTS } },
        },
        {
          name: intl.formatMessage({ id: 'Launchpad' }),
          buttonProps: { navigation: { to: WALLETS_ROUTES.LAUNCHPAD } },
        },
        {
          name: (
            <>
              {intl.formatMessage({ id: 'Staking' })}
              <span className={cx(s.link_value, s.text_opacity)}>
                {stakingList.length}
              </span>
            </>
          ),
          buttonProps: { navigation: { to: WALLETS_ROUTES.STAKING } },
          withDivider: true,
        },
        {
          name: intl.formatMessage({ id: 'Deposit' }),
          buttonProps: { navigation: { to: WALLETS_ROUTES.DEPOSIT } },
        },
        {
          name: intl.formatMessage({ id: 'Withdraw' }),
          buttonProps: { navigation: { to: WALLETS_ROUTES.WITHDRAW } },
        },
        {
          name: intl.formatMessage({ id: 'TransactionHistory' }),
          buttonProps: {
            navigation: {
              to: generatePath(WALLETS_ROUTES.TRANSACTION_HISTORY),
            },
          },
        },
      ],
    },
    {
      label: intl.formatMessage({ id: 'Orders' }),
      extra: (
        <Badge
          isCircle
          color="dangerous"
          size="xxs"
          variant="contained"
          customStyles={s}
        >
          {format(openOrdersCount, { precision: 0 })}
        </Badge>
      ),
      links: [
        {
          name: (
            <>
              {intl.formatMessage({ id: 'OpenOrders' })}
              <span className={cx(s.link_value, s.text_opacity)}>
                {format(openOrdersCount, { precision: 0 })}
              </span>
            </>
          ),
          buttonProps: { navigation: { to: ORDERS_ROUTES.OPEN_ORDERS } },
        },
        {
          name: intl.formatMessage({ id: 'OrderHistory' }),
          buttonProps: { navigation: { to: ORDERS_ROUTES.ORDER_HISTORY } },
        },
        {
          name: intl.formatMessage({ id: 'TradeHistory' }),
          buttonProps: { navigation: { to: ORDERS_ROUTES.TRADE_HISTORY } },
        },
        {
          name: intl.formatMessage({ id: 'TradeFee' }),
          buttonProps: { navigation: { to: ORDERS_ROUTES.TRADE_FEE } },
        },
      ],
    },
    {
      label: intl.formatMessage({ id: 'Security' }),
      extra: (
        <span className="fs-16 color-green">
          <FormattedMessage id="KYCLevel" values={{ KYCLevel: userKycLevel }} />
        </span>
      ),
      links: [
        {
          name: intl.formatMessage({ id: 'SecuritySettings' }),
          buttonProps: { navigation: { to: ACCOUNT_ROUTES.SECURITY } },
        },
        {
          name: intl.formatMessage({ id: 'Verification' }),
          buttonProps: { navigation: { to: ACCOUNT_ROUTES.VERIFICATION } },
        },
        {
          name: intl.formatMessage({ id: 'APIManagement' }),
          buttonProps: { navigation: { to: ACCOUNT_ROUTES.API_MANAGEMENT } },
        },
        {
          name: intl.formatMessage({ id: 'LastLogins' }),
          buttonProps: { navigation: { to: ACCOUNT_ROUTES.LAST_LOGINS } },
        },
      ],
    },
    {
      label: intl.formatMessage({ id: 'Settings' }),
      collapsible: 'disabled',
      buttonProps: { navigation: { to: SETTINGS_ROUTES.SETTINGS } },
    },
  ];

  const handleLogout = async () => {
    if (isLayoutHasChanged) {
      openConfirmLeavePageModal({
        onLeavePage: async () => {
          resetLayoutChanges();

          await logout().unwrap();

          handleCloseSidebar();
        },
        onSaveChanges: async () => {
          await logout().unwrap();

          handleCloseSidebar();
        },
      });
    } else {
      await logout().unwrap();

      handleCloseSidebar();
    }
  };

  const toggleSidebarProfile = () => {
    toggleSidebar();
  };

  const handleCloseSidebar = () => {
    closeSidebar();
  };

  const userAuthByTruncatedValue = useMemo(
    () =>
      isAuthByPhone
        ? truncatePhoneNumber(userAuthByValue)
        : truncateEmail(userAuthByValue),
    [isAuthByPhone, userAuthByValue],
  );

  const defaultUserNickname = intl.formatMessage(
    { id: 'AnonymousUser' },
    {
      userId,
    },
  );

  return (
    <>
      <div
        className={cx(s.sidebar_profile, { [s.active]: isOpen })}
        onClick={toggleSidebarProfile}
      >
        <ProfileIconSVG className={s.profile_icon} />
      </div>

      <SideBar
        className={s.header_profile_sidebar}
        open={isOpen}
        placement="right"
        handler={false}
        level={null}
        onClose={toggleSidebarProfile}
        withCloseButton={isTablet || isMobile}
      >
        <div className={s.sidebar_profile__content}>
          <div className={s.header_sidebar_main}>
            <div className={s.sidebar_profile__avatar}>
              <UserAvatar />

              <div className="d-flex flex-column">
                <span className="fw-600 fs-24 line-height-29 text-break">
                  {userNickname || defaultUserNickname}
                </span>

                <span className={cx(s.username_truncated, 'text-break')}>
                  {userAuthByTruncatedValue}
                </span>
              </div>
            </div>

            <div className={s.sidebar_profile__menu}>
              <MenuNavigation
                items={menuItems}
                onLinkClick={handleCloseSidebar}
              />

              <div className="mt-40">
                <Button
                  fill
                  color="tertiary"
                  inversion
                  shape="square"
                  size="m"
                  variant="outlined"
                  onClick={handleLogout}
                  customStyles={{ button: [s.logout] }}
                >
                  <PowerIconSVG className={s.power_icon} />

                  <span className="ms-12">
                    <FormattedMessage id="Logout" />
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <div className={s.header_sidebar_footer}>
            <div className={s.title}>
              <FormattedMessage id="ReferralProgram" />
            </div>

            <div className={s.referral}>
              <div className="flex-1">
                {referralProgramLinks
                  .slice(0, 2)
                  .map(({ title, link }, index) => (
                    <Button
                      key={`referral1_${link}_${index}`}
                      variant="text"
                      size={isTabletDown ? 'xs' : 's'}
                      navigation={{ to: link }}
                      inversion={isAccountTheme}
                      customStyles={{ button: [s.link] }}
                      onClick={closeSidebar}
                    >
                      {title}
                    </Button>
                  ))}
              </div>

              <div className="flex-1">
                {referralProgramLinks.slice(2).map(({ title, link }, index) => (
                  <Button
                    key={`referral2_${link}_${index}`}
                    variant="text"
                    size={isTabletDown ? 'xs' : 's'}
                    navigation={{ to: link }}
                    inversion={isAccountTheme}
                    customStyles={{ button: [s.link] }}
                    onClick={closeSidebar}
                  >
                    {title}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SideBar>
    </>
  );
};

export default SideBarProfile;
