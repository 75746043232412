import { useState, useRef, useEffect, memo } from 'react';
import { useIntl } from 'react-intl';

import classnames from 'classnames';
import debounce from 'lodash/debounce';

import { FieldController } from '@shared/components';

import Button from '@components/Button';
import Input from '@components/Input';

import useOnClickOutside from '@hooks/useClickOutside';

import { NOOP } from '@constants';

import { ReactComponent as CrossIconSVG } from '@icons/cross-icon.svg';
import { ReactComponent as SearchIconSVG } from '@icons/search-icon.svg';

import styles from './SearchBar.module.scss';

const SearchBar = ({
  shortView = false,
  searchMode = false,
  isAutoFocus = false,
  isUseOnClickOutside = false,
  searchIcon,
  setSearchMode = NOOP,
  onSearch = NOOP,
  ...props
}) => {
  const inputRef = useRef();
  const searchbarRef = useRef();
  const debouncedSearch = useRef(
    debounce((value) => onSearch(value), 300),
  ).current;

  const intl = useIntl();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (isAutoFocus && searchMode) {
      inputRef.current.focus();
    }
  }, [searchMode]);

  useEffect(() => {
    return () => debouncedSearch.cancel();
  }, [debouncedSearch]);

  const toggleSearchMode = () => setSearchMode((prevState) => !prevState);

  const handleSearchInput = (value) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  const resetSearchBar = () => {
    toggleSearchMode();
    setSearchValue('');
    debouncedSearch('');
  };

  useOnClickOutside(
    [searchbarRef],
    resetSearchBar,
    isUseOnClickOutside ? searchMode : false,
  );

  const SearchIcon = searchIcon ?? (
    <Button
      className={classnames(styles.search_icon, {
        [styles['search_icon--active']]: searchMode,
      })}
      disabled={searchMode}
      onClick={toggleSearchMode}
    >
      <SearchIconSVG width={14} height={14} />
    </Button>
  );

  const CrossIcon = (
    <Button
      className={classnames(styles.cross_icon, {
        [styles['cross_icon--hidden']]: !searchMode,
        [styles['cross_icon--darken']]: searchValue === '',
      })}
      disabled={!searchMode}
      onClick={resetSearchBar}
    >
      <CrossIconSVG width={14} height={14} />
    </Button>
  );

  return (
    <div className={styles.searchbar} ref={searchbarRef}>
      <Input
        className={classnames({
          [styles.hidden]: !searchMode,
          [styles.short]: shortView,
        })}
        inputContainerClassName={styles.input_container}
        disabled={!searchMode}
        value={searchValue}
        placeholder={intl.formatMessage({ id: 'SearchPlaceholder' })}
        onChange={handleSearchInput}
        ref={inputRef}
        prefix={searchMode && shortView ? null : SearchIcon}
        suffix={CrossIcon}
        {...props}
      />
    </div>
  );
};

export const SearchBarController = memo((props) => {
  return <FieldController {...props} component={SearchBar} />;
});

export default memo(SearchBar);
