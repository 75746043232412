import { SEARCH_ITEM_TYPE } from '../../SearchMenu.constant';

import { SearchMenuItemCoin } from './SeachMenuItemCoin';
import { SearchMenuItemMarket } from './SearchMenuItemMarket';
import { SearchMenuItemNews } from './SearchMenuItemNews';

const SEARCH_ITEMS = {
  [SEARCH_ITEM_TYPE.COIN]: SearchMenuItemCoin,
  [SEARCH_ITEM_TYPE.MARKET]: SearchMenuItemMarket,
  [SEARCH_ITEM_TYPE.NEWS]: SearchMenuItemNews,
};

const SearchMenuItem = ({ itemData, hightLightText, onClick }) => {
  const Component = SEARCH_ITEMS[itemData.type];

  return (
    <Component
      hightLightText={hightLightText}
      onClick={onClick}
      itemData={itemData}
    />
  );
};

export default SearchMenuItem;
