import { createSlice } from '@reduxjs/toolkit';

import {
  ORDERS_ITEMS_PER_PAGE,
  TRADE_HISTORY_FILTERS_DEFAULT_VALUES,
} from '@pages/OrderPages/constants';

const initialState = {
  filters: TRADE_HISTORY_FILTERS_DEFAULT_VALUES,
  pagination: {
    pageIndex: 0,
    pageSize: ORDERS_ITEMS_PER_PAGE,
  },
};

const tradeHistorySlice = createSlice({
  name: 'tradeHistory',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    resetPagination: (state, action) => {
      state.pagination = initialState.pagination;
    },
  },
});

const tradeHistoryActions = tradeHistorySlice.actions;
const tradeHistoryReducer = tradeHistorySlice.reducer;

export { tradeHistoryActions, tradeHistoryReducer };

export default tradeHistorySlice;
