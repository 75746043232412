import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  isGuestUserSelector,
  userIdSelector,
} from '@store/settings/settingsSelectors';

import { Avatar } from './Avatar';

import { GUEST_AVATAR_ID, TOTAL_AVATARS } from './userAvatar.constants';

const DefaultAvatar = ({ avatarId, width, height }) => {
  const userId = useSelector(userIdSelector);
  const isGuest = useSelector(isGuestUserSelector);

  const [avatarSrc, setAvatarSrc] = useState();

  useEffect(() => {
    const availableAvatarId = isGuest
      ? GUEST_AVATAR_ID
      : (userId % TOTAL_AVATARS) + 1;

    const currentAvatarId = avatarId || availableAvatarId;

    const fetchUserAvatarSrc = async () => {
      const response = await import(
        `@images/avatars/avatar-${currentAvatarId}.png`
      );

      setAvatarSrc(response.default);
    };

    fetchUserAvatarSrc();
  }, [avatarId, isGuest, userId]);

  return <Avatar avatarSrc={avatarSrc} width={width} height={height} />;
};

export default memo(DefaultAvatar);
