import { FormattedMessage } from 'react-intl';

import { Button } from '@shared/ui';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal';

import { FILE_FORMAT, modalsIds } from '@constants';

import { ReactComponent as CSVIconSVG } from '@icons/excel-icon.svg';
import { ReactComponent as PDFIconSVG } from '@icons/pdf-icon.svg';

import s from './ExportFileButtons.module.scss';

const ExportFileButtons = ({
  loaders,
  exportPdf,
  exportXsl,
  onControlsClick,
}) => {
  const { isMobile } = useBreakpoints();

  const { openModal } = useModal(modalsIds.EXPORT_FILE_MODAL);

  const handleOpenExportFileModal = () => {
    openModal({
      handleExportFile: (fileFormat) => onControlsClick(fileFormat),
      isDisplayedExportPdf: exportPdf,
      isDisplayedExportXsl: exportXsl,
    });
  };

  return (
    <>
      {isMobile ? (
        <div className={s.export__common}>
          <Button
            type="button"
            variant="outlined"
            customStyles={s}
            onClick={handleOpenExportFileModal}
          >
            <FormattedMessage id="Export" />
            ...
          </Button>
        </div>
      ) : (
        <div className={s.controls_export}>
          {exportPdf && (
            <div className={s.export__pdf}>
              <Button
                isLoading={loaders[FILE_FORMAT.PDF]}
                type="button"
                variant="outlined"
                customStyles={s}
                onClick={() => onControlsClick(FILE_FORMAT.PDF)}
              >
                <div className="d-flex align-items-center gap-8">
                  <PDFIconSVG />
                  <FormattedMessage id="Export" />
                </div>
              </Button>
            </div>
          )}
          {exportXsl && (
            <div>
              <div className={s.export__csv}>
                <Button
                  isLoading={loaders[FILE_FORMAT.CSV]}
                  type="button"
                  variant="outlined"
                  customStyles={s}
                  onClick={() => onControlsClick(FILE_FORMAT.CSV)}
                >
                  <div className="d-flex align-items-center gap-8">
                    <CSVIconSVG />
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ExportFileButtons;
