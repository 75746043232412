import { createIntl, createIntlCache } from 'react-intl';

import { flat } from '@utils';

import messages_en from '@translations/en.json';
import messages_it from '@translations/it.json';

import {
  LOCALE_LIST,
  LOCAL_STORAGE_LOCALE,
  SERVER_MESSAGE_TYPE,
} from '@constants';
import { BLOCK_TYPES } from '@constants/blockTypes';

const cache = createIntlCache();

export const intlMessages = {
  en: flat(messages_en),
  it: flat(messages_it),
};

export function translateBlockTitle(intl, type) {
  let title = '';

  switch (type) {
    case BLOCK_TYPES.chart: {
      title = intl.formatMessage({ id: 'Chart' });
      break;
    }

    case BLOCK_TYPES.trading: {
      title = intl.formatMessage({ id: 'Trading' });
      break;
    }

    case BLOCK_TYPES.orderBook: {
      title = intl.formatMessage({ id: 'Book' });
      break;
    }

    case BLOCK_TYPES.markets: {
      title = intl.formatMessage({ id: 'Markets' });
      break;
    }

    case BLOCK_TYPES.openOrders: {
      title = intl.formatMessage({ id: 'Open' });
      break;
    }

    case BLOCK_TYPES.balances: {
      title = intl.formatMessage({ id: 'Balances' });
      break;
    }

    case BLOCK_TYPES.trades: {
      title = intl.formatMessage({ id: 'Trades' });
      break;
    }

    case BLOCK_TYPES.depth: {
      title = intl.formatMessage({ id: 'Depth' });
      break;
    }

    case BLOCK_TYPES.orderHistory: {
      title = intl.formatMessage({ id: 'History' });
      break;
    }

    default:
      break;
  }

  return title;
}

export const isTranslationExists = (intl, id) => !!intl.messages[id];

// use it when you don't need instantly locale updates, e.g. for access outside of the react components
export const getIntlMessage = (messageId, values = {}) => {
  const { value } =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOCALE)) ?? LOCALE_LIST[0];

  const intl = createIntl(
    {
      locale: value,
      messages: intlMessages[value],
    },
    cache,
  );

  return intl.formatMessage({ id: messageId }, values);
};

export const getServerMessageId = (
  originalMessage,
  type = SERVER_MESSAGE_TYPE.MESSAGES,
) => {
  const serverMessages = Object.entries(messages_en[type]);

  const matchedMessage = serverMessages.find(
    ([id, message]) => originalMessage === message,
  );

  if (matchedMessage) {
    return `${type}.${matchedMessage[0]}`;
  }

  const defaultTypeMessage =
    type === SERVER_MESSAGE_TYPE.MESSAGES ? 'Success' : 'SomethingWentWrong';

  return originalMessage || defaultTypeMessage;
};

export const getServerNotification = (intl, message) => {
  const regExp = /⇶.+⇶/g;
  const placeholder = '⇶⇶';
  const originalMessage = message.replaceAll(regExp, placeholder);

  const serverNotifications = Object.entries(messages_en.ServerNotifications);

  const matchedNotification = serverNotifications.find(
    ([id, message]) => message === originalMessage,
  );

  if (matchedNotification) {
    const translatedMessage = intl.formatMessage({
      id: `ServerNotifications.${matchedNotification[0]}`,
    });

    const placeholders =
      message.match(regExp)?.map((s) => s.slice(1, -1)) ?? [];

    const translatedNotification = translatedMessage.replaceAll(
      placeholder,
      placeholders[0],
    );

    return translatedNotification;
  }

  return message;
};
