import { useMemo } from 'react';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

import useTheme from '@hooks/useTheme';

import { SECURITY_LEVELS, SECURITY_LEVELS_PERCENTAGES } from '../constants';

const useSelectResult = ({ securityLevelPercent, isSuccess }) => {
  const { themeStyles } = useTheme();

  const result = useMemo(() => {
    if (securityLevelPercent <= SECURITY_LEVELS_PERCENTAGES.low) {
      return {
        color: themeStyles.red,
        colorName: SECURITY_LEVELS.low,
        isSecurityLevelColorSuccess: isSuccess,
      };
    }

    if (securityLevelPercent <= SECURITY_LEVELS_PERCENTAGES.medium) {
      return {
        color: themeStyles.orange,
        colorName: SECURITY_LEVELS.medium,
        isSecurityLevelColorSuccess: isSuccess,
      };
    }

    return {
      color: themeStyles.green,
      colorName: SECURITY_LEVELS.high,
      isSecurityLevelColorSuccess: isSuccess,
    };
  }, [securityLevelPercent, isSuccess]);

  return result;
};

const useSecurityLevelColor = (args, options) => {
  const { securityLevelPercent, isSuccess } = useGetProfileInfoQueryResult(
    undefined,
    options,
  );

  const result = useSelectResult({
    securityLevelPercent,
    isSuccess,
  });

  return result;
};

export default useSecurityLevelColor;
