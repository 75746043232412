import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { InfoWidget, Stepper } from '@shared/components';

import { KYC_LEVELS } from '@pages/AccountPage/constants';
import { useKYCLevelsStatuses } from '@pages/AccountPage/hooks';
import { USER_ROLES } from '@pages/AuthPages/authConstants';

import {
  useGetProfileInfoQuery,
  userLastLoginSelector,
  userLevelSelector,
} from '@store/api/profileAPI';
import { userRoleSelector } from '@store/settings/settingsSelectors';

import { HeaderTitleInfo } from '../HeaderTitleInfo';

import { ACCOUNT_ROUTES } from '../../../../../routes.constants';

import styles from './ProfileKYCLevelWidget.module.scss';

const ProfileKYCLevelWidget = () => {
  const userRole = useSelector(userRoleSelector);

  const isKYBVerification = userRole === USER_ROLES.BUSINESS;

  // TODO: add real endpoint when KYB will be ready
  const isKYBPassed = false;

  const { userLevel } = useGetProfileInfoQuery(undefined, {
    selectFromResult: (result) => ({
      userLevel: userLevelSelector(result),
      userLastLogin: userLastLoginSelector(result),
    }),
  });

  const { kycLevelsStatuses } = useKYCLevelsStatuses();

  const isZeroUserLevel = userLevel === KYC_LEVELS.ZERO;

  const renderedChildren = useMemo(() => {
    if (!isKYBVerification && !isZeroUserLevel) {
      return <Stepper customStyles={styles} steps={kycLevelsStatuses} />;
    }

    if (isKYBVerification && isKYBPassed) {
      return (
        <div className="d-flex align-items-center gap-10">
          <div className="fs-xl-36 fs-28 fw-600">
            <FormattedMessage id="KYB" />
          </div>
          <div className="fs-11">
            <FormattedMessage id="Verified" />
          </div>
        </div>
      );
    }
    return undefined;
  }, [isKYBVerification, isZeroUserLevel, isKYBPassed, kycLevelsStatuses]);

  return (
    <InfoWidget
      customStyles={styles}
      title={
        <HeaderTitleInfo
          titleId={isKYBVerification ? 'BusinessAccount' : 'ProfileKYCLevel'}
          info={!isKYBVerification ? 'ProfileKYCLevelInfo' : undefined}
        />
      }
      linkId={isKYBVerification ? 'VerifyNeeded' : 'UpgradeLevel'}
      linkTo={
        isKYBVerification && !isKYBPassed
          ? ACCOUNT_ROUTES.VERIFICATION
          : isZeroUserLevel
          ? ACCOUNT_ROUTES.KYC
          : null
      }
      value={
        isKYBVerification ? (
          <FormattedMessage id="KYB" />
        ) : isZeroUserLevel ? (
          <FormattedMessage id="Zero" />
        ) : null
      }
    >
      {renderedChildren}
    </InfoWidget>
  );
};

export default memo(ProfileKYCLevelWidget);
