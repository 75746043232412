import React, { memo, useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import { HexColorPicker } from 'react-colorful';

import Button from '@components/Button';

import useClickOutside from '@hooks/useClickOutside';

import styles from './ChartColorPicker.module.scss';

const ChartColorPicker = ({ color, onChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();

  useClickOutside([pickerRef], () => setShowPicker(false));

  const debouncedColor = useRef(
    debounce((color) => {
      onChange(color);
    }, 100),
  ).current;

  useEffect(() => {
    return () => debouncedColor.cancel();
  }, [debouncedColor]);

  return (
    <div>
      <Button
        onClick={() => setShowPicker((prevState) => !prevState)}
        className={styles.swatch}
      >
        <span style={{ backgroundColor: color }} />
      </Button>

      <div ref={pickerRef} className={styles.picker}>
        {showPicker && (
          <HexColorPicker
            color={color}
            onChange={(color) => debouncedColor(color)}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ChartColorPicker);
