import { forwardRef, useMemo } from 'react';

import { City } from 'country-state-city';

import { FormController } from '@shared/components';
import { Select } from '@shared/ui';
import { Menu } from '@shared/ui/Select/components';

export const getCitiesOptions = ({ countryCode, stateCode }) => {
  return City.getCitiesOfState(countryCode, stateCode).map(
    ({ name, ...rest }) => ({ ...rest, value: name, label: name }),
  );
};

const CitiesSelect = forwardRef(
  ({ countryCode, stateCode, customStyles, ...props }, ref) => {
    const citiesOptions = useMemo(() => {
      return getCitiesOptions({ countryCode, stateCode });
    }, [countryCode, stateCode]);

    const SpecificCustomMenu = ({ children, ...rest }) => {
      return (
        <Menu customStyles={customStyles} {...rest}>
          {children}
        </Menu>
      );
    };

    return (
      <Select
        ref={ref}
        options={citiesOptions}
        specificComponents={{ Menu: SpecificCustomMenu }}
        {...props}
      />
    );
  },
);

export default CitiesSelect;

export const CitiesSelectController = (props) => {
  return <FormController component={CitiesSelect} {...props} />;
};
