import { memo, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';

import Breakpoint from '@components/Breakpoint';
import Button from '@components/Button';
import {
  ChartMenuPeriodicity,
  ChartSelect,
  ChartShortcuts,
  ChartStudies,
  ChartStyles,
  ChartTableView,
} from '@components/CustomChart/components';

import useClickOutside from '@hooks/useClickOutside';

import { getAsset } from '@utils';

import { CHART_STYLES_LIST } from '@constants';

import { ReactComponent as CrosshairIconSVG } from '@images/chartiq/crosshair-icon.svg';
import { ReactComponent as DrawIconSVG } from '@images/chartiq/draw-icon.svg';
import { ReactComponent as ListIconSVG } from '@images/chartiq/list-icon.svg';
import { ReactComponent as SaveIconSVG } from '@images/chartiq/save-icon.svg';
import { ReactComponent as ShortcutsIconSVG } from '@images/chartiq/shortcuts-icon.svg';

import { ReactComponent as InfoOutlineIconSVG } from '@icons/info-outline-icon.svg';
import { ReactComponent as ThreeHorizontalDotsIconSVG } from '@icons/three-horizontal-dots.svg';

import styles from './HeaderControls.module.scss';
import chartSelectStyles from '../ChartSelect/ChartSelect.module.scss';

const HeaderControls = ({ chartHeight, stx, config }) => {
  const chartTableViewRef = useRef();

  const [chartType, setChartType] = useState();
  const [showChartTableView, setShowChartTableView] = useState(false);

  const handleToggleChartTableView = () => {
    setShowChartTableView((prevState) => !prevState);
  };

  const isActiveChartStyle = (style) => chartType === style.value;

  const handleSelectChartType = (chartType) => {
    setChartType(chartType.value);
  };

  useHotkeys('alt+k', handleToggleChartTableView);

  useClickOutside([chartTableViewRef], () => setShowChartTableView(false));

  useEffect(() => {
    // Runs only at the beginning
    if (!stx?.layout.chartType || !!chartType) return;

    // if aggregationType === 'ohlc' is standard chart type else is advanced
    const currentChartStyle =
      stx?.layout.aggregationType === 'ohlc'
        ? stx?.layout.chartType
        : stx?.layout.aggregationType;

    setChartType(currentChartStyle);
  }, [stx?.layout.chartType]);

  return (
    <div className={styles.header_controls}>
      <div className={styles.item}>
        <ChartMenuPeriodicity
          stx={stx}
          config={config}
          chartHeight={chartHeight}
        />
      </div>

      <div className={styles.item}>
        <ChartSelect
          dropdownHeight={chartHeight}
          title={getAsset(CHART_STYLES_LIST, chartType)}
        >
          <ChartStyles
            isActiveStyle={isActiveChartStyle}
            handleSelectChartType={handleSelectChartType}
            config={config}
          />
        </ChartSelect>
      </div>

      <div className={styles.item}>
        <div className={styles.item__control_icon}>
          <cq-toggle class="ciq-draw" cq-member="drawing">
            <DrawIconSVG />
            <cq-tooltip class="top">Tools</cq-tooltip>
          </cq-toggle>
        </div>

        <div className={styles.item__control_icon}>
          <cq-toggle
            class="ciq-CH"
            cq-member="crosshair"
            cq-toggles="show,null"
          >
            <CrosshairIconSVG />
            <cq-tooltip class="top">Crosshair (Alt + \)</cq-tooltip>
          </cq-toggle>
        </div>

        <Breakpoint size="md" direction="up">
          <div className={styles.item__control_icon}>
            <cq-toggle
              class="ciq-HU"
              cq-member="headsUp"
              cq-toggles="static,null"
            >
              <InfoOutlineIconSVG />
              <cq-tooltip class="top">Info</cq-tooltip>
            </cq-toggle>
          </div>

          <div className={styles.item__control_icon}>
            <ChartSelect
              dropdownHeight={chartHeight}
              hideChevron
              title={<ShortcutsIconSVG />}
            >
              <ChartShortcuts config={config} />
            </ChartSelect>
          </div>

          <div ref={chartTableViewRef} className={styles.item__control_icon}>
            <Button
              className={classnames(chartSelectStyles.select_button, {
                [chartSelectStyles['select_button--opened']]:
                  showChartTableView,
              })}
              onClick={handleToggleChartTableView}
            >
              <ListIconSVG />
            </Button>

            {showChartTableView && (
              <ChartTableView
                chartHeight={chartHeight}
                closeTable={() => setShowChartTableView(false)}
                stx={stx}
                initialSymbol={config.initialSymbol}
              />
            )}
          </div>
        </Breakpoint>
      </div>

      <Breakpoint size="md" direction="up">
        <div className={styles.item}>
          <cq-menu class="ciq-menu ciq-views collapse">
            <SaveIconSVG />
            <cq-menu-dropdown>
              <cq-views></cq-views>
            </cq-menu-dropdown>
          </cq-menu>
        </div>

        <div className={styles.item}>
          <ChartSelect
            dropdownHeight={chartHeight}
            title={<FormattedMessage id="Studies" />}
          >
            <ChartStudies stx={stx} />
          </ChartSelect>
        </div>

        <div className={styles.item}>
          <Button className="v2-dropdown-menu__dots-button px-6">
            <ThreeHorizontalDotsIconSVG />
          </Button>
        </div>
      </Breakpoint>
    </div>
  );
};

export default memo(HeaderControls);
