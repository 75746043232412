import { useRef, useEffect, useState } from 'react';

const useScrollDirection = (enabled = true, scrollStepPx = 50) => {
  const prevScrollPosition = useRef();
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  const scrollableLayout = document.querySelector('.scrollable-layout');

  const handleScroll = () => {
    const isScrollingUp =
      scrollableLayout.scrollTop < prevScrollPosition.current;
    const isScrollingDown =
      scrollableLayout.scrollTop > prevScrollPosition.current;

    const differencePx = isScrollingUp
      ? prevScrollPosition.current - scrollableLayout.scrollTop
      : scrollableLayout.scrollTop - prevScrollPosition.current;

    if (differencePx < scrollStepPx) return;

    setIsScrollingUp(isScrollingUp);
    setIsScrollingDown(isScrollingDown);

    prevScrollPosition.current = scrollableLayout.scrollTop;
  };

  useEffect(() => {
    if (enabled) {
      scrollableLayout.addEventListener('scroll', handleScroll);
    }

    return () => {
      scrollableLayout.removeEventListener('scroll', handleScroll);
    };
  }, [enabled]);

  return { isScrollingUp, isScrollingDown };
};

export default useScrollDirection;
