import { WalletsApiSelectors } from '../selectors';
import { useGetDepositHistoryQuery } from '../walletsAPI';

const useGetDepositHistoryQueryResult = (args, options = {}) => {
  const result = useGetDepositHistoryQuery(args, {
    ...options,
    selectFromResult: (result) => ({
      ...result,
      depositHistory: WalletsApiSelectors.depositHistorySelector(result),
      meta: WalletsApiSelectors.metaSelector(result),
    }),
  });

  return result;
};

export default useGetDepositHistoryQueryResult;
