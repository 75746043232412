import { memo } from 'react';

import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

import TableSortableHeader from './TableSortableHeader';

const TableHead = ({ header, sortingState, style, className }) => {
  const isCanSort = header.column.getCanSort();
  const headerAlignClass = header.column.columnDef.headerAlign ?? 'end';

  return (
    <th
      style={style}
      className={classNames(
        {
          [`table-default__cell--${headerAlignClass}-align`]: !isCanSort,
        },
        className,
        header.id,
      )}
    >
      {header.isPlaceholder ? null : isCanSort ? (
        <TableSortableHeader
          header={header}
          sortingState={sortingState}
          headerAlignClass={headerAlignClass}
        />
      ) : (
        flexRender(header.column.columnDef.header, header.getContext())
      )}
    </th>
  );
};

export default memo(TableHead);
