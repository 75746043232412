import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { format } from 'date-fns';

import {
  ColumnHeaderItem,
  OrdersDate,
  OrdersPair,
  OrdersSide,
  PersonalFormattedCurrency,
} from '@shared/components';
import { YEAR_MONTH_DAY_TIME_FORMAT } from '@shared/constants';

const { accessor } = createColumnHelper();

const tokenGrowthTableColumns = ({ isMobile }) => [
  accessor('side', {
    header: () => <ColumnHeaderItem id="Side" />,
    cell: (info) => {
      return <OrdersSide side={info.getValue()} />;
    },
    maxSize: 20,
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
    enableTruncating: true,
  }),
  accessor('pair', {
    header: () => <ColumnHeaderItem id="Pair" />,
    cell: (info) => {
      return <OrdersPair pair={info.getValue()} />;
    },

    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
    enableTruncating: true,
  }),
  accessor('amount', {
    header: () => <ColumnHeaderItem id="Amount" />,
    cell: (info) => {
      const { token, value } = info.getValue();
      const isNegative = value < 0;

      return (
        <>
          <span
            className={classNames('fw-600 fs-14 text-uppercase', {
              'color-red': isNegative,
            })}
          >
            <PersonalFormattedCurrency value={value} currency={token} />
          </span>{' '}
          <span className={classNames('fw-400 fs-12 text-uppercase')}>
            {token}
          </span>
        </>
      );
    },
    maxSize: 30,
    cellAlign: 'end',
    headerAlign: 'end',
    enableSorting: false,
    enableTruncating: false,
  }),
  ...(isMobile
    ? []
    : [
        accessor('date', {
          header: () => <ColumnHeaderItem id="Date" />,
          cell: (info) => {
            const value = info.getValue();

            return (
              <OrdersDate
                date={format(new Date(value), YEAR_MONTH_DAY_TIME_FORMAT)}
              />
            );
          },
          maxSize: 50,
          cellAlign: 'end',
          headerAlign: 'end',
          enableSorting: false,
          enableTruncating: false,
        }),
      ]),
];

export default tokenGrowthTableColumns;
