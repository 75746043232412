import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames';
import { compact, isEmpty, range } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  AccountModal,
  FileUploaderController,
  DefaultAvatar,
} from '@shared/components';
import { MAX_RESOLUTION_ERROR } from '@shared/constants';
import { Button } from '@shared/ui';
import { fetchImageAsFile } from '@shared/utils';

import { useProfileMutations } from '@store/api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';
import useModal from '@hooks/useModal/useModal';

import { modalsIds } from '@constants';

import styles from './UpdateAvatarModal.module.scss';

const AVAILABLE_FORMATS = ['.jpg', '.png', '.gif'];

const MAX_PX_SIZE = 1024;

const AVATARS_IDS = range(1, 11);

const MAX_SIZE_MB = 5;

const UpdateAvatarModal = () => {
  const { isOpen, closeModal } = useModal(modalsIds.UPDATE_AVATAR_MODAL);

  const { onChangeAvatar, isChangeAvatarLoading } = useProfileMutations();

  const intl = useIntl();

  const { isMobile } = useBreakpoints();

  const { control, setValue, handleSubmit, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      avatarFile: null,
      defaultAvatarId: null,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const avatarFileWatch = compact(
    Array.from(useWatch({ control, name: 'avatarFile' }) ?? []),
  );

  const selectedDefaultAvatarIdWatch = useWatch({
    control,
    name: 'defaultAvatarId',
  });

  const isImageUploaded = !isEmpty(avatarFileWatch);

  const handleRemoveFile = (file) => {
    setValue('avatarFile', '');
  };

  const handleAfterClose = () => {
    reset();
  };

  const updateAccountAvatar = async (avatarFile, defaultAvatarId) => {
    setIsLoading(true);

    try {
      const avatar = !!avatarFile
        ? avatarFile[0]
        : await fetchImageAsFile(defaultAvatarId, toast);

      onChangeAvatar({ avatar });

      closeModal();
    } catch (error) {
      if (error === MAX_RESOLUTION_ERROR) {
        toast.error(
          intl.formatMessage(
            { id: error },
            {
              width: MAX_PX_SIZE,
              height: MAX_PX_SIZE,
            },
          ),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async ({ avatarFile, defaultAvatarId }) => {
    updateAccountAvatar(avatarFile, defaultAvatarId);

    closeModal();
  };

  const handleErrorFile = (errors) => {
    const { error, ...details } = errors[0];

    toast.error(intl.formatMessage({ id: error }, details));
  };

  const avatarSize = isMobile ? 48 : 64;

  return (
    <AccountModal
      title={<FormattedMessage id="ChangeAvatar" />}
      onClose={closeModal}
      afterClose={handleAfterClose}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
        <div className={styles.sub_title}>
          <FormattedMessage
            id="UploadYourFile"
            values={{ width: MAX_PX_SIZE, height: MAX_PX_SIZE }}
          />{' '}
          ({AVAILABLE_FORMATS.join(', ')})
        </div>

        <div className="mb-26">
          <FileUploaderController
            name="avatarFile"
            acceptTypes={AVAILABLE_FORMATS}
            loadedFiles={avatarFileWatch}
            onError={handleErrorFile}
            onRemoveFile={handleRemoveFile}
            control={control}
            maxSizeMb={MAX_SIZE_MB}
          />
        </div>

        {!isImageUploaded && (
          <>
            <div className={classNames(styles.line, 'mb-18')} />

            <div className="fs-16 mb-28">
              <FormattedMessage id="OrSelectFromReadyToUseAvatar" />
            </div>

            <div className={styles.avatars}>
              {AVATARS_IDS.map((avatarId) => {
                const id = avatarId.toString();

                return (
                  <div
                    onClick={() => setValue('defaultAvatarId', id)}
                    key={id}
                    className={classNames(
                      styles.avatar,
                      id === selectedDefaultAvatarIdWatch && styles.selected,
                    )}
                  >
                    <DefaultAvatar
                      avatarId={id}
                      width={avatarSize}
                      height={avatarSize}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}

        <Button
          fill
          isLoading={isChangeAvatarLoading || isLoading}
          type="submit"
          disabled={!isImageUploaded && !selectedDefaultAvatarIdWatch}
          size="l"
        >
          <FormattedMessage id="Save" />
        </Button>
      </form>
    </AccountModal>
  );
};

export default memo(UpdateAvatarModal);
