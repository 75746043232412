export const SECURITY_LEVELS = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

export const SECURITY_LEVELS_PERCENTAGES = {
  low: 30,
  medium: 70,
  high: 100,
};
