import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { Tag } from '@shared/components';

const CoinNetworks = () => {
  const intl = useIntl();

  const networks = [
    { name: 'erc20', active: true },
    { name: 'trc20', active: false },
    { name: 'sol', active: false },
  ];
  return (
    <div>
      <label>{intl.formatMessage({ id: 'Network' })}:</label>

      <div className="mt-4 d-flex align-items-center gap-10">
        {networks.map((network) => (
          <Tag
            key={network.name}
            title={network.name.toUpperCase()}
            active={network.active}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(CoinNetworks);
