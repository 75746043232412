import React, { memo } from 'react';

import classNames from 'classnames';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as ArrowDotDownThinLongSVG } from '@icons/arrow-dot-down-thin-long.svg';
import { ReactComponent as ArrowDotDownThinShortSVG } from '@icons/arrow-dot-down-thin-short.svg';
import { ReactComponent as PersonIconSVG } from '@icons/person-icon.svg';

import styles from './LevelsStepper.module.scss';

const LevelInfo = ({ isShowArrow = true, width, height }) => {
  const { isTabletDown } = useBreakpoints();

  const arrowClassName = isShowArrow ? undefined : styles.hide_arrow;

  return (
    <div className={styles.level_info}>
      <div className={styles.arrow_down}>
        {isTabletDown ? (
          <ArrowDotDownThinShortSVG className={arrowClassName} />
        ) : (
          <ArrowDotDownThinLongSVG className={arrowClassName} />
        )}
      </div>
      <div className={styles.icon}>
        <PersonIconSVG width={width} height={height} />
      </div>
    </div>
  );
};

export const LevelsGutter = ({ customStyles = {} }) => {
  return (
    <div
      className={classNames(styles.levels_gutter, customStyles.levels_gutter)}
    />
  );
};

const LevelsStepper = ({
  levels,
  customStyles = {},
  className,
  width,
  height,
}) => {
  return (
    <div
      className={classNames(
        styles.levels_stepper,
        customStyles.levels_stepper,
        className,
      )}
    >
      {levels.map((level, index) => (
        <LevelInfo key={index} width={width} height={height} {...level} />
      ))}
    </div>
  );
};

export default memo(LevelsStepper);
