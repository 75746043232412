import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import { AUTHENTICATION_ROLE } from '@pages/AuthPages/authConstants';

import { settingsActions } from '@store/settings/settingsSlice';

import {
  useConfirmPhoneCodeMutation,
  useForgotPasswordMutation,
  useLoginMutation,
  useLoginPhoneMutation,
  usePhoneCodeMutation,
  useRegisterMutation,
  useRegisterPhoneMutation,
  useResetPasswordMutation,
  useSendReset2FACodeMutation,
  useVerifyReset2FACodeMutation,
} from '@api/authAPI';
import { formatObjectKeysFromCamelToSnakeCase } from '@api/utils';
import wsClient from '@api/ws/client';

import { LOCAL_STORAGE_AUTH_TOKEN } from '@constants';

const useAuthMutations = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const [login, { isLoading: isLoginEmailLoading }] = useLoginMutation();
  const [loginPhone, { isLoading: isLoginPhoneLoading }] =
    useLoginPhoneMutation();
  const [register, { isLoading: isRegisterEmailLoading }] =
    useRegisterMutation();
  const [registerPhone, { isLoading: isRegisterPhoneLoading }] =
    useRegisterPhoneMutation();
  const [forgotPassword, { isLoading: isForgotPasswordLoading }] =
    useForgotPasswordMutation();
  const [resetPassword, { isLoading: isResetPasswordLoading }] =
    useResetPasswordMutation();
  const [getReset2FACode, { isLoading: isGetReset2FACodeLoading }] =
    useSendReset2FACodeMutation();
  const [reset2FA, { isLoading: isReset2FALoading }] =
    useVerifyReset2FACodeMutation();
  const [getCode, { isLoading: isGetCodeLoading }] = usePhoneCodeMutation();
  const [confirmPhoneCode, { isLoading: isConfirmPhoneLoading }] =
    useConfirmPhoneCodeMutation();

  const saveUserSettingsInReduxStore = (result) => {
    wsClient.client.setToken(result.token);

    dispatch(settingsActions.setUserId(Number(result.sub)));
    dispatch(settingsActions.setUserEmail(result.email));
    dispatch(settingsActions.setUserRole(result.role));
    dispatch(settingsActions.setIsAuthorizedUser(true));
  };

  const onLoginEmail = async (data, instance) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    if (body.otp_code) {
      const partialToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

      body.token = partialToken;
    }

    try {
      const result = await login(body).unwrap();

      if (result.pending) {
        toast.info(intl.formatMessage({ id: 'CheckEmailActivateAccount' }));
      }

      if (!result.isTokenPartial) {
        saveUserSettingsInReduxStore(result);
      }

      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, result.token);

      return result;
    } catch (error) {
      instance.reset();

      return null;
    }
  };

  const onLoginPhone = async (data, instance) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    if (body.otp_code) {
      const partialToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

      body.token = partialToken;
    }

    try {
      const result = await loginPhone(body).unwrap();

      if (result.pending) {
        toast.info(intl.formatMessage({ id: 'CheckEmailActivateAccount' }));
      }

      if (!result.isTokenPartial) {
        saveUserSettingsInReduxStore(result);
      }

      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, result.token);

      return result;
    } catch (error) {
      instance.reset();

      return null;
    }
  };

  const onRegisterEmail = async (data, isBusinessAccount, instance) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    body.role = isBusinessAccount ? AUTHENTICATION_ROLE.BUSINESS : undefined;

    try {
      const result = await register(body).unwrap();

      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, result.token);
    } catch (error) {
      instance.reset();

      throw new Error(error);
    }
  };

  const onRegisterPhone = async (data, instance) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    try {
      const result = await registerPhone(body).unwrap();

      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, result.token);
    } catch (error) {
      instance.reset();
    }
  };

  const onGetCode = async (phone) => {
    const partialToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    try {
      const result = await getCode({
        token: partialToken,
        phone,
      }).unwrap();

      toast.success(intl.formatMessage({ id: 'EnterReceivedSmsCode' }));

      return result;
    } catch (error) {
      console.log('error', error);
    }
  };

  const onConfirmPhoneCode = async ({ phone, code }) => {
    await confirmPhoneCode({ phone, code }).unwrap();

    toast.success(
      intl.formatMessage({
        id: 'YouSuccessfullyRegisteredAccount',
      }),
    );
  };

  const onForgotPassword = async (data, instance) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    try {
      await forgotPassword(body).unwrap();

      toast.success(
        intl.formatMessage({ id: 'PleaseCheckYourEmailToContinueTheProcess' }),
      );
    } catch (error) {
      instance.reset();

      throw new Error(error);
    }
  };

  const onResetPassword = async (data, instance) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    try {
      await resetPassword(body).unwrap();

      toast.success(intl.formatMessage({ id: 'PasswordResetSuccessfully' }));
    } catch (error) {
      instance.reset();

      throw new Error(error);
    }
  };

  const onGetReset2FACode = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    try {
      await getReset2FACode(body).unwrap();

      toast.success(intl.formatMessage({ id: 'CheckEmailResetTwoFA' }));
    } catch (error) {
      throw new Error(error);
    }
  };

  const onReset2FA = async (data) => {
    const body = formatObjectKeysFromCamelToSnakeCase(data);

    try {
      await reset2FA(body).unwrap();

      toast.success(intl.formatMessage({ id: 'ResetTwoFASuccessfully' }));
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    onLoginEmail,
    isLoginEmailLoading,
    onLoginPhone,
    isLoginPhoneLoading,
    onRegisterEmail,
    isRegisterEmailLoading,
    onRegisterPhone,
    isRegisterPhoneLoading,
    onGetCode,
    isGetCodeLoading,
    onConfirmPhoneCode,
    isConfirmPhoneLoading,
    onForgotPassword,
    isForgotPasswordLoading,
    onResetPassword,
    isResetPasswordLoading,
    onGetReset2FACode,
    isGetReset2FACodeLoading,
    onReset2FA,
    isReset2FALoading,
  };
};

export default useAuthMutations;
