import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import classnames from 'classnames';

import Link from '@components/Link';

import { AUTH_ROUTES } from 'src/routes.constants';

import styles from './LoginOrRegister.module.scss';

const LoginOrRegister = ({ className }) => {
  return (
    <div className={classnames(styles.login_or_register, className)}>
      <Link to={AUTH_ROUTES.LOGIN} className={styles.login_or_register__link}>
        <FormattedMessage id="Login" />
      </Link>

      <FormattedMessage id="OR" />

      <Link
        to={generatePath(AUTH_ROUTES.REGISTER)}
        className={styles.login_or_register__link}
      >
        <FormattedMessage id="RegisterNow" />
      </Link>
    </div>
  );
};

export default memo(LoginOrRegister);
