import React, { memo, useEffect, useState } from 'react';

import cx from 'classnames';

import { useValueColor } from '@shared/hooks';

import useTheme from '@hooks/useTheme';

import { NOOP } from '@constants';

import { PersonalData } from '../PersonalData';

import styles from './PersonalValueChange.module.scss';

const PersonalValueChange = ({
  percentValue,
  color,
  children,
  onColorChange = NOOP,
}) => {
  const { color: currentDefaultColor, isPositive } =
    useValueColor(percentValue);

  const prefix = isPositive ? '+' : '';

  const { themeStyles } = useTheme();

  const isZero = percentValue === 0;

  const [defaultColor, setDefaultColor] = useState();

  useEffect(() => {
    onColorChange(currentDefaultColor);
    setDefaultColor(currentDefaultColor);
  }, [isPositive, isZero, onColorChange, themeStyles]);

  return (
    <div
      style={{ color: color || defaultColor }}
      className={cx('personal-value-change', styles.value_block)}
    >
      <PersonalData data={`${prefix}${percentValue}`}>{children}</PersonalData>
    </div>
  );
};

export default memo(PersonalValueChange);
