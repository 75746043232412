import { memo } from 'react';

import { Card } from '@shared/components';

import { ReactComponent as ThinPlusIconSVG } from '@icons/thin-plus-icon.svg';

import styles from './AddWidgetPlaceholder.module.scss';

const AddWidgetPlaceholder = ({ onClick, customStyles = {} }) => {
  return (
    <Card
      onClick={onClick}
      classNames={[
        'd-flex align-items-center justify-content-center',
        styles.add_widget_placeholder,
        customStyles.add_widget_placeholder,
      ]}
    >
      <ThinPlusIconSVG width={20} height={20} />
    </Card>
  );
};

export default memo(AddWidgetPlaceholder);
