import { useMemo } from 'react';

import {
  marketStatisticsByMarketIdSelector,
  useStreamMarketStatisticsQuery,
} from '@api/marketsAPI';
import { MarketsApiSelectors } from '@api/selectors';

const selectFromResult = (result, { marketId } = {}) => {
  const dataResult = {
    isMarketStatisticSuccess: result.isSuccess,
  };

  dataResult.marketsStatistics = MarketsApiSelectors.dataSelector(result);

  dataResult.marketStatistics = marketId
    ? marketStatisticsByMarketIdSelector(result, marketId)
    : undefined;

  return dataResult;
};

const useMarketsStatistics = ({ marketId } = {}, options) => {
  const result = useStreamMarketStatisticsQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result, { marketId });
  }, [result, marketId]);

  return data;
};

export default useMarketsStatistics;
