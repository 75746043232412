import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useForm, useWatch } from 'react-hook-form';

import { useGetFeesInfoQueryResult } from '@store/api/hooks';
import { marketIdSelector } from '@store/settings/settingsSelectors';

import useDidMountEffect from '@hooks/useDidMountEffect';

import { format } from '@utils/numbers';

import { ORDER_FORM_TYPE } from '@constants';

import FormInput from '../../FormInput';
import FormSlider from '../../FormSlider';
import SubmitFormButton from '../../SubmitFormButton';
import { useBothForms } from '../hooks';

import { getAmountFieldRules } from './MarketForm.validations';

const MarketForm = ({
  buyTab,
  currentMarket,
  currentPrice,
  quoteAvailableBalance,
  marketAvailableBalance,
  layoutItemRef,
}) => {
  const intl = useIntl();

  const { feeInfo } = useGetFeesInfoQueryResult();

  const marketId = useSelector(marketIdSelector);

  const [fee, setFee] = useState(0);

  const {
    control,
    setValue,
    formState: { errors, isSubmitted },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      amount: '',
      price: '',
    },
  });

  const { isShowBothForms } = useBothForms({ layoutItemRef });

  const amountWatch = useWatch({ control, name: 'amount' });

  useEffect(() => {
    reset();
  }, [marketId]);

  useEffect(() => {
    let updatedFee = feeInfo.taker_fee * currentPrice * amountWatch;

    if (isNaN(updatedFee)) {
      updatedFee = 0;
    }

    if (fee !== updatedFee) {
      setFee(updatedFee);
    }
  }, [currentPrice, feeInfo.taker_fee, amountWatch]);

  const handleChangeAmount = (value) => {
    setValue('amount', value, {
      shouldValidate: isSubmitted,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleMinusPlusClickAmount = (name, isMinus = false) => {
    const decimal = currentMarket?.market_precision_format;
    const step = Number(Math.pow(0.1, decimal).toFixed(decimal));

    let value = Number(amountWatch) || 0;

    value = Number(value.toFixed(decimal));
    value = isMinus ? Math.max(0, value - step) : value + step;
    value = value.toFixed(decimal);

    handleChangeAmount(value);
  };

  useDidMountEffect(() => {
    reset();
  }, [isShowBothForms]);

  return (
    <>
      <FormInput
        control={control}
        name="price"
        disabled
        onlyNumbers
        errors={errors}
        label={
          <span>
            <FormattedMessage id="Price" />:
          </span>
        }
        placeholder={intl.formatMessage({ id: 'Market' })}
        isWhitePlaceholder
        appendLabel={currentMarket?.quote_coin_symbol}
        layoutItemRef={layoutItemRef}
      />

      <FormInput
        control={control}
        name="amount"
        rules={getAmountFieldRules(intl)}
        onlyNumbers
        plusminus
        errors={errors}
        label={
          <span>
            <FormattedMessage id="Amount" />:
          </span>
        }
        appendLabel={currentMarket?.market_coin_symbol}
        precision={currentMarket?.market_precision_format}
        placeholder={format(0, {
          precision: currentMarket?.market_precision_format,
        })}
        onMinusPlusClick={handleMinusPlusClickAmount}
        layoutItemRef={layoutItemRef}
      />

      <FormSlider
        layoutItemRef={layoutItemRef}
        isBuyTab={buyTab}
        currentPrice={currentPrice}
        quoteAvailableBalance={quoteAvailableBalance}
        marketAvailableBalance={marketAvailableBalance}
        currentMarket={currentMarket}
        absoluteValue={amountWatch}
        onChange={handleChangeAmount}
      />

      <SubmitFormButton
        tradeType={ORDER_FORM_TYPE.MARKET}
        buyTab={buyTab}
        currentMarket={currentMarket}
        currentPrice={currentPrice}
        fee={fee}
        getValues={getValues}
        handleSubmit={handleSubmit}
        layoutItemRef={layoutItemRef}
      />
    </>
  );
};

export default memo(MarketForm);
