import { forwardRef, useState } from 'react';

import cx from 'classnames';

import { FormController } from '@shared/components';

import s from './Textarea.module.scss';

const Textarea = forwardRef(
  (
    {
      label,
      maxLength,
      // styles
      rounded,
      bordered = true,
      underlined,
      size = 'm', // 's', 'l'
      inversion,
      customStyles = {},
      onChange = (e) => {},
      isInvalid,
      ...props
    },
    ref,
  ) => {
    const [count, setCount] = useState(0);

    const handleChange = (e) => {
      setCount(e.target.value.length);

      onChange(e);
    };

    return (
      <div
        className={cx(s.textarea, customStyles.textarea, s[size], {
          [s.rounded]: rounded,
          [s.bordered]: bordered,
          [s.underlined]: underlined,
          [s.inversion]: inversion,
        })}
      >
        {label && <label>{label}</label>}
        <textarea
          ref={ref}
          className={cx(s.textarea_field, customStyles.textarea_field)}
          maxLength={maxLength}
          onChange={handleChange}
          aria-invalid={isInvalid}
          {...props}
        />
        {maxLength && (
          <div className={s.textarea_counter}>
            {count}/{maxLength}
          </div>
        )}
      </div>
    );
  },
);

export const TextareaController = (props) => {
  return <FormController component={Textarea} {...props} />;
};

export default Textarea;
