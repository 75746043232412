import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { sumBy } from 'lodash';

import { CRYPTO_CURRENCIES } from '@shared/constants';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import useStakingHistoryTable from './useStakingHistoryTable';

const useTotalEarningsWidget = () => {
  const { value: currency } = useSelector(currencySelector);

  const { stakingHistory } = useStakingHistoryTable();

  const totalEarnings = useMemo(
    () => sumBy(stakingHistory, ({ amount }) => Number(amount.amount)),
    [stakingHistory],
  );

  const coinSymbol = stakingHistory[0]?.asset || CRYPTO_CURRENCIES.USDT;

  const { ratesByCoin: coinRateCurrency } = useCoinsRates({
    fromCoinSymbol: coinSymbol,
    toCoinSymbol: currency,
  });

  const totalEarningsInSelectedCurrency =
    totalEarnings * coinRateCurrency?.[currency];

  return {
    currency,
    coinSymbol,
    totalEarnings,
    totalEarningsInSelectedCurrency,
  };
};

export default useTotalEarningsWidget;
