import cx from 'classnames';
import Collapse from 'rc-collapse';

import { collapseMotion } from '@shared/utils';

import { ReactComponent as ChevronIconSVG } from '@icons/down-icon.svg';

import s from './Accordion.module.scss';

import 'rc-collapse/assets/index.css';

/**
 * Example of items:
 *
 * const items = [
 *   { label: 'title 1', children: 'text' },
 *   { label: 'title 2', children: 'text' },
 * ];
 * */

const Accordion = ({
  items,
  inversion,
  customStyles = {},
  withoutBorderTop,
  withoutBorderBottom,
  withExpandIcon = true,
  ...props
}) => {
  return (
    <div
      className={cx(s.accordion, customStyles.accordion, {
        [s.accordion_inversion]: inversion,
        [s.without_border_top]: withoutBorderTop,
        [s.without_border_bottom]: withoutBorderBottom,
      })}
    >
      <Collapse
        accordion={true}
        items={items}
        openMotion={collapseMotion}
        expandIcon={({ isActive, collapsible, ...rest }) => (
          <div className="d-flex align-items-center gap-16">
            {rest?.extra && rest.extra}
            {collapsible !== 'disabled' && (
              <ChevronIconSVG
                className={cx(s.expand_icon, customStyles.expand_icon, {
                  [s.expand_icon__active]: isActive,
                })}
              />
            )}
          </div>
        )}
        {...props}
      />
    </div>
  );
};

export default Accordion;
