import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccountModal } from '@shared/components';
import { Button } from '@shared/ui';

import useModal from '@hooks/useModal/useModal';

import { FILE_FORMAT, NOOP, modalsIds } from '@constants';

import { ReactComponent as ExcelIconSVG } from '@icons/excel-icon.svg';
import { ReactComponent as PDFIconSVG } from '@icons/pdf-icon.svg';

import styles from './ExportFileModal.module.scss';

const ExportFileModal = () => {
  const { modal, isOpen, closeModal, resetModal } = useModal(
    modalsIds.EXPORT_FILE_MODAL,
  );

  const { handleExportFile = NOOP } = modal.params;

  return (
    <AccountModal
      title={
        <span className="fs-28">
          <FormattedMessage id="ExportHistory" />
        </span>
      }
      isOpen={isOpen}
      onClose={closeModal}
      afterClose={resetModal}
      className="modal-sm"
      footer={
        <Button type="button" variant="outlined" fill onClick={closeModal}>
          <FormattedMessage id="Cancel" />
        </Button>
      }
    >
      <div
        className={styles.export_item}
        onClick={() => handleExportFile(FILE_FORMAT.PDF)}
      >
        <PDFIconSVG height={22} width={18} />
        <FormattedMessage id="AdobePdf" />
      </div>

      <div
        className={styles.export_item}
        onClick={() => handleExportFile(FILE_FORMAT.CSV)}
      >
        <ExcelIconSVG height={21} width={22} />
        <FormattedMessage id="MicrosoftExcel" />
      </div>
    </AccountModal>
  );
};

export default memo(ExportFileModal);
