import { createSlice } from '@reduxjs/toolkit';

import {
  TOP_UP_METHODS,
  TRANSFER_RECIPIENTS,
} from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.constants';

export const visaCardDefault = {
  type: undefined,
  number: '',
  expiredDate: '',
  balance: '',
  pinCode: '',
  cvv2Code: '',
  spendingLimit: '',
};

const initialState = {
  visaCard: visaCardDefault,
  availableCards: [],
  topUpMethod: TOP_UP_METHODS.SPOT_ACCOUNT,
  transferRecipient: TRANSFER_RECIPIENTS.SPOT_ACCOUNT,
  spotAccountFormDetails: {
    userAccount: '',
    amount: 0,
    currency: '',
    currentPrice: '',
    commission: {
      value: '',
      formattedValue: '',
    },
    profit: '',
  },
  confirmTransferMoneyDetails: {
    userEmail: '',
    amount: 0,
    message: '',
  },
};

const visaCardDetailsSlice = createSlice({
  name: 'visaCardDetails',
  initialState,
  reducers: {
    setVisaCard: (state, action) => {
      state.visaCard = action.payload;
    },
    setAvailableCards: (state, action) => {
      state.availableCards = action.payload;
    },
    setTopUpMethod: (state, action) => {
      state.topUpMethod = action.payload;
    },
    setTransferRecipient: (state, action) => {
      state.transferRecipient = action.payload;
    },
    setSpotAccountFormDetails: (state, action) => {
      state.spotAccountFormDetails = {
        ...state.spotAccountFormDetails,
        ...action.payload,
      };
    },
    setConfirmTransferMoneyDetails: (state, action) => {
      state.confirmTransferMoneyDetails = {
        ...state.confirmTransferMoneyDetails,
        ...action.payload,
      };
    },
  },
});

const visaCardDetailsActions = visaCardDetailsSlice.actions;
const visaCardDetailsReducer = visaCardDetailsSlice.reducer;

export { visaCardDetailsActions, visaCardDetailsReducer };

export default visaCardDetailsSlice;
