import React, { memo, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { differenceBy } from 'lodash';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { createFilter } from 'react-select';

import {
  ButtonGroup,
  CurrenciesSelect,
  CurrencyItem,
  FormActions,
  FormattedCurrency,
  transformToCurrencyOptions,
} from '@shared/components';
import { CRYPTO_CURRENCIES, FIAT_CURRENCIES } from '@shared/constants';
import { useTransferableAccounts } from '@shared/hooks';
import { Button, InputController, SelectController } from '@shared/ui';

import SideBar from '@components/SideBar';

import { useAccountBalances, useCoinsInfo } from '@store/api/hooks';
import { isAccountThemeSelector } from '@store/settings/settingsSelectors';

import { useAccountsMutations } from '@api/hooks';

import useBreakpoints from '@hooks/useBreakpoints';
import useSidebar from '@hooks/useSidebar';

import { format } from '@utils/numbers';

import { sidebarIds } from '@constants';

import AccountOption from './AccountOption';
import SelectedAccountItem from './SelectedAccountItem';

import styles from './TransferSideBar.module.scss';

const CURRENCIES = [
  CRYPTO_CURRENCIES.BTC,
  CRYPTO_CURRENCIES.USDT,
  CRYPTO_CURRENCIES.ETH,
  CRYPTO_CURRENCIES.XRP,
  FIAT_CURRENCIES.EUR,
];

const TRANSFER_FIELD_NAMES = {
  ACCOUNT_FROM: 'account_from',
  ACCOUNT_TO: 'account_to',
  CURRENCY: 'currency',
  AMOUNT: 'amount',
};

const TransferSideBar = () => {
  const isAccountTheme = useSelector(isAccountThemeSelector);

  const { onTransfer, isTransferLoading } = useAccountsMutations();

  const intl = useIntl();

  const { sidebar, closeSidebar, isOpen } = useSidebar(sidebarIds.TRANSFER);

  const { isMobile } = useBreakpoints();

  const defaultValues = Object.fromEntries(
    Object.entries(TRANSFER_FIELD_NAMES).map(([_, value]) => [value, '']),
  );

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    resetField,
    formState: { isValid },
  } = methods;

  const accountFromWatch = useWatch({
    control,
    name: TRANSFER_FIELD_NAMES.ACCOUNT_FROM,
  });
  const accountToWatch = useWatch({
    control,
    name: TRANSFER_FIELD_NAMES.ACCOUNT_TO,
  });
  const coinSymbolWatch = useWatch({
    control,
    name: TRANSFER_FIELD_NAMES.CURRENCY,
  });

  const { coinsInfoMap } = useCoinsInfo(
    {
      coinSymbol: coinSymbolWatch?.value,
      orderByTypeDirection: 'asc',
    },
    { skip: !isOpen },
  );

  const currenciesMemo = useMemo(
    () =>
      transformToCurrencyOptions({
        currencies: coinsInfoMap,
        inversion: isAccountTheme,
        iconWidth: 28,
        iconHeight: 28,
        iconAccessor: 'icon',
      }),
    [coinsInfoMap, isAccountTheme],
  );

  const { accountBalances } = useAccountBalances(
    {
      accountId: accountFromWatch?.value,
      coinSymbol: coinSymbolWatch?.value,
    },
    { skip: !isOpen },
  );

  const transferableAccounts = useTransferableAccounts(undefined, {
    skip: !isOpen,
  });

  const accountsFrom = useMemo(
    () =>
      differenceBy(
        transferableAccounts,
        [{ value: accountToWatch?.value }],
        'value',
      ),
    [accountToWatch?.value, transferableAccounts],
  );

  const accountsTo = useMemo(
    () =>
      differenceBy(
        transferableAccounts,
        [{ value: accountFromWatch?.value }],
        'value',
      ),
    [accountFromWatch?.value, transferableAccounts],
  );

  const selectedCoinsAvailable =
    accountBalances?.[coinSymbolWatch?.value]?.available;

  // set default currency and account from
  useEffect(() => {
    if (!isOpen) return;

    let defaultCurrency = currenciesMemo[0];
    if (sidebar?.params?.coinSymbol) {
      const foundCurrency = currenciesMemo.find(
        ({ value }) => value === sidebar.params.coinSymbol,
      );
      if (foundCurrency) defaultCurrency = foundCurrency;
    }

    if (!coinSymbolWatch?.value) {
      setValue(TRANSFER_FIELD_NAMES.CURRENCY, defaultCurrency);
    }

    if (sidebar?.params?.accountId !== undefined) {
      const accountFromShouldBeChanged =
        !accountFromWatch ||
        accountFromWatch?.value !== sidebar.params.accountId;

      if (accountFromShouldBeChanged) {
        setValue(
          TRANSFER_FIELD_NAMES.ACCOUNT_FROM,
          accountsFrom.find(({ value }) => value === sidebar.params.accountId),
        );
      }
    }
  }, [
    isOpen,
    sidebar?.params?.accountId,
    sidebar?.params?.coinSymbol,
    accountsFrom,
    currenciesMemo,
  ]);

  useEffect(() => {
    const { amount } = getValues();

    if (amount > 0 && isOpen) {
      setValue(
        TRANSFER_FIELD_NAMES.AMOUNT,
        format(amount, { precision: coinSymbolWatch?.digits, noCommas: true }),
      );
    }
  }, [coinSymbolWatch?.value, coinSymbolWatch?.digits, isOpen]);

  const favoriteCoins = useMemo(
    () =>
      CURRENCIES.map((currency) => ({
        id: currency,
        value: (
          <CurrencyItem
            coinSymbol={currency}
            iconAccessor="icon"
            isShowFullName={false}
            iconHeight={16}
            iconWidth={16}
            customStyles={styles}
          />
        ),
      })),
    [],
  );

  const handleMaxClick = () => {
    setValue(
      TRANSFER_FIELD_NAMES.AMOUNT,
      format(selectedCoinsAvailable, {
        precision: coinSymbolWatch?.digits,
        noCommas: true,
      }),
    );
  };

  const handleSelectCurrency = ({ id }) => {
    setValue(
      TRANSFER_FIELD_NAMES.CURRENCY,
      currenciesMemo.find(({ value }) => value === id),
    );
    resetField(TRANSFER_FIELD_NAMES.AMOUNT);
  };

  const handleClose = () => {
    closeSidebar(sidebar?.params);

    setTimeout(() => {
      if (!isOpen) {
        reset();
      }
    }, 100);
  };

  const onSubmit = async (values) => {
    const transformedValues = {
      accountFrom: values[TRANSFER_FIELD_NAMES.ACCOUNT_FROM].value,
      accountTo: values[TRANSFER_FIELD_NAMES.ACCOUNT_TO].value,
      coinSymbol: values[TRANSFER_FIELD_NAMES.CURRENCY].value,
      amount: values[TRANSFER_FIELD_NAMES.AMOUNT],
    };

    try {
      await onTransfer(transformedValues);

      handleClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  const filterConfig = useMemo(() => {
    return {
      ignoreCase: true,
      trim: true,
      stringify: (opt) => `${opt.value} ${opt.label}`,
    };
  }, []);

  const specificComponents = useMemo(() => {
    return {
      SingleValue: SelectedAccountItem,
      Option: AccountOption,
    };
  }, []);

  const commonFieldProps = {
    underlined: true,
    bordered: false,
    inversion: !!isAccountTheme,
    size: 'l',
    customStyles: styles,
  };

  return (
    <SideBar
      open={isOpen}
      className="transfer-sidebar"
      placement="right"
      handler={false}
      level={null}
      onClose={handleClose}
      forceRender
      withCloseButton
      isManualScrollControl
      title={<FormattedMessage id="Transfer" />}
    >
      <div className={styles.sidebar}>
        <FormProvider {...methods}>
          <form
            className="d-flex h-100 justify-content-between flex-column"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-30">
              <div className="fs-16 mb-32">
                <FormattedMessage id="InternalTransfersAre" />
              </div>

              <SelectController
                name={TRANSFER_FIELD_NAMES.ACCOUNT_FROM}
                options={accountsFrom}
                filterOption={createFilter(filterConfig)}
                placeholder={`${intl.formatMessage({
                  id: 'SelectAccount',
                })}...`}
                specificComponents={specificComponents}
                label={`${intl.formatMessage({ id: 'From' })}:`}
                rules={{
                  required: intl.formatMessage({ id: 'FieldRequired' }),
                }}
                {...commonFieldProps}
                {...methods}
              />

              <div className="mt-20">
                <SelectController
                  name={TRANSFER_FIELD_NAMES.ACCOUNT_TO}
                  options={accountsTo}
                  filterOption={createFilter(filterConfig)}
                  placeholder={`${intl.formatMessage({
                    id: 'SelectAccount',
                  })}...`}
                  specificComponents={specificComponents}
                  label={`${intl.formatMessage({ id: 'To' })}:`}
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                  }}
                  {...commonFieldProps}
                  {...methods}
                />
              </div>

              <div className="mt-30">
                <div className={styles.select_currency}>
                  <CurrenciesSelect
                    name={TRANSFER_FIELD_NAMES.CURRENCY}
                    currencies={currenciesMemo}
                    label={`${intl.formatMessage({ id: 'CoinLabel' })}:`}
                    rules={{
                      required: intl.formatMessage({ id: 'FieldRequired' }),
                    }}
                    {...commonFieldProps}
                    {...methods}
                  />
                </div>

                <div className="mt-4 mt-10">
                  <ButtonGroup
                    items={favoriteCoins}
                    onClick={handleSelectCurrency}
                    selectedItemId={coinSymbolWatch?.value}
                    color="tertiary"
                    variant="outlined"
                    size="xs"
                    inversion
                    customStyles={styles}
                    isScrollable={isMobile}
                  />
                </div>
              </div>

              <div className="mt-30">
                <InputController
                  name={TRANSFER_FIELD_NAMES.AMOUNT}
                  autoComplete="off"
                  placeholder={format(0, {
                    precision: coinSymbolWatch?.digits,
                  })}
                  label={`${intl.formatMessage({ id: 'Amount' })}:`}
                  precision={coinSymbolWatch?.digits}
                  onlyNumbers
                  rules={{
                    required: intl.formatMessage({ id: 'FieldRequired' }),
                    min: {
                      value: Number.MIN_VALUE,
                      message: intl.formatMessage(
                        { id: 'WithdrawAmountValidation' },
                        { amount: 0 },
                      ),
                    },
                    max: {
                      value: selectedCoinsAvailable,
                      message: intl.formatMessage(
                        { id: 'AvailableAmountValidation' },
                        {
                          amount: `${selectedCoinsAvailable} ${coinSymbolWatch?.value?.toUpperCase()}`,
                        },
                      ),
                    },
                  }}
                  suffix={
                    <Button
                      type="button"
                      onClick={handleMaxClick}
                      variant="outlined"
                      color="green"
                      size="xxs"
                    >
                      <FormattedMessage id="MAX" />
                    </Button>
                  }
                  {...commonFieldProps}
                  {...methods}
                />
              </div>

              <div className={styles.available_balance}>
                <span className={styles.title}>
                  <FormattedMessage id="AvailableBalance" />:
                </span>{' '}
                <span className={styles.value}>
                  <FormattedCurrency
                    currency={coinSymbolWatch?.value}
                    value={selectedCoinsAvailable}
                  />

                  {coinSymbolWatch?.value}
                </span>
              </div>
            </div>

            <div className="py-xxl-100 py-40">
              <FormActions
                inversion
                submitText={<FormattedMessage id="Transfer" />}
                submitButtonProps={{
                  isLoading: isTransferLoading,
                  disabled: isTransferLoading || !isValid,
                }}
                onCancel={handleClose}
                customStyles={styles}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </SideBar>
  );
};

export default memo(TransferSideBar);
