import React, { memo } from 'react';

import classNames from 'classnames';

import TruncatedText from '@shared/components/TruncatedText';

import styles from './OrdersPrice.module.scss';

const OrdersPrice = ({ price, coinSymbol, numberValueClassName }) => {
  return (
    <TruncatedText>
      <span
        className={classNames(
          {
            [styles.bold]: Number(price) !== 0,
            [styles.grey]: Number(price) === 0,
          },
          numberValueClassName,
        )}
      >
        {price}
      </span>{' '}
      <span className={styles.thin}>{coinSymbol}</span>
    </TruncatedText>
  );
};

export default memo(OrdersPrice);
