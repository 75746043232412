import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { SwitchController } from '@components/Switch/Switch';

import { ReactComponent as BuyIconSVG } from '@assets/images/icons/arrow-side-green.svg';
import { ReactComponent as SellIconSVG } from '@assets/images/icons/arrow-side-red.svg';

import { BotTrendSideDistance } from './BotTrendSideDistance';

import styles from './BotTrendDistance.module.scss';

const BotTrendDistance = ({ isSellSideEnabled, control, isDisabled }) => {
  return (
    <>
      <div className="mb-20">
        <label>
          <div className={styles.distance_label}>
            <BuyIconSVG width="18" height="18" />
            <FormattedMessage id="BuyDistances" />
          </div>
        </label>
        <BotTrendSideDistance
          entryFieldName="buyEntryDistance"
          takeFieldName="buyTakeProfitDistance"
          saveFieldName="buySaveLossesDistance"
          isDisabled={isDisabled}
        />
      </div>

      <div className="mb-24">
        <div className="w-100">
          <div className={classNames(styles.sell_label)}>
            <div className={styles.distance_label}>
              <SellIconSVG width="18" height="18" />
              <FormattedMessage id="SellDistances" />
            </div>

            <SwitchController
              inversionColors
              customStyles={styles}
              control={control}
              disabled={isDisabled}
              name="isSellSideEnabled"
            />
          </div>
        </div>
        <BotTrendSideDistance
          entryFieldName="sellEntryDistance"
          takeFieldName="sellTakeProfitDistance"
          saveFieldName="sellSaveLossesDistance"
          isDisabled={!isSellSideEnabled || isDisabled}
        />
      </div>
    </>
  );
};

export default memo(BotTrendDistance);
