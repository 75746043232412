import { memo } from 'react';

import cx from 'classnames';
import { noop } from 'lodash';

import { Card } from '@shared/components';
import { Button } from '@shared/ui';

import { ReactComponent as ConfirmedIconSVG } from '@icons/confirmed-icon.svg';

import styles from './SecurityCard.module.scss';

const SecurityCard = ({
  index,
  indexChildren,
  isActive,
  title,
  description,
  linkTo,
  buttonText,
  onClick = noop,
  classNames = [],
  customStyles = {},
}) => {
  return (
    <Card
      classNames={[
        styles.card,
        isActive && styles.active,
        ...classNames,
        customStyles.card,
      ]}
    >
      <div className={cx('security-card-title-icon', customStyles.title_icon)}>
        <div className={styles.icon}>
          <div className={styles.index}>{index}</div>
          {isActive && (
            <div className={styles.confirmed_icon}>
              <ConfirmedIconSVG />
            </div>
          )}
        </div>

        {indexChildren}
      </div>

      <div
        className={cx(
          styles.content,
          'security-card-content',
          customStyles.content,
        )}
      >
        {Boolean(title) && (
          <div className={cx(styles.title, 'security-card-title')}>
            <div className={styles.text}>{title}</div>

            {Boolean(buttonText) && (
              <Button
                type="button"
                variant="outlined"
                navigation={{ to: linkTo }}
                onClick={onClick}
              >
                {buttonText}
              </Button>
            )}
          </div>
        )}

        {Boolean(description) && (
          <div
            className={cx(
              styles.description,
              'security-card-description',
              customStyles.description,
            )}
          >
            {description}
          </div>
        )}
      </div>
    </Card>
  );
};

export default memo(SecurityCard);
