import { memo, useCallback, useId, useLayoutEffect } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import useBreakpoints from '@hooks/useBreakpoints';
import useTheme from '@hooks/useTheme';

const ColumnSeriesChart = ({
  data,
  width,
  height,
  className,
  dataSeries = [{ name: 'value' }],
  zoomFrom,
  zoomTo,
  paddingLeft = -10,
  paddingRight = -10,
}) => {
  const { isLargeDesktop, isTabletDown, isMobile } = useBreakpoints();

  const legendItemIconBorderRadius = isLargeDesktop ? 2 : 12;
  const legendIconSize = isTabletDown ? 12 : 16;
  const legendIconGap = isTabletDown ? 8 : 13;

  const valuesAxisFontSize = isTabletDown ? 11 : 12;

  const id = useId();
  const { themeStyles } = useTheme();

  const createSeries = useCallback(
    (chart, { name, color, intlName, isOppositeYAxes }) => {
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      const series = chart.series.push(new am4charts.ColumnSeries());

      series.name = intlName;
      series.yAxis = valueAxis;
      series.dataFields.valueY = name;
      series.dataFields.dateX = 'date';

      series.stroke = am4core.color(color);
      series.fill = am4core.color(color);
      series.tooltipText = `${intlName}: [bold]{valueY}[/]`;

      valueAxis.renderer.grid.template.strokeOpacity = 1;
      valueAxis.renderer.labels.template.fontSize = 11;
      valueAxis.renderer.grid.template.stroke = am4core.color(
        themeStyles.white,
        0.08,
      );

      const numberFormatter = new am4core.NumberFormatter();

      numberFormatter.numberFormat = '#.##a';
      valueAxis.numberFormatter = numberFormatter;
      valueAxis.renderer.labels.template.fill = am4core.color(
        themeStyles.white,
        0.5,
      );

      valueAxis.renderer.opposite = isOppositeYAxes;
    },
    [themeStyles.white],
  );

  useLayoutEffect(() => {
    const getLegendMarginBottom = () => {
      if (isMobile) {
        return -20;
      }

      return isTabletDown ? -40 : 0;
    };

    am4core.useTheme(am4themes_animated);
    const chart = am4core.create(id, am4charts.XYChart);

    chart.data = data;

    chart.zoomOutButton.disabled = true;

    chart.hiddenState.properties.opacity = 0;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dataSeries.forEach((item) => {
      createSeries(chart, item);
    });

    dateAxis.renderer.labels.template.fill = am4core.color(themeStyles.white);
    dateAxis.renderer.grid.template.disabled = true;

    dateAxis.renderer.labels.template.fontSize = valuesAxisFontSize;

    dateAxis.renderer.minGridDistance = 20;
    dateAxis.renderer.cellStartLocation = 0.2;
    dateAxis.renderer.cellEndLocation = 0.8;

    chart.events.on('datavalidated', function () {
      dateAxis.zoomToDates(zoomFrom, zoomTo, true, true);
    });
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'panX';
    chart.legend = new am4charts.Legend();
    chart.chartContainer.paddingTop = 40;

    chart.legend.position = 'top';
    chart.legend.contentAlign = 'right';
    chart.legend.labels.template.fill = am4core.color(themeStyles.white);

    const markerTemplate = chart.legend.markers.template;
    chart.legend.fontSize = 12;
    chart.legend.itemContainers.template.paddingTop = 5;
    chart.legend.itemContainers.template.marginBottom = getLegendMarginBottom();

    chart.legend.labels.template.paddingTop = 3;
    chart.legend.useDefaultMarker = !isLargeDesktop;

    chart.paddingLeft = paddingLeft;
    chart.paddingRight = paddingRight;

    const marker = chart.legend.markers.template.children.getIndex(0);

    marker.cornerRadius(
      legendItemIconBorderRadius,
      legendItemIconBorderRadius,
      legendItemIconBorderRadius,
      legendItemIconBorderRadius,
    );

    markerTemplate.width = legendIconSize;
    markerTemplate.marginRight = legendIconGap;
    markerTemplate.height = legendIconSize;
    markerTemplate.verticalCenter = 'middle';
    markerTemplate.horizontalCenter = 'left';

    return () => {
      chart.dispose();
    };
  }, [
    legendItemIconBorderRadius,
    themeStyles.white,
    zoomFrom,
    zoomTo,
    isMobile,
    isTabletDown,
  ]);

  return <div id={id} className={className} style={{ width, height }} />;
};

export default memo(ColumnSeriesChart);
