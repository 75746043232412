import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RequisiteList } from '@shared/components';
import { bankTransferDetails } from '@shared/constants';

import { useWalletsMutations } from '@api/hooks';

import { ReactComponent as InfoIconSVG } from '@icons/info-icon.svg';

import s from './SepaRequisites.module.scss';

const SepaRequisites = () => {
  const intl = useIntl();
  const [clearJunctionDepositInformation, setClearJunctionDepositInformation] =
    useState(null);

  const { onClearJunctionDepositRequest } = useWalletsMutations();

  useEffect(() => {
    const getClearJunctionInformation = async () => {
      const cjInfo = await onClearJunctionDepositRequest({});

      setClearJunctionDepositInformation(cjInfo);
    };

    getClearJunctionInformation().then();
  }, []);

  const requisites = useMemo(
    () => bankTransferDetails({ clearJunctionDepositInformation, intl }),
    [clearJunctionDepositInformation, intl],
  );

  return (
    <div>
      <RequisiteList requisites={requisites || []} />
      <div className={s.footer_block}>
        <div>
          <InfoIconSVG className={s.info_icon} />
        </div>
        <div>
          <div className={s.footer_block__title}>
            <FormattedMessage id="CardRequisitesAddInfoTitle" />
          </div>
          <div className="mt-10 d-flex align-items-start gap-xl-30 gap-20 flex-xl-row flex-column">
            <div className="w-xl-50 w-100">
              <FormattedMessage id="CardRequisitesAddInfoDescription1" />
            </div>
            <div className="w-xl-50 w-100">
              <FormattedMessage id="CardRequisitesAddInfoDescription2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SepaRequisites;
