import React, { memo } from 'react';

import cx from 'classnames';

import styles from './LandingHeadingHighlightedText.module.scss';

const LandingHeadingHighlightedText = ({
  children,
  size = 'l', // xl
  inversion,
  customStyles = {},
}) => {
  return (
    <span
      className={cx(
        styles.highlighted_text,
        styles[size],
        customStyles.highlighted_text,
        {
          [styles.inversion]: inversion,
        },
      )}
    >
      {children}
    </span>
  );
};

export default memo(LandingHeadingHighlightedText);
