import React, { memo } from 'react';

import classnames from 'classnames';

import styles from './Loader.module.scss';

const Loader = ({
  className,
  green = true,
  dark = false,
  s = false,
  xs = false,
  ...rest
}) => {
  return (
    <div
      className={classnames(className, styles.loader, {
        [styles.green]: green,
        [styles.dark]: dark,
        [styles.s]: s,
        [styles.xs]: xs,
      })}
      {...rest}
    />
  );
};

export default memo(Loader);
