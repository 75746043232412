import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Card,
  InfoWidget,
  TotalValue,
  MenuActionsButton,
  PersonalFormattedCurrency,
} from '@shared/components';
import { ButtonArrow } from '@shared/ui';

import { useCoinsRates } from '@store/api/hooks';
import { currencySelector } from '@store/settings/settingsSelectors';

import styles from './SubAccounts.module.scss';

const SubAccounts = ({
  titleId,
  availableValue,
  currency,
  count,
  actions,
  detailsLink,
}) => {
  const { value } = useSelector(currencySelector);

  const { ratesByPair } = useCoinsRates({
    fromCoinSymbol: currency,
    toCoinSymbol: value,
  });

  return (
    <div className={styles.card}>
      <Card
        title={
          <div className={styles.title}>
            <div>
              <FormattedMessage id={titleId} /> ({count})
            </div>
          </div>
        }
      >
        <div className={styles.container}>
          <div className="d-flex flex-column justify-content-between">
            <div className="mb-24">
              <InfoWidget
                title={<FormattedMessage id="AvailableValue" />}
                isTruncateValue
                value={
                  <PersonalFormattedCurrency
                    value={availableValue}
                    currency={currency}
                  />
                }
                suffix={
                  <span className="value-suffix text-uppercase">
                    {currency}
                  </span>
                }
              />

              <TotalValue
                customStyles={styles}
                value={
                  <PersonalFormattedCurrency
                    value={availableValue * ratesByPair}
                    currency={value}
                  />
                }
                symbol={value.toUpperCase()}
              />
            </div>

            <div className="d-flex gap-12">
              <div className={styles.arrow_button}>
                <ButtonArrow
                  fill
                  variant="outlined"
                  color="primary"
                  navigation={{ to: detailsLink }}
                >
                  <FormattedMessage id="Details" />
                </ButtonArrow>
              </div>

              <MenuActionsButton menuItems={actions} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default memo(SubAccounts);
