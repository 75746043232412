export const ORDER_SIDES = {
  SELL: 'sell',
  BUY: 'buy',
};

export const ORDER_STATUS = {
  PENDING: 'pending',
  UNTOUCHED: 'untouched',
  PARTIAL: 'partial',
  FILLED: 'filled',
  CANCELLED: 'cancelled',
};

export const ORDERS_STATUS_COLORS = {
  [ORDER_STATUS.PENDING]: { color: 'yellow', label: 'Pending' },
  [ORDER_STATUS.UNTOUCHED]: { color: 'yellow', label: 'Untouched' },
  [ORDER_STATUS.PARTIAL]: {
    color: 'orange',
    label: 'Partial',
  },
  [ORDER_STATUS.FILLED]: { color: 'green', label: 'Filled' },
  [ORDER_STATUS.CANCELLED]: { color: 'red', label: 'Cancelled' },
};

export const ALL_FILTER_KEY = 'all';
export const ANY_FILTER_KEY = 'any';

export const ALL_OPTIONS = {
  symbol: ALL_FILTER_KEY,
  title: ALL_FILTER_KEY,
};
