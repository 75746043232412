import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import Button from '@components/Button';

import useBreakpoints from '@hooks/useBreakpoints';

import { ReactComponent as ExtendIconSVG } from '@icons/extend-icon.svg';

import { CHART, chartNavigation } from '../../CustomChartTemplate';

import styles from './ChartTemplateNavbar.module.scss';
import headerControlsStyles from '../HeaderControls/HeaderControls.module.scss';

const ChartTemplateNavbar = ({
  activeTab,
  setActiveTab,
  isFullScreen,
  toggleFullScreen,
}) => {
  const { isMobile } = useBreakpoints();

  if (isMobile) {
    return (
      <>
        <Button
          className={classnames(
            'border-0',
            headerControlsStyles.item,
            styles.item,
            {
              [styles['button--active']]: isFullScreen,
            },
          )}
          onClick={toggleFullScreen}
          disabled={activeTab.name !== CHART}
        >
          <span>
            <FormattedMessage id="Enlarge" />
          </span>
          <ExtendIconSVG />
        </Button>
      </>
    );
  }

  return (
    <ul className={styles.chart_template_navbar}>
      {chartNavigation.map((nav) => (
        <li
          key={nav.name}
          className={classnames(styles.tab, {
            [styles['tab--active']]: activeTab.name === nav.name,
          })}
          onClick={() => setActiveTab(nav)}
        >
          <span className={styles.title}>{nav.title}</span>

          {nav.name === CHART && (
            <Button
              className={classnames(styles.button, {
                [styles['button--active']]: isFullScreen,
              })}
              onClick={toggleFullScreen}
              disabled={activeTab.name !== CHART}
            >
              <span>
                <FormattedMessage id="Enlarge" />
              </span>
              <ExtendIconSVG />
            </Button>
          )}
        </li>
      ))}
    </ul>
  );
};

export default memo(ChartTemplateNavbar);
