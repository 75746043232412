import { CurrencyItem } from '@shared/components';

export const transformToCurrencyOptions = ({
  currencies,
  inversion,
  ...props
}) =>
  currencies.map((currency) => ({
    label: (
      <CurrencyItem
        coinSymbol={currency.symbol}
        inversion={inversion}
        {...props}
      />
    ),
    value: currency.symbol,
    type: currency.type,
    fullName: currency.name,
    digits: currency.digits,
  }));
