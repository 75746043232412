import React, { memo } from 'react';

import styles from './AccountFooter.module.scss';

const AccountFooter = ({ children }) => {
  return (
    <div className={styles.footer}>
      <div className={styles.line}></div>
      {children}
    </div>
  );
};

export default memo(AccountFooter);
