import { memo } from 'react';

import {
  Card,
  InfoWidget,
  PercentChangeChart,
  PersonalFormattedCurrency,
  PersonalPercentChange,
  TotalValue,
} from '@shared/components';

import styles from './OverviewEarnWidget.module.scss';

export const OverviewEarnWidget = ({
  percentChange,
  color,
  chartData,
  value,
  valueCurrency,
  totalValueCurrency,
  totalValueSymbol,
  totalValue,
  title,
}) => {
  return (
    <Card color="secondary" classNames={[styles.card]}>
      <div className="d-flex justify-content-between">
        <InfoWidget
          customStyles={styles}
          isTruncateValue
          title={title}
          value={
            <PersonalFormattedCurrency value={value} currency={valueCurrency} />
          }
          suffix={<span className="value-suffix">{valueCurrency}</span>}
        />
        <PersonalPercentChange
          color={color}
          percentValue={percentChange}
          containerClassName={styles.chart__percent}
        />
      </div>

      <div className="d-flex gap-30 justify-content-between">
        <div className="flex-shrink-0">
          <TotalValue
            value={
              <PersonalFormattedCurrency
                value={totalValue}
                currency={totalValueCurrency}
              />
            }
            symbol={totalValueSymbol}
          />
        </div>

        <div className={styles.chart}>
          <PercentChangeChart
            data={chartData}
            color={color}
            width="100%"
            height="44px"
            containerClassName={styles.chart__data}
          />
        </div>
      </div>
    </Card>
  );
};

export default memo(OverviewEarnWidget);
