import { memo } from 'react';

import s from './OrderedItemList.module.scss';

const OrderedItemList = ({ data }) => {
  return (
    <div>
      {data.map((item, i) => (
        <div key={`ordered-list-${i}`} className={s.item}>
          <span className={s.order}>{i + 1}</span>
          <span className={s.title}>{item}</span>
        </div>
      ))}
    </div>
  );
};

export default memo(OrderedItemList);
