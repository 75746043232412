import React, { memo } from 'react';

import cx from 'classnames';

import { format } from '@utils/numbers';

import { PERCENT_PRECISION } from '@constants';

import styles from './CoinPercentDynamics.module.scss';

const CoinPercentDynamics = ({ percent, customStyles = {} }) => {
  const isDown = Number(percent) < 0;

  const isNeutral = isNaN(Number(percent)) || Number(percent) === 0;

  return (
    <div
      className={cx(styles.coin_percent_dynamics, {
        [styles['coin_percent_dynamics--down']]: isDown,
        [styles['coin_percent_dynamics--neutral']]: isNeutral,
      })}
    >
      <span className={cx(styles.pair_percent, customStyles.pair_percent)}>
        {isNeutral ? '' : isDown ? ' ' : ' +'}
        {format(percent, {
          precision: PERCENT_PRECISION,
        })}
        %
      </span>
    </div>
  );
};

export default memo(CoinPercentDynamics);
