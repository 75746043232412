import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shared/ui';

import { ReactComponent as MastercardLogoSVG } from '@images/mastercard-logo.svg';
import { ReactComponent as VisaLogoSVG } from '@images/visa-logo.svg';

import { MAIN_ROUTES } from 'src/routes.constants';

import styles from './BuyCryptoButton.module.scss';

const BuyCryptoButton = () => {
  return (
    <Button
      variant="outlined"
      navigation={{ to: MAIN_ROUTES.DEPOSIT }}
      customStyles={styles}
    >
      <div className={styles.icons}>
        <MastercardLogoSVG />
        <VisaLogoSVG />
      </div>
      <FormattedMessage id="BuyCrypto" />
    </Button>
  );
};

export default memo(BuyCryptoButton);
