import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import cx from 'classnames';

import { copyToClipboard } from '@shared/utils';

import { ReactComponent as CopyIconSVG } from '@images/icons/copy-icon.svg';

import styles from './CopyField.module.scss';

const CopyField = ({
  label,
  value,
  withUnderline = true,
  inverted,
  withWrapValue,
  className,
  isTruncatedValue = true,
  customStyles = {},
}) => {
  const intl = useIntl();

  const handleCopy = async () => {
    await copyToClipboard({ intl, text: value });
  };

  return (
    <div
      className={cx(styles.field, className, customStyles.copy_field, {
        [styles.inverted]: inverted,
        [styles.with_underline]: withUnderline,
      })}
    >
      {label && <div className={customStyles.copy_field__label}>{label}</div>}
      <div className={styles.content}>
        <div
          className={cx(styles.value, customStyles.copy_field__value, {
            [styles.with_wrap_value]: withWrapValue,
            [styles.truncated_value]: isTruncatedValue,
          })}
        >
          {value}
        </div>
        <button
          type="button"
          onClick={handleCopy}
          className={cx(styles.copy_icon, {
            'd-flex': withWrapValue,
          })}
        >
          <CopyIconSVG />
        </button>
      </div>
    </div>
  );
};

export default memo(CopyField);
