import { memo, useRef } from 'react';

import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classnames from 'classnames';

import Loader from '@components/Loader';
import NoData from '@components/NoData';

import useElementResize from '@hooks/useElementResize';

import { ITEMS_PER_PAGE, NOOP } from '@constants';

import { TablePagination } from '../components/TableFooter';

import TableFixed from './TableFixed';
import TableWithSwipe from './TableWithSwipe';

const TableWithFixedColumns = ({
  tableRef,
  id,
  data,
  columns,
  className,
  customStyles,
  headerSize, // s, m, l
  style = {},
  tableHeight,
  isShowPagination = false,
  isShowPaginationArrows = true,
  isShowColumnsOnEmptyData = true,
  isMinimizedPagination,
  extraHeaderComponent,
  meta = { count: 0, page: 1, limit: ITEMS_PER_PAGE },
  sortingState = [],
  wideRows = false,
  wideRowsS = false,
  wideRowsL = false,
  wideRowsXL = false,
  stripedRows = true,
  underlinedRows,
  inversion,
  isLoading = false,
  noDataProps = {},
  breakpoints,
  stickyColumns = 0,
  onPaginationChange = NOOP,
  handleSortingChange = NOOP,
  onRowClick = NOOP,
}) => {
  const fixedColumnsRef = useRef();
  const scrollWrapperRef = useRef();
  const paginationRef = useRef();
  const tablePaginationRef = useRef();

  const pageCount = Math.ceil(meta.count / meta.limit);

  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      sorting: sortingState,
      pagination: {
        pageIndex: meta.page - 1,
        pageSize: meta.limit,
      },
    },
    onSortingChange: handleSortingChange,
    onPaginationChange,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualPagination: true,
  });

  const { width: scrollableSwipeWidth } = useElementResize(scrollWrapperRef);

  const { height: paginationHeight } = useElementResize(paginationRef);
  const { width: fixedColumnsWidth, height: fixedColumnsHeight } =
    useElementResize(fixedColumnsRef);
  const { height: tablePaginationHeight } = useElementResize(
    tablePaginationRef,
    { isBorderBoxSize: true },
  );

  const scrollableHeight = tableHeight
    ? tableHeight - paginationHeight - tablePaginationHeight
    : undefined;

  const scrollableWidth = scrollableSwipeWidth - fixedColumnsWidth;

  const bodyHeight = scrollableHeight
    ? scrollableHeight - fixedColumnsHeight
    : undefined;

  const isNoData = !data.length;

  if (tableRef) {
    tableRef.current = table;
  }

  const renderFixedColumns = () => {
    const headers = table.getHeaderGroups()[0].headers.slice(0, stickyColumns);
    const rows = table
      .getRowModel()
      .rows.map((row) => row.getVisibleCells().slice(0, stickyColumns));

    return (
      <TableFixed
        ref={fixedColumnsRef}
        className={classnames('tables-swipe__fixed-columns', {
          'tables-swipe__fixed-columns--inversion': inversion,
        })}
        id={id}
        headers={headers}
        headerSize={headerSize}
        rows={rows}
        inversion={inversion}
        isShowColumnsOnEmptyData={isShowColumnsOnEmptyData}
        isNoData={isNoData}
        wideRows={wideRows}
        wideRowsS={wideRowsS}
        wideRowsL={wideRowsL}
        wideRowsXL={wideRowsXL}
        stripedRows={stripedRows}
        underlinedRows={underlinedRows}
        sortingState={sortingState}
        onRowClick={onRowClick}
      />
    );
  };

  const renderSwipeColumns = () => {
    const headers = table.getHeaderGroups()[0].headers.slice(stickyColumns);
    const rows = table
      .getRowModel()
      .rows.map((row) => row.getVisibleCells().slice(stickyColumns));

    return (
      <TableWithSwipe
        id={id}
        headers={headers}
        headerSize={headerSize}
        rows={rows}
        inversion={inversion}
        isShowColumnsOnEmptyData={isShowColumnsOnEmptyData}
        isNoData={isNoData}
        stripedRows={stripedRows}
        underlinedRows={underlinedRows}
        scrollableWidth={scrollableWidth}
        width={scrollableSwipeWidth}
        wideRows={wideRows}
        wideRowsS={wideRowsS}
        wideRowsL={wideRowsL}
        wideRowsXL={wideRowsXL}
        isLoading={!fixedColumnsWidth || !scrollableWidth}
        breakpoints={breakpoints}
        paginationRef={paginationRef}
        sortingState={sortingState}
        onRowClick={onRowClick}
      />
    );
  };

  const tableSwipePagination = (
    <div
      className={classnames('tables-swipe__pagination', {
        'tables-swipe__pagination--inversion': inversion,
      })}
      ref={paginationRef}
    />
  );

  return (
    <div
      className={classnames('tables-swipe', className)}
      style={{ height: isNoData ? 'auto' : tableHeight, ...style }}
    >
      {extraHeaderComponent ? (
        <div className="extra-header">
          <div className="extra-header__left">{extraHeaderComponent}</div>

          {tableSwipePagination}
        </div>
      ) : (
        tableSwipePagination
      )}

      <div
        ref={scrollWrapperRef}
        className="tables-swipe__scroll-wrapper"
        style={{ height: isNoData ? 'auto' : scrollableHeight }}
      >
        {renderFixedColumns()}

        {renderSwipeColumns()}
      </div>

      {isLoading && (
        <div>
          <NoData
            label={<Loader />}
            className="bg-transparent"
            style={{ height: bodyHeight }}
          />
        </div>
      )}

      {!isLoading && isNoData && (
        <div className="w-100">
          {noDataProps.component ?? (
            <NoData
              {...noDataProps}
              style={{
                height: bodyHeight,
                ...noDataProps.style,
              }}
            />
          )}
        </div>
      )}

      <div ref={tablePaginationRef}>
        {isShowPagination && (
          <TablePagination
            table={table}
            meta={meta}
            customStyles={customStyles}
            isShowPaginationArrows={isShowPaginationArrows}
            isMinimizedPagination={isMinimizedPagination}
          />
        )}
      </div>
    </div>
  );
};

export default memo(TableWithFixedColumns);
