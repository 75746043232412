import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';

import { ColumnHeaderItem } from '@shared/components';
import { COLUMN_ALIGN } from '@shared/components/Table/table.constants';

import {
  VolumeCell,
  PriceCell,
  PriceChartCell,
  PercentChangeCell,
  CoinOrderCell,
  MarketPairCell,
  CoinNameCell,
  FavouriteMarketButtonCell,
  MarketTypeCell,
  CoinIconCell,
} from '@pages/landings/components/MarketsTable/components';

const { accessor } = createColumnHelper();

const marketsTableColumns = ({
  inversion,
  includedColumnsKeys,
  chartCellSize,
  isShowChart,
  customStyles = {},
  isMarketDetailsRoute = false,
  isTabletDown,
}) => {
  let columns = [
    accessor('currencyIndex', {
      header: () => '#',
      cell: (info) => {
        const index = info.getValue();

        return <CoinOrderCell order={index} />;
      },
      maxSize: 30,
      enableSorting: false,
      enableTruncating: true,
    }),
    accessor('is_favourite', {
      header: () => null,
      cell: (info) => {
        const isFavourite = info.getValue();
        const marketId = info.row.original.id;

        return (
          <FavouriteMarketButtonCell
            isFavourite={isFavourite}
            marketId={marketId}
          />
        );
      },
      maxSize: 30,
      enableSorting: false,
      enableTruncating: true,
    }),
    accessor('coin_name', {
      header: () => <ColumnHeaderItem id="Name" />,
      cell: (info) => {
        const symbol = info.row.original.symbol;

        return <CoinNameCell symbol={symbol} />;
      },
      enableSorting: false,
      enableTruncating: true,
      minSize: isTabletDown ? 300 : 500,
    }),
    accessor('coin_name_without_icon', {
      header: () => <ColumnHeaderItem id="Name" />,
      cell: (info) => {
        const symbol = info.row.original.symbol;

        return <CoinNameCell symbol={symbol} isShowCurrencyIcon={false} />;
      },
      enableSorting: false,
      enableTruncating: true,
      minSize: 300,
    }),
    accessor('coin_icon', {
      header: () => null,
      cell: (info) => {
        const symbol = info.row.original.symbol;

        return <CoinIconCell coinSymbol={symbol} />;
      },
      maxSize: 50,
      enableSorting: false,
    }),
    accessor('market_name', {
      header: () => (
        <ColumnHeaderItem id={isMarketDetailsRoute ? 'Pair' : 'Name'} />
      ),
      cell: (info) => {
        const marketInfo = info.row.original.market;

        return (
          <MarketPairCell
            inversion={inversion}
            marketInfo={marketInfo}
            isShowOnlyMarketName
          />
        );
      },
      enableSorting: false,
      minSize: 50,
      maxSize: 100,
    }),
    accessor('market_fullname', {
      header: () => <ColumnHeaderItem id="Name" />,
      cell: (info) => {
        const marketInfo = info.row.original.market;

        return (
          <MarketPairCell
            marketInfo={marketInfo}
            inversion={inversion}
            isShowOnlyMarketNameAndFullName
          />
        );
      },
      enableSorting: false,
      minSize: isTabletDown ? 250 : 400,
    }),
    accessor('market_pair', {
      header: () => <ColumnHeaderItem id="Pair" />,
      cell: (info) => {
        const marketInfo = info.row.original.market;

        return <MarketPairCell marketInfo={marketInfo} inversion={inversion} />;
      },
      enableSorting: false,
      minSize: isTabletDown ? 250 : 300,
    }),
    accessor('market_type', {
      header: () => <ColumnHeaderItem id="Market" />,

      cell: (info) => {
        const marketType = info.getValue();

        return <MarketTypeCell marketType={marketType} />;
      },
      maxSize: isTabletDown ? 50 : 80,
      enableSorting: false,
      enableTruncating: false,
    }),
    accessor('market_price', {
      header: () => <ColumnHeaderItem id="Price" />,
      cell: (info) => {
        const marketPrice = info.row.original.price;
        const quoteCoinSymbol = info.row.original.quoteCoinSymbol;

        return (
          <PriceCell
            price={marketPrice}
            priceCurrency={quoteCoinSymbol}
            customStyles={customStyles}
          />
        );
      },
      minSize: isMarketDetailsRoute ? 130 : 160,
      maxSize: isMarketDetailsRoute ? 130 : 170,
      enableSorting: false,
      enableTruncating: false,
    }),
    accessor('market_price_with_converted_price', {
      header: () => <ColumnHeaderItem id="Price" />,
      cell: (info) => {
        const marketPrice = info.row.original.price;
        const quoteCoinSymbol = info.row.original.quoteCoinSymbol;

        return (
          <PriceCell
            price={marketPrice}
            priceCurrency={quoteCoinSymbol}
            customStyles={customStyles}
            isShowConvertedPrice
          />
        );
      },
      maxSize: isTabletDown ? 120 : 150,
      enableSorting: false,
      enableTruncating: false,
    }),
    accessor('coin_price', {
      header: () => <ColumnHeaderItem id="Price" />,
      cell: (info) => {
        const marketPrice = info.row.original.price;
        const quoteCoinSymbol = info.row.original.quoteCoinSymbol;

        return (
          <PriceCell
            price={marketPrice}
            priceCurrency={quoteCoinSymbol}
            customStyles={customStyles}
            isShowOnlyConvertedPrice
          />
        );
      },
      maxSize: isTabletDown ? 80 : 100,
      enableSorting: false,
      enableTruncating: false,
    }),
    accessor('price_change', {
      header: () => <ColumnHeaderItem id="24hChange" />,
      cell: (info) => {
        const priceChange = info.getValue();

        return priceChange === undefined ? (
          '--'
        ) : (
          <PercentChangeCell
            percentValue={priceChange}
            customStyles={customStyles}
          />
        );
      },
      maxSize: isTabletDown ? 80 : 100,
      enableSorting: false,
      enableTruncating: true,
    }),
    accessor('market_volume', {
      header: () => <ColumnHeaderItem id="24Volume" />,
      cell: (info) => {
        const volume = info.row.original.volume;

        return (
          <VolumeCell
            volume={volume}
            currency={info.row.original.symbol}
            customStyles={customStyles}
          />
        );
      },
      maxSize: isTabletDown ? 50 : 100,
      align: COLUMN_ALIGN.RIGHT,
      enableSorting: false,
      enableTruncating: true,
    }),
    accessor('coin_volume', {
      header: () => <ColumnHeaderItem id="24Volume" />,
      cell: (info) => {
        const volume = info.row.original.volume;

        return (
          <VolumeCell
            volume={volume}
            currency={info.row.original.symbol}
            customStyles={customStyles}
            isShowOnlyConvertedVolume
          />
        );
      },
      maxSize: isTabletDown ? 80 : 100,
      align: COLUMN_ALIGN.RIGHT,
      enableSorting: false,
      enableTruncating: true,
    }),
    accessor('price_history', {
      header: () => null,
      cell: (info) => (
        <PriceChartCell
          currency={info.row.original.symbol}
          percentChange={info.row.original.priceChange}
          isShowChart={isShowChart}
          inversion={inversion}
        />
      ),
      minSize: chartCellSize,
      maxSize: !isShowChart ? 60 : undefined,
      align: COLUMN_ALIGN.RIGHT,
      enableSorting: false,
      enableTruncating: true,
    }),
  ];

  let columnsUpdated = [];

  if (!isEmpty(includedColumnsKeys)) {
    includedColumnsKeys.forEach((key) => {
      columnsUpdated.push(columns.find((column) => column.accessorKey === key));
    });
  } else {
    columnsUpdated = columns;
  }

  const getLastColumnIndex = () => {
    const lastIndex = columnsUpdated.length - 1;
    if (columnsUpdated[lastIndex].accessorKey === 'price_history') {
      return columnsUpdated.length - 2;
    } else {
      return lastIndex;
    }
  };

  const applyAlignEndToLastColumn = () => {
    columnsUpdated = columnsUpdated.map((column, index) => {
      const isLastColumn = getLastColumnIndex() === index;

      if (isLastColumn) {
        column.align = COLUMN_ALIGN.RIGHT;
      }

      return column;
    });

    return columnsUpdated;
  };

  const lastColumnAlignIsStart =
    !columnsUpdated[getLastColumnIndex()]?.align ||
    columnsUpdated[getLastColumnIndex()]?.align === COLUMN_ALIGN.LEFT;

  if (lastColumnAlignIsStart) {
    columnsUpdated = applyAlignEndToLastColumn();
  }

  return columnsUpdated;
};

export default marketsTableColumns;
