import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { format } from '@utils/numbers';

import styles from './FeeSection.module.scss';

const FeeSection = ({ fee, currentMarket }) => {
  return (
    <div className={styles.section}>
      <div className={styles.label}>
        <FormattedMessage id="IncludedFee" />
      </div>

      <div>
        {format(fee, {
          precision: currentMarket?.quote_precision_format,
        })}{' '}
        <span className="text-uppercase">
          {currentMarket?.quote_coin_symbol}
        </span>
      </div>
    </div>
  );
};

export default memo(FeeSection);
