import { memo } from 'react';

import cx from 'classnames';

import TruncatedText from '@shared/components/TruncatedText';

import s from './SideBarList.module.scss';

/**
 * @items = [{id: 1, label: 'Label', value: 'Some Value'}]
 * */
const SideBarList = ({ items = [] }) => {
  return items.map((item) => (
    <div className={s.container} key={item.id}>
      <TruncatedText className={s.label}>{item.label}</TruncatedText>
      <TruncatedText className={cx(s.value, [item?.customStyles?.value])}>
        {item.value}
      </TruncatedText>
    </div>
  ));
};

export default memo(SideBarList);
