import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@shared/components';

import s from './AccountPagesTabs.module.scss';

/* * *
 * Example of items:
 * [{ id: 'item-1', title: 'Item Title', content: <div>Some content</div> }... ]
 * * */

const AccountPagesTabs = ({
  items = [],
  customStyles = { tab: {}, tabList: {}, tabPanel: {} },
  inversion,
  tabsSpace,
  tabActiveIndex,
  onTabIndexChanged,
  suffix,
  scrollableTabs,
}) => {
  return (
    <Tabs tabActiveIndex={tabActiveIndex} onTabIndexChanged={onTabIndexChanged}>
      <TabList
        inversion={inversion}
        tabsSpace={tabsSpace}
        suffix={suffix}
        customStyles={{ ...customStyles.tabList }}
        scrollableTabs={scrollableTabs}
      >
        {items.map((item) => (
          <Tab
            key={`tab-${item.id}`}
            customStyles={{ ...s, ...customStyles.tab }}
            inversion={inversion}
          >
            {item.title}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {items.map((item) => (
          <TabPanel
            key={`tab-panel-${item.id}`}
            inversion={inversion}
            customStyles={{ ...s, ...customStyles.tabPanel }}
          >
            {item.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default AccountPagesTabs;
