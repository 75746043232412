import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

import { NOOP } from '@constants';

export const excludeTableFilterProps = (props) => {
  const reactHookFormProps = [
    'searchbar',
    'clearAllButton',
    'datepicker',
    'exportPdf',
    'exportXsl',
    'marketsPair',
  ];

  return Object.fromEntries(
    Object.entries(props).filter(
      ([key, _]) => !reactHookFormProps.includes(key),
    ),
  );
};

export const transformTableFiltersValues = (
  values,
  transformAdditionalFieldsCb = NOOP,
) => {
  const filters = {};

  if (values[TABLE_FILTERS_NAMES.SEARCHBAR] !== undefined) {
    filters[TABLE_FILTERS_NAMES.SEARCHBAR] =
      values[TABLE_FILTERS_NAMES.SEARCHBAR];
  }

  if (values?.[TABLE_FILTERS_NAMES.DATE_PICKER]) {
    filters.datePeriod = {
      fromDate: values[TABLE_FILTERS_NAMES.DATE_PICKER]?.startDate?.valueOf(),
      toDate: values[TABLE_FILTERS_NAMES.DATE_PICKER]?.endDate?.valueOf(),
    };

    if (values[TABLE_FILTERS_NAMES.DATE_PICKER]?.isCustomRange) {
      filters.datePeriod.isCustomRange = true;
    }
  }

  if (values[TABLE_FILTERS_NAMES.MARKETS_PAIR.FROM]?.value) {
    filters.pairFrom = values[TABLE_FILTERS_NAMES.MARKETS_PAIR.FROM].value;
  }

  if (values[TABLE_FILTERS_NAMES.MARKETS_PAIR.TO]?.value) {
    filters.pairTo = values[TABLE_FILTERS_NAMES.MARKETS_PAIR.TO].value;
  }

  const additionalFilters = transformAdditionalFieldsCb(filters, values);

  return { ...filters, ...additionalFilters };
};
