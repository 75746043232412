// https://developers.intercom.com/installing-intercom/docs/intercom-javascript

// Set your APP_ID
export const APP_ID =
  process.env.REACT_APP_STAGE === 'production' ? 'peqd9p8j' : 'xm3bj7wl';

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
// prettier-ignore
export const loadIntercom = () => {
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// Initializes Intercom
export const bootIntercom = (options = {}) => {
  window &&
    window.Intercom &&
    window.Intercom('boot', {
      app_id: APP_ID,
      custom_launcher_selector: '#custom-supports-btn',
      hide_default_launcher: true,
      ...options,
    });
};

// This method just calls Intercom('update'), which should be run on every page
// change. This does two things:
// 1. Send an update to Intercom to create an impression on the current URL
// 2. Fetch any messages that should be delivered based on the URL and user
export const updateIntercom = () => {
  window && window.Intercom && window.Intercom('update');
};

// Clears user session and unloads messages
export const shutdownIntercom = () => {
  window && window.Intercom && window.Intercom('shutdown');
};

// This will hide the main Messenger panel if it is open. It will not hide the Messenger Launcher.
export const hideIntercom = () => {
  window && window.Intercom && window.Intercom('hide');
};

// This will show the Messenger. If there are no new conversations, it will open to the Messenger Home. If there are, it will open with the message list.
export const showIntercom = () => {
  window && window.Intercom && window.Intercom('show');
};

export const isIntercomBooted = () => window && window.Intercom;
