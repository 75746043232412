import { createSelector } from '@reduxjs/toolkit';

const featureSelector = (state) => state.instantBonus.myContracts;

const filtersSelector = createSelector(featureSelector, (myContracts) => {
  return myContracts.filters;
});

const paginationSelector = createSelector(featureSelector, (myContracts) => {
  return myContracts.pagination;
});

export const MyContractsSelectors = {
  filtersSelector,
  paginationSelector,
};
