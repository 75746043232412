import { useMemo } from 'react';

import { useGetBotsPnlQuery, useLazyGetBotsPnlQuery } from '@api/botsAPI';
import { BotsApiSelectors } from '@api/selectors';

const selectFromResult = (result) => {
  const pnlByBots = BotsApiSelectors.pnlByBotsSelector(result);
  const pnlByVersions = BotsApiSelectors.pnlByVersionsSelector(result);

  const getPnl = (pnl, contractId) => {
    if (!pnl) {
      return null;
    }

    return {
      ...pnl,
      isContract: Boolean(contractId),
      pnl: pnl.bot_profit,
      botProfit: pnl.profit,
      currency: pnl.profit_coin,
    };
  };

  const findBotPnl = (bot) => {
    const pnl = pnlByBots.find((pnl) => pnl.bot_id === bot.id);

    return getPnl(pnl, bot.contract_id);
  };

  const findBotPnlByVersion = (bot, contractId) => {
    const pnl = pnlByVersions.find((pnl) => pnl.bot_version_id === bot.id);

    return getPnl(pnl, contractId);
  };

  return {
    isBotsPnlSuccess: result.isSuccess,
    pnlByBots,
    pnlByVersions,
    findBotPnl,
    findBotPnlByVersion,
  };
};

export const useBotsPnl = (args, options) => {
  const result = useGetBotsPnlQuery(undefined, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export const useLazyBotsPnL = (options) => {
  const [trigger, result] = useLazyGetBotsPnlQuery(options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return [trigger, data];
};
