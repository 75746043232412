import React, { memo, useEffect } from 'react';

import { Fade } from 'react-awesome-reveal';

import { NOOP } from '@constants';

import { ProductsMenuAllMarkets } from '../ProductsMenuAllMarkets';

import MarketsStats from './components/MarketsStats';

const ProductMenuFooter = ({ onCloseSideBar = NOOP }) => {
  useEffect(() => {
    const productsMenuElement = document.querySelector('.products-menu-view');

    // hide scrollbar during Fade animation
    productsMenuElement.style.overflow = 'hidden';

    setTimeout(() => {
      productsMenuElement.style.overflow = '';
    }, 500);
  }, []);

  return (
    <div className="product-menu-footer">
      <Fade direction="up" duration={500} triggerOnce>
        <div className="d-flex align-items-center justify-content-end mb-12">
          <ProductsMenuAllMarkets onCloseSideBar={onCloseSideBar} />
        </div>

        <MarketsStats />
      </Fade>
    </div>
  );
};

export default memo(ProductMenuFooter);
