import { memo } from 'react';

import classNames from 'classnames';

import styles from './Avatar.module.scss';

const Avatar = ({ avatarSrc, width = 64, height = 64 }) => {
  return (
    <img
      width={width}
      height={height}
      className={classNames('avatar', styles.avatar)}
      src={avatarSrc}
      alt="Avatar"
    />
  );
};

export default memo(Avatar);
