import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { ColumnHeaderItem } from '@shared/components';

import {
  RemainingDaysItem,
  StakeActionItem,
  StakeAssetItem,
  StakeDateItem,
  StakeEstimationItem,
} from '@pages/WalletsPage/components';

const { accessor } = createColumnHelper();

const availablePlansTableColumns = () => [
  accessor('asset', {
    header: () => (
      <div className="d-flex">
        <ColumnHeaderItem id="Asset" /> / <ColumnHeaderItem id="Deposited" />
      </div>
    ),
    cell: (info) => {
      const { coinSymbol, amount, precision } = info.getValue();

      return (
        <StakeAssetItem
          coinSymbol={coinSymbol}
          amount={amount}
          precision={precision}
        />
      );
    },
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
    enableTruncating: true,
  }),
  accessor('estimation', {
    header: () => <ColumnHeaderItem id="Estimation" />,
    cell: (info) => {
      const { amount, period, percent, coinSymbol } = info.getValue();

      return (
        <StakeEstimationItem
          amount={amount}
          period={period}
          percent={percent}
          coinSymbol={coinSymbol}
        />
      );
    },
    maxSize: 100,
    cellAlign: 'start',
    headerAlign: 'start',
    enableSorting: false,
  }),
  accessor('remainingDays', {
    header: () => (
      <div className="d-flex justify-content-end">
        <ColumnHeaderItem id="Remaining" /> /{' '}
        <ColumnHeaderItem id="DurationDays" />
      </div>
    ),
    cell: (info) => {
      const { remainingDays, durationDays } = info.getValue();

      return (
        <RemainingDaysItem
          remainingDays={remainingDays}
          durationDays={durationDays}
        />
      );
    },
    enableSorting: false,
  }),
  accessor('dateInfo', {
    header: () => (
      <div className="d-flex justify-content-end">
        <ColumnHeaderItem id="Start" /> / <ColumnHeaderItem id="FinishDate" />
      </div>
    ),
    cell: (info) => {
      const { start, expired } = info.getValue();

      return <StakeDateItem start={start} expired={expired} />;
    },
    maxSize: 50,
    enableSorting: false,
  }),
  accessor('action', {
    header: () => <ColumnHeaderItem id="Actions" />,
    cell: (info) => {
      const { id, coinSymbol, amount } = info.getValue();

      return (
        <StakeActionItem
          amount={amount}
          coinSymbol={coinSymbol}
          stakingId={id}
        />
      );
    },
    enableSorting: false,
    enableTruncating: false,
  }),
];

export default availablePlansTableColumns;
