export const LAUNCHPAD_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  UPCOMING: 'upcoming',
  ENDED: 'ended',
  DELETED: 'deleted',
};

export const LAUNCHPAD_STATUS_COLORS = {
  [LAUNCHPAD_STATUSES.ACTIVE]: 'green',
  [LAUNCHPAD_STATUSES.INACTIVE]: 'yellow',
  [LAUNCHPAD_STATUSES.UPCOMING]: 'red',
  [LAUNCHPAD_STATUSES.ENDED]: 'black',
  [LAUNCHPAD_STATUSES.DELETED]: 'red',
};

export const ITEMS_PER_PAGE_LAUNCHPAD = 10;
