import { memo, useMemo } from 'react';

import Table from '@components/gridTables/Table';

import { useWithdrawHistoryTable } from '@pages/WalletsPage/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import withdrawHistoryTableColumns from '../WithdrawHistoryTableColumns/WithdrawHistoryTableColumns';

const WithdrawHistoryTable = () => {
  const { isTabletDown } = useBreakpoints();

  const {
    withdrawalsHistoryData,
    withdrawalsHistoryMeta,
    isWithdrawalsHistoryTableSuccess,
    setPagination,
  } = useWithdrawHistoryTable();

  const columns = useMemo(
    () => withdrawHistoryTableColumns({ isSwipeableTable: isTabletDown }),
    [isTabletDown],
  );

  return (
    <Table
      id="withdraw-history-table"
      columns={columns}
      meta={withdrawalsHistoryMeta}
      data={withdrawalsHistoryData}
      wideRows
      isSwipeable={isTabletDown}
      isShowPagination
      onPaginationChange={setPagination}
      isLoading={!isWithdrawalsHistoryTableSuccess}
      className="account-tables-swipe mt-14"
      swipeableTableProps={{
        stickyColumns: 1,
        breakpoints: [
          [600, 2],
          [700, 3],
          [900, 4],
          [1024, 5],
        ],
      }}
    />
  );
};

export default memo(WithdrawHistoryTable);
