import { useMemo } from 'react';

import { MyStakingSelectors } from '@api/selectors';
import { useStakingEarningsQuery } from '@api/stakingAPI';

const selectFromResult = (result) => {
  const dataResult = {
    isStakingEaringsSuccess: result.isSuccess,
  };

  dataResult.stakingEarnings =
    MyStakingSelectors.stakingEarningsSelector(result);

  return dataResult;
};

const useGetStakingEarnings = ({ stakingId } = {}, options) => {
  const result = useStakingEarningsQuery({ stakingId }, options);

  const data = useMemo(() => {
    return selectFromResult(result);
  }, [result]);

  return data;
};

export default useGetStakingEarnings;
