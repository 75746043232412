import { createSlice } from '@reduxjs/toolkit';

import { VISA_CARD_SETTINGS_IDS } from '@pages/WalletsPage/VisaCardSettings/VisaCardSettings.constants';

const initialState = {
  selectedSettingId: VISA_CARD_SETTINGS_IDS.SPENDING_LIMIT,
};

const visaCardSettingsSlice = createSlice({
  name: 'visaCardSettings',
  initialState,
  reducers: {
    setSelectedSettingId: (state, action) => {
      state.selectedSettingId = action.payload;
    },
  },
});

const visaCardSettingsActions = visaCardSettingsSlice.actions;
const visaCardSettingsReducer = visaCardSettingsSlice.reducer;

export { visaCardSettingsActions, visaCardSettingsReducer };

export default visaCardSettingsSlice;
