import { createSlice } from '@reduxjs/toolkit';

import { LOCAL_STORAGE_WALLETS_OVERVIEW_WIDGETS } from '@constants';

import { INITIAL_SELECTED_WIDGETS } from './widgets.constants';

const LOCAL_STORAGE_WIDGETS = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_WALLETS_OVERVIEW_WIDGETS),
);

const INITIAL_WIDGETS_STATE = LOCAL_STORAGE_WIDGETS || INITIAL_SELECTED_WIDGETS;

const walletsWidgetsSlice = createSlice({
  name: 'walletsWidgets',
  initialState: {
    widgets: INITIAL_WIDGETS_STATE,
  },
  reducers: {
    updateWidgets: (state, { payload }) => {
      const widgets = payload.map(({ widgetId, title, isActive }) => ({
        widgetId,
        title,
        isActive,
      }));

      localStorage.setItem(
        LOCAL_STORAGE_WALLETS_OVERVIEW_WIDGETS,
        JSON.stringify(widgets),
      );

      state.widgets = widgets;
    },
  },
});

const walletsWidgetsActions = walletsWidgetsSlice.actions;
const walletsWidgetsReducer = walletsWidgetsSlice.reducer;

export { walletsWidgetsActions, walletsWidgetsReducer };
export default walletsWidgetsSlice;
