import { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFormContext } from 'react-hook-form';
import { components, createFilter } from 'react-select';

import { SelectController } from '@shared/ui';
import { CreateLabel } from '@shared/ui/Select/components';

import { isNotInternalAddress } from '@pages/WalletsPage';
import { withdrawCurrencySymbolSelector } from '@pages/WalletsPage/Withdraw/Withdraw.selectors';

import { userEmailSelector } from '@store/settings/settingsSelectors';

import { WalletsApiSelectors } from '@api/selectors';
import { useGetWithdrawAddressesQuery } from '@api/walletsAPI';

import useSidebar from '@hooks/useSidebar';

import { sidebarIds } from '@constants';

import styles from './WithdrawAddressesSelect.module.scss';

const { SingleValue, MenuList, Option } = components;

const WithdrawAddressesSelect = () => {
  const intl = useIntl();

  const [newAddressValue, setNewAddressValue] = useState('');

  const userEmail = useSelector(userEmailSelector);
  const selectedCurrencySymbol = useSelector(withdrawCurrencySymbolSelector);

  const methods = useFormContext();

  const { toggleSidebar } = useSidebar(sidebarIds.WALLETS_WITHDRAW_ADD_ADDRESS);

  const { addresses } = useGetWithdrawAddressesQuery(undefined, {
    selectFromResult: (result) => ({
      addresses: WalletsApiSelectors.withdrawAddressesSelector(result),
    }),
  });

  const addressesOptions = useMemo(
    () =>
      addresses
        .filter((address) => address.coin === selectedCurrencySymbol)
        .map((address) => ({
          ...address,
          value: address.address,
          name: address.name,
          label: (
            <div className={styles.label_container}>
              <span className={styles.label}>{address.name}</span>
              <span className={styles.address}>{address.address}</span>
            </div>
          ),
        })),
    [addresses, selectedCurrencySymbol],
  );

  const handleCreateAddressClick = () => {
    methods.resetField('address');

    toggleSidebar({
      coin: selectedCurrencySymbol,
      address: newAddressValue,
    });

    setNewAddressValue('');
  };

  const handleInputAddress = (inputValue, { action }) => {
    if (action === 'input-change') {
      setNewAddressValue(inputValue);
    }
    if (action === 'set-value') {
      setNewAddressValue('');
    }
  };

  const SpecificSingleValue = ({ data: { value }, ...props }) => (
    <SingleValue {...props}>{value}</SingleValue>
  );

  const SpecificMenuList = ({ children, ...props }) => (
    <MenuList {...props}>
      {children}
      <Option className={styles.option} {...props}>
        <CreateLabel size="xs" onClick={handleCreateAddressClick} />
      </Option>
    </MenuList>
  );

  const specificComponents = useMemo(() => {
    const components = { SingleValue: SpecificSingleValue };

    if (!!newAddressValue) components.MenuList = SpecificMenuList;

    return components;
  }, [newAddressValue]);

  // search by address and address label
  const filterConfig = {
    ignoreCase: true,
    trim: true,
    stringify: (opt) => `${opt.value} ${opt.data.name}`,
  };

  return (
    <SelectController
      name="address"
      placeholder={`+ ${intl.formatMessage({ id: 'InputYourNewAddress' })}...`}
      rules={{
        required: intl.formatMessage({ id: 'FieldRequired' }),
        validate: {
          isNotInternalAddress: isNotInternalAddress(
            userEmail,
            selectedCurrencySymbol,
            intl,
          ),
        },
      }}
      options={[...addressesOptions]}
      filterOption={createFilter(filterConfig)}
      specificComponents={specificComponents}
      label={intl.formatMessage({ id: 'WithdrawalAddress' })}
      underlined
      creatable
      isClearable
      onInputChange={handleInputAddress}
      {...methods}
    />
  );
};

export default memo(WithdrawAddressesSelect);
