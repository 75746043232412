import React, { memo } from 'react';

import cx from 'classnames';
import { components } from 'react-select';

import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';

import styles from './CustomDropdownIndicator.module.scss';

const { DropdownIndicator } = components;

const CustomDropdownIndicator = ({ selectProps, ...props }) => {
  const isInversion = selectProps.inversion || selectProps.chevronInversion;

  return (
    <DropdownIndicator {...props}>
      <DownIconSVG
        className={cx('custom-indicator', styles.down_icon, {
          [styles.inversion]: isInversion,
        })}
      />
    </DropdownIndicator>
  );
};

export default memo(CustomDropdownIndicator);
