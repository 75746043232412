import React, { memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { Transition } from 'react-transition-group';

import Breakpoint from '@components/Breakpoint';
import Button from '@components/Button';
import CoinPercentDynamics from '@components/CoinPercentDynamics';
import Loader from '@components/Loader';

import ChartDropdown from '@pages/TerminalPage/components/GridLayout/blocks/Chart/ChartDropdown';
import MarketsBlock from '@pages/TerminalPage/components/GridLayout/blocks/Markets';

import { useCoinsRates, useMarketsInfo } from '@store/api/hooks';
import {
  favouritePairsNamesSelector,
  useGetFavouritesPairsQuery,
  useToggleFavouritePairMutation,
} from '@store/api/profileAPI';
import {
  currencySelector,
  isGuestUserSelector,
  marketIdSelector,
} from '@store/settings/settingsSelectors';

import {
  streamMarketsStatisticByMarketIdSelector,
  useStreamMarketStatisticsQuery,
} from '@api/marketsAPI';

import useBreakpoints from '@hooks/useBreakpoints';

import { format } from '@utils/numbers';

import { CRYPTO_COIN_LIST } from '@constants';

import { ReactComponent as CrossIconSVG } from '@icons/cross-icon.svg';
import { ReactComponent as DownIconSVG } from '@icons/down-icon.svg';
import { ReactComponent as StarIconSVG } from '@icons/star-icon.svg';

import styles from './ChartMainHeader.module.scss';

const getDynamicStatistics = (coin, precision, stat) => [
  {
    key: 'h',
    title: <FormattedMessage id="High" />,
    value: format(stat?.h, { precision }),
  },
  {
    key: 'l',
    title: <FormattedMessage id="Low" />,
    value: format(stat?.l, { precision }),
  },
  {
    key: 'qv',
    title: <FormattedMessage id="24hVolOfCoin" values={{ coin }} />,
    value: format(stat?.qv, { precision }),
  },
];

const ChartMainHeader = () => {
  const chartMainHeaderRef = useRef();

  const { isMobile } = useBreakpoints();

  const [toggleFavouritePair] = useToggleFavouritePairMutation();

  const isGuest = useSelector(isGuestUserSelector);

  const marketId = useSelector(marketIdSelector);
  const { marketInfo, isMarketsInfoSuccess } = useMarketsInfo({ marketId });
  const { symbol, value: currency } = useSelector(currencySelector);

  const { marketsStatistic } = useStreamMarketStatisticsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      marketsStatistic: streamMarketsStatisticByMarketIdSelector(
        result,
        marketId,
      ),
    }),
  });

  const { ratesByPair: coinRate } = useCoinsRates({
    fromCoinSymbol: currency,
    toCoinSymbol: marketInfo?.quote_coin_symbol,
  });

  const { favouritePairs } = useGetFavouritesPairsQuery(undefined, {
    skip: isGuest,
    selectFromResult: (result) => ({
      favouritePairs: favouritePairsNamesSelector(result),
    }),
  });

  const [showMobileMarketStatistics, setShowMobileMarketStatistics] =
    useState(false);

  const coinPriceInQuoteCurrency = marketsStatistic.c;
  const coinPriceInCurrentCurrency = coinPriceInQuoteCurrency / coinRate;

  const getAsset = () => {
    const marketCoinAsset =
      CRYPTO_COIN_LIST.find(
        ({ coin }) => coin === marketInfo?.market_coin_symbol,
      ) ?? CRYPTO_COIN_LIST[0];
    const quoteCoinAsset =
      CRYPTO_COIN_LIST.find(
        ({ coin }) => coin === marketInfo?.quote_coin_symbol,
      ) ?? CRYPTO_COIN_LIST[0];

    return (
      <div className={styles.pair_select__asset}>
        <div className={styles.pair_select__asset__coin}>
          {marketCoinAsset.iconOutline}
        </div>
        <div className={styles.pair_select__asset__coin}>
          {quoteCoinAsset.iconOutline}
        </div>
      </div>
    );
  };

  const handleFavouriteClick = () => {
    toggleFavouritePair({ pair: marketId });
  };

  return (
    <div ref={chartMainHeaderRef} className={styles.chart_main_header}>
      <div className="d-flex align-items-center gap-26">
        {isMarketsInfoSuccess ? (
          <div>
            <ChartDropdown
              container={
                isMobile
                  ? document.getElementById('mobile-chart-container')
                  : undefined
              }
              nestedComponent={({ setIsOpen }) => (
                <MarketsBlock
                  i="chart-main-header"
                  layoutItemRef={chartMainHeaderRef}
                  withoutHeader
                  setIsOpen={setIsOpen}
                />
              )}
              onOpen={() => {
                if (isMobile) {
                  document.body.style.overflow = 'hidden';
                  document.querySelector('.scrollable-layout').style.overflow =
                    'hidden';
                }
              }}
              onClose={() => {
                if (isMobile) {
                  document.body.style.overflow = '';
                  document.querySelector('.scrollable-layout').style.overflow =
                    '';
                }
              }}
            >
              {({ triggerRef, handleMenuTrigger, isOpen }) => (
                <div
                  className={classnames(
                    'd-flex align-items-start gap-8 cursor-pointer',
                    styles.pair_select,
                  )}
                  ref={triggerRef}
                  onClick={handleMenuTrigger}
                >
                  {getAsset()}

                  <p className={styles.pair_select__name}>{marketInfo?.name}</p>

                  <div
                    className={classnames(styles.pair_select__name__chevron, {
                      [styles['pair_select__name__chevron--active']]: isOpen,
                    })}
                  >
                    <DownIconSVG width={10} height={5} />
                  </div>
                </div>
              )}
            </ChartDropdown>
          </div>
        ) : (
          <Loader s />
        )}

        {isMobile ? (
          <div className="flex-1 d-flex justify-content-end gap-24">
            <Button
              className={styles.favourite_market_button}
              s
              disabled={isGuest}
              onClick={handleFavouriteClick}
            >
              <StarIconSVG
                height={20}
                width={20}
                className={classnames(styles.favourite_market_button__icon, {
                  [styles['favourite_market_button__icon--active']]:
                    favouritePairs.find((item) => item === marketInfo?.id),
                  [styles['favourite_market_button__icon--disabled']]: isGuest,
                })}
              />
            </Button>

            <Button
              className={styles.close_button}
              s
              onClick={() =>
                setShowMobileMarketStatistics((prevState) => !prevState)
              }
            >
              <CrossIconSVG
                width={18}
                height={18}
                className={classnames(styles.close_button__icon, {
                  [styles['close_button__icon--rotate']]:
                    showMobileMarketStatistics,
                })}
              />
            </Button>
          </div>
        ) : (
          <>
            <div className={styles.pair_price}>
              <p>
                {format(coinPriceInQuoteCurrency, {
                  precision: marketInfo?.quote_precision_format,
                })}
              </p>
              <p>
                {symbol}
                {format(coinPriceInCurrentCurrency, {
                  precision: marketInfo?.quote_precision_format,
                })}
              </p>
            </div>

            <CoinPercentDynamics percent={marketsStatistic?.ch} />

            <Breakpoint size="lg" direction="up">
              <div className={styles.statistic}>
                {getDynamicStatistics(
                  marketInfo?.market_coin_symbol,
                  marketInfo?.quote_precision_format,
                  marketsStatistic,
                ).map((stat, index) => (
                  <div key={stat.key + index}>
                    <p className={styles.statistic__title}>{stat.title}</p>
                    <p className={styles.statistic__value}>{stat.value}</p>
                  </div>
                ))}
              </div>
            </Breakpoint>
          </>
        )}
      </div>

      <Transition
        in={showMobileMarketStatistics}
        timeout={{ exit: 200 }}
        unmountOnExit
      >
        {(state) => (
          <div
            className={classnames(
              'd-flex flex-column justify-content-between',
              styles.mobile_container,
            )}
            style={{
              maxHeight: state === 'entered' ? 130 : 0,
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div
                className={classnames(
                  'd-flex flex-column',
                  styles.pair_price,
                  styles.mobile_container__pair_price,
                )}
              >
                <p>
                  {format(coinPriceInQuoteCurrency, {
                    precision: marketInfo?.quote_precision_format,
                  })}
                </p>
                <p>
                  {symbol}
                  {format(coinPriceInCurrentCurrency, {
                    precision: marketInfo?.quote_precision_format,
                  })}
                </p>
              </div>

              <CoinPercentDynamics percent={marketsStatistic?.ch} />
            </div>

            <div
              className={classnames(
                styles.statistic,
                styles.mobile_container__statistic,
              )}
            >
              {getDynamicStatistics(
                marketInfo?.market_coin_symbol,
                marketInfo?.quote_precision_format,
                marketsStatistic,
              ).map((stat, index) => (
                <div key={stat.key + index}>
                  <p className={styles.statistic__title}>{stat.title}</p>
                  <p className={styles.statistic__value}>{stat.value}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default memo(ChartMainHeader);
