import React, { memo } from 'react';

import { format } from '@utils/numbers';

import styles from './AvailableBalanceItem.module.scss';

const AvailableBalanceItem = ({ availableBalance, precision }) => {
  const value = format(availableBalance, {
    precision,
  });

  const numberValue = format(availableBalance, {
    precision,
    returnNumber: true,
  });

  return (
    <span className={numberValue ? styles.black : styles.grey}>{value}</span>
  );
};

export default memo(AvailableBalanceItem);
