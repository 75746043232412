import { useMemo } from 'react';
import { useParams } from 'react-router';

import { useCoinsInfo, useGetStakingEarnings } from '@store/api/hooks';

const useStakingHistoryTable = () => {
  const { stakingId } = useParams();

  const { coinsInfo } = useCoinsInfo();

  const { stakingEarnings, isStakingEaringsSuccess } = useGetStakingEarnings({
    stakingId,
  });

  const stakingHistory = useMemo(
    () =>
      stakingEarnings.map((item) => {
        const coinSymbol = item.coin_symbol;

        const coinInfo = coinsInfo?.[coinSymbol];

        return {
          asset: coinSymbol,
          id: item.id,
          stakingId: item.staking_id,
          amount: { amount: item.amount, precision: coinInfo?.digits },
          createdAt: item.created_at,
        };
      }),
    [coinsInfo, stakingEarnings],
  );

  return {
    stakingHistory,
    isEarningsHistorySuccess: isStakingEaringsSuccess,
  };
};

export default useStakingHistoryTable;
