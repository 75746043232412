import React, { memo } from 'react';

import { useGetProfileInfoQueryResult } from '@store/api/hooks';

import { Avatar } from './Avatar';
import DefaultAvatar from './DefaultAvatar';

const UserAvatar = ({ width = 64, height = 64 }) => {
  const { userAvatar, userAvatarSrc } = useGetProfileInfoQueryResult();

  return userAvatar ? (
    <Avatar avatarSrc={userAvatarSrc} width={width} height={height} />
  ) : (
    <DefaultAvatar avatarId={!userAvatarSrc} width={width} height={height} />
  );
};

export default memo(UserAvatar);
