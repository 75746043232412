import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { SideBarBlock, SideBarList } from '@shared/components';

import { visaCardDetailsActions } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.slice';

import { currencySelector } from '@store/settings/settingsSelectors';

import { useCoinsInfo, useCoinsRates } from '@api/hooks';

import { format } from '@utils/numbers';

/**
 * Glossary:
 * mainCurrency - currency of application (i.e. eur or usd)
 * selectedCurrency - currency selected by the user
 * */

const SpotAccountMethodDetails = ({ selectedCurrency, amount }) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const mainCurrency = useSelector(currencySelector);

  const { coinInfo: mainCurrencyInfo } = useCoinsInfo({
    coinSymbol: mainCurrency.value,
  });

  const { ratesByCoin: mainCurrencyRates } = useCoinsRates({
    fromCoinSymbol: mainCurrency?.value,
  });

  const { ratesByCoin: selectedCurrencyRates } = useCoinsRates({
    fromCoinSymbol: selectedCurrency?.value,
  });

  const selectedCurrencySymbol = selectedCurrency?.value?.toUpperCase();

  const formattedCurrencyRate = useMemo(() => {
    if (!mainCurrencyRates || !selectedCurrency?.value) return 0;

    return format(mainCurrencyRates[selectedCurrency.value.toUpperCase()], {
      precision: selectedCurrency.digits,
    });
  }, [mainCurrencyRates, selectedCurrency]);

  // TODO replace to real data
  const commission = 5;

  const formattedCommission = useMemo(
    () =>
      `${mainCurrency?.symbol} ${format(commission, {
        precision: mainCurrencyInfo?.digits,
      })}`,
    [mainCurrency, commission, mainCurrencyInfo],
  );

  const formattedCurrentPrice = useMemo(
    () =>
      `${mainCurrency?.symbol} ${format(1, {
        precision: mainCurrencyInfo?.digits,
      })} = ${formattedCurrencyRate} ${selectedCurrencySymbol}`,
    [
      mainCurrency,
      mainCurrencyInfo,
      formattedCurrencyRate,
      selectedCurrencySymbol,
    ],
  );

  const profitMemo = useMemo(() => {
    const selectedCurrencyRate = Number(
      selectedCurrencyRates?.[mainCurrency.value.toUpperCase()],
    );

    if (!selectedCurrencyRate || !amount) return 0;

    const amountInMainCurrency = amount * selectedCurrencyRate;

    const profit = amountInMainCurrency - commission;

    if (profit < 0) return 0;

    return profit;
  }, [selectedCurrencyRates, mainCurrency, amount, commission]);

  const formattedProfit = useMemo(
    () =>
      `${mainCurrency?.symbol} ${format(profitMemo, {
        precision: mainCurrencyInfo?.digits,
      })}`,
    [profitMemo, mainCurrencyInfo, mainCurrency],
  );

  useEffect(() => {
    const confirmationDetails = {
      currentPrice: formattedCurrentPrice,
      commission: { value: commission, formattedValue: formattedCommission },
    };

    if (profitMemo > 0) {
      confirmationDetails.profit = formattedProfit;
    }

    dispatch(
      visaCardDetailsActions.setSpotAccountFormDetails(confirmationDetails),
    );
  }, [
    dispatch,
    formattedCurrentPrice,
    commission,
    formattedCommission,
    formattedProfit,
    profitMemo,
  ]);

  const TopUpDetails = useMemo(
    () => [
      {
        id: 'currentPrice',
        label: `${intl.formatMessage({ id: 'CurrentPrice' })}:`,
        value: `${formattedCurrentPrice}`,
      },
      {
        id: 'commission',
        label: `${intl.formatMessage({ id: 'Commission' })}:`,
        value: `${formattedCommission}`,
      },
      {
        id: 'profit',
        label: `${intl.formatMessage({ id: 'YouWillReceive' })}:`,
        value: `${formattedProfit}`,
      },
    ],
    [intl, formattedCurrentPrice, formattedCommission, formattedProfit],
  );

  return (
    <SideBarBlock>
      <SideBarList items={TopUpDetails} />
    </SideBarBlock>
  );
};

export default SpotAccountMethodDetails;
