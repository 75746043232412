import { createSelector } from '@reduxjs/toolkit';

import { TABLE_FILTERS_NAMES } from '@shared/components/TableFilters/TableFilters';

const featureSelector = (state) => state.stakingAssets.availablePlans;

const selectedPeriodsSelector = createSelector(
  featureSelector,
  (availablePlans) => {
    return availablePlans.selectedPeriods;
  },
);

const selectedPeriodSelector = createSelector(
  featureSelector,
  (availablePlans) => {
    return availablePlans.selectedPeriod;
  },
);

const searchSelector = createSelector(featureSelector, (availablePlans) => {
  return availablePlans[TABLE_FILTERS_NAMES.SEARCHBAR];
});

export const AvailablePlansSelectors = {
  selectedPeriodsSelector,
  selectedPeriodSelector,
  searchSelector,
};
