import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import Link from '@components/Link';

import AuthPromptSrc from '@images/auth-prompt.png';
import { ReactComponent as MenuPlusSVG } from '@images/icons/menu-plus-icon.svg';

import { ReactComponent as ArrowRightSVG } from '@icons/arrow-right-side-icon.svg';

import { AUTH_ROUTES } from 'src/routes.constants';

const AuthPromptSection = () => {
  return (
    <div className="auth-prompt-section">
      <img src={AuthPromptSrc} className="img-fluid" alt="" />

      <div className="auth-prompt-contents">
        <MenuPlusSVG />

        <div className="auth-prompt-text">
          <FormattedMessage id="LoginToUseTerminal" />
        </div>

        <div className="auth-prompt-links-section">
          <Link to={AUTH_ROUTES.LOGIN} className="auth-prompt-login-link">
            <FormattedMessage id="Login" />
            <ArrowRightSVG />
          </Link>

          <Link
            to={generatePath(AUTH_ROUTES.REGISTER)}
            className="auth-prompt-signup-link"
          >
            <FormattedMessage id="Signup" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(AuthPromptSection);
