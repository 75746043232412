import { useId, useMemo } from 'react';

const DOTS = { isDots: true };

const range = (start, end) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

const useDotsPagination = ({
  totalPageCount,
  currentPage,
  siblingCount = 1,
  showSiblingsAfter = 6,
  leftItemCount = 5,
  rightItemCount = 5,
}) => {
  const leftDots = { ...DOTS, id: useId() };
  const rightDots = { ...DOTS, id: useId() };

  const paginationRange = useMemo(() => {
    const isHideSiblings = showSiblingsAfter >= totalPageCount;

    if (isHideSiblings) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    const isShowLeftDots = leftSiblingIndex > 1;
    const isShowRightDots = rightSiblingIndex < totalPageCount;

    const isShowOnlyRightDots = !isShowLeftDots && isShowRightDots;

    if (isShowOnlyRightDots) {
      const leftRange = range(1, leftItemCount);

      return [...leftRange, rightDots, totalPageCount];
    }

    const isShowOnlyLeftDots = isShowLeftDots && !isShowRightDots;

    if (isShowOnlyLeftDots) {
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );

      return [1, leftDots, ...rightRange];
    }

    const isShowBothDots = isShowLeftDots && isShowRightDots;

    if (isShowBothDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);

      return [1, leftDots, ...middleRange, rightDots, totalPageCount];
    }
  }, [
    showSiblingsAfter,
    totalPageCount,
    currentPage,
    siblingCount,
    leftItemCount,
    rightItemCount,
  ]);

  return paginationRange;
};

export default useDotsPagination;
