import { createSelector } from '@reduxjs/toolkit';

const featureSelector = (state) => state.instantBonus.expiredContracts;

const filtersSelector = createSelector(featureSelector, (expiredContracts) => {
  return expiredContracts.filters;
});

const paginationSelector = createSelector(
  featureSelector,
  (expiredContracts) => {
    return expiredContracts.pagination;
  },
);

export const ExpiredContractsSelectors = {
  filtersSelector,
  paginationSelector,
};
