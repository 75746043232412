import React, { memo, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { useMatchedRoutes } from '@shared/hooks';

import MainLayout from '@components/layouts/MainLayout';

import { useSecurityLevelModal } from '@pages/AccountPage/hooks';

import useBreakpoints from '@hooks/useBreakpoints';

import { AccountFooter, AccountHeader, AccountSideBar } from './components';

import { ROUTES_NAMES, ROUTES_SHORT_NAMES } from '../../../routes.constants';

import styles from './AccountPagesLayout.module.scss';

/**
 * sidebarRoutes example:
 * [
 *    {route: "/wallets/spot-accounts", counter: 3, ...other data}
 * ]
 */

const AccountPagesLayout = ({
  sidebarRoutes,
  headerControl,
  isShowBuyCryptoButton = true,
  isShowHeader = true,
  isShowBackArrow = false,
}) => {
  useSecurityLevelModal();

  const { isMobile } = useBreakpoints();

  const { matchedRoute } = useMatchedRoutes({
    routes: ROUTES_NAMES,
    isObjectKeys: true,
  });

  const pageName = useMemo(() => {
    let pageName = ROUTES_NAMES[matchedRoute];

    if (isMobile && !!ROUTES_SHORT_NAMES[matchedRoute]) {
      pageName = ROUTES_SHORT_NAMES[matchedRoute];
    }

    return pageName;
  }, [matchedRoute, isMobile]);

  return (
    <MainLayout secondaryFooterClassName={styles.secondary_footer}>
      {isShowHeader && (
        <AccountHeader
          isShowBackArrow={isShowBackArrow}
          pageName={pageName}
          headerControl={headerControl}
        />
      )}

      <div className={styles.content}>
        <AccountSideBar
          isShowBuyCryptoButton={isShowBuyCryptoButton}
          sidebarRoutes={sidebarRoutes}
        />

        <div className={styles.nav_container}>
          <Outlet />
        </div>
      </div>

      <AccountFooter />
    </MainLayout>
  );
};

export default memo(AccountPagesLayout);
