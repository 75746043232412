import { createElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { CARD_TYPE_MAP } from '@shared/constants';

import { visaCardSelector } from '@pages/WalletsPage/VisaCardDetails/VisaCardDetails.selectors';

import { currencySelector } from '@store/settings/settingsSelectors';

import { format } from '@utils/numbers';

import { FIAT_PRECISION } from '@constants';

import s from './VisaCardBlock.module.scss';

const VisaCardBlock = () => {
  const card = useSelector(visaCardSelector);
  const currency = useSelector(currencySelector);

  return (
    <div className={s.card__wrapper}>
      <div className={s.card__icon}>
        {createElement(CARD_TYPE_MAP[card.type].icon)}
      </div>
      <div>
        <div className={s.card__title}>
          <FormattedMessage id={CARD_TYPE_MAP[card.type].intl} />
          **** {card.number.slice(-4)}
        </div>
        <div className={s.card__balance}>
          {format(card.balance, { precision: FIAT_PRECISION })}{' '}
          {currency.symbol}
        </div>
      </div>
    </div>
  );
};

export default VisaCardBlock;
