export const formatObjectKeysFromCamelToSnakeCase = (obj) => {
  if (
    typeof obj === 'object' &&
    obj !== null &&
    !Array.isArray(obj) &&
    !(obj instanceof File)
  ) {
    const formattedObject = {};

    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const snakeKey = key.replace(
          /[A-Z]/g,
          (letter) => `_${letter.toLowerCase()}`,
        );
        formattedObject[snakeKey] = formatObjectKeysFromCamelToSnakeCase(
          obj[key],
        );
      }
    }
    return formattedObject;
  } else {
    return obj;
  }
};

export const formatObjectKeysFromSnakeToCamelCase = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    const formattedObject = {};

    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const camelKey = key.toLowerCase().replace(/_[a-z]/g, (letter) => {
          const underscoreIndex = key.indexOf(letter);
          const nextLetterToUpper = key.at(underscoreIndex + 1).toUpperCase();

          return nextLetterToUpper;
        });
        formattedObject[camelKey] = formatObjectKeysFromSnakeToCamelCase(
          obj[key],
        );
      }
    }
    return formattedObject;
  } else {
    return obj;
  }
};
