import { endOfDay, startOfDay, subDays } from 'date-fns';

import { ANY_FILTER_KEY } from '@shared/constants';

export const TRANSACTION_HISTORY_LIMIT = 10;

export const TRANSACTION_HISTORY_STATUSES = {
  COMPLETED: 'completed',
  CONFIRMED: 'confirmed',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress',
  PENDING: 'pending',
  PENDING_CANCELLATION: 'pending_cancellation',
  UNCONFIRMED: 'unconfirmed',
  USER_APPROVED: 'user_approved',
};

export const TRANSACTION_HISTORY_STATUS_COLOR = {
  [TRANSACTION_HISTORY_STATUSES.COMPLETED]: 'green',
  [TRANSACTION_HISTORY_STATUSES.CONFIRMED]: 'green',
  [TRANSACTION_HISTORY_STATUSES.FAILED]: 'red',
  [TRANSACTION_HISTORY_STATUSES.IN_PROGRESS]: 'yellow',
  [TRANSACTION_HISTORY_STATUSES.PENDING]: 'yellow',
  [TRANSACTION_HISTORY_STATUSES.PENDING_CANCELLATION]: 'yellow',
  [TRANSACTION_HISTORY_STATUSES.UNCONFIRMED]: 'yellow',
  [TRANSACTION_HISTORY_STATUSES.USER_APPROVED]: 'black',
};

const TODAY_DATE = new Date();
const THIRTY_DAYS = 30;

const FROM_DATE = startOfDay(subDays(TODAY_DATE, THIRTY_DAYS)).getTime();
const TO_DATE = endOfDay(TODAY_DATE).getTime();

export const TRANSACTION_HISTORY_DEFAULT_VALUES = {
  datePeriod: {
    fromDate: FROM_DATE,
    toDate: TO_DATE,
  },
  type: ANY_FILTER_KEY,
};

export const FILENAME = 'transaction_history_report';
